import { Warning } from "@phosphor-icons/react";
import { useController } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Box, Flex, Text, VStack } from "@chakra-ui/react";

import { CallOut, HiiveButton, InternalLink } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import { FormRadioTile, FormTextAreaInput } from "@/components/react-hook-form";
import {
  AccreditationQuestion,
  CurrentContextDocument,
  InvestorStatus,
  InvestorType,
  useAnswerAccreditationQuestionsNewMutation,
  useCurrentAccreditationQuestionGroupQuery,
  useTransitionCurrentStepMutation,
  useUpdateUserMutation,
} from "@/gql";
import { useColors, useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { shadows } from "@/styles/theme";

interface InvestorAccreditationFormValues {
  readonly questionKey?: string | null;
  readonly questionText?: string | null;
}

const OtherAccreditationCriteriaQuestionKeys = [`in-individual-other-v3`];

export const InvestorAccreditationForm = () => {
  const actor = useCurrentActor();
  const [updateUserMutation] = useUpdateUserMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const mutation = useAnswerAccreditationQuestionsNewMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const { t } = useTranslation();
  const [
    transitionCurrentStepMutation,
    isTransitioningCurrentStep,
  ] = useMutationWithError(
    useTransitionCurrentStepMutation(),
    `transitionCurrentStep`,
  );

  const [yellow600] = useColors([`yellow.600`]);

  const { data } = useCurrentAccreditationQuestionGroupQuery({
    variables: {
      investorType: InvestorStatus.Individual,
      countryId: actor.country?.id || ``,
    },
  });

  const previousAnswers = actor.accreditationAnswers;
  const hasPreviousAnswers = !!previousAnswers && previousAnswers.length > 0;
  const initialValues = {
    questionKey: hasPreviousAnswers ? previousAnswers[0].question.key : null,
    questionText: hasPreviousAnswers ? previousAnswers[0].text : null,
  };

  const accQuestionGroup = data?.currentAccreditationQuestionGroup;
  const accQuestions = accQuestionGroup?.questions || [];
  const unaccreditedQuestion = accQuestions
    .toReversed()
    .find((question: AccreditationQuestion) => !question.accredited);

  const validationSchema = Yup.object().shape({
    questionKey: Yup.string()
      .oneOf(
        accQuestions.map((question: AccreditationQuestion) => question.key),
      )
      .nullable()
      .required(t`required`),
    questionText: Yup.string().when(`questionKey`, {
      is: (questionKey: string) =>
        OtherAccreditationCriteriaQuestionKeys.includes(questionKey),
      then: (schema) => schema.required(t`required`),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  });

  const mapVariables = ({
    questionKey,
    questionText,
  }: InvestorAccreditationFormValues) => ({
    input: {
      countryId: actor.country?.id,
      answers: [
        {
          key: questionKey || ``,
          text: !!questionText ? questionText : undefined,
        },
      ],
    },
  });

  const onSuccess = () => {
    transitionCurrentStepMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { onboardingId: actor.onboardingV2?.id! },
    });
  };
  const { handleSubmit, isLoading, control, formState } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
  });

  const { field } = useController({
    name: `questionKey`,
    control,
  });

  const shouldRenderUnaccreditedWarning =
    unaccreditedQuestion &&
    unaccreditedQuestion?.key &&
    field.value === unaccreditedQuestion.key;

  const onContinueAsSeller = async () => {
    await updateUserMutation({
      variables: { input: { investorType: InvestorType.UnaccreditedSeller } },
    });
    await transitionCurrentStepMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { onboardingId: actor.onboardingV2?.id! },
    });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <SlideAnimation>
        <FormRadioTile.Group
          size="md"
          bg="white"
          p={{ base: 4, lg: 6 }}
          borderRadius="md"
          borderColor="grey.200"
          borderWidth={0.5}
          boxShadow={shadows.card}
          gap={6}
          w={{ base: `full`, lg: 160 }}
        >
          <VStack gap={4} w="full">
            {accQuestions
              .toSorted(
                (a: AccreditationQuestion, b: AccreditationQuestion) => {
                  if (a.order < b.order) return -1;
                  if (a.order > b.order) return 1;
                  return 0;
                },
              )
              .map((question: AccreditationQuestion) => (
                <FormRadioTile.Tile
                  key={question.id}
                  name="questionKey"
                  control={control}
                  value={question.key!}
                >
                  <Text>{question.text}</Text>
                </FormRadioTile.Tile>
              ))}
          </VStack>
          {OtherAccreditationCriteriaQuestionKeys.includes(
            field?.value || ``,
          ) && (
            <VStack alignItems="flex-start" w="full">
              <Text fontWeight={500}>{t`please_describe`}</Text>
              <FormTextAreaInput
                w="full"
                control={control}
                name="questionText"
                label=""
                placeholder={t`description`}
                labelSrOnly
              />
            </VStack>
          )}
          {shouldRenderUnaccreditedWarning && (
            <CallOut variant="yellow">
              <Box
                as={Warning}
                size={20}
                color={yellow600}
                weight="fill"
                flex="none"
                alignSelf="flex-start"
              />
              <VStack gap={1} alignItems="flex-start">
                <Text textStyle="text-md">
                  <Trans
                    i18nKey="investor_accreditation_disclaimer"
                    components={{
                      bold: (
                        <InternalLink
                          href=""
                          onClick={onContinueAsSeller}
                          style={{
                            fontWeight: 500,
                            textDecorationLine: `underline`,
                          }}
                        />
                      ),
                    }}
                  />
                </Text>
                <Text textStyle="text-md">{t`investor_accreditation_disclaimer_2`}</Text>
              </VStack>
            </CallOut>
          )}
        </FormRadioTile.Group>
      </SlideAnimation>

      <Flex w="full" justify="flex-end" mt={8}>
        <HiiveButton
          sentryLabel="[InvestorStatusPageV2/Submit]"
          variant="rounded-solid-salmon"
          type="submit"
          size="xl"
          maxW="unset"
          w={{ base: `full`, lg: `unset` }}
          isLoading={isLoading || isTransitioningCurrentStep}
          isDisabled={!formState.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </Flex>
    </form>
  );
};
