import { useTranslation } from "react-i18next";

import { Card, CardBody, CardHeader, Text } from "@chakra-ui/react";

import FundsReceipts from "./FundsReciepts";

const SettlementCard = () => {
  const { t } = useTranslation(`transactions`);

  return (
    <Card variant="darkened-footer" w="full">
      <CardHeader>
        <Text textStyle="heading-2xl">{t(`settlement`)}</Text>
      </CardHeader>
      <CardBody p={6}>
        <FundsReceipts
          receipts={[
            { createdAt: Date.now().toString(), id: `1` },
            { createdAt: Date.now().toString(), id: `2` },
            { createdAt: Date.now().toString(), id: `3` },
          ]}
        />
      </CardBody>
    </Card>
  );
};

export default SettlementCard;
