import { CheckCircle } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Card, HStack, List, ListItem, Text } from "@chakra-ui/react";

type FundReciept = {
  id: string;
  createdAt: string;
};

type FundsReceiptsProps = {
  receipts: FundReciept[];
};

const FundsReceipts = ({ receipts }: FundsReceiptsProps) => {
  const { t } = useTranslation(`transactions`);
  const formattedDate = (createdAt: string) =>
    dayjs(createdAt).format(`D, MMMM YYYY`);

  return (
    <Card variant="outline" boxShadow="none">
      <List>
        {receipts.map((receipt, key) => (
          <ListItem
            key={receipt.id}
            px={4}
            py={3}
            borderBottom={receipts[key + 1] ? `1px solid` : ``}
            borderBottomColor="grey.200"
          >
            <HStack h={10} justifyContent="space-between" alignItems="center">
              <HStack gap={4}>
                <CheckCircle size={24} weight="fill" />
                <Text textStyle="heading-md" lineHeight={6}>
                  {t(`payment_received`)}
                </Text>
              </HStack>
              <Text fontSize={14} color="grey.500">
                {formattedDate(receipt.createdAt)}
              </Text>
            </HStack>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default FundsReceipts;
