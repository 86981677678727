import * as Sentry from "@sentry/nextjs";
import { useCallback } from "react";

import { useToken } from "@/hooks/useToken";

const STATUS_OK = 201;

export function useRefreshToken() {
  const token = useToken();

  const url = `/api/refresh`;

  return useCallback(async () => {
    const response = await fetch(url, {
      method: `POST`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { status } = response;

    const ok = status === STATUS_OK;

    if (!ok) {
      Sentry.captureMessage(`Failed to recieve cookie on refresh`);
    }

    return ok;
  }, [url, token]);
}
