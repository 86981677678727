import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import {
  HiiveModalHeader,
  InfoGrid,
  InfoGridDivider,
  InfoGridItem,
  Note,
  TagList,
} from "@/components/common";
import { PreqinIcon } from "@/components/icons";
import {
  CompanyLastRoundDetailsCardCompanyFragment,
  DefaultUnaccreditedSellerDashboardPageCompanyOverviewCardCompanyFragment,
} from "@/gql";
import {
  formatCapitalRaised,
  formatLastRoundSeries,
  formatLastRoundValue,
} from "@/utils/format";

const InvestorList = ({
  investors,
  onOpen,
}: {
  readonly investors: CompanyLastRoundDetailsCardCompanyFragment["investors"];
  readonly onOpen: () => void;
}) => {
  const { t } = useTranslation();
  const maxInvestors = 7;
  if (investors.length === 0) {
    return <> {t(`na`)}</>;
  }
  if (investors.length <= maxInvestors) {
    return <>{investors.map((investor) => investor.name).join(`, `)}</>;
  }
  return (
    <>
      {investors
        .slice(0, maxInvestors)
        .map((investor) => investor.name)
        .join(`, `)}
      {` `}
      <Button variant="boxed-grey-mini" onClick={onOpen}>
        {`+${investors.length - maxInvestors} more`}
      </Button>
    </>
  );
};

const InvestorModal = ({
  investors,
  isOpen,
  onClose,
}: {
  readonly investors: DefaultUnaccreditedSellerDashboardPageCompanyOverviewCardCompanyFragment["investors"];
  readonly isOpen: boolean;
  readonly onClose: () => void;
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl">
    <ModalOverlay />
    <ModalContent>
      <HiiveModalHeader closeModal={onClose}>Investors</HiiveModalHeader>
      <ModalBody>
        {investors.map((investor) => investor.name).join(`, `)}
      </ModalBody>
    </ModalContent>
  </Modal>
);

export const LastRoundDetailsCard = ({
  company,
}: {
  readonly company: DefaultUnaccreditedSellerDashboardPageCompanyOverviewCardCompanyFragment;
}) => {
  const { t } = useTranslation();

  const {
    lastRoundRaised,
    lastRoundPostValuation,
    lastRoundPricePerShare,
    totalRaised,
    lastRoundSeries,
    lastRoundDate,
    investors,
    industries,
  } = company;

  const capitalRaised = formatCapitalRaised({ lastRoundRaised, totalRaised });
  const lastRoundValue = formatLastRoundValue({
    lastRoundPostValuation,
    lastRoundPricePerShare,
  });
  const formattedLastRoundSeries = formatLastRoundSeries({
    lastRoundDate,
    lastRoundSeries,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <InvestorModal investors={investors} isOpen={isOpen} onClose={onClose} />
      <Card variant="flat" w="full">
        <CardHeader>
          <HStack justifyContent="space-between">
            <Text as="h5" textStyle="heading-sm">
              {t(`last_round_details`)}
            </Text>
            <Stack
              direction={{ base: `column`, sm: `row` }}
              alignItems="center"
            >
              <Text as="i" textStyle="text-sm">
                {t(`powered_by`)}
              </Text>
              <PreqinIcon mt="0px !important" />
            </Stack>
          </HStack>
        </CardHeader>
        <CardBody p="unset" px={8} py={4}>
          <InfoGrid>
            <InfoGridItem title={t(`value`)}>{lastRoundValue}</InfoGridItem>
            <InfoGridDivider />
            <InfoGridItem title={t(`date`)}>
              {formattedLastRoundSeries}
            </InfoGridItem>
            <InfoGridDivider />
            <InfoGridItem title={t(`capital_raised`)}>
              {capitalRaised}
            </InfoGridItem>
            <InfoGridDivider />
            <InfoGridItem title={t(`notable_investors`)}>
              <InvestorList investors={investors} onOpen={onOpen} />
            </InfoGridItem>
            <InfoGridDivider />
            <InfoGridItem title={t(`industries`)}>
              {industries.length > 0 ? (
                <TagList>
                  {industries.map(({ id, name }) => (
                    <Tag variant="blue" key={id}>
                      {name}
                    </Tag>
                  ))}
                </TagList>
              ) : (
                t(`na`)
              )}
            </InfoGridItem>
          </InfoGrid>
        </CardBody>
        <CardFooter>
          <Note>{t(`last_round_details_description_preqin`)}</Note>
        </CardFooter>
      </Card>
    </>
  );
};
