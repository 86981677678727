const locale = {
  added_date: `Added {{date}}`,
  required: `Required`,
  optional: `optional`,
  validation_required: `{{field}} is required`,
  validation_min_items: `You must add at least {{min}} {{field}}`,
  validation_valid_number: `{{field}} must be a valid number`,
  validation_invalid: `{{field}} is invalid`,
  issuers_charge_fee: `For example, some issuers charge fees to sellers in
    order to cover their legal and other costs of executing the sale
    transaction. As well, you may need to pay taxes, or fees to consultants such
    as lawyers or tax advisors. These charges are borne by you and not deducted
    from the purchase price for the purpose of calculating Hiive's
    transaction fee.`,
  co_broker_fee_info_short: `*This is the estimated price per share after deducting Hiive's standard co-broker fee schedule, calculated in accordance with the `,
  co_broker_fee_info_long: `Your customer may incur other charges in order to complete this transaction, including for example taxes, any brokerage fees charged by you, fees charged by issuers, or fees for consultants such as lawyers or tax advisors. These charges are borne by you or your customer and are not deducted from the purchase price for the purpose of calculating Hiive's sell-side co-broker fee.`,
  hiive_fee_proceeds: `* Proceeds shown are net of all Hiive fees, but there may be other charges that you will have to incur in order to complete this transaction.`,
  no_standing_bids: `There are currently no standing bids for {{companyName}} from other users.`,
  no_listings: `There are currently no active market listings for {{companyName}}.`,
  solid_bid_line: `The solid bid line is the highest-priced bid for {{companyName}}, which was open anytime that week. The solid ask line is the lowest-priced listing for {{companyName}}, which was open anytime that week. The dotted lines show the last bid (or ask) price for a week where no bid (or listing) was made. The blue transaction dot indicates the highest accepted bid price that week. The dotted last round PPS line shows the unverified price of the last funding round (where available).`,
  no_active_bids_on_listing: `There are currently no active bids on this listing. You will be notified when a bid has been placed.`,
  no_past_bids: `There are no past bids on this listing.`,
  no_active_discussions: `There are currently no active discussions on this listing. You will be notified when a discussion has been requested.`,
  fee_payed: `Hiive fee paid`,
  hiive_co_broker_fee_payed: `Hiive co-broker fee paid`,
  awaiting_closing_fee: `Awaiting Hiive closing fee payment`,
  gross_proceeds: `Gross Proceeds`,
  gross_sale_amount: `Gross Sale Amount`,
  hiive: `Hiive`,
  hiive_fees: `Hiive Fees`,
  base_fee: `Hiive Base Fee`,
  hiive_commission: `Hiive Commission`,
  total_hiive_fees: `Total Hiive Fees`,
  create_listing_cta: `Create a listing for shares you have the ability to sell, enabling buyers to bid directly on your listing.`,
  sell: `Sell`,
  sell_now: `Sell Now`,
  submitting_your_details: `Submitting your details. It will take a few minutes...`,
  buy: `Buy`,
  list_for_sale: `List for Sale`,
  decline: `Decline`,
  make_bid: `Make a Bid`,
  accept_bid: `Accept Bid`,
  add_entity: `Add Entity`,
  cannot_access_hiive_until_agree_to_ca: `You cannot access the Hiive platform until you agree to the Customer Agreement. If you have questions please contact us at <a>support@hiive.com</a>.`,
  cannot_access_hiive_connect_until_agree_to_ca: `You cannot access the HiiveConnect platform until you agree to the Customer Agreement. If you have questions please contact us at <a>support@hiive.com</a>.`,
  bid_on_existing_listing: `Bid on an existing listing of stock for sale, or create your own independent standing bid that any seller can accept.`,
  must_be_approved_by_company: `You must be approved by {{companyName}} to place a bid. If you are authorized to bid or want more information, please reach out to the <0>{{companyName}} Security Specialist.</0>`,
  accepting_buyer_bid_notice: `Standing bids are posted by buyers who are ready to purchase stock at a set price. You may not counter offer but you may message the buyer below with any questions or to ask if they can revise their price.<br /><br />If you are interested in accepting this standing bid then the next step is to review the details.`,
  accepting_buyer_bid_partial_notice: `Standing bids are posted by buyers who are ready to purchase stock at a set price. You may not counter offer but you may message the buyer below with any questions or to ask if they can revise their price.<br /><br />If you are interested in accepting this standing bid then the next step is to specify the number of shares you wish to sell.`,
  identity_verification_being_processed: `You will be able to list your shares for sale soon. Your Identity
  Verification is being processed.`,
  identity_submitted_for_review: `Your information has been submitted for review to our operations team. For any questions or information contact: `,
  identity_verification_declined: `Your Identity Verification has been declined. For any questions, please
  contact: `,
  invite_customer_and_begin_transfer_loi: `To progress your customer's {{companyName}} purchase, please direct them to review and sign the {{documentTitle}} and the transfer process may begin. Your customer and the seller must sign the {{documentTitle}} before {{expirationDate}}, or the proposed transfer will be cancelled.`,
  invite_customer_and_begin_transfer_stn: `To progress your customer's {{companyName}} purchase, please direct them to review and sign the {{documentTitle}}. Hiive will then notify {{companyName}}, and the approval/transfer process will start. Your customer and the seller must sign the {{documentTitle}} before {{expirationDate}}, or the proposed transfer will be cancelled.`,
  sign_to_begin_transfer_loi: `To progress your {{companyName}}
  {{action}} you must first sign a preliminary {{documentTitle}} and the transfer process may begin. Both parties must sign the document before {{expirationDate}}, or the proposed transfer will be cancelled.`,
  sign_to_begin_transfer_stn: `To progress your {{companyName}}
  {{action}} you must first sign a preliminary {{documentTitle}} and the transfer process may begin. {{companyName}} will then be notified, and the transfer process can begin. Both parties must sign the document before {{expirationDate}}, or the proposed transfer will be cancelled.`,
  sale: `sale`,
  company_page_listing_empty_state: `There are currently no active market listings for {{companyName}}.`,
  company_page_listing_empty_state_for_brokers: `There are currently no active listings for {{companyName}}. Submit a standing bid so Hiive can display your customer's bid to potential sellers who have not yet created listings.`,
  place_bid_intent: `I understand that by placing this bid, I am communicating a serious, but not binding, intention to complete a sale transaction on these terms.`,
  submit_bid_success_in_review_title: `Your bid is in review`,
  submit_bid_success_in_review_heading: `Your {{companyName}} bid for listing {{displayId}} is now in review.`,
  submit_bid_success_in_review_body: `Your offer is being reviewed and may then be placed on the seller's listing. Hiive will notify you once your bid is placed.`,
  submit_bid_success_in_review_contact_us: `If you wish to modify or withdraw your bid or need any assistance, contact the <0>Security Specialist</0>.`,
  place_bid_success_title: `Bid placed`,
  place_bid_success_heading: `Your {{companyName}} bid on Listing {{displayId}} is now live!`,
  place_bid_success_body: `The Seller will be informed of your bid and will have an opportunity to accept or make you a counter offer. You may also engage with the Seller by requesting a discussion to ask questions, let them know something about you, or negotiate terms.`,
  place_bid_success_contact_us: `You may also reach out to the <0>Security Specialist</0> for assistance on your transaction.`,
  place_bid_spv_success_body: `We have received your bid on Listing {{displayId}} on {{companyName}}!<br /><br />Next steps: If your bid is accepted, we will send you an email outlining next steps in the process and explaining how to complete your investment.`,
  place_bid_spv_success_contact_us: `You may also reach out to our <0>Hiive SPV team</0> at any time.`,
  spv_transaction_status_description: `You should have received an email with next steps to complete your investment. You may also reach out to our <0>Hiive SPV</0> team at anytime.`,
  confirm: `Confirm`,
  total: `Total`,
  maximum_total: `Maximum Total`,
  shares: `Shares`,
  maximum_shares: `Maximum shares`,
  place_a_bid: `Place a Bid`,
  submit_a_bid: `Submit a Bid`,
  number_of_shares: `Number of shares`,
  number_of_shares_per_series: `Number of shares per series`,
  maximum_number_of_shares: `Maximum number of shares`,
  number_of_shares_selling: `Number of shares you are selling`,
  price_per_share: `Price per share`,
  bid_valid_for_days: `Bid valid for (days)`,
  continue: `Continue`,
  okay: `Okay`,
  broker_submit_bid_confirmation_body: `Are you sure you want to submit this bid?`,
  broker_submit_bid_body: `Submit an offer on this listing. Hiive will then review your bid and, if approved, place it on the seller’s listing. Hiive will notify you if your bid is placed.`,
  broker_submit_bid_affiliate_question: `Are you posting this bid on behalf of an insider or affiliate of {{companyName}}?`,
  broker_submit_bid_unsolicited_question: `Is this an unsolicited bid?`,
  broker_submit_listing_unsolicited_question: `Is this an unsolicited listing?`,
  broker_submit_listing_unsolicited_question_tooltip: `If your listing is unsolicited, it will be reviewed and may be posted on Hiive. If your listing is solicited, a Hiive representative will contact you about next steps.`,
  broker_submit_bid_acknowledgement: `I confirm that I am submitting this bid on behalf of a potential buyer with whom I am in direct contact and who has a serious and bona fide interest in pursuing a transaction on the posted terms.`,
  broker_submit_listing_acknowledgement: `I confirm that I am submitting this listing on behalf of a potential seller with whom I am in direct contact and who has a serious and bona fide interest in pursuing a transaction on the posted terms and that the above answers are correct.`,
  broker_submit_listing_affiliate_question: `Are you submitting this listing on behalf of an insider or affiliate of {{companyName}}?`,
  broker_submit_listing_affiliate_question_tooltip_part_1: `Insider = A company insider is an officer or director of the issuer or someone who is the beneficial owner, either directly or indirectly, of more than 10% of any class of issued shares.`,
  broker_submit_listing_affiliate_question_tooltip_part_2: `Affiliate = A person that has direct or indirect control over the issuer, is themselves controlled by the issuer, or is under common control with the issuer.`,
  broker_place_standing_bid_heading: `Place a Standing Bid Instead`,
  broker_place_standing_bid_description: `While you cannot submit a bid on this listing via HiiveConnect, you may still create an independent offer to buy {{companyName}} stock at a set price.`,
  search_country: `Search Country`,
  solicited_listing_activity_card_body: `This order cannot be quoted on HiiveConnect.`,
  solicited_standing_bid_activity_broker_card_body: `This order cannot be quoted on HiiveConnect but you may still engage with this party.`,
  solicited_listing_activity_card_contact: `Contact <0>{{email}}</0> for details.`,
  solicited_standing_bid_activity_card_body: `This order cannot be quoted on HiiveConnect but you may still engage with this party.`,
  solicited_standing_bid_activity_card_contact: `Contact <0>{{email}}</0> for details.`,
  learn_more: `Learn More`,
  issuer_pricing_and_fundraising: `Use issuer pricing and fundraising information to support your listing
  price decision and build your awareness of current market trends.`,
  market_activity: `Market Activity`,
  company_overview: `{{companyName}} Overview`,
  create_listing_confirm_genuine_interest: `Please tick this box to confirm that you are willing and able to pursue a transaction at the price and for the number of shares in your listing. You are not legally bound to sell at the listing price but you do need to have a genuine interest in doing so.`,
  create_listing_intent_for_sale: `I understand that by making this listing, I am communicating a serious, but not binding, intention to complete a sale transaction on these terms.`,
  create_listing_success_body: `We will notify potential buyers of your listing. Buyers can place a bid or request a discussion with you about the listing. You will receive notifications of any buyer interest.`,
  submit_broker_listing_success_body: `Your listing is being reviewed and may then be placed on the Hiive marketplace. You will be notified once it gets posted, and Hiive will keep you informed of any interest shown by potential buyers in response to your listing.`,
  listing_solicitation_solicited: `Solicited Listing`,
  listing_solicitation_unsolicited: `Unsolicited Listing`,
  bids_broker_activity: `Contact Hiive's {{companyName}} Security Specialist with any questions about this bid. They can help you modify or withdraw your bid and relay any questions to the seller.`,
  standing_bids_broker_activity: `Contact the Hiive Security Specialist representing you with any questions about this standing bid. They can help you modify or withdraw your bid and relay any questions to the seller.`,
  broker_in_review_bid_security_specialist_card: `Contact Hiive's {{companyName}} Security Specialist with any questions about this bid. They can help you modify or withdraw your bid and relay any questions to the seller.`,
  broker_live_bid_security_specialist_card: `Contact Hiive's {{companyName}} Security Specialist for any questions about the transfer process.`,
  broker_in_review_standing_bid_security_specialist_card: `Contact Hiive's {{companyName}} Security Specialist with any questions about this standing bid. They can help you modify or withdraw your bid and relay any questions to the seller.`,
  broker_live_standing_bid_security_specialist_card: `Contact the Hiive Security Specialist representing you with any questions about this standing bid. They can help you modify or withdraw your bid and relay any questions to the seller.`,
  listing_buyer_rounding_disclaimer: `Share count is rounded from the actual number of shares`,
  listing_viewer_rounding_disclaimer: `Share count is rounded from the actual number of shares`,
  listing_viewer_rounded_shares_tooltip: `The number of shares in listings is rounded to help protect the identity
  of Hiive customers.`,
  complete_document: `Complete Document`,
  complete_your_profile_buy_sell: `<italic>Complete your profile</italic> to buy or sell securities.`,
  complete_your_profile_sell: `<italic>Complete your profile</italic> to sell securities.`,
  complete_your_profile_buy: `<italic>Complete your profile</italic> to buy securities.`,
  complete_your_profile_to_receive_notifications: `<italic>Complete your profile</italic> to receive notifications.`,
  complete_suitability_buy: `<italic>Complete suitability</italic> to buy securities.`,
  institution: `Institution`,
  investor_status: `Investor Status`,
  in_what_capacity: `In what capacity will you be using Hiive?`,
  investor_type_page_title: `Which profile fits you?`,
  investor_type_individual_trader_description: `This option is best for accredited investors, experienced investors, and angel investors looking to buy and sell.`,
  individual_trader: `Individual Trader`,
  individual_trader_view_offers: `View live and historical offers and data on all listed companies.`,
  individual_trader_buy_and_sell: `<bold>Buy</bold> and <bold>sell</bold> capabilities.`,
  seller_only: `Seller Only`,
  seller_only_description: `This option is best for current and former employees who may not meet "accredited investor" thresholds but want to monitor and potentially sell some of their private company stock.`,
  seller_only_sell_capabilities: `<bold>Sell</bold> capabilities only.`,
  seller_view_offers: `View live and historical offers and data on securities you hold.`,
  selling_on_hiive_in_three_steps: `Selling on Hiive in three steps`,
  hiive_platform_aims_for_transparency: `Hiive's platform aims to give you transparency into the market for your stock, and control over your sale. Assess the market price, set your own ask, then negotiate with buyers and complete your sale.`,
  explore_pricing: `Explore pricing`,
  browse_listings_and_bids: `Browse listings and bids placed by other users to get insight into the potential value of your stock. Compare current listings and bids to "last round value". If you need help, talk to a Hiive representative to get insight into the market.`,
  create_your_listing: `Create your listing`,
  finalize_an_asking_price: `Finalize an asking price with the help of our quick comparison tool. Then post an anonymous listing. A listing will enable you to receive bids from buyers, but doesn't commit you to selling. You control the pace and terms of your sale.`,
  accept_a_bid_and_sell_shares: `Accept a bid and sell your shares!`,
  interested_buyers_may_bid: `Interested buyers may place a bid or request a discussion on your listing. You can then accept, counter-offer, or message them to negotiate. If you accept a bid, Hiive will guide you through the closing process, which can take 30-90 days and requires the company’s approval.`,
  cant_find_your_company: `Can't find your company?`,
  add_company_manually: `Add your company manually`,
  company_name: `Company name`,
  enter_company_name: `Enter company name`,
  company_not_found_on_hiive: `Company not found on Hiive`,
  watchlist_page_title: `Watchlist`,
  watchlist_page_description: `Please add all companies of interest. You will be notified of new listings, bids, pricing and other developments relating to companies on your watchlist.`,
  watchlist_page_select_at_least_one: `You must select at least one company.`,
  search_company: `Search company`,
  remove: `Remove`,
  investor_profile_type: `Which profile fits you?`,
  investor_accreditation_title: `How are you accredited?`,
  institution_accreditation_title: `How is your firm/fund accredited?`,
  investor_accreditation_description: `Only accredited investors can access and use the Hiive platform.`,
  investor_accreditation_description_two: `Please let us know how you qualify.`,
  investor_accreditation_disclaimer: `If you don't meet any of these criteria, and you only want to sell securities, <bold>you can continue as a seller</bold>.`,
  investor_accreditation_disclaimer_2: `Otherwise we will contact you to discuss your account and your accreditation status and determine if you are qualified to buy securities on Hiive.`,
  holding_details: `Holding details`,
  tell_us_about_stock_or_options: `Tell us about the stock or options you hold.`,
  you_can_add_up_to_max_holdings: `You can add up to 4 holdings.`,
  how_many_shares_or_options_owned: `How many shares or options do you own?`,
  investor_suitability_title: `Suitability`,
  investor_suitability_canadian_disclaimer: `I have read and understood the <disclaimer>Disclaimers & Disclosures</disclaimer> and the <relationship>Relationship Summary (Canada)</relationship>`,
  inst_complete_your_profile_buy_sell: `{{company}} needs to <italic>complete profile</italic> to buy or sell securities.`,
  inst_complete_your_profile_sell: `{{company}} needs to <italic>complete profile</italic> to sell securities.`,
  inst_complete_your_profile_buy: `{{company}} needs to <italic>complete profile</italic> to buy securities.`,
  inst_admin_complete_your_profile_buy_sell: `{{company}}'s admin needs to <italic>complete profile</italic> to buy or sell securities.`,
  inst_admin_complete_your_profile_sell: `{{company}}'s admin needs to <italic>complete profile</italic> to sell securities.`,
  inst_admin_complete_your_profile_buy: `{{company}}'s admin needs to <italic>complete profile</italic> to buy securities.`,
  inst_admin_complete_suitability_buy: `{{company}}'s admin needs to <italic>complete suitability</italic> to buy securities.`,
  residence: `Place of Residence`,
  name_and_residence: `Name and residence`,
  place_of_residence: `What is your legal place of residence?`,
  where_are_you_located: `Where are you located?`,
  submitted_to_hiive_for_review: `Submitted to Hiive for review`,
  standing_bid_closed_status: `Closed: This standing bid is now closed.`,
  listing_submitted_in_review: `Submitted to Hiive for review {{date}}`,
  standing_bid_in_review_status: `Submitted to Hiive for review {{date}}`,
  standing_bid_conditionally_completed_status: `Conditionally fulfilled: This standing bid is now closed pending the execution of a {{documentLongName}} ({{documentShortName}}). Both parties must sign the {{documentShortName}} before {{expireTime}} PDT on {{expireDate}}, or this standing bid will be re-opened.`,
  standing_bid_conditionally_completed_status_generic: `Conditionally fulfilled: This standing bid is now closed pending the execution of the signed agreements. If any of the agreements are not signed before they expire, the Standing Bid will be re-opened.`,
  standing_bid_open_status: `Live: Sellers can accept your bid or message you for more information.`,
  must_be_accredited: `You must be an accredited investor to access and use the Hiive platform. Please let us know how you qualify.`,
  firm_fund_must_be_accredited: `Your firm/fund must be an accredited investor or a qualified institutional buyer to access and use Hiive.`,
  firm_fund_must_be_accredited_and_how: `Your firm/fund must be an accredited investor in order to access and use the Hiive platform. How is your firm/fund accredited?`,
  accreditation: `Accreditation`,
  firm_fund_information: `Firm/fund information`,
  firm_fund_name: `Firm/fund name`,
  what_is_your_firm_fund_name: `What is the full legal name of your firm/fund?`,
  where_is_your_firm_fund: `Where is your firm/fund located?`,
  street_address_of_firm_fund: `Street address of firm/fund`,
  street_address_of_firm: `Street address of firm`,
  jurisdiction_of_incorporation_registration: `Jurisdiction of incorporation/registration`,
  what_is_the_firm_fund_structure: `What is the legal structure of the firm/fund?`,
  what_is_your_position_in_firm_fund: `What is your position in the firm/fund?`,
  position_name: `Position name`,
  are_you_a_broker_dealer: `Are you a broker-dealer, intermediary or agent acting on behalf of an unrelated client?`,
  legal_structure: `Legal structure`,
  submitted_on: `Submitted on {{date}}`,
  answer: `Answer`,
  modify: `Modify`,
  ca_institutional_investment_fund: `The firm/fund is an investment fund that distributes or has distributed its securities only to accredited investors or that is advised by a person registered as an adviser or a person that is exempt from registration as an adviser.`,
  ca_institutional_corporation_net_assets: `The firm/fund is a corporation, other than an investment fund, that has net assets of at least CAD 5 million as shown on its most recently prepared financial statements.`,
  ca_institutional_corporation_owners_accredited: `The firm/fund is a corporation in which all of the owners of interests, direct, indirect or beneficial, except the voting securities required by law to be owned by directors, are persons that are accredited investors.`,
  ca_institutional_financial_institution: `The firm/fund is a Canadian financial institution or bank, insurance company or credit union, or any wholly-owned subsidiary of one of those.`,
  ca_institutional_municipality: `The firm/fund is a municipality, public board or commission.`,
  ca_institutional_pension_fund: `The firm/fund is a pension fund.`,
  ca_institutional_not_accredited: `The firm/fund is not accredited.`,
  ca_institutional_other_criteria: `The entity is accredited on the basis of a criterion that is not
  listed above.`,
  is_qualified_qib: `Is your firm/fund a qualified institutional buyer or QIB?`,
  qualified_institutional_buyer: `Qualified Institutional Buyer https://www.law.cornell.edu/cfr/text/17/230.144A`,
  yes: `Yes`,
  no: `No`,
  description: `Description`,
  please_describe: `Please describe`,
  us_institutional_registered_adviser: `The firm/fund is a state or SEC registered investment adviser or an exempt reporting adviser.`,
  us_institutional_assets_investments: `The firm/fund has total assets or investments in excess of $5,000,000 and was not formed for the specific purpose of acquiring securities through Hiive.`,
  us_institutional_family_office: `The firm/fund is a “family office,” with assets under management in excess of $5,000,000, that is not formed for the specific purpose of acquiring securities through Hiive, and whose prospective investment is directed by a person who has such knowledge and experience in financial and business matters that such family office is capable of evaluating the merits and risks of the prospective investment.`,
  us_institutional_owners_accredited: `All equity owners of the firm/fund are accredited.`,
  us_institutional_bank_savings_insurance: `The firm/fund is a bank, savings and loan association, insurance company or registered investment company.`,
  us_institutional_not_accredited: `The firm/fund is not accredited.`,
  us_institutional_other_criteria: `The firm/fund is accredited on the basis of a criterion that is not listed above.`,
  cancel: `Cancel`,
  must_select_one_option: `Must select at least one option`,
  must_select_answer: `Must select an answer`,
  ca_individual_net_investments: `I have, either on my own or with my spouse, more than CAD 5
  million of assets, including my home, net of any liabilities.`,
  ca_individual_net_assets: `I have, either on my own or with my spouse, more than CAD 1 million of financial assets net of any liabilities.`,
  ca_individual_income: `I have had more than CAD $200,000 of income in each of the last
  two years and expect to exceed that income level this year, or I
  had more than CAD $300,000 income combined with my spouse in each
  of the last two years and we expect to exceed that income this`,
  im_not_accredited: `I’m not accredited yet.`,
  individual: `Individual`,
  individual_sell_or_buy_private_stock: `Sell or buy private stock on your <bold>own behalf</bold>.`,
  institutional_sell_or_buy_private_stock: `Sell or buy private stock on behalf of a <bold>firm, fund, entity</bold> or <bold>SPV sponsor</bold>.`,
  individual_monitor_market_data: `Monitor market data about your personal portfolio.`,
  institutional_monitor_market_data: `Monitor market data about your institutional portfolio.`,
  log_out: `Log out`,
  step_must_be_completed_before_buy_or_sell: `This step must be completed before you can sell or buy securities.`,
  step_must_be_completed_before_buy: `This step must be completed before you can buy securities.`,
  us_individual_net_assets_v2: `I have, on my own or jointly with my spouse/partner, a net worth of
  more than $1 million, excluding the value of my/our house but
  including the value of any private stock I/we own.`,
  us_individual_income_v2: `I have had a gross income of $200,000 (or $300,000 jointly with my
    spouse/partner) for the past 2 years and expect the same this year.`,
  us_individual_license_active_v2: `I am a Series 7, Series 65 or Series 82 holder and my license is
    active and in good standing.`,
  us_individual_not_accredited_v2: `I don't meet any of these criteria.`,
  no_longer_qualify: `If you no longer qualify as an accredited investor, please contact us at <bold>accounts@hiive.com</bold>`,
  inst_no_longer_qualify: `If {{company}} is no longer an accredited investor, or if {{company}} is accredited on the basis of a criterion that is not listed above, please contact <bold>accounts@hiive.com</bold>`,
  hiive_market_activity_for: `Hiive market pricing activity for {{companyName}}`,
  suitability: `Suitability`,
  action_needed: `Action Needed`,
  complete_suitability_to_buy: `Complete suitability questionnaire to buy stock on Hiive`,
  save: `Save`,
  iv: `Identity Verification`,
  customer_agreement: `Customer Agreement`,
  verify_identity: `Verify Identity`,
  verify_your_identity: `Verify your identity`,
  verify_your_email: `Verify your email`,
  please_check_your_inbox: `Please check your inbox and click the link to verify your email address.`,
  it_may_take_up_to_5_minutes: `It may take up to 5 minutes.`,
  didnt_receive_anything: `Didn't receive anything?`,
  resend_verification_email: `Resend verification email`,
  verification_email_sent: `Verification email sent!`,
  email_verified: `Email verified`,
  unable_to_verify: `Unable to verify`,
  change_email: `Change Email`,
  before_sell_shares: `Before you can sell your shares, you need to`,
  transfer_type_choice_direct: `A direct transfer is the best choice when you are permitted to transfer your shares to a buyer.`,
  transfer_type_choice_indirect: `When you are not permitted to transfer your shares, you can use an indirect transfer to sell by other means.`,
  transfer_type_choice_unknown: `If you are unsure whether you can transfer your shares directly, you can select “I don’t know.”`,
  hiive_base_fee_tooltip: `Hiive applies a universal base fee of US {{flatFeeUS}} to all transactions. `,
  hiive_commission_tooltip: `Hiive's commission fee is determined based on a percentage of your gross purchase price. For a more comprehensive breakdown of how Hiive's fees are calculated, please refer to the Hiive Customer Terms & Conditions.`,
  approximate_net_proceeds_tooltip: `This is the estimated price per share after deducting the Hiive Transaction Fee. There may be other charges that you will have to incur in order to complete this transaction. For example, some issuers charge fees to sellers in order to cover their legal and other costs of executing the sale transaction.`,
  approximate_net_proceeds_tooltip_v2: `This is the estimated price per share and net proceeds after deducting the Hiive Transaction Fee.`,
  fees_disclaimer: `There may be other charges that you will have to incur in order to complete this transaction. For example, some issuers charge fees to sellers in order to cover their legal and other costs of executing the sale transaction. As well, you may need to pay taxes, or fees to consultants such as lawyers or tax advisors. These charges are borne by you and not deducted from the purchase price for the purpose of calculating Hiive's transaction fee.`,
  place_listing_what_happens_next: `Once you place your listing, it will be posted in the marketplace. Potential buyers will be able to bid on your listing and/or message you about your shares. You can then choose the pace and terms of any sale.`,
  modify_listing_what_happens_next: `Once you submit your modified listing, your changes will update in the marketplace. Potential buyers can bid on your listing and/or message you about your shares. You can then choose the pace and terms of any sale.`,
  historical_pricing_heading: `{{companyName}} historical pricing on Hiive`,
  company_historical_pricing: `{{companyName}} historical pricing`,
  historical_pricing: `Historical pricing`,
  next: `Next`,
  dashboard: `Dashboard`,
  account: `Account`,
  help: `Help`,
  sign_document: `Sign Document`,
  sign_out: `Sign out`,
  u16r_intro_banner_title: `Getting started with your shares`,
  u16r_intro_banner_company_overview_step: `Learn more about the current {{companyName}} market activity by
  checking out the company overview.`,
  u16r_intro_banner_listing_step: ` When you are ready, list your shares or accept a standing bid from
  a buyer who has already expressed interest.`,
  u16r_intro_banner_notifications_step: `Get notifications from people interested in your listing and
  choose to accept a bid.`,
  u16r_intro_banner_transaction_step: `Upon accepting a bid, Hiive will guide you through the transaction
  and transfer process.`,
  u16r_intro_banner_learn_more_prompt: `Want to learn more about the full end to end experience?`,
  watch_video: `Watch the video`,
  select_option_learn_more: `Select an option to learn more`,
  incomplete: `Incomplete`,
  accreditation_answers: `Accreditation Answers`,
  suitability_answers: `Suitability Answers`,
  transaction_on_bid: `Transaction on Bid`,
  pending: `Pending`,
  pending_purchases_on_listing: `Your Pending Purchases on this Listing`,
  pending_purchases_on_behalf_of: `Your Pending Purchases on behalf of {{institutionName}}`,
  read_and_understood_disclaimers_us: `I have read and understood the <0>Disclaimers & Disclosures.</0>`,
  read_and_understood_disclaimers_ca: `I have read and understood the <0>Disclaimers & Disclosures</0> and the <1>Relationship Summary (Canada).</1>`,
  read_and_understood_disclaimers_us_inst: `On behalf of the firm/fund, I have read and understood the <0>Disclaimers & Disclosures.</0>`,
  read_and_understood_disclaimers_ca_inst: `On behalf of the firm/fund, I have read and understood the <0>Disclaimers & Disclosures</0> and the <1>Relationship Summary (Canada).</1>`,
  offer_at_below_or_above_ask: `Offer at, below or above the Seller's ask.`,
  select_listing_to_submit_bid_on: `Select a listing on which to submit a bid or, <button>submit a standing bid</button>`,
  confirm_submit_bid: `Are you sure you want to submit this bid?`,
  reach_out_to_hiive_security_specialist: `Reach out to the {{companyName}} Security Specialist if you have any questions.`,
  your_institution: `Your institution`,
  you_can_currently_sell: `You can currently make listings (to sell) on Hiive. If you want to make bids or standing bids (to buy), you must first complete this suitability questionnaire.`,
  if_want_to_make_bids: `If you want to make bids or standing bids (to buy), you must first complete this suitability questionnaire.`,
  company_can_currently_sell: `{{companyName}} can currently make listings (to sell) on Hiive. If you want to make bids or standing bids (to buy), {{companyName}} must first complete the suitability questionnaire.`,
  company_if_want_to_make_bids: `If you want to make bids or standing bids (to buy), {{companyName}} must first complete the suitability questionnaire.`,
  are_you_registered_with_finra: `Are you registered with FINRA (the Financial Industry
    Regulatory Authority) in the United States?`,
  finra_registration_is_required: `If you, your firm, or your customers are located in the US, then FINRA registration is required. Otherwise, you can proceed by selecting 'No'.`,
  are_you_permitted_by_the_laws: `Are you permitted by the laws of the place in which you
    are located to intermediate the purchase and sale of
    private unregistered securities on behalf of persons
    and/or entities domiciled in that place?`,
  company_admin_can_currently_sell: `{{companyName}} can currently make listings (to sell) on Hiive. If you want to make bids or standing bids (to buy), {{companyName}}'s admin must first complete the suitability questionnaire.`,
  company_admin_if_want_to_make_bids: `If you want to make bids or standing bids (to buy), {{companyName}}'s admin must first complete the suitability questionnaire.`,
  broker_add_to_watchlist: `Please add all companies of interest. You will be notified of new listings, pricing and other developments relating to securities on your watchlist.`,
  placed_by_hiive: `Placed by Hiive`,
  hiive_organized_spv: `Hiive-organized SPV`,
  expired: `Expired`,
  expires: `Expires`,
  dont_meet_criteria_but_sell: `If you don't meet any of these criteria, and you only want to <0>sell</0> securities, you can <1>continue as a seller </1>`,
  contact_discuss_accreditation_status: `Otherwise we will contact you to discuss your account and your accreditation status and determine if you are qualified to buy securities on Hiive.`,
  min_transaction_size_error: `The total transaction value must be, at minimum, {{minSize}}. Try adjusting the number of shares or the price per share.`,
  num_shares_below_conditionally_sold_amount_error: `Please enter a share quantity that is greater than or equal to the number of shares sold.`,
  withdraw_listing_to_modify_shares_message: `Most variables in your listing may be changed, but if you wish to change the total or minimum number of shares, then please withdraw this listing and place a new one.`,
  compliance_email_setting_page_title: `Add Or Change Compliance Email`,
  compliance_email_setting_page_description: `Add a compliance email address to receive copies of all
  actions taken and messages sent and received by users in
  your institution.`,
  reject_broker_bid_reason_prompt: `Indicate why the bid will be rejected. This will only be referenced internally.`,
  reject_broker_bid_heading: `Broker bid rejection reason`,
  broker_needs_further_verification_page_heading: `We are excited to welcome you to HiiveConnect.`,
  broker_needs_further_verification_page_response_period: `Your information has been submitted for review by our operations team.`,
  broker_needs_further_verification_page_registration_status: `If you have any questions about the status of your registration or
  would like more information, please contact: <0>{{contactEmail}}</0>`,
  submit_standing_bid_accepted_transfer_methods_label: `The buyer is also willing to purchase shares indirectly via:`,
  place_standing_bid_accepted_transfer_methods_label: `I am also willing to purchase shares indirectly via:`,
  broker_standing_bid_allow_partial_accept_tooltip: `Allowing partial acceptance signals that the buyer is willing to purchase
  smaller lots from multiple sellers. If a seller accepts a portion of the
  standing bid, and the residual number of shares is above the minimum
  number you set, then the standing bid will remain live and available to
  other sellers.`,
  bid_placed_by_hiive: `This bid was posted by a Hiive registered representative based on a
  customer's indication of interest.`,
  standing_bid_placed_by_hiive: `This standing bid was posted by a Hiive registered representative based on a
  customer's indication of interest.`,
  broker_bid_placed_by_hiive: `A Hiive representative posted this bid on behalf of a third party.`,
  broker_bid_placed_by_hiive_broker_perspective: `A Hiive Security Specialist posted this bid on your behalf.`,
  broker_standing_bid_placed_by_hiive: `A Hiive representative posted this standing bid on behalf of a third party.`,
  broker_standing_bid_placed_by_hiive_broker_perspective: `A Hiive Security Specialist posted this standing bid on your behalf.`,
  listing_placed_by_hiive_description: `A Hiive representative placed this listing based on a customer's indication of interest.`,
  spv_more_information: `For more information on how Hiive SPV investments work, visit our <0>Hiive SPV FAQs</0> or email our <1>SPV team</1>.`,
  spv_listing_description: `Shares are offered via a Special Purpose Vehicle (Hiive SPV). The Hiive SPV is a Delaware LLC, organized by The Hiive Company Limited, and administered by a third-party manager Sydecar, Inc. Review the listing notes for more details.`,
  invite_alternate_signer_prompt: `Enter the name and email of a person with the authority to sign this {{documentTitle}} and we will email them a link to sign this document.`,
  broker_invite_alternate_signer_prompt: `Enter your customer's name and email address, and we will email them a link to sign this document.`,
  accept_standing_bid_description: `By accepting this bid, you indicate to the buyer that you intend to sell at this price and quantity. After you accept the bid, you and the buyer will execute an STN (Share Transfer Notice) or LOI (Letter of Intent) to document your agreement to sell. The sale may be conditional on issuer approval and other processes.`,
  last_round_details: `Last round details`,
  value: `Value`,
  date: `Date`,
  capital_raised: `Capital raised`,
  notable_investors: `Notable investors`,
  na: `N/A`,
  industries: `Industries`,
  note: `Note`,
  last_round_details_description_preqin: `Details are obtained from Preqin and a variety of public and private
  sources, and are not verified as being accurate or up to date by
  Hiive.`,
  powered_by: `Powered by`,
  broker_revoke_alternate_signer_invitation_confirmation: `Do you wish to revoke the invitation sent to your customer?`,
  revoke_alternate_signer_invitation_confirmation: `Do you wish to revoke the invitation sent to an alternate signer?`,
  broker_revoke_alternate_signer_invitation_body: `This action will invalidate your customer's invitation and allow you to send to an alternative customer.`,
  revoke_alternate_signer_invitation_body: `This action will invalidate your previous invitation and allow you to send to an alternative signer or sign yourself.`,
  company: `Company`,
  share: `share`,
  seller_lot_details_manually_add_company: `Add your company manually`,
  seller_lot_details_details_prompt: `Tell us about the stocks or options you hold. You can use Hiive to sell stock from, and monitor pricing data for, multiple private companies. You can also add additional holdings later, by updating your account settings.`,
  seller_lot_details_multiple_holdings_details_prompt: `Tell us about the stocks or options you hold. You can use Hiive to sell stock from, and monitor pricing data for, multiple private companies.`,
  seller_lot_details_multiple_holdings_limit_prompt: `You can add up to 4 holdings`,
  seller_lot_details_company_which: `Which company?`,
  seller_lot_details_company_search: `Search for a company`,
  seller_lot_details_company_unlisted: `Don't see your company?`,
  seller_lot_details_company_manual: `Enter your company name manually below`,
  seller_lot_details_company_name: `Company name`,
  seller_lot_details_shares_owned: `How many shares or options of the private company do you own?`,
  seller_lot_details_shares_owned_multiple_holdings: `How many shares or options do you own?`,
  seller_lot_details_shares_approx: `Approximate number of shares`,
  seller_lot_details_shares_number: `Number of shares`,
  seller_lot_details_terms_and_conditions: `I confirm that I own or have the right to own (for example, through unexercised options) stock in the specified companies or company, and understand that this is a requirement of the <italic>Hiive (Seller Only) Terms & Conditions.</italic>`,
  seller_lot_details_min_items: `Must have at least 1 holding`,
  seller_lot_details_max_items: `Maximum of 4 holdings`,
  seller_lot_details_remove_holding: `Remove`,
  transfer_type: `Transfer type`,
  share_series: `Share series`,
  share_series_anonymity: `To preserve your anonymity, other users will only see "preferred" or "common"`,
  share_type: `Share type`,
  place_standing_bid: `Place Standing Bid`,
  place_standing_bid_after_bid_prompt: `Also place a standing bid?`,
  place_standing_bid_after_bid_description: `Boost your likelihood of acquiring {{companyName}} shares by creating a standing bid that will display to other potential sellers. Customize the standing bid to your liking before posting.`,
  submit_standing_bid_cta_broker_heading: `Submit a Standing Bid to buy`,
  submit_standing_bid_cta_broker_description: `Submit an independent order to buy stock at a specific price on your customer's behalf. Your order will be reviewed and may then be placed on the Hiive marketplace.`,
  submit_standing_bid: `Submit Standing Bid`,
  submit_standing_bid_after_bid_prompt: `Submit a Standing Bid`,
  submit_standing_bid_after_bid_description: `Boost your likelihood of acquiring {{companyName}} shares by creating a standing bid with details similar to this bid. Customize the standing bid to your liking before submitting.`,
  place_standing_bid_confirm_intent_prompt: `I understand that by placing this standing bid, I am communicating a serious, but not binding, intention to complete a sale transaction on these terms.`,
  place_standing_bid_confirm_intent_description: `Sellers cannot counter standing bids; they must accept the terms you initially set. However, a seller can message you to propose a different price, allowing for negotiation. You can modify or withdraw your standing bid at any time.`,
  net_price_per_share: `Net price per share*`,
  net_price_per_share_base: `Net price per share`,
  flat_admin_fee: `Flat Admin Fee`,
  approximate_net_proceeds: `Approximate Net Proceeds`,
  potential_sellers_count_other: `<bold>{{count}} potential sellers</bold> of {{companyName}}`,
  potential_sellers_count_one: `<bold>{{count}} potential seller</bold> of {{companyName}}`,
  potential_buyers_count_other: `<bold>{{count}} potential buyers</bold> of {{companyName}}`,
  potential_buyers_count_one: `<bold>{{count}} potential buyer</bold> of {{companyName}}`,
  potential_sellers_but_no_listings_other: `There are currently no listings for {{companyName}} but there are {{count}} users who are potential sellers.`,
  potential_sellers_but_no_listings_one: `There are currently no listings for {{companyName}} but there is {{count}} user who is a potential seller.`,
  potential_buyers_but_no_standing_bids_other: `There are currently no standing bids for {{companyName}} but there are {{count}} users who are potential buyers.`,
  potential_buyers_but_no_standing_bids_one: `There are currently no standing bids for {{companyName}} but there is {{count}} user who is a potential buyer.`,
  place_standing_bid_to_notify_sellers_other: `<bold>Place a standing bid to notify these potential sellers</bold>.`,
  place_standing_bid_to_notify_sellers_one: `<bold>Place a standing bid to notify this potential seller</bold>.`,
  broker_submit_standing_bid_to_notify_sellers: `<bold>Submit a standing bid to notify these potential sellers</bold>.`,
  place_listing_to_notify_buyers_other: `<bold>Place a listing to notify these potential buyers</bold> that there are shares available.`,
  place_listing_to_notify_buyers_one: `<bold>Place a listing to notify this potential buyer</bold> that there are shares available.`,
  u16r_place_listing_to_notify_buyers: `Be the first to <bold>place a listing</bold> for potential buyers to bid on.`,
  place_a_standing_bid: `Place a standing bid`,
  place_a_listing: `Place a listing`,
  submit_a_standing_bid: `Submit a standing bid`,
  holders_as_sellers_count_other: `<bold>{{count}} holders</bold> of {{companyName}} are registered on Hiive as potential or current sellers.`,
  holders_as_sellers_count_one: `<bold>{{count}} holder</bold> of {{companyName}} is registered on Hiive as a potential or current seller.`,
  watchers_as_buyers_count_other: `<bold>{{count}} watchers</bold> of {{companyName}} are registered on Hiive as potential or current buyers.`,
  watchers_as_buyers_count_one: `<bold>{{count}} watcher</bold> of {{companyName}} is registered on Hiive as a potential or current buyer.`,
  holders_as_sellers_count_old_other: `<bold>{{count}} holders</bold> in {{companyName}} are registered on Hiive as potential or current sellers.`,
  holders_as_sellers_count_old_one: `<bold>{{count}} holder</bold> in {{companyName}} is registered on Hiive as a potential or current seller.`,
  watchers_as_buyers_count_old_other: `<bold>{{count}} watchers</bold> for {{companyName}} are registered on Hiive as potential or current buyers.`,
  watchers_as_buyers_count_old_one: `<bold>{{count}} watcher</bold> for {{companyName}} is registered on Hiive as a potential or current buyer.`,
  complete_iv: `Complete Identity Verification`,
  complete_iv_description: `We are required by law to verify your identity and perform certain
  diligence including anti-money laundering checks. We use a third party
  service to perform these verifications.`,
  complete_iv_tech: `You will need either a webcam or camera on your device to verify your
  government issued ID during this process.`,
  identity_verified: `You verified your identity`,
  iv_change: `If you wish to change any of these details please email`,
  contact_hiive: `Contact Hiive`,
  inquiry_iv_status: `Inquiry about identity verification status`,
  iv_in_progress: `Identity Verification in Progress`,
  start_iv: `Start Identity Verification`,
  transfer_type_change_modal_title: `Change transfer type`,
  signing_procedure_automated: `Automated`,
  signing_procedure_manual: `Manual`,
  tasks: `Tasks`,
  messages: `Messages`,
  details: `Details`,
  transfer_type_change_modal_description: `Select a different transfer type for this transaction. Switching the transfer type will invalidate the current transaction document and create a new one for the buyer and seller to sign.`,
  transfer_type_change_modal_transfer_method_label: `Select transfer type`,
  transfer_type_change_modal_signing_procedure_label: `Select signing procedure`,
  transfer_type_change_modal_success_message: `Transfer type changed successfully`,
  admin_transaction_change_transfer_type_button: `Change Transfer Type`,
  admin_transaction_cancel_button: `Cancel`,
  admin_transaction_cancel_disabled_button: `Can't cancel`,
  admin_transaction_change_signing_to_manual: `Change to Manual`,
  submit_a_listing: `Submit a Listing`,
  submit_listing_cta_broker_heading: `Submit a Listing to sell`,
  submit_listing_cta_broker_description: `Submit a listing to sell your customer's shares on their behalf. Your listing will be reviewed and then may be placed on the Hiive marketplace, enabling potential buyers to bid on your listing.`,
  total_transaction_value: `Total transaction value`,
  broker_cannot_accept_standing_bid: `While you cannot directly accept a standing bid on HiiveConnect,
  if you are interested in this standing bid, reach out to the
  {{companyName}} Security Specialist who can provide you with
  additional details and discuss your options.`,
  broker_alternatively_submit_a_listing: `Alternatively, you can submit a listing now to sell {{companyName}} stock, enabling potential buyers to place bids on your listing.`,
  broker_submit_a_listing_instead: `Submit a Listing Instead`,
  accept_standing_bid: `Accept Standing Bid`,
  approximate_net_price_per_share: `APPROXIMATE NET PRICE PER SHARE*`,
  broker_customer_proceeds: `YOUR CUSTOMER'S PROCEEDS (NET OF CO-BROKER FEES)`,
  fees_payable_to_hiive: `Fees payable to Hiive`,
  fees_payable_to_hiive_tooltip: `These fees represent fees payable to Hiive based on Hiive's standard fee schedule. However, if your brokerage and Hiive have agreed to a different fee arrangement in writing, then that fee arrangement will prevail.`,
  broker_listing_share_price_summary: `{{total_shares}} SHARES @ {{price_per_share}}`,
  broker_listing_share_price_summary_partial: `{{total_shares}} SHARES`,
  bid_info_buyer_counter_bid: `Seller's Counter Bid`,
  bid_info_seller_counter_bid: `Your Counter Offer`,
  bid_info_seller_counter_bid_actions_title: `Adjust your Counter Bid`,
  bid_info_seller_counter_bid_actions_modify: `Modify`,
  bid_info_seller_counter_bid_actions_withdraw: `Withdraw`,
  bid_info_original_bid: `Original Bid`,
  listing_placed_status: `Placed by Hiive {{date}}`,
  empty_dashboard_broker_bid_listing: `You have yet to take any action, but if you submit a bid, standing bid or listing it will appear here.`,
  empty_dashboard_broker: `You have yet to take any action, but if you submit a bid or a standing bid, it will appear here.`,
  empty_dashboard_institution: `You have yet to take any action, but if you or another member of {{institution_name}} makes a bid or listing, it will appear here.`,
  empty_dashboard: `You have yet to take any action, but if you make a bid or listing, it will appear here.`,
  transfer_method_question: `How will these be transferred?`,
  end_date_question: `Would you like to add an end date?`,
  end_date_question_tooltip: `Optional: you may specify a date at which your listing will be automatically withdrawn. Hiive recommends a 4 week minimum.`,
  end_date_input_label: `Listing end date`,
  end_date_input_tooltip: `We recommend listing with an end date in 4 to 6 weeks. You may withdraw your listing at any time.`,
  listing_other_details_input_label: `Additional listing notes`,
  listing_other_details_input_placeholder: `Add details`,
  submitted_to_hiive: `Submitted to Hiive`,
  submitted_to_hiive_status: `Submitted to Hiive {{date}}`,
  listing_being_reviewed_by_hiive: `This listing is being reviewed by Hiive.`,
  broker_lister_listing_placed_by_hiive: `A Hiive Security Specialist posted this listing on your behalf.`,
  broker_submit_listing_additional_notes_tooltip: `Include any additional details for potential buyers, such as:
  <ul>
  <li>whether your customer is able to share any information on the company, such as financial statements;</li>
  <li>any details about the structure of the sale (e.g. terms of SPV);</li>
  <li>if your customer holds vested options and not shares and whether they need help financing the exercise;</li>
  <li>anything else that might be relevant to a buyer.</li>
  </ul>
  It is important not to share your customer's or your contact information or identity in this section.
  `,
  accept_standing_bid_additional_notes_tooltip: `Include any additional details for this buyers, such as:
  <ul>
  <li>whether you own shares or options;</li>
  <li>information about the company, as long as you are allowed to share it;</li>
  <li>If there any timing limitations on closing (e.g. must be before or after a specific date);</li>
  </ul>
  It is important not to share your customer's or your contact information or identity in this section.
  `,
  open_bids: `Open Bids`,
  past_bids: `Past Bids`,
  looking_to_buy: `Looking to Buy`,
  broker_lister_live_bid_contact_security_specialist: `Contact the Hiive Security Specialist representing you with any questions about this bid. They can help you accept and counter the bid or convey any questions to the buyer.`,
  broker_lister_live_bid_email_security_specialist_subject: `Broker question about bid on my {{companyName}} listing`,
  broker_lister_email_security_specialist_subject: `Broker request to change {{companyName}} listing`,
  broker_bidder_bid_email_security_specialist_subject: `Broker questions about my {{companyName}} bid`,
  broker_lister_security_specialist_will_manage_bids: `The {{companyName}} Security Specialist will manage any bids on your
  listing.`,
  broker_lister_listing_email_representing_security_specialist: `Contact the Hiive Security Specialist representing you with any questions about this listing. They can help you modify or withdraw your listing and relay any questions to a buyer.`,
  broker_lister_questions_email_security_specialist: `For any questions, modifications, or withdrawal requests related to
  this listing, contact the {{companyName}} Security Specialist:`,
  lot_value: `Lot Value`,
  maximum_lot_value: `Maximum lot Value`,
  asking_pps: `Asking PPS`,
  minimum_number_of_shares_accept: `Minimum number of shares that can be accepted`,
  accepted_share_types: `Accepted Share Types`,
  accepted_transfer_methods: `Accepted Transfer Methods`,
  listing_notes: `Listing Notes`,
  activity: `Activity`,
  not_specified: `Not Specified`,
  structure: `Structure`,
  bid: `Bid`,
  actual_amount_of_shares: `Actual Amount of Shares`,
  actual_value: `Actual Value`,
  listing_display_id: `Listing {{displayId}}`,
  inquiry_for_listing_display_id: `Inquiry for Listing {{displayId}}`,
  listing_broker_collapsed_disclaimer: `If your customer sell’s the entire listing at this price, this is the approximate net proceeds.`,
  listing_broker_expanded_disclaimer: `Your customer may incur other charges in order to complete this transaction, including for example taxes, any brokerage fees charged by you, fees charged by issuers, or fees for consultants such as lawyers or tax advisors. These charges are borne by you or your customer and are not deducted from the purchase price for the purpose of calculating Hiive's sell-side co-broker fee.<br/><br/>  To see how the Hiive Transaction Fee is calculated, consult the <italic>Hiive Customer Terms & Conditions</italic>.`,
  listing_seller_collapsed_disclaimer: `If you sell the entire listing or remaining amount at this price, this is the approximate net proceeds.`,
  listing_seller_expanded_disclaimer: `There may be other charges that you will have to incur in order to complete this transaction. For example, some issuers charge fees to sellers in order to cover their legal and other costs of executing the sale transaction. As well, you may need to pay taxes, or fees to consultants such as lawyers or tax advisors. These charges are borne by you and not deducted from the purchase price for the purpose of calculating Hiive's transaction fee.<br /><br />To see how the Hiive Transaction Fee is calculated, consult the <italic>Hiive Customer Terms & Conditions</italic>.`,
  create_listing_collapsed_disclaimer: `This is the estimated price per share and net proceeds after deducting the Hiive Transaction Fee.`,
  seller_collapsed_disclaimer: `Proceeds shown are net of all Hiive fees, but there may be other charges that you will have to incur in order to complete this transaction.`,
  seller_expanded_disclaimer: `For example, some issuers charge fees to sellers in order to cover their legal and other costs of executing the sale transaction. As well, you may need to pay taxes, or fees to consultants such as lawyers or tax advisors. These charges are borne by you and not deducted from the purchase price for the purpose of calculating Hiive's transaction fee.<br /><br />To see how the Hiive Transaction Fee is calculated, consult the <italic>Hiive Customer Terms & Conditions</italic>.`,
  broker_seller_collapsed_disclaimer: `This is the estimated proceeds after deducting Hiive’s sell-side co-broker fee, calculated in accordance with the HiiveConnect Terms & Conditions and any Co-broker agreement.`,
  broker_seller_expanded_disclaimer: `Your customer may incur other charges in order to complete this transaction, including for example taxes, any brokerage fees charged by you, fees charged by issuers, or fees for consultants such as lawyers or tax advisors. These charges are borne by you or your customer and are not deducted from the purchase price for the purpose of calculating Hiive's sell-side co-broker fee.<br /><br />To see how the Hiive Transaction Fee is calculated, consult the <italic>HiiveConnect Terms & Conditions</italic> and any Co-broker Agreement`,
  counter_bid_collapsed_disclaimer: `This is the estimated price per share and net proceeds after deducting the Hiive Transaction Fee. There may be other charges that you will have to incur in order to complete this transaction.`,
  counter_bid_expanded_disclaimer: `For example, some issuers charge fees to sellers in order to cover their legal and other costs of executing the sale transaction. As well, you may need to pay taxes, or fees to consultants such as lawyers or tax advisors. These charges are borne by you and not deducted from the purchase price for the purpose of calculating Hiive's transaction fee.<br/><br/> To see how the Hiive Transaction Fee is calculated, consult the <italic>Hiive Customer Terms & Conditions</italic>.`,
  broker_seller_proceeds: `Your customer's proceeds`,
  gross_of_your_brokerage_fees: `Gross of your brokerage fees`,
  broker_seller_proceeds_tooltip: `This is the estimated proceeds after deducting Hiive’s sell-side co-broker fee.`,
  broker_seller_bid_activity_cta: `For any questions, acceptance or counter requests related to this bid, contact the {{companyName}} Security Specialist:`,
  listing_conditionally_sold_status: `Conditionally Sold - This lot is sold pending execution of the {{documentTypeLongText}}.
  If the {{documentTypeShortText}} is not signed within {{hoursToSign}} days,
  then this lot will be re-listed.`,
  listing_closed_status: `Closed - This listing is closed and no longer accepting bids.`,
  listing_live_status: `Live: Buyers can now bid on your listing.`,
  submit_listing_cta: `Submit a listing to sell your customer's shares. Your listing may be posted on the Hiive marketplace, enabling buyers to bid on it.`,
  submit_an_order: `submit an order to hiive`,
  submit_order_description: `You can direct your customer's bid to a specific listing or submit an independent standing bid that may be posted on the Hiive marketplace.`,
  submit_order_cta: `Submit a Bid`,
  transaction_state_document_being_processed: `Your transaction document is being processed. This should only be a minute.`,
  transaction_title: `Transaction {{transactionId}}`,
  close_fullscreen: `Close Fullscreen`,
  advanced_options: `Advanced options`,
  price_chart_note_collapsed: `All prices are prices per share. Trend lines are determined by calculating a weighted average of accepted bids, bids, and asks at a point in time, with the highest`,
  price_chart_note_full: `weight on accepted bids followed by bids and then asks, and excluding certain bids and asks that deviate dramatically from the latest weighted average.<br/><br/>These charts and this data are provided for informational purposes only and are not a recommendation to buy or sell any security at any price. Before making any posting or entering into any transaction you should do your own due diligence.<br/><br/>Transaction structure and share type are based on users’ representations during listing, and may not reflect final transaction structure or share type.`,
  representing_hiive_connect_header: `Representing Hiive Connect`,
  listing_placed_by_hiive: `This listing was placed by a Hiive Security Specialist via
  HiiveConnect.`,
  select_entity: `Select Entity`,
  share_type_choice_rsu: `RSUs are "restricted stock units". The best way to figure out if you own RSUs is to look in your company's cap table management system (usually Carta).`,
  share_type_choice_options: `Options are stock options that are vested but not exercised. The best way to find out the status of your options is to look in your company's cap table management system (usually Carta).`,
  share_type_choice_common: `Shares represent an actual ownership stake in the company. Most shareholdings are evidenced by a share certificate, and you should be able to find this certificate in your company's cap table management system (usually Carta).`,
  how_do_you_hold_shares: `How do you hold your shares?`,
  listing_additional_details_expiry_date_tooltip: `You have the option to set a specific date for the automatic withdrawal of your listing. We recommend a 4-week minimum.`,
  do_not_notify_watchers_of_change: `Do not send a notification to watchers about this change`,
  represented_user_info: `Name and email of who you are representing`,
  represented_email_tooltip: `You can add an email of the party you are representing. This email will not be shown on the platform and only used for internal reporting and operations.`,
  name: `Name`,
  first: `First`,
  last: `Last`,
  first_name: `First Name`,
  last_name: `Last Name`,
  your_full_name: `Your full name`,
  your_crd_number: `Your CRD number`,
  crd_number: `CRD number`,
  nrd_number: `NDR number`,
  your_nrd_number: `Your NDR number`,
  firm_name: `Firm name`,
  firm_crd_number: `Firm CRD number`,
  firm_nrd_number: `Firm NDR number`,
  telephone: `Telephone`,
  transaction_modification_header: `Proposed Transaction Modification`,
  transaction_modification_body: `Your transaction has been modified. To proceed with the transaction, you and the counterparty need to accept this change.`,
  transaction_modification_body_broker: `Your customer’s transaction has been modified. To proceed with the transaction, you and the counterparty need to accept this change.`,
  transaction_modification_body_security_specialist: `This transaction has been modified. To proceed with the transaction, the broker you are representing and the counterparty need to accept this change.`,
  net_proceeds: `Net Proceeds`,
  net_amount: `Net Amount`,
  hiive_co_broker_fee: `Hiive co-broker fee`,
  hiive_co_broker_fee_tooltip: `Hiive’s co-broker fee  is calculated in accordance with the HiiveConnect Terms & Conditions and Co-broker Agreement.`,
  transaction_modification_status_tile: `Status`,
  transaction_modification_confirm_modal_name: `Accept Proposed Modified Transaction`,
  transaction_modification_confirm_modal_title: `Are you sure you want to accept this modification?`,
  transaction_modification_confirm_modal_description: `You may need to sign a new {{signingProcedure}}.`,
  transaction_modification_confirm_modal_description_broker: `Your customer may need to sign a new Share Transfer Notice.`,
  transaction_modification_confirm_modal_proposed_update: `Proposed Update`,
  transaction_modification_reject_modal_name: `Reject Proposed Modified Transaction`,
  transaction_modification_reject_modal_title: `Are you sure you want to reject this modification?`,
  transaction_modification_reject_modal_description: `Rejecting the modification may impact the progress or completion of your transaction. For more details, please reach out to your Hiive contact or email <0>{{supportEmail}}</0>.`,
  transaction_modification_reject_modal_description_broker: `Rejecting the modification may impact the progress or completion of your customer’s transaction. For more details, please reach out to your Hiive contact or email <0>{{supportEmail}}</0>.`,
  reject: `Reject`,
  accept: `Accept`,
  legend: `Legend`,
  last_primary_fundraise: `Last primary fundraise`,
  accepted_bid: `Accepted bid`,
  accepted_bid_one: `Accepted bid`,
  accepted_bid_other: `{{count}} Accepted bids`,
  aggregate: `Aggregate`,
  accepted_bid_common: `{{count}} Accepted bid (common)`,
  accepted_bid_common_one: `Accepted bid (common)`,
  accepted_bid_common_other: `{{count}} Accepted bids (common)`,
  common: `Common`,
  accepted_bid_preferred: `Accepted bid (preferred)`,
  accepted_bid_preferred_one: `Accepted bid (preferred)`,
  accepted_bid_preferred_other: `{{count}} Accepted bids (preferred)`,
  preferred: `Preferred`,
  accepted_bid_direct: `Accepted bid (direct)`,
  accepted_bid_direct_one: `Accepted bid (direct)`,
  accepted_bid_direct_other: `{{count}} Accepted bids (direct)`,
  direct: `Direct`,
  accepted_bid_indirect: `Accepted bid (indirect)`,
  accepted_bid_indirect_one: `Accepted bid (indirect)`,
  accepted_bid_indirect_other: `{{count}} Accepted bids (indirect)`,
  indirect: `Indirect`,
  bid_trend: `Bid trend`,
  ask_trend: `Ask trend`,
  posted_ask: `Posted ask`,
  posted_ask_one: `Posted ask`,
  posted_ask_other: `{{count}} Posted asks`,
  posted_bid: `Posted bid`,
  posted_bid_one: `Posted bid`,
  posted_bid_other: `{{count}} Posted bids`,
  posted_ask_common: `Posted ask (common)`,
  posted_ask_common_one: `Posted ask (common)`,
  posted_ask_common_other: `{{count}} Posted asks (common)`,
  posted_bid_common: `Posted bid (common)`,
  posted_bid_common_one: `Posted bid (common)`,
  posted_bid_common_other: `{{count}} Posted bids (common)`,
  posted_ask_preferred: `Posted ask (preferred)`,
  posted_ask_preferred_one: `Posted ask (preferred)`,
  posted_ask_preferred_other: `{{count}} Posted asks (preferred)`,
  posted_bid_preferred: `Posted bid (preferred)`,
  posted_bid_preferred_one: `Posted bid (preferred)`,
  posted_bid_preferred_other: `{{count}} Posted bids (preferred)`,
  posted_ask_direct: `Posted ask (direct)`,
  posted_ask_direct_one: `Posted ask (direct)`,
  posted_ask_direct_other: `{{count}} Posted asks (direct)`,
  posted_bid_direct: `Posted bid (direct)`,
  posted_bid_direct_one: `Posted bid (direct)`,
  posted_bid_direct_other: `{{count}} Posted bids (direct)`,
  posted_ask_indirect: `Posted ask (indirect)`,
  posted_ask_indirect_one: `Posted ask (indirect)`,
  posted_ask_indirect_other: `{{count}} Posted asks (indirect)`,
  posted_bid_indirect: `Posted bid (indirect)`,
  posted_bid_indirect_one: `Posted bid (indirect)`,
  posted_bid_indirect_other: `{{count}} Posted bids (indirect)`,
  all_time: `all time`,
  past_year: `past year`,
  past_six_months: `past 6 months`,
  past_three_months: `past 3 months`,
  chart_options_description: `Chart one or more price trends based on share class or transfer method.`,
  chart_learn_more: `Learn more`,
  additional_indicators: `Additional indicators`,
  show_individual_bids_asks: `Show individual bids and asks`,
  show_accepted_bids: `Show accepted bids`,
  show_last_primary_fundraise: `Show last primary fundraise`,
  reset: `Reset`,
  apply: `Apply`,
  aggregate_description: `Accepted bids and postings for all transfer types and share types`,
  common_description: `Accepted bids and postings with common shares`,
  preferred_description: `Accepted bids and postings with preferred shares`,
  direct_description: `Accepted bids and postings using direct share transfers`,
  indirect_description: `Accepted bids and postings using indirect share transfers`,
  dashboard_pending_transaction_modification_title: `Proposed Transaction Modification Needs Approval`,
  dashboard_pending_transaction_modification_seller_body: `To progress your {{companyName}} sale, you must you must review and approve certain changes.`,
  dashboard_pending_transaction_modification_buyer_body: `To progress your {{companyName}} purchase, you must you must review and approve certain changes.`,
  dashboard_pending_transaction_modification_cta: `Review and Approve`,
  initial_transaction_title: `Initial Transaction`,
  approval_status: `Approval Status`,
  your_approval: `Your approval`,
  broker_approval: `Broker approval`,
  counterparty_approval: `Counterparty approval`,
  modification_applied: `Modification Applied`,
  modification_rejected: `Modification Rejected`,
  updated: `Updated`,
  document_expired: `This document has expired.`,
  document_please_review_modification: `Please review the proposed transaction modification on your
  transaction page.`,
  contact_security_specialist: `If you have any questions, please contact our Security Specialist.`,
  alt_signer_document_page_body: `Please review and sign the {{documentTitle}} below.`,
  alt_signer_document_title: `Execute the {{documentTitle}} for your {{companyName}} transaction`,
  alt_signer_document_pending_mod_title: `Execution of your {{companyName}} {{documentTitle}} is pending review`,
  alt_signer_document_pending_mod_page_body: `To proceed, the proposed modification to your {{companyName}} transaction needs to be reviewed on Hiive.`,
  alt_signer_document_signed_title: `Signed {{documentTitle}} for your {{companyName}} transaction`,
  alt_signer_document_signed: `Thank you for signing. You can now navigate away from or close this
  window.`,
  alt_signer_transaction_modification_header: `Pending a Transaction Modification`,
  read_more: `Read More`,
  view_less: `View Less`,
  multiple_trend_lines_unavailable: `Not available if more than one share type or transfer type is selected.`,
  browse_companies: `Browse Companies`,
  admin: `Admin`,
  hiive_50: `Hiive50`,
  hiive_50_index: `Hiive50 Index`,
  hiive_50_index_description: `The Hiive50 is an equal-weight price index of the 50 most liquid securities on Hiive. The index is generated directly from user orders and transactions on the Hiive platform. It is intended to be a barometer for the direction and momentum of the late-stage pre-IPO market.`,
  view_hiive50: `View Hiive50`,
  hiive50_members: `Hiive50 Members`,
  view_all_constituents: `View all constituents`,
  hiive50_members_explanation: `Displayed price changes reflect the last 90 day period.`,
  index_constituents: `Index Constituents`,
  index_constituents_description: `The Hiive50 consists of the most liquid securities on Hiive. Membership in the index is determined quarterly based on a liquidity score incorporating the frequency and volume of closed transactions in the 180-days prior to the reconstitution date. Indirect share transfers are weighted half relative to direct share transfers in each company's liquidity score.`,
  trend: `Trend`,
  change_over_3m: `Change (over 3m)`,
  ask: `Ask`,
  transaction: `Transaction`,
  transaction_status: `Transaction Status`,
  transaction_details: `Transaction Details`,
  transaction_with: `Transaction with {{counterpartyName}}`,
  no_tasks: `No Tasks`,
  no_tasks_explainer: `Your tasks are currently pending a review. Please check back shortly to view the tasks associated with your transaction.`,
  last_transaction: `Last Transaction`,
  email_to: `Email to`,
  invite_user: `Invite User`,
  invite_user_description: `An email will be sent to this person. They must click the provided link to accept the invitation and then proceed with the sign-up process.`,
  invite_user_existing_account: `If the person already has a Hiive account, they won't be able to sign up again using the same email.`,
  make_user_signer: `Make this user a Signer`,
  make_user_signer_description: `A Signer can sign platform documents on behalf of your firm or institution. These include the Hiive Customer Agreement and transactional documents such as Share Transfer Notices and Letters of Intent.`,
  edit_role: `Edit Role`,
  u16r_fee_discount_applied_to: `{{feeDiscountName}} applied to {{companyName}}`,
  u16r_fee_discount_conditions: `Get a discount on Hiive transaction fees when you create a listing or accept a standing bid for {{companyName}}. Conditions may apply.`,
  proceed_details: `Proceed details`,
  listing_proceeds_detailed_collapsed: `If you sell the entire listing or remaining amount at this price, this is the approximate net proceeds.`,
  listing_proceeds_detailed_expanded: `There may be other charges that you will have to incur in order to complete this transaction. For example, some issuers charge fees to sellers in order to cover their legal and other costs of executing the sale transaction. As well, you may need to pay taxes, or fees to consultants such as lawyers or tax advisors. These charges are borne by you and not deducted from the purchase price for the purpose of calculating Hiive's transaction fee.<br /><br /> To see how the Hiive Transaction Fee is calculated, consult the <italic>Hiive (Seller Only) Terms & Conditions.</italic>`,
  methodology: `Methodology`,
  methodology_description: `Membership in the Hiive50 is determined by a ranked scoring methodology based on the liquidity scores of all securities on Hiive. Each security’s liquidity score is based on the number and volume of closed transactions in the 180-days prior to the reconstitution date. The ranked scoring methodology gives full credit to direct share transfers, half credit to indirect share transfers via special purpose vehicles. Price calculations for individual Hiive50 constituents do not consider the transaction size, share series, or transfer method related to a specific order, accepted bid, or transaction.`,
  new_password_set: `New Password Set`,
  sign_in_with_new_password: `Please sign in with your new password.`,
  sign_in: `Sign in`,
  email: `Email`,
  email_address: `Email address`,
  password: `Password`,
  enter_password: `Enter password`,
  forgotten_password: `Forgotten password`,
  forgot_password_page_heading: `Forgotten Password`,
  reset_password_page_heading: `Reset Password`,
  reset_password_placeholder: `Enter a password`,
  reset_password_confirm_password_placeholder: `Confirm password`,
  signup_password_placeholder: `Enter a password`,
  signup_confirm_password: `Confirm password`,
  signup_password_policy: `Minimum of 8 characters, one uppercase letter, one lowercase letter, one
    number or punctuation character.`,
  signup_key: `Signup key`,
  signup_key_placeholder: `Enter signup key`,
  sign_up: `Sign up`,
  sign_up_hiive_connect: `Sign up for HiiveConnect`,
  signup_hiive_connect_description: `HiiveConnect is a specialized platform for broker-dealers and securities intermediaries to submit bids to Hiive without accessing the Hiive marketplace.`,
  signup_footer_disclaimer: `*Securities are offered by Hiive Markets Limited, member of <0>FINRA</0> / <1>SIPC</1> and registered exempt market dealer in Ontario and British Columbia. Brokerage fees may apply. Find Hiive on <2>BrokerCheck</2>.`,
  signup_footer_copyright: `© The Hiive Company Limited {{year}}. By using this site, you accept our <0>Terms of Use</0> and <1>Privacy Policy</1>. Before engaging with this broker-dealer, review these important <2>Disclosures</2>.`,
  signup_checklist_heading: `Join the fastest growing pre-IPO marketplace.`,
  signup_checklist_description: `Find your counter-party, negotiate directly, and make a deal.*`,
  signup_checklist_market_pricing: `Discover market pricing`,
  signup_checklist_market_access: `Access the market directly`,
  signup_checklist_transaction_automation: `Automate your transaction`,
  signup_checklist_expert_help: `Get expert help`,
  signup_terms_and_policy_agreement: `I agree to Hiive's <0>Terms of Use</0> and <1>Privacy Policy</1>.`,
  signup_existing_account_prompt: `Already have an account?`,
  signup_wave_graphic_alt_text: `Wave graphic`,
  signup_hiive_connect_non_broker_redirect: `If you are a seller or investor acting on your own behalf, please visit the <0>Hiive marketplace</0>`,
  forgot_password_check_inbox: `Check your inbox and click the link to set your new password.`,
  forgot_password_enter_email_prompt: `Enter your email and we’ll send you a link to reset your password.`,
  no_account: `Don't have an account?`,
  reset_password: `Reset Password`,
  enter_new_password: `Enter a new password`,
  can_sign_in_with_new_password: `You can now sign in with your new password.`,
  accept_invitation: `Accept Invitation`,
  no_invitation_found: `No invitation found`,
  trader_customer_agreement_subheader: `The Customer Agreement references the Hiive Terms and Conditions which can be found <a>here</a>.`,
  broker_customer_agreement_subheader: `The Customer Agreement references the HiiveConnect Terms and Conditions which can be found <a>here</a>.`,
  seller_customer_agreement_subheader: `The Customer Agreement references the Hiive (Seller Only) Terms and Conditions which can be found <a>here</a>.`,
  complete_profile_to_view: `Complete your profile to view this information`,
  verify_identity_to_view: `Verify your identity to view this information`,
  sign_agreement_to_view: `Sign customer agreement to view this information`,
  high_fees_warning_message: `Due to the small size of your potential sale, total fees will be high as a percentage of transaction value.`,
  email_verification_expired: `Your email verification link may be expired.`,
  try_again: `Try again`,
  thank_you: `Thank you.`,
  one_moment_redirect: `One moment, as we redirect you...`,
  thank_you_sign_in: `Thank you, please sign in to start using Hiive.`,
  fee_discount: `fee discount`,
  flat_fee_discount: `flat fee discount`,
  fee_discount_alert_company_description: `All sellers of {{companyName}} stock qualify for a {{discountValue}} discount off Hiive’s standard transaction fees.`,
  fee_discount_alert_user_description: `You are eligible for a {{discountValue}} discount off Hiive’s standard transaction fees when selling {{companyName}} stock.`,
  standing_bid_fee_info: `* Proceeds shown are net of all Hiive fees, but gross of any other fees that may be payable in connection with the transaction, such as issuer fees, legal costs, taxes, or option exercise costs.`,
  standing_bid_fee_info_collapsed: `There may be other charges that you will have to incur in order to complete this transaction.`,
  standing_bid_fee_info_expanded: `For example, some issuers charge fees to sellers in order to cover their legal and other costs of executing the sale transaction. As well, you may need to pay taxes, or fees to consultants such as lawyers or tax advisors. These charges are borne by you and not deducted from the purchase price for the purpose of calculating Hiive's transaction fee.<br/><br/> To see how the Hiive Transaction Fee is calculated, consult the <italic>Hiive Customer Terms & Conditions</italic>.`,
  select_a_role: `Select a role`,
  view_only: `View Only`,
  trader: `Trader`,
  account_users: `Account Users`,
  users: `Users`,
  invites: `Invites`,
  invite: `Invite`,
  invitation_resent_successfully: `Invitation resent successfully`,
  resend_invitation: `Resend Invitation`,
  cancel_invitation: `Cancel Invitation`,
  at_sale_close_disclaimer: `If this sale closes, at closing, you will owe Hiive a fee as set out in the`,
  terms_and_conditions: `Terms & Conditions`,
  message_description_from_hive: `Ask about things like information sharing, transaction structure, price flexibility and transaction approval. Hiive Security Specialist will reply as soon as possible.`,
  message_description_standing_bid: `Inquire about topics such as information sharing, transaction structure, price flexibility, and transaction approval.`,
  message_description_actor: `Inquire with the {{actor}} to ask about things like information sharing, transaction structure, price flexibility and transaction approval.`,
  no_completed_transfers: `No completed transfers.`,
  completion_date: `Completion date`,
  download: `Download`,
  view_deal: `View Deal`,
  attention_required: `Attention required`,
  update_required_suitability: `Update required: Suitability Questionnaire`,
  update_required_suitability_v3_individual: `Hiive is required by law to collect information about your investing knowledge and experience. Please take 2-3 minutes to update your profile. Click "Modify" to continue`,
  update_required_suitability_v3_institutional: `Hiive is required by law to collect information about your firm/fund's investing profile and experience. Please take 2-3 minutes to update the {{companyName}} profile. Click "Modify" to continue.`,
  update_required_individual: `To complete transactions, you must update your suitability questionnaire. To proceed, click "Modify."`,
  update_required_institutional: `To complete transactions, {{companyName}} must update its suitability questionnaire. To proceed, click "Modify."`,
  listing_preview: `Listing Preview`,
  account_information: `Account Information`,
  add_account: `Add Account`,
  hiive_mt_title: `Hiive uses Modern Treasury to facilitate payments.`,
  hiive_mt_subtitle: `Modern Treasury lets you securely connect your account in seconds.`,
  country: `Country`,
  country_of_account: `Country of Account`,
  account_details: `Account Details`,
  verify_account: `Verify Account`,
  country_account_title: `Where is your bank account located?`,
  country_account_description: `Please specify the country where your financial institution is based. It's important that the selected country is for the specific bank account and financial institution you intend to use for this transaction. If you do not see your desired country, please reach out to {{email}}.`,
  country_form_placeholder: `Select Country`,
  connect_your_account: `Connect Your Account`,
  back: `back`,
  back_button_text: `Back`,
  success: `Success`,
  mt_success_description: `Successfully submitted account details.`,
  mt_account_verification_title: `Next you will need to verify the account submitted`,
  mt_account_verification_description: `Confirm the amounts deposited into your account to verify. It may take up to 3 days for the amounts to be deposited and each amount should be less than $1.`,
  mt_account_verification_description_manual: `Someone from our execution team will be in touch with you to
  confirm the account information provided.`,
  connect_mt_account: `Connect MT Account`,
  verifying_account_information: `Verifying Account Information`,
  verifying_account_information_description: `Hiive will be in touch with you to verify the account information provided.`,
  verify_account_information_description: `Confirm the amounts deposited into your account to verify. It may take up to 3 days for the amounts to be deposited and each amount should be less than $1. If you are having issues contact {{email}}.`,
  submit: `Submit`,
  close: `Close`,
  deposit_one_value: `Deposit 1 Value`,
  deposit_one_error: `Deposit 1 is required`,
  deposit_two_value: `Deposit 2 Value`,
  deposit_two_error: `Deposit 2 is required`,
  deposit_max_error: `Amount should be under $1`,
  deposit_min_error: `Amount should be over $0`,
  money_input_placeholder: `$0.00`,
  verifying_account_information_error: `Incorrect Deposit Amounts Submitted`,
  verifying_account_information_error_description: `The deposit amounts entered are incorrect. Please try again or contact <bold>{{email}}</bold> for support with verifying your account.`,
  verification_cancelled: `Verification Cancelled`,
  verification_cancelled_description: `Contact <bold>{{email}}</bold> for support with verifying your account.`,
  verification_failed: `Verification Failed`,
  verification_failed_description: `Hiive will be in touch with you shortly. In the meantime, if you have any additional questions, please get in touch with <bold>{{email}}</bold>.`,
  attempts: `Attempts`,
  added: `Added`,
  last_used_on_transaction: `Last used on Transaction`,
  used_for_last_transaction: `Used for last Transaction`,
  add_new_account: `Add New Account`,
  use_account: `Use Account`,
  confirm_verified_account_description: `You've already linked an account with Hiive. Please choose an existing account or add a new one.`,
  select_account: `Select Account For Entity`,
  trading_for: `Trading for {{companyName}}`,
  company_security_specialist: `{{companyName}} Security Specialist`,
  contact_company_security_specialist: `Contact {{companyName}} Security Specialist`,
  account_successfully_verified: `Successfully Verified Account`,
  verified_account: `Verified Account`,
  modern_treasury_privacy_policy_disclaimer: `By clicking ‘add account’, you agree to Modern Treasury’s <italic>Privacy Policy</italic>.`,
  place_bid_description: `Make an offer on this listing at, below, or above the seller's ask.`,
  place_bid_confirmation: `Are you sure you want to place this bid for {{shares}} shares of {{companyName}} @ {{value}} per share, for a total bid value of {{total}}?`,
  gross_value_of_your_bid: `Gross value of your bid`,
  place_bid: `Place Bid`,
  bank_verification_attempts_info: `You have 5 attempts.`,
  informative_articles: `Informative Articles`,
  for_assistance: `For any assistance, contact Hiive`,
  something_went_wrong: `Something went wrong. Please try again later`,
  bid_fee_breakdown_disclaimer_text: `<bold>Note:</bold> the Hiive fee remains payable in the event
    that the issuer of the securities or another shareholder exercises a right
    of first refusal (ROFR) to purchase the stock from you instead of the buyer.
    The net price to you in this case will be the same as if you sold to the
    buyer.`,
  transaction_fee_breakdown_disclaimer_text: `<bold>Note:</bold> the Hiive fee remains payable in the event
    that the issuer of the securities or another shareholder exercises a right
    of first refusal (ROFR) to purchase the stock from you instead of the buyer.
    The net price to you in this case will be the same as if you sold to the
    buyer.`,
  my_holdings: `My Holdings`,
  add_holding: `Add Holding`,
  add_a_holding: `Add a Holding`,
  current_holdings_one: `You currently have {{count}} holding. <bold>You can add {{remaining}} more.</bold>`,
  current_holdings_other: `You currently have {{count}} holdings. <bold>You can add {{remaining}} more.</bold>`,
  add_holding_description: `Tell us about the stocks or options you hold. You can use Hiive to sell stock from, and monitor pricing data for, multiple private companies.`,
  unlisted_holding_needs_action: `This company is currently not available on Hiive. You will be notified once the company is ready to trade.`,
  shares_you_own: `Shares you own`,
  in_review: `In Review`,
  unavailable: `Unavailable`,
  search_company_name: `Search company name`,
  approximate_number_of_shares: `Approximate number of shares`,
  current_pricing: `Current Pricing`,
  about: `About`,
  your_activity: `Your Activity`,
  no_activity_for_company: `You currently have no activity for {{companyName}}.`,
  available_standing_bids: `Available Standing Bids`,
  other_sellers: `Other Sellers`,
  overview: `Overview`,
  list_your_shares_for_sale: `List Your Shares for Sale`,
  list_your_shares_for_sale_description: `Post your shares or options for sale anonymously. When creating
  a listing, you set your asking price, and potential buyers can
  make offers equal to or below your listed price. You can accept
  or make a counter-offer in response to any bid. Buyers can also
  contact you through a direct message to discuss your listing.`,
  list_your_shares_for_sale_description_alternative: `Post your shares or unexercised options for sale. By creating a listing, you can set your own asking price. A buyer may then make you an offer at or below your price. You can accept or make a counter-offer against any bid you receive. Buyers may also contact you via our messaging function to discuss your listing.`,
  create_listing: `Create Listing`,
  accept_standing_bid_to_sell: `Accept a Standing Bid to Sell Now`,
  accept_standing_bid_to_sell_description: `View buyers who have posted their interest in purchasing at a
  pre-set price, known as a 'standing bid'. Before
  accepting, you can message the buyer with any questions. You
  cannot counter-offer these bids, but you can message and ask the
  buyer to modify their bid to a price you are willing to accept.`,
  see_available_standing_bids: ` See Available Standing Bids`,
  hello: `Hello`,
  your_company_dashboard: `Your {{companyName}} Dashboard`,
  monitor_your_listing: `Monitor Your Listing and Associated Activity`,
  monitor_your_listing_description: `Use the activity sidebar to navigate the details of your listing and take action on accepting or finalizing a transaction.`,
  monitor_your_transactions: `Monitor your transactions and associated activity`,
  monitor_your_transactions_description: `Use the activity sidebar to browse your recent actions and progress a transaction. Choose to place your own listing by clicking “Create Listing."`,
  list_your_shares: `List Your Shares`,
  share_price: `Share Price`,
  summary_confirmation: `Summary & Confirmation`,
  how_to_think_about_pricing: `How to think about pricing your {{companyName}} shares`,
  how_to_think_about_pricing_description: `The first step in pricing your shares is to understand the price
  at which other buyers and sellers are seeking to trade the
  stock. You can view a summary of available bids and listings by
  going to the {{companyName}} Market Summary. From there you can input a potential price
  in this form and see how your price compares to the overall
  market.`,
  share_details: `Share details`,
  how_many_shares_wish_to_sell: `How many shares do you wish to sell?`,
  how_many_shares_wish_to_sell_disabled: `Wish to change the total or minimum number of shares? Please withdraw this listing and place a new one.`,
  at_what_price: `At what price per share? (USD)`,
  how_your_ask_price_compares: `How your ask price compares`,
  want_to_sell_now: `Want to sell now?`,
  sell_now_description: `Browse standing bids, which are offers made by potential buyers to buy
  up to a specific quantity of stock at a certain price. You can message
  the buyer with any questions. You cannot counter-offer standing bids,
  but you can ask the buyer to modify their price.`,
  see_other_buyers: `See Other Buyers`,
  active_bids: `Active Bids`,
  inquiries: `Inquiries`,
  standing_bids: `Standing Bids`,
  standing_bid: `Standing Bid`,
  transactions: `Transactions`,
  inquiries_on_standing_bids: `Inquiries on Standing Bids`,
  inquiries_on_standing_bids_description: `Click on a standing bid to view and continue a previously initiated
  conversation with a buyer.`,
  available_standing_bids_description: `Browse standing bids, which are offers made by potential buyers to
  buy up to a specific quantity of stock at a certain price. You can
  message the buyer with any questions. You cannot counter-offer
  standing bids, but you can ask the buyer to modify their price.`,
  inquiries_on_standing_bids_empty: `You are not engaged in any inquiries on standing bids.`,
  placed: `Placed`,
  your_pending_sales: `Your Pending Sales`,
  status_accepted_bids: `Status of your accepted bids.`,
  your_completed_sales: `Your Completed Sales`,
  history_of_transactions: `History of your executed transactions.`,
  accepted_bids_empty_state: `You have not accepted any bids.`,
  completed_sales_empty_state: `You have no executed transactions.`,
  click_on_bid_to_respond: `Click on the bid to review details and respond to the buyer.`,
  past_bids_description: `Bids you've received in the past. These bids are no longer
  active.`,
  click_on_inquiry_to_respond: `Click on the inquiry to open the message and respond.`,
  withdraw_listing: `Withdraw listing`,
  withdraw_listing_notifications: `If you withdraw this listing, we will cancel any bids on it
  and notify the corresponding bidders.`,
  withdraw_listing_after: `After you withdraw, we will redirect you to the original
  marketplace page where you first logged in, and you can
  create a new listing from there.`,
  withdraw: `Withdraw`,
  adjust_your_listing: `Adjust your listing`,
  listing_withdrawn: `Listing Withdrawn.`,
  modify_your_listing: `Modify Your Listing`,
  summary_and_confirmation: `Summary & Confirmation`,
  listing_modified: `Listing modified`,
  transfer_eligibility_requirements: `Transfer Eligibility Requirements for Sellers`,
  transfer_eligibility_requirements_v2: `There are transfer eligibility requirements for common stock sellers of {{companyName}}`,
  agreed_to_eligibility_requirements: `My listing meets the {{companyName}} transfer eligibility requirements.`,
  agreed_to_issuer_disclosure: `I understand that {{companyName}} is part of a formal Managed Market program on Hiive and as such Hiive may share my information upon {{companyName}}'s request.`,
  entity_active_label: `active`,
  accept_bid_buyer_terms_and_conditions: `Accepting this bid will constitute an Introduction Event under the <italic>Hiive Customer Terms and Conditions</italic>. The next step after this will be to execute an {{documentTitle}} with the seller.`,
  accept_bid_seller_terms_and_conditions: `Accepting this bid will constitute an Introduction Event under the <italic>Hiive Customer Terms and Conditions</italic>. The next step after this will be to execute an {{documentTitle}} with the buyer.`,
  accept_bid_seller_only_terms_and_conditions: `Accepting this bid will constitute an Introduction Event under the <italic>Hiive (Seller Only) Customer Terms and Conditions</italic>. The next step after this will be to execute an {{documentTitle}} with the buyer.`,
  unaccredited_seller_fee_disclaimer_terms_and_conditions: `To see how the Hiive Transaction Fee is calculated, consult the <italic>Hiive (Seller Only) Terms and Conditions.</italic>`,
  issuer_approval_declined: `Issuer approval Declined`,
  issuer_approval_declined_description: `The issuer has declined this transaction. While we see this happen in private markets, we will contact you if the issuer changes their stance. Please reach out to the execution@hiive.com if you have any questions.`,
  issuer_exercised_rofr: `Issuer exercised ROFR`,
  issuer_exercised_rofr_seller_description: `Congratulations your transaction has been approved! However, right of first refusal (ROFR) has been triggered for this transaction meaning a new party has been substituted as the buyer of your shares. Please note that you will still owe Hiive fees at the completion of this transaction as you agreed to in Hiive’s terms and conditions.`,
  issuer_exercised_rofr_buyer_description: `Right of first refusal (ROFR) has been triggered for this transaction. The party exercising its ROFR will therefore be substituted for you as the buyer in this transaction. A Hiive specialist will soon be in touch about possible next steps.`,
  auth_footer_text: `Securities are offered by Hiive Markets Limited, member <0>FINRA</0>/<1>SIPC</1>. Find Hiive on <2>BrokerCheck</2>.<br /><br />
  © The Hiive Company Limited {{currentYear}}. All rights reserved. By using this site, you accept our <3>Terms of Use</3> and <4>Privacy Policy</4>. Before engaging with this broker-dealer, review these important <5>Disclosures</5>.`,
  contact: `Contact`,
  contact_info: `Contact Info`,
  phone: `Phone`,
  update: `Update`,
  update_email: `Update email`,
  update_phone: `Update phone`,
  update_password: `Update password`,
  enter_email_address: `Enter email address`,
  email_change_success_message: `Submitted successfully! Please confirm your new email address to complete the update.`,
  email_change_error_message: `Error updating email.`,
  phone_change_success_message: `Updated successfully!`,
  phone_change_error_message: `Error updating phone number.`,
  password_change_error_message: `Error updating password.`,
  change_phone: `Change Phone`,
  change_password: `Change Password`,
  email_is_required: `Email is required`,
  email_is_invalid: `Email is invalid`,
  phone_number_is_required: `Phone number is required`,
  valid_phone_number: `valid phone number`,
  please_enter_a_valid_phone_number: `Please enter a valid phone number`,
  security: `Security`,
  login: `Login`,
  app_mfa_enrollment_header: `MFA Setup`,
  multifactor_authentication: `Multi-Factor Authentication`,
  authenticator_app: `Authenticator app`,
  setup_authenticator_app: `Setup authenticator app`,
  security_mfa_authenticator_app_action_card_sub_text: `Use an authenticator app such as Google Authenticator, Authy, etc.`,
  sms_text_message: `SMS/Text message`,
  security_mfa_sms_action_card_sub_text: `Receive verification codes via text messages.`,
  must_enroll_info: `Hiive users must enroll in MFA in order to access the trading platform or Back Office`,
  enroll: `Enroll`,
  enrolled: `Enrolled`,
  current_password: `Current Password`,
  current_password_is_required: `Current password is required`,
  password_is_required: `Password is required`,
  password_regex_error: `Must contain at least 8 characters, one uppercase, one lowercase, one number or punctuation character`,
  password_confirmation_is_required: `Password confirmation is required`,
  passwords_must_match: `Passwords must match`,
  new_password: `New Password`,
  confirm_new_password: `Confirm new password`,
  last_updated_at: `Last updated at {{date}}`,
  your_signature: `Your Signature`,
  counterpartys_signature: `Counterparty's Signature`,
  invalid_request: `Invalid request`,
  invalid_email_address: `Invalid email address`,
  no_mfa_device_found: `No MFA device found`,
  failed_to_enroll_mfa: `Failed to enroll MFA`,
  failed_to_disable_mfa: `Failed to disable MFA`,
  failed_to_get_mfa_devices: `Failed to get MFA devices`,
  failed_to_verify_mfa: `Failed to verify MFA`,
  trusted_contact_person: `Trusted Contact Person`,
  add_trusted_contact_person: `Would you like to add a <italic>trusted contact person</italic> to your account?`,
  add_trusted_contact_person_disclaimer: `A trusted contact person is an individual aged 18 or older who may be contacted by Hiive Markets Limited regarding your account. Providing a Trusted Contact Person is entirely optional and will not affect your ability to access and use Hiive's platform and services.`,
  add_trusted_contact_person_disclaimer_continued: `If you provide the name of a trusted contact person, you authorize Hiive to contact your trusted contact person and disclose information about your account to address possible financial exploitation, confirm the specifics of your current contact information, health status, or the identity of any legal guardian, executor, trustee or holder of a power of attorney, or as otherwise permitted by FINRA Rule 2165.`,
  relationship: `Relationship`,
  relationship_placeholder: `eg. Spouse, personal attorney, etc`,
  first_name_required: `First name is required`,
  last_name_required: `Last name is required`,
  enter_valid_phone_number: `Please enter a valid phone number`,
  phone_number_required: `Phone number is required`,
  email_required: `Email is required`,
  relationship_required: `Relationship required`,
  saved_trusted_contact_person: `Trusted contact person information saved`,
  updated_trusted_contact_person: `Trusted contact person information updated`,
  city: `City`,
  state: `State`,
  province: `Province`,
  zip_code: `Zip code`,
  postal_code: `Postal code`,
  pin_code: `Pin code`,
  legal_name: `Legal name`,
  job_title: `Job title`,
  jurisdiction: `Jurisdiction`,
  street_address: `Street address`,
  region: `Region`,
  trader_ca_first_paragraph: `I understand that the securities offered through the Hiive platform are
      private, unregistered securities, and that such securities are inherently
      illiquid and risky and that there is no guarantee that a market will be
      available for them. I understand that investment in these securities is
      appropriate only for those investors who can tolerate a high degree of
      risk, can withstand a total loss of investment, and do not require
      liquidity of their investment.`,
  trader_ca_second_paragraph: `I have read the <a1>Hiive Customer Terms & Conditions</a1> (the “Terms”), the <a2>Privacy Policy</a2>, and these <a3>Disclaimers & Disclosures</a3> , and understand that these documents, along with the terms below, govern
      my use of Hiive’s services and this platform and set out terms and
      conditions of Hiive’s provision of its services and this platform to me.`,
  trader_ca_third_paragraph: `I understand that I can access Hiive’s platform, browse bids and listings
      and pricing information, and post a listing, bid or standing bid, without
      paying anything to Hiive. I understand that if I sell stock to a buyer
      that I met through the platform or was otherwise introduced to me through
      Hiive or through the referral by another buyer on the platform, or to a
      different buyer substituted for that buyer by operation of a “right of
      first refusal” or similar pre-emptive right of an issuer or another
      shareholder, I will be responsible to pay a transaction fee to Hiive, as
      detailed in section 18 of the Terms.`,
  trader_ca_fourth_paragraph: `I understand that my activities on the Hiive platform, and my interactions
      with potential counterparties introduced by Hiive or through the Hiive
      platform, are to be kept confidential, and that I am not permitted to
      share the name of any potential counterparty with anyone else, including,
      for example, colleagues and friends who may hold the same kind of stock as
      I do, as detailed in sections 19 and 31 of the Terms.`,
  trader_ca_fifth_paragraph: `I understand that Hiive is not an investment advisor and does not and will
      not provide recommendations or advice to me. I understand that buying and
      selling stock through the Hiive platform is a self-directed activity, and
      that it is entirely up to me to determine what stocks to buy or sell, and
      at what volume and price. I understand that I am not obligated to sell or
      buy stock, until I sign a share transfer notice or other execution
      document with a counterparty.`,
  trader_ca_sixth_paragraph: `I understand that most private stock transactions are subject to the
      approval of the issuer (i.e. the company that issued the stock) and that
      some issuers will decline to approve proposed transactions, or some
      proposed transactions, and in some cases will do so without providing any
      reason for doing so.`,
  seller_ca_first_paragraph: `I have read the <a1>Hiive (Seller Only) Terms and Conditions</a1> (the “SO Terms”), the <a2>Privacy Policy</a2>, and these <a3>Disclaimers & Disclosures</a3> , and understand that these documents, along with the terms below, govern
      my use of Hiive’s services and this platform and set out terms and
      conditions of Hiive’s provision of its services and this platform to me.`,
  seller_ca_second_paragraph: `I understand that I can access Hiive's platform, review pricing
      information about the stock I hold or have the right to hold (“My Stock”),
      and post a listing for My Stock, without paying anything to Hiive. I
      understand that if I meet a buyer through the platform or Hiive or through
      the referral by another buyer on the platform, and close a transaction
      with that buyer, or a different buyer substituted for that buyer by
      operation of a “right of first refusal” or similar pre-emptive right of an
      issuer or another shareholder, I will be responsible to pay a transaction
      fee to Hiive, as detailed in section 11 of the SO Terms.`,
  seller_ca_third_paragraph: `I understand that my activities on Hiive, and my interactions with
      potential buyers on Hiive, are to be kept confidential, and that I am not
      permitted to share the name of any potential buyer with anyone else,
      including my colleagues and friends who may hold the same kind of stock,
      as detailed in section 12 of the SO Terms.`,
  seller_ca_fourth_paragraph: `I understand that Hiive is not an investment advisor and does not and will
      not provide recommendations or advice to me. I understand that selling
      stock through Hiive is a self-directed activity, and that it is entirely
      up to me to determine at what price to list, and ultimately sell my stock.
      I understand that I am not obligated to sell my stock, until I sign a
      share transfer notice with a buyer.`,
  seller_ca_fifth_paragraph: `I understand that most private stock transactions are subject to the
      approval of the issuer (i.e. the company that issued my stock) and that
      some issuers will decline to approve proposed transactions, or some
      proposed transactions, and in some cases will do so without providing any
      reason for doing so.`,
  seller_ca_sixth_paragraph: `By clicking the "agree" button below I agree to the above.`,
  broker_ca_first_paragraph: `I have read the <a1>HiiveConnect Terms and Conditions</a1>, the <a2>Privacy Policy</a2>, and these <a3>Disclaimers & Disclosures</a3> , and understand that these documents, along with the terms below, govern
      my use of Hiive Markets Limited's ("Hiive's") portal,
      "HiiveConnect", and interactions with registered representatives
      of Hiive.`,
  broker_ca_second_paragraph: ` I understand that I may use HiiveConnect only to submit bids, standing
      bids, or listings on behalf of buyer customers who have authorized or
      asked me to do so, and who have a serious and bona fide intention to
      transact on the terms that I am posting, and that I should not use
      HiiveConnect to submit bids, standing bids, or listings on behalf of other
      brokers or intermediaries.`,
  broker_ca_third_paragraph: `I understand that Hiive may provide me with Market Data through
      HiiveConnect, including price charts, historical pricing, last round
      values, and other information or data that is owned by Hiive and/or a
      third party, and that I may use this Market Data only in accordance with
      the HiiveConnect Terms of Use in order to facilitate transactions through
      HiiveConnect.`,
  updated_suitability: `Updated suitability questionnaire`,
  broker_dealer_information: `Broker-Dealer Information`,
  intermediary_information: `Intermediary Information`,
  dealer_intermediary_registration_number_optional: `Dealer/intermediary registration number (optional)`,
  number: `Number`,
  reason_for_your_answer: `Reason for your answer`,
  remove_authenticator_app: `Remove authenticator app`,
  remove_authenticator_app_description_with_code: `Please enter the six-digit code from your authenticator. <a>Can’t access authenticator?</a>`,
  remove_authenticator_app_description_without_code: `Are you sure you want to remove authenticator app? <a>Can’t access authenticator?</a>`,
  remove_sms: `Remove SMS authentication`,
  remove_sms_description_with_code: `Please enter the six-digit code sent to your phone number ending in {{lastFourDigits}}. <a>Can't access your phone?</a>`,
  remove_sms_description_without_code: `Are you sure you want to remove SMS authentication for phone number ending in {{lastFourDigits}}? <a>Can’t access your phone?</a>`,
  verify_and_remove: `Verify and remove`,
  code_required: `Code is required`,
  incorrect_code: `The code is incorrect. Please try again.`,
  account_recovery: `Account recovery`,
  account_recovery_description: `Please enter your recovery code. This action will remove all enrolled MFA methods from your account.`,
  account_recovery_help_text: `Need help? Contact <a>accounts@hiive.com</a>`,
  your_recovery_code: `Your recovery code`,
  recovery_code_is_required: `Recovery code is required`,
  remove_mfa: `Remove MFA`,
  removal_complete: `Removal complete`,
  remove_mfa_success_message: `MFA has been successfully removed. Please refresh the page.`,
  refresh_page: `Refresh page`,
  set_up_authenticator_app: `Set up authenticator app`,
  set_up_authenticator_app_description: `Please scan the QR code below with your mobile authenticator app (such as Google Authenticator or Authy) and enter the six-digit code to complete setup.`,
  verify: `Verify`,
  setup_complete: `Setup complete`,
  setup_complete_description_with_recovery_code: `You are successfully enrolled in {{method}}. Below is your recovery code. <bold>Please save this code somewhere safe.</bold> You can use your recovery code to recover your account in the event you lose your mobile device.`,
  setup_complete_description_without_recovery_code: `You are successfully enrolled in {{method}}. You can use your recovery code to recover your account in the event you lose your mobile device.`,
  please_refresh: `Please refresh the page to see your changes.`,
  your_recovery_code_bold: `<bold>Your recovery code<bold>`,
  done: `Done`,
  enrollment_error: `Enrollment error`,
  enrollment_error_description: `We're unable to process your MFA enrollment at this time. You can try enrolling again later from your account settings.`,
  close_mfa_setup: `Close MFA setup`,
  copied_to_clipboard: `Copied to clipboard`,
  mfa_removal_error: `MFA removal error`,
  mfa_removal_error_description: `We're unable to process your MFA removal at this time. You can try removing MFA again later from your account settings.`,
  we_are_required_by_law_to_verify_your_identity: `We are required by law to verify your identity and perform certain diligence including anti-money laundering checks.`,
  hiive_uses_persona_to_verify_your_identity: `Hiive uses Persona to verify your identity.`,
  this_process_will_take_about_2_minutes: `This process will take about 2 minutes.`,
  skip_for_now: `Skip for now`,
  thank_you_for_your_interest: `Thank you for your interest`,
  thank_you_for_your_interest_in_hiive: `Thank you for your interest in Hiive. We will be in touch with you shortly to discuss how we can work together.`,
  if_you_have_any_questions: `If you have any questions in the meantime, please reach out to us at: <mailto>support@hiive.com</mailto>`,
  if_you_are_not_a_broker_dealer: `<bold>If you are not a broker dealer, agent, or other intermediary acting on behalf of a third party</bold> you may return to the onboarding process.`,
  set_up_sms: `Setup SMS`,
  set_up_sms_description: `Please enter your phone number to receive a 6-digit verification code. Standard SMS rates may apply.`,
  enter_phone_number: `Enter phone number`,
  verify_phone_number: `Verify phone number`,
  verify_phone_number_description: `An SMS has been sent to {{phoneNumber}}. Please enter the code to complete set up.`,
  add_bank_account_for_entity: `Add the bank account for the entity, <bold>{{entityName}}.</bold>`,
  us_institution_accreditation_qib_description: `Qualified institutional buyer includes`,
  us_institution_accreditation_qib_description_bullet_1: `A specified institutional entity (e.g. insurance company, investment company, investment adviser), acting for its own account or the accounts of other QIBs, that in the aggregate owns and invests on a discretionary basis at least $100 million in securities of unaffiliated issuers.`,
  us_institution_accreditation_qib_description_bullet_2: `A registered dealer, acting for its own account or the accounts of other QIBs, that in the aggregate owns and invests on a discretionary basis at least $10 million of securities of unaffiliated issuers.`,
  us_institution_accreditation_qib_description_bullet_3: `Any registered investment company acting for its own account or for the accounts of other QIBs, that is part of a family of investment companies which own in the aggregate at least $100 million in securities of unaffiliated issuers.`,
  us_institution_accreditation_qib_description_bullet_4: `Any entity, all of the equity owners of which are QIBs, acting for its own account or the accounts of other QIBs.`,
  us_institution_accreditation_how_are_you_accredited: `How is your firm/fund accredited?`,
  send_new_code_in: `Send a new code in {{timeLeft}} seconds`,
  send_new_code: `Send a new code`,
  please_confirm_email_address: `Please confirm your email address`,
  ch_institution_client_type_title: `In order to participate in Hiive, an institution or entity must qualify as a <italic>Professional Client</italic> or an <italic>Institutional Client</italic> under FinSA. Which of the following describes your entity?`,
  ch_institution_client_type_description_1: `The entity is a <bold>Professional Client</bold>, for example public entities with professional treasury operations, occupational pension schemes and other occupational institutions providing professional treasury operations, companies with professional treasury operations, large companies (which exceed two of the following thresholds: a balance sheet of CHF 20 million, turnover of CHF 40 million or equity of CHF 2 million) and private investment structures with professional treasury operations established for high net worth individuals`,
  ch_institution_client_type_description_2: `The entity is an <bold>Institutional Client</bold>, for example, banks, fund management companies, authorised asset managers, insurance companies under the Insurance Supervision Act, central banks as well as national and supranational public entities with professional treasury operations`,
  ch_institution_client_type_disclaimer: `If the entity chooses to “opt in” or “opt out” to a different categorization than the one selected above, the entity must immediately notify Hiive of its decision to do so, and the entity may no longer be permitted to access the Hiive platform.`,
  ch_institution_professional_client_type: `Professional Client`,
  ch_institution_institutional_client_type: `Institutional Client`,
  ch_institution_confirmation_disclaimer_description_bullet_1: `The disclosure, documentation and accountability obligations under FinSA do not apply to the entity’s relationship with Hiive;`,
  ch_institution_confirmation_disclaimer_description_bullet_2: `Hiive does not carry out suitability or appropriateness tests in the provision of services to the entity;`,
  ch_institution_confirmation_disclaimer_description_bullet_3: `Hiive does not provide investment advice or make investment recommendations; and`,
  ch_institution_confirmation_disclaimer_description_bullet_4: `Hiive is not obliged to apply the transparency and due diligence obligations for client mandates in accordance with FinSA.`,
  uk_institution_accreditation_question_title: `In order to participate in the Hiive platform, an institution or entity located in the UK must qualify as an elective or “per se” professional client or an eligible counterparty. Which of the following describes your entity?`,
  uk_institution_not_mifid_description_bullet_1: `Has called up share capital or net assets of at least £5 million or equivalent;`,
  uk_institution_not_mifid_description_bullet_2: `Meets at least two of the following criteria: (i) a balance sheet total of EUR 12,500,000, (ii) a net turnover of EUR 25,000,000, (ii) an average number of employees during the year of 250;`,
  uk_institution_not_mifid_description_bullet_3: `Is a partnership or unincorporated association with net assets of at least £5 million or equivalent (in the case of a limited partnership this is calculated without deducting loans owing to any of the partners);`,
  uk_institution_not_mifid_description_bullet_4: `Is a trustee of a trust which has assets of at least £10 million or equivalent, calculated by aggregating the value of the cash and designated investments forming part of the trust’s assets, but before deducting liabilities;`,
  uk_institution_not_mifid_description_bullet_5: `Is a trustee of an occupational pension scheme or SSAS, or a trustee or operator of a personal pension scheme or stakeholder pension scheme where the scheme has (i) at least 50 members; and (ii) assets under management of at least £10 million or equivalent.`,
  uk_institution_confirmation_disclaimer_bullet_1: `The protections of the Financial Services and Markets Act (FSMA) will not apply to any investment activities that the entity may initiate through the Hiive platform.`,
  uk_institution_confirmation_disclaimer_bullet_2: `Hiive does not carry out suitability or appropriateness tests in the provision of services to the entity.`,
  uk_institution_confirmation_disclaimer_bullet_3: `Any transaction that results from the entity’s participation in the Hiive platform would not fall within the jurisdiction of any UK-regulated dispute resolution or compensation scheme.`,
  uk_institution_confirmation_disclaimer_bullet_4: `Hiive does not provide investment advice or make investment recommendations.`,
  in_institution_accreditation_title: `In order to participate in Hiive platform, your firm/fund must be an accredited investor or qualified institutional buyer. Which of the following describes your entity?`,
  other_institution_accreditation_description_1: `In order to access Hiive, your firm/fund must be permitted by, the laws of the jurisdiction in which it is located to buy and, sell unregistered private securities`,
  other_institution_accreditation_description_2: `These are securities for which no public disclosure or other, information is available. Investments in these securities are, very risky and illiquid. They are only appropriate for investors, with significant investment risk tolerance, no short-term, liquidity requirements, and extensive investment experience.`,
  other_institution_accreditation_input_label: `Please explain below how your firm/fund is qualified and permitted by law to participate in the purchase and sale of private unregistered securities through Hiive`,
  investor_investing_goals_page_title: `Investing Goals`,
  investor_investing_goals_page_description: `Please tell us about your investment goals.`,
  i_agree: `I agree`,
  request_updated_email_verification: `Verify Updated Email`,
  skip: `Skip`,
  customer_agreement_description: `Please read and agree to the Customer Agreement. The Customer Agreement references the Hiive Terms and Conditions which can be found <a>here</a>.`,
  acknowledgment_and_consent: `Acknowledgement and Consent`,
  by_selecting_agree_below: `By selecting “I agree” below, you are acknowledging as follows:`,
  acknowledgment_and_consent_list_item_1: `I am an accredited investor and wish to avail regulatory benefits linked to my accredited status.`,
  acknowledgment_and_consent_list_item_2: `I understand, and can bear, the financial risks associated with unregistered securities in private companies.`,
  acknowledgment_and_consent_list_item_3: `I am aware that I am expected to have the necessary knowledge or means to understand the features and risks associated with unregistered securities in private companies.`,
  acknowledgment_and_consent_list_item_4: `I am aware that unregistered securities in private companies may have a relaxed and flexible regulatory framework and may not be subject to the same regulatory oversight as retail/regular investment products.`,
  resend_email_verification: `Resend email verification`,
  your_email_verification_link_may_be_expired: `Your email verification link may be expired.`,
};

export default locale;
