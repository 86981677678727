import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  Json: any;
};

/** Accept invitation input */
export type AcceptInvitationInput = {
  /** Email */
  email: Scalars['String'];
  /** First name */
  firstName: Scalars['String'];
  /** Last name */
  lastName: Scalars['String'];
  /** Password for user */
  password: Scalars['String'];
  /** Password confirmation */
  passwordConfirmation: Scalars['String'];
  /** Phone number */
  phoneNumber: Scalars['String'];
  /** Time limited token */
  token: Scalars['String'];
};

export type AcceptStandingBidInput = {
  notifyRepresentingEmail?: Maybe<Scalars['Boolean']>;
  numShares: Scalars['Int'];
  otherListingDetails?: Maybe<Scalars['String']>;
  representedUser?: Maybe<RepresentedUserInput>;
  shareSeries: ShareSeries;
  transferMethod: StandingBidAcceptsTransferMethodsOptions;
};

export type AcceptStandingBidMutationResponse = {
  __typename?: 'AcceptStandingBidMutationResponse';
  bid?: Maybe<Bid>;
  errors?: Maybe<Array<InputError>>;
};

export type AccountCollectionFlow = {
  __typename?: 'AccountCollectionFlow';
  clientToken: Scalars['String'];
  counterpartyId: Scalars['String'];
};

/** An answer to an accreditation question */
export type AccreditationAnswer = {
  __typename?: 'AccreditationAnswer';
  institutionId?: Maybe<Scalars['ID']>;
  question: AccreditationQuestion;
  text?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type AccreditationAnswerFilter = {
  institutionId?: Maybe<Scalars['ID']>;
  latest?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['ID']>;
};

export type AccreditationAnswerInput = {
  /** The key of the option chosen */
  key: Scalars['String'];
  /** Additional text for the answer */
  text?: Maybe<Scalars['String']>;
};

/** An accreditation question */
export type AccreditationQuestion = {
  __typename?: 'AccreditationQuestion';
  accredited: Scalars['Boolean'];
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  text: Scalars['String'];
};

/** An accreditation question group */
export type AccreditationQuestionGroup = {
  __typename?: 'AccreditationQuestionGroup';
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  investorType: InvestorStatus;
  questions: Array<Maybe<AccreditationQuestion>>;
  version: Scalars['Int'];
};

/** Inputs for updating a broker information */
export type AddBrokerInfoInput = {
  brokerRegistrationNumber?: Maybe<Scalars['String']>;
  firmCity?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  firmPostalCode?: Maybe<Scalars['String']>;
  firmRegistrationNumber?: Maybe<Scalars['String']>;
  firmState?: Maybe<Scalars['String']>;
  firmStreetAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

/** Inputs for updating broker intermediary information */
export type AddBrokerIntermediaryInfoInput = {
  brokerRegistrationNumber?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  intermediaryNotes?: Maybe<Scalars['String']>;
  intermediaryPermission?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AddEntityInput = {
  /** a country_id */
  jurisdictionOfFormationId: Scalars['ID'];
  legalName: Scalars['String'];
  otherType?: Maybe<Scalars['String']>;
  /** optional link to a transaction entity is used on */
  transactionId?: Maybe<Scalars['ID']>;
  type: EntityType;
};

export type AddEntityResponse = {
  __typename?: 'AddEntityResponse';
  entity?: Maybe<Entity>;
  errors?: Maybe<Array<InputError>>;
};

export type AddHoldingInput = {
  companyId?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
  numShares?: Maybe<Scalars['Int']>;
};

/** Add holding response */
export type AddHoldingMutationResponse = {
  __typename?: 'AddHoldingMutationResponse';
  eitherHolding?: Maybe<EitherHolding>;
  errors?: Maybe<Array<InputError>>;
};

export type AddHoldingsInput = {
  holdings: Array<HoldingInput>;
};

export type AddHoldingsResponse = {
  __typename?: 'AddHoldingsResponse';
  errors?: Maybe<Array<InputError>>;
  holdings?: Maybe<Array<Holding>>;
};

export type AddHoldingsV2Input = {
  holdings: Array<HoldingV2Input>;
};

export type AddHoldingsV2Response = {
  __typename?: 'AddHoldingsV2Response';
  errors?: Maybe<Array<InputError>>;
  holdingsV2?: Maybe<Array<HoldingV2>>;
};

/** Object to represent a Transaction */
export type AdminTransaction = {
  __typename?: 'AdminTransaction';
  documentType?: Maybe<DocumentType>;
  id: Scalars['ID'];
  signingProcedure: ChangeTransactionTypeSigningProcedure;
};

export type AggregatePriceGraph = {
  __typename?: 'AggregatePriceGraph';
  avgHighestBid?: Maybe<Scalars['Int']>;
  avgLowestAsk?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['String']>;
  continuousHighestBid?: Maybe<Scalars['Int']>;
  continuousLowestAsk?: Maybe<Scalars['Int']>;
  highestBid?: Maybe<Scalars['Int']>;
  highestTransaction?: Maybe<Scalars['Int']>;
  indexPrice?: Maybe<Scalars['Int']>;
  lastTransaction?: Maybe<Scalars['Int']>;
  lowestAsk?: Maybe<Scalars['Int']>;
  priceChangeNinetyDays?: Maybe<Scalars['Decimal']>;
  priceChangeOneDay?: Maybe<Scalars['Decimal']>;
  priceChangeSevenDays?: Maybe<Scalars['Decimal']>;
  priceChangeSixtyDays?: Maybe<Scalars['Decimal']>;
  priceChangeThirtyDays?: Maybe<Scalars['Decimal']>;
  priceDay?: Maybe<Scalars['Date']>;
};

/** Description of an alternate signer for a document */
export type AlternateSigner = {
  __typename?: 'AlternateSigner';
  documentId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  institutionId?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  name: Scalars['String'];
  sender: VeryBasicUser;
  senderId: Scalars['ID'];
};

/** Input for answering accreditation questions */
export type AnswerAccreditationQuestionsInput = {
  answers: Array<AccreditationAnswerInput>;
  countryId?: Maybe<Scalars['ID']>;
};

/** A response to answering accreditations */
export type AnswerAccreditationResponse = {
  __typename?: 'AnswerAccreditationResponse';
  errors?: Maybe<Array<InputError>>;
  user?: Maybe<VeryBasicUser>;
};

/** Input for answering institution accreditation questions */
export type AnswerInstitutionAccreditationQuestionsInput = {
  answers: Array<AccreditationAnswerInput>;
};

/** A response to answering institution accreditations */
export type AnswerInstitutionAccreditationResponse = {
  __typename?: 'AnswerInstitutionAccreditationResponse';
  errors?: Maybe<Array<InputError>>;
  institution?: Maybe<Institution>;
};

/** A response to answering suitability */
export type AnswerSuitabilityResponse = {
  __typename?: 'AnswerSuitabilityResponse';
  errors?: Maybe<Array<InputError>>;
  user?: Maybe<User>;
};

export type ArchiveEntityInput = {
  entityId: Scalars['ID'];
};

export type ArchiveEntityResponse = {
  __typename?: 'ArchiveEntityResponse';
  entity?: Maybe<Entity>;
  errors?: Maybe<Array<InputError>>;
};

export enum AvailableTimeRanges {
  Max = 'MAX',
  OneYear = 'ONE_YEAR',
  SixMonths = 'SIX_MONTHS',
  ThreeMonths = 'THREE_MONTHS'
}

export type BankAccount = {
  __typename?: 'BankAccount';
  entityId?: Maybe<Scalars['ID']>;
  externalId: Scalars['String'];
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  last4: Scalars['String'];
  lastUsedTransaction?: Maybe<Transaction>;
  updatedAt: Scalars['DateTime'];
  verificationStatus: BankAccountVerificationStatus;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedById?: Maybe<Scalars['ID']>;
};

export enum BankAccountRequestStatus {
  Cancelled = 'CANCELLED',
  Collected = 'COLLECTED',
  NotRequested = 'NOT_REQUESTED',
  Requested = 'REQUESTED'
}

export enum BankAccountVerificationStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Manual = 'MANUAL',
  PendingVerification = 'PENDING_VERIFICATION',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

/** A bid made on a listing by a buyer */
export type Bid = {
  __typename?: 'Bid';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  actualNumSharesVisible: Scalars['Boolean'];
  /** Whether bid is placed on behalf of an insider or affiliate. Always false for non-broker users */
  affiliate?: Maybe<Scalars['Boolean']>;
  broker?: Maybe<User>;
  brokerCounterpartyId?: Maybe<Scalars['ID']>;
  brokerId?: Maybe<Scalars['ID']>;
  /** The buyer's email (only for superadmins or else returns null) */
  buyerEmail?: Maybe<Scalars['String']>;
  buyerId: Scalars['ID'];
  buyerInstitutionId?: Maybe<Scalars['ID']>;
  closedAt?: Maybe<Scalars['DateTime']>;
  commission?: Maybe<Commission>;
  company: Company;
  companyId: Scalars['ID'];
  counterBid?: Maybe<CounterBid>;
  /**
   * The num of shares of the counter bid
   * @deprecated Use counter_bid.num_shares instead
   */
  counterNumShares?: Maybe<Scalars['Int']>;
  /**
   * The price per share of the counter bid
   * @deprecated Use counter_bid.price_per_share instead
   */
  counterPricePerShare?: Maybe<Scalars['Int']>;
  counteredAt?: Maybe<Scalars['DateTime']>;
  counterpartyId: Scalars['ID'];
  counterpartyInstitutionId?: Maybe<Scalars['ID']>;
  discussion?: Maybe<Discussion>;
  displayId: Scalars['String'];
  expireAt?: Maybe<Scalars['DateTime']>;
  feeDiscountApplications?: Maybe<Array<FeeDiscountApplication>>;
  /** Whether the buyer is from Hiive */
  fromHiive: Scalars['Boolean'];
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  listing: Listing;
  /** Flag representing if the listing will fall below the minimum lot size on bid acceptance */
  listingBelowMinLotOnAcceptance: Scalars['Boolean'];
  listingId: Scalars['ID'];
  myPermissionsV2: Array<BidPermission>;
  numShares: Scalars['Int'];
  numSharesActual: Scalars['Int'];
  placedAt?: Maybe<Scalars['DateTime']>;
  pricePerShare: Scalars['Int'];
  rejectedAt?: Maybe<Scalars['DateTime']>;
  rejectionReason?: Maybe<RejectionReason>;
  representedEmail?: Maybe<Scalars['String']>;
  shortId: Scalars['String'];
  solicited?: Maybe<Scalars['Boolean']>;
  /** Returns the StandingBid, when a Bid was created as a result of accepting a StandingBid */
  standingBid?: Maybe<StandingBid>;
  standingBidId?: Maybe<Scalars['ID']>;
  state: BidState;
  supersededAt?: Maybe<Scalars['DateTime']>;
  transaction?: Maybe<Transaction>;
  updatedAt: Scalars['DateTime'];
  withdrawnAt?: Maybe<Scalars['DateTime']>;
};

export type BidMutationResponse = {
  __typename?: 'BidMutationResponse';
  bid?: Maybe<Bid>;
  errors?: Maybe<Array<InputError>>;
};

export enum BidPermission {
  AcceptBid = 'ACCEPT_BID',
  AcceptCounterBid = 'ACCEPT_COUNTER_BID',
  CounterBid = 'COUNTER_BID',
  ModifyBid = 'MODIFY_BID',
  StartDiscussion = 'START_DISCUSSION',
  WithdrawBid = 'WITHDRAW_BID'
}

export enum BidState {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Countered = 'COUNTERED',
  Expired = 'EXPIRED',
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED',
  Superseded = 'SUPERSEDED',
  Withdrawn = 'WITHDRAWN'
}

/** Broker information */
export type BrokerInfo = {
  __typename?: 'BrokerInfo';
  brokerId: Scalars['ID'];
  brokerRegistrationNumber?: Maybe<Scalars['String']>;
  firmCity?: Maybe<Scalars['String']>;
  firmName?: Maybe<Scalars['String']>;
  firmPostalCode?: Maybe<Scalars['String']>;
  firmRegistrationNumber?: Maybe<Scalars['String']>;
  firmState?: Maybe<Scalars['String']>;
  firmStreetAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intermediaryNotes?: Maybe<Scalars['String']>;
  intermediaryPermission?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
};

/** Add broker info response */
export type BrokerInfoMutationResponse = {
  __typename?: 'BrokerInfoMutationResponse';
  brokerInfo?: Maybe<BrokerInfo>;
  errors?: Maybe<Array<InputError>>;
};

export type BrokerSubmitBidInput = {
  affiliate: Scalars['Boolean'];
  listingId: Scalars['ID'];
  numShares: Scalars['Int'];
  pricePerShare: Scalars['Int'];
  solicited: Scalars['Boolean'];
  /** How soon should the bid expire? (in hours) */
  timeLimit?: Maybe<Scalars['Int']>;
};

export type BrokerSubmitBidMutationResponse = {
  __typename?: 'BrokerSubmitBidMutationResponse';
  bid?: Maybe<Bid>;
  errors?: Maybe<Array<InputError>>;
};

export type BuyerListingActivity = {
  __typename?: 'BuyerListingActivity';
  openBid?: Maybe<Bid>;
  pastBids: Array<Bid>;
  pendingPurchases: Array<Transaction>;
};

export type CaIndividualInvestmentGoalOptionInput = {
  /** Chosen option ID */
  optionId: Scalars['ID'];
  /** Custom input for the chosen option */
  text?: Maybe<Scalars['String']>;
};

export enum CaIsPermittedClientAnswer {
  DontKnow = 'DONT_KNOW',
  No = 'NO',
  Yes = 'YES'
}

export type ChangeTransactionTransferTypeInput = {
  signingProcedure: ChangeTransactionTypeSigningProcedure;
  transferMethod: ChangeTransactionTypeTransferMethod;
};

export type ChangeTransactionTransferTypeResponse = {
  __typename?: 'ChangeTransactionTransferTypeResponse';
  errors?: Maybe<Array<InputError>>;
  transaction?: Maybe<AdminTransaction>;
};

export enum ChangeTransactionTypeSigningProcedure {
  Automated = 'AUTOMATED',
  Manual = 'MANUAL'
}

export enum ChangeTransactionTypeTransferMethod {
  Direct = 'DIRECT',
  ForwardContract = 'FORWARD_CONTRACT'
}

export type ClientContext = {
  __typename?: 'ClientContext';
  currentActor: User;
  userNotificationToken?: Maybe<Scalars['String']>;
};

/** Description of a commission */
export type Commission = {
  __typename?: 'Commission';
  commissionAmount: Scalars['Int'];
  feeDiscountAmount?: Maybe<Scalars['Int']>;
  flatFeeAmount: Scalars['Int'];
  netFees: Scalars['Int'];
};

export type CommissionInfo = {
  __typename?: 'CommissionInfo';
  commissionAmount: Scalars['String'];
  feeDiscountAmount: Scalars['String'];
  feeDiscountApplications: Array<FeeDiscountApplication>;
  flatFeeAmount: Scalars['String'];
  netFees: Scalars['String'];
  netPricePerShare: Scalars['String'];
  netValue: Scalars['String'];
  totalValue: Scalars['String'];
};

export type CommissionStructure = {
  __typename?: 'CommissionStructure';
  bands: Array<CommissionStructureBand>;
  flatFeeAmount: Scalars['Int'];
};

export type CommissionStructureBand = {
  __typename?: 'CommissionStructureBand';
  lower?: Maybe<Scalars['Int']>;
  percentage: Scalars['Float'];
  upper?: Maybe<Scalars['Int']>;
};

/** Description of a company on the marketplace */
export type Company = {
  __typename?: 'Company';
  activeOnHiiveFiftyIndex: Scalars['Boolean'];
  activity: CompanyActivity;
  companyEligibilityCriterion?: Maybe<CompanyEligibilityCriterion>;
  country?: Maybe<Country>;
  /** Returns the current prices of a company */
  currentPrices?: Maybe<PriceGraph>;
  description?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  feeDiscountApplications: Array<FeeDiscountApplication>;
  hasActiveInformationDisclosure: Scalars['Boolean'];
  hasAskPriceComparisonChart: Scalars['Boolean'];
  id: Scalars['ID'];
  industries: Array<Industry>;
  insertedAt: Scalars['DateTime'];
  investors: Array<Investor>;
  lastRoundDate?: Maybe<Scalars['DateTime']>;
  lastRoundPostValuation?: Maybe<Scalars['Int']>;
  lastRoundPricePerShare?: Maybe<Scalars['Int']>;
  lastRoundRaised?: Maybe<Scalars['Int']>;
  lastRoundSeries?: Maybe<Scalars['String']>;
  legalName: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  maxPostPps?: Maybe<Scalars['Int']>;
  minPostPps?: Maybe<Scalars['Int']>;
  myPermissionsV2: Array<CompanyPermission>;
  name: Scalars['String'];
  numHolders: Scalars['Int'];
  numWatchers: Scalars['Int'];
  /** Returns the configuration options for a Company's price chart */
  priceChartConfig: PriceChartConfig;
  requiresApprovedBuyers: Scalars['Boolean'];
  requiresInformationDisclosure: Scalars['Boolean'];
  securitySpecialist?: Maybe<SecuritySpecialist>;
  securitySpecialistContact?: Maybe<Scalars['String']>;
  shortId: Scalars['String'];
  status: CompanyStatus;
  totalRaised?: Maybe<Scalars['Int']>;
  tradeable: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  watchlisted: Scalars['Boolean'];
};

export type CompanyActivity = {
  __typename?: 'CompanyActivity';
  availableStandingBids: Array<StandingBid>;
  myActiveDiscussions: Array<Discussion>;
  myBids: Array<Bid>;
  myCompletedTransactions: Array<Transaction>;
  myInstitutionBids: Array<Bid>;
  myInstitutionListings: Array<Listing>;
  myInstitutionStandingBids: Array<StandingBid>;
  myListings: Array<Listing>;
  myOpenTransactions: Array<Transaction>;
  myStandingBids: Array<StandingBid>;
  numOpenListings: Scalars['Int'];
  numOpenStandingBidsAndActiveBids: Scalars['Int'];
  othersListings: Array<Listing>;
  othersStandingBids: Array<StandingBid>;
  standingBidsWithDiscussions: Array<StandingBid>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges?: Maybe<Array<Maybe<CompanyEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Company>;
};

export type CompanyEligibilityCriterion = {
  __typename?: 'CompanyEligibilityCriterion';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export enum CompanyPermission {
  AddToWatchlist = 'ADD_TO_WATCHLIST',
  CreateListing = 'CREATE_LISTING',
  PlaceBid = 'PLACE_BID',
  PlaceStandingBid = 'PLACE_STANDING_BID',
  RemoveFromWatchlist = 'REMOVE_FROM_WATCHLIST'
}

export type CompanyPriceDataResponse = {
  __typename?: 'CompanyPriceDataResponse';
  dailyPriceData?: Maybe<Array<Maybe<DailyPriceData>>>;
  indexPriceTrends?: Maybe<Array<Maybe<IndexPriceTrend>>>;
};

export enum CompanyStatus {
  Delisted = 'DELISTED',
  Draft = 'DRAFT',
  Listed = 'LISTED'
}

export type CompleteExecutionTaskMutationResponse = {
  __typename?: 'CompleteExecutionTaskMutationResponse';
  errors?: Maybe<Array<InputError>>;
  task?: Maybe<ExecutionTask>;
};

export type Config = {
  __typename?: 'Config';
  requireSignupKey: Scalars['Boolean'];
  stnLoiHoursValidHours: Scalars['Int'];
};

/** A trusted contact person's info */
export type ContactInfo = {
  __typename?: 'ContactInfo';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  relationship: Scalars['String'];
};

/** Contact Info input */
export type ContactInfoInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  relationship: Scalars['String'];
};

/** A counter bid on a bid */
export type CounterBid = {
  __typename?: 'CounterBid';
  /** A commission relative to the num_shares and price_per_share defined in this schema */
  commission?: Maybe<Commission>;
  /** Fee discount applications, relative to the commission value defined in this schema */
  feeDiscountApplications?: Maybe<Array<FeeDiscountApplication>>;
  /** This is either a counter_num_shares or the original num_shares_actual */
  numShares: Scalars['Int'];
  /** This is either a counter_price_per_share or the original price_per_share */
  pricePerShare: Scalars['Int'];
};

export type CounterBidInput = {
  counterNumShares?: Maybe<Scalars['Int']>;
  counterPricePerShare?: Maybe<Scalars['Int']>;
};

export type Counterparty = {
  __typename?: 'Counterparty';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  institution?: Maybe<VeryBasicInstitution>;
  institutionId?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  name: Scalars['String'];
};

/** A country that Hiive supports */
export type Country = {
  __typename?: 'Country';
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Array<AccreditationQuestion>;
};

export type CreateBankAccountCollectionFlowResponse = {
  __typename?: 'CreateBankAccountCollectionFlowResponse';
  accountCollectionFlow: AccountCollectionFlow;
  errors?: Maybe<Array<InputError>>;
};

/** Create institution input */
export type CreateInstitutionInput = {
  /** Country id representing country where institution is located */
  countryId: Scalars['String'];
  /** Broker dealer, intermediary, or agent acting on behalf of an unrelated client */
  isBrokerDealer: Scalars['Boolean'];
  /** Legal name for institution */
  legalName: Scalars['String'];
};

/** Create institution input v2 */
export type CreateInstitutionInputV2 = {
  city: Scalars['String'];
  /** Country id representing Country where institution is located */
  countryId: Scalars['ID'];
  entityType?: Maybe<InstitutionEntityType>;
  /** Broker dealer, intermediary, or agent acting on behalf of an unrelated client */
  isBrokerDealer: Scalars['Boolean'];
  /** Country id of where the institution has jurisdiction */
  jurisdictionOfIncorporationId: Scalars['ID'];
  /** Legal name for institution */
  legalName: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
  streetAddress: Scalars['String'];
};

/** Create Trusted Contact Person input */
export type CreateTrustedContactPersonInput = {
  /** Optional map of contact info */
  contactInfo?: Maybe<ContactInfoInput>;
  /** Bool representing if contact info is provided */
  isContactProvided: Scalars['Boolean'];
};

export type DailyPriceData = {
  __typename?: 'DailyPriceData';
  acceptedBids: Array<Maybe<Scalars['Int']>>;
  acceptedBidsShareTypeCommon: Array<Maybe<Scalars['Int']>>;
  acceptedBidsShareTypePreferred: Array<Maybe<Scalars['Int']>>;
  acceptedBidsTransferTypeDirect: Array<Maybe<Scalars['Int']>>;
  acceptedBidsTransferTypeIndirect: Array<Maybe<Scalars['Int']>>;
  continuousHighestBid?: Maybe<Scalars['Int']>;
  continuousLowestAsk?: Maybe<Scalars['Int']>;
  day: Scalars['Date'];
  highestBid?: Maybe<Scalars['Int']>;
  indexPrice?: Maybe<Scalars['Int']>;
  indexPriceShareTypeCommon?: Maybe<Scalars['Int']>;
  indexPriceShareTypePreferred?: Maybe<Scalars['Int']>;
  indexPriceTransferTypeDirect?: Maybe<Scalars['Int']>;
  indexPriceTransferTypeIndirect?: Maybe<Scalars['Int']>;
  lastRoundPrice?: Maybe<Scalars['Int']>;
  lowestAsk?: Maybe<Scalars['Int']>;
  postedAsks: Array<Maybe<Scalars['Int']>>;
  postedAsksShareTypeCommon: Array<Maybe<Scalars['Int']>>;
  postedAsksShareTypePreferred: Array<Maybe<Scalars['Int']>>;
  postedAsksTransferTypeDirect: Array<Maybe<Scalars['Int']>>;
  postedAsksTransferTypeIndirect: Array<Maybe<Scalars['Int']>>;
  postedBids: Array<Maybe<Scalars['Int']>>;
  postedBidsShareTypeCommon: Array<Maybe<Scalars['Int']>>;
  postedBidsShareTypePreferred: Array<Maybe<Scalars['Int']>>;
  postedBidsTransferTypeDirect: Array<Maybe<Scalars['Int']>>;
  postedBidsTransferTypeIndirect: Array<Maybe<Scalars['Int']>>;
};




/** A discussion consists of a topic being discussed, and a list of members participating in the discussion. */
export type Discussion = {
  __typename?: 'Discussion';
  counterpartyHasReplied: Scalars['Boolean'];
  displayId: Scalars['String'];
  id: Scalars['ID'];
  initiatorId: Scalars['ID'];
  initiatorInstitutionId?: Maybe<Scalars['ID']>;
  members: Array<VeryBasicUser>;
  messages: Array<DiscussionMessage>;
  mostRecentMessage?: Maybe<DiscussionMessage>;
  /** Describes whether the current user is the viewer or a participant in the given discussion. */
  myMembershipKind: DiscussionMembershipKind;
  /**
   * Describes whether or not the currently logged in user has given their consent
   * to participate in the discussion.
   */
  myMembershipState: DiscussionMembershipState;
  myPermissionsV2: Array<DiscussionPermission>;
  numUnreadMessages: Scalars['Int'];
  shortId: Scalars['String'];
  /** Active whenever all of the members are active, otherwise pending. */
  state: DiscussionState;
  topic: DiscussionTopic;
  topicCompanyId: Scalars['ID'];
  totalNumMessages: Scalars['Int'];
};

export enum DiscussionMembershipKind {
  Participant = 'PARTICIPANT',
  Viewer = 'VIEWER'
}

export enum DiscussionMembershipState {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type DiscussionMessage = {
  __typename?: 'DiscussionMessage';
  discussion: Discussion;
  discussionId: Scalars['ID'];
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  messageBody: Scalars['String'];
  /** A list of users in the related discussion that have read this message. */
  readBy: Array<VeryBasicUser>;
  sender: VeryBasicUser;
  senderId: Scalars['ID'];
};

export type DiscussionMutationResponse = {
  __typename?: 'DiscussionMutationResponse';
  discussion: Discussion;
  errors?: Maybe<Array<InputError>>;
};

export enum DiscussionPermission {
  AcceptDiscussionRequest = 'ACCEPT_DISCUSSION_REQUEST',
  CloseDiscussion = 'CLOSE_DISCUSSION',
  RejectDiscussionRequest = 'REJECT_DISCUSSION_REQUEST',
  SendMessage = 'SEND_MESSAGE'
}

export enum DiscussionState {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Pending = 'PENDING'
}

/** The thing being discussed in a discussion */
export type DiscussionTopic = Listing | StandingBid;

/** Description of a document */
export type Document = {
  __typename?: 'Document';
  externalId: Scalars['String'];
  id: Scalars['ID'];
  invitedAlternateSigners: Array<AlternateSigner>;
  signers: Array<DocumentSigner>;
  status: DocumentStatus;
  type: DocumentType;
};

/** Description of a document signer */
export type DocumentSigner = {
  __typename?: 'DocumentSigner';
  alternateSigner?: Maybe<AlternateSigner>;
  alternateSignerId?: Maybe<Scalars['ID']>;
  documentId: Scalars['ID'];
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  institutionId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

export enum DocumentStatus {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Sent = 'SENT',
  Uploaded = 'UPLOADED',
  Voided = 'VOIDED'
}

export enum DocumentType {
  Loi = 'LOI',
  MembershipAgreement = 'MEMBERSHIP_AGREEMENT',
  Stn = 'STN'
}

/** Description of seller's holding that's either listed or unlisted */
export type EitherHolding = {
  __typename?: 'EitherHolding';
  companyId?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  numShares?: Maybe<Scalars['Int']>;
  sellerId: Scalars['ID'];
};

export type Entity = {
  __typename?: 'Entity';
  active: Scalars['Boolean'];
  bankAccounts: Array<BankAccount>;
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  jurisdictionOfFormation?: Maybe<Country>;
  legalName: Scalars['String'];
  otherType?: Maybe<Scalars['String']>;
  type: EntityType;
};

export enum EntityType {
  Corporation = 'CORPORATION',
  Individual = 'INDIVIDUAL',
  Llc = 'LLC',
  Other = 'OTHER',
  Partnership = 'PARTNERSHIP',
  Trust = 'TRUST'
}

/** The various kinds of input validation errors that can occur */
export enum ErrorType {
  Confirmation = 'CONFIRMATION',
  Exclusion = 'EXCLUSION',
  Format = 'FORMAT',
  Inclusion = 'INCLUSION',
  Length = 'LENGTH',
  Number = 'NUMBER',
  Other = 'OTHER',
  Required = 'REQUIRED',
  Subset = 'SUBSET',
  UnsafeUnique = 'UNSAFE_UNIQUE'
}

export type ExecutionAnvilConfig = {
  __typename?: 'ExecutionAnvilConfig';
  stnWeldId?: Maybe<Scalars['String']>;
};

export type ExecutionAnvilFillDocumentTaskData = {
  __typename?: 'ExecutionAnvilFillDocumentTaskData';
  embedUrl?: Maybe<Scalars['String']>;
};

export type ExecutionAnvilSignDocumentTaskData = {
  __typename?: 'ExecutionAnvilSignDocumentTaskData';
  anvilEtchSignUrl?: Maybe<Scalars['String']>;
  embedUrl?: Maybe<Scalars['String']>;
  signers: Array<ExecutionAnvilSignDocumentTaskDataSigner>;
};

export type ExecutionAnvilSignDocumentTaskDataSigner = {
  __typename?: 'ExecutionAnvilSignDocumentTaskDataSigner';
  actorType: ExecutionTaskActorType;
  signedAt?: Maybe<Scalars['DateTime']>;
  taskId: Scalars['ID'];
};

export type ExecutionAnvilV2Config = {
  __typename?: 'ExecutionAnvilV2Config';
  anvilWeldEid?: Maybe<Scalars['String']>;
};

export type ExecutionCondition = {
  __typename?: 'ExecutionCondition';
  condition: ExecutionWorkflowCondition;
  id: Scalars['ID'];
  stepId: Scalars['ID'];
  workflowId: Scalars['ID'];
};

export type ExecutionEntityCollectionConfig = {
  __typename?: 'ExecutionEntityCollectionConfig';
  actorType: ExecutionTaskActorType;
};

export type ExecutionIssuerApprovalConfig = {
  __typename?: 'ExecutionIssuerApprovalConfig';
  buyerCopy?: Maybe<Scalars['String']>;
  operationsCopy?: Maybe<Scalars['String']>;
  sellerCopy?: Maybe<Scalars['String']>;
};

export type ExecutionManualConfig = {
  __typename?: 'ExecutionManualConfig';
  buyerCopy?: Maybe<Scalars['String']>;
  operationsCopy?: Maybe<Scalars['String']>;
  sellerCopy?: Maybe<Scalars['String']>;
};

export enum ExecutionMilestone {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Complete = 'COMPLETE',
  Confirmed = 'CONFIRMED',
  Signed = 'SIGNED',
  Submitted = 'SUBMITTED'
}

export type ExecutionMilestoneCompletion = {
  __typename?: 'ExecutionMilestoneCompletion';
  completedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  milestone: ExecutionMilestone;
  workflowId: Scalars['ID'];
};

export type ExecutionMilestoneConfig = {
  __typename?: 'ExecutionMilestoneConfig';
  milestone: ExecutionMilestone;
};

export type ExecutionStep = {
  __typename?: 'ExecutionStep';
  config: ExecutionStepConfig;
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  instructions: ExecutionStepInstruction;
  name: Scalars['String'];
  status: ExecutionStepStatus;
  tasks: Array<ExecutionTask>;
  type: ExecutionStepType;
  visibility: Array<ExecutionStepVisibility>;
  workflowId: Scalars['ID'];
};

export type ExecutionStepConfig = ExecutionAnvilConfig | ExecutionAnvilV2Config | ExecutionEntityCollectionConfig | ExecutionIssuerApprovalConfig | ExecutionManualConfig | ExecutionMilestoneConfig;

export type ExecutionStepInstruction = {
  __typename?: 'ExecutionStepInstruction';
  buyer?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
};

export enum ExecutionStepStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Terminated = 'TERMINATED'
}

export enum ExecutionStepType {
  Anvil = 'ANVIL',
  AnvilV2 = 'ANVIL_V2',
  BankAccountCollection = 'BANK_ACCOUNT_COLLECTION',
  EntityCollection = 'ENTITY_COLLECTION',
  IssuerApproval = 'ISSUER_APPROVAL',
  Manual = 'MANUAL',
  Milestone = 'MILESTONE'
}

export enum ExecutionStepVisibility {
  Buyer = 'BUYER',
  Hiive = 'HIIVE',
  Issuer = 'ISSUER',
  Operations = 'OPERATIONS',
  Seller = 'SELLER'
}

export type ExecutionTask = {
  __typename?: 'ExecutionTask';
  actorType: ExecutionTaskActorType;
  completedAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<ExecutionTaskData>;
  id: Scalars['ID'];
  status: ExecutionTaskStatus;
  type: ExecutionTaskType;
};

export enum ExecutionTaskActorType {
  Buyer = 'BUYER',
  Hiive = 'HIIVE',
  Issuer = 'ISSUER',
  Operations = 'OPERATIONS',
  Seller = 'SELLER'
}

export type ExecutionTaskData = ExecutionAnvilFillDocumentTaskData | ExecutionAnvilSignDocumentTaskData;

export enum ExecutionTaskStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Terminated = 'TERMINATED'
}

export enum ExecutionTaskType {
  AnvilFillDocument = 'ANVIL_FILL_DOCUMENT',
  AnvilSignDocument = 'ANVIL_SIGN_DOCUMENT',
  CollectBankAccount = 'COLLECT_BANK_ACCOUNT',
  CollectEntity = 'COLLECT_ENTITY',
  ConfirmIssuerApproval = 'CONFIRM_ISSUER_APPROVAL',
  ManuallyConfirm = 'MANUALLY_CONFIRM',
  VerifyBankAccount = 'VERIFY_BANK_ACCOUNT'
}

export type ExecutionWorkflow = {
  __typename?: 'ExecutionWorkflow';
  conditions: Array<ExecutionCondition>;
  id: Scalars['ID'];
  milestoneCompletions: Array<ExecutionMilestoneCompletion>;
  milestoneOrder: Array<ExecutionMilestone>;
  status: ExecutionWorkflowStatus;
  steps: Array<ExecutionStep>;
  /** @deprecated Do not use this field, it will be removed in the future */
  structure: ExecutionWorkflowStructure;
};

export enum ExecutionWorkflowCondition {
  IssuerApprovalDeclined = 'ISSUER_APPROVAL_DECLINED',
  Rofr = 'ROFR'
}

export enum ExecutionWorkflowStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export enum ExecutionWorkflowStructure {
  Direct = 'DIRECT',
  Indirect = 'INDIRECT'
}

export type ExternalAccount = {
  __typename?: 'ExternalAccount';
  counterpartyId: Scalars['String'];
  id: Scalars['String'];
  verificationStatus: Scalars['String'];
};

export type ExternalAccountResponse = {
  __typename?: 'ExternalAccountResponse';
  errors?: Maybe<Array<InputError>>;
  externalAccount?: Maybe<ExternalAccount>;
};

export type FeeDiscount = {
  __typename?: 'FeeDiscount';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FeeDiscountType;
  value: Scalars['Int'];
};

export type FeeDiscountApplication = {
  __typename?: 'FeeDiscountApplication';
  company?: Maybe<Company>;
  feeDiscount: FeeDiscount;
  feeDiscountApplicationCommission?: Maybe<FeeDiscountApplicationCommission>;
  id: Scalars['ID'];
  initial: Scalars['Boolean'];
  state: FeeDiscountApplicationState;
  transactionModification?: Maybe<TransactionModification>;
  /** @deprecated Use transaction_modification.id instead */
  transactionModificationId?: Maybe<Scalars['ID']>;
};

export type FeeDiscountApplicationCommission = {
  __typename?: 'FeeDiscountApplicationCommission';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

/** Fee Discount application state */
export enum FeeDiscountApplicationState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

/** Fee Discount types in back office */
export enum FeeDiscountType {
  FlatFee = 'FLAT_FEE',
  OverrideCommission = 'OVERRIDE_COMMISSION',
  PercentageFee = 'PERCENTAGE_FEE'
}

export type FileUpload = {
  __typename?: 'FileUpload';
  contentType: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  institutionId?: Maybe<Scalars['ID']>;
  s3ObjectRef: Scalars['String'];
  uploaderId: Scalars['ID'];
  /** A time-limited pre-signed url to access the file */
  url: Scalars['String'];
};

export type FileUploadInput = {
  contentType: Scalars['String'];
  filename: Scalars['String'];
  s3ObjectRef: Scalars['String'];
};

export type FileUploadMutationResponse = {
  __typename?: 'FileUploadMutationResponse';
  errors?: Maybe<Array<InputError>>;
  fileUpload?: Maybe<FileUpload>;
};

export type FormField = {
  __typename?: 'FormField';
  /** Name of the form field */
  name: Scalars['String'];
  /** Value of the form field */
  value: Scalars['String'];
};

export type GenerateS3UploadPolicyResponse = {
  __typename?: 'GenerateS3UploadPolicyResponse';
  /** Name/value pairs to be set in a form in addition to the `file` field to send in a POST upload request to the given Amazon S3 bucket URL */
  formFields: Array<FormField>;
  /** Object key (or key name) that uniquely identifies the object in the Amazon S3 bucket */
  objectKey: Scalars['String'];
  /** Amazon S3 bucket URL */
  url: Scalars['String'];
};

export type GraphIndicators = {
  __typename?: 'GraphIndicators';
  acceptedBids: Scalars['Boolean'];
  lastRoundPps: Scalars['Boolean'];
  postedBidsAsks: Scalars['Boolean'];
};

export type Hiive50DailyIndexPriceResponse = {
  __typename?: 'Hiive50DailyIndexPriceResponse';
  currentPrice?: Maybe<Scalars['Decimal']>;
  /** Presented as a UI ready percentage (0-100) */
  percentageChange?: Maybe<Scalars['Float']>;
  prices?: Maybe<Array<Hiive50IndexPrice>>;
};

export type Hiive50IndexPrice = {
  __typename?: 'Hiive50IndexPrice';
  avg?: Maybe<Scalars['Decimal']>;
  priceDay?: Maybe<Scalars['Date']>;
};

export enum Hiive50IndexPriceDateRange {
  Max = 'MAX',
  OneYear = 'ONE_YEAR',
  SixMonths = 'SIX_MONTHS',
  ThreeMonths = 'THREE_MONTHS'
}

export type Hiive50IndexPriceFilter = {
  range: Hiive50IndexPriceDateRange;
};

/** Description of seller's holding */
export type Holding = {
  __typename?: 'Holding';
  company: Company;
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  numSharesV2?: Maybe<Scalars['Int']>;
  sellerId: Scalars['ID'];
};

export type HoldingInput = {
  /** The id of the company for which the holding is for */
  companyId: Scalars['ID'];
  /** The number of shares in the holding */
  numShares?: Maybe<Scalars['Int']>;
};

/** Holding types */
export enum HoldingType {
  Listed = 'LISTED',
  Unlisted = 'UNLISTED'
}

/** Description of the seller's holding. Can be Listed or Unlisted */
export type HoldingV2 = Holding | UnlistedHolding;

export type HoldingV2Input = {
  /** The number of shares in the holding */
  numShares?: Maybe<Scalars['Int']>;
  type: HoldingType;
  value: Scalars['String'];
};

export enum IdentityVerificationStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  NeedsReview = 'NEEDS_REVIEW',
  Pending = 'PENDING'
}

/** Index company on the client */
export type IndexCompany = {
  __typename?: 'IndexCompany';
  company: Company;
  id: Scalars['ID'];
  /** A list of 90 index prices sorted in ascending order by `price_day`. Used to generate a sparkline. */
  sortedIndexPrices: Array<Scalars['Int']>;
  status: Status;
  todaysPriceGraph?: Maybe<AggregatePriceGraph>;
};

export type IndexCompanyConnection = {
  __typename?: 'IndexCompanyConnection';
  edges: Array<IndexCompanyEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type IndexCompanyEdge = {
  __typename?: 'IndexCompanyEdge';
  cursor?: Maybe<Scalars['String']>;
  node: IndexCompany;
};

export type IndexCompanySortBy = {
  direction: SortDirection;
  field: IndexCompanySortByField;
};

export enum IndexCompanySortByField {
  EndingDate = 'ENDING_DATE',
  Name = 'NAME',
  PostingsCount = 'POSTINGS_COUNT',
  StartingDate = 'STARTING_DATE'
}

export enum IndexPriceIndicator {
  IndexPrice = 'INDEX_PRICE',
  IndexPriceShareTypeCommon = 'INDEX_PRICE_SHARE_TYPE_COMMON',
  IndexPriceShareTypePreferred = 'INDEX_PRICE_SHARE_TYPE_PREFERRED',
  IndexPriceTransferTypeDirect = 'INDEX_PRICE_TRANSFER_TYPE_DIRECT',
  IndexPriceTransferTypeIndirect = 'INDEX_PRICE_TRANSFER_TYPE_INDIRECT'
}

export type IndexPriceTrend = {
  __typename?: 'IndexPriceTrend';
  changePercentage?: Maybe<Scalars['Float']>;
  currentPrice?: Maybe<Scalars['Int']>;
  trendName: IndexPriceIndicator;
};

export enum Indicator {
  AcceptedBids = 'ACCEPTED_BIDS',
  AcceptedBidsShareTypeCommon = 'ACCEPTED_BIDS_SHARE_TYPE_COMMON',
  AcceptedBidsShareTypePreferred = 'ACCEPTED_BIDS_SHARE_TYPE_PREFERRED',
  AcceptedBidsTransferTypeDirect = 'ACCEPTED_BIDS_TRANSFER_TYPE_DIRECT',
  AcceptedBidsTransferTypeIndirect = 'ACCEPTED_BIDS_TRANSFER_TYPE_INDIRECT',
  IndexPrice = 'INDEX_PRICE',
  IndexPriceShareTypeCommon = 'INDEX_PRICE_SHARE_TYPE_COMMON',
  IndexPriceShareTypePreferred = 'INDEX_PRICE_SHARE_TYPE_PREFERRED',
  IndexPriceTransferTypeDirect = 'INDEX_PRICE_TRANSFER_TYPE_DIRECT',
  IndexPriceTransferTypeIndirect = 'INDEX_PRICE_TRANSFER_TYPE_INDIRECT',
  LastRoundPrice = 'LAST_ROUND_PRICE',
  LowestAskHighestBidTrend = 'LOWEST_ASK_HIGHEST_BID_TREND',
  LowestAskHighestBidTrendShareTypeCommon = 'LOWEST_ASK_HIGHEST_BID_TREND_SHARE_TYPE_COMMON',
  LowestAskHighestBidTrendShareTypePreferred = 'LOWEST_ASK_HIGHEST_BID_TREND_SHARE_TYPE_PREFERRED',
  LowestAskHighestBidTrendTransferTypeDirect = 'LOWEST_ASK_HIGHEST_BID_TREND_TRANSFER_TYPE_DIRECT',
  LowestAskHighestBidTrendTransferTypeIndirect = 'LOWEST_ASK_HIGHEST_BID_TREND_TRANSFER_TYPE_INDIRECT',
  PostedBidsAsks = 'POSTED_BIDS_ASKS',
  PostedBidsAsksShareTypeCommon = 'POSTED_BIDS_ASKS_SHARE_TYPE_COMMON',
  PostedBidsAsksShareTypePreferred = 'POSTED_BIDS_ASKS_SHARE_TYPE_PREFERRED',
  PostedBidsAsksTransferTypeDirect = 'POSTED_BIDS_ASKS_TRANSFER_TYPE_DIRECT',
  PostedBidsAsksTransferTypeIndirect = 'POSTED_BIDS_ASKS_TRANSFER_TYPE_INDIRECT'
}

/** Description of an industry */
export type Industry = {
  __typename?: 'Industry';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InputError = {
  __typename?: 'InputError';
  /** The originating field for the error */
  field: Scalars['String'];
  /** A description of the error */
  message: Scalars['String'];
  /** Validation type */
  type?: Maybe<ErrorType>;
};

/** An institution */
export type Institution = {
  __typename?: 'Institution';
  accreditationAnswers: Array<AccreditationAnswer>;
  accreditationAnswersV2: Array<AccreditationAnswer>;
  accreditationLastUpdatedAt?: Maybe<Scalars['DateTime']>;
  accreditationQuestions: Array<AccreditationQuestion>;
  accredited: Scalars['Boolean'];
  approved: Scalars['Boolean'];
  caAgreedToInvestmentRisk?: Maybe<Scalars['Boolean']>;
  caIsPermittedClient?: Maybe<CaIsPermittedClientAnswer>;
  caJurisdictionOfIncorporation?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  complianceEmail?: Maybe<Scalars['String']>;
  country: Country;
  entities: Array<Entity>;
  entityType?: Maybe<InstitutionEntityType>;
  governmentDocument?: Maybe<FileUpload>;
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  /** @deprecated This field is deprecated and will be removed in a future release. Please use `suitabilityAnswers` instead. */
  investmentGoalAnswers: Array<InvestmentGoalAnswer>;
  /** @deprecated Removed - use investment_goal_answers instead */
  investmentGoalOptions: Array<InvestmentGoalOption>;
  isBrokerDealer: Scalars['Boolean'];
  isFinraInstitutionAccount?: Maybe<Scalars['Boolean']>;
  jurisdictionOfIncorporation?: Maybe<Country>;
  legalName: Scalars['String'];
  legalSignatoryEmail?: Maybe<Scalars['String']>;
  legalSignatoryName?: Maybe<Scalars['String']>;
  membershipAgreementDocument?: Maybe<Document>;
  membershipAgreementSigned: Scalars['Boolean'];
  myPermissionsV2: Array<InstitutionPermission>;
  onboardingComplete: Scalars['Boolean'];
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  registeredBy: VeryBasicUser;
  streetAddress?: Maybe<Scalars['String']>;
  suitabilityAnswers: Array<SuitabilityAnswer>;
  suitabilityLastUpdatedAt?: Maybe<Scalars['DateTime']>;
  suitable?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
};


/** An institution */
export type InstitutionAccreditationAnswersArgs = {
  filterBy?: Maybe<AccreditationAnswerFilter>;
};


/** An institution */
export type InstitutionAccreditationAnswersV2Args = {
  filterBy?: Maybe<AccreditationAnswerFilter>;
};


/** An institution */
export type InstitutionInvestmentGoalAnswersArgs = {
  filterBy?: Maybe<InvestmentGoalAnswerFilter>;
};


/** An institution */
export type InstitutionSuitabilityAnswersArgs = {
  filterBy?: Maybe<SuitabilityAnswerFilter>;
};

export enum InstitutionEntityType {
  Corporation = 'CORPORATION',
  Llc = 'LLC',
  Other = 'OTHER',
  Partnership = 'PARTNERSHIP'
}

/** Institution response, but with errors */
export type InstitutionMutationResponse = {
  __typename?: 'InstitutionMutationResponse';
  errors?: Maybe<Array<InputError>>;
  institution?: Maybe<Institution>;
};

export enum InstitutionPermission {
  CanUpdateAccreditation = 'CAN_UPDATE_ACCREDITATION',
  CanUpdateSuitability = 'CAN_UPDATE_SUITABILITY',
  InstitutionInvitations = 'INSTITUTION_INVITATIONS',
  InstitutionUsers = 'INSTITUTION_USERS',
  LinkInstitutionGovernmentDocument = 'LINK_INSTITUTION_GOVERNMENT_DOCUMENT',
  UpdateInstitution = 'UPDATE_INSTITUTION',
  UpdateInstitutionComplianceEmail = 'UPDATE_INSTITUTION_COMPLIANCE_EMAIL'
}

/** An Investing Goal answer */
export type InvestingGoalAnswer = {
  __typename?: 'InvestingGoalAnswer';
  option: InvestingGoalOption;
  optionId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Investing Goal Answer input */
export type InvestingGoalAnswerInput = {
  /** User selected Option ids */
  optionIds?: Maybe<Array<Scalars['ID']>>;
};

/** An Investing Goal answer response, with errors */
export type InvestingGoalAnswerMutationResponse = {
  __typename?: 'InvestingGoalAnswerMutationResponse';
  errors?: Maybe<Array<InputError>>;
  investingGoalAnswers: Array<Maybe<InvestingGoalAnswer>>;
};

/** An Investing Goal Question Option */
export type InvestingGoalOption = {
  __typename?: 'InvestingGoalOption';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  questionId: Scalars['ID'];
  text: Scalars['String'];
};

/** An Investing Goal Question */
export type InvestingGoalQuestion = {
  __typename?: 'InvestingGoalQuestion';
  id?: Maybe<Scalars['ID']>;
  options?: Maybe<Array<Maybe<InvestingGoalOption>>>;
  order?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

/** An Investing Goal Question Group */
export type InvestingGoalQuestionGroup = {
  __typename?: 'InvestingGoalQuestionGroup';
  country: Country;
  id: Scalars['ID'];
  investorStatus: InvestorStatus;
  questions: Array<InvestingGoalQuestion>;
  version: Scalars['Int'];
};

/** An Investment Goal Answer */
export type InvestmentGoalAnswer = {
  __typename?: 'InvestmentGoalAnswer';
  id: Scalars['ID'];
  institutionId?: Maybe<Scalars['ID']>;
  option: InvestmentGoalOption;
  revision?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type InvestmentGoalAnswerFilter = {
  institutionId?: Maybe<Scalars['ID']>;
  latest?: Maybe<Scalars['Boolean']>;
  revision?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
};

/** investment goal answer input */
export type InvestmentGoalAnswerInput = {
  /** The key of the option chosen */
  key: Scalars['String'];
  /** The question key being answered */
  questionKey: Scalars['String'];
};

/** A response to checking if a user's investment goal answers are current */
export type InvestmentGoalAnswersCurrentResponse = {
  __typename?: 'InvestmentGoalAnswersCurrentResponse';
  current?: Maybe<Scalars['Boolean']>;
};

/** An Investment Goal Option */
export type InvestmentGoalOption = {
  __typename?: 'InvestmentGoalOption';
  custom?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  nextQuestionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  order: Scalars['Int'];
  question: InvestmentGoalQuestion;
  text?: Maybe<Scalars['String']>;
};

/** An Investment Goal Question */
export type InvestmentGoalQuestion = {
  __typename?: 'InvestmentGoalQuestion';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  options: Array<Maybe<InvestmentGoalOption>>;
  order: Scalars['Int'];
  text: Scalars['String'];
};

export type InvestmentGoalQuestionGroup = {
  __typename?: 'InvestmentGoalQuestionGroup';
  country: Scalars['String'];
  id: Scalars['ID'];
  investorType: InvestorStatus;
  questions: Array<Maybe<InvestmentGoalQuestion>>;
  version: Scalars['Int'];
};

/** A response to answering investment goals */
export type InvestmentGoalsResponse = {
  __typename?: 'InvestmentGoalsResponse';
  errors?: Maybe<Array<InputError>>;
  user?: Maybe<VeryBasicUser>;
};

/** Description of an investor */
export type Investor = {
  __typename?: 'Investor';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum InvestorStatus {
  Individual = 'INDIVIDUAL',
  Institutional = 'INSTITUTIONAL'
}

export enum InvestorType {
  Broker = 'BROKER',
  Seller = 'SELLER',
  Trader = 'TRADER',
  UnaccreditedSeller = 'UNACCREDITED_SELLER'
}

/** An invitation for a new user to join an institution */
export type Invitation = {
  __typename?: 'Invitation';
  email: Scalars['String'];
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  institutionId: Scalars['String'];
  inviteeId?: Maybe<Scalars['String']>;
  roles: Array<RoleType>;
  status: InvitationStatus;
  timeLimitedTokenId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

/** Invitation response, but with errors */
export type InvitationMutationResponse = {
  __typename?: 'InvitationMutationResponse';
  errors?: Maybe<Array<InputError>>;
  invitation?: Maybe<Invitation>;
};

export enum InvitationStatus {
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

/** Input for inviting an alternate signer to a document */
export type InviteAlternateSignerInput = {
  documentId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  institutionId?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
};

/** A response to inviting an alternate signer */
export type InviteAlternateSignerResponse = {
  __typename?: 'InviteAlternateSignerResponse';
  alternateSigner?: Maybe<AlternateSigner>;
  errors?: Maybe<Array<InputError>>;
};


export type LastRoundPostValuationRange = {
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type LinkEntityInput = {
  entityId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type LinkEntityResponse = {
  __typename?: 'LinkEntityResponse';
  errors?: Maybe<Array<InputError>>;
  transaction?: Maybe<Transaction>;
};

export enum ListCompaniesOrderBy {
  Alphabetically = 'ALPHABETICALLY',
  LastRoundValue = 'LAST_ROUND_VALUE',
  MarketActivity = 'MARKET_ACTIVITY',
  NumberOfBids = 'NUMBER_OF_BIDS',
  NumberOfListings = 'NUMBER_OF_LISTINGS'
}

/** Description of shares held by a seller */
export type Listing = {
  __typename?: 'Listing';
  acceptPartialBid: Scalars['Boolean'];
  /** Returns a list of all accepted bid price per share values */
  acceptedBidPrices: Array<Scalars['Int']>;
  actualNumSharesVisible: Scalars['Boolean'];
  bids: Array<Bid>;
  broker?: Maybe<User>;
  brokerId?: Maybe<Scalars['ID']>;
  commission?: Maybe<VirtualCommission>;
  company: Company;
  companyId: Scalars['ID'];
  displayId: Scalars['String'];
  expireAt?: Maybe<Scalars['DateTime']>;
  feeDiscountApplications?: Maybe<Array<FeeDiscountApplication>>;
  /** Whether the seller is from Hiive */
  fromHiive: Scalars['Boolean'];
  /** Returns true if the currently logged in user has a bid on this listing */
  hasMyBid: Scalars['Boolean'];
  hasRequestedDiscussion: Scalars['Boolean'];
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  isHiiveSpv: Scalars['Boolean'];
  /** Share price in cents */
  listingPricePerShare?: Maybe<Scalars['Int']>;
  minPartialBidNumShares?: Maybe<Scalars['Int']>;
  myPermissionsV2: Array<ListingPermission>;
  /** The number of active bids */
  numActiveBids: Scalars['Int'];
  /** The number of countered bids */
  numCounteredBids: Scalars['Int'];
  numDiscussions: Scalars['Int'];
  /** The number of inquiries (an inquiry is any discussion not including discussions on bids) */
  numInquiries: Scalars['Int'];
  numSharesAvailable: Scalars['Int'];
  numSharesAvailableRounded: Scalars['Int'];
  /** The number of shares listed for sale */
  numSharesOriginal: Scalars['Int'];
  numSharesOriginalRounded: Scalars['Int'];
  numSharesSoldConditionally?: Maybe<Scalars['Int']>;
  numUnreadMessages: Scalars['Int'];
  otherDetails?: Maybe<Scalars['String']>;
  placedAt?: Maybe<Scalars['DateTime']>;
  representedEmail?: Maybe<Scalars['String']>;
  reviewNote?: Maybe<Scalars['String']>;
  /** The seller's email (only for superadmins or else returns null) */
  sellerEmail?: Maybe<Scalars['String']>;
  sellerId: Scalars['ID'];
  sellerInstitutionId?: Maybe<Scalars['ID']>;
  shareSeriesMakeup: Array<ShareSeriesMakeup>;
  /** @deprecated Use share_type_v2 field */
  shareType: ShareType;
  shareTypeV2: ShareTypeV2;
  shortId: Scalars['String'];
  signingProcedure: SigningProcedure;
  solicited: Scalars['Boolean'];
  state: ListingState;
  transferMethod: TransferMethod;
  updatedAt: Scalars['DateTime'];
  version: Scalars['Int'];
  withdrawnAt?: Maybe<Scalars['DateTime']>;
};

export type ListingInput = {
  affiliate?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  expireAt?: Maybe<Scalars['DateTime']>;
  muteNotifyWatchers?: Maybe<Scalars['Boolean']>;
  notifyRepresentingEmail?: Maybe<Scalars['Boolean']>;
  /** The number of shares to list for sale */
  numShares?: Maybe<Scalars['Int']>;
  otherDetails?: Maybe<Scalars['String']>;
  /** Share price in cents */
  pricePerShare?: Maybe<Scalars['Int']>;
  representedUser?: Maybe<RepresentedUserInput>;
  shareSeriesMakeup?: Array<ShareSeriesMakeupInput>;
  signingProcedure?: Maybe<SigningProcedure>;
  solicited?: Maybe<Scalars['Boolean']>;
  transferMethod?: Maybe<TransferMethod>;
};

export type ListingMutationResponse = {
  __typename?: 'ListingMutationResponse';
  errors?: Maybe<Array<InputError>>;
  listing?: Maybe<Listing>;
};

export enum ListingPermission {
  ModifyListing = 'MODIFY_LISTING',
  PlaceBid = 'PLACE_BID',
  PlaceStandingBid = 'PLACE_STANDING_BID',
  RequestBid = 'REQUEST_BID',
  SetListingPricePerShare = 'SET_LISTING_PRICE_PER_SHARE',
  StartDiscussion = 'START_DISCUSSION',
  WithdrawListing = 'WITHDRAW_LISTING'
}

export enum ListingState {
  Closed = 'CLOSED',
  ConditionallySold = 'CONDITIONALLY_SOLD',
  Expired = 'EXPIRED',
  InReview = 'IN_REVIEW',
  Open = 'OPEN',
  Rejected = 'REJECTED',
  Withdrawn = 'WITHDRAWN'
}

/** Login inputs */
export type LoginInput = {
  /** Email address */
  email: Scalars['String'];
  /** Password for user */
  password: Scalars['String'];
};

export type MarketActivity = {
  __typename?: 'MarketActivity';
  latestStandingBidsAndListings: Array<Maybe<StandingBidOrListing>>;
  /** Companies with most open listing */
  mostActiveCompanies: Array<Maybe<Company>>;
};


export type MarketActivityLatestStandingBidsAndListingsArgs = {
  limit: Scalars['Int'];
};


export type MarketActivityMostActiveCompaniesArgs = {
  limit: Scalars['Int'];
};

export type MessageMutationResponse = {
  __typename?: 'MessageMutationResponse';
  errors?: Maybe<Array<InputError>>;
  message: DiscussionMessage;
};

export type MessageTopic = {
  id: Scalars['ID'];
  type: MessageTopicType;
};

export enum MessageTopicType {
  Bid = 'BID',
  Listing = 'LISTING',
  StandingBid = 'STANDING_BID'
}

export type ModifyBidInput = {
  notifyRepresentingEmail?: Maybe<Scalars['Boolean']>;
  numShares?: Maybe<Scalars['Int']>;
  pricePerShare?: Maybe<Scalars['Int']>;
  /** How soon should the bid expire? (in hours) */
  timeLimit?: Maybe<Scalars['Int']>;
};

export type ModifyListingInput = {
  expireAt?: Maybe<Scalars['DateTime']>;
  muteNotifications?: Maybe<Scalars['Boolean']>;
  otherDetails?: Maybe<Scalars['String']>;
  /** Share price in cents */
  pricePerShare?: Maybe<Scalars['Int']>;
  shareSeriesMakeup?: Maybe<Array<ShareSeriesMakeupInput>>;
  signingProcedure?: Maybe<SigningProcedure>;
  solicited?: Maybe<Scalars['Boolean']>;
  transferMethod?: Maybe<TransferMethod>;
};

export type ModifyStandingBidInput = {
  acceptsShares?: Maybe<StandingBidAcceptsSharesOptions>;
  acceptsTransferMethods?: Maybe<Array<StandingBidAcceptsTransferMethodsOptions>>;
  allowPartialAccept?: Maybe<Scalars['Boolean']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  minPartialAcceptNumShares?: Maybe<Scalars['Int']>;
  muteNotifyWatchers?: Maybe<Scalars['Boolean']>;
  notifyRepresentingEmail?: Maybe<Scalars['Boolean']>;
  numShares?: Maybe<Scalars['Int']>;
  otherDetails?: Maybe<Scalars['String']>;
  pricePerShare?: Maybe<Scalars['Int']>;
};

export type MyHoldingsResponse = {
  __typename?: 'MyHoldingsResponse';
  holdingsV2?: Maybe<Array<HoldingV2>>;
};

export type Onboarding = {
  __typename?: 'Onboarding';
  currentStep?: Maybe<OnboardingStep>;
  customerAgreementSubmissionStatus: SubmissionStatus;
  hasAgreedToClickwrapAgreement?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identityVerificationSubmissionStatus: SubmissionStatus;
};

/** Onboarding response */
export type OnboardingMutationResponse = {
  __typename?: 'OnboardingMutationResponse';
  errors?: Maybe<Array<InputError>>;
  onboarding?: Maybe<Onboarding>;
};

export enum OnboardingStep {
  Accreditation = 'ACCREDITATION',
  Acknowledgement = 'ACKNOWLEDGEMENT',
  AwaitingApproval = 'AWAITING_APPROVAL',
  BrokerInfo = 'BROKER_INFO',
  BrokerIntermediaryInfo = 'BROKER_INTERMEDIARY_INFO',
  ClickwrapAgreement = 'CLICKWRAP_AGREEMENT',
  CustomerAgreement = 'CUSTOMER_AGREEMENT',
  HoldingDetails = 'HOLDING_DETAILS',
  InstitutionInfo = 'INSTITUTION_INFO',
  InstitutionIsBroker = 'INSTITUTION_IS_BROKER',
  InvestingGoals = 'INVESTING_GOALS',
  InvestorStatus = 'INVESTOR_STATUS',
  InvestorType = 'INVESTOR_TYPE',
  NeedsFurtherVerification = 'NEEDS_FURTHER_VERIFICATION',
  Residence = 'RESIDENCE',
  SellerInfoGuide = 'SELLER_INFO_GUIDE',
  Suitability = 'SUITABILITY',
  VerifyEmail = 'VERIFY_EMAIL',
  VerifyIdentity = 'VERIFY_IDENTITY',
  Watchlist = 'WATCHLIST'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PlaceBidInput = {
  affiliate?: Maybe<Scalars['Boolean']>;
  listingId: Scalars['ID'];
  muteNotifyWatchers?: Maybe<Scalars['Boolean']>;
  notifyRepresentingEmail?: Maybe<Scalars['Boolean']>;
  numShares: Scalars['Int'];
  pricePerShare: Scalars['Int'];
  representedUser?: Maybe<RepresentedUserInput>;
  solicited?: Maybe<Scalars['Boolean']>;
  /** How soon should the bid expire? (in hours) */
  timeLimit?: Maybe<Scalars['Int']>;
};

export type PlaceStandingBidInput = {
  acceptsShares: StandingBidAcceptsSharesOptions;
  acceptsTransferMethods: Array<StandingBidAcceptsTransferMethodsOptions>;
  affiliate?: Maybe<Scalars['Boolean']>;
  allowPartialAccept: Scalars['Boolean'];
  companyId: Scalars['ID'];
  expireAt?: Maybe<Scalars['DateTime']>;
  minPartialAcceptNumShares?: Maybe<Scalars['Int']>;
  muteNotifyWatchers?: Maybe<Scalars['Boolean']>;
  notifyRepresentingEmail?: Maybe<Scalars['Boolean']>;
  numShares: Scalars['Int'];
  otherDetails?: Maybe<Scalars['String']>;
  pricePerShare: Scalars['Int'];
  representedUser?: Maybe<RepresentedUserInput>;
  solicited?: Maybe<Scalars['Boolean']>;
};

export type PlaceStandingBidMutationResponse = {
  __typename?: 'PlaceStandingBidMutationResponse';
  errors?: Maybe<Array<InputError>>;
  standingBid?: Maybe<StandingBid>;
};

/** Configuration options for a Company's Price Chart */
export type PriceChartConfig = {
  __typename?: 'PriceChartConfig';
  availableTimeRanges: Array<AvailableTimeRanges>;
  displayChart: Scalars['Boolean'];
  graphDataStart: Scalars['Date'];
  graphIndicators: GraphIndicators;
  priceLines: PriceLines;
};

export type PriceGraph = {
  __typename?: 'PriceGraph';
  averageAsk?: Maybe<Scalars['Int']>;
  averageBid?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['String']>;
  continuousAverageAsk?: Maybe<Scalars['Int']>;
  continuousAverageBid?: Maybe<Scalars['Int']>;
  continuousHighestBid?: Maybe<Scalars['Int']>;
  continuousLowestAsk?: Maybe<Scalars['Int']>;
  highestBid?: Maybe<Scalars['Int']>;
  highestTransaction?: Maybe<Scalars['Int']>;
  highestTransactionPrice?: Maybe<Scalars['Int']>;
  indexPrice?: Maybe<Scalars['Int']>;
  lastTransaction?: Maybe<Scalars['Int']>;
  lastTransactionPrice?: Maybe<Scalars['Int']>;
  lowestAsk?: Maybe<Scalars['Int']>;
  priceChangeNinetyDays?: Maybe<Scalars['Decimal']>;
  priceChangeOneDay?: Maybe<Scalars['Decimal']>;
  priceChangeSevenDays?: Maybe<Scalars['Decimal']>;
  priceChangeSixtyDays?: Maybe<Scalars['Decimal']>;
  priceChangeThirtyDays?: Maybe<Scalars['Decimal']>;
  priceDay?: Maybe<Scalars['Date']>;
};

export type PriceLines = {
  __typename?: 'PriceLines';
  shareTypeCommon: Scalars['Boolean'];
  shareTypePreferred: Scalars['Boolean'];
  transferTypeDirect: Scalars['Boolean'];
  transferTypeIndirect: Scalars['Boolean'];
};

export type RecordTltAlternateSignerSignatureResponse = {
  __typename?: 'RecordTltAlternateSignerSignatureResponse';
  document?: Maybe<TltAlternateSignerDocument>;
  errors?: Maybe<Array<InputError>>;
};

export type RecordTransactionDocumentSignedInput = {
  documentId: Scalars['ID'];
  institutionId?: Maybe<Scalars['ID']>;
};

export type RecordTransactionDocumentSignedResponse = {
  __typename?: 'RecordTransactionDocumentSignedResponse';
  document?: Maybe<Document>;
};

export enum RejectionReason {
  BidOrderRejectedByHiive = 'BID_ORDER_REJECTED_BY_HIIVE',
  DocumentNotSigned = 'DOCUMENT_NOT_SIGNED',
  IssuerApprovalDeclined = 'ISSUER_APPROVAL_DECLINED',
  RejectedBySeller = 'REJECTED_BY_SELLER',
  SharesSold = 'SHARES_SOLD',
  TransactionCancelled = 'TRANSACTION_CANCELLED'
}

export type RenderOptions = {
  __typename?: 'RenderOptions';
  columns?: Maybe<Scalars['Int']>;
};

export type RepresentedUser = {
  __typename?: 'RepresentedUser';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type RepresentedUserInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

/** Reset password inputs */
export type ResetPasswordInput = {
  /** Password for user */
  password: Scalars['String'];
  /** Password confirmation */
  passwordConfirmation: Scalars['String'];
  /** Reset password token */
  token: Scalars['String'];
};

/** A response to un-inviting an alternate signer */
export type RevokeAlternateSignerInvitationResponse = {
  __typename?: 'RevokeAlternateSignerInvitationResponse';
  alternateSigner?: Maybe<AlternateSigner>;
  errors?: Maybe<Array<InputError>>;
};

export enum RoleType {
  Admin = 'ADMIN',
  Signer = 'SIGNER',
  Trader = 'TRADER',
  Viewer = 'VIEWER'
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Accept bid */
  acceptBid: BidMutationResponse;
  /** Accept a bid that has been countered */
  acceptCounteredBid: BidMutationResponse;
  acceptDiscussionRequest: DiscussionMutationResponse;
  /** Accept an invitation */
  acceptInvitation: SessionMutationResponse;
  acceptStandingBid?: Maybe<AcceptStandingBidMutationResponse>;
  /**
   * Inserts broker info
   * Used for Brokers which are from US or Canada or are registered with FINRA
   */
  addBrokerInfo: BrokerInfoMutationResponse;
  /**
   * Inserts broker info
   * Used for Brokers which are not from US or Canada nor are registered with FINRA
   * :intermediary_notes are required if :intermediary_permission is false
   */
  addBrokerIntermediaryInfo: BrokerInfoMutationResponse;
  addEntity?: Maybe<AddEntityResponse>;
  /** Adds either Holding or UnlistedHolding for unaccredited sellers */
  addHolding?: Maybe<AddHoldingMutationResponse>;
  addHoldings: AddHoldingsResponse;
  addHoldingsV2: AddHoldingsV2Response;
  /** Adds company to a specific user's watchlist */
  addToUserWatchlist?: Maybe<UserMutationResponse>;
  /** Adds company to current user's watchlist */
  addToWatchlist?: Maybe<UserMutationResponse>;
  answerAccreditationQuestionsNew: AnswerAccreditationResponse;
  answerInstitutionAccreditationQuestions: AnswerInstitutionAccreditationResponse;
  /**
   * Answer investment goals questions
   * @deprecated This field is deprecated and will be removed in a future release. Please use `answerSuitability` instead.
   */
  answerInvestmentGoals?: Maybe<InvestmentGoalsResponse>;
  /** Answer suitability questions */
  answerSuitability?: Maybe<AnswerSuitabilityResponse>;
  /** Update an institution */
  approveInstitution: InstitutionMutationResponse;
  archiveEntity: ArchiveEntityResponse;
  /** Allows a broker to submit a bid on a particular listing */
  brokerSubmitBid: BrokerSubmitBidMutationResponse;
  /** Cancel an invitation */
  cancelInvitation: InvitationMutationResponse;
  /** Updates an institution user's roles */
  changeInstitutionUserRoles?: Maybe<UserMutationResponse>;
  /**
   * Change Transaction Transfer Method and Signing Procedure
   * @deprecated Use `:change_transaction_transfer_type` mutation in BackOffice.TransactionSchema schema
   */
  changeTransactionTransferType: ChangeTransactionTransferTypeResponse;
  /** Change activation status for any user */
  changeUserActiveStatus?: Maybe<UserMutationResponse>;
  closeDiscussion?: Maybe<DiscussionMutationResponse>;
  completeExecutionTask: CompleteExecutionTaskMutationResponse;
  /** Counter bid V2 */
  counterBidV2: BidMutationResponse;
  /** Creates a MT account creation flow and returns the client_token. */
  createBankAccountCollectionFlow: CreateBankAccountCollectionFlowResponse;
  /** Creates a file upload for an existing S3 object */
  createFileUpload: FileUploadMutationResponse;
  /** Create an institution */
  createInstitution: InstitutionMutationResponse;
  /**
   * Create an institution
   * @deprecated To remove - use upsert_institution instead
   */
  createInstitutionV2: InstitutionMutationResponse;
  /** Create Investing Goal Answers */
  createInvestingGoalAnswers: InvestingGoalAnswerMutationResponse;
  /** Allows a seller to list some of the shares they own */
  createListing: ListingMutationResponse;
  /** Create a Trusted Contact Person record */
  createTrustedContactPerson: TrustedContactPersonMutationResponse;
  generateS3UploadPolicy: GenerateS3UploadPolicyResponse;
  generateTransloaditParams: TransloaditParams;
  /** Invites an alternate signer */
  inviteAlternateSigner: InviteAlternateSignerResponse;
  linkEntity: LinkEntityResponse;
  linkInstitutionGovernmentDocument: InstitutionMutationResponse;
  /** Login the user with the supplied credentials */
  login?: Maybe<SessionMutationResponse>;
  markDiscussionAsRead: DiscussionMutationResponse;
  markMessageAsRead: MessageMutationResponse;
  modifyBid: BidMutationResponse;
  /** Modify counter on a countered bid */
  modifyCounterBid: BidMutationResponse;
  modifyListing: ListingMutationResponse;
  modifyStandingBid?: Maybe<StandingBidMutationResponse>;
  /** Place a bid on a listing */
  placeBid: BidMutationResponse;
  /** Place a Standing Bid on stock of a particular company */
  placeStandingBid?: Maybe<PlaceStandingBidMutationResponse>;
  recordTltAlternateSignerSignature: RecordTltAlternateSignerSignatureResponse;
  /** Records document signatures for transaction documents */
  recordTransactionDocumentSigned: RecordTransactionDocumentSignedResponse;
  rejectDiscussionRequest: DiscussionMutationResponse;
  /** Removes company from specific user's watchlist */
  removeFromUserWatchlist?: Maybe<UserMutationResponse>;
  /** Removes company from current user's watchlist */
  removeFromWatchlist?: Maybe<UserMutationResponse>;
  /** Requests an email validation message to be sent */
  requestEmailVerification?: Maybe<UserMutationResponse>;
  /** Requests a reset password email to be sent */
  requestResetPassword: Scalars['String'];
  /** Resend an invitation */
  resendInvitation: InvitationMutationResponse;
  /** Requests a reset password email to be sent */
  resetPassword?: Maybe<UserMutationResponse>;
  revokeAlternateSignerInvitation: RevokeAlternateSignerInvitationResponse;
  /** Send an invitation */
  sendInvitation: InvitationMutationResponse;
  /** Send a message */
  sendMessage?: Maybe<MessageMutationResponse>;
  setHoldings: AddHoldingsV2Response;
  setListingPricePerShare: ListingMutationResponse;
  /** Login the user with the supplied credentials */
  signup?: Maybe<SessionMutationResponse>;
  /** Start a discussion */
  startDiscussionV2?: Maybe<DiscussionMutationResponse>;
  /** Toggle a feature flag for logged in user */
  toggleFeatureFlag?: Maybe<ToggleFeatureFlagMutationResponse>;
  /** Returns session token from an identity token */
  token: SessionMutationResponse;
  /** Transitions the current onboarding step back to the previous step */
  transitionBackToPreviousStep: OnboardingMutationResponse;
  /** Transitions the current onboarding step to next subsequent step */
  transitionCurrentStep: OnboardingMutationResponse;
  /** Allows unaccredited sellers to accept a standing bid */
  unaccreditedSellerAcceptStandingBid: AcceptStandingBidMutationResponse;
  unaccreditedSellerAcceptStandingBidV2: AcceptStandingBidMutationResponse;
  /** Allows unaccredited sellers to create a Listing of shares in a company corresponding to their Holding */
  unaccreditedSellerCreateListing: UnaccreditedSellerCreateListingMutationResponse;
  unaccreditedSellerCreateListingV2: UnaccreditedSellerCreateListingMutationResponse;
  /** Allows unaccredited sellers to modify their Listing */
  unaccreditedSellerModifyListing: ListingMutationResponse;
  unaccreditedSellerModifyListingV2: ListingMutationResponse;
  /** Allows unaccredited sellers to withdraw their Listing */
  unaccreditedSellerWithdrawListing: ListingMutationResponse;
  /** Updates unaccredited seller's or broker's `agreed_to_customer_agreement` */
  updateAgreedToCustomerAgreement: UserMutationResponse;
  /** Attempt a bank account verification using microdeposits received. */
  updateBankAccountVerification: ExternalAccountResponse;
  /** Updates residence country and FINRA registration for broker */
  updateBrokerResidence?: Maybe<UserMutationResponse>;
  /** @deprecated This field is deprecated and will be removed in a future release. Please use `answerSuitability` instead. */
  updateCaIndividualInvestmentGoals?: Maybe<UpdateIndividualInvestmentGoalsResponse>;
  updateIndividualAccreditationAnswer?: Maybe<UpdateIndividualAccreditationAnswerResponse>;
  /** @deprecated This field is deprecated and will be removed in a future release. Please use `answerSuitability` instead. */
  updateIndividualInvestmentGoals?: Maybe<UpdateIndividualInvestmentGoalsResponse>;
  /**
   * Update an institution
   * @deprecated To remove - use upsert_institution instead
   */
  updateInstitution: InstitutionMutationResponse;
  updateInstitutionAccreditationAnswer?: Maybe<UpdateInstitutionAccreditationAnswerResponse>;
  updateInstitutionComplianceEmail: InstitutionMutationResponse;
  /** @deprecated This field is deprecated and will be removed in a future release. Please use `answerSuitability` instead. */
  updateInstitutionInvestmentGoals?: Maybe<UpdateInstitutionInvestmentGoalsResponse>;
  /** Updates an onboarding */
  updateOnboarding: OnboardingMutationResponse;
  /**
   * Updates the existing users password, invalidating the current session and forcing a sign
   * in during the process
   */
  updatePassword?: Maybe<UserMutationResponse>;
  updateTransactionModificationAcknowledgment: UpdateTransactionModificationAcknowledgmentResponse;
  /** Updates the bank account used for the transaction */
  updateTransactionSellerBankAccountId: UpdateTransactionSellerBankAccountIdResponse;
  /** Updates seller bank account request status in transaction */
  updateTransactionSellerBankAccountRequestStatus: UpdateTransactionSellerBankAccountRequestStatusResponse;
  /** Update a Trusted Contact Person record */
  updateTrustedContactPerson: TrustedContactPersonMutationResponse;
  /** Update user */
  updateUser?: Maybe<UserMutationResponse>;
  /**
   * Updates the existing users email, invalidating the current session and forcing a sign
   * in during the process
   */
  updateUserEmail: UserMutationResponse;
  /** Updates the existing user's phone number */
  updateUserPhoneNumber: UserMutationResponse;
  /**
   * Inserts broker info, if broker info already exists, updates the existing broker info
   * Used for Brokers which are from US or Canada or are registered with FINRA
   */
  upsertBrokerInfo: BrokerInfoMutationResponse;
  /**
   * Inserts broker info, if broker info already exists, updates the existing broker info
   * Used for Brokers which are not from US or Canada nor are registered with FINRA
   * :intermediary_notes are required if :intermediary_permission is false
   */
  upsertBrokerIntermediaryInfo: BrokerInfoMutationResponse;
  /** Upsert an institution */
  upsertInstitution: InstitutionMutationResponse;
  /** Verify an email token */
  verifyEmailToken?: Maybe<UserMutationResponse>;
  viewTltAlternateSignerTransactionDocument: ViewTltAlternateSignerTransactionDocumentResponse;
  /** Returns the document view token (if available) */
  viewTransactionDocument?: Maybe<Scalars['String']>;
  /** Withdraw bid */
  withdrawBid: BidMutationResponse;
  /** Withdraw counter bid */
  withdrawCounterBid: BidMutationResponse;
  withdrawListing: ListingMutationResponse;
  withdrawStandingBid: StandingBidMutationResponse;
};


export type RootMutationTypeAcceptBidArgs = {
  bidId: Scalars['ID'];
};


export type RootMutationTypeAcceptCounteredBidArgs = {
  bidId: Scalars['ID'];
};


export type RootMutationTypeAcceptDiscussionRequestArgs = {
  discussionId: Scalars['ID'];
};


export type RootMutationTypeAcceptInvitationArgs = {
  input: AcceptInvitationInput;
};


export type RootMutationTypeAcceptStandingBidArgs = {
  input: AcceptStandingBidInput;
  standingBidId: Scalars['ID'];
};


export type RootMutationTypeAddBrokerInfoArgs = {
  input: AddBrokerInfoInput;
};


export type RootMutationTypeAddBrokerIntermediaryInfoArgs = {
  input: AddBrokerIntermediaryInfoInput;
};


export type RootMutationTypeAddEntityArgs = {
  input: AddEntityInput;
};


export type RootMutationTypeAddHoldingArgs = {
  input: AddHoldingInput;
};


export type RootMutationTypeAddHoldingsArgs = {
  input: AddHoldingsInput;
};


export type RootMutationTypeAddHoldingsV2Args = {
  input: AddHoldingsV2Input;
};


export type RootMutationTypeAddToUserWatchlistArgs = {
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type RootMutationTypeAddToWatchlistArgs = {
  companyId: Scalars['ID'];
};


export type RootMutationTypeAnswerAccreditationQuestionsNewArgs = {
  input: AnswerAccreditationQuestionsInput;
};


export type RootMutationTypeAnswerInstitutionAccreditationQuestionsArgs = {
  input: AnswerInstitutionAccreditationQuestionsInput;
};


export type RootMutationTypeAnswerInvestmentGoalsArgs = {
  answers: Array<InvestmentGoalAnswerInput>;
};


export type RootMutationTypeAnswerSuitabilityArgs = {
  answers: Array<SuitabilityAnswerInput>;
};


export type RootMutationTypeApproveInstitutionArgs = {
  institutionId: Scalars['ID'];
};


export type RootMutationTypeArchiveEntityArgs = {
  input: ArchiveEntityInput;
};


export type RootMutationTypeBrokerSubmitBidArgs = {
  input: BrokerSubmitBidInput;
};


export type RootMutationTypeCancelInvitationArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeChangeInstitutionUserRolesArgs = {
  roles: Array<UserRole>;
  userId: Scalars['ID'];
};


export type RootMutationTypeChangeTransactionTransferTypeArgs = {
  input: ChangeTransactionTransferTypeInput;
  transactionId: Scalars['ID'];
};


export type RootMutationTypeChangeUserActiveStatusArgs = {
  active: Scalars['Boolean'];
  deactivationReason?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};


export type RootMutationTypeCloseDiscussionArgs = {
  discussionId: Scalars['ID'];
};


export type RootMutationTypeCompleteExecutionTaskArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeCounterBidV2Args = {
  bidId: Scalars['ID'];
  input: CounterBidInput;
};


export type RootMutationTypeCreateBankAccountCollectionFlowArgs = {
  countryId: Scalars['String'];
  transactionId: Scalars['ID'];
};


export type RootMutationTypeCreateFileUploadArgs = {
  input: FileUploadInput;
};


export type RootMutationTypeCreateInstitutionArgs = {
  input: CreateInstitutionInput;
};


export type RootMutationTypeCreateInstitutionV2Args = {
  input: CreateInstitutionInputV2;
};


export type RootMutationTypeCreateInvestingGoalAnswersArgs = {
  input: InvestingGoalAnswerInput;
};


export type RootMutationTypeCreateListingArgs = {
  input: ListingInput;
};


export type RootMutationTypeCreateTrustedContactPersonArgs = {
  input: CreateTrustedContactPersonInput;
};


export type RootMutationTypeGenerateS3UploadPolicyArgs = {
  fileType: Scalars['String'];
  filename: Scalars['String'];
};


export type RootMutationTypeGenerateTransloaditParamsArgs = {
  templateKind: TransloaditTemplateKind;
};


export type RootMutationTypeInviteAlternateSignerArgs = {
  input: InviteAlternateSignerInput;
};


export type RootMutationTypeLinkEntityArgs = {
  input: LinkEntityInput;
};


export type RootMutationTypeLinkInstitutionGovernmentDocumentArgs = {
  fileUploadId: Scalars['ID'];
  institutionId: Scalars['ID'];
};


export type RootMutationTypeLoginArgs = {
  input: LoginInput;
};


export type RootMutationTypeMarkDiscussionAsReadArgs = {
  discussionId: Scalars['ID'];
};


export type RootMutationTypeMarkMessageAsReadArgs = {
  messageId: Scalars['ID'];
};


export type RootMutationTypeModifyBidArgs = {
  bidId: Scalars['ID'];
  input: ModifyBidInput;
};


export type RootMutationTypeModifyCounterBidArgs = {
  bidId: Scalars['ID'];
  input: CounterBidInput;
};


export type RootMutationTypeModifyListingArgs = {
  input: ModifyListingInput;
  listingId: Scalars['ID'];
};


export type RootMutationTypeModifyStandingBidArgs = {
  input: ModifyStandingBidInput;
  standingBidId: Scalars['ID'];
};


export type RootMutationTypePlaceBidArgs = {
  input: PlaceBidInput;
};


export type RootMutationTypePlaceStandingBidArgs = {
  input: PlaceStandingBidInput;
};


export type RootMutationTypeRecordTltAlternateSignerSignatureArgs = {
  token: Scalars['String'];
};


export type RootMutationTypeRecordTransactionDocumentSignedArgs = {
  input: RecordTransactionDocumentSignedInput;
};


export type RootMutationTypeRejectDiscussionRequestArgs = {
  discussionId: Scalars['ID'];
};


export type RootMutationTypeRemoveFromUserWatchlistArgs = {
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type RootMutationTypeRemoveFromWatchlistArgs = {
  companyId: Scalars['ID'];
};


export type RootMutationTypeRequestResetPasswordArgs = {
  email: Scalars['String'];
};


export type RootMutationTypeResendInvitationArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type RootMutationTypeRevokeAlternateSignerInvitationArgs = {
  documentId: Scalars['ID'];
};


export type RootMutationTypeSendInvitationArgs = {
  input: SendInvitationInput;
};


export type RootMutationTypeSendMessageArgs = {
  input: SendMessageInput;
};


export type RootMutationTypeSetHoldingsArgs = {
  input: AddHoldingsV2Input;
};


export type RootMutationTypeSetListingPricePerShareArgs = {
  listingId: Scalars['ID'];
  pricePerShare?: Maybe<Scalars['Int']>;
};


export type RootMutationTypeSignupArgs = {
  input: SignupInput;
};


export type RootMutationTypeStartDiscussionV2Args = {
  input: StartDiscussionV2Input;
};


export type RootMutationTypeToggleFeatureFlagArgs = {
  input: ToggleFeatureFlagInput;
};


export type RootMutationTypeTokenArgs = {
  input: TokenInput;
};


export type RootMutationTypeTransitionBackToPreviousStepArgs = {
  onboardingId: Scalars['ID'];
};


export type RootMutationTypeTransitionCurrentStepArgs = {
  onboardingId: Scalars['ID'];
};


export type RootMutationTypeUnaccreditedSellerAcceptStandingBidArgs = {
  input: UnaccreditedSellerAcceptStandingBidInput;
  standingBidId: Scalars['ID'];
};


export type RootMutationTypeUnaccreditedSellerAcceptStandingBidV2Args = {
  input: UnaccreditedSellerAcceptStandingBidInputV2;
  standingBidId: Scalars['ID'];
};


export type RootMutationTypeUnaccreditedSellerCreateListingArgs = {
  input: UnaccreditedSellerCreateListingInput;
};


export type RootMutationTypeUnaccreditedSellerCreateListingV2Args = {
  input: UnaccreditedSellerCreateListingInputV2;
};


export type RootMutationTypeUnaccreditedSellerModifyListingArgs = {
  input: UnaccreditedSellerModifyListingInput;
};


export type RootMutationTypeUnaccreditedSellerModifyListingV2Args = {
  input: UnaccreditedSellerModifyListingInputV2;
};


export type RootMutationTypeUpdateAgreedToCustomerAgreementArgs = {
  agreedToCustomerAgreement: Scalars['Boolean'];
};


export type RootMutationTypeUpdateBankAccountVerificationArgs = {
  deposit1Value: Scalars['Int'];
  deposit2Value: Scalars['Int'];
  sellerBankAccountId: Scalars['ID'];
};


export type RootMutationTypeUpdateBrokerResidenceArgs = {
  input: UpdateBrokerResidenceInput;
};


export type RootMutationTypeUpdateCaIndividualInvestmentGoalsArgs = {
  input: UpdateCaIndividualInvestmentGoalsInput;
};


export type RootMutationTypeUpdateIndividualAccreditationAnswerArgs = {
  input: UpdateAccreditationAnswerInput;
};


export type RootMutationTypeUpdateIndividualInvestmentGoalsArgs = {
  input: UpdateInvestmentGoalsInput;
};


export type RootMutationTypeUpdateInstitutionArgs = {
  input: UpdateInstitutionInput;
  institutionId: Scalars['ID'];
};


export type RootMutationTypeUpdateInstitutionAccreditationAnswerArgs = {
  input: UpdateAccreditationAnswerInput;
};


export type RootMutationTypeUpdateInstitutionComplianceEmailArgs = {
  complianceEmail?: Maybe<Scalars['String']>;
  institutionId: Scalars['ID'];
};


export type RootMutationTypeUpdateInstitutionInvestmentGoalsArgs = {
  input: UpdateInvestmentGoalsInput;
};


export type RootMutationTypeUpdateOnboardingArgs = {
  input: UpdateOnboardingInput;
  onboardingId: Scalars['ID'];
};


export type RootMutationTypeUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type RootMutationTypeUpdateTransactionModificationAcknowledgmentArgs = {
  input: UpdateTransactionModificationAcknowledgmentInput;
  transactionId: Scalars['ID'];
};


export type RootMutationTypeUpdateTransactionSellerBankAccountIdArgs = {
  sellerBankAccountId: Scalars['ID'];
  transactionId: Scalars['ID'];
};


export type RootMutationTypeUpdateTransactionSellerBankAccountRequestStatusArgs = {
  sellerBankAccountRequestStatus: BankAccountRequestStatus;
  transactionId: Scalars['ID'];
};


export type RootMutationTypeUpdateTrustedContactPersonArgs = {
  input: UpdateTrustedContactPersonInput;
};


export type RootMutationTypeUpdateUserArgs = {
  input: UpdateUserInput;
};


export type RootMutationTypeUpdateUserEmailArgs = {
  email: Scalars['String'];
};


export type RootMutationTypeUpdateUserPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type RootMutationTypeUpsertBrokerInfoArgs = {
  input: AddBrokerInfoInput;
};


export type RootMutationTypeUpsertBrokerIntermediaryInfoArgs = {
  input: AddBrokerIntermediaryInfoInput;
};


export type RootMutationTypeUpsertInstitutionArgs = {
  input: UpsertInstitutionInput;
};


export type RootMutationTypeVerifyEmailTokenArgs = {
  token: Scalars['String'];
};


export type RootMutationTypeViewTltAlternateSignerTransactionDocumentArgs = {
  token: Scalars['String'];
};


export type RootMutationTypeViewTransactionDocumentArgs = {
  transactionId: Scalars['ID'];
};


export type RootMutationTypeWithdrawBidArgs = {
  bidId: Scalars['ID'];
};


export type RootMutationTypeWithdrawCounterBidArgs = {
  bidId: Scalars['ID'];
};


export type RootMutationTypeWithdrawListingArgs = {
  input?: Maybe<WithdrawListingInput>;
  listingId: Scalars['ID'];
};


export type RootMutationTypeWithdrawStandingBidArgs = {
  input: WithdrawStandingBidInput;
  standingBidId: Scalars['ID'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Returns accreditation answers */
  accreditationAnswers: Array<AccreditationAnswer>;
  /** Returns a list of accreditation question groups */
  accreditationQuestionGroups: Array<AccreditationQuestionGroup>;
  activeCommissionStructure: CommissionStructure;
  /** Returns a list of countries that have companies associated with them */
  allCompanyCountries: Array<Country>;
  /** Returns a list of all industries */
  allIndustries: Array<Industry>;
  /** Returns a list of all investors */
  allInvestors: Array<Investor>;
  /** Returns a listing bid by its id */
  bidById?: Maybe<Bid>;
  /** Returns a buyer's activity on a listing */
  buyerListingActivity: BuyerListingActivity;
  commissionInfo: CommissionInfo;
  /** Returns a list of industries that are associated with a company */
  companiesIndustries: Array<Industry>;
  /** Returns a list of investors that are associated with a company */
  companiesInvestors: Array<Investor>;
  /** Returns a company by id */
  companyById: Company;
  /** Returns a company's price data. */
  companyPriceData: CompanyPriceDataResponse;
  config: Config;
  /** Returns a list of all countries */
  countries: Array<Country>;
  currentAccreditationQuestionGroup?: Maybe<AccreditationQuestionGroup>;
  /** Returns the currently authenticated user */
  currentActor: User;
  currentActorFeatureFlags: Scalars['Json'];
  /** Returns the current context information */
  currentContext?: Maybe<ClientContext>;
  /** Returns the currently authenticated users membership agreement view token (if available) */
  currentMembershipAgreement?: Maybe<Scalars['String']>;
  /** Returns the investment goal questions by group */
  currentSuitabilityQuestionGroup?: Maybe<SuitabilityQuestionGroup>;
  discussionById?: Maybe<Discussion>;
  /** Returns a company's daily index price data. */
  hiiveFiftyIndexPrices: Hiive50DailyIndexPriceResponse;
  /** Paginated and filtered list of index companies */
  indexCompanies?: Maybe<IndexCompanyConnection>;
  /** Returns a list of invitations by institution */
  institutionInvitations: Array<Invitation>;
  /** Returns list of users by institution */
  institutionUsers: Array<User>;
  investingGoalQuestionGroup?: Maybe<InvestingGoalQuestionGroup>;
  /** Returns the Investing Goal Questions & Options */
  investingGoalQuestions?: Maybe<Array<Maybe<InvestingGoalQuestion>>>;
  /** @deprecated This field is deprecated and will be removed in a future release. Please use `suitabilityAnswers` instead. */
  investmentGoalAnswers: Array<InvestmentGoalAnswer>;
  /**
   * Checks if a given user's investment goal answers are current
   * @deprecated This field is deprecated and will be removed in a future release. Please use `suitabilityAnswers` instead.
   */
  investmentGoalAnswersCurrent?: Maybe<InvestmentGoalAnswersCurrentResponse>;
  /**
   * Returns the investment goal questions by group
   * @deprecated This field is deprecated and will be removed in a future release. Please use `currentSuitabilityQuestionGroup` instead.
   */
  investmentGoalQuestionGroups?: Maybe<Array<Maybe<InvestmentGoalQuestionGroup>>>;
  /** Returns an invitation by time limited token */
  invitationByToken?: Maybe<Invitation>;
  latestListings: Array<Listing>;
  latestStandingBids: Array<StandingBid>;
  /** Paginated and filtered list of companies */
  listCompanies?: Maybe<CompanyConnection>;
  /** Returns a listing by its id */
  listingById?: Maybe<Listing>;
  marketActivity: MarketActivity;
  mostActiveCompanies: Array<Maybe<Company>>;
  /** Returns the activity for a current user */
  myActivity: UserActivity;
  /** Returns a list of discussions that the current user is a member of. */
  myDiscussions: Array<Discussion>;
  myHoldings: MyHoldingsResponse;
  /** Returns the activity on a seller's listing */
  sellerListingActivity: SellerListingActivity;
  /** Returns a standing bid by its id */
  standingBidById?: Maybe<StandingBid>;
  /** Returns suitability answers */
  suitabilityAnswers: Array<SuitabilityAnswer>;
  /** Returns an alternate_signer by time limited token */
  tltAlternateSignerByToken?: Maybe<TltAlternateSigner>;
  /** Returns a transaction by its id */
  transactionById?: Maybe<Transaction>;
  /** Returns the activity for an Unaccredited Seller user */
  unaccreditedSellerMyActivity: UnaccreditedSellerActivity;
  /** Returns the activity on an Unaccredited Seller's company */
  unaccreditedSellerMyCompanyActivity: UnaccreditedSellerMyCompanyActivity;
  /** Returns activity on an Unaccredited Seller's Listing */
  unaccreditedSellerMyListingActivity: UnaccreditedSellerMyListingActivity;
  validShareSeries: Array<Scalars['String']>;
};


export type RootQueryTypeAccreditationAnswersArgs = {
  filterBy?: Maybe<AccreditationAnswerFilter>;
};


export type RootQueryTypeAccreditationQuestionGroupsArgs = {
  countryId?: Maybe<Scalars['ID']>;
  investorType?: Maybe<InvestorStatus>;
  version?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeBidByIdArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeBuyerListingActivityArgs = {
  listingId: Scalars['ID'];
};


export type RootQueryTypeCommissionInfoArgs = {
  companyId?: Maybe<Scalars['ID']>;
  listingId?: Maybe<Scalars['ID']>;
  pricePerShare: Scalars['Int'];
  totalShares: Scalars['Int'];
};


export type RootQueryTypeCompanyByIdArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeCompanyPriceDataArgs = {
  companyId: Scalars['ID'];
  indicators?: Maybe<Array<Maybe<Indicator>>>;
  startDate: Scalars['Date'];
};


export type RootQueryTypeCountriesArgs = {
  sorted?: Maybe<Scalars['Boolean']>;
};


export type RootQueryTypeCurrentAccreditationQuestionGroupArgs = {
  countryId?: Maybe<Scalars['ID']>;
  investorType?: Maybe<InvestorStatus>;
};


export type RootQueryTypeCurrentSuitabilityQuestionGroupArgs = {
  countryId: Scalars['ID'];
  investorStatus: InvestorStatus;
};


export type RootQueryTypeDiscussionByIdArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeHiiveFiftyIndexPricesArgs = {
  filterBy: Hiive50IndexPriceFilter;
};


export type RootQueryTypeIndexCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sortBy: IndexCompanySortBy;
};


export type RootQueryTypeInvestmentGoalAnswersArgs = {
  filterBy?: Maybe<SuitabilityAnswerFilter>;
};


export type RootQueryTypeInvestmentGoalQuestionGroupsArgs = {
  countryId?: Maybe<Scalars['ID']>;
  investorType?: Maybe<InvestorStatus>;
  version?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeInvitationByTokenArgs = {
  token: Scalars['String'];
};


export type RootQueryTypeLatestListingsArgs = {
  limit: Scalars['Int'];
};


export type RootQueryTypeLatestStandingBidsArgs = {
  limit: Scalars['Int'];
};


export type RootQueryTypeListCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  countryIds?: Maybe<Array<Scalars['ID']>>;
  first: Scalars['Int'];
  industryIds?: Maybe<Array<Scalars['ID']>>;
  investorIds?: Maybe<Array<Scalars['ID']>>;
  last?: Maybe<Scalars['Int']>;
  lastRoundPostValuationRange?: Maybe<LastRoundPostValuationRange>;
  orderBy: ListCompaniesOrderBy;
  searchText?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompanyStatus>>;
};


export type RootQueryTypeListingByIdArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeMostActiveCompaniesArgs = {
  limit: Scalars['Int'];
};


export type RootQueryTypeMyDiscussionsArgs = {
  topicFilter?: Maybe<MessageTopic>;
};


export type RootQueryTypeSellerListingActivityArgs = {
  listingId: Scalars['ID'];
};


export type RootQueryTypeStandingBidByIdArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeTltAlternateSignerByTokenArgs = {
  token: Scalars['String'];
};


export type RootQueryTypeTransactionByIdArgs = {
  id: Scalars['ID'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  onExecutionWorkflowUpdated: ExecutionWorkflow;
  onNewMessage: DiscussionMessage;
  /** Notify the client that a bank account has been created and linked to a transaction. */
  onSellerBankAccountCreated: Transaction;
  onTransactionDocumentReady: Document;
  onTransactionModificationUpdated: TransactionModification;
};


export type RootSubscriptionTypeOnExecutionWorkflowUpdatedArgs = {
  workflowId: Scalars['ID'];
};


export type RootSubscriptionTypeOnNewMessageArgs = {
  discussionId: Scalars['ID'];
};


export type RootSubscriptionTypeOnSellerBankAccountCreatedArgs = {
  transactionId: Scalars['ID'];
};


export type RootSubscriptionTypeOnTransactionDocumentReadyArgs = {
  transactionId: Scalars['ID'];
};


export type RootSubscriptionTypeOnTransactionModificationUpdatedArgs = {
  transactionModificationId: Scalars['ID'];
};

/** Represents a security specialist. Contains the associated security specialist user and the market analyst that works for the security specialist */
export type SecuritySpecialist = {
  __typename?: 'SecuritySpecialist';
  id: Scalars['ID'];
  marketAnalyst?: Maybe<SecuritySpecialistUser>;
  user: SecuritySpecialistUser;
};

/** A simple `user` schema representing a `user` or `market_analyst` associated with a `security_specialist` */
export type SecuritySpecialistUser = {
  __typename?: 'SecuritySpecialistUser';
  email: Scalars['String'];
  id: Scalars['ID'];
};

export type SellerListingActivity = {
  __typename?: 'SellerListingActivity';
  activeBids: Array<Bid>;
  pastBids: Array<Bid>;
  preBidDiscussions: Array<Discussion>;
};

/** Send invitation input */
export type SendInvitationInput = {
  /** Email of the user that will receive the invitation */
  email: Scalars['String'];
  /** Initial user assigned roles in the institution */
  roles: Array<RoleType>;
};

/** The input required to send a message. */
export type SendMessageInput = {
  discussionId: Scalars['ID'];
  messageBody: Scalars['String'];
};

/** Represents a user session, including a token, refresh token and a current user */
export type Session = {
  __typename?: 'Session';
  /** Long lived refresh token to be persisted */
  refreshToken: Scalars['String'];
  /** JWT for the authed user */
  token: Scalars['String'];
  /** The authed user's id */
  userId: Scalars['ID'];
};

/** A session, but with errors attached */
export type SessionMutationResponse = {
  __typename?: 'SessionMutationResponse';
  errors?: Maybe<Array<InputError>>;
  session?: Maybe<Session>;
};

export enum ShareSeries {
  A = 'A',
  B = 'B',
  C = 'C',
  Common = 'COMMON',
  CommonOptions = 'COMMON_OPTIONS',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  Options = 'OPTIONS',
  RestrictedStockUnits = 'RESTRICTED_STOCK_UNITS',
  Seed = 'SEED'
}

/** Composition of share series in a listing */
export type ShareSeriesMakeup = {
  __typename?: 'ShareSeriesMakeup';
  id: Scalars['ID'];
  numShares: Scalars['Int'];
  shareSeries: ShareSeries;
};

/** Composition of share series in a listing */
export type ShareSeriesMakeupInput = {
  id?: Maybe<Scalars['ID']>;
  numShares: Scalars['Int'];
  shareSeries: ShareSeries;
};

export enum ShareType {
  Common = 'COMMON',
  Mix = 'MIX',
  Pref = 'PREF'
}

export enum ShareTypeV2 {
  Common = 'COMMON',
  Mix = 'MIX',
  Pref = 'PREF',
  RestrictedStockUnits = 'RESTRICTED_STOCK_UNITS'
}

export enum SigningProcedure {
  Automated = 'AUTOMATED',
  Manual = 'MANUAL',
  Unknown = 'UNKNOWN'
}

export type SignupAttributionInput = {
  firstTouch?: Maybe<SignupUtm>;
  lastTouch?: Maybe<SignupUtm>;
};

/** Signup inputs */
export type SignupInput = {
  /** Is the user a broker user */
  brokerUser?: Maybe<Scalars['Boolean']>;
  /** Email address */
  email: Scalars['String'];
  /** First name */
  firstName: Scalars['String'];
  /** Last name */
  lastName: Scalars['String'];
  /** Password for user */
  password: Scalars['String'];
  /** Password confirmation */
  passwordConfirmation: Scalars['String'];
  /** Phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /** first and last seen UTM data */
  signupAttribution?: SignupAttributionInput;
  /** Signup key */
  signupKey?: Maybe<Scalars['String']>;
};

export type SignupUtm = {
  campaign?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
  ts?: Maybe<Scalars['String']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Used to make standing bids on company stock */
export type StandingBid = {
  __typename?: 'StandingBid';
  /** Returns a list of all accepted bid price per share values */
  acceptedBidPrices: Array<Scalars['Int']>;
  acceptsShares: StandingBidAcceptsSharesOptions;
  acceptsTransferMethods: Array<StandingBidAcceptsTransferMethodsOptions>;
  affiliate?: Maybe<Scalars['Boolean']>;
  allowPartialAccept?: Maybe<Scalars['Boolean']>;
  bids: Array<Bid>;
  broker?: Maybe<User>;
  brokerId?: Maybe<Scalars['ID']>;
  /** The buyer's email (only for superadmins or else returns null) */
  buyerEmail?: Maybe<Scalars['String']>;
  buyerId: Scalars['ID'];
  buyerInstitutionId?: Maybe<Scalars['ID']>;
  company: Company;
  companyId: Scalars['ID'];
  displayId: Scalars['String'];
  expireAt?: Maybe<Scalars['DateTime']>;
  /** Whether the buyer is from Hiive */
  fromHiive: Scalars['Boolean'];
  hasRequestedDiscussion: Scalars['Boolean'];
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  minPartialAcceptNumShares?: Maybe<Scalars['Int']>;
  myPermissionsV2: Array<StandingBidPermission>;
  numDiscussions: Scalars['Int'];
  numSharesAccepted: Scalars['Int'];
  numSharesAcceptedConditionally?: Maybe<Scalars['Int']>;
  numSharesAvailable?: Maybe<Scalars['Int']>;
  numSharesOriginal?: Maybe<Scalars['Int']>;
  numUnreadMessages: Scalars['Int'];
  otherDetails?: Maybe<Scalars['String']>;
  placedAt?: Maybe<Scalars['DateTime']>;
  pricePerShare: Scalars['Int'];
  rejectionReasonText?: Maybe<Scalars['String']>;
  representedEmail?: Maybe<Scalars['String']>;
  /** A list of share series options that are selectable by a seller when accepting a standing bid */
  shareSeriesOptions: Array<ShareSeries>;
  shortId: Scalars['String'];
  solicited?: Maybe<Scalars['Boolean']>;
  state: StandingBidState;
  updatedAt: Scalars['DateTime'];
  version?: Maybe<Scalars['Int']>;
  withdrawnAt?: Maybe<Scalars['DateTime']>;
};

export enum StandingBidAcceptsSharesOptions {
  Both = 'BOTH',
  Common = 'COMMON',
  Pref = 'PREF'
}

export enum StandingBidAcceptsTransferMethodsOptions {
  Direct = 'DIRECT',
  ForwardContract = 'FORWARD_CONTRACT',
  SpvLister = 'SPV_LISTER',
  SpvThirdparty = 'SPV_THIRDPARTY'
}

export type StandingBidMutationResponse = {
  __typename?: 'StandingBidMutationResponse';
  errors?: Maybe<Array<InputError>>;
  standingBid?: Maybe<StandingBid>;
};

export type StandingBidOrListing = Listing | StandingBid;

export enum StandingBidPermission {
  AcceptStandingBid = 'ACCEPT_STANDING_BID',
  ModifyStandingBid = 'MODIFY_STANDING_BID',
  StartDiscussion = 'START_DISCUSSION',
  WithdrawStandingBid = 'WITHDRAW_STANDING_BID'
}

export enum StandingBidState {
  Closed = 'CLOSED',
  ConditionallyCompleted = 'CONDITIONALLY_COMPLETED',
  Expired = 'EXPIRED',
  InReview = 'IN_REVIEW',
  Open = 'OPEN',
  Rejected = 'REJECTED',
  Withdrawn = 'WITHDRAWN'
}

export type StartDiscussionV2Input = {
  messageBody: Scalars['String'];
  messageTopic: MessageTopic;
};

export enum Status {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Scheduled = 'SCHEDULED'
}

export enum SubmissionStatus {
  Incomplete = 'INCOMPLETE',
  Skipped = 'SKIPPED',
  Submitted = 'SUBMITTED'
}

/** A Suitability Answer */
export type SuitabilityAnswer = {
  __typename?: 'SuitabilityAnswer';
  id: Scalars['ID'];
  institutionId?: Maybe<Scalars['ID']>;
  suitabilityOption: SuitabilityOption;
  text?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type SuitabilityAnswerFilter = {
  institutionId?: Maybe<Scalars['ID']>;
  latest?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['ID']>;
};

export type SuitabilityAnswerInput = {
  /** Chosen option ID */
  suitabilityOptionId: Scalars['ID'];
  /** Custom input for the chosen option */
  text?: Maybe<Scalars['String']>;
};

/** A Suitability Option */
export type SuitabilityOption = {
  __typename?: 'SuitabilityOption';
  custom?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nextQuestionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  order: Scalars['Int'];
  placeholder?: Maybe<Scalars['String']>;
  suitabilityQuestion: SuitabilityQuestion;
  text?: Maybe<Scalars['String']>;
};

/** A Suitability Question */
export type SuitabilityQuestion = {
  __typename?: 'SuitabilityQuestion';
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order: Scalars['Int'];
  renderOptions?: Maybe<RenderOptions>;
  suitabilityOptions: Array<Maybe<SuitabilityOption>>;
  suitabilityQuestionGroup: SuitabilityQuestionGroup;
  text: Scalars['String'];
};

/** A Suitability Question Group */
export type SuitabilityQuestionGroup = {
  __typename?: 'SuitabilityQuestionGroup';
  country: Scalars['String'];
  id: Scalars['ID'];
  investorStatus: InvestorStatus;
  suitabilityQuestions: Array<Maybe<SuitabilityQuestion>>;
  version: Scalars['Int'];
};

export type TltAlternateSigner = {
  __typename?: 'TltAlternateSigner';
  delegateUser?: Maybe<TltAlternateSignerDelegateUser>;
  document: TltAlternateSignerDocument;
  documentId: Scalars['ID'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  institutionId?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  sender: TltAlternateSignerSender;
  transaction: TltAlternateSignerTransaction;
};

export type TltAlternateSignerBid = {
  __typename?: 'TltAlternateSignerBid';
  company: TltAlternateSignerCompany;
  displayId: Scalars['String'];
  id: Scalars['ID'];
  listing: TltAlternateSignerListing;
  numShares: Scalars['Int'];
  pricePerShare: Scalars['Int'];
  state: BidState;
};

export type TltAlternateSignerCompany = {
  __typename?: 'TltAlternateSignerCompany';
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type TltAlternateSignerDelegateUser = {
  __typename?: 'TltAlternateSignerDelegateUser';
  name: Scalars['String'];
};

export type TltAlternateSignerDocument = {
  __typename?: 'TltAlternateSignerDocument';
  externalId: Scalars['String'];
  id: Scalars['ID'];
  signers: Array<TltAlternateSignerDocumentSigner>;
  status: DocumentStatus;
  type: DocumentType;
};

export type TltAlternateSignerDocumentSigner = {
  __typename?: 'TltAlternateSignerDocumentSigner';
  alternateSignerId?: Maybe<Scalars['ID']>;
  documentId: Scalars['ID'];
  institutionId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type TltAlternateSignerListing = {
  __typename?: 'TltAlternateSignerListing';
  id: Scalars['ID'];
  shareSeriesMakeup: Array<ShareSeriesMakeup>;
  shareType: ShareType;
  shareTypeV2: ShareTypeV2;
};

export type TltAlternateSignerSender = {
  __typename?: 'TltAlternateSignerSender';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  investorType: InvestorType;
  lastName: Scalars['String'];
  name: Scalars['String'];
};

export type TltAlternateSignerTransaction = {
  __typename?: 'TltAlternateSignerTransaction';
  bid: TltAlternateSignerBid;
  id: Scalars['ID'];
  numShares: Scalars['Int'];
  pendingModification?: Maybe<TransactionModification>;
  pricePerShare: Scalars['Int'];
  transferMethod: TransferMethod;
};

/** Toggle feature flag input */
export type ToggleFeatureFlagInput = {
  /** Key of the feature flag, e.g `marketplace.show_redesign` */
  flagKey: Scalars['String'];
};

/** Toggle feature flag response but with errors */
export type ToggleFeatureFlagMutationResponse = {
  __typename?: 'ToggleFeatureFlagMutationResponse';
  errors?: Maybe<Array<InputError>>;
};

/** Token inputs */
export type TokenInput = {
  /** Identity token for user */
  identityToken: Scalars['String'];
};

export type Transaction = {
  __typename?: 'Transaction';
  bid: Bid;
  bidId: Scalars['ID'];
  buyer: VeryBasicUser;
  buyerBroker?: Maybe<VeryBasicUser>;
  buyerBrokerId?: Maybe<Scalars['ID']>;
  buyerEntity?: Maybe<Entity>;
  buyerId: Scalars['ID'];
  buyerInstitution?: Maybe<VeryBasicInstitution>;
  buyerInstitutionId?: Maybe<Scalars['ID']>;
  closedFeePaidAt?: Maybe<Scalars['DateTime']>;
  closedFeePendingAt?: Maybe<Scalars['DateTime']>;
  commission?: Maybe<Commission>;
  company: Company;
  /** @deprecated This field preloads the counterparty on the transaction's bid, we can change this to just preload off the transaction & call this `seller` */
  counterparty?: Maybe<Counterparty>;
  document?: Maybe<Document>;
  documentTypeV2?: Maybe<DocumentType>;
  expireAt?: Maybe<Scalars['DateTime']>;
  feeDiscountApplications?: Maybe<Array<FeeDiscountApplication>>;
  grossProceeds: Scalars['Int'];
  /**
   * A list of all previous values a transaction has had in the past.
   * An Empty list means transaction has never been modified.
   */
  history: Array<TransactionHistory>;
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  myPermissionsV2: Array<TransactionPermission>;
  myTasks: Array<ExecutionTask>;
  numShares: Scalars['Int'];
  pendingModification?: Maybe<TransactionModification>;
  pricePerShare: Scalars['Int'];
  representedBuyer?: Maybe<RepresentedUser>;
  representedBuyerId?: Maybe<Scalars['ID']>;
  representedSeller?: Maybe<RepresentedUser>;
  representedSellerId?: Maybe<Scalars['ID']>;
  rofr?: Maybe<Scalars['Boolean']>;
  seller: VeryBasicUser;
  sellerBankAccount?: Maybe<BankAccount>;
  sellerBankAccountId?: Maybe<Scalars['ID']>;
  /** Verification status of a Modern Treasury */
  sellerBankAccountRequestStatus?: Maybe<BankAccountRequestStatus>;
  sellerBroker?: Maybe<VeryBasicUser>;
  sellerBrokerId?: Maybe<Scalars['ID']>;
  sellerEntity?: Maybe<Entity>;
  sellerId: Scalars['ID'];
  sellerInstitution?: Maybe<VeryBasicInstitution>;
  sellerInstitutionId?: Maybe<Scalars['ID']>;
  signingProcedure: SigningProcedure;
  state: TransactionState;
  transferMethod: ValidTransferMethod;
  workflow?: Maybe<ExecutionWorkflow>;
};

export type TransactionHistory = {
  __typename?: 'TransactionHistory';
  commission: Commission;
  numShares: Scalars['Int'];
  pricePerShare: Scalars['Int'];
};

export type TransactionModification = {
  __typename?: 'TransactionModification';
  acknowledgments: Array<TransactionModificationAcknowledgment>;
  commission?: Maybe<Commission>;
  feeDiscountApplications?: Maybe<Array<FeeDiscountApplication>>;
  id: Scalars['ID'];
  numShares: Scalars['Int'];
  pricePerShare: Scalars['Int'];
  status: TransactionModificationStatus;
  transaction: Transaction;
  transactionId: Scalars['ID'];
};

export type TransactionModificationAcknowledgment = {
  __typename?: 'TransactionModificationAcknowledgment';
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  status: TransactionModificationAcknowledgmentStatus;
  transactionModification: TransactionModification;
  transactionModificationId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

export enum TransactionModificationAcknowledgmentStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum TransactionModificationStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Overridden = 'OVERRIDDEN',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum TransactionPermission {
  InviteAlternateSigner = 'INVITE_ALTERNATE_SIGNER',
  SignTransactionDocument = 'SIGN_TRANSACTION_DOCUMENT'
}

export enum TransactionState {
  AwaitingClosing = 'AWAITING_CLOSING',
  BidAccepted = 'BID_ACCEPTED',
  Cancelled = 'CANCELLED',
  ClosedFeePaid = 'CLOSED_FEE_PAID',
  ClosedFeePending = 'CLOSED_FEE_PENDING',
  Expired = 'EXPIRED',
  InReview = 'IN_REVIEW',
  IssuerApprovalDeclined = 'ISSUER_APPROVAL_DECLINED',
  IssuerApproved = 'ISSUER_APPROVED',
  IssuerPendingApproval = 'ISSUER_PENDING_APPROVAL',
  Pending = 'PENDING'
}

export enum TransferMethod {
  Direct = 'DIRECT',
  ForwardContract = 'FORWARD_CONTRACT',
  SpvLister = 'SPV_LISTER',
  SpvThirdparty = 'SPV_THIRDPARTY',
  Unknown = 'UNKNOWN'
}

export type TransloaditParams = {
  __typename?: 'TransloaditParams';
  params: Scalars['String'];
  signature: Scalars['String'];
};

export enum TransloaditTemplateKind {
  CompanyImage = 'COMPANY_IMAGE',
  PrivateUploads = 'PRIVATE_UPLOADS'
}

/** A trusted contact person entry */
export type TrustedContactPerson = {
  __typename?: 'TrustedContactPerson';
  contactInfo?: Maybe<ContactInfo>;
  isContactProvided: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

/** Trusted Contact Person response, with errors */
export type TrustedContactPersonMutationResponse = {
  __typename?: 'TrustedContactPersonMutationResponse';
  errors?: Maybe<Array<InputError>>;
  trustedContactPerson?: Maybe<TrustedContactPerson>;
};

export type UnaccreditedSellerAcceptStandingBidInput = {
  numShares: Scalars['Int'];
  transferMethod: UnaccreditedSellerTransferMethodInput;
};

export type UnaccreditedSellerAcceptStandingBidInputV2 = {
  numShares: Scalars['Int'];
  shareSeries: ShareSeries;
  transferMethod: UnaccreditedSellerTransferMethodInput;
};

export type UnaccreditedSellerActivity = {
  __typename?: 'UnaccreditedSellerActivity';
  completedSales: Array<Transaction>;
  myCompany: Company;
  myListing?: Maybe<Listing>;
  pendingSales: Array<Transaction>;
};

export type UnaccreditedSellerCreateListingInput = {
  numShares: Scalars['Int'];
  otherDetails?: Maybe<Scalars['String']>;
  pricePerShare: Scalars['Int'];
  transferMethod: UnaccreditedSellerTransferMethodInput;
};

export type UnaccreditedSellerCreateListingInputV2 = {
  numShares: Scalars['Int'];
  otherDetails?: Maybe<Scalars['String']>;
  pricePerShare: Scalars['Int'];
  shareSeries: ShareSeries;
  transferMethod: UnaccreditedSellerTransferMethodInput;
};

export type UnaccreditedSellerCreateListingMutationResponse = {
  __typename?: 'UnaccreditedSellerCreateListingMutationResponse';
  errors?: Maybe<Array<InputError>>;
  listing?: Maybe<Listing>;
};

export type UnaccreditedSellerModifyListingInput = {
  numShares?: Maybe<Scalars['Int']>;
  otherDetails?: Maybe<Scalars['String']>;
  pricePerShare: Scalars['Int'];
  transferMethod: UnaccreditedSellerTransferMethodInput;
};

export type UnaccreditedSellerModifyListingInputV2 = {
  numShares?: Maybe<Scalars['Int']>;
  otherDetails?: Maybe<Scalars['String']>;
  pricePerShare: Scalars['Int'];
  shareSeries: ShareSeries;
  transferMethod: UnaccreditedSellerTransferMethodInput;
};

export type UnaccreditedSellerMyCompanyActivity = {
  __typename?: 'UnaccreditedSellerMyCompanyActivity';
  availableStandingBids: Array<StandingBid>;
  otherListings: Array<Listing>;
  standingBidsWithDiscussions: Array<StandingBid>;
};

export type UnaccreditedSellerMyListingActivity = {
  __typename?: 'UnaccreditedSellerMyListingActivity';
  activeBids: Array<Bid>;
  pastBids: Array<Bid>;
  preBidDiscussions: Array<Discussion>;
};

export enum UnaccreditedSellerTransferMethodInput {
  Direct = 'DIRECT',
  ForwardContract = 'FORWARD_CONTRACT',
  Unknown = 'UNKNOWN'
}

/** Description of sellers unlisted holding */
export type UnlistedHolding = {
  __typename?: 'UnlistedHolding';
  companyName: Scalars['String'];
  id: Scalars['ID'];
  numSharesV2?: Maybe<Scalars['Int']>;
  sellerId: Scalars['ID'];
};

/** Input for updating accreditation questions */
export type UpdateAccreditationAnswerInput = {
  /** The id of the answer chosen */
  questionId: Scalars['ID'];
};

/** Inputs for updating a FINRA registration and residence of a broker user */
export type UpdateBrokerResidenceInput = {
  countryId?: Maybe<Scalars['ID']>;
  finraRegistered?: Maybe<Scalars['Boolean']>;
};

/** Input for updating Canadian individual investment goals */
export type UpdateCaIndividualInvestmentGoalsInput = {
  options: Array<CaIndividualInvestmentGoalOptionInput>;
};

/** A response to updating individual accreditation answer */
export type UpdateIndividualAccreditationAnswerResponse = {
  __typename?: 'UpdateIndividualAccreditationAnswerResponse';
  errors?: Maybe<Array<InputError>>;
  user: User;
};

/** A response to updating individual investment goals */
export type UpdateIndividualInvestmentGoalsResponse = {
  __typename?: 'UpdateIndividualInvestmentGoalsResponse';
  errors?: Maybe<Array<InputError>>;
  user: User;
};

/** A response to answering institution accreditations answer */
export type UpdateInstitutionAccreditationAnswerResponse = {
  __typename?: 'UpdateInstitutionAccreditationAnswerResponse';
  errors?: Maybe<Array<InputError>>;
  institution: Institution;
};

/** Update institution input */
export type UpdateInstitutionInput = {
  /** Canada Institution Onboarding - Whether or not the firm/fund agreed to investment risks */
  caAgreedToInvestmentRisk?: Maybe<Scalars['Boolean']>;
  /** Canada Institution Onboarding - Whether or not the firm/fund is a Permitted Client */
  caIsPermittedClient?: Maybe<CaIsPermittedClientAnswer>;
  /** Canada Institution Onboarding - The jurisdiction in which the Canadian institution is incorporated */
  caJurisdictionOfIncorporation?: Maybe<Scalars['String']>;
  /** City where institution is located */
  city?: Maybe<Scalars['String']>;
  /** Country id representing Country where institution is located */
  countryId?: Maybe<Scalars['String']>;
  /** Institution entity type, Corporation, LLC, Partnership, or Other */
  entityType?: Maybe<InstitutionEntityType>;
  /** Broker dealer, intermediary, or agent acting on behalf of an unrelated client */
  isBrokerDealer?: Maybe<Scalars['Boolean']>;
  /** FINRA Rule 4512(c) Institution Account */
  isFinraInstitutionAccount?: Maybe<Scalars['Boolean']>;
  /** Country id representing the jurisdiction wherein the institution was incorporated */
  jurisdictionOfIncorporationId?: Maybe<Scalars['String']>;
  /** Legal name for institution */
  legalName?: Maybe<Scalars['String']>;
  /** Legal signatory representative's email for institution */
  legalSignatoryEmail?: Maybe<Scalars['String']>;
  /** Legal signatory representative's name for institution */
  legalSignatoryName?: Maybe<Scalars['String']>;
  /** Postal code where institution is located */
  postalCode?: Maybe<Scalars['String']>;
  /** Province/State/Region where institution is located */
  region?: Maybe<Scalars['String']>;
  /** Street address where institution is located */
  streetAddress?: Maybe<Scalars['String']>;
  /** Is the institution suitable */
  suitable?: Maybe<Scalars['Boolean']>;
};

/** A response to updating institution investment goals */
export type UpdateInstitutionInvestmentGoalsResponse = {
  __typename?: 'UpdateInstitutionInvestmentGoalsResponse';
  errors?: Maybe<Array<InputError>>;
  institution: Institution;
};

/** Input for updating investment goals */
export type UpdateInvestmentGoalsInput = {
  /** Chosen option IDs */
  optionIds: Array<Scalars['ID']>;
};

/** Update onboarding input */
export type UpdateOnboardingInput = {
  customerAgreementSubmissionStatus?: Maybe<SubmissionStatus>;
  hasAgreedToClickwrapAgreement?: Maybe<Scalars['Boolean']>;
  identityVerificationSubmissionStatus?: Maybe<SubmissionStatus>;
};

/** Inputs for updating a password for an auth'd user */
export type UpdatePasswordInput = {
  /** Current password */
  currentPassword: Scalars['String'];
  /** Password */
  password: Scalars['String'];
  /** Password confirmation */
  passwordConfirmation: Scalars['String'];
};

export type UpdateTransactionModificationAcknowledgmentInput = {
  status: UpdateTransactionModificationAcknowledgmentStatus;
};

export type UpdateTransactionModificationAcknowledgmentResponse = {
  __typename?: 'UpdateTransactionModificationAcknowledgmentResponse';
  errors?: Maybe<Array<InputError>>;
  transactionModificationAcknowledgment?: Maybe<TransactionModificationAcknowledgment>;
};

export enum UpdateTransactionModificationAcknowledgmentStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type UpdateTransactionSellerBankAccountIdResponse = {
  __typename?: 'UpdateTransactionSellerBankAccountIdResponse';
  errors?: Maybe<Array<InputError>>;
  transaction?: Maybe<Transaction>;
};

export type UpdateTransactionSellerBankAccountRequestStatusResponse = {
  __typename?: 'UpdateTransactionSellerBankAccountRequestStatusResponse';
  errors?: Maybe<Array<InputError>>;
  transaction?: Maybe<Transaction>;
};

/** Update Trusted Contact Person input */
export type UpdateTrustedContactPersonInput = {
  /** Optional map of contact info */
  contactInfo?: Maybe<ContactInfoInput>;
  /** Bool representing if contact info is provided */
  isContactProvided?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  caAgreedToInvestmentRisk?: Maybe<Scalars['Boolean']>;
  caCanEvaluateInvestmentRisk?: Maybe<Scalars['Boolean']>;
  caIsPermittedClient?: Maybe<CaIsPermittedClientAnswer>;
  countryId?: Maybe<Scalars['ID']>;
  interestedInLoan?: Maybe<Scalars['Boolean']>;
  investorStatus?: Maybe<InvestorStatus>;
  investorType?: Maybe<InvestorType>;
  jobTitle?: Maybe<Scalars['String']>;
  requiresFunding?: Maybe<Scalars['Boolean']>;
  suitableInvestor?: Maybe<Scalars['Boolean']>;
};

/** Upsert institution input */
export type UpsertInstitutionInput = {
  /** Canada Institution Onboarding - Whether or not the firm/fund agreed to investment risks */
  caAgreedToInvestmentRisk?: Maybe<Scalars['Boolean']>;
  /** Canada Institution Onboarding - Whether or not the firm/fund is a Permitted Client */
  caIsPermittedClient?: Maybe<CaIsPermittedClientAnswer>;
  /** Canada Institution Onboarding - The jurisdiction in which the Canadian institution is incorporated */
  caJurisdictionOfIncorporation?: Maybe<Scalars['String']>;
  /** City where institution is located */
  city?: Maybe<Scalars['String']>;
  /** Country id representing Country where institution is located */
  countryId?: Maybe<Scalars['String']>;
  /** Institution entity type, Corporation, LLC, Partnership, or Other */
  entityType?: Maybe<InstitutionEntityType>;
  /** Broker dealer, intermediary, or agent acting on behalf of an unrelated client */
  isBrokerDealer?: Maybe<Scalars['Boolean']>;
  /** FINRA Rule 4512(c) Institution Account */
  isFinraInstitutionAccount?: Maybe<Scalars['Boolean']>;
  /** Country id representing the jurisdiction wherein the institution was incorporated */
  jurisdictionOfIncorporationId?: Maybe<Scalars['String']>;
  /** Legal name for institution */
  legalName?: Maybe<Scalars['String']>;
  /** Legal signatory representative's email for institution */
  legalSignatoryEmail?: Maybe<Scalars['String']>;
  /** Legal signatory representative's name for institution */
  legalSignatoryName?: Maybe<Scalars['String']>;
  /** Postal code where institution is located */
  postalCode?: Maybe<Scalars['String']>;
  /** Province/State/Region where institution is located */
  region?: Maybe<Scalars['String']>;
  /** Street address where institution is located */
  streetAddress?: Maybe<Scalars['String']>;
  /** Is the institution suitable */
  suitable?: Maybe<Scalars['Boolean']>;
};

/** A signed up user */
export type User = {
  __typename?: 'User';
  accreditationAnswers: Array<AccreditationAnswer>;
  accreditationLastUpdatedAt?: Maybe<Scalars['DateTime']>;
  accreditationQuestions: Array<AccreditationQuestion>;
  accredited: Scalars['Boolean'];
  activity: UserActivity;
  agreedToCustomerAgreement?: Maybe<Scalars['Boolean']>;
  brokerInfo?: Maybe<BrokerInfo>;
  caAgreedToInvestmentRisk?: Maybe<Scalars['Boolean']>;
  caCanEvaluateInvestmentRisk?: Maybe<Scalars['Boolean']>;
  caIsPermittedClient?: Maybe<CaIsPermittedClientAnswer>;
  chatIdHash: Scalars['String'];
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['ID']>;
  deactivationReason?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  entities: Array<Entity>;
  feeDiscountApplications: Array<FeeDiscountApplication>;
  /** Whether the user is a FINRA registered broker */
  finraRegistered?: Maybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  hasHolding: Scalars['Boolean'];
  /** @deprecated Use is_hiive_user instead */
  hiiveUser: Scalars['Boolean'];
  id: Scalars['ID'];
  identityVerificationStatus?: Maybe<IdentityVerificationStatus>;
  identityVerified: Scalars['Boolean'];
  insertedAt: Scalars['DateTime'];
  institution?: Maybe<Institution>;
  institutionId?: Maybe<Scalars['ID']>;
  interestedInLoan?: Maybe<Scalars['Boolean']>;
  investingGoals?: Maybe<Array<Maybe<InvestingGoalAnswer>>>;
  /** @deprecated This field is deprecated and will be removed in a future release. Please use `suitabilityAnswers` instead. */
  investmentGoalAnswers: Array<InvestmentGoalAnswer>;
  /** @deprecated Removed - use investment_goal_answers instead */
  investmentGoalOptions: Array<InvestmentGoalOption>;
  investorStatus?: Maybe<InvestorStatus>;
  /** Whether the user is a trader or a seller */
  investorType?: Maybe<InvestorType>;
  isActive: Scalars['Boolean'];
  isHiiveUser: Scalars['Boolean'];
  isSuperadmin: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  manuallyApproved?: Maybe<Scalars['Boolean']>;
  membershipAgreementSigned: Scalars['Boolean'];
  multiFactorAuthentications: UserMultiFactorAuthentications;
  myPermissionsV2: Array<UserPermissionV2>;
  name: Scalars['String'];
  onboardingComplete: Scalars['Boolean'];
  onboardingV2?: Maybe<Onboarding>;
  phoneNumber: Scalars['String'];
  requiresFunding?: Maybe<Scalars['Boolean']>;
  roles: Array<UserRole>;
  suitabilityAnswers: Array<SuitabilityAnswer>;
  suitabilityLastUpdatedAt?: Maybe<Scalars['DateTime']>;
  suitableInvestor?: Maybe<Scalars['Boolean']>;
  trustedContactPerson?: Maybe<TrustedContactPerson>;
  updatedAt: Scalars['DateTime'];
  /** The list of companies on a user's watchlist */
  watchlist: Array<Company>;
};


/** A signed up user */
export type UserAccreditationAnswersArgs = {
  filterBy?: Maybe<AccreditationAnswerFilter>;
};


/** A signed up user */
export type UserInvestmentGoalAnswersArgs = {
  filterBy?: Maybe<InvestmentGoalAnswerFilter>;
};


/** A signed up user */
export type UserSuitabilityAnswersArgs = {
  filterBy?: Maybe<SuitabilityAnswerFilter>;
};

export type UserActivity = {
  __typename?: 'UserActivity';
  /** A list of companies that the user is involved with (has listings/standing_bids/bids on) */
  companies: Array<Company>;
  myBids: Array<Bid>;
  myInstitutionBids: Array<Bid>;
  myInstitutionListings: Array<Listing>;
  myInstitutionStandingBids: Array<StandingBid>;
  myInstitutionTransactions: Array<Transaction>;
  myListings: Array<Listing>;
  myPendingTransactionModificationAcknowledgments: Array<TransactionModificationAcknowledgment>;
  myStandingBids: Array<StandingBid>;
  myTransactions: Array<Transaction>;
};

export enum UserMultiFactorAuthenticationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UserMultiFactorAuthentications = {
  __typename?: 'UserMultiFactorAuthentications';
  app: UserMultiFactorAuthenticationStatus;
  sms: UserMultiFactorAuthenticationStatus;
};

/** User response, but with errors */
export type UserMutationResponse = {
  __typename?: 'UserMutationResponse';
  errors?: Maybe<Array<InputError>>;
  user?: Maybe<User>;
};

export enum UserPermissionV2 {
  AcceptStandingBid = 'ACCEPT_STANDING_BID',
  AccessPlatform = 'ACCESS_PLATFORM',
  ApproveInstitution = 'APPROVE_INSTITUTION',
  CanUpdateAccreditation = 'CAN_UPDATE_ACCREDITATION',
  CanUpdateSuitability = 'CAN_UPDATE_SUITABILITY',
  CanViewAccreditation = 'CAN_VIEW_ACCREDITATION',
  CanViewSuitability = 'CAN_VIEW_SUITABILITY',
  CreateInstitution = 'CREATE_INSTITUTION',
  CreateListing = 'CREATE_LISTING',
  PlaceBid = 'PLACE_BID',
  PlaceStandingBid = 'PLACE_STANDING_BID',
  SeeSensitiveText = 'SEE_SENSITIVE_TEXT',
  SendMessage = 'SEND_MESSAGE',
  SignUnsignedCustomerAgreement = 'SIGN_UNSIGNED_CUSTOMER_AGREEMENT',
  StartDiscussion = 'START_DISCUSSION'
}

export enum UserRole {
  Admin = 'ADMIN',
  Buyer = 'BUYER',
  Seller = 'SELLER',
  Signer = 'SIGNER',
  Trader = 'TRADER',
  Viewer = 'VIEWER'
}

export enum ValidTransferMethod {
  Direct = 'DIRECT',
  ForwardContract = 'FORWARD_CONTRACT',
  SpvLister = 'SPV_LISTER',
  SpvThirdparty = 'SPV_THIRDPARTY',
  Unknown = 'UNKNOWN'
}

export type VeryBasicInstitution = {
  __typename?: 'VeryBasicInstitution';
  id: Scalars['ID'];
  legalName: Scalars['String'];
};

export type VeryBasicUser = {
  __typename?: 'VeryBasicUser';
  firstName: Scalars['String'];
  /** @deprecated Use is_hiive_user instead */
  hiiveUser: Scalars['Boolean'];
  id: Scalars['ID'];
  institution?: Maybe<VeryBasicInstitution>;
  institutionId?: Maybe<Scalars['ID']>;
  isHiiveUser: Scalars['Boolean'];
  lastName: Scalars['String'];
  name: Scalars['String'];
};

export type ViewTltAlternateSignerTransactionDocumentResponse = {
  __typename?: 'ViewTltAlternateSignerTransactionDocumentResponse';
  errors?: Maybe<Array<InputError>>;
  url?: Maybe<Scalars['String']>;
};

/** Represents dynamically computed commission for a listing */
export type VirtualCommission = {
  __typename?: 'VirtualCommission';
  commissionAmount: Scalars['Int'];
  feeDiscountAmount: Scalars['Int'];
  flatFeeAmount: Scalars['Int'];
  netFees: Scalars['Int'];
};

export type WithdrawListingInput = {
  muteNotifications?: Maybe<Scalars['Boolean']>;
};

export type WithdrawStandingBidInput = {
  muteNotifyWatchers?: Maybe<Scalars['Boolean']>;
};

export type UpdateIndividualAccreditationAnswerMutationVariables = Exact<{
  input: UpdateAccreditationAnswerInput;
}>;


export type UpdateIndividualAccreditationAnswerMutation = (
  { __typename?: 'RootMutationType' }
  & { updateIndividualAccreditationAnswer?: Maybe<(
    { __typename?: 'UpdateIndividualAccreditationAnswerResponse' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  )> }
);

export type HoldingsQueryVariables = Exact<{ [key: string]: never; }>;


export type HoldingsQuery = (
  { __typename?: 'RootQueryType' }
  & { myHoldings: (
    { __typename?: 'MyHoldingsResponse' }
    & { holdingsV2?: Maybe<Array<(
      { __typename?: 'Holding' }
      & ListedHoldingFragment
    ) | (
      { __typename?: 'UnlistedHolding' }
      & UnlistedHoldingFragment
    )>> }
  ) }
);

export type ListedHoldingFragment = (
  { __typename?: 'Holding' }
  & Pick<Holding, 'companyId' | 'id' | 'numSharesV2'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'logoUrl'>
  ) }
);

export type UnlistedHoldingFragment = (
  { __typename?: 'UnlistedHolding' }
  & Pick<UnlistedHolding, 'companyName' | 'id' | 'numSharesV2'>
);

export type InstitutionInvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type InstitutionInvitationsQuery = (
  { __typename?: 'RootQueryType' }
  & { institutionInvitations: Array<(
    { __typename?: 'Invitation' }
    & InstitutionInvitationsTableInvitationFragment
  )> }
);

export type InstitutionInvitationsTableInvitationFragment = (
  { __typename?: 'Invitation' }
  & Pick<Invitation, 'id' | 'email' | 'roles' | 'status' | 'insertedAt' | 'updatedAt'>
);

export type InstitutionUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type InstitutionUsersQuery = (
  { __typename?: 'RootQueryType' }
  & { institutionUsers: Array<(
    { __typename?: 'User' }
    & BasicUserFragment
  )> }
);

export type InvestmentGoalAnswerFragment = (
  { __typename?: 'InvestmentGoalAnswer' }
  & Pick<InvestmentGoalAnswer, 'id' | 'text'>
  & { option: (
    { __typename?: 'InvestmentGoalOption' }
    & Pick<InvestmentGoalOption, 'id' | 'text' | 'custom'>
    & { question: (
      { __typename?: 'InvestmentGoalQuestion' }
      & Pick<InvestmentGoalQuestion, 'id' | 'text' | 'description' | 'order'>
    ) }
  ) }
);

export type UpdateCaIndividualInvestmentGoalsMutationVariables = Exact<{
  input: UpdateCaIndividualInvestmentGoalsInput;
}>;


export type UpdateCaIndividualInvestmentGoalsMutation = (
  { __typename?: 'RootMutationType' }
  & { updateCaIndividualInvestmentGoals?: Maybe<(
    { __typename?: 'UpdateIndividualInvestmentGoalsResponse' }
    & { user: (
      { __typename?: 'User' }
      & BasicUserFragment
    ) }
  )> }
);

export type UpdateIndividualInvestmentGoalsMutationVariables = Exact<{
  optionIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdateIndividualInvestmentGoalsMutation = (
  { __typename?: 'RootMutationType' }
  & { updateIndividualInvestmentGoals?: Maybe<(
    { __typename?: 'UpdateIndividualInvestmentGoalsResponse' }
    & { user: (
      { __typename?: 'User' }
      & BasicUserFragment
    ) }
  )> }
);

export type UpdateInstitutionInvestmentGoalsMutationVariables = Exact<{
  optionIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdateInstitutionInvestmentGoalsMutation = (
  { __typename?: 'RootMutationType' }
  & { updateInstitutionInvestmentGoals?: Maybe<(
    { __typename?: 'UpdateInstitutionInvestmentGoalsResponse' }
    & { institution: (
      { __typename?: 'Institution' }
      & Pick<Institution, 'id'>
    ) }
  )> }
);

export type UpdateInvestmentGoalsMutationVariables = Exact<{
  optionIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdateInvestmentGoalsMutation = (
  { __typename?: 'RootMutationType' }
  & { updateIndividualInvestmentGoals?: Maybe<(
    { __typename?: 'UpdateIndividualInvestmentGoalsResponse' }
    & { user: (
      { __typename?: 'User' }
      & BasicUserFragment
    ) }
  )> }
);

export type CreateTrustedContactPersonMutationVariables = Exact<{
  input: CreateTrustedContactPersonInput;
}>;


export type CreateTrustedContactPersonMutation = (
  { __typename?: 'RootMutationType' }
  & { createTrustedContactPerson: (
    { __typename?: 'TrustedContactPersonMutationResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>>, trustedContactPerson?: Maybe<(
      { __typename?: 'TrustedContactPerson' }
      & Pick<TrustedContactPerson, 'isContactProvided'>
      & { contactInfo?: Maybe<(
        { __typename?: 'ContactInfo' }
        & Pick<ContactInfo, 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'relationship'>
      )> }
    )> }
  ) }
);

export type UpdateTrustedContactPersonMutationVariables = Exact<{
  input: UpdateTrustedContactPersonInput;
}>;


export type UpdateTrustedContactPersonMutation = (
  { __typename?: 'RootMutationType' }
  & { updateTrustedContactPerson: (
    { __typename?: 'TrustedContactPersonMutationResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>>, trustedContactPerson?: Maybe<(
      { __typename?: 'TrustedContactPerson' }
      & Pick<TrustedContactPerson, 'isContactProvided'>
      & { contactInfo?: Maybe<(
        { __typename?: 'ContactInfo' }
        & Pick<ContactInfo, 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'relationship'>
      )> }
    )> }
  ) }
);

export type UpdateInstitutionComplianceEmailMutationVariables = Exact<{
  institutionId: Scalars['ID'];
  complianceEmail?: Maybe<Scalars['String']>;
}>;


export type UpdateInstitutionComplianceEmailMutation = (
  { __typename?: 'RootMutationType' }
  & { updateInstitutionComplianceEmail: (
    { __typename?: 'InstitutionMutationResponse' }
    & { institution?: Maybe<(
      { __typename?: 'Institution' }
      & Pick<Institution, 'id' | 'complianceEmail'>
    )> }
  ) }
);

export type CompletedTransfersCardMyActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type CompletedTransfersCardMyActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { myActivity: (
    { __typename?: 'UserActivity' }
    & { myTransactions: Array<(
      { __typename?: 'Transaction' }
      & CompletedTransfersCardTransactionFragment
    )> }
  ) }
);

export type CompletedTransfersCardTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'state' | 'closedFeePaidAt' | 'closedFeePendingAt' | 'insertedAt'>
  & { bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'updatedAt' | 'buyerId' | 'brokerId' | 'counterpartyId' | 'buyerInstitutionId' | 'counterpartyInstitutionId' | 'state'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'logoUrl'>
    ) }
    & GetIsBuyerForBidBidFragment
    & GetIsCounterpartyForBidBidFragment
  ), document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'type'>
  )> }
  & MakeUrlTransactionFragment
);

export type CurrentSuitabilityQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentSuitabilityQuery = (
  { __typename?: 'RootQueryType' }
  & { investmentGoalAnswersCurrent?: Maybe<(
    { __typename?: 'InvestmentGoalAnswersCurrentResponse' }
    & OutdatedResponseFragment
  )> }
);

export type OutdatedResponseFragment = (
  { __typename?: 'InvestmentGoalAnswersCurrentResponse' }
  & Pick<InvestmentGoalAnswersCurrentResponse, 'current'>
);

export type InvitationByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type InvitationByTokenQuery = (
  { __typename?: 'RootQueryType' }
  & { invitationByToken?: Maybe<(
    { __typename?: 'Invitation' }
    & InvitationPageInvitationFragment
  )> }
);

export type InvitationPageInvitationFragment = (
  { __typename?: 'Invitation' }
  & Pick<Invitation, 'id' | 'email'>
);

export type SignupConfigFragment = (
  { __typename?: 'Config' }
  & Pick<Config, 'requireSignupKey'>
);

export type ActivityCardListingNumShareAndPriceDetailsFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptedBidPrices' | 'acceptPartialBid' | 'listingPricePerShare' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'numSharesSoldConditionally' | 'shareTypeV2' | 'state' | 'transferMethod' | 'solicited'>
);

export type ActivityCardStandingBidCardFooterFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'expireAt' | 'state' | 'acceptedBidPrices'>
);

export type ActivityCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'logoUrl' | 'name'>
);

export type ActivityCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerId' | 'buyerInstitutionId' | 'state'>
  & { standingBid?: Maybe<(
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id'>
  )> }
  & GetIsBuyerForBidBidFragment
);

export type ActivityCardTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'state' | 'signingProcedure'>
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & ActivityCardDocumentFragment
    & UseHaveISignedDocumentDocumentFragment
  )>, counterparty?: Maybe<(
    { __typename?: 'Counterparty' }
    & Pick<Counterparty, 'id'>
    & ActivityCardTransactionCounterpartyFragment
  )> }
);

export type ActivityCardDocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'type'>
  & { signers: Array<(
    { __typename?: 'DocumentSigner' }
    & Pick<DocumentSigner, 'id'>
    & ActivityCardDocumentSignersFragment
  )> }
);

export type ActivityCardTransactionCounterpartyFragment = (
  { __typename?: 'Counterparty' }
  & Pick<Counterparty, 'id' | 'name' | 'institutionId'>
);

export type ActivityCardDocumentSignersFragment = (
  { __typename?: 'DocumentSigner' }
  & Pick<DocumentSigner, 'id' | 'userId' | 'institutionId' | 'alternateSignerId'>
);

export type ActivityCardListingCardFooterListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptedBidPrices' | 'expireAt' | 'transferMethod' | 'state' | 'numActiveBids' | 'numCounteredBids' | 'solicited' | 'insertedAt'>
  & { bids: Array<(
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
    & CompanyOpenBidsBadgeBidFragment
  )> }
  & GetShowListingSolicitedStateListingFragment
);

export type ActivityCardMostRecentMessageDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & { mostRecentMessage?: Maybe<(
    { __typename?: 'DiscussionMessage' }
    & Pick<DiscussionMessage, 'id' | 'messageBody'>
    & GetIsSensitiveMessageDiscussionMessageFragment
  )> }
  & GetIsSensitiveMessageDiscussionFragment
);

export type ActivityCardStandingBidCardFooterStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'expireAt' | 'state' | 'acceptedBidPrices'>
);

export type ActivityCardStandingBidNumSharesAndPriceDetailsStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'pricePerShare' | 'numSharesOriginal' | 'numSharesAvailable' | 'acceptedBidPrices' | 'numSharesAccepted'>
  & GetIsPartiallyAcceptedStandingBidStandingBidFragment
  & GetIsConditionallyCompletedStandingBidStandingBidFragment
);

export type ActivityCardStandingBidSolicitedCardBodyStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ) }
    )> }
  ) }
);

export type BrokerBidActivityCardTimestampsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'insertedAt' | 'placedAt' | 'expireAt' | 'state'>
);

export type AcceptDiscussionRequestFormDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'initiatorId' | 'myPermissionsV2'>
  & { topic: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'sellerId'>
  ) | (
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id' | 'buyerId' | 'buyerInstitutionId'>
  ) }
);

export type DiscussionMessageDiscussionMessageFragment = (
  { __typename?: 'DiscussionMessage' }
  & Pick<DiscussionMessage, 'id' | 'insertedAt' | 'messageBody'>
  & { sender: (
    { __typename?: 'VeryBasicUser' }
    & Pick<VeryBasicUser, 'id' | 'isHiiveUser'>
  ) }
  & GetIsSenderDiscussionMessageFragment
  & GetIsSenderInstitutionDiscussionMessageFragment
  & UseMessageSenderDisplayNameDiscussionMessageFragment
  & GetIsSensitiveMessageDiscussionMessageFragment
);

export type DiscussionMessageDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & UseMessageSenderDisplayNameDiscussionFragment
  & GetIsSensitiveMessageDiscussionFragment
);

export type DiscussionMessageListDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'initiatorId' | 'state' | 'counterpartyHasReplied' | 'numUnreadMessages'>
  & { topic: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'fromHiive' | 'sellerId'>
  ) | (
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id' | 'fromHiive' | 'buyerId'>
  ) }
  & DiscussionMessageDiscussionFragment
);

export type DiscussionMessageListDiscussionMessageFragment = (
  { __typename?: 'DiscussionMessage' }
  & Pick<DiscussionMessage, 'id' | 'senderId'>
  & DiscussionMessageDiscussionMessageFragment
);

export type DiscussionThreadOnNewMessageSubscriptionVariables = Exact<{
  discussionId: Scalars['ID'];
}>;


export type DiscussionThreadOnNewMessageSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { onNewMessage: (
    { __typename?: 'DiscussionMessage' }
    & DiscussionThreadCardDiscussionMessageFragment
  ) }
);

export type DiscussionThreadCardDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & { messages: Array<(
    { __typename?: 'DiscussionMessage' }
    & Pick<DiscussionMessage, 'id'>
    & DiscussionThreadCardDiscussionMessageFragment
  )>, topic: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & GetDiscussionTopicCounterpartyTextListingFragment
  ) | (
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id'>
    & GetDiscussionTopicCounterpartyTextStandingBidFragment
  ) }
  & DiscussionMessageListDiscussionFragment
  & DiscussionThreadCardFooterDiscussionFragment
);

export type DiscussionThreadCardDiscussionMessageFragment = (
  { __typename?: 'DiscussionMessage' }
  & Pick<DiscussionMessage, 'id'>
  & DiscussionMessageListDiscussionMessageFragment
);

export type DiscussionThreadCardFooterDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'myMembershipState'>
  & GetAvailableDiscussionActionsDiscussionFragment
  & AcceptDiscussionRequestFormDiscussionFragment
  & SendMessageFormDiscussionFragment
);

export type MyDiscussionListDiscussionsQueryVariables = Exact<{
  topicFilter?: Maybe<MessageTopic>;
}>;


export type MyDiscussionListDiscussionsQuery = (
  { __typename?: 'RootQueryType' }
  & { myDiscussions: Array<(
    { __typename?: 'Discussion' }
    & MyDiscussionListDiscussionFragment
  )> }
);

export type StandingBidSellerDiscussionListDiscussionQueryVariables = Exact<{
  standingBidId: Scalars['ID'];
}>;


export type StandingBidSellerDiscussionListDiscussionQuery = (
  { __typename?: 'RootQueryType' }
  & { myDiscussions: Array<(
    { __typename?: 'Discussion' }
    & StandingBidSellerDiscussionListDiscussionFragment
  )> }
);

export type StandingBidBuyerDiscussionListDiscussionsQueryVariables = Exact<{
  standingBidId: Scalars['ID'];
}>;


export type StandingBidBuyerDiscussionListDiscussionsQuery = (
  { __typename?: 'RootQueryType' }
  & { myDiscussions: Array<(
    { __typename?: 'Discussion' }
    & StandingBidBuyerDiscussionListDiscussionFragment
  )> }
);

export type MyDiscussionListDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'state' | 'displayId' | 'numUnreadMessages' | 'totalNumMessages'>
  & { mostRecentMessage?: Maybe<(
    { __typename?: 'DiscussionMessage' }
    & Pick<DiscussionMessage, 'id' | 'senderId' | 'messageBody' | 'insertedAt'>
    & { sender: (
      { __typename?: 'VeryBasicUser' }
      & Pick<VeryBasicUser, 'institutionId' | 'id'>
    ) }
  )>, members: Array<(
    { __typename?: 'VeryBasicUser' }
    & Pick<VeryBasicUser, 'id'>
  )>, topic: (
    { __typename?: 'Listing' }
    & MyDiscussionListListingFragment
  ) | (
    { __typename?: 'StandingBid' }
    & MyDiscussionListStandingBidFragment
  ) }
);

export type MyDiscussionListStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'displayId' | 'buyerId' | 'buyerInstitutionId' | 'fromHiive'>
);

export type MyDiscussionListListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'displayId' | 'sellerId' | 'sellerInstitutionId' | 'fromHiive'>
);

export type StandingBidSellerDiscussionListDiscussionFragment = (
  { __typename?: 'Discussion' }
  & MyDiscussionListDiscussionFragment
  & DiscussionThreadCardDiscussionFragment
);

export type StandingBidBuyerDiscussionListDiscussionFragment = (
  { __typename?: 'Discussion' }
  & MyDiscussionListDiscussionFragment
);

export type OffPlatformReminderDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & { topic: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
  ) | (
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id'>
  ) }
  & GetDiscussionDocumentTextDiscussionFragment
  & GetDiscussionIsSellerDiscussionFragment
);

export type SendMessageFormDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & OffPlatformReminderDiscussionFragment
);

export type MessageCounterpartyCardListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'fromHiive' | 'isHiiveSpv'>
  & GetDiscussionTopicCounterpartyTextListingFragment
  & GetDiscussionTopicCounterpartyShortTextListingFragment
);

export type MessageCounterpartyCardStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'fromHiive'>
  & GetDiscussionTopicCounterpartyTextStandingBidFragment
  & GetDiscussionTopicCounterpartyShortTextStandingBidFragment
);

export type StartDiscussionMutationVariables = Exact<{
  input: StartDiscussionV2Input;
}>;


export type StartDiscussionMutation = (
  { __typename?: 'RootMutationType' }
  & { startDiscussionV2?: Maybe<(
    { __typename?: 'DiscussionMutationResponse' }
    & { discussion: (
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
    ), errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type FocusedShareDetailsHeaderCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name' | 'id' | 'logoUrl'>
);

export type WatchlistComboboxListCompaniesQueryVariables = Exact<{
  orderBy: ListCompaniesOrderBy;
  first: Scalars['Int'];
  searchText?: Maybe<Scalars['String']>;
}>;


export type WatchlistComboboxListCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { listCompanies?: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyEdge' }
      & WatchlistComboboxCompanyEdgeFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type WatchlistComboboxCompanyEdgeFragment = (
  { __typename?: 'CompanyEdge' }
  & Pick<CompanyEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'Company' }
    & WatchlistComboboxCompanyFragment
  )> }
);

export type WatchlistComboboxCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
);

export type CurrentContextQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentContextQuery = (
  { __typename?: 'RootQueryType' }
  & { currentContext?: Maybe<(
    { __typename?: 'ClientContext' }
    & Pick<ClientContext, 'userNotificationToken'>
    & { currentActor: (
      { __typename?: 'User' }
      & UserWithInstitutionFragment
    ) }
  )> }
);

export type UserWithInstitutionFragment = (
  { __typename?: 'User' }
  & Pick<User, 'institutionId' | 'manuallyApproved' | 'finraRegistered' | 'investorType' | 'accreditationLastUpdatedAt' | 'suitabilityLastUpdatedAt' | 'chatIdHash'>
  & { institution?: Maybe<(
    { __typename?: 'Institution' }
    & UserWithInstitutionInstitutionFragment
  )>, entities: Array<(
    { __typename?: 'Entity' }
    & TransactingEntityItemEntityFragment
  )>, accreditationQuestions: Array<(
    { __typename?: 'AccreditationQuestion' }
    & Pick<AccreditationQuestion, 'id' | 'text'>
  )>, accreditationAnswers: Array<(
    { __typename?: 'AccreditationAnswer' }
    & Pick<AccreditationAnswer, 'text'>
    & { question: (
      { __typename?: 'AccreditationQuestion' }
      & Pick<AccreditationQuestion, 'key' | 'order' | 'id' | 'text'>
    ) }
  )>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, onboardingV2?: Maybe<(
    { __typename?: 'Onboarding' }
    & Pick<Onboarding, 'id' | 'currentStep' | 'hasAgreedToClickwrapAgreement'>
  )>, feeDiscountApplications: Array<(
    { __typename?: 'FeeDiscountApplication' }
    & Pick<FeeDiscountApplication, 'id'>
    & { feeDiscount: (
      { __typename?: 'FeeDiscount' }
      & FeeDiscountFragment
    ), company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    )> }
  )>, investmentGoalAnswers: Array<(
    { __typename?: 'InvestmentGoalAnswer' }
    & InvestmentGoalAnswerFragment
  )>, suitabilityAnswers: Array<(
    { __typename?: 'SuitabilityAnswer' }
    & SuitabilityAnswerFragment
  )>, multiFactorAuthentications: (
    { __typename?: 'UserMultiFactorAuthentications' }
    & Pick<UserMultiFactorAuthentications, 'app' | 'sms'>
  ), trustedContactPerson?: Maybe<(
    { __typename?: 'TrustedContactPerson' }
    & Pick<TrustedContactPerson, 'isContactProvided' | 'updatedAt'>
    & { contactInfo?: Maybe<(
      { __typename?: 'ContactInfo' }
      & Pick<ContactInfo, 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'relationship'>
    )> }
  )>, investingGoals?: Maybe<Array<Maybe<(
    { __typename?: 'InvestingGoalAnswer' }
    & { option: (
      { __typename?: 'InvestingGoalOption' }
      & Pick<InvestingGoalOption, 'id' | 'questionId'>
    ) }
  )>>> }
  & BasicUserFragment
);

export type UserWithInstitutionInstitutionFragment = (
  { __typename?: 'Institution' }
  & Pick<Institution, 'id' | 'myPermissionsV2' | 'streetAddress' | 'city' | 'region' | 'postalCode' | 'legalName' | 'legalSignatoryName' | 'legalSignatoryEmail' | 'membershipAgreementSigned' | 'isBrokerDealer' | 'complianceEmail' | 'accreditationLastUpdatedAt' | 'approved' | 'suitabilityLastUpdatedAt' | 'entityType'>
  & { country: (
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  ), jurisdictionOfIncorporation?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, accreditationQuestions: Array<(
    { __typename?: 'AccreditationQuestion' }
    & Pick<AccreditationQuestion, 'id' | 'text'>
  )>, accreditationAnswers: Array<(
    { __typename?: 'AccreditationAnswer' }
    & Pick<AccreditationAnswer, 'text'>
    & { question: (
      { __typename?: 'AccreditationQuestion' }
      & Pick<AccreditationQuestion, 'order' | 'id' | 'text'>
    ) }
  )>, investmentGoalAnswers: Array<(
    { __typename?: 'InvestmentGoalAnswer' }
    & InvestmentGoalAnswerFragment
  )>, suitabilityAnswers: Array<(
    { __typename?: 'SuitabilityAnswer' }
    & SuitabilityAnswerFragment
  )>, entities: Array<(
    { __typename?: 'Entity' }
    & TransactingEntityItemEntityFragment
  )> }
);

export type UserBankAccountFragment = (
  { __typename?: 'BankAccount' }
  & Pick<BankAccount, 'id' | 'insertedAt' | 'last4' | 'updatedAt' | 'verificationStatus' | 'verifiedAt' | 'verifiedById'>
  & { lastUsedTransaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & { bid: (
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'displayId'>
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'logoUrl'>
    ) }
  )> }
);

export type SuitabilityAnswerFragment = (
  { __typename?: 'SuitabilityAnswer' }
  & Pick<SuitabilityAnswer, 'id' | 'text'>
  & { suitabilityOption: (
    { __typename?: 'SuitabilityOption' }
    & Pick<SuitabilityOption, 'id' | 'text' | 'custom'>
    & { suitabilityQuestion: (
      { __typename?: 'SuitabilityQuestion' }
      & Pick<SuitabilityQuestion, 'id' | 'text' | 'description' | 'order'>
      & { suitabilityQuestionGroup: (
        { __typename?: 'SuitabilityQuestionGroup' }
        & Pick<SuitabilityQuestionGroup, 'id' | 'version'>
      ) }
    ) }
  ) }
);

export type AllInvestorsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllInvestorsQuery = (
  { __typename?: 'RootQueryType' }
  & { allInvestors: Array<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'name' | 'id'>
  )> }
);

export type BrowseCompaniesPageListCompaniesQueryVariables = Exact<{
  orderBy: ListCompaniesOrderBy;
  first: Scalars['Int'];
  investorIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  industryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  countryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  lastRoundPostValuationRange: LastRoundPostValuationRange;
  cursor?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompanyStatus> | CompanyStatus>;
}>;


export type BrowseCompaniesPageListCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { listCompanies?: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyEdge' }
      & BrowseCompaniesPageCompanyEdgeFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type BrowseCompaniesPageCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type BrowseCompaniesPageCountriesQuery = (
  { __typename?: 'RootQueryType' }
  & { allCompanyCountries: Array<(
    { __typename?: 'Country' }
    & Pick<Country, 'id'>
    & BrowseCompaniesPageCountryFragment
  )> }
);

export type BrowseCompaniesPageCountryFragment = (
  { __typename?: 'Country' }
  & Pick<Country, 'id' | 'name'>
);

export type BrowseCompaniesPageCompanyEdgeFragment = (
  { __typename?: 'CompanyEdge' }
  & Pick<CompanyEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & BrowseCompaniesPageCompanyFragment
  )> }
);

export type BrowseCompaniesPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & BrowseCompaniesPageCompaniesListCompanyFragment
);

export type BrowseCompaniesPageCompaniesListCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & BrowseCompaniesPageCompanyCardCompanyFragment
);

export type BrowseCompaniesPageCompanyActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'lastRoundPostValuation' | 'lastRoundPricePerShare' | 'lastRoundDate' | 'lastRoundSeries' | 'lastRoundRaised' | 'totalRaised'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & Pick<CompanyActivity, 'numOpenListings' | 'numOpenStandingBidsAndActiveBids'>
  ) }
);

export type BrowseCompaniesPageCompanyCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'logoUrl' | 'name' | 'description' | 'activeOnHiiveFiftyIndex'>
  & { investors: Array<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'id' | 'name'>
  )>, industries: Array<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'id' | 'name'>
  )> }
  & BrowseCompaniesPagePricingBlockCompanyFragment
  & BrowseCompaniesPageCompanyActivityCompanyFragment
);

export type BrowseCompaniesPagePricingBlockCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'lowestAsk' | 'highestBid' | 'lastTransaction'>
  )> }
);

export type CompanyPageActionsCompanyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyPageActionsCompanyByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & CompanyActionsCompanyFragment
  ) }
);

export type CompanyActionsCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'requiresApprovedBuyers'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { othersListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'state' | 'myPermissionsV2'>
      & { bids: Array<(
        { __typename?: 'Bid' }
        & Pick<Bid, 'id'>
        & CompanyActionsBidFragment
      )> }
    )> }
  ) }
  & GetAvailableCompanyActionsCompanyFragment
  & CreateListingModalCompanyFragment
  & PlaceStandingBidModalCompanyFragment
  & BrokerSubmitBidSequenceModalCompanyFragment
  & PlaceBidSequenceModalCompanyFragment
);

export type CompanyActionsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerInstitutionId' | 'state'>
  & FilterBidsIMadeBidFragment
);

export type CompanyPageMarketActivityCompanyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyPageMarketActivityCompanyByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & CompanyPageMarketActivityCompanyFragment
  ) }
);

export type CompanyPageMarketActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'numHolders' | 'numWatchers'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { othersListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'listingPricePerShare'>
      & CompanyPageMarketActivityListingFragment
    )>, othersStandingBids: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id' | 'pricePerShare'>
      & CompanyPageMarketActivityStandingBidFragment
    )> }
  ) }
  & GetAvailableCompanyActionsCompanyFragment
  & CreateListingModalCompanyFragment
  & PlaceStandingBidModalCompanyFragment
  & CompanyMarketActivityListingCardCompanyFragment
);

export type CompanyPageMarketActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'state' | 'sellerInstitutionId'>
  & CompanyMarketActivityListingCardListingFragment
);

export type CompanyPageMarketActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'state' | 'buyerInstitutionId'>
  & CompanyMarketActivityStandingBidCardStandingBidFragment
);

export type CompanyPageActivityCompanyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyPageActivityCompanyByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & CompanyActivityCompanyFragment
  ) }
);

export type CompanyActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & { feeDiscountApplications: Array<(
    { __typename?: 'FeeDiscountApplication' }
    & CompanyActivityCompanyFeeDiscountApplicationFragment
  )> }
  & CompanyActionsCompanyFragment
  & CompanyPageYourActivityCompanyFragment
  & CompanyPageMarketActivityCompanyFragment
);

export type CompanyActivityCompanyFeeDiscountApplicationFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id'>
  & { feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & FeeDiscountFragment
  ) }
);

export type CompanyPageCompanyDescriptionCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'logoUrl' | 'name' | 'description' | 'activeOnHiiveFiftyIndex'>
  & CompanyPricingCompanyFragment
  & CompanyMarketActivityCompanyFragment
  & CompanyPageSuperadminDetailsTileCompanyFragment
  & CompanySecuritySpecialistContactCompanyFragment
);

export type CompanyPageInfoCompanyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyPageInfoCompanyByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & CompanyPageInfoCompanyFragment
  ) }
);

export type CompanyPageInfoCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'hasAskPriceComparisonChart'>
  & { priceChartConfig: (
    { __typename?: 'PriceChartConfig' }
    & Pick<PriceChartConfig, 'displayChart' | 'graphDataStart' | 'availableTimeRanges'>
    & { graphIndicators: (
      { __typename?: 'GraphIndicators' }
      & Pick<GraphIndicators, 'acceptedBids' | 'lastRoundPps' | 'postedBidsAsks'>
    ), priceLines: (
      { __typename?: 'PriceLines' }
      & Pick<PriceLines, 'shareTypeCommon' | 'shareTypePreferred' | 'transferTypeDirect' | 'transferTypeIndirect'>
    ) }
  ) }
  & CompanyPageCompanyDescriptionCardCompanyFragment
  & CompanyLastRoundDetailsCardCompanyFragment
);

export type CompanyPageContentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyPageContentQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & CompanyPageContentCompanyFragment
  ) }
);

export type CompanyPageContentCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { feeDiscountApplications: Array<(
    { __typename?: 'FeeDiscountApplication' }
    & CompanyPageContentCompanyFeeDiscountApplicationFragment
  )> }
);

export type CompanyPageContentCompanyFeeDiscountApplicationFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id'>
  & { feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & FeeDiscountFragment
  ) }
);

export type CompanyPageSuperadminDetailsTileCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'status' | 'tradeable'>
);

export type CompanyPageYourActivityCompanyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyPageYourActivityCompanyByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & CompanyPageYourActivityCompanyFragment
  ) }
);

export type CompanyPageYourActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { myBids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'expireAt'>
      & CompanyPageYourActivityBidFragment
    )>, myListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'expireAt'>
      & CompanyPageYourActivityListingFragment
    )>, myStandingBids: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id' | 'expireAt'>
      & CompanyPageYourActivityStandingBidFragment
    )>, myInstitutionBids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'expireAt'>
      & CompanyPageYourActivityBidFragment
    )>, myInstitutionListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'expireAt'>
      & CompanyPageYourActivityListingFragment
    )>, myInstitutionStandingBids: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id' | 'expireAt'>
      & CompanyPageYourActivityStandingBidFragment
    )>, myActiveDiscussions: Array<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
      & CompanyPageYourActivityDiscussionFragment
    )> }
  ) }
);

export type CompanyPageYourActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'buyerId'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'rofr'>
    & CompanyPageYourActivityTransactionFragment
  )> }
  & BrokerCompanyBidCardBidFragment
  & CompanyYourBidCardBidFragment
);

export type CompanyPageYourActivityTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'state'>
);

export type CompanyPageYourActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'state' | 'buyerId'>
  & CompanyYourActivityStandingBidCardStandingBidFragment
);

export type CompanyPageYourActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'state' | 'sellerId'>
  & CompanyYourActivityListingCardListingFragment
);

export type CompanyPageYourActivityDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'initiatorId'>
  & CompanyYourDiscussionCardDiscussionFragment
);

export type UnaccreditedSellerCompanyPageActionsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerCompanyPageActionsQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, myCompany: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & UnaccreditedSellerCompanyPageActionsCompanyFragment
    ) }
  ) }
);

export type UnaccreditedSellerCompanyPageActionsCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & GetAvailableCompanyActionsCompanyFragment
);

export type UnaccreditedSellerCompanyByIdActionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerCompanyByIdActionsQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & UnaccreditedSellerCompanyByIdActionsFragment
  ) }
);

export type UnaccreditedSellerCompanyByIdActionsFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )> }
  ) }
  & GetAvailableCompanyActionsCompanyFragment
);

export type UnaccreditedSellerCompanyPageDescriptionCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'logoUrl' | 'name' | 'description'>
  & CompanyPricingCompanyFragment
  & CompanyMarketActivityCompanyFragment
  & CompanySecuritySpecialistContactCompanyFragment
);

export type UnaccreditedSellerCompanyPageInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerCompanyPageInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & { priceChartConfig: (
        { __typename?: 'PriceChartConfig' }
        & Pick<PriceChartConfig, 'displayChart' | 'graphDataStart' | 'availableTimeRanges'>
        & { graphIndicators: (
          { __typename?: 'GraphIndicators' }
          & Pick<GraphIndicators, 'acceptedBids' | 'lastRoundPps' | 'postedBidsAsks'>
        ), priceLines: (
          { __typename?: 'PriceLines' }
          & Pick<PriceLines, 'shareTypeCommon' | 'shareTypePreferred' | 'transferTypeDirect' | 'transferTypeIndirect'>
        ) }
      ) }
      & UnaccreditedSellerCompanyPageDescriptionCardCompanyFragment
      & CompanyLastRoundDetailsCardCompanyFragment
    ) }
  ) }
);

export type UnaccreditedSellerCompanyPageMarketActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerCompanyPageMarketActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyCompanyActivity: (
    { __typename?: 'UnaccreditedSellerMyCompanyActivity' }
    & { otherListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
      & UnaccreditedSellerCompanyPageMarketActivityListingFragment
    )>, availableStandingBids: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
      & UnaccreditedSellerCompanyPageMarketActivityStandingBidFragment
    )> }
  ), unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & UnaccreditedSellerCompanyPageMarketActivityCompanyFragment
    ) }
  ) }
);

export type UnaccreditedSellerCompanyPageMarketActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & CompanyMarketActivityListingCardListingFragment
);

export type UnaccreditedSellerCompanyPageMarketActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & CompanyMarketActivityStandingBidCardStandingBidFragment
);

export type UnaccreditedSellerCompanyPageMarketActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'numWatchers' | 'numHolders'>
  & GetAvailableCompanyActionsCompanyFragment
  & CreateListingModalCompanyFragment
  & CompanyMarketActivityListingCardCompanyFragment
);

export type UnaccreditedSellerCompanyByIdMarketActivityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerCompanyByIdMarketActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & UnaccreditedSellerCompanyByIdMarketActivityCompanyFragment
  ) }
);

export type UnaccreditedSellerCompanyByIdMarketActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'numWatchers' | 'numHolders' | 'tradeable'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { othersListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
      & UnaccreditedSellerCompanyByIdMarketActivityListingFragment
    )>, othersStandingBids: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
      & UnaccreditedSellerCompanyByIdMarketActivityStandingBidFragment
    )> }
  ) }
  & GetAvailableCompanyActionsCompanyFragment
  & CreateListingModalCompanyFragment
  & CompanyMarketActivityListingCardCompanyFragment
);

export type UnaccreditedSellerCompanyByIdMarketActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & CompanyMarketActivityListingCardListingFragment
);

export type UnaccreditedSellerCompanyByIdMarketActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & CompanyMarketActivityStandingBidCardStandingBidFragment
);

export type UnaccreditedSellerCompanyPageMetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerCompanyPageMetadataQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ) }
);

export type UnaccreditedSellerCompanyPageYourActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerCompanyPageYourActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & UnaccreditedSellerCompanyPageYourActivityListingFragment
    )>, myCompany: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ) }
);

export type UnaccreditedSellerCompanyPageYourActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & CompanyYourActivityListingCardListingFragment
);

export type UnaccreditedSellerCompanyPageYourActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & CompanyYourActivityListingCardCompanyFragment
);

export type UnaccreditedSellerCompanyByIdYourActivityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerCompanyByIdYourActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & UnaccreditedSellerCompanyByIdYourActivityCompanyFragment
  ) }
);

export type UnaccreditedSellerCompanyByIdYourActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
      & UnaccreditedSellerCompanyByIdYourActivityListingFragment
    )> }
  ) }
  & CompanyYourActivityListingCardCompanyFragment
);

export type UnaccreditedSellerCompanyByIdYourActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & CompanyYourActivityListingCardListingFragment
);

export type UnaccreditedSellerCompanyPageQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerCompanyPageQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & UnaccreditedSellerCompanyPageCompanyFragment
    ) }
  ) }
);

export type UnaccreditedSellerCompanyPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { feeDiscountApplications: Array<(
    { __typename?: 'FeeDiscountApplication' }
    & UnaccreditedSellerCompanyPageFeeDiscountApplicationFragment
  )> }
);

export type UnaccreditedSellerCompanyPageFeeDiscountApplicationFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id'>
  & { feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & FeeDiscountFragment
  ) }
);

export type UnaccreditedSellerCompanyByIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerCompanyByIdPageQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & UnaccreditedSellerCompanyByIdPageCompanyFragment
  ) }
);

export type UnaccreditedSellerCompanyByIdPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { feeDiscountApplications: Array<(
    { __typename?: 'FeeDiscountApplication' }
    & UnaccreditedSellerCompanyPageFeeDiscountApplicationFragment
  )> }
);

export type UnaccreditedSellerCompanyByIdPageFeeDiscountApplicationFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id'>
  & { feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & FeeDiscountFragment
  ) }
);

export type CompanyComboboxCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
);

export type CompanyLastRoundDetailsCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'lastRoundSeries' | 'lastRoundDate' | 'lastRoundPricePerShare' | 'lastRoundRaised' | 'totalRaised' | 'lastRoundPostValuation'>
  & { investors: Array<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'id' | 'name'>
  )>, industries: Array<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'id' | 'name'>
  )> }
);

export type CompanyMarketActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & Pick<CompanyActivity, 'numOpenListings' | 'numOpenStandingBidsAndActiveBids'>
  ) }
);

export type CompanyMarketActivityBuyersBadgeCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'numWatchers'>
);

export type CompanyMarketActivityListingCardListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & CompanyListingCardHeaderListingFragment
  & CompanyListingCardBodyListingFragment
  & ActivityCardListingCardFooterListingFragment
);

export type CompanyMarketActivityListingCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & CompanyListingCardBodyCompanyFragment
);

export type CompanyMarketActivityStandingBidCardStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & CompanyStandingBidCardHeaderStandingBidFragment
  & CompanyStandingBidCardBodyStandingBidFragment
  & ActivityCardStandingBidCardFooterStandingBidFragment
);

export type CompanyMarketActivitySellersBadgeCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'numHolders' | 'numWatchers'>
);

export type CompanyPriceDataQueryVariables = Exact<{
  companyId: Scalars['ID'];
  startDate: Scalars['Date'];
  indicators: Array<Maybe<Indicator>> | Maybe<Indicator>;
  includeIndexPrice?: Maybe<Scalars['Boolean']>;
  includeIndexPriceTransferTypeDirect?: Maybe<Scalars['Boolean']>;
  includeIndexPriceTransferTypeIndirect?: Maybe<Scalars['Boolean']>;
  includeIndexPriceShareTypeCommon?: Maybe<Scalars['Boolean']>;
  includeIndexPriceShareTypePreferred?: Maybe<Scalars['Boolean']>;
  includeAcceptedBids?: Maybe<Scalars['Boolean']>;
  includeAcceptedBidsTransferTypeDirect?: Maybe<Scalars['Boolean']>;
  includeAcceptedBidsTransferTypeIndirect?: Maybe<Scalars['Boolean']>;
  includeAcceptedBidsShareTypeCommon?: Maybe<Scalars['Boolean']>;
  includeAcceptedBidsShareTypePreferred?: Maybe<Scalars['Boolean']>;
  includePostedBidsAsks?: Maybe<Scalars['Boolean']>;
  includePostedBidsAsksTransferTypeDirect?: Maybe<Scalars['Boolean']>;
  includePostedBidsAsksTransferTypeIndirect?: Maybe<Scalars['Boolean']>;
  includePostedBidsAsksShareTypeCommon?: Maybe<Scalars['Boolean']>;
  includePostedBidsAsksShareTypePreferred?: Maybe<Scalars['Boolean']>;
  includeLastRoundPrice?: Maybe<Scalars['Boolean']>;
}>;


export type CompanyPriceDataQuery = (
  { __typename?: 'RootQueryType' }
  & { companyPriceData: (
    { __typename?: 'CompanyPriceDataResponse' }
    & { dailyPriceData?: Maybe<Array<Maybe<(
      { __typename?: 'DailyPriceData' }
      & PriceDataFragment
    )>>>, indexPriceTrends?: Maybe<Array<Maybe<(
      { __typename?: 'IndexPriceTrend' }
      & PriceTrendFragment
    )>>> }
  ) }
);

export type PriceDataFragment = (
  { __typename?: 'DailyPriceData' }
  & MakeOptional<Pick<DailyPriceData, 'day' | 'indexPrice' | 'indexPriceTransferTypeDirect' | 'indexPriceTransferTypeIndirect' | 'indexPriceShareTypeCommon' | 'indexPriceShareTypePreferred' | 'acceptedBids' | 'acceptedBidsTransferTypeDirect' | 'acceptedBidsTransferTypeIndirect' | 'acceptedBidsShareTypeCommon' | 'acceptedBidsShareTypePreferred' | 'postedAsks' | 'postedAsksTransferTypeDirect' | 'postedAsksTransferTypeIndirect' | 'postedAsksShareTypeCommon' | 'postedAsksShareTypePreferred' | 'postedBids' | 'postedBidsTransferTypeDirect' | 'postedBidsTransferTypeIndirect' | 'postedBidsShareTypeCommon' | 'postedBidsShareTypePreferred' | 'lastRoundPrice'>, 'indexPrice' | 'indexPriceTransferTypeDirect' | 'indexPriceTransferTypeIndirect' | 'indexPriceShareTypeCommon' | 'indexPriceShareTypePreferred' | 'acceptedBids' | 'acceptedBidsTransferTypeDirect' | 'acceptedBidsTransferTypeIndirect' | 'acceptedBidsShareTypeCommon' | 'acceptedBidsShareTypePreferred' | 'postedAsks' | 'postedAsksTransferTypeDirect' | 'postedAsksTransferTypeIndirect' | 'postedAsksShareTypeCommon' | 'postedAsksShareTypePreferred' | 'postedBids' | 'postedBidsTransferTypeDirect' | 'postedBidsTransferTypeIndirect' | 'postedBidsShareTypeCommon' | 'postedBidsShareTypePreferred' | 'lastRoundPrice'>
);

export type PriceTrendFragment = (
  { __typename?: 'IndexPriceTrend' }
  & Pick<IndexPriceTrend, 'changePercentage' | 'currentPrice' | 'trendName'>
);

export type CompanyPricingCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
);

export type CompanySecuritySpecialistContactCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type CompanyStandingBidCardBodyStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'acceptsShares' | 'acceptsTransferMethods' | 'solicited'>
  & ActivityCardStandingBidNumSharesAndPriceDetailsStandingBidFragment
);

export type CompanyStandingBidCardBodyCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & SolicitedListingActivityCardCompanyFragment
);

export type CompanyStandingBidCardFooterStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'insertedAt'>
);

export type BrokerCompanyBidCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'pricePerShare'>
  & GetBidNumSharesActualBidFragment
  & MakeUrlBidFragment
  & BrokerBidActivityCardTimestampsBidFragment
);

export type CompanyYourActivityListingCardListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'expireAt' | 'numActiveBids' | 'numCounteredBids' | 'numInquiries' | 'numUnreadMessages'>
  & { bids: Array<(
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
    & CountTotalUnreadBidDiscussionMessagesBidFragment
    & CompanyOpenBidsBadgeBidFragment
  )> }
  & CompanyListingCardHeaderListingFragment
  & CompanyListingCardBodyListingFragment
  & CompanyListingCardFooterListingFragment
);

export type CompanyYourActivityListingCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & CompanyListingCardBodyCompanyFragment
);

export type CompanyYourActivityStandingBidCardStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'expireAt' | 'state' | 'acceptedBidPrices' | 'numDiscussions' | 'numUnreadMessages'>
  & CompanyStandingBidCardHeaderStandingBidFragment
  & CompanyStandingBidCardBodyStandingBidFragment
  & CompanyStandingBidCardFooterStandingBidFragment
);

export type CompanyYourActivityStandingBidCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & CompanyListingCardBodyCompanyFragment
);

export type CompanyYourBidCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'pricePerShare' | 'expireAt' | 'displayId'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'numUnreadMessages' | 'totalNumMessages'>
  )> }
  & GetBidNumSharesActualBidFragment
  & MakeUrlBidFragment
);

export type CompanyYourDiscussionCardDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'state' | 'displayId' | 'numUnreadMessages' | 'totalNumMessages'>
  & { mostRecentMessage?: Maybe<(
    { __typename?: 'DiscussionMessage' }
    & Pick<DiscussionMessage, 'id'>
  )>, topic: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'displayId'>
    & GetPostingTitleListingFragment
  ) | (
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id' | 'displayId'>
    & GetPostingTitleStandingBidFragment
  ) }
  & GetDiscussionStatusTextDiscussionFragment
  & ActivityCardMostRecentMessageDiscussionFragment
);

export type MarketActivityBlockCompanyFragment = (
  { __typename?: 'Company' }
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & MarketActivityBlockCompanyActivityFragment
  ) }
);

export type MarketActivityBlockCompanyActivityFragment = (
  { __typename?: 'CompanyActivity' }
  & Pick<CompanyActivity, 'numOpenListings' | 'numOpenStandingBidsAndActiveBids'>
);

export type PricingBlockCompanyFragment = (
  { __typename?: 'Company' }
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
);

export type CompanyListingCardBodyListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'transferMethod' | 'shareTypeV2' | 'solicited' | 'brokerId'>
  & ActivityCardListingNumShareAndPriceDetailsFragment
);

export type CompanyListingCardBodyCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & SolicitedListingActivityCardCompanyFragment
);

export type CompanyListingCardFooterListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'insertedAt'>
);

export type CompanyListingCardHeaderListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'displayId' | 'fromHiive'>
);

export type CompanyStandingBidCardHeaderStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'displayId' | 'fromHiive'>
);

export type CompanyStandingBidCardTransferDetailsStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'acceptsShares' | 'acceptsTransferMethods'>
);

export type CompanyOpenBidsBadgeBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerId' | 'state' | 'buyerInstitutionId'>
  & CountTotalUnreadBidDiscussionMessagesBidFragment
);

export type UserActivityMyActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type UserActivityMyActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { myActivity: (
    { __typename?: 'UserActivity' }
    & UserActivityActivityFragment
  ) }
);

export type UserActivityActivityFragment = (
  { __typename?: 'UserActivity' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & UserActivityCompanyFragment
  )>, myListings: Array<(
    { __typename?: 'Listing' }
    & UserActivityListingFragment
  )>, myStandingBids: Array<(
    { __typename?: 'StandingBid' }
    & UserActivityStandingBidFragment
  )>, myBids: Array<(
    { __typename?: 'Bid' }
    & UserActivityBidFragment
  )>, myInstitutionListings: Array<(
    { __typename?: 'Listing' }
    & UserActivityListingFragment
  )>, myInstitutionStandingBids: Array<(
    { __typename?: 'StandingBid' }
    & UserActivityStandingBidFragment
  )>, myInstitutionBids: Array<(
    { __typename?: 'Bid' }
    & UserActivityBidFragment
  )>, myTransactions: Array<(
    { __typename?: 'Transaction' }
    & UserActivityTransactionFragment
  )>, myInstitutionTransactions: Array<(
    { __typename?: 'Transaction' }
    & UserActivityTransactionFragment
  )>, myPendingTransactionModificationAcknowledgments: Array<(
    { __typename?: 'TransactionModificationAcknowledgment' }
    & UserActivityModificationAcknowledgmentsFragment
  )> }
);

export type UserActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
);

export type UserActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptedBidPrices' | 'acceptPartialBid' | 'shareTypeV2' | 'companyId' | 'sellerId' | 'shortId' | 'displayId' | 'state' | 'fromHiive' | 'transferMethod' | 'numUnreadMessages' | 'numDiscussions' | 'numActiveBids' | 'numCounteredBids' | 'expireAt' | 'insertedAt' | 'solicited'>
  & { company: (
    { __typename?: 'Company' }
    & UserActivityCompanyFragment
  ), bids: Array<(
    { __typename?: 'Bid' }
    & UserActivityBidOnListingFragment
  )> }
  & GetNumOfSharesListingFragment
  & GetPricePerShareListingFragment
  & ActivityCardListingCardFooterListingFragment
);

export type UserActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'companyId' | 'buyerId' | 'brokerId' | 'buyerInstitutionId' | 'shortId' | 'displayId' | 'counterpartyId' | 'brokerCounterpartyId' | 'state' | 'insertedAt' | 'expireAt'>
  & { company: (
    { __typename?: 'Company' }
    & UserActivityCompanyFragment
  ), discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'totalNumMessages' | 'numUnreadMessages'>
  )>, standingBid?: Maybe<(
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id' | 'shortId' | 'displayId'>
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'shortId'>
  ) }
  & GetNumOfSharesBidFragment
  & GetPricePerShareBidFragment
  & BrokerBidActivityCardTimestampsBidFragment
  & MakeUrlBidFragment
);

export type UserActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'acceptedBidPrices' | 'acceptsShares' | 'allowPartialAccept' | 'acceptsTransferMethods' | 'numSharesAccepted' | 'companyId' | 'state' | 'shortId' | 'displayId' | 'numUnreadMessages' | 'numDiscussions' | 'buyerId' | 'fromHiive' | 'expireAt' | 'insertedAt'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & UserActivityCompanyFragment
  ) }
  & GetNumOfSharesStandingBidFragment
  & GetPricePerShareStandingBidFragment
);

export type UserActivityBidOnListingFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerId' | 'buyerInstitutionId' | 'state'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'numUnreadMessages'>
  )> }
);

export type UserActivityTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'state' | 'signingProcedure' | 'rofr' | 'numShares' | 'pricePerShare' | 'buyerId' | 'sellerId' | 'buyerInstitutionId' | 'sellerInstitutionId'>
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & UserActivityTransactionDocumentFragment
  )>, bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
    & UserActivityBidFragment
  ) }
  & MakeUrlTransactionFragment
);

export type UserActivityTransactionDocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'type'>
  & { signers: Array<(
    { __typename?: 'DocumentSigner' }
    & Pick<DocumentSigner, 'id' | 'userId' | 'institutionId'>
  )> }
);

export type UserActivityModificationAcknowledgmentsFragment = (
  { __typename?: 'TransactionModificationAcknowledgment' }
  & Pick<TransactionModificationAcknowledgment, 'id'>
  & { transactionModification: (
    { __typename?: 'TransactionModification' }
    & Pick<TransactionModification, 'id'>
    & { transaction: (
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'bidId' | 'buyerId' | 'buyerBrokerId'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) }
  ) }
);

export type CompanyWatchlistActorQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyWatchlistActorQuery = (
  { __typename?: 'RootQueryType' }
  & { currentActor: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { watchlist: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & CompanyWatchlistCompanyFragment
    )> }
  ) }
);

export type CompanyWatchlistCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
);

export type IndexCompaniesPreviewQueryVariables = Exact<{ [key: string]: never; }>;


export type IndexCompaniesPreviewQuery = (
  { __typename?: 'RootQueryType' }
  & { indexCompanies?: Maybe<(
    { __typename?: 'IndexCompanyConnection' }
    & { edges: Array<(
      { __typename?: 'IndexCompanyEdge' }
      & IndexCompanyPreviewEdgeFragment
    )> }
  )> }
);

export type IndexCompanyPreviewEdgeFragment = (
  { __typename?: 'IndexCompanyEdge' }
  & { node: (
    { __typename?: 'IndexCompany' }
    & Pick<IndexCompany, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & CompanyPreviewFragment
    ), todaysPriceGraph?: Maybe<(
      { __typename?: 'AggregatePriceGraph' }
      & Pick<AggregatePriceGraph, 'indexPrice' | 'priceChangeNinetyDays'>
    )> }
  ) }
);

export type CompanyPreviewFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
);

export type LatestStandingBidsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type LatestStandingBidsQuery = (
  { __typename?: 'RootQueryType' }
  & { latestStandingBids: Array<(
    { __typename?: 'StandingBid' }
    & LatestActivityListStandingBidFragment
  )> }
);

export type LatestListingsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type LatestListingsQuery = (
  { __typename?: 'RootQueryType' }
  & { latestListings: Array<(
    { __typename?: 'Listing' }
    & LatestActivityListListingFragment
  )> }
);

export type LatestMarketActivityQueryVariables = Exact<{
  numListings: Scalars['Int'];
  numStandingBids: Scalars['Int'];
}>;


export type LatestMarketActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { latestListings: Array<(
    { __typename?: 'Listing' }
    & LatestActivityListListingFragment
  )>, latestStandingBids: Array<(
    { __typename?: 'StandingBid' }
    & LatestActivityListStandingBidFragment
  )> }
);

export type LatestActivityListStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'acceptedBidPrices' | 'acceptsShares' | 'acceptsTransferMethods' | 'allowPartialAccept' | 'numSharesOriginal' | 'numSharesAvailable' | 'numSharesAccepted' | 'pricePerShare' | 'state' | 'version' | 'placedAt' | 'updatedAt' | 'insertedAt' | 'solicited'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ) }
  & MakeUrlStandingBidFragment
);

export type LatestActivityListListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptedBidPrices' | 'acceptPartialBid' | 'listingPricePerShare' | 'solicited' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'numSharesSoldConditionally' | 'shareTypeV2' | 'state' | 'transferMethod' | 'sellerId' | 'brokerId' | 'actualNumSharesVisible' | 'sellerInstitutionId' | 'updatedAt' | 'insertedAt' | 'placedAt'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ) }
    )> }
  ) }
);

export type SignDocumentAlertMessagesMyActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type SignDocumentAlertMessagesMyActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { myActivity: (
    { __typename?: 'UserActivity' }
    & { myTransactions: Array<(
      { __typename?: 'Transaction' }
      & SignDocumentAlertMessagesTransactionFragment
    )> }
  ) }
);

export type SignDocumentAlertMessagesTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'expireAt' | 'state' | 'signingProcedure' | 'myPermissionsV2'>
  & { bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'buyerId' | 'buyerInstitutionId' | 'companyId'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ), document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'type'>
    & { signers: Array<(
      { __typename?: 'DocumentSigner' }
      & Pick<DocumentSigner, 'id' | 'userId' | 'institutionId'>
    )> }
  )> }
  & MakeUrlTransactionFragment
);

export type WatchlistCompanySearchListCompaniesQueryVariables = Exact<{
  orderBy: ListCompaniesOrderBy;
  first: Scalars['Int'];
  searchText?: Maybe<Scalars['String']>;
}>;


export type WatchlistCompanySearchListCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { listCompanies?: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyEdge' }
      & WatchlistCompaniesSearchCompanyEdgeFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type WatchlistCompaniesSearchCompanyEdgeFragment = (
  { __typename?: 'CompanyEdge' }
  & Pick<CompanyEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'Company' }
    & WatchlistCompaniesSearchCompanyFragment
  )> }
);

export type WatchlistCompaniesSearchCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl' | 'watchlisted'>
);

export type DefaultUnaccreditedSellerDashboardPageCompanyOverviewCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'logoUrl' | 'name' | 'lastRoundRaised' | 'lastRoundPostValuation' | 'lastRoundPricePerShare' | 'totalRaised' | 'lastRoundSeries' | 'lastRoundDate'>
  & { priceChartConfig: (
    { __typename?: 'PriceChartConfig' }
    & Pick<PriceChartConfig, 'displayChart' | 'graphDataStart' | 'availableTimeRanges'>
    & { graphIndicators: (
      { __typename?: 'GraphIndicators' }
      & Pick<GraphIndicators, 'acceptedBids' | 'lastRoundPps' | 'postedBidsAsks'>
    ), priceLines: (
      { __typename?: 'PriceLines' }
      & Pick<PriceLines, 'shareTypeCommon' | 'shareTypePreferred' | 'transferTypeDirect' | 'transferTypeIndirect'>
    ) }
  ), investors: Array<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'id' | 'name'>
  )>, industries: Array<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'id' | 'name'>
  )>, activity: (
    { __typename?: 'CompanyActivity' }
    & Pick<CompanyActivity, 'numOpenListings' | 'numOpenStandingBidsAndActiveBids'>
  ), currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
);

export type DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQueryVariables = Exact<{ [key: string]: never; }>;


export type DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyCompanyActivity: (
    { __typename?: 'UnaccreditedSellerMyCompanyActivity' }
    & { availableStandingBids: Array<(
      { __typename?: 'StandingBid' }
      & DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockStandingBidFragment
    )> }
  ), unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockCompanyFragment
    ) }
  ) }
);

export type DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'displayId' | 'pricePerShare' | 'numSharesAvailable' | 'numSharesOriginal' | 'state' | 'version' | 'companyId' | 'insertedAt' | 'expireAt' | 'fromHiive' | 'acceptsShares' | 'acceptsTransferMethods'>
);

export type DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
);

export type UnaccreditedSellerDashboardByIdMarketActivityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerDashboardByIdMarketActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & UnaccreditedSellerDashboardByIdMarketActivityCompanyFragment
  ) }
);

export type UnaccreditedSellerDashboardByIdMarketActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { othersStandingBids: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
      & UnaccreditedSellerDashboardByIdMarketActivityStandingBidFragment
    )> }
  ) }
);

export type UnaccreditedSellerDashboardByIdMarketActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
);

export type DefaultUnaccreditedSellerDashboardPageQueryVariables = Exact<{ [key: string]: never; }>;


export type DefaultUnaccreditedSellerDashboardPageQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & DefaultUnaccreditedSellerDashboardPageMyActivityFragment
  ), unaccreditedSellerMyCompanyActivity: (
    { __typename?: 'UnaccreditedSellerMyCompanyActivity' }
    & { standingBidsWithDiscussions: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
    )> }
  ), currentContext?: Maybe<(
    { __typename?: 'ClientContext' }
    & Pick<ClientContext, 'userNotificationToken'>
    & { currentActor: (
      { __typename?: 'User' }
      & UserWithInstitutionFragment
    ) }
  )> }
);

export type DefaultUnaccreditedSellerDashboardPageMyActivityFragment = (
  { __typename?: 'UnaccreditedSellerActivity' }
  & { myCompany: (
    { __typename?: 'Company' }
    & DefaultUnaccreditedSellerDashboardPageMyCompanyFragment
    & UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFragment
  ), myListing?: Maybe<(
    { __typename?: 'Listing' }
    & DefaultUnaccreditedSellerDashboardPageMyListingFragment
  )>, pendingSales: Array<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
  )>, completedSales: Array<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
  )> }
);

export type DefaultUnaccreditedSellerDashboardPageMyCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl' | 'myPermissionsV2' | 'lastRoundSeries' | 'lastRoundDate' | 'lastRoundPricePerShare' | 'lastRoundRaised' | 'totalRaised' | 'lastRoundPostValuation' | 'description' | 'status' | 'tradeable'>
  & { priceChartConfig: (
    { __typename?: 'PriceChartConfig' }
    & Pick<PriceChartConfig, 'displayChart' | 'graphDataStart' | 'availableTimeRanges'>
    & { graphIndicators: (
      { __typename?: 'GraphIndicators' }
      & Pick<GraphIndicators, 'acceptedBids' | 'lastRoundPps' | 'postedBidsAsks'>
    ), priceLines: (
      { __typename?: 'PriceLines' }
      & Pick<PriceLines, 'shareTypeCommon' | 'shareTypePreferred' | 'transferTypeDirect' | 'transferTypeIndirect'>
    ) }
  ), investors: Array<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'id' | 'name'>
  )>, industries: Array<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'id' | 'name'>
  )>, currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )>, activity: (
    { __typename?: 'CompanyActivity' }
    & Pick<CompanyActivity, 'numOpenListings' | 'numOpenStandingBidsAndActiveBids'>
  ), feeDiscountApplications: Array<(
    { __typename?: 'FeeDiscountApplication' }
    & DefaultUnaccreditedSellerDashboardPageMyCompanyFeeDiscountApplicationFragment
  )> }
);

export type DefaultUnaccreditedSellerDashboardPageMyListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'companyId' | 'displayId' | 'listingPricePerShare' | 'numSharesAvailable' | 'state' | 'transferMethod' | 'myPermissionsV2'>
);

export type DefaultUnaccreditedSellerDashboardPageMyCompanyFeeDiscountApplicationFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id'>
  & { feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & FeeDiscountFragment
  ) }
);

export type UnaccreditedSellerDashboardByIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerDashboardByIdPageQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & UnaccreditedSellerDashboardByIdPageCompanyFragment
    & GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardCompanyActivityFragment
    & UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFragment
  ) }
);

export type UnaccreditedSellerDashboardByIdPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl' | 'myPermissionsV2' | 'lastRoundSeries' | 'lastRoundDate' | 'lastRoundPricePerShare' | 'lastRoundRaised' | 'totalRaised' | 'lastRoundPostValuation' | 'description' | 'status' | 'tradeable'>
  & { priceChartConfig: (
    { __typename?: 'PriceChartConfig' }
    & Pick<PriceChartConfig, 'displayChart' | 'graphDataStart' | 'availableTimeRanges'>
    & { graphIndicators: (
      { __typename?: 'GraphIndicators' }
      & Pick<GraphIndicators, 'acceptedBids' | 'lastRoundPps' | 'postedBidsAsks'>
    ), priceLines: (
      { __typename?: 'PriceLines' }
      & Pick<PriceLines, 'shareTypeCommon' | 'shareTypePreferred' | 'transferTypeDirect' | 'transferTypeIndirect'>
    ) }
  ), investors: Array<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'id' | 'name'>
  )>, industries: Array<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'id' | 'name'>
  )>, currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )>, activity: (
    { __typename?: 'CompanyActivity' }
    & Pick<CompanyActivity, 'numOpenListings' | 'numOpenStandingBidsAndActiveBids'>
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )> }
  ), feeDiscountApplications: Array<(
    { __typename?: 'FeeDiscountApplication' }
    & UnaccreditedSellerDashboardByIdPageFeeDiscountApplicationFragment
  )> }
);

export type UnaccreditedSellerDashboardByIdPageFeeDiscountApplicationFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id'>
  & { feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & FeeDiscountFragment
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragment
    )> }
  ), unaccreditedSellerMyListingActivity: (
    { __typename?: 'UnaccreditedSellerMyListingActivity' }
    & { activeBids: Array<(
      { __typename?: 'Bid' }
      & PostActivityUnaccreditedSellerDashboardPageActiveBidsPageBidFragment
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageActiveBidsPageBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'buyerId' | 'companyId' | 'counterpartyId' | 'counterpartyInstitutionId' | 'placedAt' | 'insertedAt' | 'expireAt' | 'buyerInstitutionId' | 'pricePerShare' | 'numShares' | 'numSharesActual' | 'actualNumSharesVisible' | 'displayId'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'totalNumMessages'>
  )> }
);

export type PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'acceptedBidPrices' | 'acceptPartialBid' | 'id' | 'companyId' | 'displayId' | 'listingPricePerShare' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'state' | 'solicited' | 'transferMethod' | 'myPermissionsV2' | 'otherDetails'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ), marketAnalyst?: Maybe<(
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      )> }
    )> }
  ), commission?: Maybe<(
    { __typename?: 'VirtualCommission' }
    & Pick<VirtualCommission, 'flatFeeAmount' | 'commissionAmount' | 'feeDiscountAmount' | 'netFees'>
  )>, feeDiscountApplications?: Maybe<Array<(
    { __typename?: 'FeeDiscountApplication' }
    & Pick<FeeDiscountApplication, 'id' | 'state' | 'initial'>
    & { feeDiscount: (
      { __typename?: 'FeeDiscount' }
      & FeeDiscountFragment
    ), feeDiscountApplicationCommission?: Maybe<(
      { __typename?: 'FeeDiscountApplicationCommission' }
      & Pick<FeeDiscountApplicationCommission, 'id' | 'amount'>
    )> }
  )>> }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageCompanyFragment
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'acceptedBidPrices' | 'acceptPartialBid' | 'id' | 'companyId' | 'displayId' | 'listingPricePerShare' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'state' | 'solicited' | 'transferMethod' | 'myPermissionsV2' | 'otherDetails'>
  & { bids: Array<(
    { __typename?: 'Bid' }
    & PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragment
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ), marketAnalyst?: Maybe<(
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      )> }
    )> }
  ), commission?: Maybe<(
    { __typename?: 'VirtualCommission' }
    & Pick<VirtualCommission, 'flatFeeAmount' | 'commissionAmount' | 'feeDiscountAmount' | 'netFees'>
  )>, feeDiscountApplications?: Maybe<Array<(
    { __typename?: 'FeeDiscountApplication' }
    & Pick<FeeDiscountApplication, 'id' | 'state' | 'initial'>
    & { feeDiscount: (
      { __typename?: 'FeeDiscount' }
      & FeeDiscountFragment
    ), feeDiscountApplicationCommission?: Maybe<(
      { __typename?: 'FeeDiscountApplicationCommission' }
      & Pick<FeeDiscountApplicationCommission, 'id' | 'amount'>
    )> }
  )>> }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'buyerId' | 'companyId' | 'counterpartyId' | 'counterpartyInstitutionId' | 'placedAt' | 'insertedAt' | 'expireAt' | 'buyerInstitutionId' | 'pricePerShare' | 'numShares' | 'numSharesActual' | 'actualNumSharesVisible' | 'displayId'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'totalNumMessages'>
  )> }
);

export type PostActivityUnaccreditedSellerDashboardPageInquiriesPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )> }
  ), unaccreditedSellerMyListingActivity: (
    { __typename?: 'UnaccreditedSellerMyListingActivity' }
    & { preBidDiscussions: Array<(
      { __typename?: 'Discussion' }
      & PostActivityUnaccreditedSellerDashboardPageInquiriesPageDiscussionFragment
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageInquiriesPageDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'displayId' | 'state' | 'numUnreadMessages' | 'totalNumMessages'>
  & { mostRecentMessage?: Maybe<(
    { __typename?: 'DiscussionMessage' }
    & Pick<DiscussionMessage, 'id' | 'senderId' | 'messageBody'>
    & { sender: (
      { __typename?: 'VeryBasicUser' }
      & Pick<VeryBasicUser, 'id' | 'institutionId'>
    ) }
  )> }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQueryVariables = Exact<{
  id: Scalars['ID'];
  topicFilter?: Maybe<MessageTopic>;
}>;


export type PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { activity: (
      { __typename?: 'CompanyActivity' }
      & { myListings: Array<(
        { __typename?: 'Listing' }
        & Pick<Listing, 'id'>
      )> }
    ) }
  ), myDiscussions: Array<(
    { __typename?: 'Discussion' }
    & PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDiscussionFragment
  )> }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'displayId' | 'state' | 'numUnreadMessages' | 'totalNumMessages'>
  & { mostRecentMessage?: Maybe<(
    { __typename?: 'DiscussionMessage' }
    & Pick<DiscussionMessage, 'id' | 'senderId' | 'messageBody'>
    & { sender: (
      { __typename?: 'VeryBasicUser' }
      & Pick<VeryBasicUser, 'id' | 'institutionId'>
    ) }
  )> }
);

export type PostActivityUnaccreditedSellerDashboardPageOverviewPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PostActivityUnaccreditedSellerDashboardPageOverviewPageQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & PostActivityUnaccreditedSellerDashboardPageOverviewPageCompanyFragment
    ), myListing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, pendingSales: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )>, completedSales: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )> }
  ), unaccreditedSellerMyCompanyActivity: (
    { __typename?: 'UnaccreditedSellerMyCompanyActivity' }
    & { standingBidsWithDiscussions: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageOverviewPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl' | 'myPermissionsV2' | 'lastRoundSeries' | 'lastRoundDate' | 'lastRoundPricePerShare' | 'lastRoundRaised' | 'totalRaised' | 'lastRoundPostValuation' | 'description' | 'status' | 'tradeable'>
  & { priceChartConfig: (
    { __typename?: 'PriceChartConfig' }
    & Pick<PriceChartConfig, 'displayChart' | 'graphDataStart' | 'availableTimeRanges'>
    & { graphIndicators: (
      { __typename?: 'GraphIndicators' }
      & Pick<GraphIndicators, 'acceptedBids' | 'lastRoundPps' | 'postedBidsAsks'>
    ), priceLines: (
      { __typename?: 'PriceLines' }
      & Pick<PriceLines, 'shareTypeCommon' | 'shareTypePreferred' | 'transferTypeDirect' | 'transferTypeIndirect'>
    ) }
  ), investors: Array<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'id' | 'name'>
  )>, industries: Array<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'id' | 'name'>
  )>, currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )>, activity: (
    { __typename?: 'CompanyActivity' }
    & Pick<CompanyActivity, 'numOpenListings' | 'numOpenStandingBidsAndActiveBids'>
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPagePastBidsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )> }
  ), unaccreditedSellerMyListingActivity: (
    { __typename?: 'UnaccreditedSellerMyListingActivity' }
    & { pastBids: Array<(
      { __typename?: 'Bid' }
      & PostActivityUnaccreditedSellerDashboardPagePastBidsPageBidFragment
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPagePastBidsPageBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'state' | 'placedAt' | 'insertedAt' | 'expireAt' | 'withdrawnAt' | 'pricePerShare' | 'numShares' | 'numSharesActual' | 'actualNumSharesVisible' | 'fromHiive' | 'companyId'>
);

export type PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageCompanyFragment
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
      & { bids: Array<(
        { __typename?: 'Bid' }
        & PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBidFragment
      )> }
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'state' | 'placedAt' | 'insertedAt' | 'expireAt' | 'withdrawnAt' | 'pricePerShare' | 'numShares' | 'numSharesActual' | 'actualNumSharesVisible' | 'fromHiive' | 'companyId'>
);

export type PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'id'>
    ), myListing?: Maybe<(
      { __typename?: 'Listing' }
      & PostActivityUnaccreditedSellerDashboardPageStandingBidsPageListingFragment
    )>, pendingSales: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )>, completedSales: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )> }
  ), unaccreditedSellerMyCompanyActivity: (
    { __typename?: 'UnaccreditedSellerMyCompanyActivity' }
    & { availableStandingBids: Array<(
      { __typename?: 'StandingBid' }
      & PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragment
    )>, standingBidsWithDiscussions: Array<(
      { __typename?: 'StandingBid' }
      & PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragment
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageStandingBidsPageListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
);

export type PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'displayId' | 'pricePerShare' | 'numSharesAvailable' | 'numSharesOriginal' | 'state' | 'version' | 'companyId' | 'placedAt' | 'insertedAt' | 'expireAt' | 'fromHiive' | 'acceptsShares' | 'acceptsTransferMethods'>
);

export type PostActivityUnaccreditedSellerDashboardPageStandingBidsPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name'>
);

export type PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompanyFragment
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageListingFragment
    )>, myCompletedTransactions: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )>, myOpenTransactions: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )>, standingBidsWithDiscussions: Array<(
      { __typename?: 'StandingBid' }
      & PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragment
    )>, availableStandingBids: Array<(
      { __typename?: 'StandingBid' }
      & PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragment
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
);

export type PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'displayId' | 'pricePerShare' | 'numSharesAvailable' | 'numSharesOriginal' | 'state' | 'version' | 'companyId' | 'placedAt' | 'insertedAt' | 'expireAt' | 'fromHiive' | 'acceptsShares' | 'acceptsTransferMethods'>
);

export type PostActivityUnaccreditedSellerDashboardPageTransactionsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, pendingSales: Array<(
      { __typename?: 'Transaction' }
      & PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment
    )>, completedSales: Array<(
      { __typename?: 'Transaction' }
      & PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment
    )> }
  ), unaccreditedSellerMyCompanyActivity: (
    { __typename?: 'UnaccreditedSellerMyCompanyActivity' }
    & { standingBidsWithDiscussions: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'state' | 'signingProcedure' | 'myPermissionsV2' | 'expireAt' | 'numShares' | 'pricePerShare'>
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionDocumentFragment
  )>, bid: (
    { __typename?: 'Bid' }
    & PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionBidFragment
  ) }
  & MakeUrlTransactionFragment
);

export type PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionDocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'type'>
  & { signers: Array<(
    { __typename?: 'DocumentSigner' }
    & Pick<DocumentSigner, 'id' | 'userId' | 'institutionId'>
  )> }
);

export type PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerId' | 'buyerInstitutionId' | 'displayId' | 'numShares' | 'numSharesActual' | 'actualNumSharesVisible' | 'pricePerShare' | 'state'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'totalNumMessages'>
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'logoUrl'>
  ), standingBid?: Maybe<(
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id' | 'displayId'>
  )> }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & PostActivityUnaccreditedSellerDashboardByIdPageTransactionPageCompanyFragment
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageTransactionPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, myCompletedTransactions: Array<(
      { __typename?: 'Transaction' }
      & PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment
    )>, myOpenTransactions: Array<(
      { __typename?: 'Transaction' }
      & PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment
    )>, standingBidsWithDiscussions: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'state' | 'signingProcedure' | 'myPermissionsV2' | 'expireAt' | 'numShares' | 'pricePerShare'>
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionDocumentFragment
  )>, bid: (
    { __typename?: 'Bid' }
    & PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionBidFragment
  ) }
  & MakeUrlTransactionFragment
);

export type PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionDocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'type'>
  & { signers: Array<(
    { __typename?: 'DocumentSigner' }
    & Pick<DocumentSigner, 'id' | 'userId' | 'institutionId'>
  )> }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerId' | 'buyerInstitutionId' | 'displayId' | 'numShares' | 'numSharesActual' | 'actualNumSharesVisible' | 'pricePerShare' | 'state'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'totalNumMessages'>
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'logoUrl'>
  ), standingBid?: Maybe<(
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id' | 'displayId'>
  )> }
);

export type PostActivityUnaccreditedSellerDashboardPageHeroBannerQueryVariables = Exact<{ [key: string]: never; }>;


export type PostActivityUnaccreditedSellerDashboardPageHeroBannerQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, myCompany: (
      { __typename?: 'Company' }
      & PostActivityUnaccreditedSellerDashboardPageHeroBannerCompanyFragment
    ) }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageHeroBannerCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
);

export type PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerCompanyFragment
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQueryVariables = Exact<{ [key: string]: never; }>;


export type PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQueryVariables = Exact<{ [key: string]: never; }>;


export type PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'id'>
    ), myListing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )> }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageSidebarQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & PostActivityUnaccreditedSellerDashboardByIdPageSidebarCompanyFragment
  ) }
);

export type PostActivityUnaccreditedSellerDashboardByIdPageSidebarCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )>, activity: (
    { __typename?: 'CompanyActivity' }
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )> }
  ) }
);

export type UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { feeDiscountApplications: Array<(
    { __typename?: 'FeeDiscountApplication' }
    & UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFeeDiscountApplicationFragment
  )> }
);

export type UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFeeDiscountApplicationFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id'>
  & { feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & FeeDiscountFragment
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQueryVariables = Exact<{ [key: string]: never; }>;


export type PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesCompanyFragment
    ) }
  ) }
);

export type PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name' | 'id'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type UnaccreditedSellerInformativeArticlesCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type AccreditationQuestionGroupsQueryVariables = Exact<{
  version: Scalars['Int'];
  investorType: InvestorStatus;
  countryId: Scalars['ID'];
}>;


export type AccreditationQuestionGroupsQuery = (
  { __typename?: 'RootQueryType' }
  & { accreditationQuestionGroups: Array<(
    { __typename?: 'AccreditationQuestionGroup' }
    & Pick<AccreditationQuestionGroup, 'id'>
    & { questions: Array<Maybe<(
      { __typename?: 'AccreditationQuestion' }
      & Pick<AccreditationQuestion, 'id' | 'text' | 'order'>
    )>> }
  )> }
);

export type FormCompaniesComboboxCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
);

export type FormSelectListingInputListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'transferMethod' | 'displayId' | 'shortId'>
  & GetNumOfSharesListingFragment
);

export type BasicInvestmentGoalQuestionFragment = (
  { __typename?: 'InvestmentGoalQuestion' }
  & Pick<InvestmentGoalQuestion, 'description' | 'id' | 'order' | 'text'>
);

export type InvestmentGoalQuestionGroupsQueryVariables = Exact<{
  countryId: Scalars['ID'];
  investorType?: Maybe<InvestorStatus>;
  version: Scalars['Int'];
}>;


export type InvestmentGoalQuestionGroupsQuery = (
  { __typename?: 'RootQueryType' }
  & { investmentGoalQuestionGroups?: Maybe<Array<Maybe<(
    { __typename?: 'InvestmentGoalQuestionGroup' }
    & Pick<InvestmentGoalQuestionGroup, 'id'>
    & { questions: Array<Maybe<(
      { __typename?: 'InvestmentGoalQuestion' }
      & { options: Array<Maybe<(
        { __typename?: 'InvestmentGoalOption' }
        & Pick<InvestmentGoalOption, 'id' | 'custom' | 'text' | 'nextQuestionIds' | 'description' | 'order'>
      )>> }
      & BasicInvestmentGoalQuestionFragment
    )>> }
  )>>> }
);

export type ToggleFeatureFlagMutationVariables = Exact<{
  input: ToggleFeatureFlagInput;
}>;


export type ToggleFeatureFlagMutation = (
  { __typename?: 'RootMutationType' }
  & { toggleFeatureFlag?: Maybe<(
    { __typename?: 'ToggleFeatureFlagMutationResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type IndexCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type IndexCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { indexCompanies?: Maybe<(
    { __typename?: 'IndexCompanyConnection' }
    & { edges: Array<(
      { __typename?: 'IndexCompanyEdge' }
      & IndexCompanyEdgeFragment
    )> }
  )> }
);

export type IndexCompanyEdgeFragment = (
  { __typename?: 'IndexCompanyEdge' }
  & { node: (
    { __typename?: 'IndexCompany' }
    & Pick<IndexCompany, 'id' | 'sortedIndexPrices'>
    & { company: (
      { __typename?: 'Company' }
      & CompanyFragment
    ), todaysPriceGraph?: Maybe<(
      { __typename?: 'AggregatePriceGraph' }
      & Pick<AggregatePriceGraph, 'priceChangeNinetyDays'>
    )> }
  ) }
);

export type CompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'logoUrl' | 'name'>
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
);

export type HiiveFiftyIndexPricesQueryVariables = Exact<{
  filterBy: Hiive50IndexPriceFilter;
}>;


export type HiiveFiftyIndexPricesQuery = (
  { __typename?: 'RootQueryType' }
  & { hiiveFiftyIndexPrices: (
    { __typename?: 'Hiive50DailyIndexPriceResponse' }
    & IndexPriceTrendResponseFragment
  ) }
);

export type IndexPriceTrendResponseFragment = (
  { __typename?: 'Hiive50DailyIndexPriceResponse' }
  & Pick<Hiive50DailyIndexPriceResponse, 'currentPrice' | 'percentageChange'>
  & { prices?: Maybe<Array<(
    { __typename?: 'Hiive50IndexPrice' }
    & IndexPriceFragment
  )>> }
);

export type IndexPriceFragment = (
  { __typename?: 'Hiive50IndexPrice' }
  & Pick<Hiive50IndexPrice, 'priceDay' | 'avg'>
);

export type ConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigQuery = (
  { __typename?: 'RootQueryType' }
  & { config: (
    { __typename?: 'Config' }
    & RootConfigFragment
  ) }
);

export type RootConfigFragment = (
  { __typename?: 'Config' }
  & Pick<Config, 'stnLoiHoursValidHours'>
  & SignupConfigFragment
);

export type AskPriceComparisonChartCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'lastRoundPricePerShare' | 'hasAskPriceComparisonChart'>
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
);

export type BidCancellationWarningBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'numSharesActual'>
);

export type ListingFeeBreakdownCommissionInfoQueryVariables = Exact<{
  totalShares: Scalars['Int'];
  pricePerShare: Scalars['Int'];
  listingId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
}>;


export type ListingFeeBreakdownCommissionInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { commissionInfo: (
    { __typename?: 'CommissionInfo' }
    & ListingFeeBreakdownCommissionInfoFragment
    & ListingFeeDiscountInfoFragment
  ) }
);

export type ListingFeeBreakdownCommissionInfoFragment = (
  { __typename?: 'CommissionInfo' }
  & Pick<CommissionInfo, 'totalValue' | 'netValue' | 'flatFeeAmount' | 'commissionAmount' | 'netFees'>
);

export type ListingFeeDiscountInfoFragment = (
  { __typename?: 'CommissionInfo' }
  & Pick<CommissionInfo, 'feeDiscountAmount'>
  & { feeDiscountApplications: Array<(
    { __typename?: 'FeeDiscountApplication' }
    & Pick<FeeDiscountApplication, 'id' | 'initial' | 'state'>
    & { feeDiscountApplicationCommission?: Maybe<(
      { __typename?: 'FeeDiscountApplicationCommission' }
      & Pick<FeeDiscountApplicationCommission, 'id' | 'amount'>
    )>, feeDiscount: (
      { __typename?: 'FeeDiscount' }
      & Pick<FeeDiscount, 'id' | 'name' | 'type' | 'value'>
    ) }
  )> }
);

export type SharePriceCardUnaccreditedSellerMyActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type SharePriceCardUnaccreditedSellerMyActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & SharePriceCardCompanyFragment
    ) }
  ) }
);

export type SharePriceCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'lastRoundPricePerShare'>
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
  & AskPriceComparisonChartCompanyFragment
);

export type UnaccreditedSellerCreateListingV2MutationVariables = Exact<{
  input: UnaccreditedSellerCreateListingInputV2;
}>;


export type UnaccreditedSellerCreateListingV2Mutation = (
  { __typename?: 'RootMutationType' }
  & { unaccreditedSellerCreateListingV2: (
    { __typename?: 'UnaccreditedSellerCreateListingMutationResponse' }
    & { listing?: Maybe<(
      { __typename?: 'Listing' }
      & UnaccreditedSellerCreateListingPageListingFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type UnaccreditedSellerCreateListingPageQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerCreateListingPageQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & UnaccreditedSellerCreateListingPageListingFragment
    )> }
  ) }
);

export type UnaccreditedSellerCreateListingPageListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'state' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'actualNumSharesVisible' | 'listingPricePerShare'>
  & { shareSeriesMakeup: Array<(
    { __typename?: 'ShareSeriesMakeup' }
    & Pick<ShareSeriesMakeup, 'id' | 'shareSeries'>
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ), marketAnalyst?: Maybe<(
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      )> }
    )> }
  ) }
);

export type UnaccreditedSellerCreateListingByIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerCreateListingByIdPageQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & UnaccreditedSellerCreateListingPageByIdCompanyFragment
  ) }
);

export type UnaccreditedSellerCreateListingPageByIdCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'lastRoundPricePerShare' | 'hasActiveInformationDisclosure' | 'requiresInformationDisclosure'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )>, activity: (
    { __typename?: 'CompanyActivity' }
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )> }
  ), currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )>, companyEligibilityCriterion?: Maybe<(
    { __typename?: 'CompanyEligibilityCriterion' }
    & Pick<CompanyEligibilityCriterion, 'id' | 'text'>
  )> }
  & AskPriceComparisonChartCompanyFragment
);

export type UnaccreditedSellerModifyListingV2MutationVariables = Exact<{
  input: UnaccreditedSellerModifyListingInputV2;
}>;


export type UnaccreditedSellerModifyListingV2Mutation = (
  { __typename?: 'RootMutationType' }
  & { unaccreditedSellerModifyListingV2: (
    { __typename?: 'ListingMutationResponse' }
    & { listing?: Maybe<(
      { __typename?: 'Listing' }
      & UnaccreditedSellerModifyListingPageListingFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type UnaccreditedSellerModifyListingPageQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerModifyListingPageQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & UnaccreditedSellerModifyListingPageListingFragment
    )> }
  ) }
);

export type UnaccreditedSellerModifyListingPageListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'otherDetails' | 'listingPricePerShare' | 'transferMethod' | 'numSharesSoldConditionally' | 'acceptedBidPrices' | 'solicited'>
  & { shareSeriesMakeup: Array<(
    { __typename?: 'ShareSeriesMakeup' }
    & Pick<ShareSeriesMakeup, 'id' | 'shareSeries'>
  )>, bids: Array<(
    { __typename?: 'Bid' }
    & UnaccreditedSellerModifyListingPageBidFragment
  )> }
  & NumShareAndPriceDetailsListingFragment
);

export type UnaccreditedSellerModifyListingPageBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'numSharesActual' | 'counterNumShares'>
);

export type UnaccreditedSellerModifyListingPageSharePriceCardQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerModifyListingPageSharePriceCardQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & UnaccreditedSellerModifyListingPageSharePriceCardCompanyFragment
    ) }
  ) }
);

export type UnaccreditedSellerModifyListingPageSharePriceCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'lastRoundPricePerShare'>
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
  & AskPriceComparisonChartCompanyFragment
);

export type UnaccreditedSellerModifyListingByIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerModifyListingByIdPageQuery = (
  { __typename?: 'RootQueryType' }
  & { listingById?: Maybe<(
    { __typename?: 'Listing' }
    & UnaccreditedSellerModifyListingByIdPageListingFragment
  )> }
);

export type UnaccreditedSellerModifyListingByIdPageListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'otherDetails' | 'listingPricePerShare' | 'transferMethod' | 'numSharesSoldConditionally' | 'acceptedBidPrices' | 'solicited'>
  & { company: (
    { __typename?: 'Company' }
    & UnaccreditedSellerModifyListingByIdPageCompanyFragment
  ), shareSeriesMakeup: Array<(
    { __typename?: 'ShareSeriesMakeup' }
    & Pick<ShareSeriesMakeup, 'id' | 'shareSeries'>
  )>, bids: Array<(
    { __typename?: 'Bid' }
    & UnaccreditedSellerModifyListingByIdPageBidFragment
  )> }
  & NumShareAndPriceDetailsListingFragment
);

export type UnaccreditedSellerModifyListingByIdPageBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'numSharesActual'>
  & { counterBid?: Maybe<(
    { __typename?: 'CounterBid' }
    & Pick<CounterBid, 'numShares'>
  )> }
);

export type UnaccreditedSellerModifyListingByIdPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'lastRoundPricePerShare'>
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
  & AskPriceComparisonChartCompanyFragment
);

export type CreateListingSelectCompanyInputListCompaniesQueryVariables = Exact<{
  orderBy: ListCompaniesOrderBy;
  first: Scalars['Int'];
  searchText?: Maybe<Scalars['String']>;
}>;


export type CreateListingSelectCompanyInputListCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { listCompanies?: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyEdge' }
      & CreateListingSelectCompanyInputCompanyEdgeFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type CreateListingSelectCompanyInputCompanyEdgeFragment = (
  { __typename?: 'CompanyEdge' }
  & Pick<CompanyEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'Company' }
    & CreateListingSelectCompanyInputCompanyFragment
  )> }
);

export type CreateListingSelectCompanyInputCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl' | 'myPermissionsV2' | 'hasActiveInformationDisclosure' | 'requiresInformationDisclosure'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )>, currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid'>
  )>, companyEligibilityCriterion?: Maybe<(
    { __typename?: 'CompanyEligibilityCriterion' }
    & Pick<CompanyEligibilityCriterion, 'id' | 'text'>
  )> }
);

export type DiscussionActivityDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'myPermissionsV2'>
  & { listingTopic: (
    { __typename?: 'Listing' }
    & DiscussionActivityListingFragment
  ) | { __typename?: 'StandingBid' }, standingBidTopic: { __typename?: 'Listing' } | (
    { __typename?: 'StandingBid' }
    & DiscussionActivityStandingBidFragment
  ) }
  & DiscussionThreadCardDiscussionFragment
);

export type DiscussionActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'myPermissionsV2'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & PlaceBidSequenceModalCompanyFragment
  ) }
  & PlaceBidSequenceModalListingFragment
);

export type DiscussionActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
);

export type DiscussionInfoDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & { listingTopic: (
    { __typename?: 'Listing' }
    & DiscussionInfoListingFragment
  ) | { __typename?: 'StandingBid' }, standingBidTopic: { __typename?: 'Listing' } | (
    { __typename?: 'StandingBid' }
    & DiscussionInfoStandingBidFragment
  ) }
  & ListingPageListingInfoCardDiscussionFragment
);

export type DiscussionInfoListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & ListingPageListingInfoCardListingFragment
);

export type DiscussionInfoStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & StandingBidInfoStandingBidFragment
);

export type DiscussionPageDiscussionByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DiscussionPageDiscussionByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { discussionById?: Maybe<(
    { __typename?: 'Discussion' }
    & DiscussionPageDiscussionFragment
  )> }
);

export type DiscussionPageDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & DiscussionInfoDiscussionFragment
  & DiscussionActivityDiscussionFragment
);

export type AcceptBidMutationVariables = Exact<{
  bidId: Scalars['ID'];
}>;


export type AcceptBidMutation = (
  { __typename?: 'RootMutationType' }
  & { acceptBid: (
    { __typename?: 'BidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & AcceptBidSuccessModalBidFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type AcceptBidModalCompanyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AcceptBidModalCompanyByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & AcceptBidModalCompanyFragment
  ) }
);

export type AcceptBidModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'state' | 'numShares' | 'numSharesActual' | 'actualNumSharesVisible' | 'pricePerShare' | 'myPermissionsV2' | 'listingBelowMinLotOnAcceptance'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'actualNumSharesVisible' | 'listingPricePerShare' | 'acceptPartialBid' | 'transferMethod'>
    & { bids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'state' | 'numShares'>
    )> }
  ), company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  ) }
);

export type AcceptBidModalCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
);

export type AcceptBidSuccessModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'shortId' | 'pricePerShare' | 'numShares' | 'numSharesActual' | 'actualNumSharesVisible'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & MakeUrlTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'transferMethod'>
    & { company: (
      { __typename?: 'Company' }
      & AcceptBidSuccessModalBidListingCompanyFragment
    ) }
  ) }
);

export type AcceptBidSuccessModalBidListingCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type AcceptCounteredBidMutationVariables = Exact<{
  bidId: Scalars['ID'];
}>;


export type AcceptCounteredBidMutation = (
  { __typename?: 'RootMutationType' }
  & { acceptCounteredBid: (
    { __typename?: 'BidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & AcceptBidSuccessModalBidFragment
      & MakeUrlBidFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type AcceptCounterBidModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'numShares' | 'numSharesActual' | 'actualNumSharesVisible' | 'pricePerShare' | 'counterPricePerShare' | 'counterNumShares'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'actualNumSharesVisible' | 'listingPricePerShare' | 'transferMethod'>
    & { bids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'state' | 'numShares'>
    )> }
  ), company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ) }
);

export type BrokerSubmitBidSequenceModalBrokerSubmitBidMutationVariables = Exact<{
  input: BrokerSubmitBidInput;
}>;


export type BrokerSubmitBidSequenceModalBrokerSubmitBidMutation = (
  { __typename?: 'RootMutationType' }
  & { brokerSubmitBid: (
    { __typename?: 'BrokerSubmitBidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
      & { listing: (
        { __typename?: 'Listing' }
        & Pick<Listing, 'id' | 'displayId'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
          & GetSecuritySpecialistContactCompanyFragment
        ) }
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type BrokerSubmitBidSequenceModalCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl' | 'lastRoundPricePerShare' | 'myPermissionsV2' | 'tradeable' | 'minPostPps' | 'maxPostPps'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )>, currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )>, activity: (
    { __typename?: 'CompanyActivity' }
    & { othersListings: Array<(
      { __typename?: 'Listing' }
      & BrokerSubmitBidSequenceModalListingFragment
    )> }
  ) }
);

export type BrokerSubmitBidSequenceModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'state' | 'myPermissionsV2' | 'listingPricePerShare'>
  & { bids: Array<(
    { __typename?: 'Bid' }
    & BrokerSubmitBidSequenceModalBidFragment
  )> }
  & GetNumOfSharesListingFragment
  & FormSelectListingInputListingFragment
);

export type BrokerSubmitBidSequenceModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerInstitutionId' | 'state'>
  & FilterBidsIMadeBidFragment
);

export type ModifyBidMutationVariables = Exact<{
  input: ModifyBidInput;
  bidId: Scalars['ID'];
}>;


export type ModifyBidMutation = (
  { __typename?: 'RootMutationType' }
  & { modifyBid: (
    { __typename?: 'BidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type ModifyBidModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'numShares' | 'pricePerShare' | 'expireAt' | 'representedEmail'>
  & { listing: (
    { __typename?: 'Listing' }
    & { company: (
      { __typename?: 'Company' }
      & ModifyBidModalCompanyFragment
    ) }
    & ModifyBidModalListingFragment
  ) }
);

export type ModifyBidModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptPartialBid' | 'displayId' | 'sellerInstitutionId'>
  & PostingDetailsListingFragment
);

export type ModifyBidModalCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
);

export type ModifyCounterBidMutationVariables = Exact<{
  bidId: Scalars['ID'];
  input: CounterBidInput;
}>;


export type ModifyCounterBidMutation = (
  { __typename?: 'RootMutationType' }
  & { modifyCounterBid: (
    { __typename?: 'BidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type ModifyCounterBidModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'pricePerShare' | 'numShares' | 'numSharesActual' | 'counterPricePerShare' | 'counterNumShares' | 'buyerInstitutionId'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & ModifyCounterBidModalListingFragment
  ) }
  & GetBidNumSharesActualBidFragment
  & GetNumOfSharesBidFragment
);

export type ModifyCounterBidModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'displayId' | 'sellerInstitutionId' | 'acceptPartialBid'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ) }
  & PostingDetailsListingFragment
);

export type PlaceBidSequenceModalPlaceBidMutationVariables = Exact<{
  input: PlaceBidInput;
}>;


export type PlaceBidSequenceModalPlaceBidMutation = (
  { __typename?: 'RootMutationType' }
  & { placeBid: (
    { __typename?: 'BidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
      & PlaceBidSequenceModalPlaceBidSuccessBidFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type PlaceBidSequenceModalCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & PlaceBidSequenceModalAuthorizationCompanyFragment
);

export type PlaceBidSequenceModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & PlaceBidSequenceModalAuthorizationListingFragment
);

export type PlaceBidSequenceModalAuthorizationCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & GetSecuritySpecialistContactCompanyFragment
  & GetAvailableCompanyActionsCompanyFragment
  & PlaceBidSequenceModalContentCompanyFragment
);

export type PlaceBidSequenceModalAuthorizationListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & PlaceBidSequenceModalContentListingFragment
);

export type PlaceBidSequenceModalContentCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & PlaceBidSequenceModalPlaceBidCompanyFragment
);

export type PlaceBidSequenceModalContentListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'listingPricePerShare'>
  & PlaceBidSequenceModalPlaceBidListingFragment
);

export type PlaceBidSequenceModalPlaceBidCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { othersListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'myPermissionsV2' | 'state'>
      & { bids: Array<(
        { __typename?: 'Bid' }
        & Pick<Bid, 'id'>
        & PlaceBidSequenceModalPlaceBidBidFragment
      )> }
      & PlaceBidSequenceModalPlaceBidListingFragment
    )> }
  ) }
  & PlaceStandingBidModalCompanyFragment
);

export type PlaceBidSequenceModalPlaceBidListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptPartialBid'>
  & GetNumSharesAvailableRoundedListingFragment
  & FormSelectListingInputListingFragment
  & GetPricePerShareListingFragment
);

export type PlaceBidSequenceModalPlaceBidBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerId' | 'buyerInstitutionId' | 'state'>
  & FilterBidsIMadeBidFragment
);

export type PlaceBidSequenceModalPlaceBidSuccessBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'displayId' | 'isHiiveSpv'>
    & { company: (
      { __typename?: 'Company' }
      & PlaceBidSequenceModalPlaceBidSuccessBidListingCompanyFragment
      & GetAvailableCompanyActionsCompanyFragment
    ) }
  ) }
);

export type PlaceBidSequenceModalPlaceBidSuccessBidListingCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type CounterBidV2MutationVariables = Exact<{
  bidId: Scalars['ID'];
  input: CounterBidInput;
}>;


export type CounterBidV2Mutation = (
  { __typename?: 'RootMutationType' }
  & { counterBidV2: (
    { __typename?: 'BidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type CounterBidModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'pricePerShare' | 'numShares' | 'numSharesActual' | 'counterPricePerShare' | 'counterNumShares' | 'buyerInstitutionId'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & CounterBidModalListingFragment
  ) }
  & GetBidNumSharesActualBidFragment
  & GetCanCounterNumberOfSharesBidFragment
);

export type CounterBidModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'displayId' | 'sellerInstitutionId' | 'acceptPartialBid'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  ) }
  & PostingDetailsListingFragment
);

export type PlaceBidSuccessModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'displayId' | 'isHiiveSpv'>
    & { company: (
      { __typename?: 'Company' }
      & PlaceBidSuccessModalBidListingCompanyFragment
      & GetAvailableCompanyActionsCompanyFragment
    ) }
  ) }
);

export type PlaceBidSuccessModalBidListingCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type WithdrawBidModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId'>
);

export type WithdrawCounterBidMutationVariables = Exact<{
  bidId: Scalars['ID'];
}>;


export type WithdrawCounterBidMutation = (
  { __typename?: 'RootMutationType' }
  & { withdrawCounterBid: (
    { __typename?: 'BidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
      & { listing: (
        { __typename?: 'Listing' }
        & MakeUrlListingFragment
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type WithdrawCounterBidModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId'>
);

export type ChangeInstitutionUserRolesMutationVariables = Exact<{
  userId: Scalars['ID'];
  roles: Array<UserRole> | UserRole;
}>;


export type ChangeInstitutionUserRolesMutation = (
  { __typename?: 'RootMutationType' }
  & { changeInstitutionUserRoles?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & BasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type EditInstitutionUserModalUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'roles' | 'name' | 'email'>
);

export type BrokerSubmitListingSequenceModalCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & CreateListingSelectCompanyInputCompanyFragment
);

export type CreateListingMutationVariables = Exact<{
  input: ListingInput;
}>;


export type CreateListingMutation = (
  { __typename?: 'RootMutationType' }
  & { createListing: (
    { __typename?: 'ListingMutationResponse' }
    & { listing?: Maybe<(
      { __typename?: 'Listing' }
      & CreateListingSuccessModalListingFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type CreateListingModalCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'myPermissionsV2' | 'minPostPps' | 'maxPostPps' | 'hasActiveInformationDisclosure' | 'requiresInformationDisclosure'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )>, currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid'>
  )>, companyEligibilityCriterion?: Maybe<(
    { __typename?: 'CompanyEligibilityCriterion' }
    & Pick<CompanyEligibilityCriterion, 'id' | 'text'>
  )> }
);

export type CreateListingModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ), marketAnalyst?: Maybe<(
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      )> }
    )> }
  ) }
);

export type CreateListingSuccessModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'listingPricePerShare' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'actualNumSharesVisible'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ), marketAnalyst?: Maybe<(
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      )> }
    )> }
  ) }
);

export type ModifyListingMutationVariables = Exact<{
  listingId: Scalars['ID'];
  input: ModifyListingInput;
}>;


export type ModifyListingMutation = (
  { __typename?: 'RootMutationType' }
  & { modifyListing: (
    { __typename?: 'ListingMutationResponse' }
    & { listing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type ModifyListingModalCompanyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ModifyListingModalCompanyByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & ModifyListingModalCompanyFragment
  ) }
);

export type ModifyListingModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'listingPricePerShare' | 'displayId' | 'numSharesSoldConditionally' | 'acceptedBidPrices' | 'solicited' | 'otherDetails' | 'transferMethod' | 'signingProcedure' | 'representedEmail' | 'companyId' | 'expireAt'>
  & { shareSeriesMakeup: Array<(
    { __typename?: 'ShareSeriesMakeup' }
    & Pick<ShareSeriesMakeup, 'id' | 'shareSeries' | 'numShares'>
  )>, bids: Array<(
    { __typename?: 'Bid' }
    & ModifyListingModalBidFragment
  )> }
  & NumShareAndPriceDetailsListingFragment
);

export type ModifyListingModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'numSharesActual' | 'counterNumShares'>
);

export type ModifyListingModalCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'minPostPps' | 'maxPostPps'>
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid'>
  )> }
);

export type UnaccreditedSellerCreateListingSuccessModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'state' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'actualNumSharesVisible' | 'listingPricePerShare'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ), marketAnalyst?: Maybe<(
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      )> }
    )> }
  ) }
);

export type UnaccreditedSellerWithdrawListingMutationVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerWithdrawListingMutation = (
  { __typename?: 'RootMutationType' }
  & { unaccreditedSellerWithdrawListing: (
    { __typename?: 'ListingMutationResponse' }
    & { listing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type UnaccreditedSellerWithdrawListingModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'companyId' | 'displayId'>
);

export type WithdrawListingMutationVariables = Exact<{
  listingId: Scalars['ID'];
  input: WithdrawListingInput;
}>;


export type WithdrawListingMutation = (
  { __typename?: 'RootMutationType' }
  & { withdrawListing: (
    { __typename?: 'ListingMutationResponse' }
    & { listing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type WithdrawListingModalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'companyId' | 'displayId'>
);

export type AcceptStandingBidMutationVariables = Exact<{
  standingBidId: Scalars['ID'];
  input: AcceptStandingBidInput;
}>;


export type AcceptStandingBidMutation = (
  { __typename?: 'RootMutationType' }
  & { acceptStandingBid?: Maybe<(
    { __typename?: 'AcceptStandingBidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & AcceptStandingBidResponseBidFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type AcceptStandingBidResponseBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'companyId'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & MakeUrlTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'listingPricePerShare' | 'transferMethod'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
      & { securitySpecialist?: Maybe<(
        { __typename?: 'SecuritySpecialist' }
        & Pick<SecuritySpecialist, 'id'>
        & { user: (
          { __typename?: 'SecuritySpecialistUser' }
          & Pick<SecuritySpecialistUser, 'id' | 'email'>
        ), marketAnalyst?: Maybe<(
          { __typename?: 'SecuritySpecialistUser' }
          & Pick<SecuritySpecialistUser, 'id' | 'email'>
        )> }
      )> }
    ) }
  ), standingBid?: Maybe<(
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id' | 'shortId'>
  )> }
);

export type AcceptStandingBidModalStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'displayId' | 'version' | 'myPermissionsV2' | 'state' | 'acceptsShares' | 'shortId' | 'numSharesAvailable' | 'minPartialAcceptNumShares' | 'allowPartialAccept' | 'acceptsTransferMethods' | 'shareSeriesOptions' | 'pricePerShare'>
  & { company: (
    { __typename?: 'Company' }
    & AcceptStandingBidModalCompanyFragment
  ) }
);

export type AcceptStandingBidModalCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )>, companyEligibilityCriterion?: Maybe<(
    { __typename?: 'CompanyEligibilityCriterion' }
    & Pick<CompanyEligibilityCriterion, 'id' | 'text'>
  )> }
);

export type AcceptStandingBidSuccessModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'numSharesOriginal' | 'numSharesOriginalRounded' | 'listingPricePerShare' | 'transferMethod'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name'>
      & { securitySpecialist?: Maybe<(
        { __typename?: 'SecuritySpecialist' }
        & Pick<SecuritySpecialist, 'id'>
        & { user: (
          { __typename?: 'SecuritySpecialistUser' }
          & Pick<SecuritySpecialistUser, 'email'>
        ), marketAnalyst?: Maybe<(
          { __typename?: 'SecuritySpecialistUser' }
          & Pick<SecuritySpecialistUser, 'email'>
        )> }
      )> }
    ) }
  ), standingBid?: Maybe<(
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'shortId'>
  )> }
);

export type ModifyStandingBidMutationVariables = Exact<{
  standingBidId: Scalars['ID'];
  input: ModifyStandingBidInput;
}>;


export type ModifyStandingBidMutation = (
  { __typename?: 'RootMutationType' }
  & { modifyStandingBid?: Maybe<(
    { __typename?: 'StandingBidMutationResponse' }
    & { standingBid?: Maybe<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type ModifyStandingBidModalStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'displayId' | 'version' | 'state' | 'numSharesAvailable' | 'allowPartialAccept' | 'minPartialAcceptNumShares' | 'acceptsShares' | 'acceptsTransferMethods' | 'pricePerShare' | 'otherDetails' | 'representedEmail' | 'expireAt'>
  & { company: (
    { __typename?: 'Company' }
    & ModifyStandingBidModalCompanyFragment
  ) }
);

export type ModifyStandingBidModalCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'minPostPps' | 'maxPostPps'>
);

export type PlaceStandingBidMutationVariables = Exact<{
  input: PlaceStandingBidInput;
}>;


export type PlaceStandingBidMutation = (
  { __typename?: 'RootMutationType' }
  & { placeStandingBid?: Maybe<(
    { __typename?: 'PlaceStandingBidMutationResponse' }
    & { standingBid?: Maybe<(
      { __typename?: 'StandingBid' }
      & PlaceStandingBidSuccessModalStandingBidFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type PlaceStandingBidModalCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl' | 'myPermissionsV2' | 'tradeable' | 'minPostPps' | 'maxPostPps'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type PlaceStandingBidSuccessModalStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'pricePerShare' | 'brokerId'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ), marketAnalyst?: Maybe<(
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      )> }
    )> }
  ) }
);

export type PlaceStandingBidSelectCompanyInputListCompaniesQueryVariables = Exact<{
  orderBy: ListCompaniesOrderBy;
  first: Scalars['Int'];
  searchText?: Maybe<Scalars['String']>;
}>;


export type PlaceStandingBidSelectCompanyInputListCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { listCompanies?: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyEdge' }
      & PlaceStandingBidSelectCompanyInputCompanyEdgeFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type PlaceStandingBidSelectCompanyInputCompanyEdgeFragment = (
  { __typename?: 'CompanyEdge' }
  & Pick<CompanyEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'Company' }
    & PlaceStandingBidSelectCompanyInputCompanyFragment
  )> }
);

export type PlaceStandingBidSelectCompanyInputCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl' | 'myPermissionsV2' | 'tradeable'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type UnaccreditedSellerAcceptStandingBidV2MutationVariables = Exact<{
  standingBidId: Scalars['ID'];
  input: UnaccreditedSellerAcceptStandingBidInputV2;
}>;


export type UnaccreditedSellerAcceptStandingBidV2Mutation = (
  { __typename?: 'RootMutationType' }
  & { unaccreditedSellerAcceptStandingBidV2: (
    { __typename?: 'AcceptStandingBidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & AcceptStandingBidResponseBidFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'pricePerShare' | 'minPartialAcceptNumShares' | 'allowPartialAccept' | 'displayId' | 'shortId' | 'shareSeriesOptions'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ), marketAnalyst?: Maybe<(
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      )> }
    )>, companyEligibilityCriterion?: Maybe<(
      { __typename?: 'CompanyEligibilityCriterion' }
      & Pick<CompanyEligibilityCriterion, 'id' | 'text'>
    )> }
  ) }
  & GetNumOfSharesStandingBidFragment
);

export type WithdrawStandingBidMutationVariables = Exact<{
  standingBidId: Scalars['ID'];
  input: WithdrawStandingBidInput;
}>;


export type WithdrawStandingBidMutation = (
  { __typename?: 'RootMutationType' }
  & { withdrawStandingBid: (
    { __typename?: 'StandingBidMutationResponse' }
    & { standingBid?: Maybe<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type WithdrawStandingBidModalStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'displayId'>
);

export type InviteAlternateSignerMutationVariables = Exact<{
  input: InviteAlternateSignerInput;
}>;


export type InviteAlternateSignerMutation = (
  { __typename?: 'RootMutationType' }
  & { inviteAlternateSigner: (
    { __typename?: 'InviteAlternateSignerResponse' }
    & { alternateSigner?: Maybe<(
      { __typename?: 'AlternateSigner' }
      & BasicAlternateSignerFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type InviteAlternateSignerModalBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerId'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'type'>
    )> }
  )> }
);

export type RevokeAlternateSignerInvitationModalMutationVariables = Exact<{
  documentId: Scalars['ID'];
}>;


export type RevokeAlternateSignerInvitationModalMutation = (
  { __typename?: 'RootMutationType' }
  & { revokeAlternateSignerInvitation: (
    { __typename?: 'RevokeAlternateSignerInvitationResponse' }
    & { alternateSigner?: Maybe<(
      { __typename?: 'AlternateSigner' }
      & Pick<AlternateSigner, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type RevokeAlternateSignerInvitationModalDocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id'>
);

export type CompaniesSearchListCompaniesQueryVariables = Exact<{
  orderBy: ListCompaniesOrderBy;
  first: Scalars['Int'];
  searchText?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompanyStatus> | CompanyStatus>;
}>;


export type CompaniesSearchListCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { listCompanies?: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyEdge' }
      & CompaniesSearchCompanyEdgeFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type CompaniesSearchCompanyEdgeFragment = (
  { __typename?: 'CompanyEdge' }
  & Pick<CompanyEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'Company' }
    & CompaniesSearchCompanyFragment
  )> }
);

export type CompaniesSearchCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
);

export type UnaccreditedSellerNavbarMyActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerNavbarMyActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myCompany: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'logoUrl' | 'name'>
    ), myListing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, pendingSales: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )>, completedSales: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )> }
  ), unaccreditedSellerMyCompanyActivity: (
    { __typename?: 'UnaccreditedSellerMyCompanyActivity' }
    & { standingBidsWithDiscussions: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
    )> }
  ) }
);

export type UnaccreditedSellerNavbarByIdActivityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerNavbarByIdActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & UnaccreditedSellerNavbarByIdActivityCompanyFragment
  ) }
);

export type UnaccreditedSellerNavbarByIdActivityCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { myListings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, myCompletedTransactions: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )>, myOpenTransactions: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )>, standingBidsWithDiscussions: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
    )> }
  ) }
);

export type BrokerInfoPageUpsertBrokerInfoMutationVariables = Exact<{
  input: AddBrokerInfoInput;
}>;


export type BrokerInfoPageUpsertBrokerInfoMutation = (
  { __typename?: 'RootMutationType' }
  & { upsertBrokerInfo: (
    { __typename?: 'BrokerInfoMutationResponse' }
    & { brokerInfo?: Maybe<(
      { __typename?: 'BrokerInfo' }
      & BrokerInfoPageBrokerInfoFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type BrokerInfoPageCurrentActorQueryVariables = Exact<{ [key: string]: never; }>;


export type BrokerInfoPageCurrentActorQuery = (
  { __typename?: 'RootQueryType' }
  & { currentActor: (
    { __typename?: 'User' }
    & BrokerInfoPageUserFragment
  ) }
);

export type BrokerInfoPageBrokerInfoFragment = (
  { __typename?: 'BrokerInfo' }
  & Pick<BrokerInfo, 'id' | 'firstName' | 'lastName' | 'brokerRegistrationNumber' | 'firmRegistrationNumber' | 'firmName' | 'firmStreetAddress' | 'firmCity' | 'firmState' | 'firmPostalCode'>
);

export type BrokerInfoPageUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'finraRegistered'>
  & { onboardingV2?: Maybe<(
    { __typename?: 'Onboarding' }
    & Pick<Onboarding, 'id'>
  )>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, brokerInfo?: Maybe<(
    { __typename?: 'BrokerInfo' }
    & Pick<BrokerInfo, 'id' | 'firmName' | 'firmCity' | 'firmPostalCode' | 'firmStreetAddress' | 'firmState' | 'firmRegistrationNumber' | 'brokerRegistrationNumber'>
  )> }
);

export type BrokerIntermediaryInfoPageUpsertBrokerInfoMutationVariables = Exact<{
  input: AddBrokerIntermediaryInfoInput;
}>;


export type BrokerIntermediaryInfoPageUpsertBrokerInfoMutation = (
  { __typename?: 'RootMutationType' }
  & { upsertBrokerIntermediaryInfo: (
    { __typename?: 'BrokerInfoMutationResponse' }
    & { brokerInfo?: Maybe<(
      { __typename?: 'BrokerInfo' }
      & BrokerIntermediaryInfoPageBrokerInfoFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type BrokerIntermediaryInfoPageCurrentActorQueryVariables = Exact<{ [key: string]: never; }>;


export type BrokerIntermediaryInfoPageCurrentActorQuery = (
  { __typename?: 'RootQueryType' }
  & { currentActor: (
    { __typename?: 'User' }
    & BrokerIntermediaryInfoPageUserFragment
  ) }
);

export type BrokerIntermediaryInfoPageBrokerInfoFragment = (
  { __typename?: 'BrokerInfo' }
  & Pick<BrokerInfo, 'id' | 'firstName' | 'lastName' | 'brokerRegistrationNumber' | 'intermediaryPermission' | 'intermediaryNotes' | 'firmName' | 'firmStreetAddress'>
);

export type BrokerIntermediaryInfoPageUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName'>
  & { onboardingV2?: Maybe<(
    { __typename?: 'Onboarding' }
    & Pick<Onboarding, 'id'>
  )>, brokerInfo?: Maybe<(
    { __typename?: 'BrokerInfo' }
    & Pick<BrokerInfo, 'id' | 'firmName' | 'firmStreetAddress' | 'brokerRegistrationNumber' | 'intermediaryPermission' | 'intermediaryNotes'>
  )> }
);

export type HoldingDetailsComboboxListCompaniesQueryVariables = Exact<{
  orderBy: ListCompaniesOrderBy;
  first: Scalars['Int'];
  searchText?: Maybe<Scalars['String']>;
}>;


export type HoldingDetailsComboboxListCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { listCompanies?: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyEdge' }
      & HoldingDetailsPageCompanyEdgeFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type HoldingDetailsPageMyHoldingsQueryVariables = Exact<{ [key: string]: never; }>;


export type HoldingDetailsPageMyHoldingsQuery = (
  { __typename?: 'RootQueryType' }
  & { myHoldings: (
    { __typename?: 'MyHoldingsResponse' }
    & { holdingsV2?: Maybe<Array<(
      { __typename?: 'Holding' }
      & HoldingDetailsPageHoldingFragment
    ) | (
      { __typename?: 'UnlistedHolding' }
      & HoldingDetailsPageUnlistedHoldingFragment
    )>> }
  ) }
);

export type HoldingDetailsPageSetHoldingsMutationVariables = Exact<{
  addHoldingsInput: AddHoldingsV2Input;
}>;


export type HoldingDetailsPageSetHoldingsMutation = (
  { __typename?: 'RootMutationType' }
  & { setHoldings: (
    { __typename?: 'AddHoldingsV2Response' }
    & { holdingsV2?: Maybe<Array<(
      { __typename?: 'Holding' }
      & Pick<Holding, 'companyId' | 'id' | 'numSharesV2' | 'sellerId'>
    ) | { __typename?: 'UnlistedHolding' }>>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type HoldingDetailsPageCompanyEdgeFragment = (
  { __typename?: 'CompanyEdge' }
  & Pick<CompanyEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'Company' }
    & HoldingDetailsPageCompanyFragment
  )> }
);

export type HoldingDetailsPageCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
);

export type HoldingDetailsPageHoldingFragment = (
  { __typename?: 'Holding' }
  & Pick<Holding, 'companyId' | 'id' | 'numSharesV2'>
  & { company: (
    { __typename?: 'Company' }
    & HoldingDetailsPageCompanyFragment
  ) }
);

export type HoldingDetailsPageUnlistedHoldingFragment = (
  { __typename?: 'UnlistedHolding' }
  & Pick<UnlistedHolding, 'id' | 'companyName' | 'numSharesV2'>
);

export type InstitutionInfoPageMutationVariables = Exact<{
  upsertInstitutionInput: UpsertInstitutionInput;
  updateUserInput: UpdateUserInput;
}>;


export type InstitutionInfoPageMutation = (
  { __typename?: 'RootMutationType' }
  & { upsertInstitution: (
    { __typename?: 'InstitutionMutationResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ), updateUser?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type InvestingGoalsQueryVariables = Exact<{ [key: string]: never; }>;


export type InvestingGoalsQuery = (
  { __typename?: 'RootQueryType' }
  & { investingGoalQuestionGroup?: Maybe<(
    { __typename?: 'InvestingGoalQuestionGroup' }
    & Pick<InvestingGoalQuestionGroup, 'id'>
    & { questions: Array<(
      { __typename?: 'InvestingGoalQuestion' }
      & Pick<InvestingGoalQuestion, 'id' | 'text'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'InvestingGoalOption' }
        & Pick<InvestingGoalOption, 'text' | 'id'>
      )>>> }
    )> }
  )> }
);

export type AnswerInvestingGoalsMutationVariables = Exact<{
  input: InvestingGoalAnswerInput;
}>;


export type AnswerInvestingGoalsMutation = (
  { __typename?: 'RootMutationType' }
  & { createInvestingGoalAnswers: (
    { __typename?: 'InvestingGoalAnswerMutationResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type UpdateInstitutionAccreditationAnswerMutationVariables = Exact<{
  input: UpdateAccreditationAnswerInput;
}>;


export type UpdateInstitutionAccreditationAnswerMutation = (
  { __typename?: 'RootMutationType' }
  & { updateInstitutionAccreditationAnswer?: Maybe<(
    { __typename?: 'UpdateInstitutionAccreditationAnswerResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>>, institution: (
      { __typename?: 'Institution' }
      & Pick<Institution, 'id'>
    ) }
  )> }
);

export type CurrentAccreditationQuestionGroupQueryVariables = Exact<{
  countryId: Scalars['ID'];
  investorType: InvestorStatus;
}>;


export type CurrentAccreditationQuestionGroupQuery = (
  { __typename?: 'RootQueryType' }
  & { currentAccreditationQuestionGroup?: Maybe<(
    { __typename?: 'AccreditationQuestionGroup' }
    & Pick<AccreditationQuestionGroup, 'id' | 'country' | 'investorType'>
    & { questions: Array<Maybe<(
      { __typename?: 'AccreditationQuestion' }
      & Pick<AccreditationQuestion, 'id' | 'accredited' | 'key' | 'order' | 'text'>
    )>> }
  )> }
);

export type BrokerResidencePageUpdateBrokerResidenceMutationVariables = Exact<{
  input: UpdateBrokerResidenceInput;
}>;


export type BrokerResidencePageUpdateBrokerResidenceMutation = (
  { __typename?: 'RootMutationType' }
  & { updateBrokerResidence?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'finraRegistered'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type WatchlistPageActorWatchlistQueryVariables = Exact<{ [key: string]: never; }>;


export type WatchlistPageActorWatchlistQuery = (
  { __typename?: 'RootQueryType' }
  & { currentActor: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { watchlist: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & WatchlistPageWatchlistCompanyFragment
    )> }
  ) }
);

export type WatchlistPageAddToWatchlistMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type WatchlistPageAddToWatchlistMutation = (
  { __typename?: 'RootMutationType' }
  & { addToWatchlist?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { watchlist: Array<(
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type WatchlistPageRemoveFromWatchlistMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type WatchlistPageRemoveFromWatchlistMutation = (
  { __typename?: 'RootMutationType' }
  & { removeFromWatchlist?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { watchlist: Array<(
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type WatchlistPageWatchlistCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
  & { currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
);

export type TransitionCurrentStepMutationVariables = Exact<{
  onboardingId: Scalars['ID'];
}>;


export type TransitionCurrentStepMutation = (
  { __typename?: 'RootMutationType' }
  & { transitionCurrentStep: (
    { __typename?: 'OnboardingMutationResponse' }
    & { onboarding?: Maybe<(
      { __typename?: 'Onboarding' }
      & Pick<Onboarding, 'id' | 'currentStep'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type TransitionBackToPreviousStepMutationVariables = Exact<{
  onboardingId: Scalars['ID'];
}>;


export type TransitionBackToPreviousStepMutation = (
  { __typename?: 'RootMutationType' }
  & { transitionBackToPreviousStep: (
    { __typename?: 'OnboardingMutationResponse' }
    & { onboarding?: Maybe<(
      { __typename?: 'Onboarding' }
      & Pick<Onboarding, 'id' | 'currentStep'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type UpdateOnboardingMutationVariables = Exact<{
  onboardingId: Scalars['ID'];
  input: UpdateOnboardingInput;
}>;


export type UpdateOnboardingMutation = (
  { __typename?: 'RootMutationType' }
  & { updateOnboarding: (
    { __typename?: 'OnboardingMutationResponse' }
    & { onboarding?: Maybe<(
      { __typename?: 'Onboarding' }
      & Pick<Onboarding, 'id' | 'hasAgreedToClickwrapAgreement' | 'identityVerificationSubmissionStatus'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type AddBrokerIntermediaryInfoMutationVariables = Exact<{
  input: AddBrokerIntermediaryInfoInput;
}>;


export type AddBrokerIntermediaryInfoMutation = (
  { __typename?: 'RootMutationType' }
  & { addBrokerIntermediaryInfo: (
    { __typename?: 'BrokerInfoMutationResponse' }
    & { brokerInfo?: Maybe<(
      { __typename?: 'BrokerInfo' }
      & Pick<BrokerInfo, 'id'>
    )> }
  ) }
);

export type UpdateBrokerResidenceMutationVariables = Exact<{
  input: UpdateBrokerResidenceInput;
}>;


export type UpdateBrokerResidenceMutation = (
  { __typename?: 'RootMutationType' }
  & { updateBrokerResidence?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'finraRegistered'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type AddBrokerInfoMutationVariables = Exact<{
  input: AddBrokerInfoInput;
}>;


export type AddBrokerInfoMutation = (
  { __typename?: 'RootMutationType' }
  & { addBrokerInfo: (
    { __typename?: 'BrokerInfoMutationResponse' }
    & { brokerInfo?: Maybe<(
      { __typename?: 'BrokerInfo' }
      & ResidencePageBrokerInfoFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type ResidencePageBrokerInfoFragment = (
  { __typename?: 'BrokerInfo' }
  & Pick<BrokerInfo, 'id' | 'firstName' | 'lastName' | 'brokerRegistrationNumber' | 'firmRegistrationNumber' | 'intermediaryPermission' | 'intermediaryNotes' | 'firmName' | 'firmStreetAddress' | 'firmCity' | 'firmState' | 'firmPostalCode'>
);

export type AnswerInstitutionAccreditationQuestionsMutationVariables = Exact<{
  input: AnswerInstitutionAccreditationQuestionsInput;
}>;


export type AnswerInstitutionAccreditationQuestionsMutation = (
  { __typename?: 'RootMutationType' }
  & { answerInstitutionAccreditationQuestions: (
    { __typename?: 'AnswerInstitutionAccreditationResponse' }
    & { institution?: Maybe<(
      { __typename?: 'Institution' }
      & Pick<Institution, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type InstitutionAccreditationPageUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & { accreditationQuestions: Array<(
    { __typename?: 'AccreditationQuestion' }
    & Pick<AccreditationQuestion, 'id'>
  )>, institution?: Maybe<(
    { __typename?: 'Institution' }
    & InstitutionAccreditationPageInstitutionFragment
  )> }
);

export type InstitutionAccreditationPageInstitutionFragment = (
  { __typename?: 'Institution' }
  & Pick<Institution, 'id'>
  & { country: (
    { __typename?: 'Country' }
    & InstitutionAccreditationPageCountryFragment
  ) }
);

export type InstitutionAccreditationPageCountryFragment = (
  { __typename?: 'Country' }
  & Pick<Country, 'id' | 'name'>
);

export type InstitutionDetailsMutationVariables = Exact<{
  createInstitutionInput: CreateInstitutionInput;
  updateUserInput: UpdateUserInput;
}>;


export type InstitutionDetailsMutation = (
  { __typename?: 'RootMutationType' }
  & { createInstitution: (
    { __typename?: 'InstitutionMutationResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ), updateUser?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type SellerLotDetailsMutationVariables = Exact<{
  holdingInput: AddHoldingInput;
}>;


export type SellerLotDetailsMutation = (
  { __typename?: 'RootMutationType' }
  & { addHolding?: Maybe<(
    { __typename?: 'AddHoldingMutationResponse' }
    & { eitherHolding?: Maybe<(
      { __typename?: 'EitherHolding' }
      & EitherHoldingFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type OnboardingSelectCompanyInputListCompaniesQueryVariables = Exact<{
  orderBy: ListCompaniesOrderBy;
  first: Scalars['Int'];
  searchText?: Maybe<Scalars['String']>;
}>;


export type OnboardingSelectCompanyInputListCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { listCompanies?: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyEdge' }
      & OnboardingSelectCompanyInputListCompanyEdgeFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type OnboardingSelectCompanyInputListCompanyEdgeFragment = (
  { __typename?: 'CompanyEdge' }
  & Pick<CompanyEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'Company' }
    & OnboardingSelectCompanyInputCompanyFragment
  )> }
);

export type OnboardingSelectCompanyInputCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
);

export type CurrentMembershipAgreementQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentMembershipAgreementQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'currentMembershipAgreement'>
);

export type DocumentTransactionCardTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'numShares' | 'pricePerShare' | 'buyerId' | 'buyerBrokerId' | 'sellerId' | 'sellerBrokerId'>
  & { pendingModification?: Maybe<(
    { __typename?: 'TransactionModification' }
    & DocumentTransactionCardTransactionModificationFragment
  )>, bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'pricePerShare' | 'displayId'>
    & { listing: (
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & ActivityCardCompanyFragment
      ) }
    ), standingBid?: Maybe<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id' | 'displayId'>
    )> }
    & GetBidNumSharesActualBidFragment
  ) }
  & MakeUrlTransactionFragment
);

export type DocumentTransactionCardTransactionModificationFragment = (
  { __typename?: 'TransactionModification' }
  & Pick<TransactionModification, 'numShares' | 'pricePerShare'>
  & { commission?: Maybe<(
    { __typename?: 'Commission' }
    & TransactionFeeBreakdownCommissionFragment
  )> }
);

export type TransactionByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TransactionByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { transactionById?: Maybe<(
    { __typename?: 'Transaction' }
    & TransactionDocumentPageTransactionFragment
  )> }
);

export type TransactionDocumentPageTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'signingProcedure' | 'state' | 'myPermissionsV2' | 'sellerBankAccountId' | 'sellerBankAccountRequestStatus'>
  & { pendingModification?: Maybe<(
    { __typename?: 'TransactionModification' }
    & Pick<TransactionModification, 'id'>
  )>, bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
    & TransactionDocumentPageBidFragment
  ), document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'type' | 'externalId'>
    & TransactionDocumentPageDocumentFragment
  )> }
  & DocumentTransactionCardTransactionFragment
  & MakeUrlTransactionFragment
);

export type TransactionDocumentPageDocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'type'>
  & { signers: Array<(
    { __typename?: 'DocumentSigner' }
    & Pick<DocumentSigner, 'id' | 'institutionId' | 'userId'>
  )> }
);

export type TransactionDocumentPageBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'shortId'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ) }
);

export type BidActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'actualNumSharesVisible' | 'pricePerShare' | 'counterpartyId' | 'brokerCounterpartyId' | 'counterpartyInstitutionId'>
  & { commission?: Maybe<(
    { __typename?: 'Commission' }
    & BidPageFeeBreakdownCommissionFragment
  )>, transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state' | 'pricePerShare' | 'numShares' | 'transferMethod' | 'sellerBrokerId' | 'sellerBankAccountId' | 'sellerBankAccountRequestStatus' | 'sellerId' | 'buyerId' | 'buyerBrokerId'>
    & { sellerBankAccount?: Maybe<(
      { __typename?: 'BankAccount' }
      & UserBankAccountFragment
    )>, company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name'>
    ) }
    & TransactionModificationFragmentTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'transferMethod'>
  ) }
  & BidPageBrokerActivityBidFragment
  & BidPageBuyerActivityBidFragment
  & BidPageSellerActivityBidFragment
  & GetIsBuyerForBidBidFragment
  & GetBidNumSharesActualBidFragment
);

export type TransactionModificationFragmentTransactionFragment = (
  { __typename?: 'Transaction' }
  & { pendingModification?: Maybe<(
    { __typename?: 'TransactionModification' }
    & Pick<TransactionModification, 'id' | 'numShares' | 'pricePerShare' | 'status'>
    & { acknowledgments: Array<(
      { __typename?: 'TransactionModificationAcknowledgment' }
      & Pick<TransactionModificationAcknowledgment, 'id' | 'insertedAt' | 'updatedAt' | 'status' | 'userId'>
    )>, commission?: Maybe<(
      { __typename?: 'Commission' }
      & TransactionFeeBreakdownCommissionFragment
    )>, feeDiscountApplications?: Maybe<Array<(
      { __typename?: 'FeeDiscountApplication' }
      & Pick<FeeDiscountApplication, 'id'>
      & BidFeeDiscountBreakdownFeeDiscountApplicationFragment
    )>> }
  )> }
);

export type BidPageBrokerActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & GetIsBrokerForListingListingFragment
  ) }
  & GetIsBrokerForBidBidFragment
  & BidPageBrokerBidderActivityBidFragment
  & BidPageBrokerListerActivityBidFragment
);

export type BidPageBrokerBidderActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'buyerEmail' | 'numShares' | 'pricePerShare'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
      & { securitySpecialist?: Maybe<(
        { __typename?: 'SecuritySpecialist' }
        & Pick<SecuritySpecialist, 'id'>
        & { user: (
          { __typename?: 'SecuritySpecialistUser' }
          & Pick<SecuritySpecialistUser, 'email'>
        ), marketAnalyst?: Maybe<(
          { __typename?: 'SecuritySpecialistUser' }
          & Pick<SecuritySpecialistUser, 'email'>
        )> }
      )> }
      & GetAvailableCompanyActionsCompanyFragment
      & PlaceStandingBidModalCompanyFragment
    ) }
  ) }
);

export type BidPageBrokerListerActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'state' | 'sellerEmail'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
      & { securitySpecialist?: Maybe<(
        { __typename?: 'SecuritySpecialist' }
        & Pick<SecuritySpecialist, 'id'>
        & { user: (
          { __typename?: 'SecuritySpecialistUser' }
          & Pick<SecuritySpecialistUser, 'email'>
        ), marketAnalyst?: Maybe<(
          { __typename?: 'SecuritySpecialistUser' }
          & Pick<SecuritySpecialistUser, 'email'>
        )> }
      )> }
    ) }
  ) }
);

export type BidPageBuyerActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & MessageCounterpartyCardListingFragment
  ), discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
    & DiscussionThreadCardDiscussionFragment
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & GetAvailableCompanyActionsCompanyFragment
  ), transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & TransactingEntityFragment
  )> }
  & GetAvailableBidActionsBidFragment
  & BidPageBuyerActivityPlaceStandingBidCtaCardBidFragment
);

export type TransactingEntityFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'state'>
  & { buyerEntity?: Maybe<(
    { __typename?: 'Entity' }
    & BasicUserEntityFragment
  )>, sellerEntity?: Maybe<(
    { __typename?: 'Entity' }
    & BasicUserEntityFragment
  )> }
);

export type UpdateTransactionSellerBankAccountRequestStatusMutationVariables = Exact<{
  transactionId: Scalars['ID'];
  sellerBankAccountRequestStatus: BankAccountRequestStatus;
}>;


export type UpdateTransactionSellerBankAccountRequestStatusMutation = (
  { __typename?: 'RootMutationType' }
  & { updateTransactionSellerBankAccountRequestStatus: (
    { __typename?: 'UpdateTransactionSellerBankAccountRequestStatusResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'type' | 'field'>
    )>>, transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'sellerBankAccountRequestStatus'>
    )> }
  ) }
);

export type TransactionOnSellerBankAccountCreatedSubscriptionVariables = Exact<{
  transactionId: Scalars['ID'];
}>;


export type TransactionOnSellerBankAccountCreatedSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { onSellerBankAccountCreated: (
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
  ) }
);

export type SubmitBankAccountVerificationMutationVariables = Exact<{
  depositValueOne: Scalars['Int'];
  depositValueTwo: Scalars['Int'];
  sellerBankAccountId: Scalars['ID'];
}>;


export type SubmitBankAccountVerificationMutation = (
  { __typename?: 'RootMutationType' }
  & { updateBankAccountVerification: (
    { __typename?: 'ExternalAccountResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message' | 'type'>
    )>>, externalAccount?: Maybe<(
      { __typename?: 'ExternalAccount' }
      & Pick<ExternalAccount, 'id' | 'counterpartyId' | 'verificationStatus'>
    )> }
  ) }
);

export type UpdateTransactionSellerBankAccountIdMutationVariables = Exact<{
  transactionId: Scalars['ID'];
  sellerBankAccountId: Scalars['ID'];
}>;


export type UpdateTransactionSellerBankAccountIdMutation = (
  { __typename?: 'RootMutationType' }
  & { updateTransactionSellerBankAccountId: (
    { __typename?: 'UpdateTransactionSellerBankAccountIdResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message' | 'type'>
    )>>, transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'sellerBankAccountId'>
    )> }
  ) }
);

export type ModernTreasuryVerifyAccountCardBankAccountFragment = (
  { __typename?: 'BankAccount' }
  & Pick<BankAccount, 'id' | 'last4' | 'insertedAt' | 'verificationStatus' | 'verifiedById'>
  & { lastUsedTransaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & { bid: (
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'displayId'>
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'logoUrl'>
    ) }
  )> }
);

export type BidPageBuyerActivityPlaceStandingBidCtaCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'numShares' | 'pricePerShare'>
  & { company: (
    { __typename?: 'Company' }
    & PlaceStandingBidModalCompanyFragment
  ) }
);

export type BidPageSellerActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & MessageCounterpartyCardListingFragment
  ), transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & BidPageSellerActivityBidTransactionFragment
    & TransactingEntityFragment
  )>, discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
    & DiscussionThreadCardDiscussionFragment
  )> }
  & GetAvailableBidActionsBidFragment
);

export type TransactingEntityItemEntityFragment = (
  { __typename?: 'Entity' }
  & Pick<Entity, 'active' | 'otherType' | 'id' | 'insertedAt' | 'legalName' | 'type'>
  & { jurisdictionOfFormation?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'fullName' | 'id' | 'name'>
  )>, bankAccounts: Array<(
    { __typename?: 'BankAccount' }
    & ModernTreasuryVerifyAccountCardBankAccountFragment
  )> }
);

export type BidPageSellerActivityBidTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'sellerBankAccountId' | 'sellerBankAccountRequestStatus'>
  & { sellerBankAccount?: Maybe<(
    { __typename?: 'BankAccount' }
    & UserBankAccountFragment
  )>, sellerEntity?: Maybe<(
    { __typename?: 'Entity' }
    & TransactingEntityItemEntityFragment
  )> }
);

export type TransactionModificationTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'state' | 'pricePerShare' | 'numShares' | 'transferMethod' | 'sellerBrokerId' | 'sellerId' | 'buyerId' | 'buyerBrokerId'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  ) }
  & TransactionModificationFragmentTransactionFragment
);

export type TransactionModificationTransactionModificationAcknowledgmentFragment = (
  { __typename?: 'TransactionModificationAcknowledgment' }
  & Pick<TransactionModificationAcknowledgment, 'id' | 'insertedAt' | 'updatedAt' | 'status' | 'userId'>
);

export type UpdateTransactionModificationAcknowledgmentMutationVariables = Exact<{
  transactionId: Scalars['ID'];
  input: UpdateTransactionModificationAcknowledgmentInput;
}>;


export type UpdateTransactionModificationAcknowledgmentMutation = (
  { __typename?: 'RootMutationType' }
  & { updateTransactionModificationAcknowledgment: (
    { __typename?: 'UpdateTransactionModificationAcknowledgmentResponse' }
    & { transactionModificationAcknowledgment?: Maybe<(
      { __typename?: 'TransactionModificationAcknowledgment' }
      & Pick<TransactionModificationAcknowledgment, 'id'>
      & TransactionModificationTransactionModificationAcknowledgmentFragment
    )> }
  ) }
);

export type TransactionModificationOnTransactionModificationUpdatedSubscriptionVariables = Exact<{
  transactionModificationId: Scalars['ID'];
}>;


export type TransactionModificationOnTransactionModificationUpdatedSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { onTransactionModificationUpdated: (
    { __typename?: 'TransactionModification' }
    & Pick<TransactionModification, 'id'>
  ) }
);

export type BidPageMyActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type BidPageMyActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { myActivity: (
    { __typename?: 'UserActivity' }
    & { myBids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
    )> }
  ) }
);

export type BidPageBidByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BidPageBidByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { bidById?: Maybe<(
    { __typename?: 'Bid' }
    & BidPageBidFragment
  )> }
);

export type WithdrawBidMutationVariables = Exact<{
  bidId: Scalars['ID'];
}>;


export type WithdrawBidMutation = (
  { __typename?: 'RootMutationType' }
  & { withdrawBid: (
    { __typename?: 'BidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
      & { listing: (
        { __typename?: 'Listing' }
        & MakeUrlListingFragment
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type BidPageBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & BidPageContentBidFragment
);

export type BidPageContentBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'acceptedAt' | 'state'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'myPermissionsV2' | 'tradeable'>
  ) }
  & BidInfoBidFragment
  & BidActivityBidFragment
);

export type BidInfoBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { standingBid?: Maybe<(
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id'>
    & BidInfoStandingBidFragment
  )>, transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & ListingPageListingInfoCardListingFragment
  ) }
  & BidActionsBidFragment
  & BidInfoCardBidFragment
  & GetIsBuyerForBidBidFragment
  & GetAvailableBidActionsBidFragment
  & TransactionInfoCardBidFragment
);

export type BidInfoStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & StandingBidInfoCardStandingBidFragment
);

export type BidInfoCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counteredAt' | 'placedAt' | 'insertedAt' | 'pricePerShare' | 'fromHiive' | 'state' | 'counterpartyId' | 'displayId' | 'brokerId' | 'expireAt'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state' | 'rofr'>
    & { history: Array<(
      { __typename?: 'TransactionHistory' }
      & BidInfoCardBidTransactionHistoryFragment
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
    & UseDocumentSignersTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'isHiiveSpv'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & FocusedShareDetailsHeaderCompanyFragment
    ) }
  ), feeDiscountApplications?: Maybe<Array<(
    { __typename?: 'FeeDiscountApplication' }
    & FeeDiscountApplicationTypeFragment
  )>> }
  & GetIsBuyerForBidBidFragment
  & GetAvailableBidActionsBidFragment
  & GetBidNumSharesActualBidFragment
  & AcceptBidModalBidFragment
  & BidPageDocumentActionsBidFragment
  & ModifyCounterBidModalBidFragment
  & WithdrawCounterBidModalBidFragment
  & AcceptedBidDetailsBidFragment
  & CounterBidFeeBreakdownBidFragment
  & BidFeeBreakdownBidFragment
  & BidStatusBidFragment
  & BuyerBidInfoCardBidFragment
  & BrokerSellerBidInfoCardFragment
  & SellerBidInfoCardFragment
);

export type BidInfoCardBidTransactionHistoryFragment = (
  { __typename?: 'TransactionHistory' }
  & Pick<TransactionHistory, 'pricePerShare' | 'numShares'>
  & { commission: (
    { __typename?: 'Commission' }
    & TransactionFeeBreakdownCommissionFragment
  ) }
);

export type FeeDiscountApplicationTypeFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id' | 'initial' | 'state'>
  & { feeDiscountApplicationCommission?: Maybe<(
    { __typename?: 'FeeDiscountApplicationCommission' }
    & Pick<FeeDiscountApplicationCommission, 'id' | 'amount'>
  )>, feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & Pick<FeeDiscount, 'id' | 'name' | 'type' | 'value'>
  ) }
);

export type BrokerSellerBidInfoCardFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counteredAt' | 'placedAt' | 'insertedAt' | 'pricePerShare' | 'fromHiive' | 'state' | 'counterpartyId' | 'displayId' | 'brokerId' | 'expireAt'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state' | 'rofr'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
    & UseDocumentSignersTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'isHiiveSpv'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & FocusedShareDetailsHeaderCompanyFragment
    ) }
  ) }
  & GetIsBuyerForBidBidFragment
  & GetAvailableBidActionsBidFragment
  & GetBidNumSharesActualBidFragment
  & AcceptBidModalBidFragment
  & BidPageDocumentActionsBidFragment
  & ModifyCounterBidModalBidFragment
  & WithdrawCounterBidModalBidFragment
  & AcceptBidModalBidFragment
  & AcceptedBidDetailsBidFragment
  & BidFeeBreakdownBidFragment
  & BidInfoCardStatusTileBidFragment
);

export type BrokerTransactionInfoCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'numShares'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'numShares' | 'pricePerShare'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & FocusedShareDetailsHeaderCompanyFragment
    ) }
    & UseDocumentSignersTransactionFragment
    & BrokerSellerTransactionFeeBreakdownTransactionFragment
  )> }
  & GetBidNumSharesActualBidFragment
  & GetShowDocumentActionsBidFragment
  & BidInfoCardStatusTileBidFragment
  & AcceptedBidDetailsBidFragment
  & BidPageDocumentActionsBidFragment
  & GetIsBrokerForBidBidFragment
);

export type BuyerBidInfoCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counteredAt' | 'placedAt' | 'insertedAt' | 'pricePerShare' | 'fromHiive' | 'state' | 'counterpartyId' | 'displayId' | 'brokerId' | 'expireAt'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state' | 'rofr'>
    & { history: Array<(
      { __typename?: 'TransactionHistory' }
      & BidInfoCardBidTransactionHistoryFragment
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
    & UseDocumentSignersTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'isHiiveSpv'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & FocusedShareDetailsHeaderCompanyFragment
    ) }
  ), feeDiscountApplications?: Maybe<Array<(
    { __typename?: 'FeeDiscountApplication' }
    & FeeDiscountApplicationTypeFragment
  )>> }
  & GetIsBuyerForBidBidFragment
  & GetAvailableBidActionsBidFragment
  & GetBidNumSharesActualBidFragment
  & AcceptBidModalBidFragment
  & BidPageDocumentActionsBidFragment
  & ModifyCounterBidModalBidFragment
  & WithdrawCounterBidModalBidFragment
  & AcceptBidModalBidFragment
  & AcceptedBidDetailsBidFragment
  & BidFeeBreakdownBidFragment
  & BidInfoCardStatusTileBidFragment
  & BidActionsBidFragment
);

export type BuyerCounterBidInfoCardFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counteredAt' | 'placedAt' | 'insertedAt' | 'pricePerShare' | 'fromHiive' | 'state' | 'counterpartyId' | 'displayId' | 'brokerId' | 'expireAt'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state' | 'rofr'>
    & { history: Array<(
      { __typename?: 'TransactionHistory' }
      & BidInfoCardBidTransactionHistoryFragment
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
    & UseDocumentSignersTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'isHiiveSpv'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & FocusedShareDetailsHeaderCompanyFragment
    ) }
  ), feeDiscountApplications?: Maybe<Array<(
    { __typename?: 'FeeDiscountApplication' }
    & FeeDiscountApplicationTypeFragment
  )>> }
  & GetIsBuyerForBidBidFragment
  & GetAvailableBidActionsBidFragment
  & GetBidNumSharesActualBidFragment
  & AcceptBidModalBidFragment
  & BidPageDocumentActionsBidFragment
  & ModifyCounterBidModalBidFragment
  & WithdrawCounterBidModalBidFragment
  & AcceptedBidDetailsBidFragment
  & CounterBidFeeBreakdownBidFragment
  & BidFeeBreakdownBidFragment
  & BidInfoCardStatusTileBidFragment
  & BidActionsBidFragment
);

export type BuyerTransactionInfoCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'numShares'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'numShares' | 'pricePerShare'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & FocusedShareDetailsHeaderCompanyFragment
    ) }
    & UseDocumentSignersTransactionFragment
  )> }
  & GetBidNumSharesActualBidFragment
  & GetShowDocumentActionsBidFragment
  & BidInfoCardStatusTileBidFragment
  & AcceptedBidDetailsBidFragment
  & BidPageDocumentActionsBidFragment
);

export type CounterBidInfoCardFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counteredAt' | 'placedAt' | 'insertedAt' | 'pricePerShare' | 'fromHiive' | 'state' | 'counterpartyId' | 'displayId' | 'brokerId' | 'expireAt'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state' | 'rofr'>
    & { history: Array<(
      { __typename?: 'TransactionHistory' }
      & BidInfoCardBidTransactionHistoryFragment
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
    & UseDocumentSignersTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'isHiiveSpv'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & FocusedShareDetailsHeaderCompanyFragment
    ) }
  ), feeDiscountApplications?: Maybe<Array<(
    { __typename?: 'FeeDiscountApplication' }
    & FeeDiscountApplicationTypeFragment
  )>> }
  & GetIsBuyerForBidBidFragment
  & GetAvailableBidActionsBidFragment
  & GetBidNumSharesActualBidFragment
  & AcceptBidModalBidFragment
  & BidPageDocumentActionsBidFragment
  & ModifyCounterBidModalBidFragment
  & WithdrawCounterBidModalBidFragment
  & AcceptedBidDetailsBidFragment
  & CounterBidFeeBreakdownBidFragment
  & BidFeeBreakdownBidFragment
  & BuyerCounterBidInfoCardFragment
  & BidInfoCardStatusTileBidFragment
);

export type SellerBidInfoCardFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counteredAt' | 'placedAt' | 'insertedAt' | 'pricePerShare' | 'fromHiive' | 'state' | 'counterpartyId' | 'displayId' | 'brokerId' | 'expireAt'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state' | 'rofr'>
    & { history: Array<(
      { __typename?: 'TransactionHistory' }
      & BidInfoCardBidTransactionHistoryFragment
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
    & UseDocumentSignersTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'isHiiveSpv'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & FocusedShareDetailsHeaderCompanyFragment
    ) }
  ) }
  & GetIsBuyerForBidBidFragment
  & GetAvailableBidActionsBidFragment
  & GetBidNumSharesActualBidFragment
  & AcceptBidModalBidFragment
  & BidPageDocumentActionsBidFragment
  & ModifyCounterBidModalBidFragment
  & WithdrawCounterBidModalBidFragment
  & AcceptedBidDetailsBidFragment
  & BidFeeBreakdownBidFragment
);

export type SellerCounterBidInfoCardFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counteredAt' | 'placedAt' | 'insertedAt' | 'pricePerShare' | 'fromHiive' | 'state' | 'counterpartyId' | 'displayId' | 'brokerId' | 'expireAt'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state' | 'rofr'>
    & { history: Array<(
      { __typename?: 'TransactionHistory' }
      & BidInfoCardBidTransactionHistoryFragment
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
    & UseDocumentSignersTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'isHiiveSpv'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & FocusedShareDetailsHeaderCompanyFragment
    ) }
  ) }
  & GetIsBuyerForBidBidFragment
  & GetAvailableBidActionsBidFragment
  & GetBidNumSharesActualBidFragment
  & AcceptBidModalBidFragment
  & BidPageDocumentActionsBidFragment
  & ModifyCounterBidModalBidFragment
  & WithdrawCounterBidModalBidFragment
  & AcceptBidModalBidFragment
  & AcceptedBidDetailsBidFragment
  & BidFeeBreakdownBidFragment
);

export type SellerTransactionInfoCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'numShares'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'numShares' | 'pricePerShare'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & FocusedShareDetailsHeaderCompanyFragment
    ) }
    & UseDocumentSignersTransactionFragment
    & TransactionFeeBreakdownTransactionFragment
  )> }
  & GetBidNumSharesActualBidFragment
  & GetShowDocumentActionsBidFragment
  & BidInfoCardStatusTileBidFragment
  & AcceptedBidDetailsBidFragment
  & BidPageDocumentActionsBidFragment
);

export type BidHighFeesWarningBidFragment = (
  { __typename?: 'Bid' }
  & GetCanCounterNumberOfSharesBidFragment
);

export type AutomatedLoiBuyerStatesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & UseDocumentSignersTransactionFragment
    & BidAcceptedStatusItemTransactionFragment
  )> }
);

export type AutomatedLoiSellerStatesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & UseDocumentSignersTransactionFragment
    & BidAcceptedStatusItemTransactionFragment
  )> }
);

export type AutomatedStnBuyerStatesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & UseDocumentSignersTransactionFragment
    & BidAcceptedStatusItemTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ) }
);

export type AutomatedStnSellerStatesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & UseDocumentSignersTransactionFragment
    & BidAcceptedStatusItemTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ) }
);

export type BidAcceptedStatusItemTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'state'>
  & { bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'brokerId' | 'buyerId' | 'standingBidId' | 'brokerCounterpartyId'>
  ), counterparty?: Maybe<(
    { __typename?: 'Counterparty' }
    & Pick<Counterparty, 'id' | 'institutionId'>
  )>, document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'type'>
    & { signers: Array<(
      { __typename?: 'DocumentSigner' }
      & Pick<DocumentSigner, 'id' | 'userId'>
      & BidAcceptedStatusItemDocumentSignerFragment
    )> }
  )> }
  & UseDocumentSignersTransactionFragment
  & GetTransactionCounterpartyNameTransactionFragment
);

export type BidAcceptedStatusItemDocumentSignerFragment = (
  { __typename?: 'DocumentSigner' }
  & Pick<DocumentSigner, 'id' | 'updatedAt' | 'userId' | 'institutionId'>
);

export type ManualLoiBuyerStatesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & GetTransactionCounterpartyNameTransactionFragment
  )> }
);

export type ManualLoiSellerStatesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & GetTransactionCounterpartyNameTransactionFragment
  )> }
);

export type ManualStnBuyerStatesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & GetTransactionCounterpartyNameTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ) }
);

export type ManualStnSellerStatesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & GetTransactionCounterpartyNameTransactionFragment
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ) }
);

export type TransactionStatesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'signingProcedure' | 'documentTypeV2' | 'state'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
  )> }
  & ManualLoiBuyerStatesBidFragment
  & AutomatedLoiBuyerStatesBidFragment
  & ManualStnBuyerStatesBidFragment
  & AutomatedStnBuyerStatesBidFragment
  & ManualLoiSellerStatesBidFragment
  & AutomatedLoiSellerStatesBidFragment
  & ManualStnSellerStatesBidFragment
  & AutomatedStnSellerStatesBidFragment
  & GetIsBuyerForBidBidFragment
  & GetIsBrokerForBidBidFragment
  & GetIsCounterpartyForBidBidFragment
);

export type AcceptedBidDetailsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counterpartyId'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'signingProcedure' | 'state' | 'rofr'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
  )> }
  & TransactionStatesBidFragment
);

export type BidPageAlternateSignerDetailsTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id'>
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'type'>
    & { invitedAlternateSigners: Array<(
      { __typename?: 'AlternateSigner' }
      & Pick<AlternateSigner, 'id'>
      & BidPageAlternateSignerDetailsAlternateSignerFragment
    )> }
    & BidPageAlternateSignerDetailsDocumentFragment
  )> }
  & UseDocumentSignersTransactionFragment
);

export type BidPageAlternateSignerDetailsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & BidPageAlternateSignerDetailsTransactionFragment
  )> }
);

export type BidPageAlternateSignerDetailsDocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'type'>
  & { signers: Array<(
    { __typename?: 'DocumentSigner' }
    & Pick<DocumentSigner, 'id'>
  )> }
);

export type BidPageAlternateSignerDetailsAlternateSignerFragment = (
  { __typename?: 'AlternateSigner' }
  & Pick<AlternateSigner, 'id' | 'name' | 'email'>
  & { sender: (
    { __typename?: 'VeryBasicUser' }
    & Pick<VeryBasicUser, 'id' | 'name'>
  ) }
);

export type BidActionsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'transferMethod'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & ModifyBidModalCompanyFragment
    ) }
    & ModifyBidModalListingFragment
  ) }
  & GetAvailableBidActionsBidFragment
  & AcceptCounterBidModalBidFragment
  & AcceptBidModalBidFragment
  & CounterBidModalBidFragment
  & ModifyBidModalBidFragment
);

export type BidBuyerActionsTileBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'brokerId'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & ModifyBidModalCompanyFragment
    ) }
    & ModifyBidModalListingFragment
  ) }
  & GetAvailableBidActionsBidFragment
  & GetIsBuyerForBidBidFragment
  & ModifyBidModalBidFragment
  & WithdrawBidModalBidFragment
);

export type BidFeeBreakdownBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'pricePerShare'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state'>
    & { feeDiscountApplications?: Maybe<Array<(
      { __typename?: 'FeeDiscountApplication' }
      & Pick<FeeDiscountApplication, 'id'>
      & BidFeeDiscountBreakdownFeeDiscountApplicationFragment
    )>> }
  )>, commission?: Maybe<(
    { __typename?: 'Commission' }
    & BidPageFeeBreakdownCommissionFragment
  )> }
  & GetBidNumSharesActualBidFragment
);

export type BidFeeDiscountBreakdownFeeDiscountApplicationFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id' | 'initial' | 'state' | 'transactionModificationId'>
  & { feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & FeeDiscountFragment
  ), feeDiscountApplicationCommission?: Maybe<(
    { __typename?: 'FeeDiscountApplicationCommission' }
    & Pick<FeeDiscountApplicationCommission, 'id' | 'amount'>
  )> }
);

export type BidInfoCardBidShareDetailsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'numShares'>
  & GetBidNumSharesActualBidFragment
  & GetNumOfSharesBidFragment
  & GetPricePerShareBidFragment
  & GetIsBuyerForBidBidFragment
);

export type BidInfoCardCounterBidShareDetailsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counterNumShares' | 'counterPricePerShare' | 'numShares'>
  & GetIsBuyerForBidBidFragment
  & GetBidNumSharesActualBidFragment
  & GetNumOfSharesBidFragment
  & GetPricePerShareBidFragment
);

export type BidInfoCardStatusTileBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'counteredAt' | 'placedAt' | 'expireAt' | 'insertedAt' | 'brokerId'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'isHiiveSpv'>
  ), broker?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & BidInfoCardStatusTileBrokerFragment
  )> }
  & GetIsBuyerForBidBidFragment
  & BidStatusBidFragment
);

export type BidInfoCardStatusTileBrokerFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
);

export type BidStatusBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'state' | 'standingBidId' | 'buyerEmail' | 'brokerId'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ), transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'documentTypeV2' | 'rofr' | 'state' | 'signingProcedure'>
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'isHiiveSpv'>
    & BidStatusListingFragment
  ) }
);

export type BidStatusListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & GetSecuritySpecialistContactCompanyFragment
  ) }
);

export type BrokerSellerFeeBreakdownFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'pricePerShare'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state'>
  )>, commission?: Maybe<(
    { __typename?: 'Commission' }
    & BidPageFeeBreakdownCommissionFragment
  )> }
  & GetBidNumSharesActualBidFragment
);

export type BrokerSellerTransactionFeeBreakdownTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'numShares' | 'pricePerShare'>
  & { commission?: Maybe<(
    { __typename?: 'Commission' }
    & TransactionFeeBreakdownCommissionFragment
  )>, feeDiscountApplications?: Maybe<Array<(
    { __typename?: 'FeeDiscountApplication' }
    & BrokerSellerTransactionFeeBreakdownTransactionFeeDiscountApplicationsFragment
  )>> }
);

export type BrokerSellerTransactionFeeBreakdownTransactionFeeDiscountApplicationsFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id' | 'state' | 'initial'>
  & { feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & FeeDiscountFragment
  ), feeDiscountApplicationCommission?: Maybe<(
    { __typename?: 'FeeDiscountApplicationCommission' }
    & Pick<FeeDiscountApplicationCommission, 'id' | 'amount'>
  )> }
);

export type CounterBidFeeBreakdownCommissionInfoQueryVariables = Exact<{
  totalShares: Scalars['Int'];
  pricePerShare: Scalars['Int'];
}>;


export type CounterBidFeeBreakdownCommissionInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { commissionInfo: (
    { __typename?: 'CommissionInfo' }
    & CounterBidFeeBreakdownCommissionInfoFragment
  ) }
);

export type CounterBidFeeBreakdownBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counterNumShares' | 'counterPricePerShare' | 'numSharesActual' | 'pricePerShare'>
  & { commission?: Maybe<(
    { __typename?: 'Commission' }
    & CounterBidFeeBreakdownCommissionFragment
  )>, counterBid?: Maybe<(
    { __typename?: 'CounterBid' }
    & Pick<CounterBid, 'numShares' | 'pricePerShare'>
    & { commission?: Maybe<(
      { __typename?: 'Commission' }
      & CounterBidFeeBreakdownCommissionFragment
    )>, feeDiscountApplications?: Maybe<Array<(
      { __typename?: 'FeeDiscountApplication' }
      & FeeDiscountApplicationTypeFragment
    )>> }
  )>, transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state'>
  )> }
);

export type CounterBidFeeBreakdownCommissionInfoFragment = (
  { __typename?: 'CommissionInfo' }
  & Pick<CommissionInfo, 'totalValue' | 'flatFeeAmount' | 'commissionAmount' | 'netValue' | 'netFees'>
);

export type CounterBidFeeBreakdownCommissionFragment = (
  { __typename?: 'Commission' }
  & Pick<Commission, 'flatFeeAmount' | 'commissionAmount' | 'feeDiscountAmount' | 'netFees'>
);

export type BidPageDocumentActionsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'companyId'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'type'>
      & RevokeAlternateSignerInvitationModalDocumentFragment
    )> }
    & UseDocumentSignersTransactionFragment
  )> }
  & BidPageNotSignerForInstitutionBidFragment
  & BidPageAlternateSignerDetailsBidFragment
  & InviteAlternateSignerModalBidFragment
);

export type BidPageFeeBreakdownCommissionFragment = (
  { __typename?: 'Commission' }
  & Pick<Commission, 'flatFeeAmount' | 'commissionAmount' | 'feeDiscountAmount' | 'netFees'>
);

export type BidPageNotSignerForInstitutionBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'type'>
    )> }
  )> }
);

export type TltAlternateSignerByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type TltAlternateSignerByTokenQuery = (
  { __typename?: 'RootQueryType' }
  & { tltAlternateSignerByToken?: Maybe<(
    { __typename?: 'TltAlternateSigner' }
    & TltAlternateSignerPageAlternateSignerFragment
  )> }
);

export type TltAlternateSignerPageAlternateSignerFragment = (
  { __typename?: 'TltAlternateSigner' }
  & Pick<TltAlternateSigner, 'id' | 'firstName' | 'lastName' | 'documentId' | 'institutionId'>
  & { sender: (
    { __typename?: 'TltAlternateSignerSender' }
    & Pick<TltAlternateSignerSender, 'id' | 'name' | 'investorType' | 'email'>
  ), delegateUser?: Maybe<(
    { __typename?: 'TltAlternateSignerDelegateUser' }
    & Pick<TltAlternateSignerDelegateUser, 'name'>
  )>, document: (
    { __typename?: 'TltAlternateSignerDocument' }
    & Pick<TltAlternateSignerDocument, 'id' | 'type'>
    & { signers: Array<(
      { __typename?: 'TltAlternateSignerDocumentSigner' }
      & Pick<TltAlternateSignerDocumentSigner, 'userId' | 'institutionId' | 'alternateSignerId'>
    )> }
  ), transaction: (
    { __typename?: 'TltAlternateSignerTransaction' }
    & TltAlternateSignerPageTransactionFragment
  ) }
);

export type TltAlternateSignerPageTransactionFragment = (
  { __typename?: 'TltAlternateSignerTransaction' }
  & Pick<TltAlternateSignerTransaction, 'id' | 'transferMethod' | 'numShares' | 'pricePerShare'>
  & { pendingModification?: Maybe<(
    { __typename?: 'TransactionModification' }
    & Pick<TransactionModification, 'id' | 'pricePerShare' | 'numShares'>
  )>, bid: (
    { __typename?: 'TltAlternateSignerBid' }
    & Pick<TltAlternateSignerBid, 'id' | 'displayId' | 'state' | 'numShares' | 'pricePerShare'>
    & { listing: (
      { __typename?: 'TltAlternateSignerListing' }
      & Pick<TltAlternateSignerListing, 'id' | 'shareTypeV2'>
      & { shareSeriesMakeup: Array<(
        { __typename?: 'ShareSeriesMakeup' }
        & Pick<ShareSeriesMakeup, 'id' | 'shareSeries'>
      )> }
    ), company: (
      { __typename?: 'TltAlternateSignerCompany' }
      & Pick<TltAlternateSignerCompany, 'id' | 'name' | 'logoUrl'>
    ) }
  ) }
);

export type TransactionFeeBreakdownTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'numShares' | 'pricePerShare' | 'state'>
  & { commission?: Maybe<(
    { __typename?: 'Commission' }
    & TransactionFeeBreakdownCommissionFragment
  )>, feeDiscountApplications?: Maybe<Array<(
    { __typename?: 'FeeDiscountApplication' }
    & Pick<FeeDiscountApplication, 'id'>
    & BidFeeDiscountBreakdownFeeDiscountApplicationFragment
  )>> }
);

export type TransactionFeeBreakdownCommissionFragment = (
  { __typename?: 'Commission' }
  & Pick<Commission, 'flatFeeAmount' | 'commissionAmount' | 'feeDiscountAmount' | 'netFees'>
);

export type TransactionInfoCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
    & { history: Array<(
      { __typename?: 'TransactionHistory' }
      & BidInfoCardBidTransactionHistoryFragment
    )>, feeDiscountApplications?: Maybe<Array<(
      { __typename?: 'FeeDiscountApplication' }
      & Pick<FeeDiscountApplication, 'id'>
      & BidFeeDiscountBreakdownFeeDiscountApplicationFragment
    )>> }
  )> }
  & GetIsBuyerForBidBidFragment
  & BuyerTransactionInfoCardBidFragment
  & BrokerTransactionInfoCardBidFragment
  & SellerTransactionInfoCardBidFragment
);

export type ListingPageBrokerCounterpartyActivitySubmitBidCardSubmitBidListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptPartialBid'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'minPostPps' | 'maxPostPps'>
  ) }
  & GetNumSharesAvailableRoundedListingFragment
);

export type SubmitBidCardBrokerSubmitBidMutationVariables = Exact<{
  input: BrokerSubmitBidInput;
}>;


export type SubmitBidCardBrokerSubmitBidMutation = (
  { __typename?: 'RootMutationType' }
  & { brokerSubmitBid: (
    { __typename?: 'BrokerSubmitBidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
      & PlaceBidSuccessModalBidFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type ListingPageBrokerCounterpartyActivitySubmitBidCardListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'listingPricePerShare' | 'sellerId'>
  & GetNumSharesAvailableRoundedListingFragment
  & ListingPageBrokerCounterpartyActivitySubmitBidCardSubmitBidListingFragment
);

export type ListingPageBrokerCounterpartyActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'myPermissionsV2' | 'solicited' | 'isHiiveSpv'>
  & { bids: Array<(
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'state'>
    & ListingPageBrokerCounterpartyActivityBidFragment
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
    & GetSecuritySpecialistContactCompanyFragment
    & ListingPageBrokerCounterpartyActivityPlaceStandingBidCtaCardCompanyFragment
  ) }
  & ListingPageBrokerCounterpartyActivitySubmitBidCardListingFragment
);

export type ListingPageBrokerCounterpartyActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & ListingPageBidCardBidFragment
);

export type ListingPageBrokerCounterpartyActivityPlaceStandingBidCtaCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & PlaceStandingBidModalCompanyFragment
);

export type ListingPageBrokerListerActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'state' | 'sellerEmail'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ), marketAnalyst?: Maybe<(
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      )> }
    )> }
  ), bids: Array<(
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'state'>
    & FilterPastBidsBidFragment
    & FilterOpenBidsBidFragment
    & ListingPageBidCardBidFragment
  )> }
);

export type PlaceBidCardPlaceBidMutationVariables = Exact<{
  input: PlaceBidInput;
}>;


export type PlaceBidCardPlaceBidMutation = (
  { __typename?: 'RootMutationType' }
  & { placeBid: (
    { __typename?: 'BidMutationResponse' }
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
      & PlaceBidSuccessModalBidFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type ListingPageBuyerActivityPlaceBidCardListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'listingPricePerShare'>
  & GetNumSharesAvailableRoundedListingFragment
  & ListingPageBuyerActivityPlaceBidCardPlaceBidListingFragment
);

export type ListingPageBuyerActivityPlaceBidCardPlaceBidListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'displayId' | 'acceptPartialBid'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'minPostPps' | 'maxPostPps'>
  ) }
  & GetNumSharesAvailableRoundedListingFragment
);

export type ListingPageBuyerActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'myPermissionsV2'>
  & { bids: Array<(
    { __typename?: 'Bid' }
    & ListingPageBuyerActivityBidFragment
  )>, company: (
    { __typename?: 'Company' }
    & ListingPageBuyerActivityListingCompanyFragment
  ) }
  & ListingPageBuyerActivityPlaceBidCardListingFragment
  & MessageCounterpartyCardListingFragment
);

export type ListingPageBuyerActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
  )> }
  & ListingPageBidCardBidFragment
);

export type ListingPageBuyerActivityListingCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'requiresApprovedBuyers'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type ListingPageBuyerActivityTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id'>
  & { bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'displayId' | 'pricePerShare'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
      & ActivityCardCompanyFragment
    ) }
    & GetBidNumSharesActualBidFragment
    & ActivityCardBidFragment
  ) }
  & ActivityCardTransactionFragment
  & MakeUrlTransactionFragment
);

export type ListingPageBuyerActivityQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type ListingPageBuyerActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { buyerListingActivity: (
    { __typename?: 'BuyerListingActivity' }
    & { pendingPurchases: Array<(
      { __typename?: 'Transaction' }
      & ListingPageBuyerActivityTransactionFragment
    )> }
  ) }
);

export type ListingPageSellerActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'companyId'>
  & { bids: Array<(
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'rejectionReason' | 'state'>
    & GetIsBidDiscussionBidFragment
    & ListingPageSellerActivityBidFragment
    & FilterOpenBidsBidFragment
    & FilterPastBidsBidFragment
  )> }
);

export type ListingPageSellerActivityBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & ListingPageBidCardBidFragment
);

export type ListingPageListingActionsTileListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & GetAvailableListingActionsListingFragment
  & GetIsSellerForListingListingFragment
  & ModifyListingModalListingFragment
  & WithdrawListingModalListingFragment
);

export type ListingPageListingActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & GetIsBrokerForListingListingFragment
  & GetIsEligibleBuyerForListingListingFragment
  & GetIsSellerForListingListingFragment
  & ListingPageSellerActivityListingFragment
  & ListingPageBuyerActivityListingFragment
  & ListingPageBrokerCounterpartyActivityListingFragment
  & ListingPageBrokerListerActivityListingFragment
);

export type ListingPageListingHiiveUserTileListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'solicited' | 'shortId' | 'brokerId' | 'expireAt' | 'placedAt' | 'insertedAt' | 'state' | 'transferMethod'>
  & { broker?: Maybe<(
    { __typename?: 'User' }
    & ListingPageListingHiiveUserTileBrokerFragment
  )> }
);

export type ListingPageListingHiiveUserTileBrokerFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
);

export type ListingPageListingInfoCardListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'numActiveBids' | 'numCounteredBids' | 'version' | 'expireAt' | 'acceptedBidPrices' | 'displayId' | 'listingPricePerShare' | 'shareTypeV2' | 'transferMethod' | 'solicited' | 'otherDetails' | 'fromHiive' | 'isHiiveSpv' | 'placedAt' | 'insertedAt'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { securitySpecialist?: Maybe<(
      { __typename?: 'SecuritySpecialist' }
      & Pick<SecuritySpecialist, 'id'>
      & { user: (
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      ), marketAnalyst?: Maybe<(
        { __typename?: 'SecuritySpecialistUser' }
        & Pick<SecuritySpecialistUser, 'id' | 'email'>
      )> }
    )> }
    & FocusedShareDetailsHeaderCompanyFragment
  ), commission?: Maybe<(
    { __typename?: 'VirtualCommission' }
    & ListingFeeBreakdownCommissionFragment
  )>, feeDiscountApplications?: Maybe<Array<(
    { __typename?: 'FeeDiscountApplication' }
    & Pick<FeeDiscountApplication, 'id'>
    & ListingFeeDiscountApplicationFragment
  )>> }
  & GetAvailableListingActionsListingFragment
  & GetIsSellerForListingListingFragment
  & GetShowListingSolicitedStateListingFragment
  & NumShareAndPriceDetailsListingFragment
  & AcceptedBidSharePriceDetailsListingFragment
  & ListingPageListingActionsTileListingFragment
  & ListingPageListingStatusTileListingFragment
  & ListingPageListingHiiveUserTileListingFragment
  & GetNumOfSharesListingFragment
  & ListingPageListingPartialBidDetailsListingFragment
  & GetIsBrokerForListingListingFragment
);

export type ListingPageListingInfoCardDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
);

export type ListingFeeBreakdownCommissionFragment = (
  { __typename?: 'VirtualCommission' }
  & Pick<VirtualCommission, 'commissionAmount' | 'feeDiscountAmount' | 'flatFeeAmount' | 'netFees'>
);

export type ListingFeeDiscountApplicationFragment = (
  { __typename?: 'FeeDiscountApplication' }
  & Pick<FeeDiscountApplication, 'id' | 'initial' | 'state' | 'transactionModificationId'>
  & { feeDiscount: (
    { __typename?: 'FeeDiscount' }
    & FeeDiscountFragment
  ), feeDiscountApplicationCommission?: Maybe<(
    { __typename?: 'FeeDiscountApplicationCommission' }
    & Pick<FeeDiscountApplicationCommission, 'amount' | 'id'>
  )> }
);

export type ListingPageListingByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ListingPageListingByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { listingById?: Maybe<(
    { __typename?: 'Listing' }
    & ListingPageListingFragment
  )> }
);

export type ListingPageListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & ListingPageContentListingFragment
);

export type ListingPageBidCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'displayId' | 'expireAt' | 'companyId' | 'pricePerShare' | 'state' | 'withdrawnAt'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'numUnreadMessages' | 'totalNumMessages'>
  )> }
  & GetBidNumSharesActualBidFragment
  & BrokerBidActivityCardTimestampsBidFragment
);

export type ListingPageContentListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & ListingPageListingActivityListingFragment
  & ListingPageListingInfoCardListingFragment
  & GetShowListingActivityListingFragment
);

export type ListingPageListingPartialBidDetailsListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptPartialBid' | 'minPartialBidNumShares'>
  & GetIsSellerForListingListingFragment
  & GetIsListerListingFragment
);

export type ListingPageListingStatusTileListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'transferMethod' | 'state' | 'expireAt'>
);

export type PostingDetailsStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'shortId' | 'numSharesAvailable' | 'state' | 'version' | 'pricePerShare' | 'buyerEmail'>
  & MakeUrlStandingBidFragment
);

export type PostingDetailsListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'shortId' | 'listingPricePerShare' | 'numSharesAvailable' | 'numSharesOriginal' | 'numSharesAvailableRounded' | 'numSharesOriginalRounded' | 'actualNumSharesVisible' | 'state' | 'transferMethod' | 'sellerEmail' | 'sellerId'>
  & MakeUrlListingFragment
);

export type StandingBidBrokerActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'state' | 'buyerEmail'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ) }
  & StandingBidTransactionsStandingBidFragment
);

export type StandingBidBrokerCounterpartyActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'buyerEmail'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & BrokerSubmitListingSequenceModalCompanyFragment
  ) }
);

export type StandingBidBuyerActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & StandingBidTransactionsStandingBidFragment
  & StandingBidBuyerDiscussionsStandingBidFragment
);

export type StandingBidBuyerDiscussionsStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
);

export type AcceptStandingBidCtaCardStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment
  & AcceptStandingBidModalStandingBidFragment
);

export type StandingBidSellerActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & GetAvailableStandingBidActionsStandingBidFragment
  & StandingBidSellerMessagingStandingBidFragment
  & AcceptStandingBidCtaCardStandingBidFragment
  & StandingBidTransactionsStandingBidFragment
);

export type StandingBidSellerMessagingStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'hasRequestedDiscussion'>
  & GetAvailableStandingBidActionsStandingBidFragment
  & MessageCounterpartyCardStandingBidFragment
);

export type StandingBidActionsTileStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & GetAvailableStandingBidActionsStandingBidFragment
  & GetIsBuyerForStandingBidStandingBidFragment
  & ModifyStandingBidModalStandingBidFragment
  & WithdrawStandingBidModalStandingBidFragment
);

export type StandingBidActivityStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & StandingBidBrokerActivityStandingBidFragment
  & StandingBidBuyerActivityStandingBidFragment
  & StandingBidSellerActivityStandingBidFragment
  & StandingBidBrokerCounterpartyActivityStandingBidFragment
  & GetIsBrokerForStandingBidStandingBidFragment
  & GetIsBrokerCounterpartyForStandingBidStandingBidFragment
  & GetIsBuyerForStandingBidStandingBidFragment
);

export type StandingBidInfoStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & StandingBidInfoCardStandingBidFragment
);

export type StandingBidInfoDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & StandingBidInfoCardDiscussionFragment
);

export type StandingBidInfoCardStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'placedAt' | 'insertedAt' | 'expireAt' | 'state' | 'pricePerShare' | 'acceptedBidPrices' | 'displayId' | 'shortId' | 'numSharesAccepted' | 'numSharesOriginal' | 'numSharesAvailable' | 'brokerId' | 'allowPartialAccept' | 'minPartialAcceptNumShares' | 'acceptsShares' | 'acceptsTransferMethods' | 'otherDetails' | 'fromHiive'>
  & { broker?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & StandingBidInfoCardBrokerFragment
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & FocusedShareDetailsHeaderCompanyFragment
  ), bids: Array<(
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'state'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
      & { document?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'type'>
      )> }
    )> }
  )> }
  & GetAvailableStandingBidActionsStandingBidFragment
  & GetIsBuyerForStandingBidStandingBidFragment
  & GetStandingBidLotStandingBidFragment
  & GetNumOfSharesStandingBidFragment
  & GetIsPartiallyAcceptedStandingBidStandingBidFragment
  & GetIsConditionallyCompletedStandingBidStandingBidFragment
  & StandingBidActionsTileStandingBidFragment
  & GetIsSolicitedStandingBidStandingBidFragment
  & ActivityCardStandingBidSolicitedCardBodyStandingBidFragment
  & GetIsBrokerCounterpartyForStandingBidStandingBidFragment
  & GetIsBrokerForStandingBidStandingBidFragment
);

export type StandingBidInfoCardBrokerFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
);

export type StandingBidInfoCardDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
);

export type StandingBidPageStandingBidByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StandingBidPageStandingBidByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { standingBidById?: Maybe<(
    { __typename?: 'StandingBid' }
    & StandingBidPageStandingBidFragment
  )> }
);

export type StandingBidPageStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & StandingBidPageContentStandingBidFragment
);

export type StandingBidPageContentStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & StandingBidInfoStandingBidFragment
  & StandingBidActivityStandingBidFragment
);

export type StandingBidTransactionCardBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'numShares' | 'pricePerShare' | 'displayId'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'numShares' | 'pricePerShare'>
    & ActivityCardTransactionFragment
  )> }
  & GetBidNumSharesActualBidFragment
  & ActivityCardBidFragment
);

export type StandingBidTransactionCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & ActivityCardCompanyFragment
);

export type StandingBidTransactionsStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
  & { bids: Array<(
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )> }
    & StandingBidTransactionCardBidFragment
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & StandingBidTransactionCardCompanyFragment
  ) }
  & GetIsBuyerForStandingBidStandingBidFragment
);

export type SuitabilityQuestionGroupsQueryVariables = Exact<{
  countryId: Scalars['ID'];
  investorStatus: InvestorStatus;
}>;


export type SuitabilityQuestionGroupsQuery = (
  { __typename?: 'RootQueryType' }
  & { currentSuitabilityQuestionGroup?: Maybe<(
    { __typename?: 'SuitabilityQuestionGroup' }
    & Pick<SuitabilityQuestionGroup, 'country' | 'id' | 'investorStatus' | 'version'>
    & { suitabilityQuestions: Array<Maybe<(
      { __typename?: 'SuitabilityQuestion' }
      & Pick<SuitabilityQuestion, 'id' | 'description' | 'order' | 'text' | 'caption'>
      & { renderOptions?: Maybe<(
        { __typename?: 'RenderOptions' }
        & Pick<RenderOptions, 'columns'>
      )>, suitabilityOptions: Array<Maybe<(
        { __typename?: 'SuitabilityOption' }
        & Pick<SuitabilityOption, 'custom' | 'description' | 'id' | 'nextQuestionIds' | 'order' | 'text' | 'placeholder'>
      )>> }
    )>> }
  )> }
);

export type GetCurrentSuitabilityAnswersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentSuitabilityAnswersQuery = (
  { __typename?: 'RootQueryType' }
  & { suitabilityAnswers: Array<(
    { __typename?: 'SuitabilityAnswer' }
    & Pick<SuitabilityAnswer, 'id' | 'text'>
    & { suitabilityOption: (
      { __typename?: 'SuitabilityOption' }
      & Pick<SuitabilityOption, 'id' | 'custom'>
      & { suitabilityQuestion: (
        { __typename?: 'SuitabilityQuestion' }
        & Pick<SuitabilityQuestion, 'id' | 'text'>
        & { suitabilityQuestionGroup: (
          { __typename?: 'SuitabilityQuestionGroup' }
          & Pick<SuitabilityQuestionGroup, 'id' | 'version'>
        ) }
      ) }
    ) }
  )> }
);

export type AnswerSuitabilityQuestionsMutationVariables = Exact<{
  answers: Array<SuitabilityAnswerInput> | SuitabilityAnswerInput;
}>;


export type AnswerSuitabilityQuestionsMutation = (
  { __typename?: 'RootMutationType' }
  & { answerSuitability?: Maybe<(
    { __typename?: 'AnswerSuitabilityResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message' | 'type'>
    )>>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type TransactionExecutionPageTransactionByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TransactionExecutionPageTransactionByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { transactionById?: Maybe<(
    { __typename?: 'Transaction' }
    & TransactionExecutionPageTransactionFragment
  )> }
);

export type TransactionExecutionPageOnExecutionWorkflowUpdatedSubscriptionVariables = Exact<{
  workflowId: Scalars['ID'];
}>;


export type TransactionExecutionPageOnExecutionWorkflowUpdatedSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { onExecutionWorkflowUpdated: (
    { __typename?: 'ExecutionWorkflow' }
    & TransactionExecutionPageWorkflowFragment
  ) }
);

export type TransactionExecutionPageTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'numShares' | 'pricePerShare' | 'grossProceeds' | 'buyerId' | 'sellerId' | 'buyerBrokerId' | 'sellerBrokerId' | 'buyerInstitutionId' | 'sellerInstitutionId'>
  & { buyer: (
    { __typename?: 'VeryBasicUser' }
    & Pick<VeryBasicUser, 'id' | 'name'>
  ), seller: (
    { __typename?: 'VeryBasicUser' }
    & Pick<VeryBasicUser, 'id' | 'name'>
  ), buyerBroker?: Maybe<(
    { __typename?: 'VeryBasicUser' }
    & Pick<VeryBasicUser, 'id' | 'name'>
  )>, sellerBroker?: Maybe<(
    { __typename?: 'VeryBasicUser' }
    & Pick<VeryBasicUser, 'id' | 'name'>
  )>, representedBuyer?: Maybe<(
    { __typename?: 'RepresentedUser' }
    & Pick<RepresentedUser, 'id' | 'firstName' | 'lastName'>
  )>, representedSeller?: Maybe<(
    { __typename?: 'RepresentedUser' }
    & Pick<RepresentedUser, 'id' | 'firstName' | 'lastName'>
  )>, buyerInstitution?: Maybe<(
    { __typename?: 'VeryBasicInstitution' }
    & Pick<VeryBasicInstitution, 'id' | 'legalName'>
  )>, sellerInstitution?: Maybe<(
    { __typename?: 'VeryBasicInstitution' }
    & Pick<VeryBasicInstitution, 'id' | 'legalName'>
  )>, commission?: Maybe<(
    { __typename?: 'Commission' }
    & Pick<Commission, 'commissionAmount' | 'flatFeeAmount' | 'netFees'>
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'logoUrl' | 'name'>
  ), bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'listingId' | 'displayId'>
    & { discussion?: Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
      & DiscussionThreadCardDiscussionFragment
    )> }
  ), workflow?: Maybe<(
    { __typename?: 'ExecutionWorkflow' }
    & TransactionExecutionPageWorkflowFragment
  )> }
);

export type TransactionExecutionPageWorkflowFragment = (
  { __typename?: 'ExecutionWorkflow' }
  & Pick<ExecutionWorkflow, 'id' | 'status' | 'structure' | 'milestoneOrder'>
  & { conditions: Array<(
    { __typename?: 'ExecutionCondition' }
    & TransactionExecutionPageWorkflowConditionFragment
  )>, milestoneCompletions: Array<(
    { __typename?: 'ExecutionMilestoneCompletion' }
    & TransactionExecutionPageWorkflowMilestoneFragment
  )>, steps: Array<(
    { __typename?: 'ExecutionStep' }
    & TransactionExecutionPageWorkflowStepFragment
  )> }
);

export type TransactionExecutionPageWorkflowStepFragment = (
  { __typename?: 'ExecutionStep' }
  & Pick<ExecutionStep, 'id' | 'name' | 'status' | 'type' | 'visibility'>
  & { tasks: Array<(
    { __typename?: 'ExecutionTask' }
    & TransactionExecutionPageTaskFragment
  )>, instructions: (
    { __typename?: 'ExecutionStepInstruction' }
    & Pick<ExecutionStepInstruction, 'buyer' | 'seller'>
  ) }
);

export type TransactionExecutionPageTaskFragment = (
  { __typename?: 'ExecutionTask' }
  & Pick<ExecutionTask, 'id' | 'type' | 'status' | 'completedAt'>
  & { data?: Maybe<(
    { __typename?: 'ExecutionAnvilFillDocumentTaskData' }
    & Pick<ExecutionAnvilFillDocumentTaskData, 'embedUrl'>
  ) | (
    { __typename?: 'ExecutionAnvilSignDocumentTaskData' }
    & Pick<ExecutionAnvilSignDocumentTaskData, 'embedUrl' | 'anvilEtchSignUrl'>
    & { signers: Array<(
      { __typename?: 'ExecutionAnvilSignDocumentTaskDataSigner' }
      & Pick<ExecutionAnvilSignDocumentTaskDataSigner, 'signedAt' | 'actorType' | 'taskId'>
    )> }
  )> }
);

export type TransactionExecutionPageWorkflowMilestoneFragment = (
  { __typename?: 'ExecutionMilestoneCompletion' }
  & Pick<ExecutionMilestoneCompletion, 'id' | 'milestone' | 'completedAt'>
);

export type TransactionExecutionPageWorkflowConditionFragment = (
  { __typename?: 'ExecutionCondition' }
  & Pick<ExecutionCondition, 'id' | 'condition'>
);

export type TransactionPageTransactionByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TransactionPageTransactionByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { transactionById?: Maybe<(
    { __typename?: 'Transaction' }
    & TransactionPageTransactionFragment
  )> }
);

export type TransactionPageTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id'>
  & { bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
    & BidPageContentBidFragment
  ) }
);

export type AddHoldingsV2MutationVariables = Exact<{
  addHoldingsInput: AddHoldingsV2Input;
}>;


export type AddHoldingsV2Mutation = (
  { __typename?: 'RootMutationType' }
  & { addHoldingsV2: (
    { __typename?: 'AddHoldingsV2Response' }
    & { holdingsV2?: Maybe<Array<(
      { __typename?: 'Holding' }
      & Pick<Holding, 'companyId' | 'id' | 'numSharesV2' | 'sellerId'>
    ) | (
      { __typename?: 'UnlistedHolding' }
      & Pick<UnlistedHolding, 'companyName' | 'id' | 'numSharesV2' | 'sellerId'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type HoldingSelectCompanyListQueryVariables = Exact<{
  orderBy: ListCompaniesOrderBy;
  first: Scalars['Int'];
  searchText?: Maybe<Scalars['String']>;
}>;


export type HoldingSelectCompanyListQuery = (
  { __typename?: 'RootQueryType' }
  & { listCompanies?: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyEdge' }
      & HoldingSelectCompanyEdgeFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type HoldingSelectCompanyEdgeFragment = (
  { __typename?: 'CompanyEdge' }
  & Pick<CompanyEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'Company' }
    & HoldingSelectCompanyFragment
  )> }
);

export type HoldingSelectCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
);

export type UnaccreditedSellerActiveBidNotificationsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerActiveBidNotificationsCountQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyListingActivity: (
    { __typename?: 'UnaccreditedSellerMyListingActivity' }
    & { activeBids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
      & { discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
      )> }
    )> }
  ) }
);

export type UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { activity: (
      { __typename?: 'CompanyActivity' }
      & { myListings: Array<(
        { __typename?: 'Listing' }
        & Pick<Listing, 'id'>
        & { bids: Array<(
          { __typename?: 'Bid' }
          & Pick<Bid, 'id' | 'state'>
          & { discussion?: Maybe<(
            { __typename?: 'Discussion' }
            & Pick<Discussion, 'id'>
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type UnaccreditedSellerInquiryNotificationsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerInquiryNotificationsCountQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyListingActivity: (
    { __typename?: 'UnaccreditedSellerMyListingActivity' }
    & { preBidDiscussions: Array<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
    )> }
  ) }
);

export type UnaccreditedSellerByCompanyIdInquiryNotificationsCountQueryVariables = Exact<{
  topicFilter?: Maybe<MessageTopic>;
}>;


export type UnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery = (
  { __typename?: 'RootQueryType' }
  & { myDiscussions: Array<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
  )> }
);

export type UnaccreditedSellerPastBidNotificationsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerPastBidNotificationsCountQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyListingActivity: (
    { __typename?: 'UnaccreditedSellerMyListingActivity' }
    & { pastBids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id'>
    )> }
  ) }
);

export type UnaccreditedSellerByCompanyIdPastBidNotificationsCountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { activity: (
      { __typename?: 'CompanyActivity' }
      & { myListings: Array<(
        { __typename?: 'Listing' }
        & Pick<Listing, 'id'>
        & { bids: Array<(
          { __typename?: 'Bid' }
          & Pick<Bid, 'id' | 'state'>
        )> }
      )> }
    ) }
  ) }
);

export type UnaccreditedSellerStandingBidNotificationsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerStandingBidNotificationsCountQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyCompanyActivity: (
    { __typename?: 'UnaccreditedSellerMyCompanyActivity' }
    & { availableStandingBids: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
    )> }
  ) }
);

export type UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { activity: (
      { __typename?: 'CompanyActivity' }
      & { availableStandingBids: Array<(
        { __typename?: 'StandingBid' }
        & Pick<StandingBid, 'id'>
      )> }
    ) }
  ) }
);

export type UnaccreditedSellerTransactionNotificationsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UnaccreditedSellerTransactionNotificationsCountQuery = (
  { __typename?: 'RootQueryType' }
  & { unaccreditedSellerMyActivity: (
    { __typename?: 'UnaccreditedSellerActivity' }
    & { myListing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )>, pendingSales: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
      & { bid: (
        { __typename?: 'Bid' }
        & Pick<Bid, 'id'>
        & { discussion?: Maybe<(
          { __typename?: 'Discussion' }
          & Pick<Discussion, 'id'>
        )> }
      ) }
    )>, completedSales: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )> }
  ) }
);

export type UnaccreditedSellerByCompanyIdTransactionNotificationsCountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery = (
  { __typename?: 'RootQueryType' }
  & { companyById: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { activity: (
      { __typename?: 'CompanyActivity' }
      & { myCompletedTransactions: Array<(
        { __typename?: 'Transaction' }
        & Pick<Transaction, 'id'>
      )>, myOpenTransactions: Array<(
        { __typename?: 'Transaction' }
        & Pick<Transaction, 'id'>
        & { bid: (
          { __typename?: 'Bid' }
          & Pick<Bid, 'id'>
          & { discussion?: Maybe<(
            { __typename?: 'Discussion' }
            & Pick<Discussion, 'id'>
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type FullAccreditationAnswerFragment = (
  { __typename?: 'AccreditationAnswer' }
  & Pick<AccreditationAnswer, 'text'>
  & { question: (
    { __typename?: 'AccreditationQuestion' }
    & FullAccreditationQuestionFragment
  ) }
);

export type FullAccreditationQuestionFragment = (
  { __typename?: 'AccreditationQuestion' }
  & Pick<AccreditationQuestion, 'id' | 'order' | 'text' | 'key'>
);

export type BasicAlternateSignerFragment = (
  { __typename?: 'AlternateSigner' }
  & Pick<AlternateSigner, 'id' | 'name' | 'email' | 'senderId' | 'insertedAt'>
  & { sender: (
    { __typename?: 'VeryBasicUser' }
    & VeryBasicUserFragment
  ) }
);

export type CompanyLastRoundDetailsFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name' | 'lastRoundSeries' | 'lastRoundDate' | 'lastRoundPricePerShare' | 'lastRoundRaised' | 'totalRaised' | 'lastRoundPostValuation' | 'myPermissionsV2' | 'tradeable' | 'status' | 'insertedAt' | 'updatedAt'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )>, investors: Array<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'id' | 'name'>
  )>, industries: Array<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'id' | 'name'>
  )>, currentPrices?: Maybe<(
    { __typename?: 'PriceGraph' }
    & Pick<PriceGraph, 'highestBid' | 'lowestAsk' | 'lastTransaction'>
  )> }
);

export type BasicCountryFragment = (
  { __typename?: 'Country' }
  & Pick<Country, 'id' | 'name' | 'fullName'>
);

export type FullCountryFragment = (
  { __typename?: 'Country' }
  & { questions: Array<(
    { __typename?: 'AccreditationQuestion' }
    & FullAccreditationQuestionFragment
  )> }
  & BasicCountryFragment
);

export type FeeDiscountFragment = (
  { __typename?: 'FeeDiscount' }
  & Pick<FeeDiscount, 'id' | 'name' | 'description' | 'type' | 'value'>
);

export type FullFileUploadFragment = (
  { __typename?: 'FileUpload' }
  & Pick<FileUpload, 'id' | 'uploaderId' | 'institutionId' | 'filename' | 'contentType' | 'url'>
);

export type EitherHoldingFragment = (
  { __typename?: 'EitherHolding' }
  & Pick<EitherHolding, 'id' | 'numShares' | 'sellerId' | 'companyName' | 'companyId'>
);

export type FullInputErrorFragment = (
  { __typename?: 'InputError' }
  & Pick<InputError, 'message' | 'type' | 'field'>
);

export type VeryBasicInstitutionFragment = (
  { __typename?: 'VeryBasicInstitution' }
  & Pick<VeryBasicInstitution, 'id' | 'legalName'>
);

export type FullInvestmentGoalOptionFragment = (
  { __typename?: 'InvestmentGoalOption' }
  & Pick<InvestmentGoalOption, 'id' | 'key' | 'text'>
  & { question: (
    { __typename?: 'InvestmentGoalQuestion' }
    & Pick<InvestmentGoalQuestion, 'id' | 'key' | 'text'>
  ) }
);

export type FullSessionFragment = (
  { __typename?: 'Session' }
  & Pick<Session, 'token' | 'refreshToken' | 'userId'>
);

export type VeryBasicUserFragment = (
  { __typename?: 'VeryBasicUser' }
  & Pick<VeryBasicUser, 'id' | 'name' | 'firstName' | 'lastName' | 'institutionId'>
);

export type BasicUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'firstName' | 'lastName' | 'email' | 'emailVerified' | 'roles' | 'institutionId' | 'isSuperadmin' | 'isActive' | 'identityVerified' | 'identityVerificationStatus' | 'onboardingComplete' | 'suitableInvestor' | 'accredited' | 'membershipAgreementSigned' | 'investorStatus' | 'investorType' | 'isHiiveUser' | 'jobTitle' | 'phoneNumber' | 'updatedAt' | 'insertedAt' | 'myPermissionsV2' | 'agreedToCustomerAgreement'>
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'name' | 'id'>
  )>, entities: Array<(
    { __typename?: 'Entity' }
    & BasicUserEntityFragment
  )> }
);

export type BasicUserEntityFragment = (
  { __typename?: 'Entity' }
  & Pick<Entity, 'active' | 'otherType' | 'id' | 'insertedAt' | 'legalName' | 'type'>
  & { jurisdictionOfFormation?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'fullName' | 'id' | 'name'>
  )> }
);

export type AcceptInvitationMutationVariables = Exact<{
  input: AcceptInvitationInput;
}>;


export type AcceptInvitationMutation = (
  { __typename?: 'RootMutationType' }
  & { acceptInvitation: (
    { __typename?: 'SessionMutationResponse' }
    & { session?: Maybe<(
      { __typename?: 'Session' }
      & FullSessionFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type AddToWatchlistMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type AddToWatchlistMutation = (
  { __typename?: 'RootMutationType' }
  & { addToWatchlist?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { watchlist: Array<(
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type AnswerAccreditationQuestionsNewMutationVariables = Exact<{
  input: AnswerAccreditationQuestionsInput;
}>;


export type AnswerAccreditationQuestionsNewMutation = (
  { __typename?: 'RootMutationType' }
  & { answerAccreditationQuestionsNew: (
    { __typename?: 'AnswerAccreditationResponse' }
    & { user?: Maybe<(
      { __typename?: 'VeryBasicUser' }
      & VeryBasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type CancelInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelInvitationMutation = (
  { __typename?: 'RootMutationType' }
  & { cancelInvitation: (
    { __typename?: 'InvitationMutationResponse' }
    & { invitation?: Maybe<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type ChangeInstitutionUserActiveStatusMutationVariables = Exact<{
  userId: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type ChangeInstitutionUserActiveStatusMutation = (
  { __typename?: 'RootMutationType' }
  & { changeUserActiveStatus?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & BasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type CreateFileUploadMutationVariables = Exact<{
  input: FileUploadInput;
}>;


export type CreateFileUploadMutation = (
  { __typename?: 'RootMutationType' }
  & { createFileUpload: (
    { __typename?: 'FileUploadMutationResponse' }
    & { fileUpload?: Maybe<(
      { __typename?: 'FileUpload' }
      & Pick<FileUpload, 'id' | 'filename' | 'url'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type CreateInstitutionMutationVariables = Exact<{
  input: CreateInstitutionInput;
}>;


export type CreateInstitutionMutation = (
  { __typename?: 'RootMutationType' }
  & { createInstitution: (
    { __typename?: 'InstitutionMutationResponse' }
    & { institution?: Maybe<(
      { __typename?: 'Institution' }
      & Pick<Institution, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type GenerateTransloaditParamsMutationVariables = Exact<{
  templateKind: TransloaditTemplateKind;
}>;


export type GenerateTransloaditParamsMutation = (
  { __typename?: 'RootMutationType' }
  & { generateTransloaditParams: (
    { __typename?: 'TransloaditParams' }
    & Pick<TransloaditParams, 'signature' | 'params'>
  ) }
);

export type InstitutionSuitabilityMutationVariables = Exact<{
  institutionId: Scalars['ID'];
  updateInstitutionInput: UpdateInstitutionInput;
  answers: Array<InvestmentGoalAnswerInput> | InvestmentGoalAnswerInput;
}>;


export type InstitutionSuitabilityMutation = (
  { __typename?: 'RootMutationType' }
  & { updateInstitution: (
    { __typename?: 'InstitutionMutationResponse' }
    & { institution?: Maybe<(
      { __typename?: 'Institution' }
      & Pick<Institution, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ), answerInvestmentGoals?: Maybe<(
    { __typename?: 'InvestmentGoalsResponse' }
    & { user?: Maybe<(
      { __typename?: 'VeryBasicUser' }
      & VeryBasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type AcceptDiscussionRequestMutationVariables = Exact<{
  discussionId: Scalars['ID'];
}>;


export type AcceptDiscussionRequestMutation = (
  { __typename?: 'RootMutationType' }
  & { acceptDiscussionRequest: (
    { __typename?: 'DiscussionMutationResponse' }
    & { discussion: (
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
    ), errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type MarkDiscussionAsReadMutationVariables = Exact<{
  discussionId: Scalars['ID'];
}>;


export type MarkDiscussionAsReadMutation = (
  { __typename?: 'RootMutationType' }
  & { markDiscussionAsRead: (
    { __typename?: 'DiscussionMutationResponse' }
    & { discussion: (
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
    ), errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type MarkMessageAsReadMutationVariables = Exact<{
  messageId: Scalars['ID'];
}>;


export type MarkMessageAsReadMutation = (
  { __typename?: 'RootMutationType' }
  & { markMessageAsRead: (
    { __typename?: 'MessageMutationResponse' }
    & { message: (
      { __typename?: 'DiscussionMessage' }
      & Pick<DiscussionMessage, 'id'>
    ), errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;


export type SendMessageMutation = (
  { __typename?: 'RootMutationType' }
  & { sendMessage?: Maybe<(
    { __typename?: 'MessageMutationResponse' }
    & { message: (
      { __typename?: 'DiscussionMessage' }
      & Pick<DiscussionMessage, 'id'>
    ), errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type RecordTltAlternateSignerSignatureMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type RecordTltAlternateSignerSignatureMutation = (
  { __typename?: 'RootMutationType' }
  & { recordTltAlternateSignerSignature: (
    { __typename?: 'RecordTltAlternateSignerSignatureResponse' }
    & { document?: Maybe<(
      { __typename?: 'TltAlternateSignerDocument' }
      & Pick<TltAlternateSignerDocument, 'id'>
    )> }
  ) }
);

export type RecordTransactionDocumentSignedMutationVariables = Exact<{
  input: RecordTransactionDocumentSignedInput;
}>;


export type RecordTransactionDocumentSignedMutation = (
  { __typename?: 'RootMutationType' }
  & { recordTransactionDocumentSigned: (
    { __typename?: 'RecordTransactionDocumentSignedResponse' }
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
  ) }
);

export type RemoveFromWatchlistMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type RemoveFromWatchlistMutation = (
  { __typename?: 'RootMutationType' }
  & { removeFromWatchlist?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { watchlist: Array<(
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type ResendInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResendInvitationMutation = (
  { __typename?: 'RootMutationType' }
  & { resendInvitation: (
    { __typename?: 'InvitationMutationResponse' }
    & { invitation?: Maybe<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type SendInvitationMutationVariables = Exact<{
  input: SendInvitationInput;
}>;


export type SendInvitationMutation = (
  { __typename?: 'RootMutationType' }
  & { sendInvitation: (
    { __typename?: 'InvitationMutationResponse' }
    & { invitation?: Maybe<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type AddEntityMutationVariables = Exact<{
  input: AddEntityInput;
}>;


export type AddEntityMutation = (
  { __typename?: 'RootMutationType' }
  & { addEntity?: Maybe<(
    { __typename?: 'AddEntityResponse' }
    & { entity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type LinkEntityMutationVariables = Exact<{
  input: LinkEntityInput;
}>;


export type LinkEntityMutation = (
  { __typename?: 'RootMutationType' }
  & { linkEntity: (
    { __typename?: 'LinkEntityResponse' }
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type ArchiveEntityMutationVariables = Exact<{
  input: ArchiveEntityInput;
}>;


export type ArchiveEntityMutation = (
  { __typename?: 'RootMutationType' }
  & { archiveEntity: (
    { __typename?: 'ArchiveEntityResponse' }
    & { entity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type UpdateInstitutionMutationVariables = Exact<{
  institutionId: Scalars['ID'];
  input: UpdateInstitutionInput;
}>;


export type UpdateInstitutionMutation = (
  { __typename?: 'RootMutationType' }
  & { updateInstitution: (
    { __typename?: 'InstitutionMutationResponse' }
    & { institution?: Maybe<(
      { __typename?: 'Institution' }
      & Pick<Institution, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'RootMutationType' }
  & { login?: Maybe<(
    { __typename?: 'SessionMutationResponse' }
    & { session?: Maybe<(
      { __typename?: 'Session' }
      & FullSessionFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type RequestEmailVerificationMutationVariables = Exact<{ [key: string]: never; }>;


export type RequestEmailVerificationMutation = (
  { __typename?: 'RootMutationType' }
  & { requestEmailVerification?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & BasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestResetPasswordMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'requestResetPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'RootMutationType' }
  & { resetPassword?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & BasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type SignupMutationVariables = Exact<{
  input: SignupInput;
}>;


export type SignupMutation = (
  { __typename?: 'RootMutationType' }
  & { signup?: Maybe<(
    { __typename?: 'SessionMutationResponse' }
    & { session?: Maybe<(
      { __typename?: 'Session' }
      & FullSessionFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type UpdateAgreedToCustomerAgreementMutationVariables = Exact<{
  agreedToCustomerAgreement: Scalars['Boolean'];
}>;


export type UpdateAgreedToCustomerAgreementMutation = (
  { __typename?: 'RootMutationType' }
  & { updateAgreedToCustomerAgreement: (
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & BasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'RootMutationType' }
  & { updatePassword?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & BasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'RootMutationType' }
  & { updateUser?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & BasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type UpdateUserEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type UpdateUserEmailMutation = (
  { __typename?: 'RootMutationType' }
  & { updateUserEmail: (
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & BasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type UpdateUserPhoneNumberMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type UpdateUserPhoneNumberMutation = (
  { __typename?: 'RootMutationType' }
  & { updateUserPhoneNumber: (
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & BasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  ) }
);

export type VerifyEmailTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyEmailTokenMutation = (
  { __typename?: 'RootMutationType' }
  & { verifyEmailToken?: Maybe<(
    { __typename?: 'UserMutationResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & BasicUserFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & FullInputErrorFragment
    )>> }
  )> }
);

export type ViewTltAlternateSignerTransactionDocumentMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ViewTltAlternateSignerTransactionDocumentMutation = (
  { __typename?: 'RootMutationType' }
  & { viewTltAlternateSignerTransactionDocument: (
    { __typename?: 'ViewTltAlternateSignerTransactionDocumentResponse' }
    & Pick<ViewTltAlternateSignerTransactionDocumentResponse, 'url'>
  ) }
);

export type ViewTransactionDocumentMutationVariables = Exact<{
  transactionId: Scalars['ID'];
}>;


export type ViewTransactionDocumentMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'viewTransactionDocument'>
);

export type AllIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllIndustriesQuery = (
  { __typename?: 'RootQueryType' }
  & { allIndustries: Array<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'name' | 'id'>
  )> }
);

export type CompaniesInvestorsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesInvestorsQuery = (
  { __typename?: 'RootQueryType' }
  & { companiesInvestors: Array<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'id' | 'name'>
  )> }
);

export type CompaniesIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesIndustriesQuery = (
  { __typename?: 'RootQueryType' }
  & { companiesIndustries: Array<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'name' | 'id'>
  )> }
);

export type CountriesQueryVariables = Exact<{
  sorted?: Maybe<Scalars['Boolean']>;
}>;


export type CountriesQuery = (
  { __typename?: 'RootQueryType' }
  & { countries: Array<(
    { __typename?: 'Country' }
    & BasicCountryFragment
  )> }
);

export type MarketActivityQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type MarketActivityQuery = (
  { __typename?: 'RootQueryType' }
  & { marketActivity: (
    { __typename?: 'MarketActivity' }
    & { mostActiveCompanies: Array<Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
      & { activity: (
        { __typename?: 'CompanyActivity' }
        & Pick<CompanyActivity, 'numOpenListings' | 'numOpenStandingBidsAndActiveBids'>
      ) }
    )>> }
  ) }
);

export type MostActiveCompaniesQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type MostActiveCompaniesQuery = (
  { __typename?: 'RootQueryType' }
  & { mostActiveCompanies: Array<Maybe<(
    { __typename?: 'Company' }
    & DashboardMostActiveCompaniesCompanyFragment
  )>> }
);

export type DashboardMostActiveCompaniesCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'logoUrl'>
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & Pick<CompanyActivity, 'numOpenListings' | 'numOpenStandingBidsAndActiveBids'>
  ) }
);

export type ValidShareSeriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidShareSeriesQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'validShareSeries'>
);

export type OnTransactionDocumentReadySubscriptionVariables = Exact<{
  transactionId: Scalars['ID'];
}>;


export type OnTransactionDocumentReadySubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { onTransactionDocumentReady: (
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
  ) }
);

export type ListingWithMyPermissionsFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'myPermissionsV2'>
);

export type StandingBidWithMyPermissionsFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'myPermissionsV2'>
);

export type OnboardingCurrentActorQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingCurrentActorQuery = (
  { __typename?: 'RootQueryType' }
  & { currentActor: (
    { __typename?: 'User' }
    & OnboardingUserFragment
  ) }
);

export type OnboardingUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'finraRegistered' | 'caIsPermittedClient' | 'caAgreedToInvestmentRisk' | 'caCanEvaluateInvestmentRisk' | 'requiresFunding' | 'interestedInLoan' | 'agreedToCustomerAgreement' | 'hasHolding'>
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, institution?: Maybe<(
    { __typename?: 'Institution' }
    & Pick<Institution, 'id' | 'isBrokerDealer' | 'legalSignatoryEmail' | 'legalSignatoryName' | 'accredited' | 'entityType' | 'suitable' | 'streetAddress' | 'isFinraInstitutionAccount' | 'caIsPermittedClient' | 'caAgreedToInvestmentRisk' | 'membershipAgreementSigned'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    ), accreditationAnswers: Array<(
      { __typename?: 'AccreditationAnswer' }
      & Pick<AccreditationAnswer, 'userId' | 'institutionId'>
      & { question: (
        { __typename?: 'AccreditationQuestion' }
        & Pick<AccreditationQuestion, 'id'>
      ) }
    )>, jurisdictionOfIncorporation?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id'>
    )>, investmentGoalOptions: Array<(
      { __typename?: 'InvestmentGoalOption' }
      & Pick<InvestmentGoalOption, 'id'>
    )> }
  )>, accreditationQuestions: Array<(
    { __typename?: 'AccreditationQuestion' }
    & Pick<AccreditationQuestion, 'id'>
  )>, investmentGoalOptions: Array<(
    { __typename?: 'InvestmentGoalOption' }
    & Pick<InvestmentGoalOption, 'id'>
  )>, watchlist: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  )>, brokerInfo?: Maybe<(
    { __typename?: 'BrokerInfo' }
    & Pick<BrokerInfo, 'id' | 'firstName' | 'lastName'>
  )> }
  & BasicUserFragment
  & InstitutionAccreditationPageUserFragment
);

export type FilterBidsIMadeBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerId'>
);

export type UseDocumentSignersTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'myPermissionsV2'>
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & UseDocumentSignersDocumentFragment
  )> }
);

export type UseDocumentSignersDocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id'>
  & { signers: Array<(
    { __typename?: 'DocumentSigner' }
    & Pick<DocumentSigner, 'id' | 'alternateSignerId' | 'userId' | 'institutionId'>
    & { alternateSigner?: Maybe<(
      { __typename?: 'AlternateSigner' }
      & Pick<AlternateSigner, 'id' | 'name'>
    )> }
  )>, invitedAlternateSigners: Array<(
    { __typename?: 'AlternateSigner' }
    & Pick<AlternateSigner, 'id' | 'insertedAt' | 'senderId' | 'institutionId'>
    & BidPageAlternateSignerDetailsAlternateSignerFragment
  )> }
  & UseHaveISignedDocumentDocumentFragment
);

export type UseHaveISignedDocumentDocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id'>
  & { signers: Array<(
    { __typename?: 'DocumentSigner' }
    & Pick<DocumentSigner, 'id' | 'userId' | 'institutionId'>
  )> }
);

export type UseMessageSenderDisplayNameDiscussionMessageFragment = (
  { __typename?: 'DiscussionMessage' }
  & Pick<DiscussionMessage, 'id' | 'senderId'>
  & { sender: (
    { __typename?: 'VeryBasicUser' }
    & Pick<VeryBasicUser, 'id' | 'institutionId' | 'name' | 'firstName' | 'isHiiveUser'>
    & { institution?: Maybe<(
      { __typename?: 'VeryBasicInstitution' }
      & Pick<VeryBasicInstitution, 'id' | 'legalName'>
    )> }
  ) }
);

export type UseMessageSenderDisplayNameDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'state'>
  & GetIsBuyerForDiscussionDiscussionFragment
);

export type CreateAccountCollectionFlowMutationVariables = Exact<{
  countryId: Scalars['String'];
  transactionId: Scalars['ID'];
}>;


export type CreateAccountCollectionFlowMutation = (
  { __typename?: 'RootMutationType' }
  & { createBankAccountCollectionFlow: (
    { __typename?: 'CreateBankAccountCollectionFlowResponse' }
    & { accountCollectionFlow: (
      { __typename?: 'AccountCollectionFlow' }
      & Pick<AccountCollectionFlow, 'clientToken' | 'counterpartyId'>
    ), errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message' | 'type'>
    )>> }
  ) }
);

export type TokenExchangeMutationVariables = Exact<{
  identityToken: Scalars['String'];
}>;


export type TokenExchangeMutation = (
  { __typename?: 'RootMutationType' }
  & { token: (
    { __typename?: 'SessionMutationResponse' }
    & { session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'token' | 'refreshToken' | 'userId'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'message' | 'type' | 'field'>
    )>> }
  ) }
);

export type FilterOpenBidsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state'>
);

export type FilterPastBidsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'rejectionReason'>
);

export type GetIsBuyerForBidBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'buyerId' | 'buyerInstitutionId'>
);

export type GetBidNumSharesActualBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'numShares' | 'numSharesActual' | 'actualNumSharesVisible'>
);

export type CountTotalUnreadBidDiscussionMessagesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'numUnreadMessages'>
  )> }
);

export type GetCanCounterNumberOfSharesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'numSharesActual'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'acceptPartialBid' | 'numSharesAvailable' | 'listingPricePerShare'>
  ) }
);

export type GetAvailableBidActionsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'myPermissionsV2' | 'pricePerShare' | 'numSharesActual' | 'displayId'>
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'listingPricePerShare' | 'numSharesAvailable'>
  ), discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
  )> }
);

export type GetSecuritySpecialistContactCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type GetAvailableCompanyActionsCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'myPermissionsV2'>
);

export type GetIsSenderInstitutionUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'institutionId'>
);

export type GetIsSenderInstitutionDiscussionMessageFragment = (
  { __typename?: 'DiscussionMessage' }
  & Pick<DiscussionMessage, 'id'>
  & { sender: (
    { __typename?: 'VeryBasicUser' }
    & Pick<VeryBasicUser, 'id' | 'institutionId'>
  ) }
);

export type GetIsSenderUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
);

export type GetIsSenderDiscussionMessageFragment = (
  { __typename?: 'DiscussionMessage' }
  & Pick<DiscussionMessage, 'id' | 'senderId'>
);

export type GetIsSensitiveMessageUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & GetIsSenderUserFragment
  & GetIsSenderInstitutionUserFragment
);

export type GetIsSensitiveMessageDiscussionMessageFragment = (
  { __typename?: 'DiscussionMessage' }
  & Pick<DiscussionMessage, 'id'>
  & GetIsSenderDiscussionMessageFragment
  & GetIsSenderInstitutionDiscussionMessageFragment
);

export type GetIsSensitiveMessageDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'state'>
);

export type GetDiscussionStatusTextDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'state'>
);

export type GetDiscussionHasCounterpartyMessageUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & GetIsSenderUserFragment
  & GetIsSenderInstitutionUserFragment
);

export type GetDiscussionHasCounterpartyMessageDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & { mostRecentMessage?: Maybe<(
    { __typename?: 'DiscussionMessage' }
    & Pick<DiscussionMessage, 'id'>
    & GetIsSenderDiscussionMessageFragment
    & GetIsSenderInstitutionDiscussionMessageFragment
  )> }
);

export type GetDiscussionTopicCounterpartyShortTextUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
);

export type GetDiscussionTopicCounterpartyShortTextListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'sellerId'>
);

export type GetDiscussionTopicCounterpartyShortTextStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'buyerId'>
);

export type GetDiscussionTopicCounterpartyTextUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'institutionId' | 'isHiiveUser'>
);

export type GetDiscussionTopicCounterpartyTextListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'sellerId'>
);

export type GetDiscussionTopicCounterpartyTextStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'buyerId'>
);

export type GetDiscussionDocumentTextDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & { topic: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'transferMethod'>
  ) | (
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id'>
  ) }
);

export type GetDiscussionIsSellerUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'institutionId'>
);

export type GetDiscussionIsSellerDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & { topic: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'sellerId' | 'sellerInstitutionId'>
  ) | (
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id' | 'buyerId' | 'buyerInstitutionId'>
  ) }
);

export type GetIsBidDiscussionDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
);

export type GetIsBidDiscussionBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
  )> }
);

export type GetAvailableDiscussionActionsDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'myPermissionsV2'>
);

export type FormatInvestorsInvestorFragment = (
  { __typename?: 'Investor' }
  & Pick<Investor, 'id' | 'name'>
);

export type GetIsListerUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
);

export type GetIsListerListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'sellerId'>
);

export type GetAvailableInstitutionActionsInstitutionFragment = (
  { __typename?: 'Institution' }
  & Pick<Institution, 'id' | 'myPermissionsV2'>
);

export type GetIsBrokerForBidUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
);

export type GetIsBrokerForBidBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'brokerId'>
);

export type GetIsBrokerForStandingBidUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
);

export type GetIsBrokerForStandingBidStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'brokerId'>
);

export type GetIsCounterpartyForBidUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'institutionId'>
);

export type GetIsCounterpartyForBidBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'counterpartyId' | 'counterpartyInstitutionId' | 'brokerCounterpartyId'>
);

export type GetIsBuyerForDiscussionUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & GetIsSellerForListingUserFragment
  & GetIsBuyerForStandingBidUserFragment
);

export type GetIsBuyerForDiscussionDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & { topic: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
    & GetIsSellerForListingListingFragment
  ) | (
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id'>
    & GetIsBuyerForStandingBidStandingBidFragment
  ) }
);

export type GetIsBrokerForListingListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'brokerId'>
);

export type GetIsBrokerForListingUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
);

export type GetShowListingActivityListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & GetIsSellerForListingListingFragment
);

export type GetShowListingActivityUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'investorType'>
  & GetIsSellerForListingUserFragment
  & GetIsInstitutionViewerUserFragment
);

export type GetListingBidCountListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'numActiveBids' | 'numCounteredBids'>
);

export type GetListingHasBidsListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & GetListingBidCountListingFragment
);

export type GetListingHasAcceptedBidPricesListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptedBidPrices'>
);

export type GetIsSellerForListingListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'sellerId' | 'sellerInstitutionId'>
);

export type GetIsSellerForListingUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'institutionId'>
);

export type GetIsEligibleBuyerForListingListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & GetIsSellerForListingListingFragment
);

export type GetIsEligibleBuyerForListingUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & GetIsSellerForListingUserFragment
  & GetIsInstitutionUserUserFragment
  & HasUserRoleUserFragment
  & HasInvestorTypeUserFragment
);

export type GetIsPartiallySoldListingListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'state' | 'acceptPartialBid' | 'acceptedBidPrices'>
);

export type GetShowListingSolicitedStateListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'solicited' | 'brokerId'>
);

export type GetIsConditionallySoldListingListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'state'>
);

export type GetListingNumSharesOriginalListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'numSharesOriginalRounded' | 'numSharesOriginal'>
);

export type GetListingNumSharesAvailableListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'numSharesAvailableRounded' | 'numSharesAvailable'>
);

export type CalculateListingNumSharesSoldConditionallyListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & GetListingNumSharesOriginalListingFragment
  & GetListingNumSharesAvailableListingFragment
);

export type GetListingHasConditionallySoldSharesListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
  & CalculateListingNumSharesSoldConditionallyListingFragment
);

export type AcceptedBidSharePriceDetailsListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptedBidPrices' | 'acceptPartialBid' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'state' | 'transferMethod'>
);

export type NumShareAndPriceDetailsListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'acceptedBidPrices' | 'acceptPartialBid' | 'listingPricePerShare' | 'numSharesAvailable' | 'numSharesAvailableRounded' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'state' | 'solicited'>
);

export type SolicitedListingActivityCardCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
  & { securitySpecialist?: Maybe<(
    { __typename?: 'SecuritySpecialist' }
    & Pick<SecuritySpecialist, 'id'>
    & { user: (
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    ), marketAnalyst?: Maybe<(
      { __typename?: 'SecuritySpecialistUser' }
      & Pick<SecuritySpecialistUser, 'id' | 'email'>
    )> }
  )> }
);

export type GetIsListingPartiallySoldListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'numSharesSoldConditionally'>
);

export type GetAvailableListingActionsListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'state' | 'myPermissionsV2'>
);

export type GetPricePerShareListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'listingPricePerShare'>
);

export type GetPricePerShareStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'pricePerShare'>
);

export type GetPricePerShareBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'pricePerShare' | 'counterPricePerShare'>
);

export type GetNumOfSharesListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'numSharesAvailable' | 'numSharesOriginal' | 'numSharesAvailableRounded' | 'numSharesOriginalRounded' | 'actualNumSharesVisible' | 'state'>
);

export type GetNumOfSharesBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'numShares' | 'numSharesActual' | 'actualNumSharesVisible' | 'counterNumShares'>
);

export type GetNumOfSharesStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'state' | 'numSharesAvailable' | 'numSharesOriginal' | 'version'>
);

export type MakeUrlTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  ) }
);

export type MakeUrlBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'companyId'>
);

export type MakeUrlListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'companyId'>
);

export type MakeUrlStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'companyId'>
);

export type MakeUrlCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id'>
);

export type MakeUrlDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id' | 'state'>
  & { standingBidTopic: { __typename?: 'Listing' } | (
    { __typename?: 'StandingBid' }
    & MakeUrlStandingBidFragment
  ), listingTopic: (
    { __typename?: 'Listing' }
    & MakeUrlListingFragment
  ) | { __typename?: 'StandingBid' } }
);

export type GetPostingTitleListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id'>
);

export type GetPostingTitleBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id'>
);

export type GetPostingTitleStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id'>
);

export type GetPostingTitleDiscussionFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
);

export type GetTransactionIsPurchaseUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'institutionId'>
);

export type GetStandingBidLotStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'pricePerShare'>
  & GetNumOfSharesStandingBidFragment
);

export type GetTransactionIsPurchaseTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id'>
  & { bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'buyerId' | 'buyerInstitutionId'>
  ) }
);

export type GetNumSharesAvailableRoundedListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'numSharesAvailableRounded'>
);

export type GetListingNumOfSharesListingFragment = (
  { __typename?: 'Listing' }
  & Pick<Listing, 'id' | 'numSharesOriginal' | 'numSharesOriginalRounded' | 'numSharesAvailable' | 'numSharesAvailableRounded'>
);

export type GetIsPartiallyAcceptedStandingBidStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'state' | 'allowPartialAccept' | 'acceptedBidPrices'>
);

export type GetIsConditionallyCompletedStandingBidStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'state'>
);

export type GetIsBuyerForStandingBidStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'buyerId' | 'buyerInstitutionId'>
);

export type GetIsBuyerForStandingBidUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'institutionId'>
);

export type GetIsBrokerCounterpartyForStandingBidUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & GetIsBrokerUserFragment
);

export type GetIsBrokerCounterpartyForStandingBidStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'brokerId'>
  & GetIsBrokerForStandingBidStandingBidFragment
);

export type GetIsSolicitedStandingBidStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'solicited'>
);

export type GetAvailableStandingBidActionsStandingBidFragment = (
  { __typename?: 'StandingBid' }
  & Pick<StandingBid, 'id' | 'myPermissionsV2' | 'state'>
);

export type GetTransactionTitleTransactionFragment = (
  { __typename?: 'Transaction' }
  & { bid: (
    { __typename?: 'Bid' }
    & { standingBid?: Maybe<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
    )> }
  ) }
);

export type GetTransactionDisplayIdTransactionFragment = (
  { __typename?: 'Transaction' }
  & { bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'displayId'>
    & { standingBid?: Maybe<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'displayId'>
    )> }
  ) }
);

export type GetTransactionExpirationDateTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'expireAt'>
);

export type GetTransactionCounterpartyNameTransactionFragment = (
  { __typename?: 'Transaction' }
  & { counterparty?: Maybe<(
    { __typename?: 'Counterparty' }
    & Pick<Counterparty, 'id' | 'name'>
    & { institution?: Maybe<(
      { __typename?: 'VeryBasicInstitution' }
      & Pick<VeryBasicInstitution, 'legalName'>
    )> }
  )>, document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & { signers: Array<(
      { __typename?: 'DocumentSigner' }
      & Pick<DocumentSigner, 'id' | 'userId'>
      & { alternateSigner?: Maybe<(
        { __typename?: 'AlternateSigner' }
        & Pick<AlternateSigner, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GetShowDocumentActionsBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'state' | 'counterpartyId'>
  & { transaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'state' | 'rofr'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )> }
  )> }
);

export type GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardMyActivityFragment = (
  { __typename?: 'UnaccreditedSellerActivity' }
  & { pendingSales: Array<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
  )>, completedSales: Array<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id'>
  )> }
);

export type GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardMyCompanyActivityFragment = (
  { __typename?: 'UnaccreditedSellerMyCompanyActivity' }
  & { standingBidsWithDiscussions: Array<(
    { __typename?: 'StandingBid' }
    & Pick<StandingBid, 'id'>
  )> }
);

export type GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardCompanyActivityFragment = (
  { __typename?: 'Company' }
  & { activity: (
    { __typename?: 'CompanyActivity' }
    & { myCompletedTransactions: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )>, myOpenTransactions: Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id'>
    )>, standingBidsWithDiscussions: Array<(
      { __typename?: 'StandingBid' }
      & Pick<StandingBid, 'id'>
    )> }
  ) }
);

export type GetUserInitialsUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'firstName' | 'lastName'>
);

export type GetIsInstitutionViewerUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & GetIsInstitutionUserUserFragment
  & HasUserRoleUserFragment
);

export type GetIsInstitutionUserUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'institutionId' | 'investorStatus'>
);

export type GetIsBrokerUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'investorType'>
);

export type GetIsUnaccreditedSellerUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'investorType'>
);

export type GetIsBrokerTltAlternateSignerSenderFragment = (
  { __typename?: 'TltAlternateSignerSender' }
  & Pick<TltAlternateSignerSender, 'id' | 'investorType'>
);

export type GetIsMembershipAgreementSignedUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'membershipAgreementSigned'>
  & { institution?: Maybe<(
    { __typename?: 'Institution' }
    & Pick<Institution, 'id' | 'membershipAgreementSigned'>
  )> }
);

export type HasUserRoleUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'roles'>
);

export type HasInvestorTypeUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'investorType'>
);

export type HasMultiFactorAuthenticationUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & { multiFactorAuthentications: (
    { __typename?: 'UserMultiFactorAuthentications' }
    & Pick<UserMultiFactorAuthentications, 'app' | 'sms'>
  ) }
);

export type GetUserCountryUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, institution?: Maybe<(
    { __typename?: 'Institution' }
    & Pick<Institution, 'id'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    ) }
  )> }
  & GetIsInstitutionUserUserFragment
);

export type GetAvailableUserPermissionsUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'myPermissionsV2'>
);

export const ListedHoldingFragmentDoc = gql`
    fragment ListedHolding on Holding {
  companyId
  id
  numSharesV2
  company {
    id
    name
    logoUrl
  }
}
    `;
export const UnlistedHoldingFragmentDoc = gql`
    fragment UnlistedHolding on UnlistedHolding {
  companyName
  id
  numSharesV2
}
    `;
export const InstitutionInvitationsTableInvitationFragmentDoc = gql`
    fragment InstitutionInvitationsTableInvitation on Invitation {
  id
  email
  roles
  status
  insertedAt
  updatedAt
}
    `;
export const GetIsBuyerForBidBidFragmentDoc = gql`
    fragment GetIsBuyerForBidBid on Bid {
  id
  buyerId
  buyerInstitutionId
}
    `;
export const GetIsCounterpartyForBidBidFragmentDoc = gql`
    fragment GetIsCounterpartyForBidBid on Bid {
  id
  counterpartyId
  counterpartyInstitutionId
  brokerCounterpartyId
}
    `;
export const MakeUrlTransactionFragmentDoc = gql`
    fragment MakeUrlTransaction on Transaction {
  id
  company {
    id
  }
}
    `;
export const CompletedTransfersCardTransactionFragmentDoc = gql`
    fragment CompletedTransfersCardTransaction on Transaction {
  id
  state
  closedFeePaidAt
  closedFeePendingAt
  insertedAt
  bid {
    id
    updatedAt
    buyerId
    brokerId
    counterpartyId
    buyerInstitutionId
    counterpartyInstitutionId
    state
    company {
      id
      name
      logoUrl
    }
    ...GetIsBuyerForBidBid
    ...GetIsCounterpartyForBidBid
  }
  document {
    id
    type
  }
  ...MakeUrlTransaction
}
    ${GetIsBuyerForBidBidFragmentDoc}
${GetIsCounterpartyForBidBidFragmentDoc}
${MakeUrlTransactionFragmentDoc}`;
export const OutdatedResponseFragmentDoc = gql`
    fragment OutdatedResponse on InvestmentGoalAnswersCurrentResponse {
  current
}
    `;
export const InvitationPageInvitationFragmentDoc = gql`
    fragment InvitationPageInvitation on Invitation {
  id
  email
}
    `;
export const ActivityCardStandingBidCardFooterFragmentDoc = gql`
    fragment ActivityCardStandingBidCardFooter on StandingBid {
  id
  expireAt
  state
  acceptedBidPrices
}
    `;
export const MyDiscussionListStandingBidFragmentDoc = gql`
    fragment MyDiscussionListStandingBid on StandingBid {
  id
  displayId
  buyerId
  buyerInstitutionId
  fromHiive
}
    `;
export const MyDiscussionListListingFragmentDoc = gql`
    fragment MyDiscussionListListing on Listing {
  id
  displayId
  sellerId
  sellerInstitutionId
  fromHiive
}
    `;
export const MyDiscussionListDiscussionFragmentDoc = gql`
    fragment MyDiscussionListDiscussion on Discussion {
  id
  state
  displayId
  numUnreadMessages
  totalNumMessages
  mostRecentMessage {
    id
    senderId
    sender {
      institutionId
      id
    }
    messageBody
    insertedAt
  }
  members {
    id
  }
  topic {
    ... on StandingBid {
      ...MyDiscussionListStandingBid
    }
    ... on Listing {
      ...MyDiscussionListListing
    }
  }
}
    ${MyDiscussionListStandingBidFragmentDoc}
${MyDiscussionListListingFragmentDoc}`;
export const GetIsSenderDiscussionMessageFragmentDoc = gql`
    fragment GetIsSenderDiscussionMessage on DiscussionMessage {
  id
  senderId
}
    `;
export const GetIsSenderInstitutionDiscussionMessageFragmentDoc = gql`
    fragment GetIsSenderInstitutionDiscussionMessage on DiscussionMessage {
  id
  sender {
    id
    institutionId
  }
}
    `;
export const UseMessageSenderDisplayNameDiscussionMessageFragmentDoc = gql`
    fragment UseMessageSenderDisplayNameDiscussionMessage on DiscussionMessage {
  id
  senderId
  sender {
    id
    institutionId
    institution {
      id
      legalName
    }
    name
    firstName
    isHiiveUser
  }
}
    `;
export const GetIsSensitiveMessageDiscussionMessageFragmentDoc = gql`
    fragment GetIsSensitiveMessageDiscussionMessage on DiscussionMessage {
  id
  ...GetIsSenderDiscussionMessage
  ...GetIsSenderInstitutionDiscussionMessage
}
    ${GetIsSenderDiscussionMessageFragmentDoc}
${GetIsSenderInstitutionDiscussionMessageFragmentDoc}`;
export const DiscussionMessageDiscussionMessageFragmentDoc = gql`
    fragment DiscussionMessageDiscussionMessage on DiscussionMessage {
  id
  insertedAt
  messageBody
  sender {
    id
    isHiiveUser
  }
  ...GetIsSenderDiscussionMessage
  ...GetIsSenderInstitutionDiscussionMessage
  ...UseMessageSenderDisplayNameDiscussionMessage
  ...GetIsSensitiveMessageDiscussionMessage
}
    ${GetIsSenderDiscussionMessageFragmentDoc}
${GetIsSenderInstitutionDiscussionMessageFragmentDoc}
${UseMessageSenderDisplayNameDiscussionMessageFragmentDoc}
${GetIsSensitiveMessageDiscussionMessageFragmentDoc}`;
export const DiscussionMessageListDiscussionMessageFragmentDoc = gql`
    fragment DiscussionMessageListDiscussionMessage on DiscussionMessage {
  id
  senderId
  ...DiscussionMessageDiscussionMessage
}
    ${DiscussionMessageDiscussionMessageFragmentDoc}`;
export const DiscussionThreadCardDiscussionMessageFragmentDoc = gql`
    fragment DiscussionThreadCardDiscussionMessage on DiscussionMessage {
  id
  ...DiscussionMessageListDiscussionMessage
}
    ${DiscussionMessageListDiscussionMessageFragmentDoc}`;
export const GetDiscussionTopicCounterpartyTextStandingBidFragmentDoc = gql`
    fragment GetDiscussionTopicCounterpartyTextStandingBid on StandingBid {
  id
  buyerId
}
    `;
export const GetDiscussionTopicCounterpartyTextListingFragmentDoc = gql`
    fragment GetDiscussionTopicCounterpartyTextListing on Listing {
  id
  sellerId
}
    `;
export const GetIsSellerForListingListingFragmentDoc = gql`
    fragment GetIsSellerForListingListing on Listing {
  id
  sellerId
  sellerInstitutionId
}
    `;
export const GetIsBuyerForStandingBidStandingBidFragmentDoc = gql`
    fragment GetIsBuyerForStandingBidStandingBid on StandingBid {
  id
  buyerId
  buyerInstitutionId
}
    `;
export const GetIsBuyerForDiscussionDiscussionFragmentDoc = gql`
    fragment GetIsBuyerForDiscussionDiscussion on Discussion {
  id
  topic {
    ... on Listing {
      id
      ...GetIsSellerForListingListing
    }
    ... on StandingBid {
      id
      ...GetIsBuyerForStandingBidStandingBid
    }
  }
}
    ${GetIsSellerForListingListingFragmentDoc}
${GetIsBuyerForStandingBidStandingBidFragmentDoc}`;
export const UseMessageSenderDisplayNameDiscussionFragmentDoc = gql`
    fragment UseMessageSenderDisplayNameDiscussion on Discussion {
  id
  state
  ...GetIsBuyerForDiscussionDiscussion
}
    ${GetIsBuyerForDiscussionDiscussionFragmentDoc}`;
export const GetIsSensitiveMessageDiscussionFragmentDoc = gql`
    fragment GetIsSensitiveMessageDiscussion on Discussion {
  id
  state
}
    `;
export const DiscussionMessageDiscussionFragmentDoc = gql`
    fragment DiscussionMessageDiscussion on Discussion {
  id
  ...UseMessageSenderDisplayNameDiscussion
  ...GetIsSensitiveMessageDiscussion
}
    ${UseMessageSenderDisplayNameDiscussionFragmentDoc}
${GetIsSensitiveMessageDiscussionFragmentDoc}`;
export const DiscussionMessageListDiscussionFragmentDoc = gql`
    fragment DiscussionMessageListDiscussion on Discussion {
  id
  initiatorId
  state
  counterpartyHasReplied
  numUnreadMessages
  topic {
    ... on Listing {
      id
      fromHiive
      sellerId
    }
    ... on StandingBid {
      id
      fromHiive
      buyerId
    }
  }
  ...DiscussionMessageDiscussion
}
    ${DiscussionMessageDiscussionFragmentDoc}`;
export const GetAvailableDiscussionActionsDiscussionFragmentDoc = gql`
    fragment GetAvailableDiscussionActionsDiscussion on Discussion {
  id
  myPermissionsV2
}
    `;
export const AcceptDiscussionRequestFormDiscussionFragmentDoc = gql`
    fragment AcceptDiscussionRequestFormDiscussion on Discussion {
  id
  initiatorId
  myPermissionsV2
  topic {
    ... on Listing {
      id
      sellerId
    }
    ... on StandingBid {
      id
      buyerId
      buyerInstitutionId
    }
  }
}
    `;
export const GetDiscussionDocumentTextDiscussionFragmentDoc = gql`
    fragment GetDiscussionDocumentTextDiscussion on Discussion {
  id
  topic {
    ... on StandingBid {
      id
    }
    ... on Listing {
      id
      transferMethod
    }
  }
}
    `;
export const GetDiscussionIsSellerDiscussionFragmentDoc = gql`
    fragment GetDiscussionIsSellerDiscussion on Discussion {
  id
  topic {
    ... on StandingBid {
      id
      buyerId
      buyerInstitutionId
    }
    ... on Listing {
      id
      sellerId
      sellerInstitutionId
    }
  }
}
    `;
export const OffPlatformReminderDiscussionFragmentDoc = gql`
    fragment OffPlatformReminderDiscussion on Discussion {
  id
  topic {
    ... on Listing {
      id
    }
    ... on StandingBid {
      id
    }
  }
  ...GetDiscussionDocumentTextDiscussion
  ...GetDiscussionIsSellerDiscussion
}
    ${GetDiscussionDocumentTextDiscussionFragmentDoc}
${GetDiscussionIsSellerDiscussionFragmentDoc}`;
export const SendMessageFormDiscussionFragmentDoc = gql`
    fragment SendMessageFormDiscussion on Discussion {
  id
  ...OffPlatformReminderDiscussion
}
    ${OffPlatformReminderDiscussionFragmentDoc}`;
export const DiscussionThreadCardFooterDiscussionFragmentDoc = gql`
    fragment DiscussionThreadCardFooterDiscussion on Discussion {
  id
  myMembershipState
  ...GetAvailableDiscussionActionsDiscussion
  ...AcceptDiscussionRequestFormDiscussion
  ...SendMessageFormDiscussion
}
    ${GetAvailableDiscussionActionsDiscussionFragmentDoc}
${AcceptDiscussionRequestFormDiscussionFragmentDoc}
${SendMessageFormDiscussionFragmentDoc}`;
export const DiscussionThreadCardDiscussionFragmentDoc = gql`
    fragment DiscussionThreadCardDiscussion on Discussion {
  id
  messages {
    id
    ...DiscussionThreadCardDiscussionMessage
  }
  topic {
    ... on StandingBid {
      id
      ...GetDiscussionTopicCounterpartyTextStandingBid
    }
    ... on Listing {
      id
      ...GetDiscussionTopicCounterpartyTextListing
    }
  }
  ...DiscussionMessageListDiscussion
  ...DiscussionThreadCardFooterDiscussion
}
    ${DiscussionThreadCardDiscussionMessageFragmentDoc}
${GetDiscussionTopicCounterpartyTextStandingBidFragmentDoc}
${GetDiscussionTopicCounterpartyTextListingFragmentDoc}
${DiscussionMessageListDiscussionFragmentDoc}
${DiscussionThreadCardFooterDiscussionFragmentDoc}`;
export const StandingBidSellerDiscussionListDiscussionFragmentDoc = gql`
    fragment StandingBidSellerDiscussionListDiscussion on Discussion {
  ...MyDiscussionListDiscussion
  ...DiscussionThreadCardDiscussion
}
    ${MyDiscussionListDiscussionFragmentDoc}
${DiscussionThreadCardDiscussionFragmentDoc}`;
export const StandingBidBuyerDiscussionListDiscussionFragmentDoc = gql`
    fragment StandingBidBuyerDiscussionListDiscussion on Discussion {
  ...MyDiscussionListDiscussion
}
    ${MyDiscussionListDiscussionFragmentDoc}`;
export const WatchlistComboboxCompanyFragmentDoc = gql`
    fragment WatchlistComboboxCompany on Company {
  id
  name
  logoUrl
}
    `;
export const WatchlistComboboxCompanyEdgeFragmentDoc = gql`
    fragment WatchlistComboboxCompanyEdge on CompanyEdge {
  node {
    ...WatchlistComboboxCompany
  }
  cursor
}
    ${WatchlistComboboxCompanyFragmentDoc}`;
export const BasicUserEntityFragmentDoc = gql`
    fragment BasicUserEntity on Entity {
  active
  otherType
  id
  insertedAt
  jurisdictionOfFormation {
    fullName
    id
    name
  }
  legalName
  type
}
    `;
export const BasicUserFragmentDoc = gql`
    fragment BasicUser on User {
  id
  name
  firstName
  lastName
  email
  emailVerified
  roles
  institutionId
  isSuperadmin
  isActive
  identityVerified
  identityVerificationStatus
  onboardingComplete
  suitableInvestor
  accredited
  membershipAgreementSigned
  investorStatus
  investorType
  isHiiveUser
  jobTitle
  phoneNumber
  updatedAt
  insertedAt
  myPermissionsV2
  agreedToCustomerAgreement
  country {
    name
    id
  }
  entities {
    ...BasicUserEntity
  }
}
    ${BasicUserEntityFragmentDoc}`;
export const InvestmentGoalAnswerFragmentDoc = gql`
    fragment InvestmentGoalAnswer on InvestmentGoalAnswer {
  id
  text
  option {
    id
    text
    custom
    question {
      id
      text
      description
      order
    }
  }
}
    `;
export const SuitabilityAnswerFragmentDoc = gql`
    fragment SuitabilityAnswer on SuitabilityAnswer {
  id
  text
  suitabilityOption {
    id
    text
    custom
    suitabilityQuestion {
      id
      text
      description
      order
      suitabilityQuestionGroup {
        id
        version
      }
    }
  }
}
    `;
export const ModernTreasuryVerifyAccountCardBankAccountFragmentDoc = gql`
    fragment ModernTreasuryVerifyAccountCardBankAccount on BankAccount {
  id
  last4
  insertedAt
  verificationStatus
  verifiedById
  lastUsedTransaction {
    id
    bid {
      id
      displayId
    }
    company {
      id
      name
      logoUrl
    }
  }
}
    `;
export const TransactingEntityItemEntityFragmentDoc = gql`
    fragment TransactingEntityItemEntity on Entity {
  active
  otherType
  id
  insertedAt
  jurisdictionOfFormation {
    fullName
    id
    name
  }
  legalName
  type
  bankAccounts {
    ...ModernTreasuryVerifyAccountCardBankAccount
  }
}
    ${ModernTreasuryVerifyAccountCardBankAccountFragmentDoc}`;
export const UserWithInstitutionInstitutionFragmentDoc = gql`
    fragment UserWithInstitutionInstitution on Institution {
  id
  myPermissionsV2
  streetAddress
  city
  region
  postalCode
  legalName
  legalSignatoryName
  legalSignatoryEmail
  membershipAgreementSigned
  isBrokerDealer
  country {
    id
    name
  }
  jurisdictionOfIncorporation {
    id
    name
  }
  complianceEmail
  accreditationLastUpdatedAt
  accreditationQuestions {
    id
    text
  }
  accreditationAnswers(filterBy: {latest: true}) {
    text
    question {
      order
      id
      text
    }
  }
  investmentGoalAnswers(filterBy: {latest: true}) {
    ...InvestmentGoalAnswer
  }
  suitabilityAnswers {
    ...SuitabilityAnswer
  }
  approved
  suitabilityLastUpdatedAt
  entities {
    ...TransactingEntityItemEntity
  }
  entityType
}
    ${InvestmentGoalAnswerFragmentDoc}
${SuitabilityAnswerFragmentDoc}
${TransactingEntityItemEntityFragmentDoc}`;
export const FeeDiscountFragmentDoc = gql`
    fragment FeeDiscount on FeeDiscount {
  id
  name
  description
  type
  value
}
    `;
export const UserWithInstitutionFragmentDoc = gql`
    fragment UserWithInstitution on User {
  ...BasicUser
  institutionId
  institution {
    ...UserWithInstitutionInstitution
  }
  entities {
    ...TransactingEntityItemEntity
  }
  accreditationQuestions {
    id
    text
  }
  accreditationAnswers(filterBy: {latest: true}) {
    question {
      key
      order
      id
      text
    }
    text
  }
  country {
    id
    name
  }
  onboardingV2 {
    id
    currentStep
    hasAgreedToClickwrapAgreement
  }
  manuallyApproved
  finraRegistered
  investorType
  accreditationLastUpdatedAt
  feeDiscountApplications {
    id
    feeDiscount {
      ...FeeDiscount
    }
    company {
      id
    }
  }
  investmentGoalAnswers(filterBy: {latest: true}) {
    ...InvestmentGoalAnswer
  }
  suitabilityAnswers {
    ...SuitabilityAnswer
  }
  suitabilityLastUpdatedAt
  multiFactorAuthentications {
    app
    sms
  }
  trustedContactPerson {
    isContactProvided
    updatedAt
    contactInfo {
      firstName
      lastName
      email
      phoneNumber
      relationship
    }
  }
  chatIdHash
  investingGoals {
    option {
      id
      questionId
    }
  }
}
    ${BasicUserFragmentDoc}
${UserWithInstitutionInstitutionFragmentDoc}
${TransactingEntityItemEntityFragmentDoc}
${FeeDiscountFragmentDoc}
${InvestmentGoalAnswerFragmentDoc}
${SuitabilityAnswerFragmentDoc}`;
export const BrowseCompaniesPageCountryFragmentDoc = gql`
    fragment BrowseCompaniesPageCountry on Country {
  id
  name
}
    `;
export const BrowseCompaniesPagePricingBlockCompanyFragmentDoc = gql`
    fragment BrowseCompaniesPagePricingBlockCompany on Company {
  id
  name
  currentPrices {
    lowestAsk
    highestBid
    lastTransaction
  }
}
    `;
export const BrowseCompaniesPageCompanyActivityCompanyFragmentDoc = gql`
    fragment BrowseCompaniesPageCompanyActivityCompany on Company {
  id
  lastRoundPostValuation
  lastRoundPricePerShare
  lastRoundDate
  lastRoundSeries
  lastRoundRaised
  totalRaised
  activity {
    numOpenListings
    numOpenStandingBidsAndActiveBids
  }
}
    `;
export const BrowseCompaniesPageCompanyCardCompanyFragmentDoc = gql`
    fragment BrowseCompaniesPageCompanyCardCompany on Company {
  id
  logoUrl
  name
  investors {
    id
    name
  }
  description
  industries {
    id
    name
  }
  activeOnHiiveFiftyIndex
  ...BrowseCompaniesPagePricingBlockCompany
  ...BrowseCompaniesPageCompanyActivityCompany
}
    ${BrowseCompaniesPagePricingBlockCompanyFragmentDoc}
${BrowseCompaniesPageCompanyActivityCompanyFragmentDoc}`;
export const BrowseCompaniesPageCompaniesListCompanyFragmentDoc = gql`
    fragment BrowseCompaniesPageCompaniesListCompany on Company {
  id
  ...BrowseCompaniesPageCompanyCardCompany
}
    ${BrowseCompaniesPageCompanyCardCompanyFragmentDoc}`;
export const BrowseCompaniesPageCompanyFragmentDoc = gql`
    fragment BrowseCompaniesPageCompany on Company {
  id
  ...BrowseCompaniesPageCompaniesListCompany
}
    ${BrowseCompaniesPageCompaniesListCompanyFragmentDoc}`;
export const BrowseCompaniesPageCompanyEdgeFragmentDoc = gql`
    fragment BrowseCompaniesPageCompanyEdge on CompanyEdge {
  node {
    id
    ...BrowseCompaniesPageCompany
  }
  cursor
}
    ${BrowseCompaniesPageCompanyFragmentDoc}`;
export const FilterBidsIMadeBidFragmentDoc = gql`
    fragment FilterBidsIMadeBid on Bid {
  id
  buyerId
}
    `;
export const CompanyActionsBidFragmentDoc = gql`
    fragment CompanyActionsBid on Bid {
  id
  buyerInstitutionId
  state
  ...FilterBidsIMadeBid
}
    ${FilterBidsIMadeBidFragmentDoc}`;
export const GetAvailableCompanyActionsCompanyFragmentDoc = gql`
    fragment GetAvailableCompanyActionsCompany on Company {
  id
  myPermissionsV2
}
    `;
export const CreateListingModalCompanyFragmentDoc = gql`
    fragment CreateListingModalCompany on Company {
  id
  name
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
  myPermissionsV2
  minPostPps
  maxPostPps
  currentPrices {
    highestBid
  }
  companyEligibilityCriterion {
    id
    text
  }
  hasActiveInformationDisclosure
  requiresInformationDisclosure
}
    `;
export const PlaceStandingBidModalCompanyFragmentDoc = gql`
    fragment PlaceStandingBidModalCompany on Company {
  id
  name
  logoUrl
  myPermissionsV2
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
  tradeable
  minPostPps
  maxPostPps
}
    `;
export const BrokerSubmitBidSequenceModalBidFragmentDoc = gql`
    fragment BrokerSubmitBidSequenceModalBid on Bid {
  id
  buyerInstitutionId
  state
  ...FilterBidsIMadeBid
}
    ${FilterBidsIMadeBidFragmentDoc}`;
export const GetNumOfSharesListingFragmentDoc = gql`
    fragment GetNumOfSharesListing on Listing {
  numSharesAvailable
  numSharesOriginal
  numSharesAvailableRounded
  numSharesOriginalRounded
  actualNumSharesVisible
  state
}
    `;
export const FormSelectListingInputListingFragmentDoc = gql`
    fragment FormSelectListingInputListing on Listing {
  id
  transferMethod
  displayId
  shortId
  ...GetNumOfSharesListing
}
    ${GetNumOfSharesListingFragmentDoc}`;
export const BrokerSubmitBidSequenceModalListingFragmentDoc = gql`
    fragment BrokerSubmitBidSequenceModalListing on Listing {
  id
  state
  myPermissionsV2
  listingPricePerShare
  bids {
    ...BrokerSubmitBidSequenceModalBid
  }
  ...GetNumOfSharesListing
  ...FormSelectListingInputListing
}
    ${BrokerSubmitBidSequenceModalBidFragmentDoc}
${GetNumOfSharesListingFragmentDoc}
${FormSelectListingInputListingFragmentDoc}`;
export const BrokerSubmitBidSequenceModalCompanyFragmentDoc = gql`
    fragment BrokerSubmitBidSequenceModalCompany on Company {
  id
  name
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
  logoUrl
  lastRoundPricePerShare
  myPermissionsV2
  tradeable
  minPostPps
  maxPostPps
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
  activity {
    othersListings {
      ...BrokerSubmitBidSequenceModalListing
    }
  }
}
    ${BrokerSubmitBidSequenceModalListingFragmentDoc}`;
export const GetSecuritySpecialistContactCompanyFragmentDoc = gql`
    fragment GetSecuritySpecialistContactCompany on Company {
  id
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
}
    `;
export const PlaceBidSequenceModalPlaceBidBidFragmentDoc = gql`
    fragment PlaceBidSequenceModalPlaceBidBid on Bid {
  id
  buyerId
  buyerInstitutionId
  state
  ...FilterBidsIMadeBid
}
    ${FilterBidsIMadeBidFragmentDoc}`;
export const GetNumSharesAvailableRoundedListingFragmentDoc = gql`
    fragment GetNumSharesAvailableRoundedListing on Listing {
  id
  numSharesAvailableRounded
}
    `;
export const GetPricePerShareListingFragmentDoc = gql`
    fragment GetPricePerShareListing on Listing {
  id
  listingPricePerShare
}
    `;
export const PlaceBidSequenceModalPlaceBidListingFragmentDoc = gql`
    fragment PlaceBidSequenceModalPlaceBidListing on Listing {
  id
  acceptPartialBid
  ...GetNumSharesAvailableRoundedListing
  ...FormSelectListingInputListing
  ...GetPricePerShareListing
}
    ${GetNumSharesAvailableRoundedListingFragmentDoc}
${FormSelectListingInputListingFragmentDoc}
${GetPricePerShareListingFragmentDoc}`;
export const PlaceBidSequenceModalPlaceBidCompanyFragmentDoc = gql`
    fragment PlaceBidSequenceModalPlaceBidCompany on Company {
  id
  activity {
    othersListings {
      id
      myPermissionsV2
      state
      bids {
        id
        ...PlaceBidSequenceModalPlaceBidBid
      }
      ...PlaceBidSequenceModalPlaceBidListing
    }
  }
  ...PlaceStandingBidModalCompany
}
    ${PlaceBidSequenceModalPlaceBidBidFragmentDoc}
${PlaceBidSequenceModalPlaceBidListingFragmentDoc}
${PlaceStandingBidModalCompanyFragmentDoc}`;
export const PlaceBidSequenceModalContentCompanyFragmentDoc = gql`
    fragment PlaceBidSequenceModalContentCompany on Company {
  id
  ...PlaceBidSequenceModalPlaceBidCompany
}
    ${PlaceBidSequenceModalPlaceBidCompanyFragmentDoc}`;
export const PlaceBidSequenceModalAuthorizationCompanyFragmentDoc = gql`
    fragment PlaceBidSequenceModalAuthorizationCompany on Company {
  id
  name
  ...GetSecuritySpecialistContactCompany
  ...GetAvailableCompanyActionsCompany
  ...PlaceBidSequenceModalContentCompany
}
    ${GetSecuritySpecialistContactCompanyFragmentDoc}
${GetAvailableCompanyActionsCompanyFragmentDoc}
${PlaceBidSequenceModalContentCompanyFragmentDoc}`;
export const PlaceBidSequenceModalCompanyFragmentDoc = gql`
    fragment PlaceBidSequenceModalCompany on Company {
  id
  ...PlaceBidSequenceModalAuthorizationCompany
}
    ${PlaceBidSequenceModalAuthorizationCompanyFragmentDoc}`;
export const CompanyActionsCompanyFragmentDoc = gql`
    fragment CompanyActionsCompany on Company {
  id
  requiresApprovedBuyers
  activity {
    othersListings {
      id
      state
      myPermissionsV2
      bids {
        id
        ...CompanyActionsBid
      }
    }
  }
  ...GetAvailableCompanyActionsCompany
  ...CreateListingModalCompany
  ...PlaceStandingBidModalCompany
  ...BrokerSubmitBidSequenceModalCompany
  ...PlaceBidSequenceModalCompany
}
    ${CompanyActionsBidFragmentDoc}
${GetAvailableCompanyActionsCompanyFragmentDoc}
${CreateListingModalCompanyFragmentDoc}
${PlaceStandingBidModalCompanyFragmentDoc}
${BrokerSubmitBidSequenceModalCompanyFragmentDoc}
${PlaceBidSequenceModalCompanyFragmentDoc}`;
export const CompanyPageYourActivityTransactionFragmentDoc = gql`
    fragment CompanyPageYourActivityTransaction on Transaction {
  id
  state
}
    `;
export const GetBidNumSharesActualBidFragmentDoc = gql`
    fragment GetBidNumSharesActualBid on Bid {
  id
  numShares
  numSharesActual
  actualNumSharesVisible
}
    `;
export const MakeUrlBidFragmentDoc = gql`
    fragment MakeUrlBid on Bid {
  id
  companyId
}
    `;
export const BrokerBidActivityCardTimestampsBidFragmentDoc = gql`
    fragment BrokerBidActivityCardTimestampsBid on Bid {
  id
  insertedAt
  placedAt
  expireAt
  state
}
    `;
export const BrokerCompanyBidCardBidFragmentDoc = gql`
    fragment BrokerCompanyBidCardBid on Bid {
  id
  displayId
  pricePerShare
  ...GetBidNumSharesActualBid
  ...MakeUrlBid
  ...BrokerBidActivityCardTimestampsBid
}
    ${GetBidNumSharesActualBidFragmentDoc}
${MakeUrlBidFragmentDoc}
${BrokerBidActivityCardTimestampsBidFragmentDoc}`;
export const CompanyYourBidCardBidFragmentDoc = gql`
    fragment CompanyYourBidCardBid on Bid {
  id
  pricePerShare
  expireAt
  displayId
  discussion {
    id
    numUnreadMessages
    totalNumMessages
  }
  ...GetBidNumSharesActualBid
  ...MakeUrlBid
}
    ${GetBidNumSharesActualBidFragmentDoc}
${MakeUrlBidFragmentDoc}`;
export const CompanyPageYourActivityBidFragmentDoc = gql`
    fragment CompanyPageYourActivityBid on Bid {
  id
  state
  buyerId
  transaction {
    id
    rofr
    ...CompanyPageYourActivityTransaction
  }
  ...BrokerCompanyBidCardBid
  ...CompanyYourBidCardBid
}
    ${CompanyPageYourActivityTransactionFragmentDoc}
${BrokerCompanyBidCardBidFragmentDoc}
${CompanyYourBidCardBidFragmentDoc}`;
export const CountTotalUnreadBidDiscussionMessagesBidFragmentDoc = gql`
    fragment CountTotalUnreadBidDiscussionMessagesBid on Bid {
  id
  state
  discussion {
    id
    numUnreadMessages
  }
}
    `;
export const CompanyOpenBidsBadgeBidFragmentDoc = gql`
    fragment CompanyOpenBidsBadgeBid on Bid {
  id
  buyerId
  state
  buyerInstitutionId
  ...CountTotalUnreadBidDiscussionMessagesBid
}
    ${CountTotalUnreadBidDiscussionMessagesBidFragmentDoc}`;
export const CompanyListingCardHeaderListingFragmentDoc = gql`
    fragment CompanyListingCardHeaderListing on Listing {
  id
  displayId
  fromHiive
}
    `;
export const ActivityCardListingNumShareAndPriceDetailsFragmentDoc = gql`
    fragment ActivityCardListingNumShareAndPriceDetails on Listing {
  id
  acceptedBidPrices
  acceptPartialBid
  listingPricePerShare
  numSharesAvailable
  numSharesAvailableRounded
  numSharesOriginal
  numSharesOriginalRounded
  numSharesSoldConditionally
  shareTypeV2
  state
  transferMethod
  solicited
}
    `;
export const CompanyListingCardBodyListingFragmentDoc = gql`
    fragment CompanyListingCardBodyListing on Listing {
  id
  transferMethod
  shareTypeV2
  solicited
  brokerId
  ...ActivityCardListingNumShareAndPriceDetails
}
    ${ActivityCardListingNumShareAndPriceDetailsFragmentDoc}`;
export const CompanyListingCardFooterListingFragmentDoc = gql`
    fragment CompanyListingCardFooterListing on Listing {
  insertedAt
}
    `;
export const CompanyYourActivityListingCardListingFragmentDoc = gql`
    fragment CompanyYourActivityListingCardListing on Listing {
  id
  bids {
    id
    ...CountTotalUnreadBidDiscussionMessagesBid
    ...CompanyOpenBidsBadgeBid
  }
  expireAt
  numActiveBids
  numCounteredBids
  numInquiries
  numUnreadMessages
  ...CompanyListingCardHeaderListing
  ...CompanyListingCardBodyListing
  ...CompanyListingCardFooterListing
}
    ${CountTotalUnreadBidDiscussionMessagesBidFragmentDoc}
${CompanyOpenBidsBadgeBidFragmentDoc}
${CompanyListingCardHeaderListingFragmentDoc}
${CompanyListingCardBodyListingFragmentDoc}
${CompanyListingCardFooterListingFragmentDoc}`;
export const CompanyPageYourActivityListingFragmentDoc = gql`
    fragment CompanyPageYourActivityListing on Listing {
  id
  state
  sellerId
  ...CompanyYourActivityListingCardListing
}
    ${CompanyYourActivityListingCardListingFragmentDoc}`;
export const CompanyStandingBidCardHeaderStandingBidFragmentDoc = gql`
    fragment CompanyStandingBidCardHeaderStandingBid on StandingBid {
  id
  displayId
  fromHiive
}
    `;
export const GetIsPartiallyAcceptedStandingBidStandingBidFragmentDoc = gql`
    fragment GetIsPartiallyAcceptedStandingBidStandingBid on StandingBid {
  state
  allowPartialAccept
  acceptedBidPrices
}
    `;
export const GetIsConditionallyCompletedStandingBidStandingBidFragmentDoc = gql`
    fragment GetIsConditionallyCompletedStandingBidStandingBid on StandingBid {
  state
}
    `;
export const ActivityCardStandingBidNumSharesAndPriceDetailsStandingBidFragmentDoc = gql`
    fragment ActivityCardStandingBidNumSharesAndPriceDetailsStandingBid on StandingBid {
  id
  pricePerShare
  numSharesOriginal
  numSharesAvailable
  acceptedBidPrices
  numSharesAccepted
  ...GetIsPartiallyAcceptedStandingBidStandingBid
  ...GetIsConditionallyCompletedStandingBidStandingBid
}
    ${GetIsPartiallyAcceptedStandingBidStandingBidFragmentDoc}
${GetIsConditionallyCompletedStandingBidStandingBidFragmentDoc}`;
export const CompanyStandingBidCardBodyStandingBidFragmentDoc = gql`
    fragment CompanyStandingBidCardBodyStandingBid on StandingBid {
  id
  acceptsShares
  acceptsTransferMethods
  solicited
  ...ActivityCardStandingBidNumSharesAndPriceDetailsStandingBid
}
    ${ActivityCardStandingBidNumSharesAndPriceDetailsStandingBidFragmentDoc}`;
export const CompanyStandingBidCardFooterStandingBidFragmentDoc = gql`
    fragment CompanyStandingBidCardFooterStandingBid on StandingBid {
  insertedAt
}
    `;
export const CompanyYourActivityStandingBidCardStandingBidFragmentDoc = gql`
    fragment CompanyYourActivityStandingBidCardStandingBid on StandingBid {
  id
  expireAt
  state
  acceptedBidPrices
  numDiscussions
  numUnreadMessages
  ...CompanyStandingBidCardHeaderStandingBid
  ...CompanyStandingBidCardBodyStandingBid
  ...CompanyStandingBidCardFooterStandingBid
}
    ${CompanyStandingBidCardHeaderStandingBidFragmentDoc}
${CompanyStandingBidCardBodyStandingBidFragmentDoc}
${CompanyStandingBidCardFooterStandingBidFragmentDoc}`;
export const CompanyPageYourActivityStandingBidFragmentDoc = gql`
    fragment CompanyPageYourActivityStandingBid on StandingBid {
  id
  state
  buyerId
  ...CompanyYourActivityStandingBidCardStandingBid
}
    ${CompanyYourActivityStandingBidCardStandingBidFragmentDoc}`;
export const GetPostingTitleListingFragmentDoc = gql`
    fragment GetPostingTitleListing on Listing {
  id
}
    `;
export const GetPostingTitleStandingBidFragmentDoc = gql`
    fragment GetPostingTitleStandingBid on StandingBid {
  id
}
    `;
export const GetDiscussionStatusTextDiscussionFragmentDoc = gql`
    fragment GetDiscussionStatusTextDiscussion on Discussion {
  id
  state
}
    `;
export const ActivityCardMostRecentMessageDiscussionFragmentDoc = gql`
    fragment ActivityCardMostRecentMessageDiscussion on Discussion {
  id
  mostRecentMessage {
    id
    messageBody
    ...GetIsSensitiveMessageDiscussionMessage
  }
  ...GetIsSensitiveMessageDiscussion
}
    ${GetIsSensitiveMessageDiscussionMessageFragmentDoc}
${GetIsSensitiveMessageDiscussionFragmentDoc}`;
export const CompanyYourDiscussionCardDiscussionFragmentDoc = gql`
    fragment CompanyYourDiscussionCardDiscussion on Discussion {
  id
  state
  displayId
  mostRecentMessage {
    id
  }
  numUnreadMessages
  totalNumMessages
  topic {
    ... on Listing {
      id
      displayId
      ...GetPostingTitleListing
    }
    ... on StandingBid {
      id
      displayId
      ...GetPostingTitleStandingBid
    }
  }
  ...GetDiscussionStatusTextDiscussion
  ...ActivityCardMostRecentMessageDiscussion
}
    ${GetPostingTitleListingFragmentDoc}
${GetPostingTitleStandingBidFragmentDoc}
${GetDiscussionStatusTextDiscussionFragmentDoc}
${ActivityCardMostRecentMessageDiscussionFragmentDoc}`;
export const CompanyPageYourActivityDiscussionFragmentDoc = gql`
    fragment CompanyPageYourActivityDiscussion on Discussion {
  id
  initiatorId
  ...CompanyYourDiscussionCardDiscussion
}
    ${CompanyYourDiscussionCardDiscussionFragmentDoc}`;
export const CompanyPageYourActivityCompanyFragmentDoc = gql`
    fragment CompanyPageYourActivityCompany on Company {
  id
  name
  activity {
    myBids {
      id
      expireAt
      ...CompanyPageYourActivityBid
    }
    myListings {
      id
      expireAt
      ...CompanyPageYourActivityListing
    }
    myStandingBids {
      id
      expireAt
      ...CompanyPageYourActivityStandingBid
    }
    myInstitutionBids {
      id
      expireAt
      ...CompanyPageYourActivityBid
    }
    myInstitutionListings {
      id
      expireAt
      ...CompanyPageYourActivityListing
    }
    myInstitutionStandingBids {
      id
      expireAt
      ...CompanyPageYourActivityStandingBid
    }
    myActiveDiscussions {
      id
      ...CompanyPageYourActivityDiscussion
    }
  }
}
    ${CompanyPageYourActivityBidFragmentDoc}
${CompanyPageYourActivityListingFragmentDoc}
${CompanyPageYourActivityStandingBidFragmentDoc}
${CompanyPageYourActivityDiscussionFragmentDoc}`;
export const GetShowListingSolicitedStateListingFragmentDoc = gql`
    fragment GetShowListingSolicitedStateListing on Listing {
  id
  solicited
  brokerId
}
    `;
export const ActivityCardListingCardFooterListingFragmentDoc = gql`
    fragment ActivityCardListingCardFooterListing on Listing {
  id
  acceptedBidPrices
  bids {
    id
    ...CompanyOpenBidsBadgeBid
  }
  expireAt
  transferMethod
  state
  numActiveBids
  numCounteredBids
  solicited
  insertedAt
  ...GetShowListingSolicitedStateListing
}
    ${CompanyOpenBidsBadgeBidFragmentDoc}
${GetShowListingSolicitedStateListingFragmentDoc}`;
export const CompanyMarketActivityListingCardListingFragmentDoc = gql`
    fragment CompanyMarketActivityListingCardListing on Listing {
  id
  ...CompanyListingCardHeaderListing
  ...CompanyListingCardBodyListing
  ...ActivityCardListingCardFooterListing
}
    ${CompanyListingCardHeaderListingFragmentDoc}
${CompanyListingCardBodyListingFragmentDoc}
${ActivityCardListingCardFooterListingFragmentDoc}`;
export const CompanyPageMarketActivityListingFragmentDoc = gql`
    fragment CompanyPageMarketActivityListing on Listing {
  id
  state
  sellerInstitutionId
  ...CompanyMarketActivityListingCardListing
}
    ${CompanyMarketActivityListingCardListingFragmentDoc}`;
export const ActivityCardStandingBidCardFooterStandingBidFragmentDoc = gql`
    fragment ActivityCardStandingBidCardFooterStandingBid on StandingBid {
  id
  expireAt
  state
  acceptedBidPrices
}
    `;
export const CompanyMarketActivityStandingBidCardStandingBidFragmentDoc = gql`
    fragment CompanyMarketActivityStandingBidCardStandingBid on StandingBid {
  id
  ...CompanyStandingBidCardHeaderStandingBid
  ...CompanyStandingBidCardBodyStandingBid
  ...ActivityCardStandingBidCardFooterStandingBid
}
    ${CompanyStandingBidCardHeaderStandingBidFragmentDoc}
${CompanyStandingBidCardBodyStandingBidFragmentDoc}
${ActivityCardStandingBidCardFooterStandingBidFragmentDoc}`;
export const CompanyPageMarketActivityStandingBidFragmentDoc = gql`
    fragment CompanyPageMarketActivityStandingBid on StandingBid {
  id
  state
  buyerInstitutionId
  ...CompanyMarketActivityStandingBidCardStandingBid
}
    ${CompanyMarketActivityStandingBidCardStandingBidFragmentDoc}`;
export const SolicitedListingActivityCardCompanyFragmentDoc = gql`
    fragment SolicitedListingActivityCardCompany on Company {
  id
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
}
    `;
export const CompanyListingCardBodyCompanyFragmentDoc = gql`
    fragment CompanyListingCardBodyCompany on Company {
  id
  ...SolicitedListingActivityCardCompany
}
    ${SolicitedListingActivityCardCompanyFragmentDoc}`;
export const CompanyMarketActivityListingCardCompanyFragmentDoc = gql`
    fragment CompanyMarketActivityListingCardCompany on Company {
  id
  ...CompanyListingCardBodyCompany
}
    ${CompanyListingCardBodyCompanyFragmentDoc}`;
export const CompanyPageMarketActivityCompanyFragmentDoc = gql`
    fragment CompanyPageMarketActivityCompany on Company {
  id
  name
  numHolders
  numWatchers
  activity {
    othersListings {
      id
      listingPricePerShare
      ...CompanyPageMarketActivityListing
    }
    othersStandingBids {
      id
      pricePerShare
      ...CompanyPageMarketActivityStandingBid
    }
  }
  ...GetAvailableCompanyActionsCompany
  ...CreateListingModalCompany
  ...PlaceStandingBidModalCompany
  ...CompanyMarketActivityListingCardCompany
}
    ${CompanyPageMarketActivityListingFragmentDoc}
${CompanyPageMarketActivityStandingBidFragmentDoc}
${GetAvailableCompanyActionsCompanyFragmentDoc}
${CreateListingModalCompanyFragmentDoc}
${PlaceStandingBidModalCompanyFragmentDoc}
${CompanyMarketActivityListingCardCompanyFragmentDoc}`;
export const CompanyActivityCompanyFeeDiscountApplicationFragmentDoc = gql`
    fragment CompanyActivityCompanyFeeDiscountApplication on FeeDiscountApplication {
  id
  feeDiscount {
    ...FeeDiscount
  }
}
    ${FeeDiscountFragmentDoc}`;
export const CompanyActivityCompanyFragmentDoc = gql`
    fragment CompanyActivityCompany on Company {
  id
  ...CompanyActionsCompany
  ...CompanyPageYourActivityCompany
  ...CompanyPageMarketActivityCompany
  feeDiscountApplications {
    ...CompanyActivityCompanyFeeDiscountApplication
  }
}
    ${CompanyActionsCompanyFragmentDoc}
${CompanyPageYourActivityCompanyFragmentDoc}
${CompanyPageMarketActivityCompanyFragmentDoc}
${CompanyActivityCompanyFeeDiscountApplicationFragmentDoc}`;
export const CompanyPricingCompanyFragmentDoc = gql`
    fragment CompanyPricingCompany on Company {
  id
  name
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
}
    `;
export const CompanyMarketActivityCompanyFragmentDoc = gql`
    fragment CompanyMarketActivityCompany on Company {
  id
  activity {
    numOpenListings
    numOpenStandingBidsAndActiveBids
  }
}
    `;
export const CompanyPageSuperadminDetailsTileCompanyFragmentDoc = gql`
    fragment CompanyPageSuperadminDetailsTileCompany on Company {
  id
  status
  tradeable
}
    `;
export const CompanySecuritySpecialistContactCompanyFragmentDoc = gql`
    fragment CompanySecuritySpecialistContactCompany on Company {
  id
  name
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
}
    `;
export const CompanyPageCompanyDescriptionCardCompanyFragmentDoc = gql`
    fragment CompanyPageCompanyDescriptionCardCompany on Company {
  id
  logoUrl
  name
  description
  activeOnHiiveFiftyIndex
  ...CompanyPricingCompany
  ...CompanyMarketActivityCompany
  ...CompanyPageSuperadminDetailsTileCompany
  ...CompanySecuritySpecialistContactCompany
}
    ${CompanyPricingCompanyFragmentDoc}
${CompanyMarketActivityCompanyFragmentDoc}
${CompanyPageSuperadminDetailsTileCompanyFragmentDoc}
${CompanySecuritySpecialistContactCompanyFragmentDoc}`;
export const CompanyLastRoundDetailsCardCompanyFragmentDoc = gql`
    fragment CompanyLastRoundDetailsCardCompany on Company {
  id
  lastRoundSeries
  lastRoundDate
  lastRoundPricePerShare
  lastRoundRaised
  totalRaised
  lastRoundPostValuation
  investors {
    id
    name
  }
  industries {
    id
    name
  }
}
    `;
export const CompanyPageInfoCompanyFragmentDoc = gql`
    fragment CompanyPageInfoCompany on Company {
  id
  hasAskPriceComparisonChart
  priceChartConfig {
    graphIndicators {
      acceptedBids
      lastRoundPps
      postedBidsAsks
    }
    priceLines {
      shareTypeCommon
      shareTypePreferred
      transferTypeDirect
      transferTypeIndirect
    }
    displayChart
    graphDataStart
    availableTimeRanges
  }
  ...CompanyPageCompanyDescriptionCardCompany
  ...CompanyLastRoundDetailsCardCompany
}
    ${CompanyPageCompanyDescriptionCardCompanyFragmentDoc}
${CompanyLastRoundDetailsCardCompanyFragmentDoc}`;
export const CompanyPageContentCompanyFeeDiscountApplicationFragmentDoc = gql`
    fragment CompanyPageContentCompanyFeeDiscountApplication on FeeDiscountApplication {
  id
  feeDiscount {
    ...FeeDiscount
  }
}
    ${FeeDiscountFragmentDoc}`;
export const CompanyPageContentCompanyFragmentDoc = gql`
    fragment CompanyPageContentCompany on Company {
  id
  name
  feeDiscountApplications {
    ...CompanyPageContentCompanyFeeDiscountApplication
  }
}
    ${CompanyPageContentCompanyFeeDiscountApplicationFragmentDoc}`;
export const UnaccreditedSellerCompanyPageActionsCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyPageActionsCompany on Company {
  id
  ...GetAvailableCompanyActionsCompany
}
    ${GetAvailableCompanyActionsCompanyFragmentDoc}`;
export const UnaccreditedSellerCompanyByIdActionsFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyByIdActions on Company {
  id
  activity {
    myListings {
      id
    }
  }
  ...GetAvailableCompanyActionsCompany
}
    ${GetAvailableCompanyActionsCompanyFragmentDoc}`;
export const UnaccreditedSellerCompanyPageDescriptionCardCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyPageDescriptionCardCompany on Company {
  id
  logoUrl
  name
  description
  ...CompanyPricingCompany
  ...CompanyMarketActivityCompany
  ...CompanySecuritySpecialistContactCompany
}
    ${CompanyPricingCompanyFragmentDoc}
${CompanyMarketActivityCompanyFragmentDoc}
${CompanySecuritySpecialistContactCompanyFragmentDoc}`;
export const UnaccreditedSellerCompanyPageMarketActivityListingFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyPageMarketActivityListing on Listing {
  id
  ...CompanyMarketActivityListingCardListing
}
    ${CompanyMarketActivityListingCardListingFragmentDoc}`;
export const UnaccreditedSellerCompanyPageMarketActivityStandingBidFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyPageMarketActivityStandingBid on StandingBid {
  id
  ...CompanyMarketActivityStandingBidCardStandingBid
}
    ${CompanyMarketActivityStandingBidCardStandingBidFragmentDoc}`;
export const UnaccreditedSellerCompanyPageMarketActivityCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyPageMarketActivityCompany on Company {
  id
  name
  numWatchers
  numHolders
  ...GetAvailableCompanyActionsCompany
  ...CreateListingModalCompany
  ...CompanyMarketActivityListingCardCompany
}
    ${GetAvailableCompanyActionsCompanyFragmentDoc}
${CreateListingModalCompanyFragmentDoc}
${CompanyMarketActivityListingCardCompanyFragmentDoc}`;
export const UnaccreditedSellerCompanyByIdMarketActivityListingFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyByIdMarketActivityListing on Listing {
  id
  ...CompanyMarketActivityListingCardListing
}
    ${CompanyMarketActivityListingCardListingFragmentDoc}`;
export const UnaccreditedSellerCompanyByIdMarketActivityStandingBidFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyByIdMarketActivityStandingBid on StandingBid {
  id
  ...CompanyMarketActivityStandingBidCardStandingBid
}
    ${CompanyMarketActivityStandingBidCardStandingBidFragmentDoc}`;
export const UnaccreditedSellerCompanyByIdMarketActivityCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyByIdMarketActivityCompany on Company {
  id
  name
  numWatchers
  numHolders
  tradeable
  activity {
    othersListings {
      id
      ...UnaccreditedSellerCompanyByIdMarketActivityListing
    }
    othersStandingBids {
      id
      ...UnaccreditedSellerCompanyByIdMarketActivityStandingBid
    }
  }
  ...GetAvailableCompanyActionsCompany
  ...CreateListingModalCompany
  ...CompanyMarketActivityListingCardCompany
}
    ${UnaccreditedSellerCompanyByIdMarketActivityListingFragmentDoc}
${UnaccreditedSellerCompanyByIdMarketActivityStandingBidFragmentDoc}
${GetAvailableCompanyActionsCompanyFragmentDoc}
${CreateListingModalCompanyFragmentDoc}
${CompanyMarketActivityListingCardCompanyFragmentDoc}`;
export const UnaccreditedSellerCompanyPageYourActivityListingFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyPageYourActivityListing on Listing {
  id
  ...CompanyYourActivityListingCardListing
}
    ${CompanyYourActivityListingCardListingFragmentDoc}`;
export const CompanyYourActivityListingCardCompanyFragmentDoc = gql`
    fragment CompanyYourActivityListingCardCompany on Company {
  id
  ...CompanyListingCardBodyCompany
}
    ${CompanyListingCardBodyCompanyFragmentDoc}`;
export const UnaccreditedSellerCompanyPageYourActivityCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyPageYourActivityCompany on Company {
  id
  ...CompanyYourActivityListingCardCompany
}
    ${CompanyYourActivityListingCardCompanyFragmentDoc}`;
export const UnaccreditedSellerCompanyByIdYourActivityListingFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyByIdYourActivityListing on Listing {
  id
  ...CompanyYourActivityListingCardListing
}
    ${CompanyYourActivityListingCardListingFragmentDoc}`;
export const UnaccreditedSellerCompanyByIdYourActivityCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyByIdYourActivityCompany on Company {
  id
  name
  activity {
    myListings {
      id
      ...UnaccreditedSellerCompanyByIdYourActivityListing
    }
  }
  ...CompanyYourActivityListingCardCompany
}
    ${UnaccreditedSellerCompanyByIdYourActivityListingFragmentDoc}
${CompanyYourActivityListingCardCompanyFragmentDoc}`;
export const UnaccreditedSellerCompanyPageFeeDiscountApplicationFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyPageFeeDiscountApplication on FeeDiscountApplication {
  id
  feeDiscount {
    ...FeeDiscount
  }
}
    ${FeeDiscountFragmentDoc}`;
export const UnaccreditedSellerCompanyPageCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyPageCompany on Company {
  id
  name
  feeDiscountApplications {
    ...UnaccreditedSellerCompanyPageFeeDiscountApplication
  }
}
    ${UnaccreditedSellerCompanyPageFeeDiscountApplicationFragmentDoc}`;
export const UnaccreditedSellerCompanyByIdPageCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyByIdPageCompany on Company {
  id
  name
  feeDiscountApplications {
    ...UnaccreditedSellerCompanyPageFeeDiscountApplication
  }
}
    ${UnaccreditedSellerCompanyPageFeeDiscountApplicationFragmentDoc}`;
export const UnaccreditedSellerCompanyByIdPageFeeDiscountApplicationFragmentDoc = gql`
    fragment UnaccreditedSellerCompanyByIdPageFeeDiscountApplication on FeeDiscountApplication {
  id
  feeDiscount {
    ...FeeDiscount
  }
}
    ${FeeDiscountFragmentDoc}`;
export const CompanyComboboxCompanyFragmentDoc = gql`
    fragment CompanyComboboxCompany on Company {
  id
  name
  logoUrl
}
    `;
export const CompanyMarketActivityBuyersBadgeCompanyFragmentDoc = gql`
    fragment CompanyMarketActivityBuyersBadgeCompany on Company {
  id
  name
  numWatchers
}
    `;
export const CompanyMarketActivitySellersBadgeCompanyFragmentDoc = gql`
    fragment CompanyMarketActivitySellersBadgeCompany on Company {
  id
  name
  numHolders
  numWatchers
}
    `;
export const PriceDataFragmentDoc = gql`
    fragment PriceData on DailyPriceData {
  day
  indexPrice @include(if: $includeIndexPrice)
  indexPriceTransferTypeDirect @include(if: $includeIndexPriceTransferTypeDirect)
  indexPriceTransferTypeIndirect @include(if: $includeIndexPriceTransferTypeIndirect)
  indexPriceShareTypeCommon @include(if: $includeIndexPriceShareTypeCommon)
  indexPriceShareTypePreferred @include(if: $includeIndexPriceShareTypePreferred)
  acceptedBids @include(if: $includeAcceptedBids)
  acceptedBidsTransferTypeDirect @include(if: $includeAcceptedBidsTransferTypeDirect)
  acceptedBidsTransferTypeIndirect @include(if: $includeAcceptedBidsTransferTypeIndirect)
  acceptedBidsShareTypeCommon @include(if: $includeAcceptedBidsShareTypeCommon)
  acceptedBidsShareTypePreferred @include(if: $includeAcceptedBidsShareTypePreferred)
  postedAsks @include(if: $includePostedBidsAsks)
  postedAsksTransferTypeDirect @include(if: $includePostedBidsAsksTransferTypeDirect)
  postedAsksTransferTypeIndirect @include(if: $includePostedBidsAsksTransferTypeIndirect)
  postedAsksShareTypeCommon @include(if: $includePostedBidsAsksShareTypeCommon)
  postedAsksShareTypePreferred @include(if: $includePostedBidsAsksShareTypePreferred)
  postedBids @include(if: $includePostedBidsAsks)
  postedBidsTransferTypeDirect @include(if: $includePostedBidsAsksTransferTypeDirect)
  postedBidsTransferTypeIndirect @include(if: $includePostedBidsAsksTransferTypeIndirect)
  postedBidsShareTypeCommon @include(if: $includePostedBidsAsksShareTypeCommon)
  postedBidsShareTypePreferred @include(if: $includePostedBidsAsksShareTypePreferred)
  lastRoundPrice @include(if: $includeLastRoundPrice)
}
    `;
export const PriceTrendFragmentDoc = gql`
    fragment PriceTrend on IndexPriceTrend {
  changePercentage
  currentPrice
  trendName
}
    `;
export const CompanyStandingBidCardBodyCompanyFragmentDoc = gql`
    fragment CompanyStandingBidCardBodyCompany on Company {
  id
  ...SolicitedListingActivityCardCompany
}
    ${SolicitedListingActivityCardCompanyFragmentDoc}`;
export const CompanyYourActivityStandingBidCardCompanyFragmentDoc = gql`
    fragment CompanyYourActivityStandingBidCardCompany on Company {
  id
  ...CompanyListingCardBodyCompany
}
    ${CompanyListingCardBodyCompanyFragmentDoc}`;
export const MarketActivityBlockCompanyActivityFragmentDoc = gql`
    fragment MarketActivityBlockCompanyActivity on CompanyActivity {
  numOpenListings
  numOpenStandingBidsAndActiveBids
}
    `;
export const MarketActivityBlockCompanyFragmentDoc = gql`
    fragment MarketActivityBlockCompany on Company {
  activity {
    ...MarketActivityBlockCompanyActivity
  }
}
    ${MarketActivityBlockCompanyActivityFragmentDoc}`;
export const PricingBlockCompanyFragmentDoc = gql`
    fragment PricingBlockCompany on Company {
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
}
    `;
export const CompanyStandingBidCardTransferDetailsStandingBidFragmentDoc = gql`
    fragment CompanyStandingBidCardTransferDetailsStandingBid on StandingBid {
  acceptsShares
  acceptsTransferMethods
}
    `;
export const UserActivityCompanyFragmentDoc = gql`
    fragment UserActivityCompany on Company {
  id
  name
  logoUrl
}
    `;
export const UserActivityBidOnListingFragmentDoc = gql`
    fragment UserActivityBidOnListing on Bid {
  id
  buyerId
  buyerInstitutionId
  state
  discussion {
    id
    numUnreadMessages
  }
}
    `;
export const UserActivityListingFragmentDoc = gql`
    fragment UserActivityListing on Listing {
  id
  acceptedBidPrices
  acceptPartialBid
  shareTypeV2
  companyId
  sellerId
  shortId
  displayId
  state
  fromHiive
  transferMethod
  numUnreadMessages
  numDiscussions
  numActiveBids
  numCounteredBids
  expireAt
  insertedAt
  solicited
  company {
    ...UserActivityCompany
  }
  bids {
    ...UserActivityBidOnListing
  }
  ...GetNumOfSharesListing
  ...GetPricePerShareListing
  ...ActivityCardListingCardFooterListing
}
    ${UserActivityCompanyFragmentDoc}
${UserActivityBidOnListingFragmentDoc}
${GetNumOfSharesListingFragmentDoc}
${GetPricePerShareListingFragmentDoc}
${ActivityCardListingCardFooterListingFragmentDoc}`;
export const GetNumOfSharesStandingBidFragmentDoc = gql`
    fragment GetNumOfSharesStandingBid on StandingBid {
  state
  numSharesAvailable
  numSharesOriginal
  version
}
    `;
export const GetPricePerShareStandingBidFragmentDoc = gql`
    fragment GetPricePerShareStandingBid on StandingBid {
  id
  pricePerShare
}
    `;
export const UserActivityStandingBidFragmentDoc = gql`
    fragment UserActivityStandingBid on StandingBid {
  id
  acceptedBidPrices
  acceptsShares
  allowPartialAccept
  acceptsTransferMethods
  numSharesAccepted
  companyId
  state
  shortId
  displayId
  numUnreadMessages
  numDiscussions
  buyerId
  fromHiive
  expireAt
  insertedAt
  company {
    id
    ...UserActivityCompany
  }
  ...GetNumOfSharesStandingBid
  ...GetPricePerShareStandingBid
}
    ${UserActivityCompanyFragmentDoc}
${GetNumOfSharesStandingBidFragmentDoc}
${GetPricePerShareStandingBidFragmentDoc}`;
export const GetNumOfSharesBidFragmentDoc = gql`
    fragment GetNumOfSharesBid on Bid {
  numShares
  numSharesActual
  actualNumSharesVisible
  counterNumShares
}
    `;
export const GetPricePerShareBidFragmentDoc = gql`
    fragment GetPricePerShareBid on Bid {
  id
  pricePerShare
  counterPricePerShare
}
    `;
export const UserActivityBidFragmentDoc = gql`
    fragment UserActivityBid on Bid {
  id
  companyId
  buyerId
  brokerId
  buyerInstitutionId
  shortId
  displayId
  counterpartyId
  brokerCounterpartyId
  state
  insertedAt
  expireAt
  company {
    ...UserActivityCompany
  }
  discussion {
    totalNumMessages
    numUnreadMessages
  }
  standingBid {
    id
    shortId
    displayId
  }
  listing {
    id
    shortId
  }
  ...GetNumOfSharesBid
  ...GetPricePerShareBid
  ...BrokerBidActivityCardTimestampsBid
  ...MakeUrlBid
}
    ${UserActivityCompanyFragmentDoc}
${GetNumOfSharesBidFragmentDoc}
${GetPricePerShareBidFragmentDoc}
${BrokerBidActivityCardTimestampsBidFragmentDoc}
${MakeUrlBidFragmentDoc}`;
export const UserActivityTransactionDocumentFragmentDoc = gql`
    fragment UserActivityTransactionDocument on Document {
  id
  type
  signers {
    id
    userId
    institutionId
  }
}
    `;
export const UserActivityTransactionFragmentDoc = gql`
    fragment UserActivityTransaction on Transaction {
  id
  state
  signingProcedure
  rofr
  numShares
  pricePerShare
  buyerId
  sellerId
  buyerInstitutionId
  sellerInstitutionId
  document {
    id
    ...UserActivityTransactionDocument
  }
  bid {
    id
    ...UserActivityBid
  }
  ...MakeUrlTransaction
}
    ${UserActivityTransactionDocumentFragmentDoc}
${UserActivityBidFragmentDoc}
${MakeUrlTransactionFragmentDoc}`;
export const UserActivityModificationAcknowledgmentsFragmentDoc = gql`
    fragment UserActivityModificationAcknowledgments on TransactionModificationAcknowledgment {
  id
  transactionModification {
    id
    transaction {
      id
      bidId
      buyerId
      buyerBrokerId
      company {
        id
        name
      }
    }
  }
}
    `;
export const UserActivityActivityFragmentDoc = gql`
    fragment UserActivityActivity on UserActivity {
  companies {
    ...UserActivityCompany
  }
  myListings {
    ...UserActivityListing
  }
  myStandingBids {
    ...UserActivityStandingBid
  }
  myBids {
    ...UserActivityBid
  }
  myInstitutionListings {
    ...UserActivityListing
  }
  myInstitutionStandingBids {
    ...UserActivityStandingBid
  }
  myInstitutionBids {
    ...UserActivityBid
  }
  myTransactions {
    ...UserActivityTransaction
  }
  myInstitutionTransactions {
    ...UserActivityTransaction
  }
  myPendingTransactionModificationAcknowledgments {
    ...UserActivityModificationAcknowledgments
  }
}
    ${UserActivityCompanyFragmentDoc}
${UserActivityListingFragmentDoc}
${UserActivityStandingBidFragmentDoc}
${UserActivityBidFragmentDoc}
${UserActivityTransactionFragmentDoc}
${UserActivityModificationAcknowledgmentsFragmentDoc}`;
export const CompanyWatchlistCompanyFragmentDoc = gql`
    fragment CompanyWatchlistCompany on Company {
  id
  name
  logoUrl
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
}
    `;
export const CompanyPreviewFragmentDoc = gql`
    fragment CompanyPreview on Company {
  id
  name
}
    `;
export const IndexCompanyPreviewEdgeFragmentDoc = gql`
    fragment IndexCompanyPreviewEdge on IndexCompanyEdge {
  node {
    id
    company {
      ...CompanyPreview
    }
    todaysPriceGraph {
      indexPrice
      priceChangeNinetyDays
    }
  }
}
    ${CompanyPreviewFragmentDoc}`;
export const MakeUrlStandingBidFragmentDoc = gql`
    fragment MakeUrlStandingBid on StandingBid {
  id
  companyId
}
    `;
export const LatestActivityListStandingBidFragmentDoc = gql`
    fragment LatestActivityListStandingBid on StandingBid {
  id
  company {
    id
    name
  }
  acceptedBidPrices
  acceptsShares
  acceptsTransferMethods
  allowPartialAccept
  numSharesOriginal
  numSharesAvailable
  numSharesAccepted
  pricePerShare
  state
  version
  placedAt
  updatedAt
  insertedAt
  solicited
  ...MakeUrlStandingBid
}
    ${MakeUrlStandingBidFragmentDoc}`;
export const LatestActivityListListingFragmentDoc = gql`
    fragment LatestActivityListListing on Listing {
  id
  company {
    id
    name
    securitySpecialist {
      id
      user {
        id
        email
      }
    }
  }
  acceptedBidPrices
  acceptPartialBid
  listingPricePerShare
  solicited
  numSharesAvailable
  numSharesAvailableRounded
  numSharesOriginal
  numSharesOriginalRounded
  numSharesSoldConditionally
  shareTypeV2
  state
  transferMethod
  sellerId
  brokerId
  actualNumSharesVisible
  sellerInstitutionId
  updatedAt
  insertedAt
  placedAt
}
    `;
export const SignDocumentAlertMessagesTransactionFragmentDoc = gql`
    fragment SignDocumentAlertMessagesTransaction on Transaction {
  id
  expireAt
  state
  signingProcedure
  myPermissionsV2
  bid {
    id
    buyerId
    buyerInstitutionId
    companyId
    company {
      id
      name
    }
  }
  document {
    id
    type
    signers {
      id
      userId
      institutionId
    }
  }
  ...MakeUrlTransaction
}
    ${MakeUrlTransactionFragmentDoc}`;
export const WatchlistCompaniesSearchCompanyFragmentDoc = gql`
    fragment WatchlistCompaniesSearchCompany on Company {
  id
  name
  logoUrl
  watchlisted
}
    `;
export const WatchlistCompaniesSearchCompanyEdgeFragmentDoc = gql`
    fragment WatchlistCompaniesSearchCompanyEdge on CompanyEdge {
  node {
    ...WatchlistCompaniesSearchCompany
  }
  cursor
}
    ${WatchlistCompaniesSearchCompanyFragmentDoc}`;
export const DefaultUnaccreditedSellerDashboardPageCompanyOverviewCardCompanyFragmentDoc = gql`
    fragment DefaultUnaccreditedSellerDashboardPageCompanyOverviewCardCompany on Company {
  id
  priceChartConfig {
    graphIndicators {
      acceptedBids
      lastRoundPps
      postedBidsAsks
    }
    priceLines {
      shareTypeCommon
      shareTypePreferred
      transferTypeDirect
      transferTypeIndirect
    }
    displayChart
    graphDataStart
    availableTimeRanges
  }
  logoUrl
  name
  lastRoundRaised
  lastRoundPostValuation
  lastRoundPricePerShare
  totalRaised
  lastRoundSeries
  lastRoundDate
  investors {
    id
    name
  }
  industries {
    id
    name
  }
  activity {
    numOpenListings
    numOpenStandingBidsAndActiveBids
  }
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
}
    `;
export const DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockStandingBidFragmentDoc = gql`
    fragment DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockStandingBid on StandingBid {
  id
  displayId
  pricePerShare
  numSharesAvailable
  numSharesOriginal
  state
  version
  companyId
  insertedAt
  expireAt
  fromHiive
  acceptsShares
  acceptsTransferMethods
}
    `;
export const DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockCompanyFragmentDoc = gql`
    fragment DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockCompany on Company {
  id
}
    `;
export const UnaccreditedSellerDashboardByIdMarketActivityStandingBidFragmentDoc = gql`
    fragment UnaccreditedSellerDashboardByIdMarketActivityStandingBid on StandingBid {
  id
}
    `;
export const UnaccreditedSellerDashboardByIdMarketActivityCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerDashboardByIdMarketActivityCompany on Company {
  id
  activity {
    othersStandingBids {
      id
      ...UnaccreditedSellerDashboardByIdMarketActivityStandingBid
    }
  }
}
    ${UnaccreditedSellerDashboardByIdMarketActivityStandingBidFragmentDoc}`;
export const DefaultUnaccreditedSellerDashboardPageMyCompanyFeeDiscountApplicationFragmentDoc = gql`
    fragment DefaultUnaccreditedSellerDashboardPageMyCompanyFeeDiscountApplication on FeeDiscountApplication {
  id
  feeDiscount {
    ...FeeDiscount
  }
}
    ${FeeDiscountFragmentDoc}`;
export const DefaultUnaccreditedSellerDashboardPageMyCompanyFragmentDoc = gql`
    fragment DefaultUnaccreditedSellerDashboardPageMyCompany on Company {
  id
  name
  logoUrl
  myPermissionsV2
  lastRoundSeries
  lastRoundDate
  lastRoundPricePerShare
  lastRoundRaised
  totalRaised
  lastRoundPostValuation
  description
  priceChartConfig {
    graphIndicators {
      acceptedBids
      lastRoundPps
      postedBidsAsks
    }
    priceLines {
      shareTypeCommon
      shareTypePreferred
      transferTypeDirect
      transferTypeIndirect
    }
    displayChart
    graphDataStart
    availableTimeRanges
  }
  status
  tradeable
  investors {
    id
    name
  }
  industries {
    id
    name
  }
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
  activity {
    numOpenListings
    numOpenStandingBidsAndActiveBids
  }
  feeDiscountApplications {
    ...DefaultUnaccreditedSellerDashboardPageMyCompanyFeeDiscountApplication
  }
}
    ${DefaultUnaccreditedSellerDashboardPageMyCompanyFeeDiscountApplicationFragmentDoc}`;
export const UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFeeDiscountApplicationFragmentDoc = gql`
    fragment UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFeeDiscountApplication on FeeDiscountApplication {
  id
  feeDiscount {
    ...FeeDiscount
  }
}
    ${FeeDiscountFragmentDoc}`;
export const UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerDashboardPagePreListingHeroBannerCompany on Company {
  id
  name
  feeDiscountApplications {
    ...UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFeeDiscountApplication
  }
}
    ${UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFeeDiscountApplicationFragmentDoc}`;
export const DefaultUnaccreditedSellerDashboardPageMyListingFragmentDoc = gql`
    fragment DefaultUnaccreditedSellerDashboardPageMyListing on Listing {
  id
  companyId
  displayId
  listingPricePerShare
  numSharesAvailable
  state
  transferMethod
  myPermissionsV2
}
    `;
export const DefaultUnaccreditedSellerDashboardPageMyActivityFragmentDoc = gql`
    fragment DefaultUnaccreditedSellerDashboardPageMyActivity on UnaccreditedSellerActivity {
  myCompany {
    ...DefaultUnaccreditedSellerDashboardPageMyCompany
    ...UnaccreditedSellerDashboardPagePreListingHeroBannerCompany
  }
  myListing {
    ...DefaultUnaccreditedSellerDashboardPageMyListing
  }
  pendingSales {
    id
  }
  completedSales {
    id
  }
}
    ${DefaultUnaccreditedSellerDashboardPageMyCompanyFragmentDoc}
${UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFragmentDoc}
${DefaultUnaccreditedSellerDashboardPageMyListingFragmentDoc}`;
export const UnaccreditedSellerDashboardByIdPageFeeDiscountApplicationFragmentDoc = gql`
    fragment UnaccreditedSellerDashboardByIdPageFeeDiscountApplication on FeeDiscountApplication {
  id
  feeDiscount {
    ...FeeDiscount
  }
}
    ${FeeDiscountFragmentDoc}`;
export const UnaccreditedSellerDashboardByIdPageCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerDashboardByIdPageCompany on Company {
  id
  name
  logoUrl
  myPermissionsV2
  lastRoundSeries
  lastRoundDate
  lastRoundPricePerShare
  lastRoundRaised
  totalRaised
  lastRoundPostValuation
  description
  priceChartConfig {
    graphIndicators {
      acceptedBids
      lastRoundPps
      postedBidsAsks
    }
    priceLines {
      shareTypeCommon
      shareTypePreferred
      transferTypeDirect
      transferTypeIndirect
    }
    displayChart
    graphDataStart
    availableTimeRanges
  }
  status
  tradeable
  investors {
    id
    name
  }
  industries {
    id
    name
  }
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
  activity {
    numOpenListings
    numOpenStandingBidsAndActiveBids
    myListings {
      id
    }
  }
  feeDiscountApplications {
    ...UnaccreditedSellerDashboardByIdPageFeeDiscountApplication
  }
}
    ${UnaccreditedSellerDashboardByIdPageFeeDiscountApplicationFragmentDoc}`;
export const PostActivityUnaccreditedSellerDashboardPageActiveBidsPageBidFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageActiveBidsPageBid on Bid {
  id
  state
  buyerId
  companyId
  counterpartyId
  counterpartyInstitutionId
  placedAt
  insertedAt
  expireAt
  buyerInstitutionId
  pricePerShare
  numShares
  numSharesActual
  actualNumSharesVisible
  displayId
  discussion {
    id
    totalNumMessages
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListing on Listing {
  acceptedBidPrices
  acceptPartialBid
  id
  companyId
  displayId
  listingPricePerShare
  numSharesAvailable
  numSharesAvailableRounded
  numSharesOriginal
  numSharesOriginalRounded
  state
  solicited
  transferMethod
  myPermissionsV2
  otherDetails
  company {
    id
    securitySpecialist {
      id
      user {
        id
        email
      }
      marketAnalyst {
        id
        email
      }
    }
  }
  commission {
    flatFeeAmount
    commissionAmount
    feeDiscountAmount
    netFees
  }
  feeDiscountApplications {
    id
    state
    initial
    feeDiscount {
      ...FeeDiscount
    }
    feeDiscountApplicationCommission {
      id
      amount
    }
  }
}
    ${FeeDiscountFragmentDoc}`;
export const PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBid on Bid {
  id
  state
  buyerId
  companyId
  counterpartyId
  counterpartyInstitutionId
  placedAt
  insertedAt
  expireAt
  buyerInstitutionId
  pricePerShare
  numShares
  numSharesActual
  actualNumSharesVisible
  displayId
  discussion {
    id
    totalNumMessages
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListing on Listing {
  acceptedBidPrices
  acceptPartialBid
  id
  companyId
  displayId
  listingPricePerShare
  numSharesAvailable
  numSharesAvailableRounded
  numSharesOriginal
  numSharesOriginalRounded
  state
  solicited
  transferMethod
  myPermissionsV2
  otherDetails
  bids {
    ...PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBid
  }
  company {
    id
    securitySpecialist {
      id
      user {
        id
        email
      }
      marketAnalyst {
        id
        email
      }
    }
  }
  commission {
    flatFeeAmount
    commissionAmount
    feeDiscountAmount
    netFees
  }
  feeDiscountApplications {
    id
    state
    initial
    feeDiscount {
      ...FeeDiscount
    }
    feeDiscountApplicationCommission {
      id
      amount
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragmentDoc}
${FeeDiscountFragmentDoc}`;
export const PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageCompanyFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageCompany on Company {
  id
  name
  activity {
    myListings {
      ...PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListing
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragmentDoc}`;
export const PostActivityUnaccreditedSellerDashboardPageInquiriesPageDiscussionFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageInquiriesPageDiscussion on Discussion {
  id
  displayId
  state
  numUnreadMessages
  totalNumMessages
  mostRecentMessage {
    id
    senderId
    messageBody
    sender {
      id
      institutionId
    }
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDiscussionFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDiscussion on Discussion {
  id
  displayId
  state
  numUnreadMessages
  totalNumMessages
  mostRecentMessage {
    id
    senderId
    messageBody
    sender {
      id
      institutionId
    }
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardPageOverviewPageCompanyFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageOverviewPageCompany on Company {
  id
  name
  logoUrl
  myPermissionsV2
  lastRoundSeries
  lastRoundDate
  lastRoundPricePerShare
  lastRoundRaised
  totalRaised
  lastRoundPostValuation
  description
  priceChartConfig {
    graphIndicators {
      acceptedBids
      lastRoundPps
      postedBidsAsks
    }
    priceLines {
      shareTypeCommon
      shareTypePreferred
      transferTypeDirect
      transferTypeIndirect
    }
    displayChart
    graphDataStart
    availableTimeRanges
  }
  status
  tradeable
  investors {
    id
    name
  }
  industries {
    id
    name
  }
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
  activity {
    numOpenListings
    numOpenStandingBidsAndActiveBids
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardPagePastBidsPageBidFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPagePastBidsPageBid on Bid {
  id
  displayId
  state
  placedAt
  insertedAt
  expireAt
  withdrawnAt
  pricePerShare
  numShares
  numSharesActual
  actualNumSharesVisible
  fromHiive
  companyId
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBidFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBid on Bid {
  id
  displayId
  state
  placedAt
  insertedAt
  expireAt
  withdrawnAt
  pricePerShare
  numShares
  numSharesActual
  actualNumSharesVisible
  fromHiive
  companyId
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageCompanyFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageCompany on Company {
  id
  name
  activity {
    myListings {
      id
      bids {
        ...PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBid
      }
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBidFragmentDoc}`;
export const PostActivityUnaccreditedSellerDashboardPageStandingBidsPageListingFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageStandingBidsPageListing on Listing {
  id
}
    `;
export const PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBid on StandingBid {
  id
  displayId
  pricePerShare
  numSharesAvailable
  numSharesOriginal
  state
  version
  companyId
  placedAt
  insertedAt
  expireAt
  fromHiive
  acceptsShares
  acceptsTransferMethods
}
    `;
export const PostActivityUnaccreditedSellerDashboardPageStandingBidsPageCompanyFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageStandingBidsPageCompany on Company {
  name
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageListingFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageListing on Listing {
  id
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBid on StandingBid {
  id
  displayId
  pricePerShare
  numSharesAvailable
  numSharesOriginal
  state
  version
  companyId
  placedAt
  insertedAt
  expireAt
  fromHiive
  acceptsShares
  acceptsTransferMethods
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompanyFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompany on Company {
  id
  name
  activity {
    myListings {
      ...PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageListing
    }
    myCompletedTransactions {
      id
    }
    myOpenTransactions {
      id
    }
    standingBidsWithDiscussions {
      ...PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBid
    }
    availableStandingBids {
      ...PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBid
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageListingFragmentDoc}
${PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageStandingBidFragmentDoc}`;
export const PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionDocumentFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionDocument on Document {
  id
  type
  signers {
    id
    userId
    institutionId
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionBidFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionBid on Bid {
  id
  buyerId
  buyerInstitutionId
  displayId
  numShares
  numSharesActual
  actualNumSharesVisible
  pricePerShare
  state
  discussion {
    id
    totalNumMessages
  }
  company {
    id
    name
    logoUrl
  }
  standingBid {
    id
    displayId
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransaction on Transaction {
  id
  state
  signingProcedure
  myPermissionsV2
  expireAt
  numShares
  pricePerShare
  document {
    ...PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionDocument
  }
  bid {
    ...PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionBid
  }
  ...MakeUrlTransaction
}
    ${PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionDocumentFragmentDoc}
${PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionBidFragmentDoc}
${MakeUrlTransactionFragmentDoc}`;
export const PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionDocumentFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionDocument on Document {
  id
  type
  signers {
    id
    userId
    institutionId
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionBidFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionBid on Bid {
  id
  buyerId
  buyerInstitutionId
  displayId
  numShares
  numSharesActual
  actualNumSharesVisible
  pricePerShare
  state
  discussion {
    id
    totalNumMessages
  }
  company {
    id
    name
    logoUrl
  }
  standingBid {
    id
    displayId
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransaction on Transaction {
  id
  state
  signingProcedure
  myPermissionsV2
  expireAt
  numShares
  pricePerShare
  document {
    ...PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionDocument
  }
  bid {
    ...PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionBid
  }
  ...MakeUrlTransaction
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionDocumentFragmentDoc}
${PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionBidFragmentDoc}
${MakeUrlTransactionFragmentDoc}`;
export const PostActivityUnaccreditedSellerDashboardByIdPageTransactionPageCompanyFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageTransactionPageCompany on Company {
  id
  name
  activity {
    myListings {
      id
    }
    myCompletedTransactions {
      ...PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransaction
    }
    myOpenTransactions {
      ...PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransaction
    }
    standingBidsWithDiscussions {
      id
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragmentDoc}`;
export const PostActivityUnaccreditedSellerDashboardPageHeroBannerCompanyFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageHeroBannerCompany on Company {
  id
  name
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerCompanyFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerCompany on Company {
  id
  name
  activity {
    myListings {
      id
    }
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardByIdPageSidebarCompanyFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardByIdPageSidebarCompany on Company {
  id
  name
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
  activity {
    myListings {
      id
    }
  }
}
    `;
export const PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesCompanyFragmentDoc = gql`
    fragment PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesCompany on Company {
  name
  id
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
}
    `;
export const UnaccreditedSellerInformativeArticlesCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerInformativeArticlesCompany on Company {
  id
  name
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
}
    `;
export const FormCompaniesComboboxCompanyFragmentDoc = gql`
    fragment FormCompaniesComboboxCompany on Company {
  id
  name
  logoUrl
}
    `;
export const BasicInvestmentGoalQuestionFragmentDoc = gql`
    fragment BasicInvestmentGoalQuestion on InvestmentGoalQuestion {
  description
  id
  order
  text
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  id
  logoUrl
  name
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
}
    `;
export const IndexCompanyEdgeFragmentDoc = gql`
    fragment IndexCompanyEdge on IndexCompanyEdge {
  node {
    id
    sortedIndexPrices
    company {
      ...Company
    }
    todaysPriceGraph {
      priceChangeNinetyDays
    }
  }
}
    ${CompanyFragmentDoc}`;
export const IndexPriceFragmentDoc = gql`
    fragment IndexPrice on Hiive50IndexPrice {
  priceDay
  avg
}
    `;
export const IndexPriceTrendResponseFragmentDoc = gql`
    fragment IndexPriceTrendResponse on Hiive50DailyIndexPriceResponse {
  prices {
    ...IndexPrice
  }
  currentPrice
  percentageChange
}
    ${IndexPriceFragmentDoc}`;
export const SignupConfigFragmentDoc = gql`
    fragment SignupConfig on Config {
  requireSignupKey
}
    `;
export const RootConfigFragmentDoc = gql`
    fragment RootConfig on Config {
  stnLoiHoursValidHours
  ...SignupConfig
}
    ${SignupConfigFragmentDoc}`;
export const BidCancellationWarningBidFragmentDoc = gql`
    fragment BidCancellationWarningBid on Bid {
  id
  state
  numSharesActual
}
    `;
export const ListingFeeBreakdownCommissionInfoFragmentDoc = gql`
    fragment ListingFeeBreakdownCommissionInfo on CommissionInfo {
  totalValue
  netValue
  flatFeeAmount
  commissionAmount
  netFees
}
    `;
export const ListingFeeDiscountInfoFragmentDoc = gql`
    fragment ListingFeeDiscountInfo on CommissionInfo {
  feeDiscountAmount
  feeDiscountApplications {
    id
    initial
    state
    feeDiscountApplicationCommission {
      id
      amount
    }
    feeDiscount {
      id
      name
      type
      value
    }
  }
}
    `;
export const AskPriceComparisonChartCompanyFragmentDoc = gql`
    fragment AskPriceComparisonChartCompany on Company {
  lastRoundPricePerShare
  hasAskPriceComparisonChart
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
}
    `;
export const SharePriceCardCompanyFragmentDoc = gql`
    fragment SharePriceCardCompany on Company {
  id
  name
  lastRoundPricePerShare
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
  ...AskPriceComparisonChartCompany
}
    ${AskPriceComparisonChartCompanyFragmentDoc}`;
export const UnaccreditedSellerCreateListingPageListingFragmentDoc = gql`
    fragment UnaccreditedSellerCreateListingPageListing on Listing {
  id
  numSharesOriginal
  numSharesOriginalRounded
  state
  numSharesAvailable
  numSharesAvailableRounded
  actualNumSharesVisible
  shareSeriesMakeup {
    id
    shareSeries
  }
  company {
    id
    name
    securitySpecialist {
      id
      user {
        id
        email
      }
      marketAnalyst {
        id
        email
      }
    }
  }
  listingPricePerShare
}
    `;
export const UnaccreditedSellerCreateListingPageByIdCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerCreateListingPageByIdCompany on Company {
  id
  name
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
  activity {
    myListings {
      id
    }
  }
  lastRoundPricePerShare
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
  companyEligibilityCriterion {
    id
    text
  }
  hasActiveInformationDisclosure
  requiresInformationDisclosure
  ...AskPriceComparisonChartCompany
}
    ${AskPriceComparisonChartCompanyFragmentDoc}`;
export const UnaccreditedSellerModifyListingPageBidFragmentDoc = gql`
    fragment UnaccreditedSellerModifyListingPageBid on Bid {
  id
  state
  numSharesActual
  counterNumShares
}
    `;
export const NumShareAndPriceDetailsListingFragmentDoc = gql`
    fragment NumShareAndPriceDetailsListing on Listing {
  id
  acceptedBidPrices
  acceptPartialBid
  listingPricePerShare
  numSharesAvailable
  numSharesAvailableRounded
  numSharesOriginal
  numSharesOriginalRounded
  state
  solicited
}
    `;
export const UnaccreditedSellerModifyListingPageListingFragmentDoc = gql`
    fragment UnaccreditedSellerModifyListingPageListing on Listing {
  id
  otherDetails
  listingPricePerShare
  transferMethod
  numSharesSoldConditionally
  acceptedBidPrices
  solicited
  shareSeriesMakeup {
    id
    shareSeries
  }
  bids {
    ...UnaccreditedSellerModifyListingPageBid
  }
  ...NumShareAndPriceDetailsListing
}
    ${UnaccreditedSellerModifyListingPageBidFragmentDoc}
${NumShareAndPriceDetailsListingFragmentDoc}`;
export const UnaccreditedSellerModifyListingPageSharePriceCardCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerModifyListingPageSharePriceCardCompany on Company {
  id
  name
  lastRoundPricePerShare
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
  ...AskPriceComparisonChartCompany
}
    ${AskPriceComparisonChartCompanyFragmentDoc}`;
export const UnaccreditedSellerModifyListingByIdPageCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerModifyListingByIdPageCompany on Company {
  id
  name
  lastRoundPricePerShare
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
  ...AskPriceComparisonChartCompany
}
    ${AskPriceComparisonChartCompanyFragmentDoc}`;
export const UnaccreditedSellerModifyListingByIdPageBidFragmentDoc = gql`
    fragment UnaccreditedSellerModifyListingByIdPageBid on Bid {
  id
  state
  numSharesActual
  counterBid {
    numShares
  }
}
    `;
export const UnaccreditedSellerModifyListingByIdPageListingFragmentDoc = gql`
    fragment UnaccreditedSellerModifyListingByIdPageListing on Listing {
  id
  otherDetails
  listingPricePerShare
  transferMethod
  numSharesSoldConditionally
  acceptedBidPrices
  solicited
  company {
    ...UnaccreditedSellerModifyListingByIdPageCompany
  }
  shareSeriesMakeup {
    id
    shareSeries
  }
  bids {
    ...UnaccreditedSellerModifyListingByIdPageBid
  }
  ...NumShareAndPriceDetailsListing
}
    ${UnaccreditedSellerModifyListingByIdPageCompanyFragmentDoc}
${UnaccreditedSellerModifyListingByIdPageBidFragmentDoc}
${NumShareAndPriceDetailsListingFragmentDoc}`;
export const CreateListingSelectCompanyInputCompanyFragmentDoc = gql`
    fragment CreateListingSelectCompanyInputCompany on Company {
  id
  name
  logoUrl
  myPermissionsV2
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
  currentPrices {
    highestBid
  }
  companyEligibilityCriterion {
    id
    text
  }
  hasActiveInformationDisclosure
  requiresInformationDisclosure
}
    `;
export const CreateListingSelectCompanyInputCompanyEdgeFragmentDoc = gql`
    fragment CreateListingSelectCompanyInputCompanyEdge on CompanyEdge {
  node {
    ...CreateListingSelectCompanyInputCompany
  }
  cursor
}
    ${CreateListingSelectCompanyInputCompanyFragmentDoc}`;
export const FocusedShareDetailsHeaderCompanyFragmentDoc = gql`
    fragment FocusedShareDetailsHeaderCompany on Company {
  name
  id
  logoUrl
}
    `;
export const GetAvailableListingActionsListingFragmentDoc = gql`
    fragment GetAvailableListingActionsListing on Listing {
  state
  myPermissionsV2
}
    `;
export const AcceptedBidSharePriceDetailsListingFragmentDoc = gql`
    fragment AcceptedBidSharePriceDetailsListing on Listing {
  id
  acceptedBidPrices
  acceptPartialBid
  numSharesAvailable
  numSharesAvailableRounded
  numSharesOriginal
  numSharesOriginalRounded
  state
  transferMethod
}
    `;
export const ModifyListingModalBidFragmentDoc = gql`
    fragment ModifyListingModalBid on Bid {
  id
  state
  numSharesActual
  counterNumShares
}
    `;
export const ModifyListingModalListingFragmentDoc = gql`
    fragment ModifyListingModalListing on Listing {
  id
  listingPricePerShare
  displayId
  numSharesSoldConditionally
  acceptedBidPrices
  solicited
  otherDetails
  transferMethod
  signingProcedure
  representedEmail
  companyId
  expireAt
  shareSeriesMakeup {
    id
    shareSeries
    numShares
  }
  bids {
    ...ModifyListingModalBid
  }
  ...NumShareAndPriceDetailsListing
}
    ${ModifyListingModalBidFragmentDoc}
${NumShareAndPriceDetailsListingFragmentDoc}`;
export const WithdrawListingModalListingFragmentDoc = gql`
    fragment WithdrawListingModalListing on Listing {
  id
  companyId
  displayId
}
    `;
export const ListingPageListingActionsTileListingFragmentDoc = gql`
    fragment ListingPageListingActionsTileListing on Listing {
  id
  ...GetAvailableListingActionsListing
  ...GetIsSellerForListingListing
  ...ModifyListingModalListing
  ...WithdrawListingModalListing
}
    ${GetAvailableListingActionsListingFragmentDoc}
${GetIsSellerForListingListingFragmentDoc}
${ModifyListingModalListingFragmentDoc}
${WithdrawListingModalListingFragmentDoc}`;
export const ListingPageListingStatusTileListingFragmentDoc = gql`
    fragment ListingPageListingStatusTileListing on Listing {
  id
  transferMethod
  state
  expireAt
}
    `;
export const ListingPageListingHiiveUserTileBrokerFragmentDoc = gql`
    fragment ListingPageListingHiiveUserTileBroker on User {
  id
  firstName
  lastName
  email
}
    `;
export const ListingPageListingHiiveUserTileListingFragmentDoc = gql`
    fragment ListingPageListingHiiveUserTileListing on Listing {
  id
  solicited
  shortId
  brokerId
  broker {
    ...ListingPageListingHiiveUserTileBroker
  }
  expireAt
  placedAt
  insertedAt
  state
  transferMethod
}
    ${ListingPageListingHiiveUserTileBrokerFragmentDoc}`;
export const GetIsListerListingFragmentDoc = gql`
    fragment GetIsListerListing on Listing {
  id
  sellerId
}
    `;
export const ListingPageListingPartialBidDetailsListingFragmentDoc = gql`
    fragment ListingPageListingPartialBidDetailsListing on Listing {
  id
  acceptPartialBid
  minPartialBidNumShares
  ...GetIsSellerForListingListing
  ...GetIsListerListing
}
    ${GetIsSellerForListingListingFragmentDoc}
${GetIsListerListingFragmentDoc}`;
export const GetIsBrokerForListingListingFragmentDoc = gql`
    fragment GetIsBrokerForListingListing on Listing {
  id
  brokerId
}
    `;
export const ListingFeeBreakdownCommissionFragmentDoc = gql`
    fragment ListingFeeBreakdownCommission on VirtualCommission {
  commissionAmount
  feeDiscountAmount
  flatFeeAmount
  netFees
}
    `;
export const ListingFeeDiscountApplicationFragmentDoc = gql`
    fragment ListingFeeDiscountApplication on FeeDiscountApplication {
  id
  initial
  state
  transactionModificationId
  feeDiscount {
    ...FeeDiscount
  }
  feeDiscountApplicationCommission {
    amount
    id
  }
}
    ${FeeDiscountFragmentDoc}`;
export const ListingPageListingInfoCardListingFragmentDoc = gql`
    fragment ListingPageListingInfoCardListing on Listing {
  id
  numActiveBids
  numCounteredBids
  version
  expireAt
  acceptedBidPrices
  displayId
  listingPricePerShare
  shareTypeV2
  transferMethod
  solicited
  otherDetails
  fromHiive
  isHiiveSpv
  placedAt
  insertedAt
  company {
    id
    securitySpecialist {
      id
      user {
        id
        email
      }
      marketAnalyst {
        id
        email
      }
    }
    ...FocusedShareDetailsHeaderCompany
  }
  ...GetAvailableListingActionsListing
  ...GetIsSellerForListingListing
  ...GetShowListingSolicitedStateListing
  ...NumShareAndPriceDetailsListing
  ...AcceptedBidSharePriceDetailsListing
  ...ListingPageListingActionsTileListing
  ...ListingPageListingStatusTileListing
  ...ListingPageListingHiiveUserTileListing
  ...GetNumOfSharesListing
  ...ListingPageListingPartialBidDetailsListing
  ...GetIsBrokerForListingListing
  commission {
    ...ListingFeeBreakdownCommission
  }
  feeDiscountApplications {
    id
    ...ListingFeeDiscountApplication
  }
}
    ${FocusedShareDetailsHeaderCompanyFragmentDoc}
${GetAvailableListingActionsListingFragmentDoc}
${GetIsSellerForListingListingFragmentDoc}
${GetShowListingSolicitedStateListingFragmentDoc}
${NumShareAndPriceDetailsListingFragmentDoc}
${AcceptedBidSharePriceDetailsListingFragmentDoc}
${ListingPageListingActionsTileListingFragmentDoc}
${ListingPageListingStatusTileListingFragmentDoc}
${ListingPageListingHiiveUserTileListingFragmentDoc}
${GetNumOfSharesListingFragmentDoc}
${ListingPageListingPartialBidDetailsListingFragmentDoc}
${GetIsBrokerForListingListingFragmentDoc}
${ListingFeeBreakdownCommissionFragmentDoc}
${ListingFeeDiscountApplicationFragmentDoc}`;
export const DiscussionInfoListingFragmentDoc = gql`
    fragment DiscussionInfoListing on Listing {
  id
  ...ListingPageListingInfoCardListing
}
    ${ListingPageListingInfoCardListingFragmentDoc}`;
export const StandingBidInfoCardBrokerFragmentDoc = gql`
    fragment StandingBidInfoCardBroker on User {
  id
  firstName
  lastName
  email
}
    `;
export const GetAvailableStandingBidActionsStandingBidFragmentDoc = gql`
    fragment GetAvailableStandingBidActionsStandingBid on StandingBid {
  id
  myPermissionsV2
  state
}
    `;
export const GetStandingBidLotStandingBidFragmentDoc = gql`
    fragment GetStandingBidLotStandingBid on StandingBid {
  id
  pricePerShare
  ...GetNumOfSharesStandingBid
}
    ${GetNumOfSharesStandingBidFragmentDoc}`;
export const ModifyStandingBidModalCompanyFragmentDoc = gql`
    fragment ModifyStandingBidModalCompany on Company {
  minPostPps
  maxPostPps
}
    `;
export const ModifyStandingBidModalStandingBidFragmentDoc = gql`
    fragment ModifyStandingBidModalStandingBid on StandingBid {
  id
  displayId
  version
  state
  numSharesAvailable
  allowPartialAccept
  minPartialAcceptNumShares
  acceptsShares
  acceptsTransferMethods
  pricePerShare
  otherDetails
  representedEmail
  expireAt
  company {
    ...ModifyStandingBidModalCompany
  }
}
    ${ModifyStandingBidModalCompanyFragmentDoc}`;
export const WithdrawStandingBidModalStandingBidFragmentDoc = gql`
    fragment WithdrawStandingBidModalStandingBid on StandingBid {
  id
  displayId
}
    `;
export const StandingBidActionsTileStandingBidFragmentDoc = gql`
    fragment StandingBidActionsTileStandingBid on StandingBid {
  id
  ...GetAvailableStandingBidActionsStandingBid
  ...GetIsBuyerForStandingBidStandingBid
  ...ModifyStandingBidModalStandingBid
  ...WithdrawStandingBidModalStandingBid
}
    ${GetAvailableStandingBidActionsStandingBidFragmentDoc}
${GetIsBuyerForStandingBidStandingBidFragmentDoc}
${ModifyStandingBidModalStandingBidFragmentDoc}
${WithdrawStandingBidModalStandingBidFragmentDoc}`;
export const GetIsSolicitedStandingBidStandingBidFragmentDoc = gql`
    fragment GetIsSolicitedStandingBidStandingBid on StandingBid {
  id
  solicited
}
    `;
export const ActivityCardStandingBidSolicitedCardBodyStandingBidFragmentDoc = gql`
    fragment ActivityCardStandingBidSolicitedCardBodyStandingBid on StandingBid {
  id
  company {
    id
    securitySpecialist {
      id
      user {
        id
        email
      }
    }
  }
}
    `;
export const GetIsBrokerForStandingBidStandingBidFragmentDoc = gql`
    fragment GetIsBrokerForStandingBidStandingBid on StandingBid {
  id
  brokerId
}
    `;
export const GetIsBrokerCounterpartyForStandingBidStandingBidFragmentDoc = gql`
    fragment GetIsBrokerCounterpartyForStandingBidStandingBid on StandingBid {
  id
  brokerId
  ...GetIsBrokerForStandingBidStandingBid
}
    ${GetIsBrokerForStandingBidStandingBidFragmentDoc}`;
export const StandingBidInfoCardStandingBidFragmentDoc = gql`
    fragment StandingBidInfoCardStandingBid on StandingBid {
  id
  placedAt
  insertedAt
  expireAt
  state
  pricePerShare
  acceptedBidPrices
  displayId
  shortId
  numSharesAccepted
  numSharesOriginal
  numSharesAvailable
  brokerId
  allowPartialAccept
  minPartialAcceptNumShares
  acceptsShares
  acceptsTransferMethods
  broker {
    id
    ...StandingBidInfoCardBroker
  }
  company {
    id
    ...FocusedShareDetailsHeaderCompany
  }
  bids {
    id
    state
    transaction {
      id
      document {
        id
        type
      }
    }
  }
  otherDetails
  fromHiive
  ...GetAvailableStandingBidActionsStandingBid
  ...GetIsBuyerForStandingBidStandingBid
  ...GetStandingBidLotStandingBid
  ...GetNumOfSharesStandingBid
  ...GetIsPartiallyAcceptedStandingBidStandingBid
  ...GetIsConditionallyCompletedStandingBidStandingBid
  ...StandingBidActionsTileStandingBid
  ...GetIsSolicitedStandingBidStandingBid
  ...ActivityCardStandingBidSolicitedCardBodyStandingBid
  ...GetIsBrokerCounterpartyForStandingBidStandingBid
  ...GetIsBrokerForStandingBidStandingBid
}
    ${StandingBidInfoCardBrokerFragmentDoc}
${FocusedShareDetailsHeaderCompanyFragmentDoc}
${GetAvailableStandingBidActionsStandingBidFragmentDoc}
${GetIsBuyerForStandingBidStandingBidFragmentDoc}
${GetStandingBidLotStandingBidFragmentDoc}
${GetNumOfSharesStandingBidFragmentDoc}
${GetIsPartiallyAcceptedStandingBidStandingBidFragmentDoc}
${GetIsConditionallyCompletedStandingBidStandingBidFragmentDoc}
${StandingBidActionsTileStandingBidFragmentDoc}
${GetIsSolicitedStandingBidStandingBidFragmentDoc}
${ActivityCardStandingBidSolicitedCardBodyStandingBidFragmentDoc}
${GetIsBrokerCounterpartyForStandingBidStandingBidFragmentDoc}
${GetIsBrokerForStandingBidStandingBidFragmentDoc}`;
export const StandingBidInfoStandingBidFragmentDoc = gql`
    fragment StandingBidInfoStandingBid on StandingBid {
  id
  ...StandingBidInfoCardStandingBid
}
    ${StandingBidInfoCardStandingBidFragmentDoc}`;
export const DiscussionInfoStandingBidFragmentDoc = gql`
    fragment DiscussionInfoStandingBid on StandingBid {
  id
  ...StandingBidInfoStandingBid
}
    ${StandingBidInfoStandingBidFragmentDoc}`;
export const ListingPageListingInfoCardDiscussionFragmentDoc = gql`
    fragment ListingPageListingInfoCardDiscussion on Discussion {
  id
}
    `;
export const DiscussionInfoDiscussionFragmentDoc = gql`
    fragment DiscussionInfoDiscussion on Discussion {
  id
  listingTopic: topic {
    ...DiscussionInfoListing
  }
  standingBidTopic: topic {
    ...DiscussionInfoStandingBid
  }
  ...ListingPageListingInfoCardDiscussion
}
    ${DiscussionInfoListingFragmentDoc}
${DiscussionInfoStandingBidFragmentDoc}
${ListingPageListingInfoCardDiscussionFragmentDoc}`;
export const PlaceBidSequenceModalContentListingFragmentDoc = gql`
    fragment PlaceBidSequenceModalContentListing on Listing {
  id
  listingPricePerShare
  ...PlaceBidSequenceModalPlaceBidListing
}
    ${PlaceBidSequenceModalPlaceBidListingFragmentDoc}`;
export const PlaceBidSequenceModalAuthorizationListingFragmentDoc = gql`
    fragment PlaceBidSequenceModalAuthorizationListing on Listing {
  id
  ...PlaceBidSequenceModalContentListing
}
    ${PlaceBidSequenceModalContentListingFragmentDoc}`;
export const PlaceBidSequenceModalListingFragmentDoc = gql`
    fragment PlaceBidSequenceModalListing on Listing {
  id
  ...PlaceBidSequenceModalAuthorizationListing
}
    ${PlaceBidSequenceModalAuthorizationListingFragmentDoc}`;
export const DiscussionActivityListingFragmentDoc = gql`
    fragment DiscussionActivityListing on Listing {
  id
  myPermissionsV2
  company {
    id
    ...PlaceBidSequenceModalCompany
  }
  ...PlaceBidSequenceModalListing
}
    ${PlaceBidSequenceModalCompanyFragmentDoc}
${PlaceBidSequenceModalListingFragmentDoc}`;
export const DiscussionActivityStandingBidFragmentDoc = gql`
    fragment DiscussionActivityStandingBid on StandingBid {
  id
}
    `;
export const DiscussionActivityDiscussionFragmentDoc = gql`
    fragment DiscussionActivityDiscussion on Discussion {
  id
  myPermissionsV2
  listingTopic: topic {
    ...DiscussionActivityListing
  }
  standingBidTopic: topic {
    ...DiscussionActivityStandingBid
  }
  ...DiscussionThreadCardDiscussion
}
    ${DiscussionActivityListingFragmentDoc}
${DiscussionActivityStandingBidFragmentDoc}
${DiscussionThreadCardDiscussionFragmentDoc}`;
export const DiscussionPageDiscussionFragmentDoc = gql`
    fragment DiscussionPageDiscussion on Discussion {
  id
  ...DiscussionInfoDiscussion
  ...DiscussionActivityDiscussion
}
    ${DiscussionInfoDiscussionFragmentDoc}
${DiscussionActivityDiscussionFragmentDoc}`;
export const AcceptBidModalCompanyFragmentDoc = gql`
    fragment AcceptBidModalCompany on Company {
  id
  name
}
    `;
export const AcceptBidSuccessModalBidListingCompanyFragmentDoc = gql`
    fragment AcceptBidSuccessModalBidListingCompany on Company {
  id
  name
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
}
    `;
export const AcceptBidSuccessModalBidFragmentDoc = gql`
    fragment AcceptBidSuccessModalBid on Bid {
  transaction {
    id
    ...MakeUrlTransaction
  }
  id
  shortId
  pricePerShare
  numShares
  numSharesActual
  actualNumSharesVisible
  listing {
    id
    transferMethod
    company {
      ...AcceptBidSuccessModalBidListingCompany
    }
  }
}
    ${MakeUrlTransactionFragmentDoc}
${AcceptBidSuccessModalBidListingCompanyFragmentDoc}`;
export const PlaceBidSequenceModalPlaceBidSuccessBidListingCompanyFragmentDoc = gql`
    fragment PlaceBidSequenceModalPlaceBidSuccessBidListingCompany on Company {
  id
  name
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
}
    `;
export const PlaceBidSequenceModalPlaceBidSuccessBidFragmentDoc = gql`
    fragment PlaceBidSequenceModalPlaceBidSuccessBid on Bid {
  id
  listing {
    id
    displayId
    isHiiveSpv
    company {
      ...PlaceBidSequenceModalPlaceBidSuccessBidListingCompany
      ...GetAvailableCompanyActionsCompany
    }
  }
}
    ${PlaceBidSequenceModalPlaceBidSuccessBidListingCompanyFragmentDoc}
${GetAvailableCompanyActionsCompanyFragmentDoc}`;
export const PlaceBidSuccessModalBidListingCompanyFragmentDoc = gql`
    fragment PlaceBidSuccessModalBidListingCompany on Company {
  id
  name
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
}
    `;
export const PlaceBidSuccessModalBidFragmentDoc = gql`
    fragment PlaceBidSuccessModalBid on Bid {
  id
  state
  listing {
    id
    displayId
    isHiiveSpv
    company {
      ...PlaceBidSuccessModalBidListingCompany
      ...GetAvailableCompanyActionsCompany
    }
  }
}
    ${PlaceBidSuccessModalBidListingCompanyFragmentDoc}
${GetAvailableCompanyActionsCompanyFragmentDoc}`;
export const EditInstitutionUserModalUserFragmentDoc = gql`
    fragment EditInstitutionUserModalUser on User {
  id
  roles
  name
  email
}
    `;
export const CreateListingModalListingFragmentDoc = gql`
    fragment CreateListingModalListing on Listing {
  id
  company {
    id
    name
    securitySpecialist {
      id
      user {
        id
        email
      }
      marketAnalyst {
        id
        email
      }
    }
  }
}
    `;
export const CreateListingSuccessModalListingFragmentDoc = gql`
    fragment CreateListingSuccessModalListing on Listing {
  id
  listingPricePerShare
  numSharesOriginal
  numSharesOriginalRounded
  numSharesAvailable
  numSharesAvailableRounded
  actualNumSharesVisible
  company {
    id
    name
    securitySpecialist {
      id
      user {
        id
        email
      }
      marketAnalyst {
        id
        email
      }
    }
  }
}
    `;
export const ModifyListingModalCompanyFragmentDoc = gql`
    fragment ModifyListingModalCompany on Company {
  id
  name
  minPostPps
  maxPostPps
  currentPrices {
    highestBid
  }
}
    `;
export const UnaccreditedSellerCreateListingSuccessModalListingFragmentDoc = gql`
    fragment UnaccreditedSellerCreateListingSuccessModalListing on Listing {
  id
  numSharesOriginal
  numSharesOriginalRounded
  state
  numSharesAvailable
  numSharesAvailableRounded
  actualNumSharesVisible
  company {
    id
    name
    securitySpecialist {
      id
      user {
        id
        email
      }
      marketAnalyst {
        id
        email
      }
    }
  }
  listingPricePerShare
}
    `;
export const UnaccreditedSellerWithdrawListingModalListingFragmentDoc = gql`
    fragment UnaccreditedSellerWithdrawListingModalListing on Listing {
  id
  companyId
  displayId
}
    `;
export const AcceptStandingBidResponseBidFragmentDoc = gql`
    fragment AcceptStandingBidResponseBid on Bid {
  transaction {
    id
    ...MakeUrlTransaction
  }
  id
  companyId
  listing {
    id
    numSharesOriginal
    numSharesOriginalRounded
    listingPricePerShare
    transferMethod
    company {
      id
      name
      securitySpecialist {
        id
        user {
          id
          email
        }
        marketAnalyst {
          id
          email
        }
      }
    }
  }
  standingBid {
    id
    shortId
  }
}
    ${MakeUrlTransactionFragmentDoc}`;
export const AcceptStandingBidSuccessModalBidFragmentDoc = gql`
    fragment AcceptStandingBidSuccessModalBid on Bid {
  id
  listing {
    numSharesOriginal
    numSharesOriginalRounded
    listingPricePerShare
    transferMethod
    company {
      name
      securitySpecialist {
        id
        user {
          email
        }
        marketAnalyst {
          email
        }
      }
    }
  }
  standingBid {
    shortId
  }
}
    `;
export const PlaceStandingBidSuccessModalStandingBidFragmentDoc = gql`
    fragment PlaceStandingBidSuccessModalStandingBid on StandingBid {
  id
  pricePerShare
  company {
    id
    name
    securitySpecialist {
      id
      user {
        id
        email
      }
      marketAnalyst {
        id
        email
      }
    }
  }
  brokerId
}
    `;
export const PlaceStandingBidSelectCompanyInputCompanyFragmentDoc = gql`
    fragment PlaceStandingBidSelectCompanyInputCompany on Company {
  id
  name
  logoUrl
  myPermissionsV2
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
  tradeable
}
    `;
export const PlaceStandingBidSelectCompanyInputCompanyEdgeFragmentDoc = gql`
    fragment PlaceStandingBidSelectCompanyInputCompanyEdge on CompanyEdge {
  node {
    ...PlaceStandingBidSelectCompanyInputCompany
  }
  cursor
}
    ${PlaceStandingBidSelectCompanyInputCompanyFragmentDoc}`;
export const CompaniesSearchCompanyFragmentDoc = gql`
    fragment CompaniesSearchCompany on Company {
  id
  name
  logoUrl
}
    `;
export const CompaniesSearchCompanyEdgeFragmentDoc = gql`
    fragment CompaniesSearchCompanyEdge on CompanyEdge {
  node {
    ...CompaniesSearchCompany
  }
  cursor
}
    ${CompaniesSearchCompanyFragmentDoc}`;
export const UnaccreditedSellerNavbarByIdActivityCompanyFragmentDoc = gql`
    fragment UnaccreditedSellerNavbarByIdActivityCompany on Company {
  id
  name
  logoUrl
  activity {
    myListings {
      id
    }
    myCompletedTransactions {
      id
    }
    myOpenTransactions {
      id
    }
    standingBidsWithDiscussions {
      id
    }
  }
}
    `;
export const BrokerInfoPageBrokerInfoFragmentDoc = gql`
    fragment BrokerInfoPageBrokerInfo on BrokerInfo {
  id
  firstName
  lastName
  brokerRegistrationNumber
  firmRegistrationNumber
  firmName
  firmStreetAddress
  firmCity
  firmState
  firmPostalCode
}
    `;
export const BrokerInfoPageUserFragmentDoc = gql`
    fragment BrokerInfoPageUser on User {
  id
  firstName
  lastName
  finraRegistered
  onboardingV2 {
    id
  }
  country {
    id
    name
  }
  brokerInfo {
    id
    firmName
    firmCity
    firmPostalCode
    firmStreetAddress
    firmState
    firmRegistrationNumber
    brokerRegistrationNumber
  }
}
    `;
export const BrokerIntermediaryInfoPageBrokerInfoFragmentDoc = gql`
    fragment BrokerIntermediaryInfoPageBrokerInfo on BrokerInfo {
  id
  firstName
  lastName
  brokerRegistrationNumber
  intermediaryPermission
  intermediaryNotes
  firmName
  firmStreetAddress
}
    `;
export const BrokerIntermediaryInfoPageUserFragmentDoc = gql`
    fragment BrokerIntermediaryInfoPageUser on User {
  id
  firstName
  lastName
  onboardingV2 {
    id
  }
  brokerInfo {
    id
    firmName
    firmStreetAddress
    brokerRegistrationNumber
    intermediaryPermission
    intermediaryNotes
  }
}
    `;
export const HoldingDetailsPageCompanyFragmentDoc = gql`
    fragment HoldingDetailsPageCompany on Company {
  id
  name
  logoUrl
}
    `;
export const HoldingDetailsPageCompanyEdgeFragmentDoc = gql`
    fragment HoldingDetailsPageCompanyEdge on CompanyEdge {
  node {
    ...HoldingDetailsPageCompany
  }
  cursor
}
    ${HoldingDetailsPageCompanyFragmentDoc}`;
export const HoldingDetailsPageHoldingFragmentDoc = gql`
    fragment HoldingDetailsPageHolding on Holding {
  companyId
  id
  numSharesV2
  company {
    ...HoldingDetailsPageCompany
  }
}
    ${HoldingDetailsPageCompanyFragmentDoc}`;
export const HoldingDetailsPageUnlistedHoldingFragmentDoc = gql`
    fragment HoldingDetailsPageUnlistedHolding on UnlistedHolding {
  id
  companyName
  numSharesV2
}
    `;
export const WatchlistPageWatchlistCompanyFragmentDoc = gql`
    fragment WatchlistPageWatchlistCompany on Company {
  id
  name
  logoUrl
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
}
    `;
export const ResidencePageBrokerInfoFragmentDoc = gql`
    fragment ResidencePageBrokerInfo on BrokerInfo {
  id
  firstName
  lastName
  brokerRegistrationNumber
  firmRegistrationNumber
  intermediaryPermission
  intermediaryNotes
  firmName
  firmStreetAddress
  firmCity
  firmState
  firmPostalCode
}
    `;
export const OnboardingSelectCompanyInputCompanyFragmentDoc = gql`
    fragment OnboardingSelectCompanyInputCompany on Company {
  id
  name
  logoUrl
}
    `;
export const OnboardingSelectCompanyInputListCompanyEdgeFragmentDoc = gql`
    fragment OnboardingSelectCompanyInputListCompanyEdge on CompanyEdge {
  node {
    ...OnboardingSelectCompanyInputCompany
  }
  cursor
}
    ${OnboardingSelectCompanyInputCompanyFragmentDoc}`;
export const TransactionDocumentPageBidFragmentDoc = gql`
    fragment TransactionDocumentPageBid on Bid {
  id
  shortId
  listing {
    id
    company {
      id
      name
    }
  }
}
    `;
export const TransactionDocumentPageDocumentFragmentDoc = gql`
    fragment TransactionDocumentPageDocument on Document {
  id
  type
  signers {
    id
    institutionId
    userId
  }
}
    `;
export const TransactionFeeBreakdownCommissionFragmentDoc = gql`
    fragment TransactionFeeBreakdownCommission on Commission {
  flatFeeAmount
  commissionAmount
  feeDiscountAmount
  netFees
}
    `;
export const DocumentTransactionCardTransactionModificationFragmentDoc = gql`
    fragment DocumentTransactionCardTransactionModification on TransactionModification {
  numShares
  pricePerShare
  commission {
    ...TransactionFeeBreakdownCommission
  }
}
    ${TransactionFeeBreakdownCommissionFragmentDoc}`;
export const ActivityCardCompanyFragmentDoc = gql`
    fragment ActivityCardCompany on Company {
  id
  logoUrl
  name
}
    `;
export const DocumentTransactionCardTransactionFragmentDoc = gql`
    fragment DocumentTransactionCardTransaction on Transaction {
  id
  numShares
  pricePerShare
  buyerId
  buyerBrokerId
  sellerId
  sellerBrokerId
  pendingModification {
    ...DocumentTransactionCardTransactionModification
  }
  bid {
    id
    pricePerShare
    displayId
    listing {
      id
      company {
        id
        ...ActivityCardCompany
      }
    }
    standingBid {
      id
      displayId
    }
    ...GetBidNumSharesActualBid
  }
  ...MakeUrlTransaction
}
    ${DocumentTransactionCardTransactionModificationFragmentDoc}
${ActivityCardCompanyFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${MakeUrlTransactionFragmentDoc}`;
export const TransactionDocumentPageTransactionFragmentDoc = gql`
    fragment TransactionDocumentPageTransaction on Transaction {
  id
  signingProcedure
  state
  pendingModification {
    id
  }
  bid {
    id
    ...TransactionDocumentPageBid
  }
  myPermissionsV2
  sellerBankAccountId
  sellerBankAccountRequestStatus
  document {
    id
    type
    externalId
    ...TransactionDocumentPageDocument
  }
  ...DocumentTransactionCardTransaction
  ...MakeUrlTransaction
}
    ${TransactionDocumentPageBidFragmentDoc}
${TransactionDocumentPageDocumentFragmentDoc}
${DocumentTransactionCardTransactionFragmentDoc}
${MakeUrlTransactionFragmentDoc}`;
export const BidFeeDiscountBreakdownFeeDiscountApplicationFragmentDoc = gql`
    fragment BidFeeDiscountBreakdownFeeDiscountApplication on FeeDiscountApplication {
  id
  initial
  state
  transactionModificationId
  feeDiscount {
    ...FeeDiscount
  }
  feeDiscountApplicationCommission {
    id
    amount
  }
}
    ${FeeDiscountFragmentDoc}`;
export const TransactionModificationFragmentTransactionFragmentDoc = gql`
    fragment TransactionModificationFragmentTransaction on Transaction {
  pendingModification {
    id
    numShares
    pricePerShare
    status
    acknowledgments {
      id
      insertedAt
      updatedAt
      status
      userId
    }
    commission {
      ...TransactionFeeBreakdownCommission
    }
    feeDiscountApplications {
      id
      ...BidFeeDiscountBreakdownFeeDiscountApplication
    }
  }
}
    ${TransactionFeeBreakdownCommissionFragmentDoc}
${BidFeeDiscountBreakdownFeeDiscountApplicationFragmentDoc}`;
export const TransactionModificationTransactionFragmentDoc = gql`
    fragment TransactionModificationTransaction on Transaction {
  id
  state
  pricePerShare
  numShares
  transferMethod
  sellerBrokerId
  sellerId
  buyerId
  buyerBrokerId
  company {
    name
  }
  ...TransactionModificationFragmentTransaction
}
    ${TransactionModificationFragmentTransactionFragmentDoc}`;
export const TransactionModificationTransactionModificationAcknowledgmentFragmentDoc = gql`
    fragment TransactionModificationTransactionModificationAcknowledgment on TransactionModificationAcknowledgment {
  id
  insertedAt
  updatedAt
  status
  userId
}
    `;
export const BidInfoStandingBidFragmentDoc = gql`
    fragment BidInfoStandingBid on StandingBid {
  id
  ...StandingBidInfoCardStandingBid
}
    ${StandingBidInfoCardStandingBidFragmentDoc}`;
export const ModifyBidModalCompanyFragmentDoc = gql`
    fragment ModifyBidModalCompany on Company {
  id
  name
}
    `;
export const MakeUrlListingFragmentDoc = gql`
    fragment MakeUrlListing on Listing {
  id
  companyId
}
    `;
export const PostingDetailsListingFragmentDoc = gql`
    fragment PostingDetailsListing on Listing {
  id
  shortId
  listingPricePerShare
  numSharesAvailable
  numSharesOriginal
  numSharesAvailableRounded
  numSharesOriginalRounded
  actualNumSharesVisible
  state
  transferMethod
  sellerEmail
  sellerId
  ...MakeUrlListing
}
    ${MakeUrlListingFragmentDoc}`;
export const ModifyBidModalListingFragmentDoc = gql`
    fragment ModifyBidModalListing on Listing {
  id
  acceptPartialBid
  displayId
  sellerInstitutionId
  ...PostingDetailsListing
}
    ${PostingDetailsListingFragmentDoc}`;
export const GetAvailableBidActionsBidFragmentDoc = gql`
    fragment GetAvailableBidActionsBid on Bid {
  id
  state
  myPermissionsV2
  pricePerShare
  numSharesActual
  listing {
    id
    listingPricePerShare
    numSharesAvailable
  }
  discussion {
    id
  }
  displayId
}
    `;
export const AcceptCounterBidModalBidFragmentDoc = gql`
    fragment AcceptCounterBidModalBid on Bid {
  id
  displayId
  numShares
  numSharesActual
  actualNumSharesVisible
  pricePerShare
  counterPricePerShare
  counterNumShares
  listing {
    id
    numSharesAvailable
    numSharesAvailableRounded
    actualNumSharesVisible
    listingPricePerShare
    transferMethod
    bids {
      id
      state
      numShares
    }
  }
  company {
    id
    name
  }
}
    `;
export const AcceptBidModalBidFragmentDoc = gql`
    fragment AcceptBidModalBid on Bid {
  id
  displayId
  state
  numShares
  numSharesActual
  actualNumSharesVisible
  pricePerShare
  myPermissionsV2
  listingBelowMinLotOnAcceptance
  listing {
    id
    numSharesAvailable
    numSharesAvailableRounded
    actualNumSharesVisible
    listingPricePerShare
    acceptPartialBid
    transferMethod
    bids {
      id
      state
      numShares
    }
  }
  company {
    id
  }
}
    `;
export const CounterBidModalListingFragmentDoc = gql`
    fragment CounterBidModalListing on Listing {
  displayId
  sellerInstitutionId
  acceptPartialBid
  company {
    name
  }
  ...PostingDetailsListing
}
    ${PostingDetailsListingFragmentDoc}`;
export const GetCanCounterNumberOfSharesBidFragmentDoc = gql`
    fragment GetCanCounterNumberOfSharesBid on Bid {
  numSharesActual
  listing {
    acceptPartialBid
    numSharesAvailable
    listingPricePerShare
  }
}
    `;
export const CounterBidModalBidFragmentDoc = gql`
    fragment CounterBidModalBid on Bid {
  id
  displayId
  pricePerShare
  numShares
  numSharesActual
  counterPricePerShare
  counterNumShares
  buyerInstitutionId
  listing {
    id
    ...CounterBidModalListing
  }
  ...GetBidNumSharesActualBid
  ...GetCanCounterNumberOfSharesBid
}
    ${CounterBidModalListingFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${GetCanCounterNumberOfSharesBidFragmentDoc}`;
export const ModifyBidModalBidFragmentDoc = gql`
    fragment ModifyBidModalBid on Bid {
  id
  numShares
  pricePerShare
  expireAt
  representedEmail
  listing {
    ...ModifyBidModalListing
    company {
      ...ModifyBidModalCompany
    }
  }
}
    ${ModifyBidModalListingFragmentDoc}
${ModifyBidModalCompanyFragmentDoc}`;
export const BidActionsBidFragmentDoc = gql`
    fragment BidActionsBid on Bid {
  id
  listing {
    id
    transferMethod
    company {
      id
      ...ModifyBidModalCompany
    }
    ...ModifyBidModalListing
  }
  ...GetAvailableBidActionsBid
  ...AcceptCounterBidModalBid
  ...AcceptBidModalBid
  ...CounterBidModalBid
  ...ModifyBidModalBid
}
    ${ModifyBidModalCompanyFragmentDoc}
${ModifyBidModalListingFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${AcceptCounterBidModalBidFragmentDoc}
${AcceptBidModalBidFragmentDoc}
${CounterBidModalBidFragmentDoc}
${ModifyBidModalBidFragmentDoc}`;
export const BidInfoCardBidTransactionHistoryFragmentDoc = gql`
    fragment BidInfoCardBidTransactionHistory on TransactionHistory {
  pricePerShare
  numShares
  commission {
    ...TransactionFeeBreakdownCommission
  }
}
    ${TransactionFeeBreakdownCommissionFragmentDoc}`;
export const BidPageAlternateSignerDetailsAlternateSignerFragmentDoc = gql`
    fragment BidPageAlternateSignerDetailsAlternateSigner on AlternateSigner {
  id
  name
  email
  sender {
    id
    name
  }
}
    `;
export const UseHaveISignedDocumentDocumentFragmentDoc = gql`
    fragment UseHaveISignedDocumentDocument on Document {
  id
  signers {
    id
    userId
    institutionId
  }
}
    `;
export const UseDocumentSignersDocumentFragmentDoc = gql`
    fragment UseDocumentSignersDocument on Document {
  id
  signers {
    id
    alternateSignerId
    alternateSigner {
      id
      name
    }
    userId
    institutionId
  }
  invitedAlternateSigners {
    id
    insertedAt
    senderId
    institutionId
    ...BidPageAlternateSignerDetailsAlternateSigner
  }
  ...UseHaveISignedDocumentDocument
}
    ${BidPageAlternateSignerDetailsAlternateSignerFragmentDoc}
${UseHaveISignedDocumentDocumentFragmentDoc}`;
export const UseDocumentSignersTransactionFragmentDoc = gql`
    fragment UseDocumentSignersTransaction on Transaction {
  id
  myPermissionsV2
  document {
    id
    ...UseDocumentSignersDocument
  }
}
    ${UseDocumentSignersDocumentFragmentDoc}`;
export const FeeDiscountApplicationTypeFragmentDoc = gql`
    fragment FeeDiscountApplicationType on FeeDiscountApplication {
  id
  initial
  state
  feeDiscountApplicationCommission {
    id
    amount
  }
  feeDiscount {
    id
    name
    type
    value
  }
}
    `;
export const RevokeAlternateSignerInvitationModalDocumentFragmentDoc = gql`
    fragment RevokeAlternateSignerInvitationModalDocument on Document {
  id
}
    `;
export const BidPageNotSignerForInstitutionBidFragmentDoc = gql`
    fragment BidPageNotSignerForInstitutionBid on Bid {
  id
  transaction {
    id
    document {
      id
      type
    }
  }
}
    `;
export const BidPageAlternateSignerDetailsDocumentFragmentDoc = gql`
    fragment BidPageAlternateSignerDetailsDocument on Document {
  id
  type
  signers {
    id
  }
}
    `;
export const BidPageAlternateSignerDetailsTransactionFragmentDoc = gql`
    fragment BidPageAlternateSignerDetailsTransaction on Transaction {
  id
  document {
    id
    type
    invitedAlternateSigners {
      id
      ...BidPageAlternateSignerDetailsAlternateSigner
    }
    ...BidPageAlternateSignerDetailsDocument
  }
  ...UseDocumentSignersTransaction
}
    ${BidPageAlternateSignerDetailsAlternateSignerFragmentDoc}
${BidPageAlternateSignerDetailsDocumentFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}`;
export const BidPageAlternateSignerDetailsBidFragmentDoc = gql`
    fragment BidPageAlternateSignerDetailsBid on Bid {
  id
  transaction {
    id
    ...BidPageAlternateSignerDetailsTransaction
  }
}
    ${BidPageAlternateSignerDetailsTransactionFragmentDoc}`;
export const InviteAlternateSignerModalBidFragmentDoc = gql`
    fragment InviteAlternateSignerModalBid on Bid {
  id
  buyerId
  transaction {
    id
    document {
      id
      type
    }
  }
}
    `;
export const BidPageDocumentActionsBidFragmentDoc = gql`
    fragment BidPageDocumentActionsBid on Bid {
  id
  companyId
  transaction {
    id
    document {
      id
      type
      ...RevokeAlternateSignerInvitationModalDocument
    }
    ...UseDocumentSignersTransaction
  }
  ...BidPageNotSignerForInstitutionBid
  ...BidPageAlternateSignerDetailsBid
  ...InviteAlternateSignerModalBid
}
    ${RevokeAlternateSignerInvitationModalDocumentFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${BidPageNotSignerForInstitutionBidFragmentDoc}
${BidPageAlternateSignerDetailsBidFragmentDoc}
${InviteAlternateSignerModalBidFragmentDoc}`;
export const ModifyCounterBidModalListingFragmentDoc = gql`
    fragment ModifyCounterBidModalListing on Listing {
  displayId
  sellerInstitutionId
  acceptPartialBid
  company {
    id
    name
  }
  ...PostingDetailsListing
}
    ${PostingDetailsListingFragmentDoc}`;
export const ModifyCounterBidModalBidFragmentDoc = gql`
    fragment ModifyCounterBidModalBid on Bid {
  id
  displayId
  pricePerShare
  numShares
  numSharesActual
  counterPricePerShare
  counterNumShares
  buyerInstitutionId
  listing {
    id
    ...ModifyCounterBidModalListing
  }
  ...GetBidNumSharesActualBid
  ...GetNumOfSharesBid
}
    ${ModifyCounterBidModalListingFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${GetNumOfSharesBidFragmentDoc}`;
export const WithdrawCounterBidModalBidFragmentDoc = gql`
    fragment WithdrawCounterBidModalBid on Bid {
  id
  displayId
}
    `;
export const GetTransactionCounterpartyNameTransactionFragmentDoc = gql`
    fragment GetTransactionCounterpartyNameTransaction on Transaction {
  counterparty {
    id
    name
    institution {
      legalName
    }
  }
  document {
    id
    signers {
      id
      userId
      alternateSigner {
        id
        name
      }
    }
  }
}
    `;
export const ManualLoiBuyerStatesBidFragmentDoc = gql`
    fragment ManualLOIBuyerStatesBid on Bid {
  id
  transaction {
    id
    ...GetTransactionCounterpartyNameTransaction
  }
}
    ${GetTransactionCounterpartyNameTransactionFragmentDoc}`;
export const BidAcceptedStatusItemDocumentSignerFragmentDoc = gql`
    fragment BidAcceptedStatusItemDocumentSigner on DocumentSigner {
  id
  updatedAt
  userId
  institutionId
}
    `;
export const BidAcceptedStatusItemTransactionFragmentDoc = gql`
    fragment BidAcceptedStatusItemTransaction on Transaction {
  id
  state
  bid {
    id
    brokerId
    buyerId
    standingBidId
    brokerCounterpartyId
  }
  counterparty {
    id
    institutionId
  }
  document {
    id
    type
    signers {
      id
      userId
      ...BidAcceptedStatusItemDocumentSigner
    }
  }
  ...UseDocumentSignersTransaction
  ...GetTransactionCounterpartyNameTransaction
}
    ${BidAcceptedStatusItemDocumentSignerFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${GetTransactionCounterpartyNameTransactionFragmentDoc}`;
export const AutomatedLoiBuyerStatesBidFragmentDoc = gql`
    fragment AutomatedLOIBuyerStatesBid on Bid {
  id
  transaction {
    id
    ...UseDocumentSignersTransaction
    ...BidAcceptedStatusItemTransaction
  }
}
    ${UseDocumentSignersTransactionFragmentDoc}
${BidAcceptedStatusItemTransactionFragmentDoc}`;
export const ManualStnBuyerStatesBidFragmentDoc = gql`
    fragment ManualSTNBuyerStatesBid on Bid {
  id
  transaction {
    id
    ...GetTransactionCounterpartyNameTransaction
  }
  listing {
    id
    company {
      id
      name
    }
  }
}
    ${GetTransactionCounterpartyNameTransactionFragmentDoc}`;
export const AutomatedStnBuyerStatesBidFragmentDoc = gql`
    fragment AutomatedSTNBuyerStatesBid on Bid {
  id
  transaction {
    id
    ...UseDocumentSignersTransaction
    ...BidAcceptedStatusItemTransaction
  }
  listing {
    id
    company {
      id
      name
    }
  }
}
    ${UseDocumentSignersTransactionFragmentDoc}
${BidAcceptedStatusItemTransactionFragmentDoc}`;
export const ManualLoiSellerStatesBidFragmentDoc = gql`
    fragment ManualLOISellerStatesBid on Bid {
  id
  transaction {
    id
    ...GetTransactionCounterpartyNameTransaction
  }
}
    ${GetTransactionCounterpartyNameTransactionFragmentDoc}`;
export const AutomatedLoiSellerStatesBidFragmentDoc = gql`
    fragment AutomatedLOISellerStatesBid on Bid {
  id
  transaction {
    id
    ...UseDocumentSignersTransaction
    ...BidAcceptedStatusItemTransaction
  }
}
    ${UseDocumentSignersTransactionFragmentDoc}
${BidAcceptedStatusItemTransactionFragmentDoc}`;
export const ManualStnSellerStatesBidFragmentDoc = gql`
    fragment ManualSTNSellerStatesBid on Bid {
  id
  transaction {
    id
    ...GetTransactionCounterpartyNameTransaction
  }
  listing {
    id
    company {
      id
      name
    }
  }
}
    ${GetTransactionCounterpartyNameTransactionFragmentDoc}`;
export const AutomatedStnSellerStatesBidFragmentDoc = gql`
    fragment AutomatedSTNSellerStatesBid on Bid {
  id
  transaction {
    id
    ...UseDocumentSignersTransaction
    ...BidAcceptedStatusItemTransaction
  }
  listing {
    id
    company {
      id
      name
    }
  }
}
    ${UseDocumentSignersTransactionFragmentDoc}
${BidAcceptedStatusItemTransactionFragmentDoc}`;
export const GetIsBrokerForBidBidFragmentDoc = gql`
    fragment GetIsBrokerForBidBid on Bid {
  id
  brokerId
}
    `;
export const TransactionStatesBidFragmentDoc = gql`
    fragment TransactionStatesBid on Bid {
  id
  transaction {
    id
    signingProcedure
    documentTypeV2
    state
    document {
      id
    }
  }
  ...ManualLOIBuyerStatesBid
  ...AutomatedLOIBuyerStatesBid
  ...ManualSTNBuyerStatesBid
  ...AutomatedSTNBuyerStatesBid
  ...ManualLOISellerStatesBid
  ...AutomatedLOISellerStatesBid
  ...ManualSTNSellerStatesBid
  ...AutomatedSTNSellerStatesBid
  ...GetIsBuyerForBidBid
  ...GetIsBrokerForBidBid
  ...GetIsCounterpartyForBidBid
}
    ${ManualLoiBuyerStatesBidFragmentDoc}
${AutomatedLoiBuyerStatesBidFragmentDoc}
${ManualStnBuyerStatesBidFragmentDoc}
${AutomatedStnBuyerStatesBidFragmentDoc}
${ManualLoiSellerStatesBidFragmentDoc}
${AutomatedLoiSellerStatesBidFragmentDoc}
${ManualStnSellerStatesBidFragmentDoc}
${AutomatedStnSellerStatesBidFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${GetIsBrokerForBidBidFragmentDoc}
${GetIsCounterpartyForBidBidFragmentDoc}`;
export const AcceptedBidDetailsBidFragmentDoc = gql`
    fragment AcceptedBidDetailsBid on Bid {
  id
  counterpartyId
  transaction {
    id
    signingProcedure
    state
    rofr
    document {
      id
    }
  }
  ...TransactionStatesBid
}
    ${TransactionStatesBidFragmentDoc}`;
export const CounterBidFeeBreakdownCommissionFragmentDoc = gql`
    fragment CounterBidFeeBreakdownCommission on Commission {
  flatFeeAmount
  commissionAmount
  feeDiscountAmount
  netFees
}
    `;
export const CounterBidFeeBreakdownBidFragmentDoc = gql`
    fragment CounterBidFeeBreakdownBid on Bid {
  id
  counterNumShares
  counterPricePerShare
  numSharesActual
  pricePerShare
  commission {
    ...CounterBidFeeBreakdownCommission
  }
  counterBid {
    numShares
    pricePerShare
    commission {
      ...CounterBidFeeBreakdownCommission
    }
    feeDiscountApplications {
      ...FeeDiscountApplicationType
    }
  }
  transaction {
    id
    state
  }
}
    ${CounterBidFeeBreakdownCommissionFragmentDoc}
${FeeDiscountApplicationTypeFragmentDoc}`;
export const BidPageFeeBreakdownCommissionFragmentDoc = gql`
    fragment BidPageFeeBreakdownCommission on Commission {
  flatFeeAmount
  commissionAmount
  feeDiscountAmount
  netFees
}
    `;
export const BidFeeBreakdownBidFragmentDoc = gql`
    fragment BidFeeBreakdownBid on Bid {
  id
  pricePerShare
  transaction {
    id
    state
    feeDiscountApplications {
      id
      ...BidFeeDiscountBreakdownFeeDiscountApplication
    }
  }
  commission {
    ...BidPageFeeBreakdownCommission
  }
  ...GetBidNumSharesActualBid
}
    ${BidFeeDiscountBreakdownFeeDiscountApplicationFragmentDoc}
${BidPageFeeBreakdownCommissionFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}`;
export const BidStatusListingFragmentDoc = gql`
    fragment BidStatusListing on Listing {
  id
  company {
    id
    name
    ...GetSecuritySpecialistContactCompany
  }
}
    ${GetSecuritySpecialistContactCompanyFragmentDoc}`;
export const BidStatusBidFragmentDoc = gql`
    fragment BidStatusBid on Bid {
  state
  standingBidId
  buyerEmail
  brokerId
  company {
    id
    name
  }
  transaction {
    id
    documentTypeV2
    rofr
    state
    signingProcedure
  }
  listing {
    id
    isHiiveSpv
    ...BidStatusListing
  }
}
    ${BidStatusListingFragmentDoc}`;
export const BidInfoCardStatusTileBrokerFragmentDoc = gql`
    fragment BidInfoCardStatusTileBroker on User {
  id
  firstName
  lastName
  email
}
    `;
export const BidInfoCardStatusTileBidFragmentDoc = gql`
    fragment BidInfoCardStatusTileBid on Bid {
  id
  state
  counteredAt
  placedAt
  expireAt
  insertedAt
  brokerId
  listing {
    isHiiveSpv
  }
  broker {
    id
    ...BidInfoCardStatusTileBroker
  }
  ...GetIsBuyerForBidBid
  ...BidStatusBid
}
    ${BidInfoCardStatusTileBrokerFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${BidStatusBidFragmentDoc}`;
export const BuyerBidInfoCardBidFragmentDoc = gql`
    fragment BuyerBidInfoCardBid on Bid {
  id
  counteredAt
  placedAt
  insertedAt
  pricePerShare
  fromHiive
  state
  counterpartyId
  displayId
  brokerId
  expireAt
  transaction {
    id
    state
    rofr
    history {
      ...BidInfoCardBidTransactionHistory
    }
    document {
      id
    }
    ...UseDocumentSignersTransaction
  }
  listing {
    id
    isHiiveSpv
    company {
      id
      ...FocusedShareDetailsHeaderCompany
    }
  }
  feeDiscountApplications {
    ...FeeDiscountApplicationType
  }
  ...GetIsBuyerForBidBid
  ...GetAvailableBidActionsBid
  ...GetBidNumSharesActualBid
  ...AcceptBidModalBid
  ...BidPageDocumentActionsBid
  ...ModifyCounterBidModalBid
  ...WithdrawCounterBidModalBid
  ...AcceptBidModalBid
  ...AcceptedBidDetailsBid
  ...BidFeeBreakdownBid
  ...BidInfoCardStatusTileBid
  ...BidActionsBid
}
    ${BidInfoCardBidTransactionHistoryFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${FocusedShareDetailsHeaderCompanyFragmentDoc}
${FeeDiscountApplicationTypeFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${AcceptBidModalBidFragmentDoc}
${BidPageDocumentActionsBidFragmentDoc}
${ModifyCounterBidModalBidFragmentDoc}
${WithdrawCounterBidModalBidFragmentDoc}
${AcceptedBidDetailsBidFragmentDoc}
${BidFeeBreakdownBidFragmentDoc}
${BidInfoCardStatusTileBidFragmentDoc}
${BidActionsBidFragmentDoc}`;
export const BrokerSellerBidInfoCardFragmentDoc = gql`
    fragment BrokerSellerBidInfoCard on Bid {
  id
  counteredAt
  placedAt
  insertedAt
  pricePerShare
  fromHiive
  state
  counterpartyId
  displayId
  brokerId
  expireAt
  transaction {
    id
    state
    rofr
    document {
      id
    }
    ...UseDocumentSignersTransaction
  }
  listing {
    id
    isHiiveSpv
    company {
      id
      ...FocusedShareDetailsHeaderCompany
    }
  }
  ...GetIsBuyerForBidBid
  ...GetAvailableBidActionsBid
  ...GetBidNumSharesActualBid
  ...AcceptBidModalBid
  ...BidPageDocumentActionsBid
  ...ModifyCounterBidModalBid
  ...WithdrawCounterBidModalBid
  ...AcceptBidModalBid
  ...AcceptedBidDetailsBid
  ...BidFeeBreakdownBid
  ...BidInfoCardStatusTileBid
}
    ${UseDocumentSignersTransactionFragmentDoc}
${FocusedShareDetailsHeaderCompanyFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${AcceptBidModalBidFragmentDoc}
${BidPageDocumentActionsBidFragmentDoc}
${ModifyCounterBidModalBidFragmentDoc}
${WithdrawCounterBidModalBidFragmentDoc}
${AcceptedBidDetailsBidFragmentDoc}
${BidFeeBreakdownBidFragmentDoc}
${BidInfoCardStatusTileBidFragmentDoc}`;
export const SellerBidInfoCardFragmentDoc = gql`
    fragment SellerBidInfoCard on Bid {
  id
  counteredAt
  placedAt
  insertedAt
  pricePerShare
  fromHiive
  state
  counterpartyId
  displayId
  brokerId
  expireAt
  transaction {
    id
    state
    rofr
    history {
      ...BidInfoCardBidTransactionHistory
    }
    document {
      id
    }
    ...UseDocumentSignersTransaction
  }
  listing {
    id
    isHiiveSpv
    company {
      id
      ...FocusedShareDetailsHeaderCompany
    }
  }
  ...GetIsBuyerForBidBid
  ...GetAvailableBidActionsBid
  ...GetBidNumSharesActualBid
  ...AcceptBidModalBid
  ...BidPageDocumentActionsBid
  ...ModifyCounterBidModalBid
  ...WithdrawCounterBidModalBid
  ...AcceptedBidDetailsBid
  ...BidFeeBreakdownBid
}
    ${BidInfoCardBidTransactionHistoryFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${FocusedShareDetailsHeaderCompanyFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${AcceptBidModalBidFragmentDoc}
${BidPageDocumentActionsBidFragmentDoc}
${ModifyCounterBidModalBidFragmentDoc}
${WithdrawCounterBidModalBidFragmentDoc}
${AcceptedBidDetailsBidFragmentDoc}
${BidFeeBreakdownBidFragmentDoc}`;
export const BidInfoCardBidFragmentDoc = gql`
    fragment BidInfoCardBid on Bid {
  id
  counteredAt
  placedAt
  insertedAt
  pricePerShare
  fromHiive
  state
  counterpartyId
  displayId
  brokerId
  expireAt
  transaction {
    id
    state
    rofr
    history {
      ...BidInfoCardBidTransactionHistory
    }
    document {
      id
    }
    ...UseDocumentSignersTransaction
  }
  listing {
    id
    isHiiveSpv
    company {
      id
      ...FocusedShareDetailsHeaderCompany
    }
  }
  feeDiscountApplications {
    ...FeeDiscountApplicationType
  }
  ...GetIsBuyerForBidBid
  ...GetAvailableBidActionsBid
  ...GetBidNumSharesActualBid
  ...AcceptBidModalBid
  ...BidPageDocumentActionsBid
  ...ModifyCounterBidModalBid
  ...WithdrawCounterBidModalBid
  ...AcceptedBidDetailsBid
  ...CounterBidFeeBreakdownBid
  ...BidFeeBreakdownBid
  ...BidStatusBid
  ...BuyerBidInfoCardBid
  ...BrokerSellerBidInfoCard
  ...SellerBidInfoCard
}
    ${BidInfoCardBidTransactionHistoryFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${FocusedShareDetailsHeaderCompanyFragmentDoc}
${FeeDiscountApplicationTypeFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${AcceptBidModalBidFragmentDoc}
${BidPageDocumentActionsBidFragmentDoc}
${ModifyCounterBidModalBidFragmentDoc}
${WithdrawCounterBidModalBidFragmentDoc}
${AcceptedBidDetailsBidFragmentDoc}
${CounterBidFeeBreakdownBidFragmentDoc}
${BidFeeBreakdownBidFragmentDoc}
${BidStatusBidFragmentDoc}
${BuyerBidInfoCardBidFragmentDoc}
${BrokerSellerBidInfoCardFragmentDoc}
${SellerBidInfoCardFragmentDoc}`;
export const GetShowDocumentActionsBidFragmentDoc = gql`
    fragment GetShowDocumentActionsBid on Bid {
  id
  state
  counterpartyId
  transaction {
    id
    state
    rofr
    document {
      id
    }
  }
}
    `;
export const BuyerTransactionInfoCardBidFragmentDoc = gql`
    fragment BuyerTransactionInfoCardBid on Bid {
  id
  displayId
  numShares
  transaction {
    id
    numShares
    pricePerShare
    company {
      id
      ...FocusedShareDetailsHeaderCompany
    }
    ...UseDocumentSignersTransaction
  }
  ...GetBidNumSharesActualBid
  ...GetShowDocumentActionsBid
  ...BidInfoCardStatusTileBid
  ...AcceptedBidDetailsBid
  ...BidPageDocumentActionsBid
}
    ${FocusedShareDetailsHeaderCompanyFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${GetShowDocumentActionsBidFragmentDoc}
${BidInfoCardStatusTileBidFragmentDoc}
${AcceptedBidDetailsBidFragmentDoc}
${BidPageDocumentActionsBidFragmentDoc}`;
export const BrokerSellerTransactionFeeBreakdownTransactionFeeDiscountApplicationsFragmentDoc = gql`
    fragment BrokerSellerTransactionFeeBreakdownTransactionFeeDiscountApplications on FeeDiscountApplication {
  id
  state
  initial
  feeDiscount {
    ...FeeDiscount
  }
  feeDiscountApplicationCommission {
    id
    amount
  }
}
    ${FeeDiscountFragmentDoc}`;
export const BrokerSellerTransactionFeeBreakdownTransactionFragmentDoc = gql`
    fragment BrokerSellerTransactionFeeBreakdownTransaction on Transaction {
  id
  numShares
  pricePerShare
  commission {
    ...TransactionFeeBreakdownCommission
  }
  feeDiscountApplications {
    ...BrokerSellerTransactionFeeBreakdownTransactionFeeDiscountApplications
  }
}
    ${TransactionFeeBreakdownCommissionFragmentDoc}
${BrokerSellerTransactionFeeBreakdownTransactionFeeDiscountApplicationsFragmentDoc}`;
export const BrokerTransactionInfoCardBidFragmentDoc = gql`
    fragment BrokerTransactionInfoCardBid on Bid {
  id
  displayId
  numShares
  transaction {
    id
    numShares
    pricePerShare
    company {
      id
      ...FocusedShareDetailsHeaderCompany
    }
    ...UseDocumentSignersTransaction
    ...BrokerSellerTransactionFeeBreakdownTransaction
  }
  ...GetBidNumSharesActualBid
  ...GetShowDocumentActionsBid
  ...BidInfoCardStatusTileBid
  ...AcceptedBidDetailsBid
  ...BidPageDocumentActionsBid
  ...GetIsBrokerForBidBid
}
    ${FocusedShareDetailsHeaderCompanyFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${BrokerSellerTransactionFeeBreakdownTransactionFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${GetShowDocumentActionsBidFragmentDoc}
${BidInfoCardStatusTileBidFragmentDoc}
${AcceptedBidDetailsBidFragmentDoc}
${BidPageDocumentActionsBidFragmentDoc}
${GetIsBrokerForBidBidFragmentDoc}`;
export const TransactionFeeBreakdownTransactionFragmentDoc = gql`
    fragment TransactionFeeBreakdownTransaction on Transaction {
  id
  numShares
  pricePerShare
  state
  commission {
    ...TransactionFeeBreakdownCommission
  }
  feeDiscountApplications {
    id
    ...BidFeeDiscountBreakdownFeeDiscountApplication
  }
}
    ${TransactionFeeBreakdownCommissionFragmentDoc}
${BidFeeDiscountBreakdownFeeDiscountApplicationFragmentDoc}`;
export const SellerTransactionInfoCardBidFragmentDoc = gql`
    fragment SellerTransactionInfoCardBid on Bid {
  id
  displayId
  transaction {
    id
    numShares
    pricePerShare
    company {
      id
      ...FocusedShareDetailsHeaderCompany
    }
    ...UseDocumentSignersTransaction
    ...TransactionFeeBreakdownTransaction
  }
  numShares
  ...GetBidNumSharesActualBid
  ...GetShowDocumentActionsBid
  ...BidInfoCardStatusTileBid
  ...AcceptedBidDetailsBid
  ...BidPageDocumentActionsBid
}
    ${FocusedShareDetailsHeaderCompanyFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${TransactionFeeBreakdownTransactionFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${GetShowDocumentActionsBidFragmentDoc}
${BidInfoCardStatusTileBidFragmentDoc}
${AcceptedBidDetailsBidFragmentDoc}
${BidPageDocumentActionsBidFragmentDoc}`;
export const TransactionInfoCardBidFragmentDoc = gql`
    fragment TransactionInfoCardBid on Bid {
  id
  transaction {
    id
    history {
      ...BidInfoCardBidTransactionHistory
    }
    feeDiscountApplications {
      id
      ...BidFeeDiscountBreakdownFeeDiscountApplication
    }
  }
  ...GetIsBuyerForBidBid
  ...BuyerTransactionInfoCardBid
  ...BrokerTransactionInfoCardBid
  ...SellerTransactionInfoCardBid
}
    ${BidInfoCardBidTransactionHistoryFragmentDoc}
${BidFeeDiscountBreakdownFeeDiscountApplicationFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${BuyerTransactionInfoCardBidFragmentDoc}
${BrokerTransactionInfoCardBidFragmentDoc}
${SellerTransactionInfoCardBidFragmentDoc}`;
export const BidInfoBidFragmentDoc = gql`
    fragment BidInfoBid on Bid {
  id
  standingBid {
    id
    ...BidInfoStandingBid
  }
  transaction {
    id
  }
  listing {
    id
    ...ListingPageListingInfoCardListing
  }
  ...BidActionsBid
  ...BidInfoCardBid
  ...GetIsBuyerForBidBid
  ...GetAvailableBidActionsBid
  ...TransactionInfoCardBid
}
    ${BidInfoStandingBidFragmentDoc}
${ListingPageListingInfoCardListingFragmentDoc}
${BidActionsBidFragmentDoc}
${BidInfoCardBidFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${TransactionInfoCardBidFragmentDoc}`;
export const UserBankAccountFragmentDoc = gql`
    fragment UserBankAccount on BankAccount {
  id
  insertedAt
  last4
  updatedAt
  verificationStatus
  verifiedAt
  verifiedById
  lastUsedTransaction {
    id
    bid {
      id
      displayId
    }
    company {
      id
      name
      logoUrl
    }
  }
}
    `;
export const BidPageBrokerBidderActivityBidFragmentDoc = gql`
    fragment BidPageBrokerBidderActivityBid on Bid {
  id
  state
  buyerEmail
  numShares
  pricePerShare
  listing {
    id
    company {
      id
      name
      securitySpecialist {
        id
        user {
          email
        }
        marketAnalyst {
          email
        }
      }
      ...GetAvailableCompanyActionsCompany
      ...PlaceStandingBidModalCompany
    }
  }
}
    ${GetAvailableCompanyActionsCompanyFragmentDoc}
${PlaceStandingBidModalCompanyFragmentDoc}`;
export const BidPageBrokerListerActivityBidFragmentDoc = gql`
    fragment BidPageBrokerListerActivityBid on Bid {
  id
  listing {
    id
    state
    sellerEmail
    company {
      id
      name
      securitySpecialist {
        id
        user {
          email
        }
        marketAnalyst {
          email
        }
      }
    }
  }
}
    `;
export const BidPageBrokerActivityBidFragmentDoc = gql`
    fragment BidPageBrokerActivityBid on Bid {
  id
  listing {
    id
    ...GetIsBrokerForListingListing
  }
  ...GetIsBrokerForBidBid
  ...BidPageBrokerBidderActivityBid
  ...BidPageBrokerListerActivityBid
}
    ${GetIsBrokerForListingListingFragmentDoc}
${GetIsBrokerForBidBidFragmentDoc}
${BidPageBrokerBidderActivityBidFragmentDoc}
${BidPageBrokerListerActivityBidFragmentDoc}`;
export const GetDiscussionTopicCounterpartyShortTextListingFragmentDoc = gql`
    fragment GetDiscussionTopicCounterpartyShortTextListing on Listing {
  id
  sellerId
}
    `;
export const MessageCounterpartyCardListingFragmentDoc = gql`
    fragment MessageCounterpartyCardListing on Listing {
  id
  fromHiive
  isHiiveSpv
  ...GetDiscussionTopicCounterpartyTextListing
  ...GetDiscussionTopicCounterpartyShortTextListing
}
    ${GetDiscussionTopicCounterpartyTextListingFragmentDoc}
${GetDiscussionTopicCounterpartyShortTextListingFragmentDoc}`;
export const TransactingEntityFragmentDoc = gql`
    fragment TransactingEntity on Transaction {
  id
  state
  buyerEntity {
    ...BasicUserEntity
  }
  sellerEntity {
    ...BasicUserEntity
  }
}
    ${BasicUserEntityFragmentDoc}`;
export const BidPageBuyerActivityPlaceStandingBidCtaCardBidFragmentDoc = gql`
    fragment BidPageBuyerActivityPlaceStandingBidCTACardBid on Bid {
  id
  numShares
  pricePerShare
  company {
    ...PlaceStandingBidModalCompany
  }
}
    ${PlaceStandingBidModalCompanyFragmentDoc}`;
export const BidPageBuyerActivityBidFragmentDoc = gql`
    fragment BidPageBuyerActivityBid on Bid {
  id
  listing {
    id
    ...MessageCounterpartyCardListing
  }
  discussion {
    id
    ...DiscussionThreadCardDiscussion
  }
  company {
    id
    ...GetAvailableCompanyActionsCompany
  }
  transaction {
    ...TransactingEntity
  }
  ...GetAvailableBidActionsBid
  ...BidPageBuyerActivityPlaceStandingBidCTACardBid
}
    ${MessageCounterpartyCardListingFragmentDoc}
${DiscussionThreadCardDiscussionFragmentDoc}
${GetAvailableCompanyActionsCompanyFragmentDoc}
${TransactingEntityFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${BidPageBuyerActivityPlaceStandingBidCtaCardBidFragmentDoc}`;
export const BidPageSellerActivityBidTransactionFragmentDoc = gql`
    fragment BidPageSellerActivityBidTransaction on Transaction {
  id
  sellerBankAccountId
  sellerBankAccountRequestStatus
  sellerBankAccount {
    ...UserBankAccount
  }
  sellerEntity {
    ...TransactingEntityItemEntity
  }
}
    ${UserBankAccountFragmentDoc}
${TransactingEntityItemEntityFragmentDoc}`;
export const BidPageSellerActivityBidFragmentDoc = gql`
    fragment BidPageSellerActivityBid on Bid {
  id
  listing {
    id
    ...MessageCounterpartyCardListing
  }
  transaction {
    ...BidPageSellerActivityBidTransaction
    ...TransactingEntity
  }
  discussion {
    id
    ...DiscussionThreadCardDiscussion
  }
  ...GetAvailableBidActionsBid
}
    ${MessageCounterpartyCardListingFragmentDoc}
${BidPageSellerActivityBidTransactionFragmentDoc}
${TransactingEntityFragmentDoc}
${DiscussionThreadCardDiscussionFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}`;
export const BidActivityBidFragmentDoc = gql`
    fragment BidActivityBid on Bid {
  id
  actualNumSharesVisible
  pricePerShare
  counterpartyId
  brokerCounterpartyId
  counterpartyInstitutionId
  commission {
    ...BidPageFeeBreakdownCommission
  }
  transaction {
    id
    state
    pricePerShare
    numShares
    transferMethod
    sellerBrokerId
    sellerBankAccountId
    sellerBankAccountRequestStatus
    sellerBankAccount {
      ...UserBankAccount
    }
    sellerId
    buyerId
    buyerBrokerId
    company {
      name
    }
    ...TransactionModificationFragmentTransaction
  }
  listing {
    transferMethod
  }
  ...BidPageBrokerActivityBid
  ...BidPageBuyerActivityBid
  ...BidPageSellerActivityBid
  ...GetIsBuyerForBidBid
  ...GetBidNumSharesActualBid
}
    ${BidPageFeeBreakdownCommissionFragmentDoc}
${UserBankAccountFragmentDoc}
${TransactionModificationFragmentTransactionFragmentDoc}
${BidPageBrokerActivityBidFragmentDoc}
${BidPageBuyerActivityBidFragmentDoc}
${BidPageSellerActivityBidFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}`;
export const BidPageContentBidFragmentDoc = gql`
    fragment BidPageContentBid on Bid {
  id
  acceptedAt
  state
  company {
    id
    myPermissionsV2
    tradeable
  }
  ...BidInfoBid
  ...BidActivityBid
}
    ${BidInfoBidFragmentDoc}
${BidActivityBidFragmentDoc}`;
export const BidPageBidFragmentDoc = gql`
    fragment BidPageBid on Bid {
  id
  ...BidPageContentBid
}
    ${BidPageContentBidFragmentDoc}`;
export const BuyerCounterBidInfoCardFragmentDoc = gql`
    fragment BuyerCounterBidInfoCard on Bid {
  id
  counteredAt
  placedAt
  insertedAt
  pricePerShare
  fromHiive
  state
  counterpartyId
  displayId
  brokerId
  expireAt
  transaction {
    id
    state
    rofr
    history {
      ...BidInfoCardBidTransactionHistory
    }
    document {
      id
    }
    ...UseDocumentSignersTransaction
  }
  listing {
    id
    isHiiveSpv
    company {
      id
      ...FocusedShareDetailsHeaderCompany
    }
  }
  feeDiscountApplications {
    ...FeeDiscountApplicationType
  }
  ...GetIsBuyerForBidBid
  ...GetAvailableBidActionsBid
  ...GetBidNumSharesActualBid
  ...AcceptBidModalBid
  ...BidPageDocumentActionsBid
  ...ModifyCounterBidModalBid
  ...WithdrawCounterBidModalBid
  ...AcceptedBidDetailsBid
  ...CounterBidFeeBreakdownBid
  ...BidFeeBreakdownBid
  ...BidInfoCardStatusTileBid
  ...BidActionsBid
}
    ${BidInfoCardBidTransactionHistoryFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${FocusedShareDetailsHeaderCompanyFragmentDoc}
${FeeDiscountApplicationTypeFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${AcceptBidModalBidFragmentDoc}
${BidPageDocumentActionsBidFragmentDoc}
${ModifyCounterBidModalBidFragmentDoc}
${WithdrawCounterBidModalBidFragmentDoc}
${AcceptedBidDetailsBidFragmentDoc}
${CounterBidFeeBreakdownBidFragmentDoc}
${BidFeeBreakdownBidFragmentDoc}
${BidInfoCardStatusTileBidFragmentDoc}
${BidActionsBidFragmentDoc}`;
export const CounterBidInfoCardFragmentDoc = gql`
    fragment CounterBidInfoCard on Bid {
  id
  counteredAt
  placedAt
  insertedAt
  pricePerShare
  fromHiive
  state
  counterpartyId
  displayId
  brokerId
  expireAt
  transaction {
    id
    state
    rofr
    history {
      ...BidInfoCardBidTransactionHistory
    }
    document {
      id
    }
    ...UseDocumentSignersTransaction
  }
  listing {
    id
    isHiiveSpv
    company {
      id
      ...FocusedShareDetailsHeaderCompany
    }
  }
  feeDiscountApplications {
    ...FeeDiscountApplicationType
  }
  ...GetIsBuyerForBidBid
  ...GetAvailableBidActionsBid
  ...GetBidNumSharesActualBid
  ...AcceptBidModalBid
  ...BidPageDocumentActionsBid
  ...ModifyCounterBidModalBid
  ...WithdrawCounterBidModalBid
  ...AcceptedBidDetailsBid
  ...CounterBidFeeBreakdownBid
  ...BidFeeBreakdownBid
  ...BuyerCounterBidInfoCard
  ...BidInfoCardStatusTileBid
}
    ${BidInfoCardBidTransactionHistoryFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${FocusedShareDetailsHeaderCompanyFragmentDoc}
${FeeDiscountApplicationTypeFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${AcceptBidModalBidFragmentDoc}
${BidPageDocumentActionsBidFragmentDoc}
${ModifyCounterBidModalBidFragmentDoc}
${WithdrawCounterBidModalBidFragmentDoc}
${AcceptedBidDetailsBidFragmentDoc}
${CounterBidFeeBreakdownBidFragmentDoc}
${BidFeeBreakdownBidFragmentDoc}
${BuyerCounterBidInfoCardFragmentDoc}
${BidInfoCardStatusTileBidFragmentDoc}`;
export const SellerCounterBidInfoCardFragmentDoc = gql`
    fragment SellerCounterBidInfoCard on Bid {
  id
  counteredAt
  placedAt
  insertedAt
  pricePerShare
  fromHiive
  state
  counterpartyId
  displayId
  brokerId
  expireAt
  transaction {
    id
    state
    rofr
    history {
      ...BidInfoCardBidTransactionHistory
    }
    document {
      id
    }
    ...UseDocumentSignersTransaction
  }
  listing {
    id
    isHiiveSpv
    company {
      id
      ...FocusedShareDetailsHeaderCompany
    }
  }
  ...GetIsBuyerForBidBid
  ...GetAvailableBidActionsBid
  ...GetBidNumSharesActualBid
  ...AcceptBidModalBid
  ...BidPageDocumentActionsBid
  ...ModifyCounterBidModalBid
  ...WithdrawCounterBidModalBid
  ...AcceptBidModalBid
  ...AcceptedBidDetailsBid
  ...BidFeeBreakdownBid
}
    ${BidInfoCardBidTransactionHistoryFragmentDoc}
${UseDocumentSignersTransactionFragmentDoc}
${FocusedShareDetailsHeaderCompanyFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${AcceptBidModalBidFragmentDoc}
${BidPageDocumentActionsBidFragmentDoc}
${ModifyCounterBidModalBidFragmentDoc}
${WithdrawCounterBidModalBidFragmentDoc}
${AcceptedBidDetailsBidFragmentDoc}
${BidFeeBreakdownBidFragmentDoc}`;
export const BidHighFeesWarningBidFragmentDoc = gql`
    fragment BidHighFeesWarningBid on Bid {
  ...GetCanCounterNumberOfSharesBid
}
    ${GetCanCounterNumberOfSharesBidFragmentDoc}`;
export const WithdrawBidModalBidFragmentDoc = gql`
    fragment WithdrawBidModalBid on Bid {
  id
  displayId
}
    `;
export const BidBuyerActionsTileBidFragmentDoc = gql`
    fragment BidBuyerActionsTileBid on Bid {
  id
  state
  brokerId
  listing {
    id
    company {
      id
      ...ModifyBidModalCompany
    }
    ...ModifyBidModalListing
  }
  ...GetAvailableBidActionsBid
  ...GetIsBuyerForBidBid
  ...ModifyBidModalBid
  ...WithdrawBidModalBid
}
    ${ModifyBidModalCompanyFragmentDoc}
${ModifyBidModalListingFragmentDoc}
${GetAvailableBidActionsBidFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}
${ModifyBidModalBidFragmentDoc}
${WithdrawBidModalBidFragmentDoc}`;
export const BidInfoCardBidShareDetailsBidFragmentDoc = gql`
    fragment BidInfoCardBidShareDetailsBid on Bid {
  id
  numShares
  ...GetBidNumSharesActualBid
  ...GetNumOfSharesBid
  ...GetPricePerShareBid
  ...GetIsBuyerForBidBid
}
    ${GetBidNumSharesActualBidFragmentDoc}
${GetNumOfSharesBidFragmentDoc}
${GetPricePerShareBidFragmentDoc}
${GetIsBuyerForBidBidFragmentDoc}`;
export const BidInfoCardCounterBidShareDetailsBidFragmentDoc = gql`
    fragment BidInfoCardCounterBidShareDetailsBid on Bid {
  id
  counterNumShares
  counterPricePerShare
  numShares
  ...GetIsBuyerForBidBid
  ...GetBidNumSharesActualBid
  ...GetNumOfSharesBid
  ...GetPricePerShareBid
}
    ${GetIsBuyerForBidBidFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${GetNumOfSharesBidFragmentDoc}
${GetPricePerShareBidFragmentDoc}`;
export const BrokerSellerFeeBreakdownFragmentDoc = gql`
    fragment BrokerSellerFeeBreakdown on Bid {
  id
  pricePerShare
  transaction {
    id
    state
  }
  commission {
    ...BidPageFeeBreakdownCommission
  }
  ...GetBidNumSharesActualBid
}
    ${BidPageFeeBreakdownCommissionFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}`;
export const CounterBidFeeBreakdownCommissionInfoFragmentDoc = gql`
    fragment CounterBidFeeBreakdownCommissionInfo on CommissionInfo {
  totalValue
  flatFeeAmount
  commissionAmount
  netValue
  netFees
}
    `;
export const TltAlternateSignerPageTransactionFragmentDoc = gql`
    fragment TltAlternateSignerPageTransaction on TltAlternateSignerTransaction {
  id
  transferMethod
  numShares
  pricePerShare
  pendingModification {
    id
    pricePerShare
    numShares
  }
  bid {
    id
    displayId
    state
    numShares
    pricePerShare
    listing {
      id
      shareTypeV2
      shareSeriesMakeup {
        id
        shareSeries
      }
    }
    company {
      id
      name
      logoUrl
    }
  }
}
    `;
export const TltAlternateSignerPageAlternateSignerFragmentDoc = gql`
    fragment TltAlternateSignerPageAlternateSigner on TltAlternateSigner {
  id
  firstName
  lastName
  documentId
  institutionId
  sender {
    id
    name
    investorType
    email
  }
  delegateUser {
    name
  }
  document {
    id
    type
    signers {
      userId
      institutionId
      alternateSignerId
    }
  }
  transaction {
    ...TltAlternateSignerPageTransaction
  }
}
    ${TltAlternateSignerPageTransactionFragmentDoc}`;
export const ActivityCardBidFragmentDoc = gql`
    fragment ActivityCardBid on Bid {
  id
  buyerId
  buyerInstitutionId
  state
  standingBid {
    id
  }
  ...GetIsBuyerForBidBid
}
    ${GetIsBuyerForBidBidFragmentDoc}`;
export const ActivityCardDocumentSignersFragmentDoc = gql`
    fragment ActivityCardDocumentSigners on DocumentSigner {
  id
  userId
  institutionId
  alternateSignerId
}
    `;
export const ActivityCardDocumentFragmentDoc = gql`
    fragment ActivityCardDocument on Document {
  id
  type
  signers {
    id
    ...ActivityCardDocumentSigners
  }
}
    ${ActivityCardDocumentSignersFragmentDoc}`;
export const ActivityCardTransactionCounterpartyFragmentDoc = gql`
    fragment ActivityCardTransactionCounterparty on Counterparty {
  id
  name
  institutionId
}
    `;
export const ActivityCardTransactionFragmentDoc = gql`
    fragment ActivityCardTransaction on Transaction {
  id
  state
  signingProcedure
  document {
    id
    ...ActivityCardDocument
    ...UseHaveISignedDocumentDocument
  }
  counterparty {
    id
    ...ActivityCardTransactionCounterparty
  }
}
    ${ActivityCardDocumentFragmentDoc}
${UseHaveISignedDocumentDocumentFragmentDoc}
${ActivityCardTransactionCounterpartyFragmentDoc}`;
export const ListingPageBuyerActivityTransactionFragmentDoc = gql`
    fragment ListingPageBuyerActivityTransaction on Transaction {
  id
  bid {
    id
    displayId
    pricePerShare
    company {
      id
      ...ActivityCardCompany
    }
    ...GetBidNumSharesActualBid
    ...ActivityCardBid
  }
  ...ActivityCardTransaction
  ...MakeUrlTransaction
}
    ${ActivityCardCompanyFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${ActivityCardBidFragmentDoc}
${ActivityCardTransactionFragmentDoc}
${MakeUrlTransactionFragmentDoc}`;
export const GetIsEligibleBuyerForListingListingFragmentDoc = gql`
    fragment GetIsEligibleBuyerForListingListing on Listing {
  id
  ...GetIsSellerForListingListing
}
    ${GetIsSellerForListingListingFragmentDoc}`;
export const GetIsBidDiscussionBidFragmentDoc = gql`
    fragment GetIsBidDiscussionBid on Bid {
  id
  discussion {
    id
  }
}
    `;
export const ListingPageBidCardBidFragmentDoc = gql`
    fragment ListingPageBidCardBid on Bid {
  id
  displayId
  expireAt
  companyId
  discussion {
    id
    numUnreadMessages
    totalNumMessages
  }
  pricePerShare
  state
  withdrawnAt
  ...GetBidNumSharesActualBid
  ...BrokerBidActivityCardTimestampsBid
}
    ${GetBidNumSharesActualBidFragmentDoc}
${BrokerBidActivityCardTimestampsBidFragmentDoc}`;
export const ListingPageSellerActivityBidFragmentDoc = gql`
    fragment ListingPageSellerActivityBid on Bid {
  id
  ...ListingPageBidCardBid
}
    ${ListingPageBidCardBidFragmentDoc}`;
export const FilterOpenBidsBidFragmentDoc = gql`
    fragment FilterOpenBidsBid on Bid {
  id
  state
}
    `;
export const FilterPastBidsBidFragmentDoc = gql`
    fragment FilterPastBidsBid on Bid {
  id
  state
  rejectionReason
}
    `;
export const ListingPageSellerActivityListingFragmentDoc = gql`
    fragment ListingPageSellerActivityListing on Listing {
  id
  companyId
  bids {
    id
    rejectionReason
    state
    ...GetIsBidDiscussionBid
    ...ListingPageSellerActivityBid
    ...FilterOpenBidsBid
    ...FilterPastBidsBid
  }
}
    ${GetIsBidDiscussionBidFragmentDoc}
${ListingPageSellerActivityBidFragmentDoc}
${FilterOpenBidsBidFragmentDoc}
${FilterPastBidsBidFragmentDoc}`;
export const ListingPageBuyerActivityBidFragmentDoc = gql`
    fragment ListingPageBuyerActivityBid on Bid {
  id
  discussion {
    id
  }
  state
  ...ListingPageBidCardBid
}
    ${ListingPageBidCardBidFragmentDoc}`;
export const ListingPageBuyerActivityListingCompanyFragmentDoc = gql`
    fragment ListingPageBuyerActivityListingCompany on Company {
  id
  name
  requiresApprovedBuyers
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
}
    `;
export const ListingPageBuyerActivityPlaceBidCardPlaceBidListingFragmentDoc = gql`
    fragment ListingPageBuyerActivityPlaceBidCardPlaceBidListing on Listing {
  id
  displayId
  acceptPartialBid
  company {
    name
    minPostPps
    maxPostPps
  }
  ...GetNumSharesAvailableRoundedListing
}
    ${GetNumSharesAvailableRoundedListingFragmentDoc}`;
export const ListingPageBuyerActivityPlaceBidCardListingFragmentDoc = gql`
    fragment ListingPageBuyerActivityPlaceBidCardListing on Listing {
  id
  listingPricePerShare
  ...GetNumSharesAvailableRoundedListing
  ...ListingPageBuyerActivityPlaceBidCardPlaceBidListing
}
    ${GetNumSharesAvailableRoundedListingFragmentDoc}
${ListingPageBuyerActivityPlaceBidCardPlaceBidListingFragmentDoc}`;
export const ListingPageBuyerActivityListingFragmentDoc = gql`
    fragment ListingPageBuyerActivityListing on Listing {
  id
  bids {
    ...ListingPageBuyerActivityBid
  }
  company {
    ...ListingPageBuyerActivityListingCompany
  }
  myPermissionsV2
  ...ListingPageBuyerActivityPlaceBidCardListing
  ...MessageCounterpartyCardListing
}
    ${ListingPageBuyerActivityBidFragmentDoc}
${ListingPageBuyerActivityListingCompanyFragmentDoc}
${ListingPageBuyerActivityPlaceBidCardListingFragmentDoc}
${MessageCounterpartyCardListingFragmentDoc}`;
export const ListingPageBrokerCounterpartyActivityBidFragmentDoc = gql`
    fragment ListingPageBrokerCounterpartyActivityBid on Bid {
  id
  ...ListingPageBidCardBid
}
    ${ListingPageBidCardBidFragmentDoc}`;
export const ListingPageBrokerCounterpartyActivityPlaceStandingBidCtaCardCompanyFragmentDoc = gql`
    fragment ListingPageBrokerCounterpartyActivityPlaceStandingBidCtaCardCompany on Company {
  id
  name
  ...PlaceStandingBidModalCompany
}
    ${PlaceStandingBidModalCompanyFragmentDoc}`;
export const ListingPageBrokerCounterpartyActivitySubmitBidCardSubmitBidListingFragmentDoc = gql`
    fragment ListingPageBrokerCounterpartyActivitySubmitBidCardSubmitBidListing on Listing {
  id
  acceptPartialBid
  company {
    id
    name
    minPostPps
    maxPostPps
  }
  ...GetNumSharesAvailableRoundedListing
}
    ${GetNumSharesAvailableRoundedListingFragmentDoc}`;
export const ListingPageBrokerCounterpartyActivitySubmitBidCardListingFragmentDoc = gql`
    fragment ListingPageBrokerCounterpartyActivitySubmitBidCardListing on Listing {
  id
  listingPricePerShare
  sellerId
  ...GetNumSharesAvailableRoundedListing
  ...ListingPageBrokerCounterpartyActivitySubmitBidCardSubmitBidListing
}
    ${GetNumSharesAvailableRoundedListingFragmentDoc}
${ListingPageBrokerCounterpartyActivitySubmitBidCardSubmitBidListingFragmentDoc}`;
export const ListingPageBrokerCounterpartyActivityListingFragmentDoc = gql`
    fragment ListingPageBrokerCounterpartyActivityListing on Listing {
  id
  bids {
    id
    state
    ...ListingPageBrokerCounterpartyActivityBid
  }
  company {
    name
    ...GetSecuritySpecialistContactCompany
    ...ListingPageBrokerCounterpartyActivityPlaceStandingBidCtaCardCompany
  }
  myPermissionsV2
  solicited
  isHiiveSpv
  ...ListingPageBrokerCounterpartyActivitySubmitBidCardListing
}
    ${ListingPageBrokerCounterpartyActivityBidFragmentDoc}
${GetSecuritySpecialistContactCompanyFragmentDoc}
${ListingPageBrokerCounterpartyActivityPlaceStandingBidCtaCardCompanyFragmentDoc}
${ListingPageBrokerCounterpartyActivitySubmitBidCardListingFragmentDoc}`;
export const ListingPageBrokerListerActivityListingFragmentDoc = gql`
    fragment ListingPageBrokerListerActivityListing on Listing {
  id
  state
  sellerEmail
  company {
    id
    name
    securitySpecialist {
      id
      user {
        id
        email
      }
      marketAnalyst {
        id
        email
      }
    }
  }
  bids {
    id
    state
    ...FilterPastBidsBid
    ...FilterOpenBidsBid
    ...ListingPageBidCardBid
  }
}
    ${FilterPastBidsBidFragmentDoc}
${FilterOpenBidsBidFragmentDoc}
${ListingPageBidCardBidFragmentDoc}`;
export const ListingPageListingActivityListingFragmentDoc = gql`
    fragment ListingPageListingActivityListing on Listing {
  id
  ...GetIsBrokerForListingListing
  ...GetIsEligibleBuyerForListingListing
  ...GetIsSellerForListingListing
  ...ListingPageSellerActivityListing
  ...ListingPageBuyerActivityListing
  ...ListingPageBrokerCounterpartyActivityListing
  ...ListingPageBrokerListerActivityListing
}
    ${GetIsBrokerForListingListingFragmentDoc}
${GetIsEligibleBuyerForListingListingFragmentDoc}
${GetIsSellerForListingListingFragmentDoc}
${ListingPageSellerActivityListingFragmentDoc}
${ListingPageBuyerActivityListingFragmentDoc}
${ListingPageBrokerCounterpartyActivityListingFragmentDoc}
${ListingPageBrokerListerActivityListingFragmentDoc}`;
export const GetShowListingActivityListingFragmentDoc = gql`
    fragment GetShowListingActivityListing on Listing {
  id
  ...GetIsSellerForListingListing
}
    ${GetIsSellerForListingListingFragmentDoc}`;
export const ListingPageContentListingFragmentDoc = gql`
    fragment ListingPageContentListing on Listing {
  id
  ...ListingPageListingActivityListing
  ...ListingPageListingInfoCardListing
  ...GetShowListingActivityListing
}
    ${ListingPageListingActivityListingFragmentDoc}
${ListingPageListingInfoCardListingFragmentDoc}
${GetShowListingActivityListingFragmentDoc}`;
export const ListingPageListingFragmentDoc = gql`
    fragment ListingPageListing on Listing {
  id
  ...ListingPageContentListing
}
    ${ListingPageContentListingFragmentDoc}`;
export const PostingDetailsStandingBidFragmentDoc = gql`
    fragment PostingDetailsStandingBid on StandingBid {
  id
  shortId
  numSharesAvailable
  state
  version
  pricePerShare
  buyerEmail
  ...MakeUrlStandingBid
}
    ${MakeUrlStandingBidFragmentDoc}`;
export const StandingBidInfoCardDiscussionFragmentDoc = gql`
    fragment StandingBidInfoCardDiscussion on Discussion {
  id
}
    `;
export const StandingBidInfoDiscussionFragmentDoc = gql`
    fragment StandingBidInfoDiscussion on Discussion {
  id
  ...StandingBidInfoCardDiscussion
}
    ${StandingBidInfoCardDiscussionFragmentDoc}`;
export const StandingBidTransactionCardBidFragmentDoc = gql`
    fragment StandingBidTransactionCardBid on Bid {
  id
  numShares
  pricePerShare
  displayId
  transaction {
    id
    numShares
    pricePerShare
    ...ActivityCardTransaction
  }
  ...GetBidNumSharesActualBid
  ...ActivityCardBid
}
    ${ActivityCardTransactionFragmentDoc}
${GetBidNumSharesActualBidFragmentDoc}
${ActivityCardBidFragmentDoc}`;
export const StandingBidTransactionCardCompanyFragmentDoc = gql`
    fragment StandingBidTransactionCardCompany on Company {
  id
  ...ActivityCardCompany
}
    ${ActivityCardCompanyFragmentDoc}`;
export const StandingBidTransactionsStandingBidFragmentDoc = gql`
    fragment StandingBidTransactionsStandingBid on StandingBid {
  id
  bids {
    id
    transaction {
      id
    }
    ...StandingBidTransactionCardBid
  }
  company {
    id
    ...StandingBidTransactionCardCompany
  }
  ...GetIsBuyerForStandingBidStandingBid
}
    ${StandingBidTransactionCardBidFragmentDoc}
${StandingBidTransactionCardCompanyFragmentDoc}
${GetIsBuyerForStandingBidStandingBidFragmentDoc}`;
export const StandingBidBrokerActivityStandingBidFragmentDoc = gql`
    fragment StandingBidBrokerActivityStandingBid on StandingBid {
  id
  state
  buyerEmail
  company {
    id
    name
  }
  ...StandingBidTransactionsStandingBid
}
    ${StandingBidTransactionsStandingBidFragmentDoc}`;
export const StandingBidBuyerDiscussionsStandingBidFragmentDoc = gql`
    fragment StandingBidBuyerDiscussionsStandingBid on StandingBid {
  id
}
    `;
export const StandingBidBuyerActivityStandingBidFragmentDoc = gql`
    fragment StandingBidBuyerActivityStandingBid on StandingBid {
  id
  ...StandingBidTransactionsStandingBid
  ...StandingBidBuyerDiscussionsStandingBid
}
    ${StandingBidTransactionsStandingBidFragmentDoc}
${StandingBidBuyerDiscussionsStandingBidFragmentDoc}`;
export const GetDiscussionTopicCounterpartyShortTextStandingBidFragmentDoc = gql`
    fragment GetDiscussionTopicCounterpartyShortTextStandingBid on StandingBid {
  id
  buyerId
}
    `;
export const MessageCounterpartyCardStandingBidFragmentDoc = gql`
    fragment MessageCounterpartyCardStandingBid on StandingBid {
  id
  fromHiive
  ...GetDiscussionTopicCounterpartyTextStandingBid
  ...GetDiscussionTopicCounterpartyShortTextStandingBid
}
    ${GetDiscussionTopicCounterpartyTextStandingBidFragmentDoc}
${GetDiscussionTopicCounterpartyShortTextStandingBidFragmentDoc}`;
export const StandingBidSellerMessagingStandingBidFragmentDoc = gql`
    fragment StandingBidSellerMessagingStandingBid on StandingBid {
  id
  hasRequestedDiscussion
  ...GetAvailableStandingBidActionsStandingBid
  ...MessageCounterpartyCardStandingBid
}
    ${GetAvailableStandingBidActionsStandingBidFragmentDoc}
${MessageCounterpartyCardStandingBidFragmentDoc}`;
export const UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragmentDoc = gql`
    fragment UnaccreditedSellerAcceptStandingBidSequenceModalStandingBid on StandingBid {
  id
  pricePerShare
  minPartialAcceptNumShares
  allowPartialAccept
  displayId
  shortId
  shareSeriesOptions
  company {
    id
    name
    securitySpecialist {
      id
      user {
        id
        email
      }
      marketAnalyst {
        id
        email
      }
    }
    companyEligibilityCriterion {
      id
      text
    }
  }
  ...GetNumOfSharesStandingBid
}
    ${GetNumOfSharesStandingBidFragmentDoc}`;
export const AcceptStandingBidModalCompanyFragmentDoc = gql`
    fragment AcceptStandingBidModalCompany on Company {
  id
  name
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
  companyEligibilityCriterion {
    id
    text
  }
}
    `;
export const AcceptStandingBidModalStandingBidFragmentDoc = gql`
    fragment AcceptStandingBidModalStandingBid on StandingBid {
  id
  displayId
  version
  myPermissionsV2
  state
  acceptsShares
  shortId
  numSharesAvailable
  minPartialAcceptNumShares
  allowPartialAccept
  acceptsTransferMethods
  shareSeriesOptions
  pricePerShare
  company {
    ...AcceptStandingBidModalCompany
  }
}
    ${AcceptStandingBidModalCompanyFragmentDoc}`;
export const AcceptStandingBidCtaCardStandingBidFragmentDoc = gql`
    fragment AcceptStandingBidCTACardStandingBid on StandingBid {
  id
  ...UnaccreditedSellerAcceptStandingBidSequenceModalStandingBid
  ...AcceptStandingBidModalStandingBid
}
    ${UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragmentDoc}
${AcceptStandingBidModalStandingBidFragmentDoc}`;
export const StandingBidSellerActivityStandingBidFragmentDoc = gql`
    fragment StandingBidSellerActivityStandingBid on StandingBid {
  id
  ...GetAvailableStandingBidActionsStandingBid
  ...StandingBidSellerMessagingStandingBid
  ...AcceptStandingBidCTACardStandingBid
  ...StandingBidTransactionsStandingBid
}
    ${GetAvailableStandingBidActionsStandingBidFragmentDoc}
${StandingBidSellerMessagingStandingBidFragmentDoc}
${AcceptStandingBidCtaCardStandingBidFragmentDoc}
${StandingBidTransactionsStandingBidFragmentDoc}`;
export const BrokerSubmitListingSequenceModalCompanyFragmentDoc = gql`
    fragment BrokerSubmitListingSequenceModalCompany on Company {
  id
  ...CreateListingSelectCompanyInputCompany
}
    ${CreateListingSelectCompanyInputCompanyFragmentDoc}`;
export const StandingBidBrokerCounterpartyActivityStandingBidFragmentDoc = gql`
    fragment StandingBidBrokerCounterpartyActivityStandingBid on StandingBid {
  id
  buyerEmail
  company {
    id
    name
    ...BrokerSubmitListingSequenceModalCompany
  }
}
    ${BrokerSubmitListingSequenceModalCompanyFragmentDoc}`;
export const StandingBidActivityStandingBidFragmentDoc = gql`
    fragment StandingBidActivityStandingBid on StandingBid {
  id
  ...StandingBidBrokerActivityStandingBid
  ...StandingBidBuyerActivityStandingBid
  ...StandingBidSellerActivityStandingBid
  ...StandingBidBrokerCounterpartyActivityStandingBid
  ...GetIsBrokerForStandingBidStandingBid
  ...GetIsBrokerCounterpartyForStandingBidStandingBid
  ...GetIsBuyerForStandingBidStandingBid
}
    ${StandingBidBrokerActivityStandingBidFragmentDoc}
${StandingBidBuyerActivityStandingBidFragmentDoc}
${StandingBidSellerActivityStandingBidFragmentDoc}
${StandingBidBrokerCounterpartyActivityStandingBidFragmentDoc}
${GetIsBrokerForStandingBidStandingBidFragmentDoc}
${GetIsBrokerCounterpartyForStandingBidStandingBidFragmentDoc}
${GetIsBuyerForStandingBidStandingBidFragmentDoc}`;
export const StandingBidPageContentStandingBidFragmentDoc = gql`
    fragment StandingBidPageContentStandingBid on StandingBid {
  id
  ...StandingBidInfoStandingBid
  ...StandingBidActivityStandingBid
}
    ${StandingBidInfoStandingBidFragmentDoc}
${StandingBidActivityStandingBidFragmentDoc}`;
export const StandingBidPageStandingBidFragmentDoc = gql`
    fragment StandingBidPageStandingBid on StandingBid {
  id
  ...StandingBidPageContentStandingBid
}
    ${StandingBidPageContentStandingBidFragmentDoc}`;
export const TransactionExecutionPageWorkflowConditionFragmentDoc = gql`
    fragment TransactionExecutionPageWorkflowCondition on ExecutionCondition {
  id
  condition
}
    `;
export const TransactionExecutionPageWorkflowMilestoneFragmentDoc = gql`
    fragment TransactionExecutionPageWorkflowMilestone on ExecutionMilestoneCompletion {
  id
  milestone
  completedAt
}
    `;
export const TransactionExecutionPageTaskFragmentDoc = gql`
    fragment TransactionExecutionPageTask on ExecutionTask {
  id
  type
  status
  completedAt
  data {
    ... on ExecutionAnvilFillDocumentTaskData {
      embedUrl
    }
    ... on ExecutionAnvilSignDocumentTaskData {
      embedUrl
      anvilEtchSignUrl
      signers {
        signedAt
        actorType
        taskId
      }
    }
  }
}
    `;
export const TransactionExecutionPageWorkflowStepFragmentDoc = gql`
    fragment TransactionExecutionPageWorkflowStep on ExecutionStep {
  id
  name
  status
  type
  visibility
  tasks {
    ...TransactionExecutionPageTask
  }
  instructions {
    buyer
    seller
  }
}
    ${TransactionExecutionPageTaskFragmentDoc}`;
export const TransactionExecutionPageWorkflowFragmentDoc = gql`
    fragment TransactionExecutionPageWorkflow on ExecutionWorkflow {
  id
  status
  structure
  conditions {
    ...TransactionExecutionPageWorkflowCondition
  }
  milestoneOrder
  milestoneCompletions {
    ...TransactionExecutionPageWorkflowMilestone
  }
  steps {
    ...TransactionExecutionPageWorkflowStep
  }
}
    ${TransactionExecutionPageWorkflowConditionFragmentDoc}
${TransactionExecutionPageWorkflowMilestoneFragmentDoc}
${TransactionExecutionPageWorkflowStepFragmentDoc}`;
export const TransactionExecutionPageTransactionFragmentDoc = gql`
    fragment TransactionExecutionPageTransaction on Transaction {
  id
  numShares
  pricePerShare
  grossProceeds
  buyerId
  buyer {
    id
    name
  }
  sellerId
  seller {
    id
    name
  }
  buyerBrokerId
  buyerBroker {
    id
    name
  }
  sellerBrokerId
  sellerBroker {
    id
    name
  }
  representedBuyer {
    id
    firstName
    lastName
  }
  representedSeller {
    id
    firstName
    lastName
  }
  buyerInstitutionId
  buyerInstitution {
    id
    legalName
  }
  sellerInstitutionId
  sellerInstitution {
    id
    legalName
  }
  commission {
    commissionAmount
    flatFeeAmount
    netFees
  }
  company {
    id
    logoUrl
    name
  }
  bid {
    id
    listingId
    displayId
    discussion {
      id
      ...DiscussionThreadCardDiscussion
    }
  }
  workflow {
    ...TransactionExecutionPageWorkflow
  }
}
    ${DiscussionThreadCardDiscussionFragmentDoc}
${TransactionExecutionPageWorkflowFragmentDoc}`;
export const TransactionPageTransactionFragmentDoc = gql`
    fragment TransactionPageTransaction on Transaction {
  id
  bid {
    id
    ...BidPageContentBid
  }
}
    ${BidPageContentBidFragmentDoc}`;
export const HoldingSelectCompanyFragmentDoc = gql`
    fragment HoldingSelectCompany on Company {
  id
  name
  logoUrl
}
    `;
export const HoldingSelectCompanyEdgeFragmentDoc = gql`
    fragment HoldingSelectCompanyEdge on CompanyEdge {
  node {
    ...HoldingSelectCompany
  }
  cursor
}
    ${HoldingSelectCompanyFragmentDoc}`;
export const FullAccreditationQuestionFragmentDoc = gql`
    fragment FullAccreditationQuestion on AccreditationQuestion {
  id
  order
  text
  key
}
    `;
export const FullAccreditationAnswerFragmentDoc = gql`
    fragment FullAccreditationAnswer on AccreditationAnswer {
  question {
    ...FullAccreditationQuestion
  }
  text
}
    ${FullAccreditationQuestionFragmentDoc}`;
export const VeryBasicUserFragmentDoc = gql`
    fragment VeryBasicUser on VeryBasicUser {
  id
  name
  firstName
  lastName
  institutionId
}
    `;
export const BasicAlternateSignerFragmentDoc = gql`
    fragment BasicAlternateSigner on AlternateSigner {
  id
  name
  email
  senderId
  sender {
    ...VeryBasicUser
  }
  insertedAt
}
    ${VeryBasicUserFragmentDoc}`;
export const CompanyLastRoundDetailsFragmentDoc = gql`
    fragment CompanyLastRoundDetails on Company {
  name
  lastRoundSeries
  lastRoundDate
  lastRoundPricePerShare
  lastRoundRaised
  totalRaised
  lastRoundPostValuation
  myPermissionsV2
  tradeable
  status
  securitySpecialist {
    id
    user {
      id
      email
    }
    marketAnalyst {
      id
      email
    }
  }
  investors {
    id
    name
  }
  industries {
    id
    name
  }
  insertedAt
  updatedAt
  currentPrices {
    highestBid
    lowestAsk
    lastTransaction
  }
}
    `;
export const BasicCountryFragmentDoc = gql`
    fragment BasicCountry on Country {
  id
  name
  fullName
}
    `;
export const FullCountryFragmentDoc = gql`
    fragment FullCountry on Country {
  ...BasicCountry
  questions {
    ...FullAccreditationQuestion
  }
}
    ${BasicCountryFragmentDoc}
${FullAccreditationQuestionFragmentDoc}`;
export const FullFileUploadFragmentDoc = gql`
    fragment FullFileUpload on FileUpload {
  id
  uploaderId
  institutionId
  filename
  contentType
  url
}
    `;
export const EitherHoldingFragmentDoc = gql`
    fragment EitherHolding on EitherHolding {
  id
  numShares
  sellerId
  companyName
  companyId
}
    `;
export const FullInputErrorFragmentDoc = gql`
    fragment FullInputError on InputError {
  message
  type
  field
}
    `;
export const VeryBasicInstitutionFragmentDoc = gql`
    fragment VeryBasicInstitution on VeryBasicInstitution {
  id
  legalName
}
    `;
export const FullInvestmentGoalOptionFragmentDoc = gql`
    fragment FullInvestmentGoalOption on InvestmentGoalOption {
  id
  key
  question {
    id
    key
    text
  }
  text
}
    `;
export const FullSessionFragmentDoc = gql`
    fragment FullSession on Session {
  token
  refreshToken
  userId
}
    `;
export const DashboardMostActiveCompaniesCompanyFragmentDoc = gql`
    fragment DashboardMostActiveCompaniesCompany on Company {
  id
  name
  logoUrl
  activity {
    numOpenListings
    numOpenStandingBidsAndActiveBids
  }
}
    `;
export const ListingWithMyPermissionsFragmentDoc = gql`
    fragment ListingWithMyPermissions on Listing {
  myPermissionsV2
}
    `;
export const StandingBidWithMyPermissionsFragmentDoc = gql`
    fragment StandingBidWithMyPermissions on StandingBid {
  myPermissionsV2
}
    `;
export const InstitutionAccreditationPageCountryFragmentDoc = gql`
    fragment InstitutionAccreditationPageCountry on Country {
  id
  name
}
    `;
export const InstitutionAccreditationPageInstitutionFragmentDoc = gql`
    fragment InstitutionAccreditationPageInstitution on Institution {
  id
  country {
    ...InstitutionAccreditationPageCountry
  }
}
    ${InstitutionAccreditationPageCountryFragmentDoc}`;
export const InstitutionAccreditationPageUserFragmentDoc = gql`
    fragment InstitutionAccreditationPageUser on User {
  id
  accreditationQuestions {
    id
  }
  institution {
    ...InstitutionAccreditationPageInstitution
  }
}
    ${InstitutionAccreditationPageInstitutionFragmentDoc}`;
export const OnboardingUserFragmentDoc = gql`
    fragment OnboardingUser on User {
  ...BasicUser
  ...InstitutionAccreditationPageUser
  country {
    id
    name
  }
  institution {
    id
    isBrokerDealer
    legalSignatoryEmail
    legalSignatoryName
    accredited
    entityType
    suitable
    streetAddress
    isFinraInstitutionAccount
    caIsPermittedClient
    caAgreedToInvestmentRisk
    membershipAgreementSigned
    country {
      id
      name
    }
    accreditationAnswers {
      userId
      institutionId
      question {
        id
      }
    }
    jurisdictionOfIncorporation {
      id
    }
    investmentGoalOptions {
      id
    }
  }
  accreditationQuestions {
    id
  }
  investmentGoalOptions {
    id
  }
  watchlist {
    id
  }
  finraRegistered
  brokerInfo {
    id
    firstName
    lastName
  }
  caIsPermittedClient
  caAgreedToInvestmentRisk
  caCanEvaluateInvestmentRisk
  requiresFunding
  interestedInLoan
  agreedToCustomerAgreement
  hasHolding
}
    ${BasicUserFragmentDoc}
${InstitutionAccreditationPageUserFragmentDoc}`;
export const GetIsSenderUserFragmentDoc = gql`
    fragment GetIsSenderUser on User {
  id
}
    `;
export const GetIsSenderInstitutionUserFragmentDoc = gql`
    fragment GetIsSenderInstitutionUser on User {
  id
  institutionId
}
    `;
export const GetIsSensitiveMessageUserFragmentDoc = gql`
    fragment GetIsSensitiveMessageUser on User {
  id
  ...GetIsSenderUser
  ...GetIsSenderInstitutionUser
}
    ${GetIsSenderUserFragmentDoc}
${GetIsSenderInstitutionUserFragmentDoc}`;
export const GetDiscussionHasCounterpartyMessageUserFragmentDoc = gql`
    fragment GetDiscussionHasCounterpartyMessageUser on User {
  id
  ...GetIsSenderUser
  ...GetIsSenderInstitutionUser
}
    ${GetIsSenderUserFragmentDoc}
${GetIsSenderInstitutionUserFragmentDoc}`;
export const GetDiscussionHasCounterpartyMessageDiscussionFragmentDoc = gql`
    fragment GetDiscussionHasCounterpartyMessageDiscussion on Discussion {
  id
  mostRecentMessage {
    id
    ...GetIsSenderDiscussionMessage
    ...GetIsSenderInstitutionDiscussionMessage
  }
}
    ${GetIsSenderDiscussionMessageFragmentDoc}
${GetIsSenderInstitutionDiscussionMessageFragmentDoc}`;
export const GetDiscussionTopicCounterpartyShortTextUserFragmentDoc = gql`
    fragment GetDiscussionTopicCounterpartyShortTextUser on User {
  id
}
    `;
export const GetDiscussionTopicCounterpartyTextUserFragmentDoc = gql`
    fragment GetDiscussionTopicCounterpartyTextUser on User {
  id
  institutionId
  isHiiveUser
}
    `;
export const GetDiscussionIsSellerUserFragmentDoc = gql`
    fragment GetDiscussionIsSellerUser on User {
  id
  institutionId
}
    `;
export const GetIsBidDiscussionDiscussionFragmentDoc = gql`
    fragment GetIsBidDiscussionDiscussion on Discussion {
  id
}
    `;
export const FormatInvestorsInvestorFragmentDoc = gql`
    fragment FormatInvestorsInvestor on Investor {
  id
  name
}
    `;
export const GetIsListerUserFragmentDoc = gql`
    fragment GetIsListerUser on User {
  id
}
    `;
export const GetAvailableInstitutionActionsInstitutionFragmentDoc = gql`
    fragment GetAvailableInstitutionActionsInstitution on Institution {
  id
  myPermissionsV2
}
    `;
export const GetIsBrokerForBidUserFragmentDoc = gql`
    fragment GetIsBrokerForBidUser on User {
  id
}
    `;
export const GetIsBrokerForStandingBidUserFragmentDoc = gql`
    fragment GetIsBrokerForStandingBidUser on User {
  id
}
    `;
export const GetIsCounterpartyForBidUserFragmentDoc = gql`
    fragment GetIsCounterpartyForBidUser on User {
  id
  institutionId
}
    `;
export const GetIsSellerForListingUserFragmentDoc = gql`
    fragment GetIsSellerForListingUser on User {
  id
  institutionId
}
    `;
export const GetIsBuyerForStandingBidUserFragmentDoc = gql`
    fragment GetIsBuyerForStandingBidUser on User {
  id
  institutionId
}
    `;
export const GetIsBuyerForDiscussionUserFragmentDoc = gql`
    fragment GetIsBuyerForDiscussionUser on User {
  id
  ...GetIsSellerForListingUser
  ...GetIsBuyerForStandingBidUser
}
    ${GetIsSellerForListingUserFragmentDoc}
${GetIsBuyerForStandingBidUserFragmentDoc}`;
export const GetIsBrokerForListingUserFragmentDoc = gql`
    fragment GetIsBrokerForListingUser on User {
  id
}
    `;
export const GetIsInstitutionUserUserFragmentDoc = gql`
    fragment GetIsInstitutionUserUser on User {
  id
  institutionId
  investorStatus
}
    `;
export const HasUserRoleUserFragmentDoc = gql`
    fragment HasUserRoleUser on User {
  id
  roles
}
    `;
export const GetIsInstitutionViewerUserFragmentDoc = gql`
    fragment GetIsInstitutionViewerUser on User {
  id
  ...GetIsInstitutionUserUser
  ...HasUserRoleUser
}
    ${GetIsInstitutionUserUserFragmentDoc}
${HasUserRoleUserFragmentDoc}`;
export const GetShowListingActivityUserFragmentDoc = gql`
    fragment GetShowListingActivityUser on User {
  id
  investorType
  ...GetIsSellerForListingUser
  ...GetIsInstitutionViewerUser
}
    ${GetIsSellerForListingUserFragmentDoc}
${GetIsInstitutionViewerUserFragmentDoc}`;
export const GetListingBidCountListingFragmentDoc = gql`
    fragment GetListingBidCountListing on Listing {
  id
  numActiveBids
  numCounteredBids
}
    `;
export const GetListingHasBidsListingFragmentDoc = gql`
    fragment GetListingHasBidsListing on Listing {
  id
  ...GetListingBidCountListing
}
    ${GetListingBidCountListingFragmentDoc}`;
export const GetListingHasAcceptedBidPricesListingFragmentDoc = gql`
    fragment GetListingHasAcceptedBidPricesListing on Listing {
  id
  acceptedBidPrices
}
    `;
export const HasInvestorTypeUserFragmentDoc = gql`
    fragment HasInvestorTypeUser on User {
  id
  investorType
}
    `;
export const GetIsEligibleBuyerForListingUserFragmentDoc = gql`
    fragment GetIsEligibleBuyerForListingUser on User {
  id
  ...GetIsSellerForListingUser
  ...GetIsInstitutionUserUser
  ...HasUserRoleUser
  ...HasInvestorTypeUser
}
    ${GetIsSellerForListingUserFragmentDoc}
${GetIsInstitutionUserUserFragmentDoc}
${HasUserRoleUserFragmentDoc}
${HasInvestorTypeUserFragmentDoc}`;
export const GetIsPartiallySoldListingListingFragmentDoc = gql`
    fragment GetIsPartiallySoldListingListing on Listing {
  id
  state
  acceptPartialBid
  acceptedBidPrices
}
    `;
export const GetIsConditionallySoldListingListingFragmentDoc = gql`
    fragment GetIsConditionallySoldListingListing on Listing {
  id
  state
}
    `;
export const GetListingNumSharesOriginalListingFragmentDoc = gql`
    fragment GetListingNumSharesOriginalListing on Listing {
  id
  numSharesOriginalRounded
  numSharesOriginal
}
    `;
export const GetListingNumSharesAvailableListingFragmentDoc = gql`
    fragment GetListingNumSharesAvailableListing on Listing {
  id
  numSharesAvailableRounded
  numSharesAvailable
}
    `;
export const CalculateListingNumSharesSoldConditionallyListingFragmentDoc = gql`
    fragment CalculateListingNumSharesSoldConditionallyListing on Listing {
  id
  ...GetListingNumSharesOriginalListing
  ...GetListingNumSharesAvailableListing
}
    ${GetListingNumSharesOriginalListingFragmentDoc}
${GetListingNumSharesAvailableListingFragmentDoc}`;
export const GetListingHasConditionallySoldSharesListingFragmentDoc = gql`
    fragment GetListingHasConditionallySoldSharesListing on Listing {
  id
  ...CalculateListingNumSharesSoldConditionallyListing
}
    ${CalculateListingNumSharesSoldConditionallyListingFragmentDoc}`;
export const GetIsListingPartiallySoldListingFragmentDoc = gql`
    fragment GetIsListingPartiallySoldListing on Listing {
  id
  numSharesSoldConditionally
}
    `;
export const MakeUrlCompanyFragmentDoc = gql`
    fragment MakeUrlCompany on Company {
  id
}
    `;
export const MakeUrlDiscussionFragmentDoc = gql`
    fragment MakeUrlDiscussion on Discussion {
  id
  state
  standingBidTopic: topic {
    ...MakeUrlStandingBid
  }
  listingTopic: topic {
    ...MakeUrlListing
  }
}
    ${MakeUrlStandingBidFragmentDoc}
${MakeUrlListingFragmentDoc}`;
export const GetPostingTitleBidFragmentDoc = gql`
    fragment GetPostingTitleBid on Bid {
  id
}
    `;
export const GetPostingTitleDiscussionFragmentDoc = gql`
    fragment GetPostingTitleDiscussion on Discussion {
  id
}
    `;
export const GetTransactionIsPurchaseUserFragmentDoc = gql`
    fragment GetTransactionIsPurchaseUser on User {
  id
  institutionId
}
    `;
export const GetTransactionIsPurchaseTransactionFragmentDoc = gql`
    fragment GetTransactionIsPurchaseTransaction on Transaction {
  id
  bid {
    id
    buyerId
    buyerInstitutionId
  }
}
    `;
export const GetListingNumOfSharesListingFragmentDoc = gql`
    fragment GetListingNumOfSharesListing on Listing {
  id
  numSharesOriginal
  numSharesOriginalRounded
  numSharesAvailable
  numSharesAvailableRounded
}
    `;
export const GetIsBrokerUserFragmentDoc = gql`
    fragment GetIsBrokerUser on User {
  id
  investorType
}
    `;
export const GetIsBrokerCounterpartyForStandingBidUserFragmentDoc = gql`
    fragment GetIsBrokerCounterpartyForStandingBidUser on User {
  id
  ...GetIsBrokerUser
}
    ${GetIsBrokerUserFragmentDoc}`;
export const GetTransactionTitleTransactionFragmentDoc = gql`
    fragment GetTransactionTitleTransaction on Transaction {
  bid {
    standingBid {
      id
    }
  }
}
    `;
export const GetTransactionDisplayIdTransactionFragmentDoc = gql`
    fragment GetTransactionDisplayIdTransaction on Transaction {
  bid {
    displayId
    standingBid {
      displayId
    }
  }
}
    `;
export const GetTransactionExpirationDateTransactionFragmentDoc = gql`
    fragment GetTransactionExpirationDateTransaction on Transaction {
  expireAt
}
    `;
export const GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardMyActivityFragmentDoc = gql`
    fragment GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardMyActivity on UnaccreditedSellerActivity {
  pendingSales {
    id
  }
  completedSales {
    id
  }
}
    `;
export const GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardMyCompanyActivityFragmentDoc = gql`
    fragment GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardMyCompanyActivity on UnaccreditedSellerMyCompanyActivity {
  standingBidsWithDiscussions {
    id
  }
}
    `;
export const GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardCompanyActivityFragmentDoc = gql`
    fragment GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardCompanyActivity on Company {
  activity {
    myCompletedTransactions {
      id
    }
    myOpenTransactions {
      id
    }
    standingBidsWithDiscussions {
      id
    }
  }
}
    `;
export const GetUserInitialsUserFragmentDoc = gql`
    fragment GetUserInitialsUser on User {
  firstName
  lastName
}
    `;
export const GetIsUnaccreditedSellerUserFragmentDoc = gql`
    fragment GetIsUnaccreditedSellerUser on User {
  id
  investorType
}
    `;
export const GetIsBrokerTltAlternateSignerSenderFragmentDoc = gql`
    fragment GetIsBrokerTltAlternateSignerSender on TltAlternateSignerSender {
  id
  investorType
}
    `;
export const GetIsMembershipAgreementSignedUserFragmentDoc = gql`
    fragment GetIsMembershipAgreementSignedUser on User {
  id
  membershipAgreementSigned
  institution {
    id
    membershipAgreementSigned
  }
}
    `;
export const HasMultiFactorAuthenticationUserFragmentDoc = gql`
    fragment HasMultiFactorAuthenticationUser on User {
  id
  multiFactorAuthentications {
    app
    sms
  }
}
    `;
export const GetUserCountryUserFragmentDoc = gql`
    fragment GetUserCountryUser on User {
  id
  ...GetIsInstitutionUserUser
  country {
    id
    name
  }
  institution {
    id
    country {
      id
      name
    }
  }
}
    ${GetIsInstitutionUserUserFragmentDoc}`;
export const GetAvailableUserPermissionsUserFragmentDoc = gql`
    fragment GetAvailableUserPermissionsUser on User {
  id
  myPermissionsV2
}
    `;
export const UpdateIndividualAccreditationAnswerDocument = gql`
    mutation updateIndividualAccreditationAnswer($input: UpdateAccreditationAnswerInput!) {
  updateIndividualAccreditationAnswer(input: $input) {
    user {
      id
    }
  }
}
    `;
export type UpdateIndividualAccreditationAnswerMutationFn = Apollo.MutationFunction<UpdateIndividualAccreditationAnswerMutation, UpdateIndividualAccreditationAnswerMutationVariables>;

/**
 * __useUpdateIndividualAccreditationAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateIndividualAccreditationAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndividualAccreditationAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndividualAccreditationAnswerMutation, { data, loading, error }] = useUpdateIndividualAccreditationAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIndividualAccreditationAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndividualAccreditationAnswerMutation, UpdateIndividualAccreditationAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIndividualAccreditationAnswerMutation, UpdateIndividualAccreditationAnswerMutationVariables>(UpdateIndividualAccreditationAnswerDocument, options);
      }
export type UpdateIndividualAccreditationAnswerMutationHookResult = ReturnType<typeof useUpdateIndividualAccreditationAnswerMutation>;
export type UpdateIndividualAccreditationAnswerMutationResult = Apollo.MutationResult<UpdateIndividualAccreditationAnswerMutation>;
export type UpdateIndividualAccreditationAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateIndividualAccreditationAnswerMutation, UpdateIndividualAccreditationAnswerMutationVariables>;
export const HoldingsDocument = gql`
    query holdings {
  myHoldings {
    holdingsV2 {
      ...ListedHolding
      ...UnlistedHolding
    }
  }
}
    ${ListedHoldingFragmentDoc}
${UnlistedHoldingFragmentDoc}`;

/**
 * __useHoldingsQuery__
 *
 * To run a query within a React component, call `useHoldingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHoldingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHoldingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHoldingsQuery(baseOptions?: Apollo.QueryHookOptions<HoldingsQuery, HoldingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HoldingsQuery, HoldingsQueryVariables>(HoldingsDocument, options);
      }
export function useHoldingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HoldingsQuery, HoldingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HoldingsQuery, HoldingsQueryVariables>(HoldingsDocument, options);
        }
export type HoldingsQueryHookResult = ReturnType<typeof useHoldingsQuery>;
export type HoldingsLazyQueryHookResult = ReturnType<typeof useHoldingsLazyQuery>;
export type HoldingsQueryResult = Apollo.QueryResult<HoldingsQuery, HoldingsQueryVariables>;
export const InstitutionInvitationsDocument = gql`
    query institutionInvitations {
  institutionInvitations {
    ...InstitutionInvitationsTableInvitation
  }
}
    ${InstitutionInvitationsTableInvitationFragmentDoc}`;

/**
 * __useInstitutionInvitationsQuery__
 *
 * To run a query within a React component, call `useInstitutionInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstitutionInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<InstitutionInvitationsQuery, InstitutionInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstitutionInvitationsQuery, InstitutionInvitationsQueryVariables>(InstitutionInvitationsDocument, options);
      }
export function useInstitutionInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstitutionInvitationsQuery, InstitutionInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstitutionInvitationsQuery, InstitutionInvitationsQueryVariables>(InstitutionInvitationsDocument, options);
        }
export type InstitutionInvitationsQueryHookResult = ReturnType<typeof useInstitutionInvitationsQuery>;
export type InstitutionInvitationsLazyQueryHookResult = ReturnType<typeof useInstitutionInvitationsLazyQuery>;
export type InstitutionInvitationsQueryResult = Apollo.QueryResult<InstitutionInvitationsQuery, InstitutionInvitationsQueryVariables>;
export const InstitutionUsersDocument = gql`
    query institutionUsers {
  institutionUsers {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;

/**
 * __useInstitutionUsersQuery__
 *
 * To run a query within a React component, call `useInstitutionUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstitutionUsersQuery(baseOptions?: Apollo.QueryHookOptions<InstitutionUsersQuery, InstitutionUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstitutionUsersQuery, InstitutionUsersQueryVariables>(InstitutionUsersDocument, options);
      }
export function useInstitutionUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstitutionUsersQuery, InstitutionUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstitutionUsersQuery, InstitutionUsersQueryVariables>(InstitutionUsersDocument, options);
        }
export type InstitutionUsersQueryHookResult = ReturnType<typeof useInstitutionUsersQuery>;
export type InstitutionUsersLazyQueryHookResult = ReturnType<typeof useInstitutionUsersLazyQuery>;
export type InstitutionUsersQueryResult = Apollo.QueryResult<InstitutionUsersQuery, InstitutionUsersQueryVariables>;
export const UpdateCaIndividualInvestmentGoalsDocument = gql`
    mutation updateCaIndividualInvestmentGoals($input: UpdateCaIndividualInvestmentGoalsInput!) {
  updateCaIndividualInvestmentGoals(input: $input) {
    user {
      ...BasicUser
    }
  }
}
    ${BasicUserFragmentDoc}`;
export type UpdateCaIndividualInvestmentGoalsMutationFn = Apollo.MutationFunction<UpdateCaIndividualInvestmentGoalsMutation, UpdateCaIndividualInvestmentGoalsMutationVariables>;

/**
 * __useUpdateCaIndividualInvestmentGoalsMutation__
 *
 * To run a mutation, you first call `useUpdateCaIndividualInvestmentGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaIndividualInvestmentGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaIndividualInvestmentGoalsMutation, { data, loading, error }] = useUpdateCaIndividualInvestmentGoalsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCaIndividualInvestmentGoalsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaIndividualInvestmentGoalsMutation, UpdateCaIndividualInvestmentGoalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCaIndividualInvestmentGoalsMutation, UpdateCaIndividualInvestmentGoalsMutationVariables>(UpdateCaIndividualInvestmentGoalsDocument, options);
      }
export type UpdateCaIndividualInvestmentGoalsMutationHookResult = ReturnType<typeof useUpdateCaIndividualInvestmentGoalsMutation>;
export type UpdateCaIndividualInvestmentGoalsMutationResult = Apollo.MutationResult<UpdateCaIndividualInvestmentGoalsMutation>;
export type UpdateCaIndividualInvestmentGoalsMutationOptions = Apollo.BaseMutationOptions<UpdateCaIndividualInvestmentGoalsMutation, UpdateCaIndividualInvestmentGoalsMutationVariables>;
export const UpdateIndividualInvestmentGoalsDocument = gql`
    mutation updateIndividualInvestmentGoals($optionIds: [ID!]!) {
  updateIndividualInvestmentGoals(input: {optionIds: $optionIds}) {
    user {
      ...BasicUser
    }
  }
}
    ${BasicUserFragmentDoc}`;
export type UpdateIndividualInvestmentGoalsMutationFn = Apollo.MutationFunction<UpdateIndividualInvestmentGoalsMutation, UpdateIndividualInvestmentGoalsMutationVariables>;

/**
 * __useUpdateIndividualInvestmentGoalsMutation__
 *
 * To run a mutation, you first call `useUpdateIndividualInvestmentGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndividualInvestmentGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndividualInvestmentGoalsMutation, { data, loading, error }] = useUpdateIndividualInvestmentGoalsMutation({
 *   variables: {
 *      optionIds: // value for 'optionIds'
 *   },
 * });
 */
export function useUpdateIndividualInvestmentGoalsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndividualInvestmentGoalsMutation, UpdateIndividualInvestmentGoalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIndividualInvestmentGoalsMutation, UpdateIndividualInvestmentGoalsMutationVariables>(UpdateIndividualInvestmentGoalsDocument, options);
      }
export type UpdateIndividualInvestmentGoalsMutationHookResult = ReturnType<typeof useUpdateIndividualInvestmentGoalsMutation>;
export type UpdateIndividualInvestmentGoalsMutationResult = Apollo.MutationResult<UpdateIndividualInvestmentGoalsMutation>;
export type UpdateIndividualInvestmentGoalsMutationOptions = Apollo.BaseMutationOptions<UpdateIndividualInvestmentGoalsMutation, UpdateIndividualInvestmentGoalsMutationVariables>;
export const UpdateInstitutionInvestmentGoalsDocument = gql`
    mutation updateInstitutionInvestmentGoals($optionIds: [ID!]!) {
  updateInstitutionInvestmentGoals(input: {optionIds: $optionIds}) {
    institution {
      id
    }
  }
}
    `;
export type UpdateInstitutionInvestmentGoalsMutationFn = Apollo.MutationFunction<UpdateInstitutionInvestmentGoalsMutation, UpdateInstitutionInvestmentGoalsMutationVariables>;

/**
 * __useUpdateInstitutionInvestmentGoalsMutation__
 *
 * To run a mutation, you first call `useUpdateInstitutionInvestmentGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstitutionInvestmentGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstitutionInvestmentGoalsMutation, { data, loading, error }] = useUpdateInstitutionInvestmentGoalsMutation({
 *   variables: {
 *      optionIds: // value for 'optionIds'
 *   },
 * });
 */
export function useUpdateInstitutionInvestmentGoalsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstitutionInvestmentGoalsMutation, UpdateInstitutionInvestmentGoalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstitutionInvestmentGoalsMutation, UpdateInstitutionInvestmentGoalsMutationVariables>(UpdateInstitutionInvestmentGoalsDocument, options);
      }
export type UpdateInstitutionInvestmentGoalsMutationHookResult = ReturnType<typeof useUpdateInstitutionInvestmentGoalsMutation>;
export type UpdateInstitutionInvestmentGoalsMutationResult = Apollo.MutationResult<UpdateInstitutionInvestmentGoalsMutation>;
export type UpdateInstitutionInvestmentGoalsMutationOptions = Apollo.BaseMutationOptions<UpdateInstitutionInvestmentGoalsMutation, UpdateInstitutionInvestmentGoalsMutationVariables>;
export const UpdateInvestmentGoalsDocument = gql`
    mutation updateInvestmentGoals($optionIds: [ID!]!) {
  updateIndividualInvestmentGoals(input: {optionIds: $optionIds}) {
    user {
      ...BasicUser
    }
  }
}
    ${BasicUserFragmentDoc}`;
export type UpdateInvestmentGoalsMutationFn = Apollo.MutationFunction<UpdateInvestmentGoalsMutation, UpdateInvestmentGoalsMutationVariables>;

/**
 * __useUpdateInvestmentGoalsMutation__
 *
 * To run a mutation, you first call `useUpdateInvestmentGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestmentGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestmentGoalsMutation, { data, loading, error }] = useUpdateInvestmentGoalsMutation({
 *   variables: {
 *      optionIds: // value for 'optionIds'
 *   },
 * });
 */
export function useUpdateInvestmentGoalsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvestmentGoalsMutation, UpdateInvestmentGoalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvestmentGoalsMutation, UpdateInvestmentGoalsMutationVariables>(UpdateInvestmentGoalsDocument, options);
      }
export type UpdateInvestmentGoalsMutationHookResult = ReturnType<typeof useUpdateInvestmentGoalsMutation>;
export type UpdateInvestmentGoalsMutationResult = Apollo.MutationResult<UpdateInvestmentGoalsMutation>;
export type UpdateInvestmentGoalsMutationOptions = Apollo.BaseMutationOptions<UpdateInvestmentGoalsMutation, UpdateInvestmentGoalsMutationVariables>;
export const CreateTrustedContactPersonDocument = gql`
    mutation createTrustedContactPerson($input: CreateTrustedContactPersonInput!) {
  createTrustedContactPerson(input: $input) {
    errors {
      ...FullInputError
    }
    trustedContactPerson {
      contactInfo {
        email
        firstName
        lastName
        phoneNumber
        relationship
      }
      isContactProvided
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type CreateTrustedContactPersonMutationFn = Apollo.MutationFunction<CreateTrustedContactPersonMutation, CreateTrustedContactPersonMutationVariables>;

/**
 * __useCreateTrustedContactPersonMutation__
 *
 * To run a mutation, you first call `useCreateTrustedContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrustedContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrustedContactPersonMutation, { data, loading, error }] = useCreateTrustedContactPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrustedContactPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrustedContactPersonMutation, CreateTrustedContactPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrustedContactPersonMutation, CreateTrustedContactPersonMutationVariables>(CreateTrustedContactPersonDocument, options);
      }
export type CreateTrustedContactPersonMutationHookResult = ReturnType<typeof useCreateTrustedContactPersonMutation>;
export type CreateTrustedContactPersonMutationResult = Apollo.MutationResult<CreateTrustedContactPersonMutation>;
export type CreateTrustedContactPersonMutationOptions = Apollo.BaseMutationOptions<CreateTrustedContactPersonMutation, CreateTrustedContactPersonMutationVariables>;
export const UpdateTrustedContactPersonDocument = gql`
    mutation updateTrustedContactPerson($input: UpdateTrustedContactPersonInput!) {
  updateTrustedContactPerson(input: $input) {
    errors {
      ...FullInputError
    }
    trustedContactPerson {
      contactInfo {
        email
        firstName
        lastName
        phoneNumber
        relationship
      }
      isContactProvided
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type UpdateTrustedContactPersonMutationFn = Apollo.MutationFunction<UpdateTrustedContactPersonMutation, UpdateTrustedContactPersonMutationVariables>;

/**
 * __useUpdateTrustedContactPersonMutation__
 *
 * To run a mutation, you first call `useUpdateTrustedContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrustedContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrustedContactPersonMutation, { data, loading, error }] = useUpdateTrustedContactPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrustedContactPersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrustedContactPersonMutation, UpdateTrustedContactPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrustedContactPersonMutation, UpdateTrustedContactPersonMutationVariables>(UpdateTrustedContactPersonDocument, options);
      }
export type UpdateTrustedContactPersonMutationHookResult = ReturnType<typeof useUpdateTrustedContactPersonMutation>;
export type UpdateTrustedContactPersonMutationResult = Apollo.MutationResult<UpdateTrustedContactPersonMutation>;
export type UpdateTrustedContactPersonMutationOptions = Apollo.BaseMutationOptions<UpdateTrustedContactPersonMutation, UpdateTrustedContactPersonMutationVariables>;
export const UpdateInstitutionComplianceEmailDocument = gql`
    mutation updateInstitutionComplianceEmail($institutionId: ID!, $complianceEmail: String) {
  updateInstitutionComplianceEmail(
    institutionId: $institutionId
    complianceEmail: $complianceEmail
  ) {
    institution {
      id
      complianceEmail
    }
  }
}
    `;
export type UpdateInstitutionComplianceEmailMutationFn = Apollo.MutationFunction<UpdateInstitutionComplianceEmailMutation, UpdateInstitutionComplianceEmailMutationVariables>;

/**
 * __useUpdateInstitutionComplianceEmailMutation__
 *
 * To run a mutation, you first call `useUpdateInstitutionComplianceEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstitutionComplianceEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstitutionComplianceEmailMutation, { data, loading, error }] = useUpdateInstitutionComplianceEmailMutation({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *      complianceEmail: // value for 'complianceEmail'
 *   },
 * });
 */
export function useUpdateInstitutionComplianceEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstitutionComplianceEmailMutation, UpdateInstitutionComplianceEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstitutionComplianceEmailMutation, UpdateInstitutionComplianceEmailMutationVariables>(UpdateInstitutionComplianceEmailDocument, options);
      }
export type UpdateInstitutionComplianceEmailMutationHookResult = ReturnType<typeof useUpdateInstitutionComplianceEmailMutation>;
export type UpdateInstitutionComplianceEmailMutationResult = Apollo.MutationResult<UpdateInstitutionComplianceEmailMutation>;
export type UpdateInstitutionComplianceEmailMutationOptions = Apollo.BaseMutationOptions<UpdateInstitutionComplianceEmailMutation, UpdateInstitutionComplianceEmailMutationVariables>;
export const CompletedTransfersCardMyActivityDocument = gql`
    query completedTransfersCardMyActivity {
  myActivity {
    myTransactions {
      ...CompletedTransfersCardTransaction
    }
  }
}
    ${CompletedTransfersCardTransactionFragmentDoc}`;

/**
 * __useCompletedTransfersCardMyActivityQuery__
 *
 * To run a query within a React component, call `useCompletedTransfersCardMyActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedTransfersCardMyActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedTransfersCardMyActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompletedTransfersCardMyActivityQuery(baseOptions?: Apollo.QueryHookOptions<CompletedTransfersCardMyActivityQuery, CompletedTransfersCardMyActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompletedTransfersCardMyActivityQuery, CompletedTransfersCardMyActivityQueryVariables>(CompletedTransfersCardMyActivityDocument, options);
      }
export function useCompletedTransfersCardMyActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedTransfersCardMyActivityQuery, CompletedTransfersCardMyActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompletedTransfersCardMyActivityQuery, CompletedTransfersCardMyActivityQueryVariables>(CompletedTransfersCardMyActivityDocument, options);
        }
export type CompletedTransfersCardMyActivityQueryHookResult = ReturnType<typeof useCompletedTransfersCardMyActivityQuery>;
export type CompletedTransfersCardMyActivityLazyQueryHookResult = ReturnType<typeof useCompletedTransfersCardMyActivityLazyQuery>;
export type CompletedTransfersCardMyActivityQueryResult = Apollo.QueryResult<CompletedTransfersCardMyActivityQuery, CompletedTransfersCardMyActivityQueryVariables>;
export const CurrentSuitabilityDocument = gql`
    query currentSuitability {
  investmentGoalAnswersCurrent {
    ...OutdatedResponse
  }
}
    ${OutdatedResponseFragmentDoc}`;

/**
 * __useCurrentSuitabilityQuery__
 *
 * To run a query within a React component, call `useCurrentSuitabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSuitabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSuitabilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentSuitabilityQuery(baseOptions?: Apollo.QueryHookOptions<CurrentSuitabilityQuery, CurrentSuitabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentSuitabilityQuery, CurrentSuitabilityQueryVariables>(CurrentSuitabilityDocument, options);
      }
export function useCurrentSuitabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentSuitabilityQuery, CurrentSuitabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentSuitabilityQuery, CurrentSuitabilityQueryVariables>(CurrentSuitabilityDocument, options);
        }
export type CurrentSuitabilityQueryHookResult = ReturnType<typeof useCurrentSuitabilityQuery>;
export type CurrentSuitabilityLazyQueryHookResult = ReturnType<typeof useCurrentSuitabilityLazyQuery>;
export type CurrentSuitabilityQueryResult = Apollo.QueryResult<CurrentSuitabilityQuery, CurrentSuitabilityQueryVariables>;
export const InvitationByTokenDocument = gql`
    query invitationByToken($token: String!) {
  invitationByToken(token: $token) {
    ...InvitationPageInvitation
  }
}
    ${InvitationPageInvitationFragmentDoc}`;

/**
 * __useInvitationByTokenQuery__
 *
 * To run a query within a React component, call `useInvitationByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInvitationByTokenQuery(baseOptions: Apollo.QueryHookOptions<InvitationByTokenQuery, InvitationByTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationByTokenQuery, InvitationByTokenQueryVariables>(InvitationByTokenDocument, options);
      }
export function useInvitationByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationByTokenQuery, InvitationByTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationByTokenQuery, InvitationByTokenQueryVariables>(InvitationByTokenDocument, options);
        }
export type InvitationByTokenQueryHookResult = ReturnType<typeof useInvitationByTokenQuery>;
export type InvitationByTokenLazyQueryHookResult = ReturnType<typeof useInvitationByTokenLazyQuery>;
export type InvitationByTokenQueryResult = Apollo.QueryResult<InvitationByTokenQuery, InvitationByTokenQueryVariables>;
export const DiscussionThreadOnNewMessageDocument = gql`
    subscription discussionThreadOnNewMessage($discussionId: ID!) {
  onNewMessage(discussionId: $discussionId) {
    ...DiscussionThreadCardDiscussionMessage
  }
}
    ${DiscussionThreadCardDiscussionMessageFragmentDoc}`;

/**
 * __useDiscussionThreadOnNewMessageSubscription__
 *
 * To run a query within a React component, call `useDiscussionThreadOnNewMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionThreadOnNewMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionThreadOnNewMessageSubscription({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *   },
 * });
 */
export function useDiscussionThreadOnNewMessageSubscription(baseOptions: Apollo.SubscriptionHookOptions<DiscussionThreadOnNewMessageSubscription, DiscussionThreadOnNewMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DiscussionThreadOnNewMessageSubscription, DiscussionThreadOnNewMessageSubscriptionVariables>(DiscussionThreadOnNewMessageDocument, options);
      }
export type DiscussionThreadOnNewMessageSubscriptionHookResult = ReturnType<typeof useDiscussionThreadOnNewMessageSubscription>;
export type DiscussionThreadOnNewMessageSubscriptionResult = Apollo.SubscriptionResult<DiscussionThreadOnNewMessageSubscription>;
export const MyDiscussionListDiscussionsDocument = gql`
    query myDiscussionListDiscussions($topicFilter: MessageTopic) {
  myDiscussions(topicFilter: $topicFilter) {
    ...MyDiscussionListDiscussion
  }
}
    ${MyDiscussionListDiscussionFragmentDoc}`;

/**
 * __useMyDiscussionListDiscussionsQuery__
 *
 * To run a query within a React component, call `useMyDiscussionListDiscussionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyDiscussionListDiscussionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyDiscussionListDiscussionsQuery({
 *   variables: {
 *      topicFilter: // value for 'topicFilter'
 *   },
 * });
 */
export function useMyDiscussionListDiscussionsQuery(baseOptions?: Apollo.QueryHookOptions<MyDiscussionListDiscussionsQuery, MyDiscussionListDiscussionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyDiscussionListDiscussionsQuery, MyDiscussionListDiscussionsQueryVariables>(MyDiscussionListDiscussionsDocument, options);
      }
export function useMyDiscussionListDiscussionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyDiscussionListDiscussionsQuery, MyDiscussionListDiscussionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyDiscussionListDiscussionsQuery, MyDiscussionListDiscussionsQueryVariables>(MyDiscussionListDiscussionsDocument, options);
        }
export type MyDiscussionListDiscussionsQueryHookResult = ReturnType<typeof useMyDiscussionListDiscussionsQuery>;
export type MyDiscussionListDiscussionsLazyQueryHookResult = ReturnType<typeof useMyDiscussionListDiscussionsLazyQuery>;
export type MyDiscussionListDiscussionsQueryResult = Apollo.QueryResult<MyDiscussionListDiscussionsQuery, MyDiscussionListDiscussionsQueryVariables>;
export const StandingBidSellerDiscussionListDiscussionDocument = gql`
    query standingBidSellerDiscussionListDiscussion($standingBidId: ID!) {
  myDiscussions(topicFilter: {id: $standingBidId, type: STANDING_BID}) {
    ...StandingBidSellerDiscussionListDiscussion
  }
}
    ${StandingBidSellerDiscussionListDiscussionFragmentDoc}`;

/**
 * __useStandingBidSellerDiscussionListDiscussionQuery__
 *
 * To run a query within a React component, call `useStandingBidSellerDiscussionListDiscussionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandingBidSellerDiscussionListDiscussionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandingBidSellerDiscussionListDiscussionQuery({
 *   variables: {
 *      standingBidId: // value for 'standingBidId'
 *   },
 * });
 */
export function useStandingBidSellerDiscussionListDiscussionQuery(baseOptions: Apollo.QueryHookOptions<StandingBidSellerDiscussionListDiscussionQuery, StandingBidSellerDiscussionListDiscussionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandingBidSellerDiscussionListDiscussionQuery, StandingBidSellerDiscussionListDiscussionQueryVariables>(StandingBidSellerDiscussionListDiscussionDocument, options);
      }
export function useStandingBidSellerDiscussionListDiscussionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandingBidSellerDiscussionListDiscussionQuery, StandingBidSellerDiscussionListDiscussionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandingBidSellerDiscussionListDiscussionQuery, StandingBidSellerDiscussionListDiscussionQueryVariables>(StandingBidSellerDiscussionListDiscussionDocument, options);
        }
export type StandingBidSellerDiscussionListDiscussionQueryHookResult = ReturnType<typeof useStandingBidSellerDiscussionListDiscussionQuery>;
export type StandingBidSellerDiscussionListDiscussionLazyQueryHookResult = ReturnType<typeof useStandingBidSellerDiscussionListDiscussionLazyQuery>;
export type StandingBidSellerDiscussionListDiscussionQueryResult = Apollo.QueryResult<StandingBidSellerDiscussionListDiscussionQuery, StandingBidSellerDiscussionListDiscussionQueryVariables>;
export const StandingBidBuyerDiscussionListDiscussionsDocument = gql`
    query standingBidBuyerDiscussionListDiscussions($standingBidId: ID!) {
  myDiscussions(topicFilter: {id: $standingBidId, type: STANDING_BID}) {
    ...StandingBidBuyerDiscussionListDiscussion
  }
}
    ${StandingBidBuyerDiscussionListDiscussionFragmentDoc}`;

/**
 * __useStandingBidBuyerDiscussionListDiscussionsQuery__
 *
 * To run a query within a React component, call `useStandingBidBuyerDiscussionListDiscussionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandingBidBuyerDiscussionListDiscussionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandingBidBuyerDiscussionListDiscussionsQuery({
 *   variables: {
 *      standingBidId: // value for 'standingBidId'
 *   },
 * });
 */
export function useStandingBidBuyerDiscussionListDiscussionsQuery(baseOptions: Apollo.QueryHookOptions<StandingBidBuyerDiscussionListDiscussionsQuery, StandingBidBuyerDiscussionListDiscussionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandingBidBuyerDiscussionListDiscussionsQuery, StandingBidBuyerDiscussionListDiscussionsQueryVariables>(StandingBidBuyerDiscussionListDiscussionsDocument, options);
      }
export function useStandingBidBuyerDiscussionListDiscussionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandingBidBuyerDiscussionListDiscussionsQuery, StandingBidBuyerDiscussionListDiscussionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandingBidBuyerDiscussionListDiscussionsQuery, StandingBidBuyerDiscussionListDiscussionsQueryVariables>(StandingBidBuyerDiscussionListDiscussionsDocument, options);
        }
export type StandingBidBuyerDiscussionListDiscussionsQueryHookResult = ReturnType<typeof useStandingBidBuyerDiscussionListDiscussionsQuery>;
export type StandingBidBuyerDiscussionListDiscussionsLazyQueryHookResult = ReturnType<typeof useStandingBidBuyerDiscussionListDiscussionsLazyQuery>;
export type StandingBidBuyerDiscussionListDiscussionsQueryResult = Apollo.QueryResult<StandingBidBuyerDiscussionListDiscussionsQuery, StandingBidBuyerDiscussionListDiscussionsQueryVariables>;
export const StartDiscussionDocument = gql`
    mutation startDiscussion($input: StartDiscussionV2Input!) {
  startDiscussionV2(input: $input) {
    discussion {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type StartDiscussionMutationFn = Apollo.MutationFunction<StartDiscussionMutation, StartDiscussionMutationVariables>;

/**
 * __useStartDiscussionMutation__
 *
 * To run a mutation, you first call `useStartDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDiscussionMutation, { data, loading, error }] = useStartDiscussionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<StartDiscussionMutation, StartDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartDiscussionMutation, StartDiscussionMutationVariables>(StartDiscussionDocument, options);
      }
export type StartDiscussionMutationHookResult = ReturnType<typeof useStartDiscussionMutation>;
export type StartDiscussionMutationResult = Apollo.MutationResult<StartDiscussionMutation>;
export type StartDiscussionMutationOptions = Apollo.BaseMutationOptions<StartDiscussionMutation, StartDiscussionMutationVariables>;
export const WatchlistComboboxListCompaniesDocument = gql`
    query watchlistComboboxListCompanies($orderBy: ListCompaniesOrderBy!, $first: Int!, $searchText: String) {
  listCompanies(orderBy: $orderBy, first: $first, searchText: $searchText) {
    edges {
      ...WatchlistComboboxCompanyEdge
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${WatchlistComboboxCompanyEdgeFragmentDoc}`;

/**
 * __useWatchlistComboboxListCompaniesQuery__
 *
 * To run a query within a React component, call `useWatchlistComboboxListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchlistComboboxListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchlistComboboxListCompaniesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useWatchlistComboboxListCompaniesQuery(baseOptions: Apollo.QueryHookOptions<WatchlistComboboxListCompaniesQuery, WatchlistComboboxListCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WatchlistComboboxListCompaniesQuery, WatchlistComboboxListCompaniesQueryVariables>(WatchlistComboboxListCompaniesDocument, options);
      }
export function useWatchlistComboboxListCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WatchlistComboboxListCompaniesQuery, WatchlistComboboxListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WatchlistComboboxListCompaniesQuery, WatchlistComboboxListCompaniesQueryVariables>(WatchlistComboboxListCompaniesDocument, options);
        }
export type WatchlistComboboxListCompaniesQueryHookResult = ReturnType<typeof useWatchlistComboboxListCompaniesQuery>;
export type WatchlistComboboxListCompaniesLazyQueryHookResult = ReturnType<typeof useWatchlistComboboxListCompaniesLazyQuery>;
export type WatchlistComboboxListCompaniesQueryResult = Apollo.QueryResult<WatchlistComboboxListCompaniesQuery, WatchlistComboboxListCompaniesQueryVariables>;
export const CurrentContextDocument = gql`
    query currentContext {
  currentContext {
    userNotificationToken
    currentActor {
      ...UserWithInstitution
    }
  }
}
    ${UserWithInstitutionFragmentDoc}`;

/**
 * __useCurrentContextQuery__
 *
 * To run a query within a React component, call `useCurrentContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentContextQuery(baseOptions?: Apollo.QueryHookOptions<CurrentContextQuery, CurrentContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentContextQuery, CurrentContextQueryVariables>(CurrentContextDocument, options);
      }
export function useCurrentContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentContextQuery, CurrentContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentContextQuery, CurrentContextQueryVariables>(CurrentContextDocument, options);
        }
export type CurrentContextQueryHookResult = ReturnType<typeof useCurrentContextQuery>;
export type CurrentContextLazyQueryHookResult = ReturnType<typeof useCurrentContextLazyQuery>;
export type CurrentContextQueryResult = Apollo.QueryResult<CurrentContextQuery, CurrentContextQueryVariables>;
export const AllInvestorsDocument = gql`
    query allInvestors {
  allInvestors {
    name
    id
  }
}
    `;

/**
 * __useAllInvestorsQuery__
 *
 * To run a query within a React component, call `useAllInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInvestorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllInvestorsQuery(baseOptions?: Apollo.QueryHookOptions<AllInvestorsQuery, AllInvestorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllInvestorsQuery, AllInvestorsQueryVariables>(AllInvestorsDocument, options);
      }
export function useAllInvestorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllInvestorsQuery, AllInvestorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllInvestorsQuery, AllInvestorsQueryVariables>(AllInvestorsDocument, options);
        }
export type AllInvestorsQueryHookResult = ReturnType<typeof useAllInvestorsQuery>;
export type AllInvestorsLazyQueryHookResult = ReturnType<typeof useAllInvestorsLazyQuery>;
export type AllInvestorsQueryResult = Apollo.QueryResult<AllInvestorsQuery, AllInvestorsQueryVariables>;
export const BrowseCompaniesPageListCompaniesDocument = gql`
    query browseCompaniesPageListCompanies($orderBy: ListCompaniesOrderBy!, $first: Int!, $investorIds: [ID!], $industryIds: [ID!], $countryIds: [ID!], $lastRoundPostValuationRange: LastRoundPostValuationRange!, $cursor: String, $searchText: String, $statuses: [CompanyStatus!]) {
  listCompanies(
    orderBy: $orderBy
    investorIds: $investorIds
    industryIds: $industryIds
    countryIds: $countryIds
    lastRoundPostValuationRange: $lastRoundPostValuationRange
    first: $first
    after: $cursor
    searchText: $searchText
    statuses: $statuses
  ) {
    edges {
      ...BrowseCompaniesPageCompanyEdge
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${BrowseCompaniesPageCompanyEdgeFragmentDoc}`;

/**
 * __useBrowseCompaniesPageListCompaniesQuery__
 *
 * To run a query within a React component, call `useBrowseCompaniesPageListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowseCompaniesPageListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowseCompaniesPageListCompaniesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      investorIds: // value for 'investorIds'
 *      industryIds: // value for 'industryIds'
 *      countryIds: // value for 'countryIds'
 *      lastRoundPostValuationRange: // value for 'lastRoundPostValuationRange'
 *      cursor: // value for 'cursor'
 *      searchText: // value for 'searchText'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useBrowseCompaniesPageListCompaniesQuery(baseOptions: Apollo.QueryHookOptions<BrowseCompaniesPageListCompaniesQuery, BrowseCompaniesPageListCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseCompaniesPageListCompaniesQuery, BrowseCompaniesPageListCompaniesQueryVariables>(BrowseCompaniesPageListCompaniesDocument, options);
      }
export function useBrowseCompaniesPageListCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseCompaniesPageListCompaniesQuery, BrowseCompaniesPageListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseCompaniesPageListCompaniesQuery, BrowseCompaniesPageListCompaniesQueryVariables>(BrowseCompaniesPageListCompaniesDocument, options);
        }
export type BrowseCompaniesPageListCompaniesQueryHookResult = ReturnType<typeof useBrowseCompaniesPageListCompaniesQuery>;
export type BrowseCompaniesPageListCompaniesLazyQueryHookResult = ReturnType<typeof useBrowseCompaniesPageListCompaniesLazyQuery>;
export type BrowseCompaniesPageListCompaniesQueryResult = Apollo.QueryResult<BrowseCompaniesPageListCompaniesQuery, BrowseCompaniesPageListCompaniesQueryVariables>;
export const BrowseCompaniesPageCountriesDocument = gql`
    query browseCompaniesPageCountries {
  allCompanyCountries {
    id
    ...BrowseCompaniesPageCountry
  }
}
    ${BrowseCompaniesPageCountryFragmentDoc}`;

/**
 * __useBrowseCompaniesPageCountriesQuery__
 *
 * To run a query within a React component, call `useBrowseCompaniesPageCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowseCompaniesPageCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowseCompaniesPageCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrowseCompaniesPageCountriesQuery(baseOptions?: Apollo.QueryHookOptions<BrowseCompaniesPageCountriesQuery, BrowseCompaniesPageCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseCompaniesPageCountriesQuery, BrowseCompaniesPageCountriesQueryVariables>(BrowseCompaniesPageCountriesDocument, options);
      }
export function useBrowseCompaniesPageCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseCompaniesPageCountriesQuery, BrowseCompaniesPageCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseCompaniesPageCountriesQuery, BrowseCompaniesPageCountriesQueryVariables>(BrowseCompaniesPageCountriesDocument, options);
        }
export type BrowseCompaniesPageCountriesQueryHookResult = ReturnType<typeof useBrowseCompaniesPageCountriesQuery>;
export type BrowseCompaniesPageCountriesLazyQueryHookResult = ReturnType<typeof useBrowseCompaniesPageCountriesLazyQuery>;
export type BrowseCompaniesPageCountriesQueryResult = Apollo.QueryResult<BrowseCompaniesPageCountriesQuery, BrowseCompaniesPageCountriesQueryVariables>;
export const CompanyPageActionsCompanyByIdDocument = gql`
    query companyPageActionsCompanyById($id: ID!) {
  companyById(id: $id) {
    ...CompanyActionsCompany
  }
}
    ${CompanyActionsCompanyFragmentDoc}`;

/**
 * __useCompanyPageActionsCompanyByIdQuery__
 *
 * To run a query within a React component, call `useCompanyPageActionsCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPageActionsCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPageActionsCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyPageActionsCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<CompanyPageActionsCompanyByIdQuery, CompanyPageActionsCompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPageActionsCompanyByIdQuery, CompanyPageActionsCompanyByIdQueryVariables>(CompanyPageActionsCompanyByIdDocument, options);
      }
export function useCompanyPageActionsCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPageActionsCompanyByIdQuery, CompanyPageActionsCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPageActionsCompanyByIdQuery, CompanyPageActionsCompanyByIdQueryVariables>(CompanyPageActionsCompanyByIdDocument, options);
        }
export type CompanyPageActionsCompanyByIdQueryHookResult = ReturnType<typeof useCompanyPageActionsCompanyByIdQuery>;
export type CompanyPageActionsCompanyByIdLazyQueryHookResult = ReturnType<typeof useCompanyPageActionsCompanyByIdLazyQuery>;
export type CompanyPageActionsCompanyByIdQueryResult = Apollo.QueryResult<CompanyPageActionsCompanyByIdQuery, CompanyPageActionsCompanyByIdQueryVariables>;
export const CompanyPageMarketActivityCompanyByIdDocument = gql`
    query companyPageMarketActivityCompanyById($id: ID!) {
  companyById(id: $id) {
    ...CompanyPageMarketActivityCompany
  }
}
    ${CompanyPageMarketActivityCompanyFragmentDoc}`;

/**
 * __useCompanyPageMarketActivityCompanyByIdQuery__
 *
 * To run a query within a React component, call `useCompanyPageMarketActivityCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPageMarketActivityCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPageMarketActivityCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyPageMarketActivityCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<CompanyPageMarketActivityCompanyByIdQuery, CompanyPageMarketActivityCompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPageMarketActivityCompanyByIdQuery, CompanyPageMarketActivityCompanyByIdQueryVariables>(CompanyPageMarketActivityCompanyByIdDocument, options);
      }
export function useCompanyPageMarketActivityCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPageMarketActivityCompanyByIdQuery, CompanyPageMarketActivityCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPageMarketActivityCompanyByIdQuery, CompanyPageMarketActivityCompanyByIdQueryVariables>(CompanyPageMarketActivityCompanyByIdDocument, options);
        }
export type CompanyPageMarketActivityCompanyByIdQueryHookResult = ReturnType<typeof useCompanyPageMarketActivityCompanyByIdQuery>;
export type CompanyPageMarketActivityCompanyByIdLazyQueryHookResult = ReturnType<typeof useCompanyPageMarketActivityCompanyByIdLazyQuery>;
export type CompanyPageMarketActivityCompanyByIdQueryResult = Apollo.QueryResult<CompanyPageMarketActivityCompanyByIdQuery, CompanyPageMarketActivityCompanyByIdQueryVariables>;
export const CompanyPageActivityCompanyByIdDocument = gql`
    query companyPageActivityCompanyById($id: ID!) {
  companyById(id: $id) {
    ...CompanyActivityCompany
  }
}
    ${CompanyActivityCompanyFragmentDoc}`;

/**
 * __useCompanyPageActivityCompanyByIdQuery__
 *
 * To run a query within a React component, call `useCompanyPageActivityCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPageActivityCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPageActivityCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyPageActivityCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<CompanyPageActivityCompanyByIdQuery, CompanyPageActivityCompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPageActivityCompanyByIdQuery, CompanyPageActivityCompanyByIdQueryVariables>(CompanyPageActivityCompanyByIdDocument, options);
      }
export function useCompanyPageActivityCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPageActivityCompanyByIdQuery, CompanyPageActivityCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPageActivityCompanyByIdQuery, CompanyPageActivityCompanyByIdQueryVariables>(CompanyPageActivityCompanyByIdDocument, options);
        }
export type CompanyPageActivityCompanyByIdQueryHookResult = ReturnType<typeof useCompanyPageActivityCompanyByIdQuery>;
export type CompanyPageActivityCompanyByIdLazyQueryHookResult = ReturnType<typeof useCompanyPageActivityCompanyByIdLazyQuery>;
export type CompanyPageActivityCompanyByIdQueryResult = Apollo.QueryResult<CompanyPageActivityCompanyByIdQuery, CompanyPageActivityCompanyByIdQueryVariables>;
export const CompanyPageInfoCompanyByIdDocument = gql`
    query companyPageInfoCompanyById($id: ID!) {
  companyById(id: $id) {
    ...CompanyPageInfoCompany
  }
}
    ${CompanyPageInfoCompanyFragmentDoc}`;

/**
 * __useCompanyPageInfoCompanyByIdQuery__
 *
 * To run a query within a React component, call `useCompanyPageInfoCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPageInfoCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPageInfoCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyPageInfoCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<CompanyPageInfoCompanyByIdQuery, CompanyPageInfoCompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPageInfoCompanyByIdQuery, CompanyPageInfoCompanyByIdQueryVariables>(CompanyPageInfoCompanyByIdDocument, options);
      }
export function useCompanyPageInfoCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPageInfoCompanyByIdQuery, CompanyPageInfoCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPageInfoCompanyByIdQuery, CompanyPageInfoCompanyByIdQueryVariables>(CompanyPageInfoCompanyByIdDocument, options);
        }
export type CompanyPageInfoCompanyByIdQueryHookResult = ReturnType<typeof useCompanyPageInfoCompanyByIdQuery>;
export type CompanyPageInfoCompanyByIdLazyQueryHookResult = ReturnType<typeof useCompanyPageInfoCompanyByIdLazyQuery>;
export type CompanyPageInfoCompanyByIdQueryResult = Apollo.QueryResult<CompanyPageInfoCompanyByIdQuery, CompanyPageInfoCompanyByIdQueryVariables>;
export const CompanyPageContentDocument = gql`
    query companyPageContent($id: ID!) {
  companyById(id: $id) {
    ...CompanyPageContentCompany
  }
}
    ${CompanyPageContentCompanyFragmentDoc}`;

/**
 * __useCompanyPageContentQuery__
 *
 * To run a query within a React component, call `useCompanyPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPageContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyPageContentQuery(baseOptions: Apollo.QueryHookOptions<CompanyPageContentQuery, CompanyPageContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPageContentQuery, CompanyPageContentQueryVariables>(CompanyPageContentDocument, options);
      }
export function useCompanyPageContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPageContentQuery, CompanyPageContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPageContentQuery, CompanyPageContentQueryVariables>(CompanyPageContentDocument, options);
        }
export type CompanyPageContentQueryHookResult = ReturnType<typeof useCompanyPageContentQuery>;
export type CompanyPageContentLazyQueryHookResult = ReturnType<typeof useCompanyPageContentLazyQuery>;
export type CompanyPageContentQueryResult = Apollo.QueryResult<CompanyPageContentQuery, CompanyPageContentQueryVariables>;
export const CompanyPageYourActivityCompanyByIdDocument = gql`
    query companyPageYourActivityCompanyById($id: ID!) {
  companyById(id: $id) {
    ...CompanyPageYourActivityCompany
  }
}
    ${CompanyPageYourActivityCompanyFragmentDoc}`;

/**
 * __useCompanyPageYourActivityCompanyByIdQuery__
 *
 * To run a query within a React component, call `useCompanyPageYourActivityCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPageYourActivityCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPageYourActivityCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyPageYourActivityCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<CompanyPageYourActivityCompanyByIdQuery, CompanyPageYourActivityCompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPageYourActivityCompanyByIdQuery, CompanyPageYourActivityCompanyByIdQueryVariables>(CompanyPageYourActivityCompanyByIdDocument, options);
      }
export function useCompanyPageYourActivityCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPageYourActivityCompanyByIdQuery, CompanyPageYourActivityCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPageYourActivityCompanyByIdQuery, CompanyPageYourActivityCompanyByIdQueryVariables>(CompanyPageYourActivityCompanyByIdDocument, options);
        }
export type CompanyPageYourActivityCompanyByIdQueryHookResult = ReturnType<typeof useCompanyPageYourActivityCompanyByIdQuery>;
export type CompanyPageYourActivityCompanyByIdLazyQueryHookResult = ReturnType<typeof useCompanyPageYourActivityCompanyByIdLazyQuery>;
export type CompanyPageYourActivityCompanyByIdQueryResult = Apollo.QueryResult<CompanyPageYourActivityCompanyByIdQuery, CompanyPageYourActivityCompanyByIdQueryVariables>;
export const UnaccreditedSellerCompanyPageActionsDocument = gql`
    query unaccreditedSellerCompanyPageActions {
  unaccreditedSellerMyActivity {
    myListing {
      id
    }
    myCompany {
      id
      ...UnaccreditedSellerCompanyPageActionsCompany
    }
  }
}
    ${UnaccreditedSellerCompanyPageActionsCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerCompanyPageActionsQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCompanyPageActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCompanyPageActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCompanyPageActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerCompanyPageActionsQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerCompanyPageActionsQuery, UnaccreditedSellerCompanyPageActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCompanyPageActionsQuery, UnaccreditedSellerCompanyPageActionsQueryVariables>(UnaccreditedSellerCompanyPageActionsDocument, options);
      }
export function useUnaccreditedSellerCompanyPageActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCompanyPageActionsQuery, UnaccreditedSellerCompanyPageActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCompanyPageActionsQuery, UnaccreditedSellerCompanyPageActionsQueryVariables>(UnaccreditedSellerCompanyPageActionsDocument, options);
        }
export type UnaccreditedSellerCompanyPageActionsQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageActionsQuery>;
export type UnaccreditedSellerCompanyPageActionsLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageActionsLazyQuery>;
export type UnaccreditedSellerCompanyPageActionsQueryResult = Apollo.QueryResult<UnaccreditedSellerCompanyPageActionsQuery, UnaccreditedSellerCompanyPageActionsQueryVariables>;
export const UnaccreditedSellerCompanyByIdActionsDocument = gql`
    query unaccreditedSellerCompanyByIdActions($id: ID!) {
  companyById(id: $id) {
    ...UnaccreditedSellerCompanyByIdActions
  }
}
    ${UnaccreditedSellerCompanyByIdActionsFragmentDoc}`;

/**
 * __useUnaccreditedSellerCompanyByIdActionsQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCompanyByIdActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCompanyByIdActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCompanyByIdActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerCompanyByIdActionsQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerCompanyByIdActionsQuery, UnaccreditedSellerCompanyByIdActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCompanyByIdActionsQuery, UnaccreditedSellerCompanyByIdActionsQueryVariables>(UnaccreditedSellerCompanyByIdActionsDocument, options);
      }
export function useUnaccreditedSellerCompanyByIdActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCompanyByIdActionsQuery, UnaccreditedSellerCompanyByIdActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCompanyByIdActionsQuery, UnaccreditedSellerCompanyByIdActionsQueryVariables>(UnaccreditedSellerCompanyByIdActionsDocument, options);
        }
export type UnaccreditedSellerCompanyByIdActionsQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyByIdActionsQuery>;
export type UnaccreditedSellerCompanyByIdActionsLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyByIdActionsLazyQuery>;
export type UnaccreditedSellerCompanyByIdActionsQueryResult = Apollo.QueryResult<UnaccreditedSellerCompanyByIdActionsQuery, UnaccreditedSellerCompanyByIdActionsQueryVariables>;
export const UnaccreditedSellerCompanyPageInfoDocument = gql`
    query unaccreditedSellerCompanyPageInfo {
  unaccreditedSellerMyActivity {
    myCompany {
      id
      priceChartConfig {
        graphIndicators {
          acceptedBids
          lastRoundPps
          postedBidsAsks
        }
        priceLines {
          shareTypeCommon
          shareTypePreferred
          transferTypeDirect
          transferTypeIndirect
        }
        displayChart
        graphDataStart
        availableTimeRanges
      }
      ...UnaccreditedSellerCompanyPageDescriptionCardCompany
      ...CompanyLastRoundDetailsCardCompany
    }
  }
}
    ${UnaccreditedSellerCompanyPageDescriptionCardCompanyFragmentDoc}
${CompanyLastRoundDetailsCardCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerCompanyPageInfoQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCompanyPageInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCompanyPageInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCompanyPageInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerCompanyPageInfoQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerCompanyPageInfoQuery, UnaccreditedSellerCompanyPageInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCompanyPageInfoQuery, UnaccreditedSellerCompanyPageInfoQueryVariables>(UnaccreditedSellerCompanyPageInfoDocument, options);
      }
export function useUnaccreditedSellerCompanyPageInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCompanyPageInfoQuery, UnaccreditedSellerCompanyPageInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCompanyPageInfoQuery, UnaccreditedSellerCompanyPageInfoQueryVariables>(UnaccreditedSellerCompanyPageInfoDocument, options);
        }
export type UnaccreditedSellerCompanyPageInfoQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageInfoQuery>;
export type UnaccreditedSellerCompanyPageInfoLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageInfoLazyQuery>;
export type UnaccreditedSellerCompanyPageInfoQueryResult = Apollo.QueryResult<UnaccreditedSellerCompanyPageInfoQuery, UnaccreditedSellerCompanyPageInfoQueryVariables>;
export const UnaccreditedSellerCompanyPageMarketActivityDocument = gql`
    query unaccreditedSellerCompanyPageMarketActivity {
  unaccreditedSellerMyCompanyActivity {
    otherListings {
      id
      ...UnaccreditedSellerCompanyPageMarketActivityListing
    }
    availableStandingBids {
      id
      ...UnaccreditedSellerCompanyPageMarketActivityStandingBid
    }
  }
  unaccreditedSellerMyActivity {
    myCompany {
      ...UnaccreditedSellerCompanyPageMarketActivityCompany
    }
  }
}
    ${UnaccreditedSellerCompanyPageMarketActivityListingFragmentDoc}
${UnaccreditedSellerCompanyPageMarketActivityStandingBidFragmentDoc}
${UnaccreditedSellerCompanyPageMarketActivityCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerCompanyPageMarketActivityQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCompanyPageMarketActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCompanyPageMarketActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCompanyPageMarketActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerCompanyPageMarketActivityQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerCompanyPageMarketActivityQuery, UnaccreditedSellerCompanyPageMarketActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCompanyPageMarketActivityQuery, UnaccreditedSellerCompanyPageMarketActivityQueryVariables>(UnaccreditedSellerCompanyPageMarketActivityDocument, options);
      }
export function useUnaccreditedSellerCompanyPageMarketActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCompanyPageMarketActivityQuery, UnaccreditedSellerCompanyPageMarketActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCompanyPageMarketActivityQuery, UnaccreditedSellerCompanyPageMarketActivityQueryVariables>(UnaccreditedSellerCompanyPageMarketActivityDocument, options);
        }
export type UnaccreditedSellerCompanyPageMarketActivityQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageMarketActivityQuery>;
export type UnaccreditedSellerCompanyPageMarketActivityLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageMarketActivityLazyQuery>;
export type UnaccreditedSellerCompanyPageMarketActivityQueryResult = Apollo.QueryResult<UnaccreditedSellerCompanyPageMarketActivityQuery, UnaccreditedSellerCompanyPageMarketActivityQueryVariables>;
export const UnaccreditedSellerCompanyByIdMarketActivityDocument = gql`
    query unaccreditedSellerCompanyByIdMarketActivity($id: ID!) {
  companyById(id: $id) {
    ...UnaccreditedSellerCompanyByIdMarketActivityCompany
  }
}
    ${UnaccreditedSellerCompanyByIdMarketActivityCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerCompanyByIdMarketActivityQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCompanyByIdMarketActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCompanyByIdMarketActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCompanyByIdMarketActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerCompanyByIdMarketActivityQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerCompanyByIdMarketActivityQuery, UnaccreditedSellerCompanyByIdMarketActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCompanyByIdMarketActivityQuery, UnaccreditedSellerCompanyByIdMarketActivityQueryVariables>(UnaccreditedSellerCompanyByIdMarketActivityDocument, options);
      }
export function useUnaccreditedSellerCompanyByIdMarketActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCompanyByIdMarketActivityQuery, UnaccreditedSellerCompanyByIdMarketActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCompanyByIdMarketActivityQuery, UnaccreditedSellerCompanyByIdMarketActivityQueryVariables>(UnaccreditedSellerCompanyByIdMarketActivityDocument, options);
        }
export type UnaccreditedSellerCompanyByIdMarketActivityQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyByIdMarketActivityQuery>;
export type UnaccreditedSellerCompanyByIdMarketActivityLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyByIdMarketActivityLazyQuery>;
export type UnaccreditedSellerCompanyByIdMarketActivityQueryResult = Apollo.QueryResult<UnaccreditedSellerCompanyByIdMarketActivityQuery, UnaccreditedSellerCompanyByIdMarketActivityQueryVariables>;
export const UnaccreditedSellerCompanyPageMetadataDocument = gql`
    query unaccreditedSellerCompanyPageMetadata {
  unaccreditedSellerMyActivity {
    myCompany {
      id
      name
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerCompanyPageMetadataQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCompanyPageMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCompanyPageMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCompanyPageMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerCompanyPageMetadataQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerCompanyPageMetadataQuery, UnaccreditedSellerCompanyPageMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCompanyPageMetadataQuery, UnaccreditedSellerCompanyPageMetadataQueryVariables>(UnaccreditedSellerCompanyPageMetadataDocument, options);
      }
export function useUnaccreditedSellerCompanyPageMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCompanyPageMetadataQuery, UnaccreditedSellerCompanyPageMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCompanyPageMetadataQuery, UnaccreditedSellerCompanyPageMetadataQueryVariables>(UnaccreditedSellerCompanyPageMetadataDocument, options);
        }
export type UnaccreditedSellerCompanyPageMetadataQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageMetadataQuery>;
export type UnaccreditedSellerCompanyPageMetadataLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageMetadataLazyQuery>;
export type UnaccreditedSellerCompanyPageMetadataQueryResult = Apollo.QueryResult<UnaccreditedSellerCompanyPageMetadataQuery, UnaccreditedSellerCompanyPageMetadataQueryVariables>;
export const UnaccreditedSellerCompanyPageYourActivityDocument = gql`
    query unaccreditedSellerCompanyPageYourActivity {
  unaccreditedSellerMyActivity {
    myListing {
      ...UnaccreditedSellerCompanyPageYourActivityListing
    }
    myCompany {
      id
      name
    }
  }
}
    ${UnaccreditedSellerCompanyPageYourActivityListingFragmentDoc}`;

/**
 * __useUnaccreditedSellerCompanyPageYourActivityQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCompanyPageYourActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCompanyPageYourActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCompanyPageYourActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerCompanyPageYourActivityQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerCompanyPageYourActivityQuery, UnaccreditedSellerCompanyPageYourActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCompanyPageYourActivityQuery, UnaccreditedSellerCompanyPageYourActivityQueryVariables>(UnaccreditedSellerCompanyPageYourActivityDocument, options);
      }
export function useUnaccreditedSellerCompanyPageYourActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCompanyPageYourActivityQuery, UnaccreditedSellerCompanyPageYourActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCompanyPageYourActivityQuery, UnaccreditedSellerCompanyPageYourActivityQueryVariables>(UnaccreditedSellerCompanyPageYourActivityDocument, options);
        }
export type UnaccreditedSellerCompanyPageYourActivityQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageYourActivityQuery>;
export type UnaccreditedSellerCompanyPageYourActivityLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageYourActivityLazyQuery>;
export type UnaccreditedSellerCompanyPageYourActivityQueryResult = Apollo.QueryResult<UnaccreditedSellerCompanyPageYourActivityQuery, UnaccreditedSellerCompanyPageYourActivityQueryVariables>;
export const UnaccreditedSellerCompanyByIdYourActivityDocument = gql`
    query unaccreditedSellerCompanyByIdYourActivity($id: ID!) {
  companyById(id: $id) {
    ...UnaccreditedSellerCompanyByIdYourActivityCompany
  }
}
    ${UnaccreditedSellerCompanyByIdYourActivityCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerCompanyByIdYourActivityQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCompanyByIdYourActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCompanyByIdYourActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCompanyByIdYourActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerCompanyByIdYourActivityQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerCompanyByIdYourActivityQuery, UnaccreditedSellerCompanyByIdYourActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCompanyByIdYourActivityQuery, UnaccreditedSellerCompanyByIdYourActivityQueryVariables>(UnaccreditedSellerCompanyByIdYourActivityDocument, options);
      }
export function useUnaccreditedSellerCompanyByIdYourActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCompanyByIdYourActivityQuery, UnaccreditedSellerCompanyByIdYourActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCompanyByIdYourActivityQuery, UnaccreditedSellerCompanyByIdYourActivityQueryVariables>(UnaccreditedSellerCompanyByIdYourActivityDocument, options);
        }
export type UnaccreditedSellerCompanyByIdYourActivityQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyByIdYourActivityQuery>;
export type UnaccreditedSellerCompanyByIdYourActivityLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyByIdYourActivityLazyQuery>;
export type UnaccreditedSellerCompanyByIdYourActivityQueryResult = Apollo.QueryResult<UnaccreditedSellerCompanyByIdYourActivityQuery, UnaccreditedSellerCompanyByIdYourActivityQueryVariables>;
export const UnaccreditedSellerCompanyPageDocument = gql`
    query unaccreditedSellerCompanyPage {
  unaccreditedSellerMyActivity {
    myCompany {
      ...UnaccreditedSellerCompanyPageCompany
    }
  }
}
    ${UnaccreditedSellerCompanyPageCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerCompanyPageQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCompanyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCompanyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCompanyPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerCompanyPageQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerCompanyPageQuery, UnaccreditedSellerCompanyPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCompanyPageQuery, UnaccreditedSellerCompanyPageQueryVariables>(UnaccreditedSellerCompanyPageDocument, options);
      }
export function useUnaccreditedSellerCompanyPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCompanyPageQuery, UnaccreditedSellerCompanyPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCompanyPageQuery, UnaccreditedSellerCompanyPageQueryVariables>(UnaccreditedSellerCompanyPageDocument, options);
        }
export type UnaccreditedSellerCompanyPageQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageQuery>;
export type UnaccreditedSellerCompanyPageLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyPageLazyQuery>;
export type UnaccreditedSellerCompanyPageQueryResult = Apollo.QueryResult<UnaccreditedSellerCompanyPageQuery, UnaccreditedSellerCompanyPageQueryVariables>;
export const UnaccreditedSellerCompanyByIdPageDocument = gql`
    query unaccreditedSellerCompanyByIdPage($id: ID!) {
  companyById(id: $id) {
    ...UnaccreditedSellerCompanyByIdPageCompany
  }
}
    ${UnaccreditedSellerCompanyByIdPageCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerCompanyByIdPageQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCompanyByIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCompanyByIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCompanyByIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerCompanyByIdPageQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerCompanyByIdPageQuery, UnaccreditedSellerCompanyByIdPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCompanyByIdPageQuery, UnaccreditedSellerCompanyByIdPageQueryVariables>(UnaccreditedSellerCompanyByIdPageDocument, options);
      }
export function useUnaccreditedSellerCompanyByIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCompanyByIdPageQuery, UnaccreditedSellerCompanyByIdPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCompanyByIdPageQuery, UnaccreditedSellerCompanyByIdPageQueryVariables>(UnaccreditedSellerCompanyByIdPageDocument, options);
        }
export type UnaccreditedSellerCompanyByIdPageQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyByIdPageQuery>;
export type UnaccreditedSellerCompanyByIdPageLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCompanyByIdPageLazyQuery>;
export type UnaccreditedSellerCompanyByIdPageQueryResult = Apollo.QueryResult<UnaccreditedSellerCompanyByIdPageQuery, UnaccreditedSellerCompanyByIdPageQueryVariables>;
export const CompanyPriceDataDocument = gql`
    query companyPriceData($companyId: ID!, $startDate: Date!, $indicators: [Indicator]!, $includeIndexPrice: Boolean = false, $includeIndexPriceTransferTypeDirect: Boolean = false, $includeIndexPriceTransferTypeIndirect: Boolean = false, $includeIndexPriceShareTypeCommon: Boolean = false, $includeIndexPriceShareTypePreferred: Boolean = false, $includeAcceptedBids: Boolean = false, $includeAcceptedBidsTransferTypeDirect: Boolean = false, $includeAcceptedBidsTransferTypeIndirect: Boolean = false, $includeAcceptedBidsShareTypeCommon: Boolean = false, $includeAcceptedBidsShareTypePreferred: Boolean = false, $includePostedBidsAsks: Boolean = false, $includePostedBidsAsksTransferTypeDirect: Boolean = false, $includePostedBidsAsksTransferTypeIndirect: Boolean = false, $includePostedBidsAsksShareTypeCommon: Boolean = false, $includePostedBidsAsksShareTypePreferred: Boolean = false, $includeLastRoundPrice: Boolean = false) {
  companyPriceData(
    companyId: $companyId
    startDate: $startDate
    indicators: $indicators
  ) {
    dailyPriceData {
      ...PriceData
    }
    indexPriceTrends {
      ...PriceTrend
    }
  }
}
    ${PriceDataFragmentDoc}
${PriceTrendFragmentDoc}`;

/**
 * __useCompanyPriceDataQuery__
 *
 * To run a query within a React component, call `useCompanyPriceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPriceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPriceDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      startDate: // value for 'startDate'
 *      indicators: // value for 'indicators'
 *      includeIndexPrice: // value for 'includeIndexPrice'
 *      includeIndexPriceTransferTypeDirect: // value for 'includeIndexPriceTransferTypeDirect'
 *      includeIndexPriceTransferTypeIndirect: // value for 'includeIndexPriceTransferTypeIndirect'
 *      includeIndexPriceShareTypeCommon: // value for 'includeIndexPriceShareTypeCommon'
 *      includeIndexPriceShareTypePreferred: // value for 'includeIndexPriceShareTypePreferred'
 *      includeAcceptedBids: // value for 'includeAcceptedBids'
 *      includeAcceptedBidsTransferTypeDirect: // value for 'includeAcceptedBidsTransferTypeDirect'
 *      includeAcceptedBidsTransferTypeIndirect: // value for 'includeAcceptedBidsTransferTypeIndirect'
 *      includeAcceptedBidsShareTypeCommon: // value for 'includeAcceptedBidsShareTypeCommon'
 *      includeAcceptedBidsShareTypePreferred: // value for 'includeAcceptedBidsShareTypePreferred'
 *      includePostedBidsAsks: // value for 'includePostedBidsAsks'
 *      includePostedBidsAsksTransferTypeDirect: // value for 'includePostedBidsAsksTransferTypeDirect'
 *      includePostedBidsAsksTransferTypeIndirect: // value for 'includePostedBidsAsksTransferTypeIndirect'
 *      includePostedBidsAsksShareTypeCommon: // value for 'includePostedBidsAsksShareTypeCommon'
 *      includePostedBidsAsksShareTypePreferred: // value for 'includePostedBidsAsksShareTypePreferred'
 *      includeLastRoundPrice: // value for 'includeLastRoundPrice'
 *   },
 * });
 */
export function useCompanyPriceDataQuery(baseOptions: Apollo.QueryHookOptions<CompanyPriceDataQuery, CompanyPriceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPriceDataQuery, CompanyPriceDataQueryVariables>(CompanyPriceDataDocument, options);
      }
export function useCompanyPriceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPriceDataQuery, CompanyPriceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPriceDataQuery, CompanyPriceDataQueryVariables>(CompanyPriceDataDocument, options);
        }
export type CompanyPriceDataQueryHookResult = ReturnType<typeof useCompanyPriceDataQuery>;
export type CompanyPriceDataLazyQueryHookResult = ReturnType<typeof useCompanyPriceDataLazyQuery>;
export type CompanyPriceDataQueryResult = Apollo.QueryResult<CompanyPriceDataQuery, CompanyPriceDataQueryVariables>;
export const UserActivityMyActivityDocument = gql`
    query userActivityMyActivity {
  myActivity {
    ...UserActivityActivity
  }
}
    ${UserActivityActivityFragmentDoc}`;

/**
 * __useUserActivityMyActivityQuery__
 *
 * To run a query within a React component, call `useUserActivityMyActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActivityMyActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActivityMyActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserActivityMyActivityQuery(baseOptions?: Apollo.QueryHookOptions<UserActivityMyActivityQuery, UserActivityMyActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserActivityMyActivityQuery, UserActivityMyActivityQueryVariables>(UserActivityMyActivityDocument, options);
      }
export function useUserActivityMyActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserActivityMyActivityQuery, UserActivityMyActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserActivityMyActivityQuery, UserActivityMyActivityQueryVariables>(UserActivityMyActivityDocument, options);
        }
export type UserActivityMyActivityQueryHookResult = ReturnType<typeof useUserActivityMyActivityQuery>;
export type UserActivityMyActivityLazyQueryHookResult = ReturnType<typeof useUserActivityMyActivityLazyQuery>;
export type UserActivityMyActivityQueryResult = Apollo.QueryResult<UserActivityMyActivityQuery, UserActivityMyActivityQueryVariables>;
export const CompanyWatchlistActorDocument = gql`
    query companyWatchlistActor {
  currentActor {
    id
    watchlist {
      id
      ...CompanyWatchlistCompany
    }
  }
}
    ${CompanyWatchlistCompanyFragmentDoc}`;

/**
 * __useCompanyWatchlistActorQuery__
 *
 * To run a query within a React component, call `useCompanyWatchlistActorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyWatchlistActorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyWatchlistActorQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyWatchlistActorQuery(baseOptions?: Apollo.QueryHookOptions<CompanyWatchlistActorQuery, CompanyWatchlistActorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyWatchlistActorQuery, CompanyWatchlistActorQueryVariables>(CompanyWatchlistActorDocument, options);
      }
export function useCompanyWatchlistActorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyWatchlistActorQuery, CompanyWatchlistActorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyWatchlistActorQuery, CompanyWatchlistActorQueryVariables>(CompanyWatchlistActorDocument, options);
        }
export type CompanyWatchlistActorQueryHookResult = ReturnType<typeof useCompanyWatchlistActorQuery>;
export type CompanyWatchlistActorLazyQueryHookResult = ReturnType<typeof useCompanyWatchlistActorLazyQuery>;
export type CompanyWatchlistActorQueryResult = Apollo.QueryResult<CompanyWatchlistActorQuery, CompanyWatchlistActorQueryVariables>;
export const IndexCompaniesPreviewDocument = gql`
    query indexCompaniesPreview {
  indexCompanies(first: 50, sortBy: {direction: DESC, field: POSTINGS_COUNT}) {
    edges {
      ...IndexCompanyPreviewEdge
    }
  }
}
    ${IndexCompanyPreviewEdgeFragmentDoc}`;

/**
 * __useIndexCompaniesPreviewQuery__
 *
 * To run a query within a React component, call `useIndexCompaniesPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexCompaniesPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexCompaniesPreviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndexCompaniesPreviewQuery(baseOptions?: Apollo.QueryHookOptions<IndexCompaniesPreviewQuery, IndexCompaniesPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndexCompaniesPreviewQuery, IndexCompaniesPreviewQueryVariables>(IndexCompaniesPreviewDocument, options);
      }
export function useIndexCompaniesPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndexCompaniesPreviewQuery, IndexCompaniesPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndexCompaniesPreviewQuery, IndexCompaniesPreviewQueryVariables>(IndexCompaniesPreviewDocument, options);
        }
export type IndexCompaniesPreviewQueryHookResult = ReturnType<typeof useIndexCompaniesPreviewQuery>;
export type IndexCompaniesPreviewLazyQueryHookResult = ReturnType<typeof useIndexCompaniesPreviewLazyQuery>;
export type IndexCompaniesPreviewQueryResult = Apollo.QueryResult<IndexCompaniesPreviewQuery, IndexCompaniesPreviewQueryVariables>;
export const LatestStandingBidsDocument = gql`
    query latestStandingBids($limit: Int!) {
  latestStandingBids(limit: $limit) {
    ...LatestActivityListStandingBid
  }
}
    ${LatestActivityListStandingBidFragmentDoc}`;

/**
 * __useLatestStandingBidsQuery__
 *
 * To run a query within a React component, call `useLatestStandingBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestStandingBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestStandingBidsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLatestStandingBidsQuery(baseOptions: Apollo.QueryHookOptions<LatestStandingBidsQuery, LatestStandingBidsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestStandingBidsQuery, LatestStandingBidsQueryVariables>(LatestStandingBidsDocument, options);
      }
export function useLatestStandingBidsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestStandingBidsQuery, LatestStandingBidsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestStandingBidsQuery, LatestStandingBidsQueryVariables>(LatestStandingBidsDocument, options);
        }
export type LatestStandingBidsQueryHookResult = ReturnType<typeof useLatestStandingBidsQuery>;
export type LatestStandingBidsLazyQueryHookResult = ReturnType<typeof useLatestStandingBidsLazyQuery>;
export type LatestStandingBidsQueryResult = Apollo.QueryResult<LatestStandingBidsQuery, LatestStandingBidsQueryVariables>;
export const LatestListingsDocument = gql`
    query latestListings($limit: Int!) {
  latestListings(limit: $limit) {
    ...LatestActivityListListing
  }
}
    ${LatestActivityListListingFragmentDoc}`;

/**
 * __useLatestListingsQuery__
 *
 * To run a query within a React component, call `useLatestListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestListingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLatestListingsQuery(baseOptions: Apollo.QueryHookOptions<LatestListingsQuery, LatestListingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestListingsQuery, LatestListingsQueryVariables>(LatestListingsDocument, options);
      }
export function useLatestListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestListingsQuery, LatestListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestListingsQuery, LatestListingsQueryVariables>(LatestListingsDocument, options);
        }
export type LatestListingsQueryHookResult = ReturnType<typeof useLatestListingsQuery>;
export type LatestListingsLazyQueryHookResult = ReturnType<typeof useLatestListingsLazyQuery>;
export type LatestListingsQueryResult = Apollo.QueryResult<LatestListingsQuery, LatestListingsQueryVariables>;
export const LatestMarketActivityDocument = gql`
    query latestMarketActivity($numListings: Int!, $numStandingBids: Int!) {
  latestListings(limit: $numListings) {
    ...LatestActivityListListing
  }
  latestStandingBids(limit: $numStandingBids) {
    ...LatestActivityListStandingBid
  }
}
    ${LatestActivityListListingFragmentDoc}
${LatestActivityListStandingBidFragmentDoc}`;

/**
 * __useLatestMarketActivityQuery__
 *
 * To run a query within a React component, call `useLatestMarketActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestMarketActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestMarketActivityQuery({
 *   variables: {
 *      numListings: // value for 'numListings'
 *      numStandingBids: // value for 'numStandingBids'
 *   },
 * });
 */
export function useLatestMarketActivityQuery(baseOptions: Apollo.QueryHookOptions<LatestMarketActivityQuery, LatestMarketActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestMarketActivityQuery, LatestMarketActivityQueryVariables>(LatestMarketActivityDocument, options);
      }
export function useLatestMarketActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestMarketActivityQuery, LatestMarketActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestMarketActivityQuery, LatestMarketActivityQueryVariables>(LatestMarketActivityDocument, options);
        }
export type LatestMarketActivityQueryHookResult = ReturnType<typeof useLatestMarketActivityQuery>;
export type LatestMarketActivityLazyQueryHookResult = ReturnType<typeof useLatestMarketActivityLazyQuery>;
export type LatestMarketActivityQueryResult = Apollo.QueryResult<LatestMarketActivityQuery, LatestMarketActivityQueryVariables>;
export const SignDocumentAlertMessagesMyActivityDocument = gql`
    query signDocumentAlertMessagesMyActivity {
  myActivity {
    myTransactions {
      ...SignDocumentAlertMessagesTransaction
    }
  }
}
    ${SignDocumentAlertMessagesTransactionFragmentDoc}`;

/**
 * __useSignDocumentAlertMessagesMyActivityQuery__
 *
 * To run a query within a React component, call `useSignDocumentAlertMessagesMyActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignDocumentAlertMessagesMyActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignDocumentAlertMessagesMyActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignDocumentAlertMessagesMyActivityQuery(baseOptions?: Apollo.QueryHookOptions<SignDocumentAlertMessagesMyActivityQuery, SignDocumentAlertMessagesMyActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignDocumentAlertMessagesMyActivityQuery, SignDocumentAlertMessagesMyActivityQueryVariables>(SignDocumentAlertMessagesMyActivityDocument, options);
      }
export function useSignDocumentAlertMessagesMyActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignDocumentAlertMessagesMyActivityQuery, SignDocumentAlertMessagesMyActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignDocumentAlertMessagesMyActivityQuery, SignDocumentAlertMessagesMyActivityQueryVariables>(SignDocumentAlertMessagesMyActivityDocument, options);
        }
export type SignDocumentAlertMessagesMyActivityQueryHookResult = ReturnType<typeof useSignDocumentAlertMessagesMyActivityQuery>;
export type SignDocumentAlertMessagesMyActivityLazyQueryHookResult = ReturnType<typeof useSignDocumentAlertMessagesMyActivityLazyQuery>;
export type SignDocumentAlertMessagesMyActivityQueryResult = Apollo.QueryResult<SignDocumentAlertMessagesMyActivityQuery, SignDocumentAlertMessagesMyActivityQueryVariables>;
export const WatchlistCompanySearchListCompaniesDocument = gql`
    query watchlistCompanySearchListCompanies($orderBy: ListCompaniesOrderBy!, $first: Int!, $searchText: String) {
  listCompanies(orderBy: $orderBy, first: $first, searchText: $searchText) {
    edges {
      ...WatchlistCompaniesSearchCompanyEdge
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${WatchlistCompaniesSearchCompanyEdgeFragmentDoc}`;

/**
 * __useWatchlistCompanySearchListCompaniesQuery__
 *
 * To run a query within a React component, call `useWatchlistCompanySearchListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchlistCompanySearchListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchlistCompanySearchListCompaniesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useWatchlistCompanySearchListCompaniesQuery(baseOptions: Apollo.QueryHookOptions<WatchlistCompanySearchListCompaniesQuery, WatchlistCompanySearchListCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WatchlistCompanySearchListCompaniesQuery, WatchlistCompanySearchListCompaniesQueryVariables>(WatchlistCompanySearchListCompaniesDocument, options);
      }
export function useWatchlistCompanySearchListCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WatchlistCompanySearchListCompaniesQuery, WatchlistCompanySearchListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WatchlistCompanySearchListCompaniesQuery, WatchlistCompanySearchListCompaniesQueryVariables>(WatchlistCompanySearchListCompaniesDocument, options);
        }
export type WatchlistCompanySearchListCompaniesQueryHookResult = ReturnType<typeof useWatchlistCompanySearchListCompaniesQuery>;
export type WatchlistCompanySearchListCompaniesLazyQueryHookResult = ReturnType<typeof useWatchlistCompanySearchListCompaniesLazyQuery>;
export type WatchlistCompanySearchListCompaniesQueryResult = Apollo.QueryResult<WatchlistCompanySearchListCompaniesQuery, WatchlistCompanySearchListCompaniesQueryVariables>;
export const DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockDocument = gql`
    query defaultUnaccreditedSellerDashboardPageListSharesSellNowBlock {
  unaccreditedSellerMyCompanyActivity {
    availableStandingBids {
      ...DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockStandingBid
    }
  }
  unaccreditedSellerMyActivity {
    myCompany {
      ...DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockCompany
    }
  }
}
    ${DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockStandingBidFragmentDoc}
${DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockCompanyFragmentDoc}`;

/**
 * __useDefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery__
 *
 * To run a query within a React component, call `useDefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery(baseOptions?: Apollo.QueryHookOptions<DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery, DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery, DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQueryVariables>(DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockDocument, options);
      }
export function useDefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery, DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery, DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQueryVariables>(DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockDocument, options);
        }
export type DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQueryHookResult = ReturnType<typeof useDefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery>;
export type DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockLazyQueryHookResult = ReturnType<typeof useDefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockLazyQuery>;
export type DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQueryResult = Apollo.QueryResult<DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQuery, DefaultUnaccreditedSellerDashboardPageListSharesSellNowBlockQueryVariables>;
export const UnaccreditedSellerDashboardByIdMarketActivityDocument = gql`
    query unaccreditedSellerDashboardByIdMarketActivity($id: ID!) {
  companyById(id: $id) {
    ...UnaccreditedSellerDashboardByIdMarketActivityCompany
  }
}
    ${UnaccreditedSellerDashboardByIdMarketActivityCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerDashboardByIdMarketActivityQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerDashboardByIdMarketActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerDashboardByIdMarketActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerDashboardByIdMarketActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerDashboardByIdMarketActivityQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerDashboardByIdMarketActivityQuery, UnaccreditedSellerDashboardByIdMarketActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerDashboardByIdMarketActivityQuery, UnaccreditedSellerDashboardByIdMarketActivityQueryVariables>(UnaccreditedSellerDashboardByIdMarketActivityDocument, options);
      }
export function useUnaccreditedSellerDashboardByIdMarketActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerDashboardByIdMarketActivityQuery, UnaccreditedSellerDashboardByIdMarketActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerDashboardByIdMarketActivityQuery, UnaccreditedSellerDashboardByIdMarketActivityQueryVariables>(UnaccreditedSellerDashboardByIdMarketActivityDocument, options);
        }
export type UnaccreditedSellerDashboardByIdMarketActivityQueryHookResult = ReturnType<typeof useUnaccreditedSellerDashboardByIdMarketActivityQuery>;
export type UnaccreditedSellerDashboardByIdMarketActivityLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerDashboardByIdMarketActivityLazyQuery>;
export type UnaccreditedSellerDashboardByIdMarketActivityQueryResult = Apollo.QueryResult<UnaccreditedSellerDashboardByIdMarketActivityQuery, UnaccreditedSellerDashboardByIdMarketActivityQueryVariables>;
export const DefaultUnaccreditedSellerDashboardPageDocument = gql`
    query defaultUnaccreditedSellerDashboardPage {
  unaccreditedSellerMyActivity {
    ...DefaultUnaccreditedSellerDashboardPageMyActivity
  }
  unaccreditedSellerMyCompanyActivity {
    standingBidsWithDiscussions {
      id
    }
  }
  currentContext {
    userNotificationToken
    currentActor {
      ...UserWithInstitution
    }
  }
}
    ${DefaultUnaccreditedSellerDashboardPageMyActivityFragmentDoc}
${UserWithInstitutionFragmentDoc}`;

/**
 * __useDefaultUnaccreditedSellerDashboardPageQuery__
 *
 * To run a query within a React component, call `useDefaultUnaccreditedSellerDashboardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultUnaccreditedSellerDashboardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultUnaccreditedSellerDashboardPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultUnaccreditedSellerDashboardPageQuery(baseOptions?: Apollo.QueryHookOptions<DefaultUnaccreditedSellerDashboardPageQuery, DefaultUnaccreditedSellerDashboardPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultUnaccreditedSellerDashboardPageQuery, DefaultUnaccreditedSellerDashboardPageQueryVariables>(DefaultUnaccreditedSellerDashboardPageDocument, options);
      }
export function useDefaultUnaccreditedSellerDashboardPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultUnaccreditedSellerDashboardPageQuery, DefaultUnaccreditedSellerDashboardPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultUnaccreditedSellerDashboardPageQuery, DefaultUnaccreditedSellerDashboardPageQueryVariables>(DefaultUnaccreditedSellerDashboardPageDocument, options);
        }
export type DefaultUnaccreditedSellerDashboardPageQueryHookResult = ReturnType<typeof useDefaultUnaccreditedSellerDashboardPageQuery>;
export type DefaultUnaccreditedSellerDashboardPageLazyQueryHookResult = ReturnType<typeof useDefaultUnaccreditedSellerDashboardPageLazyQuery>;
export type DefaultUnaccreditedSellerDashboardPageQueryResult = Apollo.QueryResult<DefaultUnaccreditedSellerDashboardPageQuery, DefaultUnaccreditedSellerDashboardPageQueryVariables>;
export const UnaccreditedSellerDashboardByIdPageDocument = gql`
    query unaccreditedSellerDashboardByIdPage($id: ID!) {
  companyById(id: $id) {
    ...UnaccreditedSellerDashboardByIdPageCompany
    ...GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardCompanyActivity
    ...UnaccreditedSellerDashboardPagePreListingHeroBannerCompany
  }
}
    ${UnaccreditedSellerDashboardByIdPageCompanyFragmentDoc}
${GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardCompanyActivityFragmentDoc}
${UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerDashboardByIdPageQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerDashboardByIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerDashboardByIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerDashboardByIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerDashboardByIdPageQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerDashboardByIdPageQuery, UnaccreditedSellerDashboardByIdPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerDashboardByIdPageQuery, UnaccreditedSellerDashboardByIdPageQueryVariables>(UnaccreditedSellerDashboardByIdPageDocument, options);
      }
export function useUnaccreditedSellerDashboardByIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerDashboardByIdPageQuery, UnaccreditedSellerDashboardByIdPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerDashboardByIdPageQuery, UnaccreditedSellerDashboardByIdPageQueryVariables>(UnaccreditedSellerDashboardByIdPageDocument, options);
        }
export type UnaccreditedSellerDashboardByIdPageQueryHookResult = ReturnType<typeof useUnaccreditedSellerDashboardByIdPageQuery>;
export type UnaccreditedSellerDashboardByIdPageLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerDashboardByIdPageLazyQuery>;
export type UnaccreditedSellerDashboardByIdPageQueryResult = Apollo.QueryResult<UnaccreditedSellerDashboardByIdPageQuery, UnaccreditedSellerDashboardByIdPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardPageActiveBidsPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardPageActiveBidsPage {
  unaccreditedSellerMyActivity {
    myListing {
      ...PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListing
    }
  }
  unaccreditedSellerMyListingActivity {
    activeBids {
      ...PostActivityUnaccreditedSellerDashboardPageActiveBidsPageBid
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragmentDoc}
${PostActivityUnaccreditedSellerDashboardPageActiveBidsPageBidFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery(baseOptions?: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery, PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery, PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPageActiveBidsPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardPageActiveBidsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery, PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery, PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPageActiveBidsPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery>;
export type PostActivityUnaccreditedSellerDashboardPageActiveBidsPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageActiveBidsPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQuery, PostActivityUnaccreditedSellerDashboardPageActiveBidsPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardByIdPageActiveBidsPage($id: ID!) {
  companyById(id: $id) {
    ...PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageCompany
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageCompanyFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery(baseOptions: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardPageInquiriesPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardPageInquiriesPage {
  unaccreditedSellerMyActivity {
    myListing {
      id
    }
  }
  unaccreditedSellerMyListingActivity {
    preBidDiscussions {
      ...PostActivityUnaccreditedSellerDashboardPageInquiriesPageDiscussion
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardPageInquiriesPageDiscussionFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery(baseOptions?: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery, PostActivityUnaccreditedSellerDashboardPageInquiriesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery, PostActivityUnaccreditedSellerDashboardPageInquiriesPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPageInquiriesPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardPageInquiriesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery, PostActivityUnaccreditedSellerDashboardPageInquiriesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery, PostActivityUnaccreditedSellerDashboardPageInquiriesPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPageInquiriesPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardPageInquiriesPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery>;
export type PostActivityUnaccreditedSellerDashboardPageInquiriesPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageInquiriesPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardPageInquiriesPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery, PostActivityUnaccreditedSellerDashboardPageInquiriesPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardByIdPageInquiriesPage($id: ID!, $topicFilter: MessageTopic) {
  companyById(id: $id) {
    id
    name
    activity {
      myListings {
        id
      }
    }
  }
  myDiscussions(topicFilter: $topicFilter) {
    ...PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDiscussion
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDiscussionFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      topicFilter: // value for 'topicFilter'
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery(baseOptions: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardPageOverviewPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardPageOverviewPage {
  unaccreditedSellerMyActivity {
    myCompany {
      ...PostActivityUnaccreditedSellerDashboardPageOverviewPageCompany
    }
    myListing {
      id
    }
    pendingSales {
      id
    }
    completedSales {
      id
    }
  }
  unaccreditedSellerMyCompanyActivity {
    standingBidsWithDiscussions {
      id
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardPageOverviewPageCompanyFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardPageOverviewPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardPageOverviewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardPageOverviewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardPageOverviewPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardPageOverviewPageQuery(baseOptions?: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardPageOverviewPageQuery, PostActivityUnaccreditedSellerDashboardPageOverviewPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardPageOverviewPageQuery, PostActivityUnaccreditedSellerDashboardPageOverviewPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPageOverviewPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardPageOverviewPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardPageOverviewPageQuery, PostActivityUnaccreditedSellerDashboardPageOverviewPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardPageOverviewPageQuery, PostActivityUnaccreditedSellerDashboardPageOverviewPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPageOverviewPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardPageOverviewPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageOverviewPageQuery>;
export type PostActivityUnaccreditedSellerDashboardPageOverviewPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageOverviewPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardPageOverviewPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardPageOverviewPageQuery, PostActivityUnaccreditedSellerDashboardPageOverviewPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardPagePastBidsPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardPagePastBidsPage {
  unaccreditedSellerMyActivity {
    myListing {
      id
    }
  }
  unaccreditedSellerMyListingActivity {
    pastBids {
      ...PostActivityUnaccreditedSellerDashboardPagePastBidsPageBid
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardPagePastBidsPageBidFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery(baseOptions?: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery, PostActivityUnaccreditedSellerDashboardPagePastBidsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery, PostActivityUnaccreditedSellerDashboardPagePastBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPagePastBidsPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardPagePastBidsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery, PostActivityUnaccreditedSellerDashboardPagePastBidsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery, PostActivityUnaccreditedSellerDashboardPagePastBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPagePastBidsPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardPagePastBidsPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery>;
export type PostActivityUnaccreditedSellerDashboardPagePastBidsPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPagePastBidsPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardPagePastBidsPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery, PostActivityUnaccreditedSellerDashboardPagePastBidsPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardByIdPagePastBidsPage($id: ID!) {
  companyById(id: $id) {
    ...PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageCompany
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageCompanyFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery(baseOptions: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardPageStandingBidsPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardPageStandingBidsPage {
  unaccreditedSellerMyActivity {
    myCompany {
      name
      id
    }
    myListing {
      ...PostActivityUnaccreditedSellerDashboardPageStandingBidsPageListing
    }
    pendingSales {
      id
    }
    completedSales {
      id
    }
  }
  unaccreditedSellerMyCompanyActivity {
    availableStandingBids {
      ...PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBid
    }
    standingBidsWithDiscussions {
      ...PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBid
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardPageStandingBidsPageListingFragmentDoc}
${PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery(baseOptions?: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery, PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery, PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPageStandingBidsPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardPageStandingBidsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery, PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery, PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPageStandingBidsPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery>;
export type PostActivityUnaccreditedSellerDashboardPageStandingBidsPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageStandingBidsPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery, PostActivityUnaccreditedSellerDashboardPageStandingBidsPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardByIdPageStandingBidsPage($id: ID!) {
  companyById(id: $id) {
    ...PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompany
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageCompanyFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery(baseOptions: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageStandingBidsPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardPageTransactionsPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardPageTransactionsPage {
  unaccreditedSellerMyActivity {
    myListing {
      id
    }
    pendingSales {
      ...PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransaction
    }
    completedSales {
      ...PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransaction
    }
  }
  unaccreditedSellerMyCompanyActivity {
    standingBidsWithDiscussions {
      id
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery(baseOptions?: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery, PostActivityUnaccreditedSellerDashboardPageTransactionsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery, PostActivityUnaccreditedSellerDashboardPageTransactionsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPageTransactionsPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardPageTransactionsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery, PostActivityUnaccreditedSellerDashboardPageTransactionsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery, PostActivityUnaccreditedSellerDashboardPageTransactionsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardPageTransactionsPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardPageTransactionsPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery>;
export type PostActivityUnaccreditedSellerDashboardPageTransactionsPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageTransactionsPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardPageTransactionsPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery, PostActivityUnaccreditedSellerDashboardPageTransactionsPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageDocument = gql`
    query postActivityUnaccreditedSellerDashboardByIdPageTransactionsPage($id: ID!) {
  companyById(id: $id) {
    ...PostActivityUnaccreditedSellerDashboardByIdPageTransactionPageCompany
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageTransactionPageCompanyFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery(baseOptions: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery, PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardPageHeroBannerDocument = gql`
    query postActivityUnaccreditedSellerDashboardPageHeroBanner {
  unaccreditedSellerMyActivity {
    myListing {
      id
    }
    myCompany {
      ...PostActivityUnaccreditedSellerDashboardPageHeroBannerCompany
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardPageHeroBannerCompanyFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardPageHeroBannerQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardPageHeroBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardPageHeroBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardPageHeroBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardPageHeroBannerQuery(baseOptions?: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardPageHeroBannerQuery, PostActivityUnaccreditedSellerDashboardPageHeroBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardPageHeroBannerQuery, PostActivityUnaccreditedSellerDashboardPageHeroBannerQueryVariables>(PostActivityUnaccreditedSellerDashboardPageHeroBannerDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardPageHeroBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardPageHeroBannerQuery, PostActivityUnaccreditedSellerDashboardPageHeroBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardPageHeroBannerQuery, PostActivityUnaccreditedSellerDashboardPageHeroBannerQueryVariables>(PostActivityUnaccreditedSellerDashboardPageHeroBannerDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardPageHeroBannerQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageHeroBannerQuery>;
export type PostActivityUnaccreditedSellerDashboardPageHeroBannerLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageHeroBannerLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardPageHeroBannerQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardPageHeroBannerQuery, PostActivityUnaccreditedSellerDashboardPageHeroBannerQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerDocument = gql`
    query postActivityUnaccreditedSellerDashboardByIdPageHeroBanner($id: ID!) {
  companyById(id: $id) {
    ...PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerCompany
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerCompanyFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery(baseOptions: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery, PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery, PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardByIdPageHeroBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery, PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery, PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageHeroBannerLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQuery, PostActivityUnaccreditedSellerDashboardByIdPageHeroBannerQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardDocument = gql`
    query postActivityUnaccreditedSellerDashboardPageSidebarSellNowCard {
  unaccreditedSellerMyActivity {
    myListing {
      id
    }
  }
}
    `;

/**
 * __usePostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery(baseOptions?: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery, PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery, PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQueryVariables>(PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery, PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery, PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQueryVariables>(PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery>;
export type PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQuery, PostActivityUnaccreditedSellerDashboardPageSidebarSellNowCardQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardDocument = gql`
    query postActivityUnaccreditedSellerDashboardPageSidebarNavigationCard {
  unaccreditedSellerMyActivity {
    myCompany {
      name
      id
    }
    myListing {
      id
    }
  }
}
    `;

/**
 * __usePostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery(baseOptions?: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery, PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery, PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQueryVariables>(PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery, PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery, PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQueryVariables>(PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery>;
export type PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery, PostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardByIdPageSidebarDocument = gql`
    query postActivityUnaccreditedSellerDashboardByIdPageSidebar($id: ID!) {
  companyById(id: $id) {
    ...PostActivityUnaccreditedSellerDashboardByIdPageSidebarCompany
  }
}
    ${PostActivityUnaccreditedSellerDashboardByIdPageSidebarCompanyFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery(baseOptions: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery, PostActivityUnaccreditedSellerDashboardByIdPageSidebarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery, PostActivityUnaccreditedSellerDashboardByIdPageSidebarQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageSidebarDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardByIdPageSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery, PostActivityUnaccreditedSellerDashboardByIdPageSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery, PostActivityUnaccreditedSellerDashboardByIdPageSidebarQueryVariables>(PostActivityUnaccreditedSellerDashboardByIdPageSidebarDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardByIdPageSidebarQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageSidebarLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardByIdPageSidebarLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardByIdPageSidebarQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery, PostActivityUnaccreditedSellerDashboardByIdPageSidebarQueryVariables>;
export const PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesDocument = gql`
    query postActivityUnaccreditedSellerDashboardPageSidebarInformativeArticles {
  unaccreditedSellerMyActivity {
    myCompany {
      ...PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesCompany
    }
  }
}
    ${PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesCompanyFragmentDoc}`;

/**
 * __usePostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery__
 *
 * To run a query within a React component, call `usePostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery(baseOptions?: Apollo.QueryHookOptions<PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery, PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery, PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQueryVariables>(PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesDocument, options);
      }
export function usePostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery, PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery, PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQueryVariables>(PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesDocument, options);
        }
export type PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery>;
export type PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesLazyQueryHookResult = ReturnType<typeof usePostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesLazyQuery>;
export type PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQueryResult = Apollo.QueryResult<PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery, PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQueryVariables>;
export const AccreditationQuestionGroupsDocument = gql`
    query accreditationQuestionGroups($version: Int!, $investorType: InvestorStatus!, $countryId: ID!) {
  accreditationQuestionGroups(
    version: $version
    investorType: $investorType
    countryId: $countryId
  ) {
    id
    questions {
      id
      text
      order
    }
  }
}
    `;

/**
 * __useAccreditationQuestionGroupsQuery__
 *
 * To run a query within a React component, call `useAccreditationQuestionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccreditationQuestionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccreditationQuestionGroupsQuery({
 *   variables: {
 *      version: // value for 'version'
 *      investorType: // value for 'investorType'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useAccreditationQuestionGroupsQuery(baseOptions: Apollo.QueryHookOptions<AccreditationQuestionGroupsQuery, AccreditationQuestionGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccreditationQuestionGroupsQuery, AccreditationQuestionGroupsQueryVariables>(AccreditationQuestionGroupsDocument, options);
      }
export function useAccreditationQuestionGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccreditationQuestionGroupsQuery, AccreditationQuestionGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccreditationQuestionGroupsQuery, AccreditationQuestionGroupsQueryVariables>(AccreditationQuestionGroupsDocument, options);
        }
export type AccreditationQuestionGroupsQueryHookResult = ReturnType<typeof useAccreditationQuestionGroupsQuery>;
export type AccreditationQuestionGroupsLazyQueryHookResult = ReturnType<typeof useAccreditationQuestionGroupsLazyQuery>;
export type AccreditationQuestionGroupsQueryResult = Apollo.QueryResult<AccreditationQuestionGroupsQuery, AccreditationQuestionGroupsQueryVariables>;
export const InvestmentGoalQuestionGroupsDocument = gql`
    query investmentGoalQuestionGroups($countryId: ID!, $investorType: InvestorStatus, $version: Int!) {
  investmentGoalQuestionGroups(
    countryId: $countryId
    investorType: $investorType
    version: $version
  ) {
    id
    questions {
      ...BasicInvestmentGoalQuestion
      options {
        id
        custom
        text
        nextQuestionIds
        description
        order
      }
    }
  }
}
    ${BasicInvestmentGoalQuestionFragmentDoc}`;

/**
 * __useInvestmentGoalQuestionGroupsQuery__
 *
 * To run a query within a React component, call `useInvestmentGoalQuestionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentGoalQuestionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentGoalQuestionGroupsQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      investorType: // value for 'investorType'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useInvestmentGoalQuestionGroupsQuery(baseOptions: Apollo.QueryHookOptions<InvestmentGoalQuestionGroupsQuery, InvestmentGoalQuestionGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestmentGoalQuestionGroupsQuery, InvestmentGoalQuestionGroupsQueryVariables>(InvestmentGoalQuestionGroupsDocument, options);
      }
export function useInvestmentGoalQuestionGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestmentGoalQuestionGroupsQuery, InvestmentGoalQuestionGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestmentGoalQuestionGroupsQuery, InvestmentGoalQuestionGroupsQueryVariables>(InvestmentGoalQuestionGroupsDocument, options);
        }
export type InvestmentGoalQuestionGroupsQueryHookResult = ReturnType<typeof useInvestmentGoalQuestionGroupsQuery>;
export type InvestmentGoalQuestionGroupsLazyQueryHookResult = ReturnType<typeof useInvestmentGoalQuestionGroupsLazyQuery>;
export type InvestmentGoalQuestionGroupsQueryResult = Apollo.QueryResult<InvestmentGoalQuestionGroupsQuery, InvestmentGoalQuestionGroupsQueryVariables>;
export const ToggleFeatureFlagDocument = gql`
    mutation toggleFeatureFlag($input: ToggleFeatureFlagInput!) {
  toggleFeatureFlag(input: $input) {
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type ToggleFeatureFlagMutationFn = Apollo.MutationFunction<ToggleFeatureFlagMutation, ToggleFeatureFlagMutationVariables>;

/**
 * __useToggleFeatureFlagMutation__
 *
 * To run a mutation, you first call `useToggleFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleFeatureFlagMutation, { data, loading, error }] = useToggleFeatureFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleFeatureFlagMutation(baseOptions?: Apollo.MutationHookOptions<ToggleFeatureFlagMutation, ToggleFeatureFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleFeatureFlagMutation, ToggleFeatureFlagMutationVariables>(ToggleFeatureFlagDocument, options);
      }
export type ToggleFeatureFlagMutationHookResult = ReturnType<typeof useToggleFeatureFlagMutation>;
export type ToggleFeatureFlagMutationResult = Apollo.MutationResult<ToggleFeatureFlagMutation>;
export type ToggleFeatureFlagMutationOptions = Apollo.BaseMutationOptions<ToggleFeatureFlagMutation, ToggleFeatureFlagMutationVariables>;
export const IndexCompaniesDocument = gql`
    query indexCompanies {
  indexCompanies(first: 50, sortBy: {direction: DESC, field: POSTINGS_COUNT}) {
    edges {
      ...IndexCompanyEdge
    }
  }
}
    ${IndexCompanyEdgeFragmentDoc}`;

/**
 * __useIndexCompaniesQuery__
 *
 * To run a query within a React component, call `useIndexCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndexCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<IndexCompaniesQuery, IndexCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndexCompaniesQuery, IndexCompaniesQueryVariables>(IndexCompaniesDocument, options);
      }
export function useIndexCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndexCompaniesQuery, IndexCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndexCompaniesQuery, IndexCompaniesQueryVariables>(IndexCompaniesDocument, options);
        }
export type IndexCompaniesQueryHookResult = ReturnType<typeof useIndexCompaniesQuery>;
export type IndexCompaniesLazyQueryHookResult = ReturnType<typeof useIndexCompaniesLazyQuery>;
export type IndexCompaniesQueryResult = Apollo.QueryResult<IndexCompaniesQuery, IndexCompaniesQueryVariables>;
export const HiiveFiftyIndexPricesDocument = gql`
    query hiiveFiftyIndexPrices($filterBy: Hiive50IndexPriceFilter!) {
  hiiveFiftyIndexPrices(filterBy: $filterBy) {
    ...IndexPriceTrendResponse
  }
}
    ${IndexPriceTrendResponseFragmentDoc}`;

/**
 * __useHiiveFiftyIndexPricesQuery__
 *
 * To run a query within a React component, call `useHiiveFiftyIndexPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiiveFiftyIndexPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiiveFiftyIndexPricesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useHiiveFiftyIndexPricesQuery(baseOptions: Apollo.QueryHookOptions<HiiveFiftyIndexPricesQuery, HiiveFiftyIndexPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HiiveFiftyIndexPricesQuery, HiiveFiftyIndexPricesQueryVariables>(HiiveFiftyIndexPricesDocument, options);
      }
export function useHiiveFiftyIndexPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HiiveFiftyIndexPricesQuery, HiiveFiftyIndexPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HiiveFiftyIndexPricesQuery, HiiveFiftyIndexPricesQueryVariables>(HiiveFiftyIndexPricesDocument, options);
        }
export type HiiveFiftyIndexPricesQueryHookResult = ReturnType<typeof useHiiveFiftyIndexPricesQuery>;
export type HiiveFiftyIndexPricesLazyQueryHookResult = ReturnType<typeof useHiiveFiftyIndexPricesLazyQuery>;
export type HiiveFiftyIndexPricesQueryResult = Apollo.QueryResult<HiiveFiftyIndexPricesQuery, HiiveFiftyIndexPricesQueryVariables>;
export const ConfigDocument = gql`
    query config {
  config {
    ...RootConfig
  }
}
    ${RootConfigFragmentDoc}`;

/**
 * __useConfigQuery__
 *
 * To run a query within a React component, call `useConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigQuery(baseOptions?: Apollo.QueryHookOptions<ConfigQuery, ConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
      }
export function useConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfigQuery, ConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
        }
export type ConfigQueryHookResult = ReturnType<typeof useConfigQuery>;
export type ConfigLazyQueryHookResult = ReturnType<typeof useConfigLazyQuery>;
export type ConfigQueryResult = Apollo.QueryResult<ConfigQuery, ConfigQueryVariables>;
export const ListingFeeBreakdownCommissionInfoDocument = gql`
    query listingFeeBreakdownCommissionInfo($totalShares: Int!, $pricePerShare: Int!, $listingId: ID, $companyId: ID) {
  commissionInfo(
    totalShares: $totalShares
    pricePerShare: $pricePerShare
    listingId: $listingId
    companyId: $companyId
  ) {
    ...ListingFeeBreakdownCommissionInfo
    ...ListingFeeDiscountInfo
  }
}
    ${ListingFeeBreakdownCommissionInfoFragmentDoc}
${ListingFeeDiscountInfoFragmentDoc}`;

/**
 * __useListingFeeBreakdownCommissionInfoQuery__
 *
 * To run a query within a React component, call `useListingFeeBreakdownCommissionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingFeeBreakdownCommissionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingFeeBreakdownCommissionInfoQuery({
 *   variables: {
 *      totalShares: // value for 'totalShares'
 *      pricePerShare: // value for 'pricePerShare'
 *      listingId: // value for 'listingId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useListingFeeBreakdownCommissionInfoQuery(baseOptions: Apollo.QueryHookOptions<ListingFeeBreakdownCommissionInfoQuery, ListingFeeBreakdownCommissionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingFeeBreakdownCommissionInfoQuery, ListingFeeBreakdownCommissionInfoQueryVariables>(ListingFeeBreakdownCommissionInfoDocument, options);
      }
export function useListingFeeBreakdownCommissionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingFeeBreakdownCommissionInfoQuery, ListingFeeBreakdownCommissionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingFeeBreakdownCommissionInfoQuery, ListingFeeBreakdownCommissionInfoQueryVariables>(ListingFeeBreakdownCommissionInfoDocument, options);
        }
export type ListingFeeBreakdownCommissionInfoQueryHookResult = ReturnType<typeof useListingFeeBreakdownCommissionInfoQuery>;
export type ListingFeeBreakdownCommissionInfoLazyQueryHookResult = ReturnType<typeof useListingFeeBreakdownCommissionInfoLazyQuery>;
export type ListingFeeBreakdownCommissionInfoQueryResult = Apollo.QueryResult<ListingFeeBreakdownCommissionInfoQuery, ListingFeeBreakdownCommissionInfoQueryVariables>;
export const SharePriceCardUnaccreditedSellerMyActivityDocument = gql`
    query sharePriceCardUnaccreditedSellerMyActivity {
  unaccreditedSellerMyActivity {
    myCompany {
      ...SharePriceCardCompany
    }
  }
}
    ${SharePriceCardCompanyFragmentDoc}`;

/**
 * __useSharePriceCardUnaccreditedSellerMyActivityQuery__
 *
 * To run a query within a React component, call `useSharePriceCardUnaccreditedSellerMyActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharePriceCardUnaccreditedSellerMyActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharePriceCardUnaccreditedSellerMyActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useSharePriceCardUnaccreditedSellerMyActivityQuery(baseOptions?: Apollo.QueryHookOptions<SharePriceCardUnaccreditedSellerMyActivityQuery, SharePriceCardUnaccreditedSellerMyActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharePriceCardUnaccreditedSellerMyActivityQuery, SharePriceCardUnaccreditedSellerMyActivityQueryVariables>(SharePriceCardUnaccreditedSellerMyActivityDocument, options);
      }
export function useSharePriceCardUnaccreditedSellerMyActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharePriceCardUnaccreditedSellerMyActivityQuery, SharePriceCardUnaccreditedSellerMyActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharePriceCardUnaccreditedSellerMyActivityQuery, SharePriceCardUnaccreditedSellerMyActivityQueryVariables>(SharePriceCardUnaccreditedSellerMyActivityDocument, options);
        }
export type SharePriceCardUnaccreditedSellerMyActivityQueryHookResult = ReturnType<typeof useSharePriceCardUnaccreditedSellerMyActivityQuery>;
export type SharePriceCardUnaccreditedSellerMyActivityLazyQueryHookResult = ReturnType<typeof useSharePriceCardUnaccreditedSellerMyActivityLazyQuery>;
export type SharePriceCardUnaccreditedSellerMyActivityQueryResult = Apollo.QueryResult<SharePriceCardUnaccreditedSellerMyActivityQuery, SharePriceCardUnaccreditedSellerMyActivityQueryVariables>;
export const UnaccreditedSellerCreateListingV2Document = gql`
    mutation unaccreditedSellerCreateListingV2($input: UnaccreditedSellerCreateListingInputV2!) {
  unaccreditedSellerCreateListingV2(input: $input) {
    listing {
      ...UnaccreditedSellerCreateListingPageListing
    }
    errors {
      ...FullInputError
    }
  }
}
    ${UnaccreditedSellerCreateListingPageListingFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type UnaccreditedSellerCreateListingV2MutationFn = Apollo.MutationFunction<UnaccreditedSellerCreateListingV2Mutation, UnaccreditedSellerCreateListingV2MutationVariables>;

/**
 * __useUnaccreditedSellerCreateListingV2Mutation__
 *
 * To run a mutation, you first call `useUnaccreditedSellerCreateListingV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCreateListingV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unaccreditedSellerCreateListingV2Mutation, { data, loading, error }] = useUnaccreditedSellerCreateListingV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnaccreditedSellerCreateListingV2Mutation(baseOptions?: Apollo.MutationHookOptions<UnaccreditedSellerCreateListingV2Mutation, UnaccreditedSellerCreateListingV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnaccreditedSellerCreateListingV2Mutation, UnaccreditedSellerCreateListingV2MutationVariables>(UnaccreditedSellerCreateListingV2Document, options);
      }
export type UnaccreditedSellerCreateListingV2MutationHookResult = ReturnType<typeof useUnaccreditedSellerCreateListingV2Mutation>;
export type UnaccreditedSellerCreateListingV2MutationResult = Apollo.MutationResult<UnaccreditedSellerCreateListingV2Mutation>;
export type UnaccreditedSellerCreateListingV2MutationOptions = Apollo.BaseMutationOptions<UnaccreditedSellerCreateListingV2Mutation, UnaccreditedSellerCreateListingV2MutationVariables>;
export const UnaccreditedSellerCreateListingPageDocument = gql`
    query unaccreditedSellerCreateListingPage {
  unaccreditedSellerMyActivity {
    myListing {
      ...UnaccreditedSellerCreateListingPageListing
    }
  }
}
    ${UnaccreditedSellerCreateListingPageListingFragmentDoc}`;

/**
 * __useUnaccreditedSellerCreateListingPageQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCreateListingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCreateListingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCreateListingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerCreateListingPageQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerCreateListingPageQuery, UnaccreditedSellerCreateListingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCreateListingPageQuery, UnaccreditedSellerCreateListingPageQueryVariables>(UnaccreditedSellerCreateListingPageDocument, options);
      }
export function useUnaccreditedSellerCreateListingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCreateListingPageQuery, UnaccreditedSellerCreateListingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCreateListingPageQuery, UnaccreditedSellerCreateListingPageQueryVariables>(UnaccreditedSellerCreateListingPageDocument, options);
        }
export type UnaccreditedSellerCreateListingPageQueryHookResult = ReturnType<typeof useUnaccreditedSellerCreateListingPageQuery>;
export type UnaccreditedSellerCreateListingPageLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCreateListingPageLazyQuery>;
export type UnaccreditedSellerCreateListingPageQueryResult = Apollo.QueryResult<UnaccreditedSellerCreateListingPageQuery, UnaccreditedSellerCreateListingPageQueryVariables>;
export const UnaccreditedSellerCreateListingByIdPageDocument = gql`
    query unaccreditedSellerCreateListingByIdPage($id: ID!) {
  companyById(id: $id) {
    ...UnaccreditedSellerCreateListingPageByIdCompany
  }
}
    ${UnaccreditedSellerCreateListingPageByIdCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerCreateListingByIdPageQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerCreateListingByIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerCreateListingByIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerCreateListingByIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerCreateListingByIdPageQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerCreateListingByIdPageQuery, UnaccreditedSellerCreateListingByIdPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerCreateListingByIdPageQuery, UnaccreditedSellerCreateListingByIdPageQueryVariables>(UnaccreditedSellerCreateListingByIdPageDocument, options);
      }
export function useUnaccreditedSellerCreateListingByIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerCreateListingByIdPageQuery, UnaccreditedSellerCreateListingByIdPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerCreateListingByIdPageQuery, UnaccreditedSellerCreateListingByIdPageQueryVariables>(UnaccreditedSellerCreateListingByIdPageDocument, options);
        }
export type UnaccreditedSellerCreateListingByIdPageQueryHookResult = ReturnType<typeof useUnaccreditedSellerCreateListingByIdPageQuery>;
export type UnaccreditedSellerCreateListingByIdPageLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerCreateListingByIdPageLazyQuery>;
export type UnaccreditedSellerCreateListingByIdPageQueryResult = Apollo.QueryResult<UnaccreditedSellerCreateListingByIdPageQuery, UnaccreditedSellerCreateListingByIdPageQueryVariables>;
export const UnaccreditedSellerModifyListingV2Document = gql`
    mutation unaccreditedSellerModifyListingV2($input: UnaccreditedSellerModifyListingInputV2!) {
  unaccreditedSellerModifyListingV2(input: $input) {
    listing {
      ...UnaccreditedSellerModifyListingPageListing
    }
    errors {
      ...FullInputError
    }
  }
}
    ${UnaccreditedSellerModifyListingPageListingFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type UnaccreditedSellerModifyListingV2MutationFn = Apollo.MutationFunction<UnaccreditedSellerModifyListingV2Mutation, UnaccreditedSellerModifyListingV2MutationVariables>;

/**
 * __useUnaccreditedSellerModifyListingV2Mutation__
 *
 * To run a mutation, you first call `useUnaccreditedSellerModifyListingV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerModifyListingV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unaccreditedSellerModifyListingV2Mutation, { data, loading, error }] = useUnaccreditedSellerModifyListingV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnaccreditedSellerModifyListingV2Mutation(baseOptions?: Apollo.MutationHookOptions<UnaccreditedSellerModifyListingV2Mutation, UnaccreditedSellerModifyListingV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnaccreditedSellerModifyListingV2Mutation, UnaccreditedSellerModifyListingV2MutationVariables>(UnaccreditedSellerModifyListingV2Document, options);
      }
export type UnaccreditedSellerModifyListingV2MutationHookResult = ReturnType<typeof useUnaccreditedSellerModifyListingV2Mutation>;
export type UnaccreditedSellerModifyListingV2MutationResult = Apollo.MutationResult<UnaccreditedSellerModifyListingV2Mutation>;
export type UnaccreditedSellerModifyListingV2MutationOptions = Apollo.BaseMutationOptions<UnaccreditedSellerModifyListingV2Mutation, UnaccreditedSellerModifyListingV2MutationVariables>;
export const UnaccreditedSellerModifyListingPageDocument = gql`
    query unaccreditedSellerModifyListingPage {
  unaccreditedSellerMyActivity {
    myListing {
      ...UnaccreditedSellerModifyListingPageListing
    }
  }
}
    ${UnaccreditedSellerModifyListingPageListingFragmentDoc}`;

/**
 * __useUnaccreditedSellerModifyListingPageQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerModifyListingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerModifyListingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerModifyListingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerModifyListingPageQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerModifyListingPageQuery, UnaccreditedSellerModifyListingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerModifyListingPageQuery, UnaccreditedSellerModifyListingPageQueryVariables>(UnaccreditedSellerModifyListingPageDocument, options);
      }
export function useUnaccreditedSellerModifyListingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerModifyListingPageQuery, UnaccreditedSellerModifyListingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerModifyListingPageQuery, UnaccreditedSellerModifyListingPageQueryVariables>(UnaccreditedSellerModifyListingPageDocument, options);
        }
export type UnaccreditedSellerModifyListingPageQueryHookResult = ReturnType<typeof useUnaccreditedSellerModifyListingPageQuery>;
export type UnaccreditedSellerModifyListingPageLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerModifyListingPageLazyQuery>;
export type UnaccreditedSellerModifyListingPageQueryResult = Apollo.QueryResult<UnaccreditedSellerModifyListingPageQuery, UnaccreditedSellerModifyListingPageQueryVariables>;
export const UnaccreditedSellerModifyListingPageSharePriceCardDocument = gql`
    query unaccreditedSellerModifyListingPageSharePriceCard {
  unaccreditedSellerMyActivity {
    myCompany {
      ...UnaccreditedSellerModifyListingPageSharePriceCardCompany
    }
  }
}
    ${UnaccreditedSellerModifyListingPageSharePriceCardCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerModifyListingPageSharePriceCardQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerModifyListingPageSharePriceCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerModifyListingPageSharePriceCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerModifyListingPageSharePriceCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerModifyListingPageSharePriceCardQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerModifyListingPageSharePriceCardQuery, UnaccreditedSellerModifyListingPageSharePriceCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerModifyListingPageSharePriceCardQuery, UnaccreditedSellerModifyListingPageSharePriceCardQueryVariables>(UnaccreditedSellerModifyListingPageSharePriceCardDocument, options);
      }
export function useUnaccreditedSellerModifyListingPageSharePriceCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerModifyListingPageSharePriceCardQuery, UnaccreditedSellerModifyListingPageSharePriceCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerModifyListingPageSharePriceCardQuery, UnaccreditedSellerModifyListingPageSharePriceCardQueryVariables>(UnaccreditedSellerModifyListingPageSharePriceCardDocument, options);
        }
export type UnaccreditedSellerModifyListingPageSharePriceCardQueryHookResult = ReturnType<typeof useUnaccreditedSellerModifyListingPageSharePriceCardQuery>;
export type UnaccreditedSellerModifyListingPageSharePriceCardLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerModifyListingPageSharePriceCardLazyQuery>;
export type UnaccreditedSellerModifyListingPageSharePriceCardQueryResult = Apollo.QueryResult<UnaccreditedSellerModifyListingPageSharePriceCardQuery, UnaccreditedSellerModifyListingPageSharePriceCardQueryVariables>;
export const UnaccreditedSellerModifyListingByIdPageDocument = gql`
    query unaccreditedSellerModifyListingByIdPage($id: ID!) {
  listingById(id: $id) {
    ...UnaccreditedSellerModifyListingByIdPageListing
  }
}
    ${UnaccreditedSellerModifyListingByIdPageListingFragmentDoc}`;

/**
 * __useUnaccreditedSellerModifyListingByIdPageQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerModifyListingByIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerModifyListingByIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerModifyListingByIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerModifyListingByIdPageQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerModifyListingByIdPageQuery, UnaccreditedSellerModifyListingByIdPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerModifyListingByIdPageQuery, UnaccreditedSellerModifyListingByIdPageQueryVariables>(UnaccreditedSellerModifyListingByIdPageDocument, options);
      }
export function useUnaccreditedSellerModifyListingByIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerModifyListingByIdPageQuery, UnaccreditedSellerModifyListingByIdPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerModifyListingByIdPageQuery, UnaccreditedSellerModifyListingByIdPageQueryVariables>(UnaccreditedSellerModifyListingByIdPageDocument, options);
        }
export type UnaccreditedSellerModifyListingByIdPageQueryHookResult = ReturnType<typeof useUnaccreditedSellerModifyListingByIdPageQuery>;
export type UnaccreditedSellerModifyListingByIdPageLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerModifyListingByIdPageLazyQuery>;
export type UnaccreditedSellerModifyListingByIdPageQueryResult = Apollo.QueryResult<UnaccreditedSellerModifyListingByIdPageQuery, UnaccreditedSellerModifyListingByIdPageQueryVariables>;
export const CreateListingSelectCompanyInputListCompaniesDocument = gql`
    query createListingSelectCompanyInputListCompanies($orderBy: ListCompaniesOrderBy!, $first: Int!, $searchText: String) {
  listCompanies(orderBy: $orderBy, first: $first, searchText: $searchText) {
    edges {
      ...CreateListingSelectCompanyInputCompanyEdge
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${CreateListingSelectCompanyInputCompanyEdgeFragmentDoc}`;

/**
 * __useCreateListingSelectCompanyInputListCompaniesQuery__
 *
 * To run a query within a React component, call `useCreateListingSelectCompanyInputListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateListingSelectCompanyInputListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateListingSelectCompanyInputListCompaniesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useCreateListingSelectCompanyInputListCompaniesQuery(baseOptions: Apollo.QueryHookOptions<CreateListingSelectCompanyInputListCompaniesQuery, CreateListingSelectCompanyInputListCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateListingSelectCompanyInputListCompaniesQuery, CreateListingSelectCompanyInputListCompaniesQueryVariables>(CreateListingSelectCompanyInputListCompaniesDocument, options);
      }
export function useCreateListingSelectCompanyInputListCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateListingSelectCompanyInputListCompaniesQuery, CreateListingSelectCompanyInputListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateListingSelectCompanyInputListCompaniesQuery, CreateListingSelectCompanyInputListCompaniesQueryVariables>(CreateListingSelectCompanyInputListCompaniesDocument, options);
        }
export type CreateListingSelectCompanyInputListCompaniesQueryHookResult = ReturnType<typeof useCreateListingSelectCompanyInputListCompaniesQuery>;
export type CreateListingSelectCompanyInputListCompaniesLazyQueryHookResult = ReturnType<typeof useCreateListingSelectCompanyInputListCompaniesLazyQuery>;
export type CreateListingSelectCompanyInputListCompaniesQueryResult = Apollo.QueryResult<CreateListingSelectCompanyInputListCompaniesQuery, CreateListingSelectCompanyInputListCompaniesQueryVariables>;
export const DiscussionPageDiscussionByIdDocument = gql`
    query discussionPageDiscussionById($id: ID!) {
  discussionById(id: $id) {
    ...DiscussionPageDiscussion
  }
}
    ${DiscussionPageDiscussionFragmentDoc}`;

/**
 * __useDiscussionPageDiscussionByIdQuery__
 *
 * To run a query within a React component, call `useDiscussionPageDiscussionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionPageDiscussionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionPageDiscussionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscussionPageDiscussionByIdQuery(baseOptions: Apollo.QueryHookOptions<DiscussionPageDiscussionByIdQuery, DiscussionPageDiscussionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscussionPageDiscussionByIdQuery, DiscussionPageDiscussionByIdQueryVariables>(DiscussionPageDiscussionByIdDocument, options);
      }
export function useDiscussionPageDiscussionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscussionPageDiscussionByIdQuery, DiscussionPageDiscussionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscussionPageDiscussionByIdQuery, DiscussionPageDiscussionByIdQueryVariables>(DiscussionPageDiscussionByIdDocument, options);
        }
export type DiscussionPageDiscussionByIdQueryHookResult = ReturnType<typeof useDiscussionPageDiscussionByIdQuery>;
export type DiscussionPageDiscussionByIdLazyQueryHookResult = ReturnType<typeof useDiscussionPageDiscussionByIdLazyQuery>;
export type DiscussionPageDiscussionByIdQueryResult = Apollo.QueryResult<DiscussionPageDiscussionByIdQuery, DiscussionPageDiscussionByIdQueryVariables>;
export const AcceptBidDocument = gql`
    mutation acceptBid($bidId: ID!) {
  acceptBid(bidId: $bidId) {
    bid {
      ...AcceptBidSuccessModalBid
    }
    errors {
      ...FullInputError
    }
  }
}
    ${AcceptBidSuccessModalBidFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type AcceptBidMutationFn = Apollo.MutationFunction<AcceptBidMutation, AcceptBidMutationVariables>;

/**
 * __useAcceptBidMutation__
 *
 * To run a mutation, you first call `useAcceptBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBidMutation, { data, loading, error }] = useAcceptBidMutation({
 *   variables: {
 *      bidId: // value for 'bidId'
 *   },
 * });
 */
export function useAcceptBidMutation(baseOptions?: Apollo.MutationHookOptions<AcceptBidMutation, AcceptBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptBidMutation, AcceptBidMutationVariables>(AcceptBidDocument, options);
      }
export type AcceptBidMutationHookResult = ReturnType<typeof useAcceptBidMutation>;
export type AcceptBidMutationResult = Apollo.MutationResult<AcceptBidMutation>;
export type AcceptBidMutationOptions = Apollo.BaseMutationOptions<AcceptBidMutation, AcceptBidMutationVariables>;
export const AcceptBidModalCompanyByIdDocument = gql`
    query acceptBidModalCompanyById($id: ID!) {
  companyById(id: $id) {
    ...AcceptBidModalCompany
  }
}
    ${AcceptBidModalCompanyFragmentDoc}`;

/**
 * __useAcceptBidModalCompanyByIdQuery__
 *
 * To run a query within a React component, call `useAcceptBidModalCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptBidModalCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceptBidModalCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptBidModalCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<AcceptBidModalCompanyByIdQuery, AcceptBidModalCompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AcceptBidModalCompanyByIdQuery, AcceptBidModalCompanyByIdQueryVariables>(AcceptBidModalCompanyByIdDocument, options);
      }
export function useAcceptBidModalCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AcceptBidModalCompanyByIdQuery, AcceptBidModalCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AcceptBidModalCompanyByIdQuery, AcceptBidModalCompanyByIdQueryVariables>(AcceptBidModalCompanyByIdDocument, options);
        }
export type AcceptBidModalCompanyByIdQueryHookResult = ReturnType<typeof useAcceptBidModalCompanyByIdQuery>;
export type AcceptBidModalCompanyByIdLazyQueryHookResult = ReturnType<typeof useAcceptBidModalCompanyByIdLazyQuery>;
export type AcceptBidModalCompanyByIdQueryResult = Apollo.QueryResult<AcceptBidModalCompanyByIdQuery, AcceptBidModalCompanyByIdQueryVariables>;
export const AcceptCounteredBidDocument = gql`
    mutation acceptCounteredBid($bidId: ID!) {
  acceptCounteredBid(bidId: $bidId) {
    bid {
      ...AcceptBidSuccessModalBid
      ...MakeUrlBid
    }
    errors {
      ...FullInputError
    }
  }
}
    ${AcceptBidSuccessModalBidFragmentDoc}
${MakeUrlBidFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type AcceptCounteredBidMutationFn = Apollo.MutationFunction<AcceptCounteredBidMutation, AcceptCounteredBidMutationVariables>;

/**
 * __useAcceptCounteredBidMutation__
 *
 * To run a mutation, you first call `useAcceptCounteredBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCounteredBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCounteredBidMutation, { data, loading, error }] = useAcceptCounteredBidMutation({
 *   variables: {
 *      bidId: // value for 'bidId'
 *   },
 * });
 */
export function useAcceptCounteredBidMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCounteredBidMutation, AcceptCounteredBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCounteredBidMutation, AcceptCounteredBidMutationVariables>(AcceptCounteredBidDocument, options);
      }
export type AcceptCounteredBidMutationHookResult = ReturnType<typeof useAcceptCounteredBidMutation>;
export type AcceptCounteredBidMutationResult = Apollo.MutationResult<AcceptCounteredBidMutation>;
export type AcceptCounteredBidMutationOptions = Apollo.BaseMutationOptions<AcceptCounteredBidMutation, AcceptCounteredBidMutationVariables>;
export const BrokerSubmitBidSequenceModalBrokerSubmitBidDocument = gql`
    mutation brokerSubmitBidSequenceModalBrokerSubmitBid($input: BrokerSubmitBidInput!) {
  brokerSubmitBid(input: $input) {
    bid {
      id
      listing {
        id
        displayId
        company {
          id
          name
          ...GetSecuritySpecialistContactCompany
        }
      }
    }
    errors {
      ...FullInputError
    }
  }
}
    ${GetSecuritySpecialistContactCompanyFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type BrokerSubmitBidSequenceModalBrokerSubmitBidMutationFn = Apollo.MutationFunction<BrokerSubmitBidSequenceModalBrokerSubmitBidMutation, BrokerSubmitBidSequenceModalBrokerSubmitBidMutationVariables>;

/**
 * __useBrokerSubmitBidSequenceModalBrokerSubmitBidMutation__
 *
 * To run a mutation, you first call `useBrokerSubmitBidSequenceModalBrokerSubmitBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrokerSubmitBidSequenceModalBrokerSubmitBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brokerSubmitBidSequenceModalBrokerSubmitBidMutation, { data, loading, error }] = useBrokerSubmitBidSequenceModalBrokerSubmitBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrokerSubmitBidSequenceModalBrokerSubmitBidMutation(baseOptions?: Apollo.MutationHookOptions<BrokerSubmitBidSequenceModalBrokerSubmitBidMutation, BrokerSubmitBidSequenceModalBrokerSubmitBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BrokerSubmitBidSequenceModalBrokerSubmitBidMutation, BrokerSubmitBidSequenceModalBrokerSubmitBidMutationVariables>(BrokerSubmitBidSequenceModalBrokerSubmitBidDocument, options);
      }
export type BrokerSubmitBidSequenceModalBrokerSubmitBidMutationHookResult = ReturnType<typeof useBrokerSubmitBidSequenceModalBrokerSubmitBidMutation>;
export type BrokerSubmitBidSequenceModalBrokerSubmitBidMutationResult = Apollo.MutationResult<BrokerSubmitBidSequenceModalBrokerSubmitBidMutation>;
export type BrokerSubmitBidSequenceModalBrokerSubmitBidMutationOptions = Apollo.BaseMutationOptions<BrokerSubmitBidSequenceModalBrokerSubmitBidMutation, BrokerSubmitBidSequenceModalBrokerSubmitBidMutationVariables>;
export const ModifyBidDocument = gql`
    mutation modifyBid($input: ModifyBidInput!, $bidId: ID!) {
  modifyBid(input: $input, bidId: $bidId) {
    bid {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type ModifyBidMutationFn = Apollo.MutationFunction<ModifyBidMutation, ModifyBidMutationVariables>;

/**
 * __useModifyBidMutation__
 *
 * To run a mutation, you first call `useModifyBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyBidMutation, { data, loading, error }] = useModifyBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *      bidId: // value for 'bidId'
 *   },
 * });
 */
export function useModifyBidMutation(baseOptions?: Apollo.MutationHookOptions<ModifyBidMutation, ModifyBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyBidMutation, ModifyBidMutationVariables>(ModifyBidDocument, options);
      }
export type ModifyBidMutationHookResult = ReturnType<typeof useModifyBidMutation>;
export type ModifyBidMutationResult = Apollo.MutationResult<ModifyBidMutation>;
export type ModifyBidMutationOptions = Apollo.BaseMutationOptions<ModifyBidMutation, ModifyBidMutationVariables>;
export const ModifyCounterBidDocument = gql`
    mutation modifyCounterBid($bidId: ID!, $input: CounterBidInput!) {
  modifyCounterBid(bidId: $bidId, input: $input) {
    bid {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type ModifyCounterBidMutationFn = Apollo.MutationFunction<ModifyCounterBidMutation, ModifyCounterBidMutationVariables>;

/**
 * __useModifyCounterBidMutation__
 *
 * To run a mutation, you first call `useModifyCounterBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCounterBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCounterBidMutation, { data, loading, error }] = useModifyCounterBidMutation({
 *   variables: {
 *      bidId: // value for 'bidId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyCounterBidMutation(baseOptions?: Apollo.MutationHookOptions<ModifyCounterBidMutation, ModifyCounterBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyCounterBidMutation, ModifyCounterBidMutationVariables>(ModifyCounterBidDocument, options);
      }
export type ModifyCounterBidMutationHookResult = ReturnType<typeof useModifyCounterBidMutation>;
export type ModifyCounterBidMutationResult = Apollo.MutationResult<ModifyCounterBidMutation>;
export type ModifyCounterBidMutationOptions = Apollo.BaseMutationOptions<ModifyCounterBidMutation, ModifyCounterBidMutationVariables>;
export const PlaceBidSequenceModalPlaceBidDocument = gql`
    mutation placeBidSequenceModalPlaceBid($input: PlaceBidInput!) {
  placeBid(input: $input) {
    bid {
      id
      ...PlaceBidSequenceModalPlaceBidSuccessBid
    }
    errors {
      ...FullInputError
    }
  }
}
    ${PlaceBidSequenceModalPlaceBidSuccessBidFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type PlaceBidSequenceModalPlaceBidMutationFn = Apollo.MutationFunction<PlaceBidSequenceModalPlaceBidMutation, PlaceBidSequenceModalPlaceBidMutationVariables>;

/**
 * __usePlaceBidSequenceModalPlaceBidMutation__
 *
 * To run a mutation, you first call `usePlaceBidSequenceModalPlaceBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceBidSequenceModalPlaceBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeBidSequenceModalPlaceBidMutation, { data, loading, error }] = usePlaceBidSequenceModalPlaceBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceBidSequenceModalPlaceBidMutation(baseOptions?: Apollo.MutationHookOptions<PlaceBidSequenceModalPlaceBidMutation, PlaceBidSequenceModalPlaceBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaceBidSequenceModalPlaceBidMutation, PlaceBidSequenceModalPlaceBidMutationVariables>(PlaceBidSequenceModalPlaceBidDocument, options);
      }
export type PlaceBidSequenceModalPlaceBidMutationHookResult = ReturnType<typeof usePlaceBidSequenceModalPlaceBidMutation>;
export type PlaceBidSequenceModalPlaceBidMutationResult = Apollo.MutationResult<PlaceBidSequenceModalPlaceBidMutation>;
export type PlaceBidSequenceModalPlaceBidMutationOptions = Apollo.BaseMutationOptions<PlaceBidSequenceModalPlaceBidMutation, PlaceBidSequenceModalPlaceBidMutationVariables>;
export const CounterBidV2Document = gql`
    mutation counterBidV2($bidId: ID!, $input: CounterBidInput!) {
  counterBidV2(bidId: $bidId, input: $input) {
    bid {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type CounterBidV2MutationFn = Apollo.MutationFunction<CounterBidV2Mutation, CounterBidV2MutationVariables>;

/**
 * __useCounterBidV2Mutation__
 *
 * To run a mutation, you first call `useCounterBidV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCounterBidV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [counterBidV2Mutation, { data, loading, error }] = useCounterBidV2Mutation({
 *   variables: {
 *      bidId: // value for 'bidId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCounterBidV2Mutation(baseOptions?: Apollo.MutationHookOptions<CounterBidV2Mutation, CounterBidV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CounterBidV2Mutation, CounterBidV2MutationVariables>(CounterBidV2Document, options);
      }
export type CounterBidV2MutationHookResult = ReturnType<typeof useCounterBidV2Mutation>;
export type CounterBidV2MutationResult = Apollo.MutationResult<CounterBidV2Mutation>;
export type CounterBidV2MutationOptions = Apollo.BaseMutationOptions<CounterBidV2Mutation, CounterBidV2MutationVariables>;
export const WithdrawCounterBidDocument = gql`
    mutation withdrawCounterBid($bidId: ID!) {
  withdrawCounterBid(bidId: $bidId) {
    bid {
      id
      listing {
        ...MakeUrlListing
      }
    }
    errors {
      ...FullInputError
    }
  }
}
    ${MakeUrlListingFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type WithdrawCounterBidMutationFn = Apollo.MutationFunction<WithdrawCounterBidMutation, WithdrawCounterBidMutationVariables>;

/**
 * __useWithdrawCounterBidMutation__
 *
 * To run a mutation, you first call `useWithdrawCounterBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawCounterBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawCounterBidMutation, { data, loading, error }] = useWithdrawCounterBidMutation({
 *   variables: {
 *      bidId: // value for 'bidId'
 *   },
 * });
 */
export function useWithdrawCounterBidMutation(baseOptions?: Apollo.MutationHookOptions<WithdrawCounterBidMutation, WithdrawCounterBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WithdrawCounterBidMutation, WithdrawCounterBidMutationVariables>(WithdrawCounterBidDocument, options);
      }
export type WithdrawCounterBidMutationHookResult = ReturnType<typeof useWithdrawCounterBidMutation>;
export type WithdrawCounterBidMutationResult = Apollo.MutationResult<WithdrawCounterBidMutation>;
export type WithdrawCounterBidMutationOptions = Apollo.BaseMutationOptions<WithdrawCounterBidMutation, WithdrawCounterBidMutationVariables>;
export const ChangeInstitutionUserRolesDocument = gql`
    mutation changeInstitutionUserRoles($userId: ID!, $roles: [UserRole!]!) {
  changeInstitutionUserRoles(userId: $userId, roles: $roles) {
    user {
      ...BasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type ChangeInstitutionUserRolesMutationFn = Apollo.MutationFunction<ChangeInstitutionUserRolesMutation, ChangeInstitutionUserRolesMutationVariables>;

/**
 * __useChangeInstitutionUserRolesMutation__
 *
 * To run a mutation, you first call `useChangeInstitutionUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeInstitutionUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeInstitutionUserRolesMutation, { data, loading, error }] = useChangeInstitutionUserRolesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useChangeInstitutionUserRolesMutation(baseOptions?: Apollo.MutationHookOptions<ChangeInstitutionUserRolesMutation, ChangeInstitutionUserRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeInstitutionUserRolesMutation, ChangeInstitutionUserRolesMutationVariables>(ChangeInstitutionUserRolesDocument, options);
      }
export type ChangeInstitutionUserRolesMutationHookResult = ReturnType<typeof useChangeInstitutionUserRolesMutation>;
export type ChangeInstitutionUserRolesMutationResult = Apollo.MutationResult<ChangeInstitutionUserRolesMutation>;
export type ChangeInstitutionUserRolesMutationOptions = Apollo.BaseMutationOptions<ChangeInstitutionUserRolesMutation, ChangeInstitutionUserRolesMutationVariables>;
export const CreateListingDocument = gql`
    mutation createListing($input: ListingInput!) {
  createListing(input: $input) {
    listing {
      ...CreateListingSuccessModalListing
    }
    errors {
      ...FullInputError
    }
  }
}
    ${CreateListingSuccessModalListingFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type CreateListingMutationFn = Apollo.MutationFunction<CreateListingMutation, CreateListingMutationVariables>;

/**
 * __useCreateListingMutation__
 *
 * To run a mutation, you first call `useCreateListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListingMutation, { data, loading, error }] = useCreateListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateListingMutation(baseOptions?: Apollo.MutationHookOptions<CreateListingMutation, CreateListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateListingMutation, CreateListingMutationVariables>(CreateListingDocument, options);
      }
export type CreateListingMutationHookResult = ReturnType<typeof useCreateListingMutation>;
export type CreateListingMutationResult = Apollo.MutationResult<CreateListingMutation>;
export type CreateListingMutationOptions = Apollo.BaseMutationOptions<CreateListingMutation, CreateListingMutationVariables>;
export const ModifyListingDocument = gql`
    mutation modifyListing($listingId: ID!, $input: ModifyListingInput!) {
  modifyListing(listingId: $listingId, input: $input) {
    listing {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type ModifyListingMutationFn = Apollo.MutationFunction<ModifyListingMutation, ModifyListingMutationVariables>;

/**
 * __useModifyListingMutation__
 *
 * To run a mutation, you first call `useModifyListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyListingMutation, { data, loading, error }] = useModifyListingMutation({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyListingMutation(baseOptions?: Apollo.MutationHookOptions<ModifyListingMutation, ModifyListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyListingMutation, ModifyListingMutationVariables>(ModifyListingDocument, options);
      }
export type ModifyListingMutationHookResult = ReturnType<typeof useModifyListingMutation>;
export type ModifyListingMutationResult = Apollo.MutationResult<ModifyListingMutation>;
export type ModifyListingMutationOptions = Apollo.BaseMutationOptions<ModifyListingMutation, ModifyListingMutationVariables>;
export const ModifyListingModalCompanyByIdDocument = gql`
    query modifyListingModalCompanyById($id: ID!) {
  companyById(id: $id) {
    ...ModifyListingModalCompany
  }
}
    ${ModifyListingModalCompanyFragmentDoc}`;

/**
 * __useModifyListingModalCompanyByIdQuery__
 *
 * To run a query within a React component, call `useModifyListingModalCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useModifyListingModalCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModifyListingModalCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModifyListingModalCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<ModifyListingModalCompanyByIdQuery, ModifyListingModalCompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModifyListingModalCompanyByIdQuery, ModifyListingModalCompanyByIdQueryVariables>(ModifyListingModalCompanyByIdDocument, options);
      }
export function useModifyListingModalCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModifyListingModalCompanyByIdQuery, ModifyListingModalCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModifyListingModalCompanyByIdQuery, ModifyListingModalCompanyByIdQueryVariables>(ModifyListingModalCompanyByIdDocument, options);
        }
export type ModifyListingModalCompanyByIdQueryHookResult = ReturnType<typeof useModifyListingModalCompanyByIdQuery>;
export type ModifyListingModalCompanyByIdLazyQueryHookResult = ReturnType<typeof useModifyListingModalCompanyByIdLazyQuery>;
export type ModifyListingModalCompanyByIdQueryResult = Apollo.QueryResult<ModifyListingModalCompanyByIdQuery, ModifyListingModalCompanyByIdQueryVariables>;
export const UnaccreditedSellerWithdrawListingDocument = gql`
    mutation unaccreditedSellerWithdrawListing {
  unaccreditedSellerWithdrawListing {
    listing {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type UnaccreditedSellerWithdrawListingMutationFn = Apollo.MutationFunction<UnaccreditedSellerWithdrawListingMutation, UnaccreditedSellerWithdrawListingMutationVariables>;

/**
 * __useUnaccreditedSellerWithdrawListingMutation__
 *
 * To run a mutation, you first call `useUnaccreditedSellerWithdrawListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerWithdrawListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unaccreditedSellerWithdrawListingMutation, { data, loading, error }] = useUnaccreditedSellerWithdrawListingMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerWithdrawListingMutation(baseOptions?: Apollo.MutationHookOptions<UnaccreditedSellerWithdrawListingMutation, UnaccreditedSellerWithdrawListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnaccreditedSellerWithdrawListingMutation, UnaccreditedSellerWithdrawListingMutationVariables>(UnaccreditedSellerWithdrawListingDocument, options);
      }
export type UnaccreditedSellerWithdrawListingMutationHookResult = ReturnType<typeof useUnaccreditedSellerWithdrawListingMutation>;
export type UnaccreditedSellerWithdrawListingMutationResult = Apollo.MutationResult<UnaccreditedSellerWithdrawListingMutation>;
export type UnaccreditedSellerWithdrawListingMutationOptions = Apollo.BaseMutationOptions<UnaccreditedSellerWithdrawListingMutation, UnaccreditedSellerWithdrawListingMutationVariables>;
export const WithdrawListingDocument = gql`
    mutation withdrawListing($listingId: ID!, $input: WithdrawListingInput!) {
  withdrawListing(listingId: $listingId, input: $input) {
    listing {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type WithdrawListingMutationFn = Apollo.MutationFunction<WithdrawListingMutation, WithdrawListingMutationVariables>;

/**
 * __useWithdrawListingMutation__
 *
 * To run a mutation, you first call `useWithdrawListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawListingMutation, { data, loading, error }] = useWithdrawListingMutation({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawListingMutation(baseOptions?: Apollo.MutationHookOptions<WithdrawListingMutation, WithdrawListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WithdrawListingMutation, WithdrawListingMutationVariables>(WithdrawListingDocument, options);
      }
export type WithdrawListingMutationHookResult = ReturnType<typeof useWithdrawListingMutation>;
export type WithdrawListingMutationResult = Apollo.MutationResult<WithdrawListingMutation>;
export type WithdrawListingMutationOptions = Apollo.BaseMutationOptions<WithdrawListingMutation, WithdrawListingMutationVariables>;
export const AcceptStandingBidDocument = gql`
    mutation acceptStandingBid($standingBidId: ID!, $input: AcceptStandingBidInput!) {
  acceptStandingBid(standingBidId: $standingBidId, input: $input) {
    bid {
      ...AcceptStandingBidResponseBid
    }
    errors {
      ...FullInputError
    }
  }
}
    ${AcceptStandingBidResponseBidFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type AcceptStandingBidMutationFn = Apollo.MutationFunction<AcceptStandingBidMutation, AcceptStandingBidMutationVariables>;

/**
 * __useAcceptStandingBidMutation__
 *
 * To run a mutation, you first call `useAcceptStandingBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptStandingBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptStandingBidMutation, { data, loading, error }] = useAcceptStandingBidMutation({
 *   variables: {
 *      standingBidId: // value for 'standingBidId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptStandingBidMutation(baseOptions?: Apollo.MutationHookOptions<AcceptStandingBidMutation, AcceptStandingBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptStandingBidMutation, AcceptStandingBidMutationVariables>(AcceptStandingBidDocument, options);
      }
export type AcceptStandingBidMutationHookResult = ReturnType<typeof useAcceptStandingBidMutation>;
export type AcceptStandingBidMutationResult = Apollo.MutationResult<AcceptStandingBidMutation>;
export type AcceptStandingBidMutationOptions = Apollo.BaseMutationOptions<AcceptStandingBidMutation, AcceptStandingBidMutationVariables>;
export const ModifyStandingBidDocument = gql`
    mutation modifyStandingBid($standingBidId: ID!, $input: ModifyStandingBidInput!) {
  modifyStandingBid(standingBidId: $standingBidId, input: $input) {
    standingBid {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type ModifyStandingBidMutationFn = Apollo.MutationFunction<ModifyStandingBidMutation, ModifyStandingBidMutationVariables>;

/**
 * __useModifyStandingBidMutation__
 *
 * To run a mutation, you first call `useModifyStandingBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyStandingBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyStandingBidMutation, { data, loading, error }] = useModifyStandingBidMutation({
 *   variables: {
 *      standingBidId: // value for 'standingBidId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyStandingBidMutation(baseOptions?: Apollo.MutationHookOptions<ModifyStandingBidMutation, ModifyStandingBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyStandingBidMutation, ModifyStandingBidMutationVariables>(ModifyStandingBidDocument, options);
      }
export type ModifyStandingBidMutationHookResult = ReturnType<typeof useModifyStandingBidMutation>;
export type ModifyStandingBidMutationResult = Apollo.MutationResult<ModifyStandingBidMutation>;
export type ModifyStandingBidMutationOptions = Apollo.BaseMutationOptions<ModifyStandingBidMutation, ModifyStandingBidMutationVariables>;
export const PlaceStandingBidDocument = gql`
    mutation placeStandingBid($input: PlaceStandingBidInput!) {
  placeStandingBid(input: $input) {
    standingBid {
      ...PlaceStandingBidSuccessModalStandingBid
    }
    errors {
      ...FullInputError
    }
  }
}
    ${PlaceStandingBidSuccessModalStandingBidFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type PlaceStandingBidMutationFn = Apollo.MutationFunction<PlaceStandingBidMutation, PlaceStandingBidMutationVariables>;

/**
 * __usePlaceStandingBidMutation__
 *
 * To run a mutation, you first call `usePlaceStandingBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceStandingBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeStandingBidMutation, { data, loading, error }] = usePlaceStandingBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceStandingBidMutation(baseOptions?: Apollo.MutationHookOptions<PlaceStandingBidMutation, PlaceStandingBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaceStandingBidMutation, PlaceStandingBidMutationVariables>(PlaceStandingBidDocument, options);
      }
export type PlaceStandingBidMutationHookResult = ReturnType<typeof usePlaceStandingBidMutation>;
export type PlaceStandingBidMutationResult = Apollo.MutationResult<PlaceStandingBidMutation>;
export type PlaceStandingBidMutationOptions = Apollo.BaseMutationOptions<PlaceStandingBidMutation, PlaceStandingBidMutationVariables>;
export const PlaceStandingBidSelectCompanyInputListCompaniesDocument = gql`
    query placeStandingBidSelectCompanyInputListCompanies($orderBy: ListCompaniesOrderBy!, $first: Int!, $searchText: String) {
  listCompanies(orderBy: $orderBy, first: $first, searchText: $searchText) {
    edges {
      ...PlaceStandingBidSelectCompanyInputCompanyEdge
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${PlaceStandingBidSelectCompanyInputCompanyEdgeFragmentDoc}`;

/**
 * __usePlaceStandingBidSelectCompanyInputListCompaniesQuery__
 *
 * To run a query within a React component, call `usePlaceStandingBidSelectCompanyInputListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceStandingBidSelectCompanyInputListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceStandingBidSelectCompanyInputListCompaniesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function usePlaceStandingBidSelectCompanyInputListCompaniesQuery(baseOptions: Apollo.QueryHookOptions<PlaceStandingBidSelectCompanyInputListCompaniesQuery, PlaceStandingBidSelectCompanyInputListCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaceStandingBidSelectCompanyInputListCompaniesQuery, PlaceStandingBidSelectCompanyInputListCompaniesQueryVariables>(PlaceStandingBidSelectCompanyInputListCompaniesDocument, options);
      }
export function usePlaceStandingBidSelectCompanyInputListCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaceStandingBidSelectCompanyInputListCompaniesQuery, PlaceStandingBidSelectCompanyInputListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaceStandingBidSelectCompanyInputListCompaniesQuery, PlaceStandingBidSelectCompanyInputListCompaniesQueryVariables>(PlaceStandingBidSelectCompanyInputListCompaniesDocument, options);
        }
export type PlaceStandingBidSelectCompanyInputListCompaniesQueryHookResult = ReturnType<typeof usePlaceStandingBidSelectCompanyInputListCompaniesQuery>;
export type PlaceStandingBidSelectCompanyInputListCompaniesLazyQueryHookResult = ReturnType<typeof usePlaceStandingBidSelectCompanyInputListCompaniesLazyQuery>;
export type PlaceStandingBidSelectCompanyInputListCompaniesQueryResult = Apollo.QueryResult<PlaceStandingBidSelectCompanyInputListCompaniesQuery, PlaceStandingBidSelectCompanyInputListCompaniesQueryVariables>;
export const UnaccreditedSellerAcceptStandingBidV2Document = gql`
    mutation unaccreditedSellerAcceptStandingBidV2($standingBidId: ID!, $input: UnaccreditedSellerAcceptStandingBidInputV2!) {
  unaccreditedSellerAcceptStandingBidV2(
    standingBidId: $standingBidId
    input: $input
  ) {
    bid {
      ...AcceptStandingBidResponseBid
    }
    errors {
      ...FullInputError
    }
  }
}
    ${AcceptStandingBidResponseBidFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type UnaccreditedSellerAcceptStandingBidV2MutationFn = Apollo.MutationFunction<UnaccreditedSellerAcceptStandingBidV2Mutation, UnaccreditedSellerAcceptStandingBidV2MutationVariables>;

/**
 * __useUnaccreditedSellerAcceptStandingBidV2Mutation__
 *
 * To run a mutation, you first call `useUnaccreditedSellerAcceptStandingBidV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerAcceptStandingBidV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unaccreditedSellerAcceptStandingBidV2Mutation, { data, loading, error }] = useUnaccreditedSellerAcceptStandingBidV2Mutation({
 *   variables: {
 *      standingBidId: // value for 'standingBidId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnaccreditedSellerAcceptStandingBidV2Mutation(baseOptions?: Apollo.MutationHookOptions<UnaccreditedSellerAcceptStandingBidV2Mutation, UnaccreditedSellerAcceptStandingBidV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnaccreditedSellerAcceptStandingBidV2Mutation, UnaccreditedSellerAcceptStandingBidV2MutationVariables>(UnaccreditedSellerAcceptStandingBidV2Document, options);
      }
export type UnaccreditedSellerAcceptStandingBidV2MutationHookResult = ReturnType<typeof useUnaccreditedSellerAcceptStandingBidV2Mutation>;
export type UnaccreditedSellerAcceptStandingBidV2MutationResult = Apollo.MutationResult<UnaccreditedSellerAcceptStandingBidV2Mutation>;
export type UnaccreditedSellerAcceptStandingBidV2MutationOptions = Apollo.BaseMutationOptions<UnaccreditedSellerAcceptStandingBidV2Mutation, UnaccreditedSellerAcceptStandingBidV2MutationVariables>;
export const WithdrawStandingBidDocument = gql`
    mutation withdrawStandingBid($standingBidId: ID!, $input: WithdrawStandingBidInput!) {
  withdrawStandingBid(standingBidId: $standingBidId, input: $input) {
    standingBid {
      id
      company {
        id
      }
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type WithdrawStandingBidMutationFn = Apollo.MutationFunction<WithdrawStandingBidMutation, WithdrawStandingBidMutationVariables>;

/**
 * __useWithdrawStandingBidMutation__
 *
 * To run a mutation, you first call `useWithdrawStandingBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawStandingBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawStandingBidMutation, { data, loading, error }] = useWithdrawStandingBidMutation({
 *   variables: {
 *      standingBidId: // value for 'standingBidId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawStandingBidMutation(baseOptions?: Apollo.MutationHookOptions<WithdrawStandingBidMutation, WithdrawStandingBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WithdrawStandingBidMutation, WithdrawStandingBidMutationVariables>(WithdrawStandingBidDocument, options);
      }
export type WithdrawStandingBidMutationHookResult = ReturnType<typeof useWithdrawStandingBidMutation>;
export type WithdrawStandingBidMutationResult = Apollo.MutationResult<WithdrawStandingBidMutation>;
export type WithdrawStandingBidMutationOptions = Apollo.BaseMutationOptions<WithdrawStandingBidMutation, WithdrawStandingBidMutationVariables>;
export const InviteAlternateSignerDocument = gql`
    mutation inviteAlternateSigner($input: InviteAlternateSignerInput!) {
  inviteAlternateSigner(input: $input) {
    alternateSigner {
      ...BasicAlternateSigner
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicAlternateSignerFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type InviteAlternateSignerMutationFn = Apollo.MutationFunction<InviteAlternateSignerMutation, InviteAlternateSignerMutationVariables>;

/**
 * __useInviteAlternateSignerMutation__
 *
 * To run a mutation, you first call `useInviteAlternateSignerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteAlternateSignerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteAlternateSignerMutation, { data, loading, error }] = useInviteAlternateSignerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteAlternateSignerMutation(baseOptions?: Apollo.MutationHookOptions<InviteAlternateSignerMutation, InviteAlternateSignerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteAlternateSignerMutation, InviteAlternateSignerMutationVariables>(InviteAlternateSignerDocument, options);
      }
export type InviteAlternateSignerMutationHookResult = ReturnType<typeof useInviteAlternateSignerMutation>;
export type InviteAlternateSignerMutationResult = Apollo.MutationResult<InviteAlternateSignerMutation>;
export type InviteAlternateSignerMutationOptions = Apollo.BaseMutationOptions<InviteAlternateSignerMutation, InviteAlternateSignerMutationVariables>;
export const RevokeAlternateSignerInvitationModalDocument = gql`
    mutation revokeAlternateSignerInvitationModal($documentId: ID!) {
  revokeAlternateSignerInvitation(documentId: $documentId) {
    alternateSigner {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type RevokeAlternateSignerInvitationModalMutationFn = Apollo.MutationFunction<RevokeAlternateSignerInvitationModalMutation, RevokeAlternateSignerInvitationModalMutationVariables>;

/**
 * __useRevokeAlternateSignerInvitationModalMutation__
 *
 * To run a mutation, you first call `useRevokeAlternateSignerInvitationModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAlternateSignerInvitationModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAlternateSignerInvitationModalMutation, { data, loading, error }] = useRevokeAlternateSignerInvitationModalMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useRevokeAlternateSignerInvitationModalMutation(baseOptions?: Apollo.MutationHookOptions<RevokeAlternateSignerInvitationModalMutation, RevokeAlternateSignerInvitationModalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeAlternateSignerInvitationModalMutation, RevokeAlternateSignerInvitationModalMutationVariables>(RevokeAlternateSignerInvitationModalDocument, options);
      }
export type RevokeAlternateSignerInvitationModalMutationHookResult = ReturnType<typeof useRevokeAlternateSignerInvitationModalMutation>;
export type RevokeAlternateSignerInvitationModalMutationResult = Apollo.MutationResult<RevokeAlternateSignerInvitationModalMutation>;
export type RevokeAlternateSignerInvitationModalMutationOptions = Apollo.BaseMutationOptions<RevokeAlternateSignerInvitationModalMutation, RevokeAlternateSignerInvitationModalMutationVariables>;
export const CompaniesSearchListCompaniesDocument = gql`
    query companiesSearchListCompanies($orderBy: ListCompaniesOrderBy!, $first: Int!, $searchText: String, $statuses: [CompanyStatus!]) {
  listCompanies(
    orderBy: $orderBy
    first: $first
    searchText: $searchText
    statuses: $statuses
  ) {
    edges {
      ...CompaniesSearchCompanyEdge
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${CompaniesSearchCompanyEdgeFragmentDoc}`;

/**
 * __useCompaniesSearchListCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesSearchListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesSearchListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesSearchListCompaniesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      searchText: // value for 'searchText'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useCompaniesSearchListCompaniesQuery(baseOptions: Apollo.QueryHookOptions<CompaniesSearchListCompaniesQuery, CompaniesSearchListCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesSearchListCompaniesQuery, CompaniesSearchListCompaniesQueryVariables>(CompaniesSearchListCompaniesDocument, options);
      }
export function useCompaniesSearchListCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesSearchListCompaniesQuery, CompaniesSearchListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesSearchListCompaniesQuery, CompaniesSearchListCompaniesQueryVariables>(CompaniesSearchListCompaniesDocument, options);
        }
export type CompaniesSearchListCompaniesQueryHookResult = ReturnType<typeof useCompaniesSearchListCompaniesQuery>;
export type CompaniesSearchListCompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesSearchListCompaniesLazyQuery>;
export type CompaniesSearchListCompaniesQueryResult = Apollo.QueryResult<CompaniesSearchListCompaniesQuery, CompaniesSearchListCompaniesQueryVariables>;
export const UnaccreditedSellerNavbarMyActivityDocument = gql`
    query unaccreditedSellerNavbarMyActivity {
  unaccreditedSellerMyActivity {
    myCompany {
      id
      logoUrl
      name
    }
    myListing {
      id
    }
    pendingSales {
      id
    }
    completedSales {
      id
    }
  }
  unaccreditedSellerMyCompanyActivity {
    standingBidsWithDiscussions {
      id
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerNavbarMyActivityQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerNavbarMyActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerNavbarMyActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerNavbarMyActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerNavbarMyActivityQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerNavbarMyActivityQuery, UnaccreditedSellerNavbarMyActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerNavbarMyActivityQuery, UnaccreditedSellerNavbarMyActivityQueryVariables>(UnaccreditedSellerNavbarMyActivityDocument, options);
      }
export function useUnaccreditedSellerNavbarMyActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerNavbarMyActivityQuery, UnaccreditedSellerNavbarMyActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerNavbarMyActivityQuery, UnaccreditedSellerNavbarMyActivityQueryVariables>(UnaccreditedSellerNavbarMyActivityDocument, options);
        }
export type UnaccreditedSellerNavbarMyActivityQueryHookResult = ReturnType<typeof useUnaccreditedSellerNavbarMyActivityQuery>;
export type UnaccreditedSellerNavbarMyActivityLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerNavbarMyActivityLazyQuery>;
export type UnaccreditedSellerNavbarMyActivityQueryResult = Apollo.QueryResult<UnaccreditedSellerNavbarMyActivityQuery, UnaccreditedSellerNavbarMyActivityQueryVariables>;
export const UnaccreditedSellerNavbarByIdActivityDocument = gql`
    query unaccreditedSellerNavbarByIdActivity($id: ID!) {
  companyById(id: $id) {
    ...UnaccreditedSellerNavbarByIdActivityCompany
  }
}
    ${UnaccreditedSellerNavbarByIdActivityCompanyFragmentDoc}`;

/**
 * __useUnaccreditedSellerNavbarByIdActivityQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerNavbarByIdActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerNavbarByIdActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerNavbarByIdActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerNavbarByIdActivityQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerNavbarByIdActivityQuery, UnaccreditedSellerNavbarByIdActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerNavbarByIdActivityQuery, UnaccreditedSellerNavbarByIdActivityQueryVariables>(UnaccreditedSellerNavbarByIdActivityDocument, options);
      }
export function useUnaccreditedSellerNavbarByIdActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerNavbarByIdActivityQuery, UnaccreditedSellerNavbarByIdActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerNavbarByIdActivityQuery, UnaccreditedSellerNavbarByIdActivityQueryVariables>(UnaccreditedSellerNavbarByIdActivityDocument, options);
        }
export type UnaccreditedSellerNavbarByIdActivityQueryHookResult = ReturnType<typeof useUnaccreditedSellerNavbarByIdActivityQuery>;
export type UnaccreditedSellerNavbarByIdActivityLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerNavbarByIdActivityLazyQuery>;
export type UnaccreditedSellerNavbarByIdActivityQueryResult = Apollo.QueryResult<UnaccreditedSellerNavbarByIdActivityQuery, UnaccreditedSellerNavbarByIdActivityQueryVariables>;
export const BrokerInfoPageUpsertBrokerInfoDocument = gql`
    mutation brokerInfoPageUpsertBrokerInfo($input: AddBrokerInfoInput!) {
  upsertBrokerInfo(input: $input) {
    brokerInfo {
      ...BrokerInfoPageBrokerInfo
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BrokerInfoPageBrokerInfoFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type BrokerInfoPageUpsertBrokerInfoMutationFn = Apollo.MutationFunction<BrokerInfoPageUpsertBrokerInfoMutation, BrokerInfoPageUpsertBrokerInfoMutationVariables>;

/**
 * __useBrokerInfoPageUpsertBrokerInfoMutation__
 *
 * To run a mutation, you first call `useBrokerInfoPageUpsertBrokerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrokerInfoPageUpsertBrokerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brokerInfoPageUpsertBrokerInfoMutation, { data, loading, error }] = useBrokerInfoPageUpsertBrokerInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrokerInfoPageUpsertBrokerInfoMutation(baseOptions?: Apollo.MutationHookOptions<BrokerInfoPageUpsertBrokerInfoMutation, BrokerInfoPageUpsertBrokerInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BrokerInfoPageUpsertBrokerInfoMutation, BrokerInfoPageUpsertBrokerInfoMutationVariables>(BrokerInfoPageUpsertBrokerInfoDocument, options);
      }
export type BrokerInfoPageUpsertBrokerInfoMutationHookResult = ReturnType<typeof useBrokerInfoPageUpsertBrokerInfoMutation>;
export type BrokerInfoPageUpsertBrokerInfoMutationResult = Apollo.MutationResult<BrokerInfoPageUpsertBrokerInfoMutation>;
export type BrokerInfoPageUpsertBrokerInfoMutationOptions = Apollo.BaseMutationOptions<BrokerInfoPageUpsertBrokerInfoMutation, BrokerInfoPageUpsertBrokerInfoMutationVariables>;
export const BrokerInfoPageCurrentActorDocument = gql`
    query brokerInfoPageCurrentActor {
  currentActor {
    ...BrokerInfoPageUser
  }
}
    ${BrokerInfoPageUserFragmentDoc}`;

/**
 * __useBrokerInfoPageCurrentActorQuery__
 *
 * To run a query within a React component, call `useBrokerInfoPageCurrentActorQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokerInfoPageCurrentActorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokerInfoPageCurrentActorQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrokerInfoPageCurrentActorQuery(baseOptions?: Apollo.QueryHookOptions<BrokerInfoPageCurrentActorQuery, BrokerInfoPageCurrentActorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokerInfoPageCurrentActorQuery, BrokerInfoPageCurrentActorQueryVariables>(BrokerInfoPageCurrentActorDocument, options);
      }
export function useBrokerInfoPageCurrentActorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokerInfoPageCurrentActorQuery, BrokerInfoPageCurrentActorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokerInfoPageCurrentActorQuery, BrokerInfoPageCurrentActorQueryVariables>(BrokerInfoPageCurrentActorDocument, options);
        }
export type BrokerInfoPageCurrentActorQueryHookResult = ReturnType<typeof useBrokerInfoPageCurrentActorQuery>;
export type BrokerInfoPageCurrentActorLazyQueryHookResult = ReturnType<typeof useBrokerInfoPageCurrentActorLazyQuery>;
export type BrokerInfoPageCurrentActorQueryResult = Apollo.QueryResult<BrokerInfoPageCurrentActorQuery, BrokerInfoPageCurrentActorQueryVariables>;
export const BrokerIntermediaryInfoPageUpsertBrokerInfoDocument = gql`
    mutation brokerIntermediaryInfoPageUpsertBrokerInfo($input: AddBrokerIntermediaryInfoInput!) {
  upsertBrokerIntermediaryInfo(input: $input) {
    brokerInfo {
      ...BrokerIntermediaryInfoPageBrokerInfo
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BrokerIntermediaryInfoPageBrokerInfoFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type BrokerIntermediaryInfoPageUpsertBrokerInfoMutationFn = Apollo.MutationFunction<BrokerIntermediaryInfoPageUpsertBrokerInfoMutation, BrokerIntermediaryInfoPageUpsertBrokerInfoMutationVariables>;

/**
 * __useBrokerIntermediaryInfoPageUpsertBrokerInfoMutation__
 *
 * To run a mutation, you first call `useBrokerIntermediaryInfoPageUpsertBrokerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrokerIntermediaryInfoPageUpsertBrokerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brokerIntermediaryInfoPageUpsertBrokerInfoMutation, { data, loading, error }] = useBrokerIntermediaryInfoPageUpsertBrokerInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrokerIntermediaryInfoPageUpsertBrokerInfoMutation(baseOptions?: Apollo.MutationHookOptions<BrokerIntermediaryInfoPageUpsertBrokerInfoMutation, BrokerIntermediaryInfoPageUpsertBrokerInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BrokerIntermediaryInfoPageUpsertBrokerInfoMutation, BrokerIntermediaryInfoPageUpsertBrokerInfoMutationVariables>(BrokerIntermediaryInfoPageUpsertBrokerInfoDocument, options);
      }
export type BrokerIntermediaryInfoPageUpsertBrokerInfoMutationHookResult = ReturnType<typeof useBrokerIntermediaryInfoPageUpsertBrokerInfoMutation>;
export type BrokerIntermediaryInfoPageUpsertBrokerInfoMutationResult = Apollo.MutationResult<BrokerIntermediaryInfoPageUpsertBrokerInfoMutation>;
export type BrokerIntermediaryInfoPageUpsertBrokerInfoMutationOptions = Apollo.BaseMutationOptions<BrokerIntermediaryInfoPageUpsertBrokerInfoMutation, BrokerIntermediaryInfoPageUpsertBrokerInfoMutationVariables>;
export const BrokerIntermediaryInfoPageCurrentActorDocument = gql`
    query brokerIntermediaryInfoPageCurrentActor {
  currentActor {
    ...BrokerIntermediaryInfoPageUser
  }
}
    ${BrokerIntermediaryInfoPageUserFragmentDoc}`;

/**
 * __useBrokerIntermediaryInfoPageCurrentActorQuery__
 *
 * To run a query within a React component, call `useBrokerIntermediaryInfoPageCurrentActorQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokerIntermediaryInfoPageCurrentActorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokerIntermediaryInfoPageCurrentActorQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrokerIntermediaryInfoPageCurrentActorQuery(baseOptions?: Apollo.QueryHookOptions<BrokerIntermediaryInfoPageCurrentActorQuery, BrokerIntermediaryInfoPageCurrentActorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokerIntermediaryInfoPageCurrentActorQuery, BrokerIntermediaryInfoPageCurrentActorQueryVariables>(BrokerIntermediaryInfoPageCurrentActorDocument, options);
      }
export function useBrokerIntermediaryInfoPageCurrentActorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokerIntermediaryInfoPageCurrentActorQuery, BrokerIntermediaryInfoPageCurrentActorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokerIntermediaryInfoPageCurrentActorQuery, BrokerIntermediaryInfoPageCurrentActorQueryVariables>(BrokerIntermediaryInfoPageCurrentActorDocument, options);
        }
export type BrokerIntermediaryInfoPageCurrentActorQueryHookResult = ReturnType<typeof useBrokerIntermediaryInfoPageCurrentActorQuery>;
export type BrokerIntermediaryInfoPageCurrentActorLazyQueryHookResult = ReturnType<typeof useBrokerIntermediaryInfoPageCurrentActorLazyQuery>;
export type BrokerIntermediaryInfoPageCurrentActorQueryResult = Apollo.QueryResult<BrokerIntermediaryInfoPageCurrentActorQuery, BrokerIntermediaryInfoPageCurrentActorQueryVariables>;
export const HoldingDetailsComboboxListCompaniesDocument = gql`
    query holdingDetailsComboboxListCompanies($orderBy: ListCompaniesOrderBy!, $first: Int!, $searchText: String) {
  listCompanies(orderBy: $orderBy, first: $first, searchText: $searchText) {
    edges {
      ...HoldingDetailsPageCompanyEdge
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${HoldingDetailsPageCompanyEdgeFragmentDoc}`;

/**
 * __useHoldingDetailsComboboxListCompaniesQuery__
 *
 * To run a query within a React component, call `useHoldingDetailsComboboxListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHoldingDetailsComboboxListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHoldingDetailsComboboxListCompaniesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useHoldingDetailsComboboxListCompaniesQuery(baseOptions: Apollo.QueryHookOptions<HoldingDetailsComboboxListCompaniesQuery, HoldingDetailsComboboxListCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HoldingDetailsComboboxListCompaniesQuery, HoldingDetailsComboboxListCompaniesQueryVariables>(HoldingDetailsComboboxListCompaniesDocument, options);
      }
export function useHoldingDetailsComboboxListCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HoldingDetailsComboboxListCompaniesQuery, HoldingDetailsComboboxListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HoldingDetailsComboboxListCompaniesQuery, HoldingDetailsComboboxListCompaniesQueryVariables>(HoldingDetailsComboboxListCompaniesDocument, options);
        }
export type HoldingDetailsComboboxListCompaniesQueryHookResult = ReturnType<typeof useHoldingDetailsComboboxListCompaniesQuery>;
export type HoldingDetailsComboboxListCompaniesLazyQueryHookResult = ReturnType<typeof useHoldingDetailsComboboxListCompaniesLazyQuery>;
export type HoldingDetailsComboboxListCompaniesQueryResult = Apollo.QueryResult<HoldingDetailsComboboxListCompaniesQuery, HoldingDetailsComboboxListCompaniesQueryVariables>;
export const HoldingDetailsPageMyHoldingsDocument = gql`
    query holdingDetailsPageMyHoldings {
  myHoldings {
    holdingsV2 {
      ...HoldingDetailsPageHolding
      ...HoldingDetailsPageUnlistedHolding
    }
  }
}
    ${HoldingDetailsPageHoldingFragmentDoc}
${HoldingDetailsPageUnlistedHoldingFragmentDoc}`;

/**
 * __useHoldingDetailsPageMyHoldingsQuery__
 *
 * To run a query within a React component, call `useHoldingDetailsPageMyHoldingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHoldingDetailsPageMyHoldingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHoldingDetailsPageMyHoldingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHoldingDetailsPageMyHoldingsQuery(baseOptions?: Apollo.QueryHookOptions<HoldingDetailsPageMyHoldingsQuery, HoldingDetailsPageMyHoldingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HoldingDetailsPageMyHoldingsQuery, HoldingDetailsPageMyHoldingsQueryVariables>(HoldingDetailsPageMyHoldingsDocument, options);
      }
export function useHoldingDetailsPageMyHoldingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HoldingDetailsPageMyHoldingsQuery, HoldingDetailsPageMyHoldingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HoldingDetailsPageMyHoldingsQuery, HoldingDetailsPageMyHoldingsQueryVariables>(HoldingDetailsPageMyHoldingsDocument, options);
        }
export type HoldingDetailsPageMyHoldingsQueryHookResult = ReturnType<typeof useHoldingDetailsPageMyHoldingsQuery>;
export type HoldingDetailsPageMyHoldingsLazyQueryHookResult = ReturnType<typeof useHoldingDetailsPageMyHoldingsLazyQuery>;
export type HoldingDetailsPageMyHoldingsQueryResult = Apollo.QueryResult<HoldingDetailsPageMyHoldingsQuery, HoldingDetailsPageMyHoldingsQueryVariables>;
export const HoldingDetailsPageSetHoldingsDocument = gql`
    mutation holdingDetailsPageSetHoldings($addHoldingsInput: AddHoldingsV2Input!) {
  setHoldings(input: $addHoldingsInput) {
    holdingsV2 {
      ... on Holding {
        companyId
        id
        numSharesV2
        sellerId
      }
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type HoldingDetailsPageSetHoldingsMutationFn = Apollo.MutationFunction<HoldingDetailsPageSetHoldingsMutation, HoldingDetailsPageSetHoldingsMutationVariables>;

/**
 * __useHoldingDetailsPageSetHoldingsMutation__
 *
 * To run a mutation, you first call `useHoldingDetailsPageSetHoldingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHoldingDetailsPageSetHoldingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [holdingDetailsPageSetHoldingsMutation, { data, loading, error }] = useHoldingDetailsPageSetHoldingsMutation({
 *   variables: {
 *      addHoldingsInput: // value for 'addHoldingsInput'
 *   },
 * });
 */
export function useHoldingDetailsPageSetHoldingsMutation(baseOptions?: Apollo.MutationHookOptions<HoldingDetailsPageSetHoldingsMutation, HoldingDetailsPageSetHoldingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HoldingDetailsPageSetHoldingsMutation, HoldingDetailsPageSetHoldingsMutationVariables>(HoldingDetailsPageSetHoldingsDocument, options);
      }
export type HoldingDetailsPageSetHoldingsMutationHookResult = ReturnType<typeof useHoldingDetailsPageSetHoldingsMutation>;
export type HoldingDetailsPageSetHoldingsMutationResult = Apollo.MutationResult<HoldingDetailsPageSetHoldingsMutation>;
export type HoldingDetailsPageSetHoldingsMutationOptions = Apollo.BaseMutationOptions<HoldingDetailsPageSetHoldingsMutation, HoldingDetailsPageSetHoldingsMutationVariables>;
export const InstitutionInfoPageDocument = gql`
    mutation institutionInfoPage($upsertInstitutionInput: UpsertInstitutionInput!, $updateUserInput: UpdateUserInput!) {
  upsertInstitution(input: $upsertInstitutionInput) {
    errors {
      ...FullInputError
    }
  }
  updateUser(input: $updateUserInput) {
    user {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type InstitutionInfoPageMutationFn = Apollo.MutationFunction<InstitutionInfoPageMutation, InstitutionInfoPageMutationVariables>;

/**
 * __useInstitutionInfoPageMutation__
 *
 * To run a mutation, you first call `useInstitutionInfoPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionInfoPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionInfoPageMutation, { data, loading, error }] = useInstitutionInfoPageMutation({
 *   variables: {
 *      upsertInstitutionInput: // value for 'upsertInstitutionInput'
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useInstitutionInfoPageMutation(baseOptions?: Apollo.MutationHookOptions<InstitutionInfoPageMutation, InstitutionInfoPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InstitutionInfoPageMutation, InstitutionInfoPageMutationVariables>(InstitutionInfoPageDocument, options);
      }
export type InstitutionInfoPageMutationHookResult = ReturnType<typeof useInstitutionInfoPageMutation>;
export type InstitutionInfoPageMutationResult = Apollo.MutationResult<InstitutionInfoPageMutation>;
export type InstitutionInfoPageMutationOptions = Apollo.BaseMutationOptions<InstitutionInfoPageMutation, InstitutionInfoPageMutationVariables>;
export const InvestingGoalsDocument = gql`
    query investingGoals {
  investingGoalQuestionGroup {
    id
    questions {
      id
      text
      options {
        text
        id
      }
    }
  }
}
    `;

/**
 * __useInvestingGoalsQuery__
 *
 * To run a query within a React component, call `useInvestingGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestingGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestingGoalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestingGoalsQuery(baseOptions?: Apollo.QueryHookOptions<InvestingGoalsQuery, InvestingGoalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestingGoalsQuery, InvestingGoalsQueryVariables>(InvestingGoalsDocument, options);
      }
export function useInvestingGoalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestingGoalsQuery, InvestingGoalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestingGoalsQuery, InvestingGoalsQueryVariables>(InvestingGoalsDocument, options);
        }
export type InvestingGoalsQueryHookResult = ReturnType<typeof useInvestingGoalsQuery>;
export type InvestingGoalsLazyQueryHookResult = ReturnType<typeof useInvestingGoalsLazyQuery>;
export type InvestingGoalsQueryResult = Apollo.QueryResult<InvestingGoalsQuery, InvestingGoalsQueryVariables>;
export const AnswerInvestingGoalsDocument = gql`
    mutation answerInvestingGoals($input: InvestingGoalAnswerInput!) {
  createInvestingGoalAnswers(input: $input) {
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type AnswerInvestingGoalsMutationFn = Apollo.MutationFunction<AnswerInvestingGoalsMutation, AnswerInvestingGoalsMutationVariables>;

/**
 * __useAnswerInvestingGoalsMutation__
 *
 * To run a mutation, you first call `useAnswerInvestingGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerInvestingGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerInvestingGoalsMutation, { data, loading, error }] = useAnswerInvestingGoalsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnswerInvestingGoalsMutation(baseOptions?: Apollo.MutationHookOptions<AnswerInvestingGoalsMutation, AnswerInvestingGoalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnswerInvestingGoalsMutation, AnswerInvestingGoalsMutationVariables>(AnswerInvestingGoalsDocument, options);
      }
export type AnswerInvestingGoalsMutationHookResult = ReturnType<typeof useAnswerInvestingGoalsMutation>;
export type AnswerInvestingGoalsMutationResult = Apollo.MutationResult<AnswerInvestingGoalsMutation>;
export type AnswerInvestingGoalsMutationOptions = Apollo.BaseMutationOptions<AnswerInvestingGoalsMutation, AnswerInvestingGoalsMutationVariables>;
export const UpdateInstitutionAccreditationAnswerDocument = gql`
    mutation updateInstitutionAccreditationAnswer($input: UpdateAccreditationAnswerInput!) {
  updateInstitutionAccreditationAnswer(input: $input) {
    errors {
      field
      message
    }
    institution {
      id
    }
  }
}
    `;
export type UpdateInstitutionAccreditationAnswerMutationFn = Apollo.MutationFunction<UpdateInstitutionAccreditationAnswerMutation, UpdateInstitutionAccreditationAnswerMutationVariables>;

/**
 * __useUpdateInstitutionAccreditationAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateInstitutionAccreditationAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstitutionAccreditationAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstitutionAccreditationAnswerMutation, { data, loading, error }] = useUpdateInstitutionAccreditationAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInstitutionAccreditationAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstitutionAccreditationAnswerMutation, UpdateInstitutionAccreditationAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstitutionAccreditationAnswerMutation, UpdateInstitutionAccreditationAnswerMutationVariables>(UpdateInstitutionAccreditationAnswerDocument, options);
      }
export type UpdateInstitutionAccreditationAnswerMutationHookResult = ReturnType<typeof useUpdateInstitutionAccreditationAnswerMutation>;
export type UpdateInstitutionAccreditationAnswerMutationResult = Apollo.MutationResult<UpdateInstitutionAccreditationAnswerMutation>;
export type UpdateInstitutionAccreditationAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateInstitutionAccreditationAnswerMutation, UpdateInstitutionAccreditationAnswerMutationVariables>;
export const CurrentAccreditationQuestionGroupDocument = gql`
    query currentAccreditationQuestionGroup($countryId: ID!, $investorType: InvestorStatus!) {
  currentAccreditationQuestionGroup(
    countryId: $countryId
    investorType: $investorType
  ) {
    id
    country
    investorType
    questions {
      id
      accredited
      key
      order
      text
    }
  }
}
    `;

/**
 * __useCurrentAccreditationQuestionGroupQuery__
 *
 * To run a query within a React component, call `useCurrentAccreditationQuestionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAccreditationQuestionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAccreditationQuestionGroupQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      investorType: // value for 'investorType'
 *   },
 * });
 */
export function useCurrentAccreditationQuestionGroupQuery(baseOptions: Apollo.QueryHookOptions<CurrentAccreditationQuestionGroupQuery, CurrentAccreditationQuestionGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentAccreditationQuestionGroupQuery, CurrentAccreditationQuestionGroupQueryVariables>(CurrentAccreditationQuestionGroupDocument, options);
      }
export function useCurrentAccreditationQuestionGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAccreditationQuestionGroupQuery, CurrentAccreditationQuestionGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentAccreditationQuestionGroupQuery, CurrentAccreditationQuestionGroupQueryVariables>(CurrentAccreditationQuestionGroupDocument, options);
        }
export type CurrentAccreditationQuestionGroupQueryHookResult = ReturnType<typeof useCurrentAccreditationQuestionGroupQuery>;
export type CurrentAccreditationQuestionGroupLazyQueryHookResult = ReturnType<typeof useCurrentAccreditationQuestionGroupLazyQuery>;
export type CurrentAccreditationQuestionGroupQueryResult = Apollo.QueryResult<CurrentAccreditationQuestionGroupQuery, CurrentAccreditationQuestionGroupQueryVariables>;
export const BrokerResidencePageUpdateBrokerResidenceDocument = gql`
    mutation brokerResidencePageUpdateBrokerResidence($input: UpdateBrokerResidenceInput!) {
  updateBrokerResidence(input: $input) {
    user {
      id
      finraRegistered
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type BrokerResidencePageUpdateBrokerResidenceMutationFn = Apollo.MutationFunction<BrokerResidencePageUpdateBrokerResidenceMutation, BrokerResidencePageUpdateBrokerResidenceMutationVariables>;

/**
 * __useBrokerResidencePageUpdateBrokerResidenceMutation__
 *
 * To run a mutation, you first call `useBrokerResidencePageUpdateBrokerResidenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrokerResidencePageUpdateBrokerResidenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brokerResidencePageUpdateBrokerResidenceMutation, { data, loading, error }] = useBrokerResidencePageUpdateBrokerResidenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrokerResidencePageUpdateBrokerResidenceMutation(baseOptions?: Apollo.MutationHookOptions<BrokerResidencePageUpdateBrokerResidenceMutation, BrokerResidencePageUpdateBrokerResidenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BrokerResidencePageUpdateBrokerResidenceMutation, BrokerResidencePageUpdateBrokerResidenceMutationVariables>(BrokerResidencePageUpdateBrokerResidenceDocument, options);
      }
export type BrokerResidencePageUpdateBrokerResidenceMutationHookResult = ReturnType<typeof useBrokerResidencePageUpdateBrokerResidenceMutation>;
export type BrokerResidencePageUpdateBrokerResidenceMutationResult = Apollo.MutationResult<BrokerResidencePageUpdateBrokerResidenceMutation>;
export type BrokerResidencePageUpdateBrokerResidenceMutationOptions = Apollo.BaseMutationOptions<BrokerResidencePageUpdateBrokerResidenceMutation, BrokerResidencePageUpdateBrokerResidenceMutationVariables>;
export const WatchlistPageActorWatchlistDocument = gql`
    query watchlistPageActorWatchlist {
  currentActor {
    id
    watchlist {
      id
      ...WatchlistPageWatchlistCompany
    }
  }
}
    ${WatchlistPageWatchlistCompanyFragmentDoc}`;

/**
 * __useWatchlistPageActorWatchlistQuery__
 *
 * To run a query within a React component, call `useWatchlistPageActorWatchlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchlistPageActorWatchlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchlistPageActorWatchlistQuery({
 *   variables: {
 *   },
 * });
 */
export function useWatchlistPageActorWatchlistQuery(baseOptions?: Apollo.QueryHookOptions<WatchlistPageActorWatchlistQuery, WatchlistPageActorWatchlistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WatchlistPageActorWatchlistQuery, WatchlistPageActorWatchlistQueryVariables>(WatchlistPageActorWatchlistDocument, options);
      }
export function useWatchlistPageActorWatchlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WatchlistPageActorWatchlistQuery, WatchlistPageActorWatchlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WatchlistPageActorWatchlistQuery, WatchlistPageActorWatchlistQueryVariables>(WatchlistPageActorWatchlistDocument, options);
        }
export type WatchlistPageActorWatchlistQueryHookResult = ReturnType<typeof useWatchlistPageActorWatchlistQuery>;
export type WatchlistPageActorWatchlistLazyQueryHookResult = ReturnType<typeof useWatchlistPageActorWatchlistLazyQuery>;
export type WatchlistPageActorWatchlistQueryResult = Apollo.QueryResult<WatchlistPageActorWatchlistQuery, WatchlistPageActorWatchlistQueryVariables>;
export const WatchlistPageAddToWatchlistDocument = gql`
    mutation watchlistPageAddToWatchlist($companyId: ID!) {
  addToWatchlist(companyId: $companyId) {
    user {
      id
      watchlist {
        id
      }
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type WatchlistPageAddToWatchlistMutationFn = Apollo.MutationFunction<WatchlistPageAddToWatchlistMutation, WatchlistPageAddToWatchlistMutationVariables>;

/**
 * __useWatchlistPageAddToWatchlistMutation__
 *
 * To run a mutation, you first call `useWatchlistPageAddToWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWatchlistPageAddToWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [watchlistPageAddToWatchlistMutation, { data, loading, error }] = useWatchlistPageAddToWatchlistMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWatchlistPageAddToWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<WatchlistPageAddToWatchlistMutation, WatchlistPageAddToWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WatchlistPageAddToWatchlistMutation, WatchlistPageAddToWatchlistMutationVariables>(WatchlistPageAddToWatchlistDocument, options);
      }
export type WatchlistPageAddToWatchlistMutationHookResult = ReturnType<typeof useWatchlistPageAddToWatchlistMutation>;
export type WatchlistPageAddToWatchlistMutationResult = Apollo.MutationResult<WatchlistPageAddToWatchlistMutation>;
export type WatchlistPageAddToWatchlistMutationOptions = Apollo.BaseMutationOptions<WatchlistPageAddToWatchlistMutation, WatchlistPageAddToWatchlistMutationVariables>;
export const WatchlistPageRemoveFromWatchlistDocument = gql`
    mutation watchlistPageRemoveFromWatchlist($companyId: ID!) {
  removeFromWatchlist(companyId: $companyId) {
    user {
      id
      watchlist {
        id
      }
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type WatchlistPageRemoveFromWatchlistMutationFn = Apollo.MutationFunction<WatchlistPageRemoveFromWatchlistMutation, WatchlistPageRemoveFromWatchlistMutationVariables>;

/**
 * __useWatchlistPageRemoveFromWatchlistMutation__
 *
 * To run a mutation, you first call `useWatchlistPageRemoveFromWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWatchlistPageRemoveFromWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [watchlistPageRemoveFromWatchlistMutation, { data, loading, error }] = useWatchlistPageRemoveFromWatchlistMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWatchlistPageRemoveFromWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<WatchlistPageRemoveFromWatchlistMutation, WatchlistPageRemoveFromWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WatchlistPageRemoveFromWatchlistMutation, WatchlistPageRemoveFromWatchlistMutationVariables>(WatchlistPageRemoveFromWatchlistDocument, options);
      }
export type WatchlistPageRemoveFromWatchlistMutationHookResult = ReturnType<typeof useWatchlistPageRemoveFromWatchlistMutation>;
export type WatchlistPageRemoveFromWatchlistMutationResult = Apollo.MutationResult<WatchlistPageRemoveFromWatchlistMutation>;
export type WatchlistPageRemoveFromWatchlistMutationOptions = Apollo.BaseMutationOptions<WatchlistPageRemoveFromWatchlistMutation, WatchlistPageRemoveFromWatchlistMutationVariables>;
export const TransitionCurrentStepDocument = gql`
    mutation transitionCurrentStep($onboardingId: ID!) {
  transitionCurrentStep(onboardingId: $onboardingId) {
    onboarding {
      id
      currentStep
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type TransitionCurrentStepMutationFn = Apollo.MutationFunction<TransitionCurrentStepMutation, TransitionCurrentStepMutationVariables>;

/**
 * __useTransitionCurrentStepMutation__
 *
 * To run a mutation, you first call `useTransitionCurrentStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionCurrentStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionCurrentStepMutation, { data, loading, error }] = useTransitionCurrentStepMutation({
 *   variables: {
 *      onboardingId: // value for 'onboardingId'
 *   },
 * });
 */
export function useTransitionCurrentStepMutation(baseOptions?: Apollo.MutationHookOptions<TransitionCurrentStepMutation, TransitionCurrentStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransitionCurrentStepMutation, TransitionCurrentStepMutationVariables>(TransitionCurrentStepDocument, options);
      }
export type TransitionCurrentStepMutationHookResult = ReturnType<typeof useTransitionCurrentStepMutation>;
export type TransitionCurrentStepMutationResult = Apollo.MutationResult<TransitionCurrentStepMutation>;
export type TransitionCurrentStepMutationOptions = Apollo.BaseMutationOptions<TransitionCurrentStepMutation, TransitionCurrentStepMutationVariables>;
export const TransitionBackToPreviousStepDocument = gql`
    mutation transitionBackToPreviousStep($onboardingId: ID!) {
  transitionBackToPreviousStep(onboardingId: $onboardingId) {
    onboarding {
      id
      currentStep
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type TransitionBackToPreviousStepMutationFn = Apollo.MutationFunction<TransitionBackToPreviousStepMutation, TransitionBackToPreviousStepMutationVariables>;

/**
 * __useTransitionBackToPreviousStepMutation__
 *
 * To run a mutation, you first call `useTransitionBackToPreviousStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionBackToPreviousStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionBackToPreviousStepMutation, { data, loading, error }] = useTransitionBackToPreviousStepMutation({
 *   variables: {
 *      onboardingId: // value for 'onboardingId'
 *   },
 * });
 */
export function useTransitionBackToPreviousStepMutation(baseOptions?: Apollo.MutationHookOptions<TransitionBackToPreviousStepMutation, TransitionBackToPreviousStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransitionBackToPreviousStepMutation, TransitionBackToPreviousStepMutationVariables>(TransitionBackToPreviousStepDocument, options);
      }
export type TransitionBackToPreviousStepMutationHookResult = ReturnType<typeof useTransitionBackToPreviousStepMutation>;
export type TransitionBackToPreviousStepMutationResult = Apollo.MutationResult<TransitionBackToPreviousStepMutation>;
export type TransitionBackToPreviousStepMutationOptions = Apollo.BaseMutationOptions<TransitionBackToPreviousStepMutation, TransitionBackToPreviousStepMutationVariables>;
export const UpdateOnboardingDocument = gql`
    mutation updateOnboarding($onboardingId: ID!, $input: UpdateOnboardingInput!) {
  updateOnboarding(onboardingId: $onboardingId, input: $input) {
    onboarding {
      id
      hasAgreedToClickwrapAgreement
      identityVerificationSubmissionStatus
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type UpdateOnboardingMutationFn = Apollo.MutationFunction<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>;

/**
 * __useUpdateOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingMutation, { data, loading, error }] = useUpdateOnboardingMutation({
 *   variables: {
 *      onboardingId: // value for 'onboardingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>(UpdateOnboardingDocument, options);
      }
export type UpdateOnboardingMutationHookResult = ReturnType<typeof useUpdateOnboardingMutation>;
export type UpdateOnboardingMutationResult = Apollo.MutationResult<UpdateOnboardingMutation>;
export type UpdateOnboardingMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>;
export const AddBrokerIntermediaryInfoDocument = gql`
    mutation addBrokerIntermediaryInfo($input: AddBrokerIntermediaryInfoInput!) {
  addBrokerIntermediaryInfo(input: $input) {
    brokerInfo {
      id
    }
  }
}
    `;
export type AddBrokerIntermediaryInfoMutationFn = Apollo.MutationFunction<AddBrokerIntermediaryInfoMutation, AddBrokerIntermediaryInfoMutationVariables>;

/**
 * __useAddBrokerIntermediaryInfoMutation__
 *
 * To run a mutation, you first call `useAddBrokerIntermediaryInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBrokerIntermediaryInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBrokerIntermediaryInfoMutation, { data, loading, error }] = useAddBrokerIntermediaryInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBrokerIntermediaryInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddBrokerIntermediaryInfoMutation, AddBrokerIntermediaryInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBrokerIntermediaryInfoMutation, AddBrokerIntermediaryInfoMutationVariables>(AddBrokerIntermediaryInfoDocument, options);
      }
export type AddBrokerIntermediaryInfoMutationHookResult = ReturnType<typeof useAddBrokerIntermediaryInfoMutation>;
export type AddBrokerIntermediaryInfoMutationResult = Apollo.MutationResult<AddBrokerIntermediaryInfoMutation>;
export type AddBrokerIntermediaryInfoMutationOptions = Apollo.BaseMutationOptions<AddBrokerIntermediaryInfoMutation, AddBrokerIntermediaryInfoMutationVariables>;
export const UpdateBrokerResidenceDocument = gql`
    mutation updateBrokerResidence($input: UpdateBrokerResidenceInput!) {
  updateBrokerResidence(input: $input) {
    user {
      id
      finraRegistered
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type UpdateBrokerResidenceMutationFn = Apollo.MutationFunction<UpdateBrokerResidenceMutation, UpdateBrokerResidenceMutationVariables>;

/**
 * __useUpdateBrokerResidenceMutation__
 *
 * To run a mutation, you first call `useUpdateBrokerResidenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrokerResidenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrokerResidenceMutation, { data, loading, error }] = useUpdateBrokerResidenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrokerResidenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrokerResidenceMutation, UpdateBrokerResidenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrokerResidenceMutation, UpdateBrokerResidenceMutationVariables>(UpdateBrokerResidenceDocument, options);
      }
export type UpdateBrokerResidenceMutationHookResult = ReturnType<typeof useUpdateBrokerResidenceMutation>;
export type UpdateBrokerResidenceMutationResult = Apollo.MutationResult<UpdateBrokerResidenceMutation>;
export type UpdateBrokerResidenceMutationOptions = Apollo.BaseMutationOptions<UpdateBrokerResidenceMutation, UpdateBrokerResidenceMutationVariables>;
export const AddBrokerInfoDocument = gql`
    mutation addBrokerInfo($input: AddBrokerInfoInput!) {
  addBrokerInfo(input: $input) {
    brokerInfo {
      ...ResidencePageBrokerInfo
    }
    errors {
      ...FullInputError
    }
  }
}
    ${ResidencePageBrokerInfoFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type AddBrokerInfoMutationFn = Apollo.MutationFunction<AddBrokerInfoMutation, AddBrokerInfoMutationVariables>;

/**
 * __useAddBrokerInfoMutation__
 *
 * To run a mutation, you first call `useAddBrokerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBrokerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBrokerInfoMutation, { data, loading, error }] = useAddBrokerInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBrokerInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddBrokerInfoMutation, AddBrokerInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBrokerInfoMutation, AddBrokerInfoMutationVariables>(AddBrokerInfoDocument, options);
      }
export type AddBrokerInfoMutationHookResult = ReturnType<typeof useAddBrokerInfoMutation>;
export type AddBrokerInfoMutationResult = Apollo.MutationResult<AddBrokerInfoMutation>;
export type AddBrokerInfoMutationOptions = Apollo.BaseMutationOptions<AddBrokerInfoMutation, AddBrokerInfoMutationVariables>;
export const AnswerInstitutionAccreditationQuestionsDocument = gql`
    mutation answerInstitutionAccreditationQuestions($input: AnswerInstitutionAccreditationQuestionsInput!) {
  answerInstitutionAccreditationQuestions(input: $input) {
    institution {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type AnswerInstitutionAccreditationQuestionsMutationFn = Apollo.MutationFunction<AnswerInstitutionAccreditationQuestionsMutation, AnswerInstitutionAccreditationQuestionsMutationVariables>;

/**
 * __useAnswerInstitutionAccreditationQuestionsMutation__
 *
 * To run a mutation, you first call `useAnswerInstitutionAccreditationQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerInstitutionAccreditationQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerInstitutionAccreditationQuestionsMutation, { data, loading, error }] = useAnswerInstitutionAccreditationQuestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnswerInstitutionAccreditationQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<AnswerInstitutionAccreditationQuestionsMutation, AnswerInstitutionAccreditationQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnswerInstitutionAccreditationQuestionsMutation, AnswerInstitutionAccreditationQuestionsMutationVariables>(AnswerInstitutionAccreditationQuestionsDocument, options);
      }
export type AnswerInstitutionAccreditationQuestionsMutationHookResult = ReturnType<typeof useAnswerInstitutionAccreditationQuestionsMutation>;
export type AnswerInstitutionAccreditationQuestionsMutationResult = Apollo.MutationResult<AnswerInstitutionAccreditationQuestionsMutation>;
export type AnswerInstitutionAccreditationQuestionsMutationOptions = Apollo.BaseMutationOptions<AnswerInstitutionAccreditationQuestionsMutation, AnswerInstitutionAccreditationQuestionsMutationVariables>;
export const InstitutionDetailsDocument = gql`
    mutation institutionDetails($createInstitutionInput: CreateInstitutionInput!, $updateUserInput: UpdateUserInput!) {
  createInstitution(input: $createInstitutionInput) {
    errors {
      ...FullInputError
    }
  }
  updateUser(input: $updateUserInput) {
    user {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type InstitutionDetailsMutationFn = Apollo.MutationFunction<InstitutionDetailsMutation, InstitutionDetailsMutationVariables>;

/**
 * __useInstitutionDetailsMutation__
 *
 * To run a mutation, you first call `useInstitutionDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionDetailsMutation, { data, loading, error }] = useInstitutionDetailsMutation({
 *   variables: {
 *      createInstitutionInput: // value for 'createInstitutionInput'
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useInstitutionDetailsMutation(baseOptions?: Apollo.MutationHookOptions<InstitutionDetailsMutation, InstitutionDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InstitutionDetailsMutation, InstitutionDetailsMutationVariables>(InstitutionDetailsDocument, options);
      }
export type InstitutionDetailsMutationHookResult = ReturnType<typeof useInstitutionDetailsMutation>;
export type InstitutionDetailsMutationResult = Apollo.MutationResult<InstitutionDetailsMutation>;
export type InstitutionDetailsMutationOptions = Apollo.BaseMutationOptions<InstitutionDetailsMutation, InstitutionDetailsMutationVariables>;
export const SellerLotDetailsDocument = gql`
    mutation sellerLotDetails($holdingInput: AddHoldingInput!) {
  addHolding(input: $holdingInput) {
    eitherHolding {
      ...EitherHolding
    }
    errors {
      ...FullInputError
    }
  }
}
    ${EitherHoldingFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type SellerLotDetailsMutationFn = Apollo.MutationFunction<SellerLotDetailsMutation, SellerLotDetailsMutationVariables>;

/**
 * __useSellerLotDetailsMutation__
 *
 * To run a mutation, you first call `useSellerLotDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellerLotDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellerLotDetailsMutation, { data, loading, error }] = useSellerLotDetailsMutation({
 *   variables: {
 *      holdingInput: // value for 'holdingInput'
 *   },
 * });
 */
export function useSellerLotDetailsMutation(baseOptions?: Apollo.MutationHookOptions<SellerLotDetailsMutation, SellerLotDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SellerLotDetailsMutation, SellerLotDetailsMutationVariables>(SellerLotDetailsDocument, options);
      }
export type SellerLotDetailsMutationHookResult = ReturnType<typeof useSellerLotDetailsMutation>;
export type SellerLotDetailsMutationResult = Apollo.MutationResult<SellerLotDetailsMutation>;
export type SellerLotDetailsMutationOptions = Apollo.BaseMutationOptions<SellerLotDetailsMutation, SellerLotDetailsMutationVariables>;
export const OnboardingSelectCompanyInputListCompaniesDocument = gql`
    query onboardingSelectCompanyInputListCompanies($orderBy: ListCompaniesOrderBy!, $first: Int!, $searchText: String) {
  listCompanies(orderBy: $orderBy, first: $first, searchText: $searchText) {
    edges {
      ...OnboardingSelectCompanyInputListCompanyEdge
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${OnboardingSelectCompanyInputListCompanyEdgeFragmentDoc}`;

/**
 * __useOnboardingSelectCompanyInputListCompaniesQuery__
 *
 * To run a query within a React component, call `useOnboardingSelectCompanyInputListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingSelectCompanyInputListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingSelectCompanyInputListCompaniesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useOnboardingSelectCompanyInputListCompaniesQuery(baseOptions: Apollo.QueryHookOptions<OnboardingSelectCompanyInputListCompaniesQuery, OnboardingSelectCompanyInputListCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingSelectCompanyInputListCompaniesQuery, OnboardingSelectCompanyInputListCompaniesQueryVariables>(OnboardingSelectCompanyInputListCompaniesDocument, options);
      }
export function useOnboardingSelectCompanyInputListCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingSelectCompanyInputListCompaniesQuery, OnboardingSelectCompanyInputListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingSelectCompanyInputListCompaniesQuery, OnboardingSelectCompanyInputListCompaniesQueryVariables>(OnboardingSelectCompanyInputListCompaniesDocument, options);
        }
export type OnboardingSelectCompanyInputListCompaniesQueryHookResult = ReturnType<typeof useOnboardingSelectCompanyInputListCompaniesQuery>;
export type OnboardingSelectCompanyInputListCompaniesLazyQueryHookResult = ReturnType<typeof useOnboardingSelectCompanyInputListCompaniesLazyQuery>;
export type OnboardingSelectCompanyInputListCompaniesQueryResult = Apollo.QueryResult<OnboardingSelectCompanyInputListCompaniesQuery, OnboardingSelectCompanyInputListCompaniesQueryVariables>;
export const CurrentMembershipAgreementDocument = gql`
    query currentMembershipAgreement {
  currentMembershipAgreement
}
    `;

/**
 * __useCurrentMembershipAgreementQuery__
 *
 * To run a query within a React component, call `useCurrentMembershipAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentMembershipAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentMembershipAgreementQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentMembershipAgreementQuery(baseOptions?: Apollo.QueryHookOptions<CurrentMembershipAgreementQuery, CurrentMembershipAgreementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentMembershipAgreementQuery, CurrentMembershipAgreementQueryVariables>(CurrentMembershipAgreementDocument, options);
      }
export function useCurrentMembershipAgreementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentMembershipAgreementQuery, CurrentMembershipAgreementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentMembershipAgreementQuery, CurrentMembershipAgreementQueryVariables>(CurrentMembershipAgreementDocument, options);
        }
export type CurrentMembershipAgreementQueryHookResult = ReturnType<typeof useCurrentMembershipAgreementQuery>;
export type CurrentMembershipAgreementLazyQueryHookResult = ReturnType<typeof useCurrentMembershipAgreementLazyQuery>;
export type CurrentMembershipAgreementQueryResult = Apollo.QueryResult<CurrentMembershipAgreementQuery, CurrentMembershipAgreementQueryVariables>;
export const TransactionByIdDocument = gql`
    query transactionById($id: ID!) {
  transactionById(id: $id) {
    ...TransactionDocumentPageTransaction
  }
}
    ${TransactionDocumentPageTransactionFragmentDoc}`;

/**
 * __useTransactionByIdQuery__
 *
 * To run a query within a React component, call `useTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransactionByIdQuery(baseOptions: Apollo.QueryHookOptions<TransactionByIdQuery, TransactionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionByIdQuery, TransactionByIdQueryVariables>(TransactionByIdDocument, options);
      }
export function useTransactionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionByIdQuery, TransactionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionByIdQuery, TransactionByIdQueryVariables>(TransactionByIdDocument, options);
        }
export type TransactionByIdQueryHookResult = ReturnType<typeof useTransactionByIdQuery>;
export type TransactionByIdLazyQueryHookResult = ReturnType<typeof useTransactionByIdLazyQuery>;
export type TransactionByIdQueryResult = Apollo.QueryResult<TransactionByIdQuery, TransactionByIdQueryVariables>;
export const UpdateTransactionSellerBankAccountRequestStatusDocument = gql`
    mutation updateTransactionSellerBankAccountRequestStatus($transactionId: ID!, $sellerBankAccountRequestStatus: BankAccountRequestStatus!) {
  updateTransactionSellerBankAccountRequestStatus(
    transactionId: $transactionId
    sellerBankAccountRequestStatus: $sellerBankAccountRequestStatus
  ) {
    errors {
      type
      field
    }
    transaction {
      id
      sellerBankAccountRequestStatus
    }
  }
}
    `;
export type UpdateTransactionSellerBankAccountRequestStatusMutationFn = Apollo.MutationFunction<UpdateTransactionSellerBankAccountRequestStatusMutation, UpdateTransactionSellerBankAccountRequestStatusMutationVariables>;

/**
 * __useUpdateTransactionSellerBankAccountRequestStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionSellerBankAccountRequestStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionSellerBankAccountRequestStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionSellerBankAccountRequestStatusMutation, { data, loading, error }] = useUpdateTransactionSellerBankAccountRequestStatusMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      sellerBankAccountRequestStatus: // value for 'sellerBankAccountRequestStatus'
 *   },
 * });
 */
export function useUpdateTransactionSellerBankAccountRequestStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionSellerBankAccountRequestStatusMutation, UpdateTransactionSellerBankAccountRequestStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionSellerBankAccountRequestStatusMutation, UpdateTransactionSellerBankAccountRequestStatusMutationVariables>(UpdateTransactionSellerBankAccountRequestStatusDocument, options);
      }
export type UpdateTransactionSellerBankAccountRequestStatusMutationHookResult = ReturnType<typeof useUpdateTransactionSellerBankAccountRequestStatusMutation>;
export type UpdateTransactionSellerBankAccountRequestStatusMutationResult = Apollo.MutationResult<UpdateTransactionSellerBankAccountRequestStatusMutation>;
export type UpdateTransactionSellerBankAccountRequestStatusMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionSellerBankAccountRequestStatusMutation, UpdateTransactionSellerBankAccountRequestStatusMutationVariables>;
export const TransactionOnSellerBankAccountCreatedDocument = gql`
    subscription transactionOnSellerBankAccountCreated($transactionId: ID!) {
  onSellerBankAccountCreated(transactionId: $transactionId) {
    id
  }
}
    `;

/**
 * __useTransactionOnSellerBankAccountCreatedSubscription__
 *
 * To run a query within a React component, call `useTransactionOnSellerBankAccountCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTransactionOnSellerBankAccountCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionOnSellerBankAccountCreatedSubscription({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useTransactionOnSellerBankAccountCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<TransactionOnSellerBankAccountCreatedSubscription, TransactionOnSellerBankAccountCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TransactionOnSellerBankAccountCreatedSubscription, TransactionOnSellerBankAccountCreatedSubscriptionVariables>(TransactionOnSellerBankAccountCreatedDocument, options);
      }
export type TransactionOnSellerBankAccountCreatedSubscriptionHookResult = ReturnType<typeof useTransactionOnSellerBankAccountCreatedSubscription>;
export type TransactionOnSellerBankAccountCreatedSubscriptionResult = Apollo.SubscriptionResult<TransactionOnSellerBankAccountCreatedSubscription>;
export const SubmitBankAccountVerificationDocument = gql`
    mutation submitBankAccountVerification($depositValueOne: Int!, $depositValueTwo: Int!, $sellerBankAccountId: ID!) {
  updateBankAccountVerification(
    deposit1Value: $depositValueOne
    deposit2Value: $depositValueTwo
    sellerBankAccountId: $sellerBankAccountId
  ) {
    errors {
      field
      message
      type
    }
    externalAccount {
      id
      counterpartyId
      verificationStatus
    }
  }
}
    `;
export type SubmitBankAccountVerificationMutationFn = Apollo.MutationFunction<SubmitBankAccountVerificationMutation, SubmitBankAccountVerificationMutationVariables>;

/**
 * __useSubmitBankAccountVerificationMutation__
 *
 * To run a mutation, you first call `useSubmitBankAccountVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBankAccountVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBankAccountVerificationMutation, { data, loading, error }] = useSubmitBankAccountVerificationMutation({
 *   variables: {
 *      depositValueOne: // value for 'depositValueOne'
 *      depositValueTwo: // value for 'depositValueTwo'
 *      sellerBankAccountId: // value for 'sellerBankAccountId'
 *   },
 * });
 */
export function useSubmitBankAccountVerificationMutation(baseOptions?: Apollo.MutationHookOptions<SubmitBankAccountVerificationMutation, SubmitBankAccountVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitBankAccountVerificationMutation, SubmitBankAccountVerificationMutationVariables>(SubmitBankAccountVerificationDocument, options);
      }
export type SubmitBankAccountVerificationMutationHookResult = ReturnType<typeof useSubmitBankAccountVerificationMutation>;
export type SubmitBankAccountVerificationMutationResult = Apollo.MutationResult<SubmitBankAccountVerificationMutation>;
export type SubmitBankAccountVerificationMutationOptions = Apollo.BaseMutationOptions<SubmitBankAccountVerificationMutation, SubmitBankAccountVerificationMutationVariables>;
export const UpdateTransactionSellerBankAccountIdDocument = gql`
    mutation updateTransactionSellerBankAccountId($transactionId: ID!, $sellerBankAccountId: ID!) {
  updateTransactionSellerBankAccountId(
    transactionId: $transactionId
    sellerBankAccountId: $sellerBankAccountId
  ) {
    errors {
      field
      message
      type
    }
    transaction {
      id
      sellerBankAccountId
    }
  }
}
    `;
export type UpdateTransactionSellerBankAccountIdMutationFn = Apollo.MutationFunction<UpdateTransactionSellerBankAccountIdMutation, UpdateTransactionSellerBankAccountIdMutationVariables>;

/**
 * __useUpdateTransactionSellerBankAccountIdMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionSellerBankAccountIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionSellerBankAccountIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionSellerBankAccountIdMutation, { data, loading, error }] = useUpdateTransactionSellerBankAccountIdMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      sellerBankAccountId: // value for 'sellerBankAccountId'
 *   },
 * });
 */
export function useUpdateTransactionSellerBankAccountIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionSellerBankAccountIdMutation, UpdateTransactionSellerBankAccountIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionSellerBankAccountIdMutation, UpdateTransactionSellerBankAccountIdMutationVariables>(UpdateTransactionSellerBankAccountIdDocument, options);
      }
export type UpdateTransactionSellerBankAccountIdMutationHookResult = ReturnType<typeof useUpdateTransactionSellerBankAccountIdMutation>;
export type UpdateTransactionSellerBankAccountIdMutationResult = Apollo.MutationResult<UpdateTransactionSellerBankAccountIdMutation>;
export type UpdateTransactionSellerBankAccountIdMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionSellerBankAccountIdMutation, UpdateTransactionSellerBankAccountIdMutationVariables>;
export const UpdateTransactionModificationAcknowledgmentDocument = gql`
    mutation updateTransactionModificationAcknowledgment($transactionId: ID!, $input: UpdateTransactionModificationAcknowledgmentInput!) {
  updateTransactionModificationAcknowledgment(
    transactionId: $transactionId
    input: $input
  ) {
    transactionModificationAcknowledgment {
      id
      ...TransactionModificationTransactionModificationAcknowledgment
    }
  }
}
    ${TransactionModificationTransactionModificationAcknowledgmentFragmentDoc}`;
export type UpdateTransactionModificationAcknowledgmentMutationFn = Apollo.MutationFunction<UpdateTransactionModificationAcknowledgmentMutation, UpdateTransactionModificationAcknowledgmentMutationVariables>;

/**
 * __useUpdateTransactionModificationAcknowledgmentMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionModificationAcknowledgmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionModificationAcknowledgmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionModificationAcknowledgmentMutation, { data, loading, error }] = useUpdateTransactionModificationAcknowledgmentMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransactionModificationAcknowledgmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionModificationAcknowledgmentMutation, UpdateTransactionModificationAcknowledgmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionModificationAcknowledgmentMutation, UpdateTransactionModificationAcknowledgmentMutationVariables>(UpdateTransactionModificationAcknowledgmentDocument, options);
      }
export type UpdateTransactionModificationAcknowledgmentMutationHookResult = ReturnType<typeof useUpdateTransactionModificationAcknowledgmentMutation>;
export type UpdateTransactionModificationAcknowledgmentMutationResult = Apollo.MutationResult<UpdateTransactionModificationAcknowledgmentMutation>;
export type UpdateTransactionModificationAcknowledgmentMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionModificationAcknowledgmentMutation, UpdateTransactionModificationAcknowledgmentMutationVariables>;
export const TransactionModificationOnTransactionModificationUpdatedDocument = gql`
    subscription transactionModificationOnTransactionModificationUpdated($transactionModificationId: ID!) {
  onTransactionModificationUpdated(
    transactionModificationId: $transactionModificationId
  ) {
    id
  }
}
    `;

/**
 * __useTransactionModificationOnTransactionModificationUpdatedSubscription__
 *
 * To run a query within a React component, call `useTransactionModificationOnTransactionModificationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTransactionModificationOnTransactionModificationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionModificationOnTransactionModificationUpdatedSubscription({
 *   variables: {
 *      transactionModificationId: // value for 'transactionModificationId'
 *   },
 * });
 */
export function useTransactionModificationOnTransactionModificationUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<TransactionModificationOnTransactionModificationUpdatedSubscription, TransactionModificationOnTransactionModificationUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TransactionModificationOnTransactionModificationUpdatedSubscription, TransactionModificationOnTransactionModificationUpdatedSubscriptionVariables>(TransactionModificationOnTransactionModificationUpdatedDocument, options);
      }
export type TransactionModificationOnTransactionModificationUpdatedSubscriptionHookResult = ReturnType<typeof useTransactionModificationOnTransactionModificationUpdatedSubscription>;
export type TransactionModificationOnTransactionModificationUpdatedSubscriptionResult = Apollo.SubscriptionResult<TransactionModificationOnTransactionModificationUpdatedSubscription>;
export const BidPageMyActivityDocument = gql`
    query bidPageMyActivity {
  myActivity {
    myBids {
      id
    }
  }
}
    `;

/**
 * __useBidPageMyActivityQuery__
 *
 * To run a query within a React component, call `useBidPageMyActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useBidPageMyActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBidPageMyActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useBidPageMyActivityQuery(baseOptions?: Apollo.QueryHookOptions<BidPageMyActivityQuery, BidPageMyActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BidPageMyActivityQuery, BidPageMyActivityQueryVariables>(BidPageMyActivityDocument, options);
      }
export function useBidPageMyActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BidPageMyActivityQuery, BidPageMyActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BidPageMyActivityQuery, BidPageMyActivityQueryVariables>(BidPageMyActivityDocument, options);
        }
export type BidPageMyActivityQueryHookResult = ReturnType<typeof useBidPageMyActivityQuery>;
export type BidPageMyActivityLazyQueryHookResult = ReturnType<typeof useBidPageMyActivityLazyQuery>;
export type BidPageMyActivityQueryResult = Apollo.QueryResult<BidPageMyActivityQuery, BidPageMyActivityQueryVariables>;
export const BidPageBidByIdDocument = gql`
    query bidPageBidById($id: ID!) {
  bidById(id: $id) {
    ...BidPageBid
  }
}
    ${BidPageBidFragmentDoc}`;

/**
 * __useBidPageBidByIdQuery__
 *
 * To run a query within a React component, call `useBidPageBidByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBidPageBidByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBidPageBidByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBidPageBidByIdQuery(baseOptions: Apollo.QueryHookOptions<BidPageBidByIdQuery, BidPageBidByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BidPageBidByIdQuery, BidPageBidByIdQueryVariables>(BidPageBidByIdDocument, options);
      }
export function useBidPageBidByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BidPageBidByIdQuery, BidPageBidByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BidPageBidByIdQuery, BidPageBidByIdQueryVariables>(BidPageBidByIdDocument, options);
        }
export type BidPageBidByIdQueryHookResult = ReturnType<typeof useBidPageBidByIdQuery>;
export type BidPageBidByIdLazyQueryHookResult = ReturnType<typeof useBidPageBidByIdLazyQuery>;
export type BidPageBidByIdQueryResult = Apollo.QueryResult<BidPageBidByIdQuery, BidPageBidByIdQueryVariables>;
export const WithdrawBidDocument = gql`
    mutation withdrawBid($bidId: ID!) {
  withdrawBid(bidId: $bidId) {
    bid {
      id
      listing {
        ...MakeUrlListing
      }
    }
    errors {
      ...FullInputError
    }
  }
}
    ${MakeUrlListingFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type WithdrawBidMutationFn = Apollo.MutationFunction<WithdrawBidMutation, WithdrawBidMutationVariables>;

/**
 * __useWithdrawBidMutation__
 *
 * To run a mutation, you first call `useWithdrawBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawBidMutation, { data, loading, error }] = useWithdrawBidMutation({
 *   variables: {
 *      bidId: // value for 'bidId'
 *   },
 * });
 */
export function useWithdrawBidMutation(baseOptions?: Apollo.MutationHookOptions<WithdrawBidMutation, WithdrawBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WithdrawBidMutation, WithdrawBidMutationVariables>(WithdrawBidDocument, options);
      }
export type WithdrawBidMutationHookResult = ReturnType<typeof useWithdrawBidMutation>;
export type WithdrawBidMutationResult = Apollo.MutationResult<WithdrawBidMutation>;
export type WithdrawBidMutationOptions = Apollo.BaseMutationOptions<WithdrawBidMutation, WithdrawBidMutationVariables>;
export const CounterBidFeeBreakdownCommissionInfoDocument = gql`
    query counterBidFeeBreakdownCommissionInfo($totalShares: Int!, $pricePerShare: Int!) {
  commissionInfo(totalShares: $totalShares, pricePerShare: $pricePerShare) {
    ...CounterBidFeeBreakdownCommissionInfo
  }
}
    ${CounterBidFeeBreakdownCommissionInfoFragmentDoc}`;

/**
 * __useCounterBidFeeBreakdownCommissionInfoQuery__
 *
 * To run a query within a React component, call `useCounterBidFeeBreakdownCommissionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterBidFeeBreakdownCommissionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterBidFeeBreakdownCommissionInfoQuery({
 *   variables: {
 *      totalShares: // value for 'totalShares'
 *      pricePerShare: // value for 'pricePerShare'
 *   },
 * });
 */
export function useCounterBidFeeBreakdownCommissionInfoQuery(baseOptions: Apollo.QueryHookOptions<CounterBidFeeBreakdownCommissionInfoQuery, CounterBidFeeBreakdownCommissionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounterBidFeeBreakdownCommissionInfoQuery, CounterBidFeeBreakdownCommissionInfoQueryVariables>(CounterBidFeeBreakdownCommissionInfoDocument, options);
      }
export function useCounterBidFeeBreakdownCommissionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounterBidFeeBreakdownCommissionInfoQuery, CounterBidFeeBreakdownCommissionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounterBidFeeBreakdownCommissionInfoQuery, CounterBidFeeBreakdownCommissionInfoQueryVariables>(CounterBidFeeBreakdownCommissionInfoDocument, options);
        }
export type CounterBidFeeBreakdownCommissionInfoQueryHookResult = ReturnType<typeof useCounterBidFeeBreakdownCommissionInfoQuery>;
export type CounterBidFeeBreakdownCommissionInfoLazyQueryHookResult = ReturnType<typeof useCounterBidFeeBreakdownCommissionInfoLazyQuery>;
export type CounterBidFeeBreakdownCommissionInfoQueryResult = Apollo.QueryResult<CounterBidFeeBreakdownCommissionInfoQuery, CounterBidFeeBreakdownCommissionInfoQueryVariables>;
export const TltAlternateSignerByTokenDocument = gql`
    query tltAlternateSignerByToken($token: String!) {
  tltAlternateSignerByToken(token: $token) {
    ...TltAlternateSignerPageAlternateSigner
  }
}
    ${TltAlternateSignerPageAlternateSignerFragmentDoc}`;

/**
 * __useTltAlternateSignerByTokenQuery__
 *
 * To run a query within a React component, call `useTltAlternateSignerByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTltAlternateSignerByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTltAlternateSignerByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTltAlternateSignerByTokenQuery(baseOptions: Apollo.QueryHookOptions<TltAlternateSignerByTokenQuery, TltAlternateSignerByTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TltAlternateSignerByTokenQuery, TltAlternateSignerByTokenQueryVariables>(TltAlternateSignerByTokenDocument, options);
      }
export function useTltAlternateSignerByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TltAlternateSignerByTokenQuery, TltAlternateSignerByTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TltAlternateSignerByTokenQuery, TltAlternateSignerByTokenQueryVariables>(TltAlternateSignerByTokenDocument, options);
        }
export type TltAlternateSignerByTokenQueryHookResult = ReturnType<typeof useTltAlternateSignerByTokenQuery>;
export type TltAlternateSignerByTokenLazyQueryHookResult = ReturnType<typeof useTltAlternateSignerByTokenLazyQuery>;
export type TltAlternateSignerByTokenQueryResult = Apollo.QueryResult<TltAlternateSignerByTokenQuery, TltAlternateSignerByTokenQueryVariables>;
export const SubmitBidCardBrokerSubmitBidDocument = gql`
    mutation submitBidCardBrokerSubmitBid($input: BrokerSubmitBidInput!) {
  brokerSubmitBid(input: $input) {
    bid {
      id
      ...PlaceBidSuccessModalBid
    }
    errors {
      ...FullInputError
    }
  }
}
    ${PlaceBidSuccessModalBidFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type SubmitBidCardBrokerSubmitBidMutationFn = Apollo.MutationFunction<SubmitBidCardBrokerSubmitBidMutation, SubmitBidCardBrokerSubmitBidMutationVariables>;

/**
 * __useSubmitBidCardBrokerSubmitBidMutation__
 *
 * To run a mutation, you first call `useSubmitBidCardBrokerSubmitBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBidCardBrokerSubmitBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBidCardBrokerSubmitBidMutation, { data, loading, error }] = useSubmitBidCardBrokerSubmitBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitBidCardBrokerSubmitBidMutation(baseOptions?: Apollo.MutationHookOptions<SubmitBidCardBrokerSubmitBidMutation, SubmitBidCardBrokerSubmitBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitBidCardBrokerSubmitBidMutation, SubmitBidCardBrokerSubmitBidMutationVariables>(SubmitBidCardBrokerSubmitBidDocument, options);
      }
export type SubmitBidCardBrokerSubmitBidMutationHookResult = ReturnType<typeof useSubmitBidCardBrokerSubmitBidMutation>;
export type SubmitBidCardBrokerSubmitBidMutationResult = Apollo.MutationResult<SubmitBidCardBrokerSubmitBidMutation>;
export type SubmitBidCardBrokerSubmitBidMutationOptions = Apollo.BaseMutationOptions<SubmitBidCardBrokerSubmitBidMutation, SubmitBidCardBrokerSubmitBidMutationVariables>;
export const PlaceBidCardPlaceBidDocument = gql`
    mutation placeBidCardPlaceBid($input: PlaceBidInput!) {
  placeBid(input: $input) {
    bid {
      id
      ...PlaceBidSuccessModalBid
    }
    errors {
      ...FullInputError
    }
  }
}
    ${PlaceBidSuccessModalBidFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type PlaceBidCardPlaceBidMutationFn = Apollo.MutationFunction<PlaceBidCardPlaceBidMutation, PlaceBidCardPlaceBidMutationVariables>;

/**
 * __usePlaceBidCardPlaceBidMutation__
 *
 * To run a mutation, you first call `usePlaceBidCardPlaceBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceBidCardPlaceBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeBidCardPlaceBidMutation, { data, loading, error }] = usePlaceBidCardPlaceBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceBidCardPlaceBidMutation(baseOptions?: Apollo.MutationHookOptions<PlaceBidCardPlaceBidMutation, PlaceBidCardPlaceBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaceBidCardPlaceBidMutation, PlaceBidCardPlaceBidMutationVariables>(PlaceBidCardPlaceBidDocument, options);
      }
export type PlaceBidCardPlaceBidMutationHookResult = ReturnType<typeof usePlaceBidCardPlaceBidMutation>;
export type PlaceBidCardPlaceBidMutationResult = Apollo.MutationResult<PlaceBidCardPlaceBidMutation>;
export type PlaceBidCardPlaceBidMutationOptions = Apollo.BaseMutationOptions<PlaceBidCardPlaceBidMutation, PlaceBidCardPlaceBidMutationVariables>;
export const ListingPageBuyerActivityDocument = gql`
    query listingPageBuyerActivity($listingId: ID!) {
  buyerListingActivity(listingId: $listingId) {
    pendingPurchases {
      ...ListingPageBuyerActivityTransaction
    }
  }
}
    ${ListingPageBuyerActivityTransactionFragmentDoc}`;

/**
 * __useListingPageBuyerActivityQuery__
 *
 * To run a query within a React component, call `useListingPageBuyerActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingPageBuyerActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingPageBuyerActivityQuery({
 *   variables: {
 *      listingId: // value for 'listingId'
 *   },
 * });
 */
export function useListingPageBuyerActivityQuery(baseOptions: Apollo.QueryHookOptions<ListingPageBuyerActivityQuery, ListingPageBuyerActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingPageBuyerActivityQuery, ListingPageBuyerActivityQueryVariables>(ListingPageBuyerActivityDocument, options);
      }
export function useListingPageBuyerActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingPageBuyerActivityQuery, ListingPageBuyerActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingPageBuyerActivityQuery, ListingPageBuyerActivityQueryVariables>(ListingPageBuyerActivityDocument, options);
        }
export type ListingPageBuyerActivityQueryHookResult = ReturnType<typeof useListingPageBuyerActivityQuery>;
export type ListingPageBuyerActivityLazyQueryHookResult = ReturnType<typeof useListingPageBuyerActivityLazyQuery>;
export type ListingPageBuyerActivityQueryResult = Apollo.QueryResult<ListingPageBuyerActivityQuery, ListingPageBuyerActivityQueryVariables>;
export const ListingPageListingByIdDocument = gql`
    query listingPageListingById($id: ID!) {
  listingById(id: $id) {
    ...ListingPageListing
  }
}
    ${ListingPageListingFragmentDoc}`;

/**
 * __useListingPageListingByIdQuery__
 *
 * To run a query within a React component, call `useListingPageListingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingPageListingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingPageListingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListingPageListingByIdQuery(baseOptions: Apollo.QueryHookOptions<ListingPageListingByIdQuery, ListingPageListingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingPageListingByIdQuery, ListingPageListingByIdQueryVariables>(ListingPageListingByIdDocument, options);
      }
export function useListingPageListingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingPageListingByIdQuery, ListingPageListingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingPageListingByIdQuery, ListingPageListingByIdQueryVariables>(ListingPageListingByIdDocument, options);
        }
export type ListingPageListingByIdQueryHookResult = ReturnType<typeof useListingPageListingByIdQuery>;
export type ListingPageListingByIdLazyQueryHookResult = ReturnType<typeof useListingPageListingByIdLazyQuery>;
export type ListingPageListingByIdQueryResult = Apollo.QueryResult<ListingPageListingByIdQuery, ListingPageListingByIdQueryVariables>;
export const StandingBidPageStandingBidByIdDocument = gql`
    query standingBidPageStandingBidById($id: ID!) {
  standingBidById(id: $id) {
    ...StandingBidPageStandingBid
  }
}
    ${StandingBidPageStandingBidFragmentDoc}`;

/**
 * __useStandingBidPageStandingBidByIdQuery__
 *
 * To run a query within a React component, call `useStandingBidPageStandingBidByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandingBidPageStandingBidByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandingBidPageStandingBidByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStandingBidPageStandingBidByIdQuery(baseOptions: Apollo.QueryHookOptions<StandingBidPageStandingBidByIdQuery, StandingBidPageStandingBidByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandingBidPageStandingBidByIdQuery, StandingBidPageStandingBidByIdQueryVariables>(StandingBidPageStandingBidByIdDocument, options);
      }
export function useStandingBidPageStandingBidByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandingBidPageStandingBidByIdQuery, StandingBidPageStandingBidByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandingBidPageStandingBidByIdQuery, StandingBidPageStandingBidByIdQueryVariables>(StandingBidPageStandingBidByIdDocument, options);
        }
export type StandingBidPageStandingBidByIdQueryHookResult = ReturnType<typeof useStandingBidPageStandingBidByIdQuery>;
export type StandingBidPageStandingBidByIdLazyQueryHookResult = ReturnType<typeof useStandingBidPageStandingBidByIdLazyQuery>;
export type StandingBidPageStandingBidByIdQueryResult = Apollo.QueryResult<StandingBidPageStandingBidByIdQuery, StandingBidPageStandingBidByIdQueryVariables>;
export const SuitabilityQuestionGroupsDocument = gql`
    query suitabilityQuestionGroups($countryId: ID!, $investorStatus: InvestorStatus!) {
  currentSuitabilityQuestionGroup(
    countryId: $countryId
    investorStatus: $investorStatus
  ) {
    country
    id
    investorStatus
    version
    suitabilityQuestions {
      id
      description
      order
      text
      renderOptions {
        columns
      }
      caption
      suitabilityOptions {
        custom
        description
        id
        nextQuestionIds
        order
        text
        placeholder
      }
    }
  }
}
    `;

/**
 * __useSuitabilityQuestionGroupsQuery__
 *
 * To run a query within a React component, call `useSuitabilityQuestionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuitabilityQuestionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuitabilityQuestionGroupsQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      investorStatus: // value for 'investorStatus'
 *   },
 * });
 */
export function useSuitabilityQuestionGroupsQuery(baseOptions: Apollo.QueryHookOptions<SuitabilityQuestionGroupsQuery, SuitabilityQuestionGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuitabilityQuestionGroupsQuery, SuitabilityQuestionGroupsQueryVariables>(SuitabilityQuestionGroupsDocument, options);
      }
export function useSuitabilityQuestionGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuitabilityQuestionGroupsQuery, SuitabilityQuestionGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuitabilityQuestionGroupsQuery, SuitabilityQuestionGroupsQueryVariables>(SuitabilityQuestionGroupsDocument, options);
        }
export type SuitabilityQuestionGroupsQueryHookResult = ReturnType<typeof useSuitabilityQuestionGroupsQuery>;
export type SuitabilityQuestionGroupsLazyQueryHookResult = ReturnType<typeof useSuitabilityQuestionGroupsLazyQuery>;
export type SuitabilityQuestionGroupsQueryResult = Apollo.QueryResult<SuitabilityQuestionGroupsQuery, SuitabilityQuestionGroupsQueryVariables>;
export const GetCurrentSuitabilityAnswersDocument = gql`
    query getCurrentSuitabilityAnswers {
  suitabilityAnswers {
    id
    text
    suitabilityOption {
      id
      custom
      suitabilityQuestion {
        id
        text
        suitabilityQuestionGroup {
          id
          version
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentSuitabilityAnswersQuery__
 *
 * To run a query within a React component, call `useGetCurrentSuitabilityAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSuitabilityAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSuitabilityAnswersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentSuitabilityAnswersQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentSuitabilityAnswersQuery, GetCurrentSuitabilityAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentSuitabilityAnswersQuery, GetCurrentSuitabilityAnswersQueryVariables>(GetCurrentSuitabilityAnswersDocument, options);
      }
export function useGetCurrentSuitabilityAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentSuitabilityAnswersQuery, GetCurrentSuitabilityAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentSuitabilityAnswersQuery, GetCurrentSuitabilityAnswersQueryVariables>(GetCurrentSuitabilityAnswersDocument, options);
        }
export type GetCurrentSuitabilityAnswersQueryHookResult = ReturnType<typeof useGetCurrentSuitabilityAnswersQuery>;
export type GetCurrentSuitabilityAnswersLazyQueryHookResult = ReturnType<typeof useGetCurrentSuitabilityAnswersLazyQuery>;
export type GetCurrentSuitabilityAnswersQueryResult = Apollo.QueryResult<GetCurrentSuitabilityAnswersQuery, GetCurrentSuitabilityAnswersQueryVariables>;
export const AnswerSuitabilityQuestionsDocument = gql`
    mutation answerSuitabilityQuestions($answers: [SuitabilityAnswerInput!]!) {
  answerSuitability(answers: $answers) {
    errors {
      field
      message
      type
    }
    user {
      id
    }
  }
}
    `;
export type AnswerSuitabilityQuestionsMutationFn = Apollo.MutationFunction<AnswerSuitabilityQuestionsMutation, AnswerSuitabilityQuestionsMutationVariables>;

/**
 * __useAnswerSuitabilityQuestionsMutation__
 *
 * To run a mutation, you first call `useAnswerSuitabilityQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerSuitabilityQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerSuitabilityQuestionsMutation, { data, loading, error }] = useAnswerSuitabilityQuestionsMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useAnswerSuitabilityQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<AnswerSuitabilityQuestionsMutation, AnswerSuitabilityQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnswerSuitabilityQuestionsMutation, AnswerSuitabilityQuestionsMutationVariables>(AnswerSuitabilityQuestionsDocument, options);
      }
export type AnswerSuitabilityQuestionsMutationHookResult = ReturnType<typeof useAnswerSuitabilityQuestionsMutation>;
export type AnswerSuitabilityQuestionsMutationResult = Apollo.MutationResult<AnswerSuitabilityQuestionsMutation>;
export type AnswerSuitabilityQuestionsMutationOptions = Apollo.BaseMutationOptions<AnswerSuitabilityQuestionsMutation, AnswerSuitabilityQuestionsMutationVariables>;
export const TransactionExecutionPageTransactionByIdDocument = gql`
    query transactionExecutionPageTransactionById($id: ID!) {
  transactionById(id: $id) {
    ...TransactionExecutionPageTransaction
  }
}
    ${TransactionExecutionPageTransactionFragmentDoc}`;

/**
 * __useTransactionExecutionPageTransactionByIdQuery__
 *
 * To run a query within a React component, call `useTransactionExecutionPageTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionExecutionPageTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionExecutionPageTransactionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransactionExecutionPageTransactionByIdQuery(baseOptions: Apollo.QueryHookOptions<TransactionExecutionPageTransactionByIdQuery, TransactionExecutionPageTransactionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionExecutionPageTransactionByIdQuery, TransactionExecutionPageTransactionByIdQueryVariables>(TransactionExecutionPageTransactionByIdDocument, options);
      }
export function useTransactionExecutionPageTransactionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionExecutionPageTransactionByIdQuery, TransactionExecutionPageTransactionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionExecutionPageTransactionByIdQuery, TransactionExecutionPageTransactionByIdQueryVariables>(TransactionExecutionPageTransactionByIdDocument, options);
        }
export type TransactionExecutionPageTransactionByIdQueryHookResult = ReturnType<typeof useTransactionExecutionPageTransactionByIdQuery>;
export type TransactionExecutionPageTransactionByIdLazyQueryHookResult = ReturnType<typeof useTransactionExecutionPageTransactionByIdLazyQuery>;
export type TransactionExecutionPageTransactionByIdQueryResult = Apollo.QueryResult<TransactionExecutionPageTransactionByIdQuery, TransactionExecutionPageTransactionByIdQueryVariables>;
export const TransactionExecutionPageOnExecutionWorkflowUpdatedDocument = gql`
    subscription transactionExecutionPageOnExecutionWorkflowUpdated($workflowId: ID!) {
  onExecutionWorkflowUpdated(workflowId: $workflowId) {
    ...TransactionExecutionPageWorkflow
  }
}
    ${TransactionExecutionPageWorkflowFragmentDoc}`;

/**
 * __useTransactionExecutionPageOnExecutionWorkflowUpdatedSubscription__
 *
 * To run a query within a React component, call `useTransactionExecutionPageOnExecutionWorkflowUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTransactionExecutionPageOnExecutionWorkflowUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionExecutionPageOnExecutionWorkflowUpdatedSubscription({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useTransactionExecutionPageOnExecutionWorkflowUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<TransactionExecutionPageOnExecutionWorkflowUpdatedSubscription, TransactionExecutionPageOnExecutionWorkflowUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TransactionExecutionPageOnExecutionWorkflowUpdatedSubscription, TransactionExecutionPageOnExecutionWorkflowUpdatedSubscriptionVariables>(TransactionExecutionPageOnExecutionWorkflowUpdatedDocument, options);
      }
export type TransactionExecutionPageOnExecutionWorkflowUpdatedSubscriptionHookResult = ReturnType<typeof useTransactionExecutionPageOnExecutionWorkflowUpdatedSubscription>;
export type TransactionExecutionPageOnExecutionWorkflowUpdatedSubscriptionResult = Apollo.SubscriptionResult<TransactionExecutionPageOnExecutionWorkflowUpdatedSubscription>;
export const TransactionPageTransactionByIdDocument = gql`
    query transactionPageTransactionById($id: ID!) {
  transactionById(id: $id) {
    ...TransactionPageTransaction
  }
}
    ${TransactionPageTransactionFragmentDoc}`;

/**
 * __useTransactionPageTransactionByIdQuery__
 *
 * To run a query within a React component, call `useTransactionPageTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionPageTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionPageTransactionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransactionPageTransactionByIdQuery(baseOptions: Apollo.QueryHookOptions<TransactionPageTransactionByIdQuery, TransactionPageTransactionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionPageTransactionByIdQuery, TransactionPageTransactionByIdQueryVariables>(TransactionPageTransactionByIdDocument, options);
      }
export function useTransactionPageTransactionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionPageTransactionByIdQuery, TransactionPageTransactionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionPageTransactionByIdQuery, TransactionPageTransactionByIdQueryVariables>(TransactionPageTransactionByIdDocument, options);
        }
export type TransactionPageTransactionByIdQueryHookResult = ReturnType<typeof useTransactionPageTransactionByIdQuery>;
export type TransactionPageTransactionByIdLazyQueryHookResult = ReturnType<typeof useTransactionPageTransactionByIdLazyQuery>;
export type TransactionPageTransactionByIdQueryResult = Apollo.QueryResult<TransactionPageTransactionByIdQuery, TransactionPageTransactionByIdQueryVariables>;
export const AddHoldingsV2Document = gql`
    mutation addHoldingsV2($addHoldingsInput: AddHoldingsV2Input!) {
  addHoldingsV2(input: $addHoldingsInput) {
    holdingsV2 {
      ... on Holding {
        companyId
        id
        numSharesV2
        sellerId
      }
      ... on UnlistedHolding {
        companyName
        id
        numSharesV2
        sellerId
      }
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type AddHoldingsV2MutationFn = Apollo.MutationFunction<AddHoldingsV2Mutation, AddHoldingsV2MutationVariables>;

/**
 * __useAddHoldingsV2Mutation__
 *
 * To run a mutation, you first call `useAddHoldingsV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHoldingsV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHoldingsV2Mutation, { data, loading, error }] = useAddHoldingsV2Mutation({
 *   variables: {
 *      addHoldingsInput: // value for 'addHoldingsInput'
 *   },
 * });
 */
export function useAddHoldingsV2Mutation(baseOptions?: Apollo.MutationHookOptions<AddHoldingsV2Mutation, AddHoldingsV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHoldingsV2Mutation, AddHoldingsV2MutationVariables>(AddHoldingsV2Document, options);
      }
export type AddHoldingsV2MutationHookResult = ReturnType<typeof useAddHoldingsV2Mutation>;
export type AddHoldingsV2MutationResult = Apollo.MutationResult<AddHoldingsV2Mutation>;
export type AddHoldingsV2MutationOptions = Apollo.BaseMutationOptions<AddHoldingsV2Mutation, AddHoldingsV2MutationVariables>;
export const HoldingSelectCompanyListDocument = gql`
    query holdingSelectCompanyList($orderBy: ListCompaniesOrderBy!, $first: Int!, $searchText: String) {
  listCompanies(orderBy: $orderBy, first: $first, searchText: $searchText) {
    edges {
      ...HoldingSelectCompanyEdge
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${HoldingSelectCompanyEdgeFragmentDoc}`;

/**
 * __useHoldingSelectCompanyListQuery__
 *
 * To run a query within a React component, call `useHoldingSelectCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHoldingSelectCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHoldingSelectCompanyListQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useHoldingSelectCompanyListQuery(baseOptions: Apollo.QueryHookOptions<HoldingSelectCompanyListQuery, HoldingSelectCompanyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HoldingSelectCompanyListQuery, HoldingSelectCompanyListQueryVariables>(HoldingSelectCompanyListDocument, options);
      }
export function useHoldingSelectCompanyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HoldingSelectCompanyListQuery, HoldingSelectCompanyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HoldingSelectCompanyListQuery, HoldingSelectCompanyListQueryVariables>(HoldingSelectCompanyListDocument, options);
        }
export type HoldingSelectCompanyListQueryHookResult = ReturnType<typeof useHoldingSelectCompanyListQuery>;
export type HoldingSelectCompanyListLazyQueryHookResult = ReturnType<typeof useHoldingSelectCompanyListLazyQuery>;
export type HoldingSelectCompanyListQueryResult = Apollo.QueryResult<HoldingSelectCompanyListQuery, HoldingSelectCompanyListQueryVariables>;
export const UnaccreditedSellerActiveBidNotificationsCountDocument = gql`
    query unaccreditedSellerActiveBidNotificationsCount {
  unaccreditedSellerMyListingActivity {
    activeBids {
      id
      discussion {
        id
      }
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerActiveBidNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerActiveBidNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerActiveBidNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerActiveBidNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerActiveBidNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerActiveBidNotificationsCountQuery, UnaccreditedSellerActiveBidNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerActiveBidNotificationsCountQuery, UnaccreditedSellerActiveBidNotificationsCountQueryVariables>(UnaccreditedSellerActiveBidNotificationsCountDocument, options);
      }
export function useUnaccreditedSellerActiveBidNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerActiveBidNotificationsCountQuery, UnaccreditedSellerActiveBidNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerActiveBidNotificationsCountQuery, UnaccreditedSellerActiveBidNotificationsCountQueryVariables>(UnaccreditedSellerActiveBidNotificationsCountDocument, options);
        }
export type UnaccreditedSellerActiveBidNotificationsCountQueryHookResult = ReturnType<typeof useUnaccreditedSellerActiveBidNotificationsCountQuery>;
export type UnaccreditedSellerActiveBidNotificationsCountLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerActiveBidNotificationsCountLazyQuery>;
export type UnaccreditedSellerActiveBidNotificationsCountQueryResult = Apollo.QueryResult<UnaccreditedSellerActiveBidNotificationsCountQuery, UnaccreditedSellerActiveBidNotificationsCountQueryVariables>;
export const UnaccreditedSellerByCompanyIdActiveBidNotificationsCountDocument = gql`
    query unaccreditedSellerByCompanyIdActiveBidNotificationsCount($id: ID!) {
  companyById(id: $id) {
    id
    activity {
      myListings {
        id
        bids {
          id
          state
          discussion {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQueryVariables>(UnaccreditedSellerByCompanyIdActiveBidNotificationsCountDocument, options);
      }
export function useUnaccreditedSellerByCompanyIdActiveBidNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQueryVariables>(UnaccreditedSellerByCompanyIdActiveBidNotificationsCountDocument, options);
        }
export type UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQueryHookResult = ReturnType<typeof useUnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery>;
export type UnaccreditedSellerByCompanyIdActiveBidNotificationsCountLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerByCompanyIdActiveBidNotificationsCountLazyQuery>;
export type UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQueryResult = Apollo.QueryResult<UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdActiveBidNotificationsCountQueryVariables>;
export const UnaccreditedSellerInquiryNotificationsCountDocument = gql`
    query unaccreditedSellerInquiryNotificationsCount {
  unaccreditedSellerMyListingActivity {
    preBidDiscussions {
      id
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerInquiryNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerInquiryNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerInquiryNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerInquiryNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerInquiryNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerInquiryNotificationsCountQuery, UnaccreditedSellerInquiryNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerInquiryNotificationsCountQuery, UnaccreditedSellerInquiryNotificationsCountQueryVariables>(UnaccreditedSellerInquiryNotificationsCountDocument, options);
      }
export function useUnaccreditedSellerInquiryNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerInquiryNotificationsCountQuery, UnaccreditedSellerInquiryNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerInquiryNotificationsCountQuery, UnaccreditedSellerInquiryNotificationsCountQueryVariables>(UnaccreditedSellerInquiryNotificationsCountDocument, options);
        }
export type UnaccreditedSellerInquiryNotificationsCountQueryHookResult = ReturnType<typeof useUnaccreditedSellerInquiryNotificationsCountQuery>;
export type UnaccreditedSellerInquiryNotificationsCountLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerInquiryNotificationsCountLazyQuery>;
export type UnaccreditedSellerInquiryNotificationsCountQueryResult = Apollo.QueryResult<UnaccreditedSellerInquiryNotificationsCountQuery, UnaccreditedSellerInquiryNotificationsCountQueryVariables>;
export const UnaccreditedSellerByCompanyIdInquiryNotificationsCountDocument = gql`
    query unaccreditedSellerByCompanyIdInquiryNotificationsCount($topicFilter: MessageTopic) {
  myDiscussions(topicFilter: $topicFilter) {
    id
  }
}
    `;

/**
 * __useUnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery({
 *   variables: {
 *      topicFilter: // value for 'topicFilter'
 *   },
 * });
 */
export function useUnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery, UnaccreditedSellerByCompanyIdInquiryNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery, UnaccreditedSellerByCompanyIdInquiryNotificationsCountQueryVariables>(UnaccreditedSellerByCompanyIdInquiryNotificationsCountDocument, options);
      }
export function useUnaccreditedSellerByCompanyIdInquiryNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery, UnaccreditedSellerByCompanyIdInquiryNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery, UnaccreditedSellerByCompanyIdInquiryNotificationsCountQueryVariables>(UnaccreditedSellerByCompanyIdInquiryNotificationsCountDocument, options);
        }
export type UnaccreditedSellerByCompanyIdInquiryNotificationsCountQueryHookResult = ReturnType<typeof useUnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery>;
export type UnaccreditedSellerByCompanyIdInquiryNotificationsCountLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerByCompanyIdInquiryNotificationsCountLazyQuery>;
export type UnaccreditedSellerByCompanyIdInquiryNotificationsCountQueryResult = Apollo.QueryResult<UnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery, UnaccreditedSellerByCompanyIdInquiryNotificationsCountQueryVariables>;
export const UnaccreditedSellerPastBidNotificationsCountDocument = gql`
    query unaccreditedSellerPastBidNotificationsCount {
  unaccreditedSellerMyListingActivity {
    pastBids {
      id
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerPastBidNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerPastBidNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerPastBidNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerPastBidNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerPastBidNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerPastBidNotificationsCountQuery, UnaccreditedSellerPastBidNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerPastBidNotificationsCountQuery, UnaccreditedSellerPastBidNotificationsCountQueryVariables>(UnaccreditedSellerPastBidNotificationsCountDocument, options);
      }
export function useUnaccreditedSellerPastBidNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerPastBidNotificationsCountQuery, UnaccreditedSellerPastBidNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerPastBidNotificationsCountQuery, UnaccreditedSellerPastBidNotificationsCountQueryVariables>(UnaccreditedSellerPastBidNotificationsCountDocument, options);
        }
export type UnaccreditedSellerPastBidNotificationsCountQueryHookResult = ReturnType<typeof useUnaccreditedSellerPastBidNotificationsCountQuery>;
export type UnaccreditedSellerPastBidNotificationsCountLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerPastBidNotificationsCountLazyQuery>;
export type UnaccreditedSellerPastBidNotificationsCountQueryResult = Apollo.QueryResult<UnaccreditedSellerPastBidNotificationsCountQuery, UnaccreditedSellerPastBidNotificationsCountQueryVariables>;
export const UnaccreditedSellerByCompanyIdPastBidNotificationsCountDocument = gql`
    query unaccreditedSellerByCompanyIdPastBidNotificationsCount($id: ID!) {
  companyById(id: $id) {
    id
    activity {
      myListings {
        id
        bids {
          id
          state
        }
      }
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdPastBidNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdPastBidNotificationsCountQueryVariables>(UnaccreditedSellerByCompanyIdPastBidNotificationsCountDocument, options);
      }
export function useUnaccreditedSellerByCompanyIdPastBidNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdPastBidNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdPastBidNotificationsCountQueryVariables>(UnaccreditedSellerByCompanyIdPastBidNotificationsCountDocument, options);
        }
export type UnaccreditedSellerByCompanyIdPastBidNotificationsCountQueryHookResult = ReturnType<typeof useUnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery>;
export type UnaccreditedSellerByCompanyIdPastBidNotificationsCountLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerByCompanyIdPastBidNotificationsCountLazyQuery>;
export type UnaccreditedSellerByCompanyIdPastBidNotificationsCountQueryResult = Apollo.QueryResult<UnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdPastBidNotificationsCountQueryVariables>;
export const UnaccreditedSellerStandingBidNotificationsCountDocument = gql`
    query unaccreditedSellerStandingBidNotificationsCount {
  unaccreditedSellerMyCompanyActivity {
    availableStandingBids {
      id
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerStandingBidNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerStandingBidNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerStandingBidNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerStandingBidNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerStandingBidNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerStandingBidNotificationsCountQuery, UnaccreditedSellerStandingBidNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerStandingBidNotificationsCountQuery, UnaccreditedSellerStandingBidNotificationsCountQueryVariables>(UnaccreditedSellerStandingBidNotificationsCountDocument, options);
      }
export function useUnaccreditedSellerStandingBidNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerStandingBidNotificationsCountQuery, UnaccreditedSellerStandingBidNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerStandingBidNotificationsCountQuery, UnaccreditedSellerStandingBidNotificationsCountQueryVariables>(UnaccreditedSellerStandingBidNotificationsCountDocument, options);
        }
export type UnaccreditedSellerStandingBidNotificationsCountQueryHookResult = ReturnType<typeof useUnaccreditedSellerStandingBidNotificationsCountQuery>;
export type UnaccreditedSellerStandingBidNotificationsCountLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerStandingBidNotificationsCountLazyQuery>;
export type UnaccreditedSellerStandingBidNotificationsCountQueryResult = Apollo.QueryResult<UnaccreditedSellerStandingBidNotificationsCountQuery, UnaccreditedSellerStandingBidNotificationsCountQueryVariables>;
export const UnaccreditedSellerByCompanyIdStandingBidNotificationsCountDocument = gql`
    query unaccreditedSellerByCompanyIdStandingBidNotificationsCount($id: ID!) {
  companyById(id: $id) {
    id
    activity {
      availableStandingBids {
        id
      }
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQueryVariables>(UnaccreditedSellerByCompanyIdStandingBidNotificationsCountDocument, options);
      }
export function useUnaccreditedSellerByCompanyIdStandingBidNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQueryVariables>(UnaccreditedSellerByCompanyIdStandingBidNotificationsCountDocument, options);
        }
export type UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQueryHookResult = ReturnType<typeof useUnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery>;
export type UnaccreditedSellerByCompanyIdStandingBidNotificationsCountLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerByCompanyIdStandingBidNotificationsCountLazyQuery>;
export type UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQueryResult = Apollo.QueryResult<UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQuery, UnaccreditedSellerByCompanyIdStandingBidNotificationsCountQueryVariables>;
export const UnaccreditedSellerTransactionNotificationsCountDocument = gql`
    query unaccreditedSellerTransactionNotificationsCount {
  unaccreditedSellerMyActivity {
    myListing {
      id
    }
    pendingSales {
      id
      bid {
        id
        discussion {
          id
        }
      }
    }
    completedSales {
      id
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerTransactionNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerTransactionNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerTransactionNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerTransactionNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnaccreditedSellerTransactionNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<UnaccreditedSellerTransactionNotificationsCountQuery, UnaccreditedSellerTransactionNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerTransactionNotificationsCountQuery, UnaccreditedSellerTransactionNotificationsCountQueryVariables>(UnaccreditedSellerTransactionNotificationsCountDocument, options);
      }
export function useUnaccreditedSellerTransactionNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerTransactionNotificationsCountQuery, UnaccreditedSellerTransactionNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerTransactionNotificationsCountQuery, UnaccreditedSellerTransactionNotificationsCountQueryVariables>(UnaccreditedSellerTransactionNotificationsCountDocument, options);
        }
export type UnaccreditedSellerTransactionNotificationsCountQueryHookResult = ReturnType<typeof useUnaccreditedSellerTransactionNotificationsCountQuery>;
export type UnaccreditedSellerTransactionNotificationsCountLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerTransactionNotificationsCountLazyQuery>;
export type UnaccreditedSellerTransactionNotificationsCountQueryResult = Apollo.QueryResult<UnaccreditedSellerTransactionNotificationsCountQuery, UnaccreditedSellerTransactionNotificationsCountQueryVariables>;
export const UnaccreditedSellerByCompanyIdTransactionNotificationsCountDocument = gql`
    query unaccreditedSellerByCompanyIdTransactionNotificationsCount($id: ID!) {
  companyById(id: $id) {
    id
    activity {
      myCompletedTransactions {
        id
      }
      myOpenTransactions {
        id
        bid {
          id
          discussion {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery(baseOptions: Apollo.QueryHookOptions<UnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery, UnaccreditedSellerByCompanyIdTransactionNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery, UnaccreditedSellerByCompanyIdTransactionNotificationsCountQueryVariables>(UnaccreditedSellerByCompanyIdTransactionNotificationsCountDocument, options);
      }
export function useUnaccreditedSellerByCompanyIdTransactionNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery, UnaccreditedSellerByCompanyIdTransactionNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery, UnaccreditedSellerByCompanyIdTransactionNotificationsCountQueryVariables>(UnaccreditedSellerByCompanyIdTransactionNotificationsCountDocument, options);
        }
export type UnaccreditedSellerByCompanyIdTransactionNotificationsCountQueryHookResult = ReturnType<typeof useUnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery>;
export type UnaccreditedSellerByCompanyIdTransactionNotificationsCountLazyQueryHookResult = ReturnType<typeof useUnaccreditedSellerByCompanyIdTransactionNotificationsCountLazyQuery>;
export type UnaccreditedSellerByCompanyIdTransactionNotificationsCountQueryResult = Apollo.QueryResult<UnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery, UnaccreditedSellerByCompanyIdTransactionNotificationsCountQueryVariables>;
export const AcceptInvitationDocument = gql`
    mutation acceptInvitation($input: AcceptInvitationInput!) {
  acceptInvitation(input: $input) {
    session {
      ...FullSession
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullSessionFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const AddToWatchlistDocument = gql`
    mutation addToWatchlist($companyId: ID!) {
  addToWatchlist(companyId: $companyId) {
    user {
      id
      watchlist {
        id
      }
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type AddToWatchlistMutationFn = Apollo.MutationFunction<AddToWatchlistMutation, AddToWatchlistMutationVariables>;

/**
 * __useAddToWatchlistMutation__
 *
 * To run a mutation, you first call `useAddToWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToWatchlistMutation, { data, loading, error }] = useAddToWatchlistMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddToWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<AddToWatchlistMutation, AddToWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToWatchlistMutation, AddToWatchlistMutationVariables>(AddToWatchlistDocument, options);
      }
export type AddToWatchlistMutationHookResult = ReturnType<typeof useAddToWatchlistMutation>;
export type AddToWatchlistMutationResult = Apollo.MutationResult<AddToWatchlistMutation>;
export type AddToWatchlistMutationOptions = Apollo.BaseMutationOptions<AddToWatchlistMutation, AddToWatchlistMutationVariables>;
export const AnswerAccreditationQuestionsNewDocument = gql`
    mutation answerAccreditationQuestionsNew($input: AnswerAccreditationQuestionsInput!) {
  answerAccreditationQuestionsNew(input: $input) {
    user {
      ...VeryBasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${VeryBasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type AnswerAccreditationQuestionsNewMutationFn = Apollo.MutationFunction<AnswerAccreditationQuestionsNewMutation, AnswerAccreditationQuestionsNewMutationVariables>;

/**
 * __useAnswerAccreditationQuestionsNewMutation__
 *
 * To run a mutation, you first call `useAnswerAccreditationQuestionsNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerAccreditationQuestionsNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerAccreditationQuestionsNewMutation, { data, loading, error }] = useAnswerAccreditationQuestionsNewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnswerAccreditationQuestionsNewMutation(baseOptions?: Apollo.MutationHookOptions<AnswerAccreditationQuestionsNewMutation, AnswerAccreditationQuestionsNewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnswerAccreditationQuestionsNewMutation, AnswerAccreditationQuestionsNewMutationVariables>(AnswerAccreditationQuestionsNewDocument, options);
      }
export type AnswerAccreditationQuestionsNewMutationHookResult = ReturnType<typeof useAnswerAccreditationQuestionsNewMutation>;
export type AnswerAccreditationQuestionsNewMutationResult = Apollo.MutationResult<AnswerAccreditationQuestionsNewMutation>;
export type AnswerAccreditationQuestionsNewMutationOptions = Apollo.BaseMutationOptions<AnswerAccreditationQuestionsNewMutation, AnswerAccreditationQuestionsNewMutationVariables>;
export const CancelInvitationDocument = gql`
    mutation cancelInvitation($id: ID!) {
  cancelInvitation(id: $id) {
    invitation {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type CancelInvitationMutationFn = Apollo.MutationFunction<CancelInvitationMutation, CancelInvitationMutationVariables>;

/**
 * __useCancelInvitationMutation__
 *
 * To run a mutation, you first call `useCancelInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInvitationMutation, { data, loading, error }] = useCancelInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CancelInvitationMutation, CancelInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelInvitationMutation, CancelInvitationMutationVariables>(CancelInvitationDocument, options);
      }
export type CancelInvitationMutationHookResult = ReturnType<typeof useCancelInvitationMutation>;
export type CancelInvitationMutationResult = Apollo.MutationResult<CancelInvitationMutation>;
export type CancelInvitationMutationOptions = Apollo.BaseMutationOptions<CancelInvitationMutation, CancelInvitationMutationVariables>;
export const ChangeInstitutionUserActiveStatusDocument = gql`
    mutation changeInstitutionUserActiveStatus($userId: ID!, $active: Boolean!) {
  changeUserActiveStatus(userId: $userId, active: $active) {
    user {
      ...BasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type ChangeInstitutionUserActiveStatusMutationFn = Apollo.MutationFunction<ChangeInstitutionUserActiveStatusMutation, ChangeInstitutionUserActiveStatusMutationVariables>;

/**
 * __useChangeInstitutionUserActiveStatusMutation__
 *
 * To run a mutation, you first call `useChangeInstitutionUserActiveStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeInstitutionUserActiveStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeInstitutionUserActiveStatusMutation, { data, loading, error }] = useChangeInstitutionUserActiveStatusMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useChangeInstitutionUserActiveStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeInstitutionUserActiveStatusMutation, ChangeInstitutionUserActiveStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeInstitutionUserActiveStatusMutation, ChangeInstitutionUserActiveStatusMutationVariables>(ChangeInstitutionUserActiveStatusDocument, options);
      }
export type ChangeInstitutionUserActiveStatusMutationHookResult = ReturnType<typeof useChangeInstitutionUserActiveStatusMutation>;
export type ChangeInstitutionUserActiveStatusMutationResult = Apollo.MutationResult<ChangeInstitutionUserActiveStatusMutation>;
export type ChangeInstitutionUserActiveStatusMutationOptions = Apollo.BaseMutationOptions<ChangeInstitutionUserActiveStatusMutation, ChangeInstitutionUserActiveStatusMutationVariables>;
export const CreateFileUploadDocument = gql`
    mutation createFileUpload($input: FileUploadInput!) {
  createFileUpload(input: $input) {
    fileUpload {
      id
      filename
      url
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type CreateFileUploadMutationFn = Apollo.MutationFunction<CreateFileUploadMutation, CreateFileUploadMutationVariables>;

/**
 * __useCreateFileUploadMutation__
 *
 * To run a mutation, you first call `useCreateFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileUploadMutation, { data, loading, error }] = useCreateFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileUploadMutation, CreateFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileUploadMutation, CreateFileUploadMutationVariables>(CreateFileUploadDocument, options);
      }
export type CreateFileUploadMutationHookResult = ReturnType<typeof useCreateFileUploadMutation>;
export type CreateFileUploadMutationResult = Apollo.MutationResult<CreateFileUploadMutation>;
export type CreateFileUploadMutationOptions = Apollo.BaseMutationOptions<CreateFileUploadMutation, CreateFileUploadMutationVariables>;
export const CreateInstitutionDocument = gql`
    mutation createInstitution($input: CreateInstitutionInput!) {
  createInstitution(input: $input) {
    institution {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type CreateInstitutionMutationFn = Apollo.MutationFunction<CreateInstitutionMutation, CreateInstitutionMutationVariables>;

/**
 * __useCreateInstitutionMutation__
 *
 * To run a mutation, you first call `useCreateInstitutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstitutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstitutionMutation, { data, loading, error }] = useCreateInstitutionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInstitutionMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstitutionMutation, CreateInstitutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstitutionMutation, CreateInstitutionMutationVariables>(CreateInstitutionDocument, options);
      }
export type CreateInstitutionMutationHookResult = ReturnType<typeof useCreateInstitutionMutation>;
export type CreateInstitutionMutationResult = Apollo.MutationResult<CreateInstitutionMutation>;
export type CreateInstitutionMutationOptions = Apollo.BaseMutationOptions<CreateInstitutionMutation, CreateInstitutionMutationVariables>;
export const GenerateTransloaditParamsDocument = gql`
    mutation generateTransloaditParams($templateKind: TransloaditTemplateKind!) {
  generateTransloaditParams(templateKind: $templateKind) {
    signature
    params
  }
}
    `;
export type GenerateTransloaditParamsMutationFn = Apollo.MutationFunction<GenerateTransloaditParamsMutation, GenerateTransloaditParamsMutationVariables>;

/**
 * __useGenerateTransloaditParamsMutation__
 *
 * To run a mutation, you first call `useGenerateTransloaditParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTransloaditParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTransloaditParamsMutation, { data, loading, error }] = useGenerateTransloaditParamsMutation({
 *   variables: {
 *      templateKind: // value for 'templateKind'
 *   },
 * });
 */
export function useGenerateTransloaditParamsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTransloaditParamsMutation, GenerateTransloaditParamsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTransloaditParamsMutation, GenerateTransloaditParamsMutationVariables>(GenerateTransloaditParamsDocument, options);
      }
export type GenerateTransloaditParamsMutationHookResult = ReturnType<typeof useGenerateTransloaditParamsMutation>;
export type GenerateTransloaditParamsMutationResult = Apollo.MutationResult<GenerateTransloaditParamsMutation>;
export type GenerateTransloaditParamsMutationOptions = Apollo.BaseMutationOptions<GenerateTransloaditParamsMutation, GenerateTransloaditParamsMutationVariables>;
export const InstitutionSuitabilityDocument = gql`
    mutation institutionSuitability($institutionId: ID!, $updateInstitutionInput: UpdateInstitutionInput!, $answers: [InvestmentGoalAnswerInput!]!) {
  updateInstitution(institutionId: $institutionId, input: $updateInstitutionInput) {
    institution {
      id
    }
    errors {
      ...FullInputError
    }
  }
  answerInvestmentGoals(answers: $answers) {
    user {
      ...VeryBasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}
${VeryBasicUserFragmentDoc}`;
export type InstitutionSuitabilityMutationFn = Apollo.MutationFunction<InstitutionSuitabilityMutation, InstitutionSuitabilityMutationVariables>;

/**
 * __useInstitutionSuitabilityMutation__
 *
 * To run a mutation, you first call `useInstitutionSuitabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionSuitabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionSuitabilityMutation, { data, loading, error }] = useInstitutionSuitabilityMutation({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *      updateInstitutionInput: // value for 'updateInstitutionInput'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useInstitutionSuitabilityMutation(baseOptions?: Apollo.MutationHookOptions<InstitutionSuitabilityMutation, InstitutionSuitabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InstitutionSuitabilityMutation, InstitutionSuitabilityMutationVariables>(InstitutionSuitabilityDocument, options);
      }
export type InstitutionSuitabilityMutationHookResult = ReturnType<typeof useInstitutionSuitabilityMutation>;
export type InstitutionSuitabilityMutationResult = Apollo.MutationResult<InstitutionSuitabilityMutation>;
export type InstitutionSuitabilityMutationOptions = Apollo.BaseMutationOptions<InstitutionSuitabilityMutation, InstitutionSuitabilityMutationVariables>;
export const AcceptDiscussionRequestDocument = gql`
    mutation acceptDiscussionRequest($discussionId: ID!) {
  acceptDiscussionRequest(discussionId: $discussionId) {
    discussion {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type AcceptDiscussionRequestMutationFn = Apollo.MutationFunction<AcceptDiscussionRequestMutation, AcceptDiscussionRequestMutationVariables>;

/**
 * __useAcceptDiscussionRequestMutation__
 *
 * To run a mutation, you first call `useAcceptDiscussionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptDiscussionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptDiscussionRequestMutation, { data, loading, error }] = useAcceptDiscussionRequestMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *   },
 * });
 */
export function useAcceptDiscussionRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptDiscussionRequestMutation, AcceptDiscussionRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptDiscussionRequestMutation, AcceptDiscussionRequestMutationVariables>(AcceptDiscussionRequestDocument, options);
      }
export type AcceptDiscussionRequestMutationHookResult = ReturnType<typeof useAcceptDiscussionRequestMutation>;
export type AcceptDiscussionRequestMutationResult = Apollo.MutationResult<AcceptDiscussionRequestMutation>;
export type AcceptDiscussionRequestMutationOptions = Apollo.BaseMutationOptions<AcceptDiscussionRequestMutation, AcceptDiscussionRequestMutationVariables>;
export const MarkDiscussionAsReadDocument = gql`
    mutation markDiscussionAsRead($discussionId: ID!) {
  markDiscussionAsRead(discussionId: $discussionId) {
    discussion {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type MarkDiscussionAsReadMutationFn = Apollo.MutationFunction<MarkDiscussionAsReadMutation, MarkDiscussionAsReadMutationVariables>;

/**
 * __useMarkDiscussionAsReadMutation__
 *
 * To run a mutation, you first call `useMarkDiscussionAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDiscussionAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDiscussionAsReadMutation, { data, loading, error }] = useMarkDiscussionAsReadMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *   },
 * });
 */
export function useMarkDiscussionAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkDiscussionAsReadMutation, MarkDiscussionAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkDiscussionAsReadMutation, MarkDiscussionAsReadMutationVariables>(MarkDiscussionAsReadDocument, options);
      }
export type MarkDiscussionAsReadMutationHookResult = ReturnType<typeof useMarkDiscussionAsReadMutation>;
export type MarkDiscussionAsReadMutationResult = Apollo.MutationResult<MarkDiscussionAsReadMutation>;
export type MarkDiscussionAsReadMutationOptions = Apollo.BaseMutationOptions<MarkDiscussionAsReadMutation, MarkDiscussionAsReadMutationVariables>;
export const MarkMessageAsReadDocument = gql`
    mutation markMessageAsRead($messageId: ID!) {
  markMessageAsRead(messageId: $messageId) {
    message {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type MarkMessageAsReadMutationFn = Apollo.MutationFunction<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>;

/**
 * __useMarkMessageAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageAsReadMutation, { data, loading, error }] = useMarkMessageAsReadMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useMarkMessageAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>(MarkMessageAsReadDocument, options);
      }
export type MarkMessageAsReadMutationHookResult = ReturnType<typeof useMarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationResult = Apollo.MutationResult<MarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationOptions = Apollo.BaseMutationOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($input: SendMessageInput!) {
  sendMessage(input: $input) {
    message {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const RecordTltAlternateSignerSignatureDocument = gql`
    mutation recordTltAlternateSignerSignature($token: String!) {
  recordTltAlternateSignerSignature(token: $token) {
    document {
      id
    }
  }
}
    `;
export type RecordTltAlternateSignerSignatureMutationFn = Apollo.MutationFunction<RecordTltAlternateSignerSignatureMutation, RecordTltAlternateSignerSignatureMutationVariables>;

/**
 * __useRecordTltAlternateSignerSignatureMutation__
 *
 * To run a mutation, you first call `useRecordTltAlternateSignerSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordTltAlternateSignerSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordTltAlternateSignerSignatureMutation, { data, loading, error }] = useRecordTltAlternateSignerSignatureMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRecordTltAlternateSignerSignatureMutation(baseOptions?: Apollo.MutationHookOptions<RecordTltAlternateSignerSignatureMutation, RecordTltAlternateSignerSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordTltAlternateSignerSignatureMutation, RecordTltAlternateSignerSignatureMutationVariables>(RecordTltAlternateSignerSignatureDocument, options);
      }
export type RecordTltAlternateSignerSignatureMutationHookResult = ReturnType<typeof useRecordTltAlternateSignerSignatureMutation>;
export type RecordTltAlternateSignerSignatureMutationResult = Apollo.MutationResult<RecordTltAlternateSignerSignatureMutation>;
export type RecordTltAlternateSignerSignatureMutationOptions = Apollo.BaseMutationOptions<RecordTltAlternateSignerSignatureMutation, RecordTltAlternateSignerSignatureMutationVariables>;
export const RecordTransactionDocumentSignedDocument = gql`
    mutation recordTransactionDocumentSigned($input: RecordTransactionDocumentSignedInput!) {
  recordTransactionDocumentSigned(input: $input) {
    document {
      id
    }
  }
}
    `;
export type RecordTransactionDocumentSignedMutationFn = Apollo.MutationFunction<RecordTransactionDocumentSignedMutation, RecordTransactionDocumentSignedMutationVariables>;

/**
 * __useRecordTransactionDocumentSignedMutation__
 *
 * To run a mutation, you first call `useRecordTransactionDocumentSignedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordTransactionDocumentSignedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordTransactionDocumentSignedMutation, { data, loading, error }] = useRecordTransactionDocumentSignedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordTransactionDocumentSignedMutation(baseOptions?: Apollo.MutationHookOptions<RecordTransactionDocumentSignedMutation, RecordTransactionDocumentSignedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordTransactionDocumentSignedMutation, RecordTransactionDocumentSignedMutationVariables>(RecordTransactionDocumentSignedDocument, options);
      }
export type RecordTransactionDocumentSignedMutationHookResult = ReturnType<typeof useRecordTransactionDocumentSignedMutation>;
export type RecordTransactionDocumentSignedMutationResult = Apollo.MutationResult<RecordTransactionDocumentSignedMutation>;
export type RecordTransactionDocumentSignedMutationOptions = Apollo.BaseMutationOptions<RecordTransactionDocumentSignedMutation, RecordTransactionDocumentSignedMutationVariables>;
export const RemoveFromWatchlistDocument = gql`
    mutation removeFromWatchlist($companyId: ID!) {
  removeFromWatchlist(companyId: $companyId) {
    user {
      id
      watchlist {
        id
      }
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type RemoveFromWatchlistMutationFn = Apollo.MutationFunction<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables>;

/**
 * __useRemoveFromWatchlistMutation__
 *
 * To run a mutation, you first call `useRemoveFromWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromWatchlistMutation, { data, loading, error }] = useRemoveFromWatchlistMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRemoveFromWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables>(RemoveFromWatchlistDocument, options);
      }
export type RemoveFromWatchlistMutationHookResult = ReturnType<typeof useRemoveFromWatchlistMutation>;
export type RemoveFromWatchlistMutationResult = Apollo.MutationResult<RemoveFromWatchlistMutation>;
export type RemoveFromWatchlistMutationOptions = Apollo.BaseMutationOptions<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation resendInvitation($id: ID!) {
  resendInvitation(id: $id) {
    invitation {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type ResendInvitationMutationFn = Apollo.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, options);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const SendInvitationDocument = gql`
    mutation sendInvitation($input: SendInvitationInput!) {
  sendInvitation(input: $input) {
    invitation {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type SendInvitationMutationFn = Apollo.MutationFunction<SendInvitationMutation, SendInvitationMutationVariables>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendInvitationMutation, SendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvitationMutation, SendInvitationMutationVariables>(SendInvitationDocument, options);
      }
export type SendInvitationMutationHookResult = ReturnType<typeof useSendInvitationMutation>;
export type SendInvitationMutationResult = Apollo.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<SendInvitationMutation, SendInvitationMutationVariables>;
export const AddEntityDocument = gql`
    mutation addEntity($input: AddEntityInput!) {
  addEntity(input: $input) {
    entity {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type AddEntityMutationFn = Apollo.MutationFunction<AddEntityMutation, AddEntityMutationVariables>;

/**
 * __useAddEntityMutation__
 *
 * To run a mutation, you first call `useAddEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEntityMutation, { data, loading, error }] = useAddEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEntityMutation(baseOptions?: Apollo.MutationHookOptions<AddEntityMutation, AddEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEntityMutation, AddEntityMutationVariables>(AddEntityDocument, options);
      }
export type AddEntityMutationHookResult = ReturnType<typeof useAddEntityMutation>;
export type AddEntityMutationResult = Apollo.MutationResult<AddEntityMutation>;
export type AddEntityMutationOptions = Apollo.BaseMutationOptions<AddEntityMutation, AddEntityMutationVariables>;
export const LinkEntityDocument = gql`
    mutation linkEntity($input: LinkEntityInput!) {
  linkEntity(input: $input) {
    transaction {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type LinkEntityMutationFn = Apollo.MutationFunction<LinkEntityMutation, LinkEntityMutationVariables>;

/**
 * __useLinkEntityMutation__
 *
 * To run a mutation, you first call `useLinkEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkEntityMutation, { data, loading, error }] = useLinkEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkEntityMutation(baseOptions?: Apollo.MutationHookOptions<LinkEntityMutation, LinkEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkEntityMutation, LinkEntityMutationVariables>(LinkEntityDocument, options);
      }
export type LinkEntityMutationHookResult = ReturnType<typeof useLinkEntityMutation>;
export type LinkEntityMutationResult = Apollo.MutationResult<LinkEntityMutation>;
export type LinkEntityMutationOptions = Apollo.BaseMutationOptions<LinkEntityMutation, LinkEntityMutationVariables>;
export const ArchiveEntityDocument = gql`
    mutation archiveEntity($input: ArchiveEntityInput!) {
  archiveEntity(input: $input) {
    entity {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type ArchiveEntityMutationFn = Apollo.MutationFunction<ArchiveEntityMutation, ArchiveEntityMutationVariables>;

/**
 * __useArchiveEntityMutation__
 *
 * To run a mutation, you first call `useArchiveEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveEntityMutation, { data, loading, error }] = useArchiveEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveEntityMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveEntityMutation, ArchiveEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveEntityMutation, ArchiveEntityMutationVariables>(ArchiveEntityDocument, options);
      }
export type ArchiveEntityMutationHookResult = ReturnType<typeof useArchiveEntityMutation>;
export type ArchiveEntityMutationResult = Apollo.MutationResult<ArchiveEntityMutation>;
export type ArchiveEntityMutationOptions = Apollo.BaseMutationOptions<ArchiveEntityMutation, ArchiveEntityMutationVariables>;
export const UpdateInstitutionDocument = gql`
    mutation updateInstitution($institutionId: ID!, $input: UpdateInstitutionInput!) {
  updateInstitution(institutionId: $institutionId, input: $input) {
    institution {
      id
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullInputErrorFragmentDoc}`;
export type UpdateInstitutionMutationFn = Apollo.MutationFunction<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>;

/**
 * __useUpdateInstitutionMutation__
 *
 * To run a mutation, you first call `useUpdateInstitutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstitutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstitutionMutation, { data, loading, error }] = useUpdateInstitutionMutation({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInstitutionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>(UpdateInstitutionDocument, options);
      }
export type UpdateInstitutionMutationHookResult = ReturnType<typeof useUpdateInstitutionMutation>;
export type UpdateInstitutionMutationResult = Apollo.MutationResult<UpdateInstitutionMutation>;
export type UpdateInstitutionMutationOptions = Apollo.BaseMutationOptions<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    session {
      ...FullSession
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullSessionFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RequestEmailVerificationDocument = gql`
    mutation requestEmailVerification {
  requestEmailVerification {
    user {
      ...BasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type RequestEmailVerificationMutationFn = Apollo.MutationFunction<RequestEmailVerificationMutation, RequestEmailVerificationMutationVariables>;

/**
 * __useRequestEmailVerificationMutation__
 *
 * To run a mutation, you first call `useRequestEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailVerificationMutation, { data, loading, error }] = useRequestEmailVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<RequestEmailVerificationMutation, RequestEmailVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestEmailVerificationMutation, RequestEmailVerificationMutationVariables>(RequestEmailVerificationDocument, options);
      }
export type RequestEmailVerificationMutationHookResult = ReturnType<typeof useRequestEmailVerificationMutation>;
export type RequestEmailVerificationMutationResult = Apollo.MutationResult<RequestEmailVerificationMutation>;
export type RequestEmailVerificationMutationOptions = Apollo.BaseMutationOptions<RequestEmailVerificationMutation, RequestEmailVerificationMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation requestResetPassword($email: String!) {
  requestResetPassword(email: $email)
}
    `;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, options);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    user {
      ...BasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignupDocument = gql`
    mutation signup($input: SignupInput!) {
  signup(input: $input) {
    session {
      ...FullSession
    }
    errors {
      ...FullInputError
    }
  }
}
    ${FullSessionFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const UpdateAgreedToCustomerAgreementDocument = gql`
    mutation updateAgreedToCustomerAgreement($agreedToCustomerAgreement: Boolean!) {
  updateAgreedToCustomerAgreement(
    agreedToCustomerAgreement: $agreedToCustomerAgreement
  ) {
    user {
      ...BasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type UpdateAgreedToCustomerAgreementMutationFn = Apollo.MutationFunction<UpdateAgreedToCustomerAgreementMutation, UpdateAgreedToCustomerAgreementMutationVariables>;

/**
 * __useUpdateAgreedToCustomerAgreementMutation__
 *
 * To run a mutation, you first call `useUpdateAgreedToCustomerAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgreedToCustomerAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgreedToCustomerAgreementMutation, { data, loading, error }] = useUpdateAgreedToCustomerAgreementMutation({
 *   variables: {
 *      agreedToCustomerAgreement: // value for 'agreedToCustomerAgreement'
 *   },
 * });
 */
export function useUpdateAgreedToCustomerAgreementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgreedToCustomerAgreementMutation, UpdateAgreedToCustomerAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgreedToCustomerAgreementMutation, UpdateAgreedToCustomerAgreementMutationVariables>(UpdateAgreedToCustomerAgreementDocument, options);
      }
export type UpdateAgreedToCustomerAgreementMutationHookResult = ReturnType<typeof useUpdateAgreedToCustomerAgreementMutation>;
export type UpdateAgreedToCustomerAgreementMutationResult = Apollo.MutationResult<UpdateAgreedToCustomerAgreementMutation>;
export type UpdateAgreedToCustomerAgreementMutationOptions = Apollo.BaseMutationOptions<UpdateAgreedToCustomerAgreementMutation, UpdateAgreedToCustomerAgreementMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input) {
    user {
      ...BasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      ...BasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserEmailDocument = gql`
    mutation updateUserEmail($email: String!) {
  updateUserEmail(email: $email) {
    user {
      ...BasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type UpdateUserEmailMutationFn = Apollo.MutationFunction<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>;

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>(UpdateUserEmailDocument, options);
      }
export type UpdateUserEmailMutationHookResult = ReturnType<typeof useUpdateUserEmailMutation>;
export type UpdateUserEmailMutationResult = Apollo.MutationResult<UpdateUserEmailMutation>;
export type UpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>;
export const UpdateUserPhoneNumberDocument = gql`
    mutation updateUserPhoneNumber($phoneNumber: String!) {
  updateUserPhoneNumber(phoneNumber: $phoneNumber) {
    user {
      ...BasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type UpdateUserPhoneNumberMutationFn = Apollo.MutationFunction<UpdateUserPhoneNumberMutation, UpdateUserPhoneNumberMutationVariables>;

/**
 * __useUpdateUserPhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhoneNumberMutation, { data, loading, error }] = useUpdateUserPhoneNumberMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateUserPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPhoneNumberMutation, UpdateUserPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPhoneNumberMutation, UpdateUserPhoneNumberMutationVariables>(UpdateUserPhoneNumberDocument, options);
      }
export type UpdateUserPhoneNumberMutationHookResult = ReturnType<typeof useUpdateUserPhoneNumberMutation>;
export type UpdateUserPhoneNumberMutationResult = Apollo.MutationResult<UpdateUserPhoneNumberMutation>;
export type UpdateUserPhoneNumberMutationOptions = Apollo.BaseMutationOptions<UpdateUserPhoneNumberMutation, UpdateUserPhoneNumberMutationVariables>;
export const VerifyEmailTokenDocument = gql`
    mutation verifyEmailToken($token: String!) {
  verifyEmailToken(token: $token) {
    user {
      ...BasicUser
    }
    errors {
      ...FullInputError
    }
  }
}
    ${BasicUserFragmentDoc}
${FullInputErrorFragmentDoc}`;
export type VerifyEmailTokenMutationFn = Apollo.MutationFunction<VerifyEmailTokenMutation, VerifyEmailTokenMutationVariables>;

/**
 * __useVerifyEmailTokenMutation__
 *
 * To run a mutation, you first call `useVerifyEmailTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailTokenMutation, { data, loading, error }] = useVerifyEmailTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailTokenMutation, VerifyEmailTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailTokenMutation, VerifyEmailTokenMutationVariables>(VerifyEmailTokenDocument, options);
      }
export type VerifyEmailTokenMutationHookResult = ReturnType<typeof useVerifyEmailTokenMutation>;
export type VerifyEmailTokenMutationResult = Apollo.MutationResult<VerifyEmailTokenMutation>;
export type VerifyEmailTokenMutationOptions = Apollo.BaseMutationOptions<VerifyEmailTokenMutation, VerifyEmailTokenMutationVariables>;
export const ViewTltAlternateSignerTransactionDocumentDocument = gql`
    mutation viewTltAlternateSignerTransactionDocument($token: String!) {
  viewTltAlternateSignerTransactionDocument(token: $token) {
    url
  }
}
    `;
export type ViewTltAlternateSignerTransactionDocumentMutationFn = Apollo.MutationFunction<ViewTltAlternateSignerTransactionDocumentMutation, ViewTltAlternateSignerTransactionDocumentMutationVariables>;

/**
 * __useViewTltAlternateSignerTransactionDocumentMutation__
 *
 * To run a mutation, you first call `useViewTltAlternateSignerTransactionDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewTltAlternateSignerTransactionDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewTltAlternateSignerTransactionDocumentMutation, { data, loading, error }] = useViewTltAlternateSignerTransactionDocumentMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useViewTltAlternateSignerTransactionDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ViewTltAlternateSignerTransactionDocumentMutation, ViewTltAlternateSignerTransactionDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ViewTltAlternateSignerTransactionDocumentMutation, ViewTltAlternateSignerTransactionDocumentMutationVariables>(ViewTltAlternateSignerTransactionDocumentDocument, options);
      }
export type ViewTltAlternateSignerTransactionDocumentMutationHookResult = ReturnType<typeof useViewTltAlternateSignerTransactionDocumentMutation>;
export type ViewTltAlternateSignerTransactionDocumentMutationResult = Apollo.MutationResult<ViewTltAlternateSignerTransactionDocumentMutation>;
export type ViewTltAlternateSignerTransactionDocumentMutationOptions = Apollo.BaseMutationOptions<ViewTltAlternateSignerTransactionDocumentMutation, ViewTltAlternateSignerTransactionDocumentMutationVariables>;
export const ViewTransactionDocumentDocument = gql`
    mutation viewTransactionDocument($transactionId: ID!) {
  viewTransactionDocument(transactionId: $transactionId)
}
    `;
export type ViewTransactionDocumentMutationFn = Apollo.MutationFunction<ViewTransactionDocumentMutation, ViewTransactionDocumentMutationVariables>;

/**
 * __useViewTransactionDocumentMutation__
 *
 * To run a mutation, you first call `useViewTransactionDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewTransactionDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewTransactionDocumentMutation, { data, loading, error }] = useViewTransactionDocumentMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useViewTransactionDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ViewTransactionDocumentMutation, ViewTransactionDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ViewTransactionDocumentMutation, ViewTransactionDocumentMutationVariables>(ViewTransactionDocumentDocument, options);
      }
export type ViewTransactionDocumentMutationHookResult = ReturnType<typeof useViewTransactionDocumentMutation>;
export type ViewTransactionDocumentMutationResult = Apollo.MutationResult<ViewTransactionDocumentMutation>;
export type ViewTransactionDocumentMutationOptions = Apollo.BaseMutationOptions<ViewTransactionDocumentMutation, ViewTransactionDocumentMutationVariables>;
export const AllIndustriesDocument = gql`
    query allIndustries {
  allIndustries {
    name
    id
  }
}
    `;

/**
 * __useAllIndustriesQuery__
 *
 * To run a query within a React component, call `useAllIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<AllIndustriesQuery, AllIndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllIndustriesQuery, AllIndustriesQueryVariables>(AllIndustriesDocument, options);
      }
export function useAllIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllIndustriesQuery, AllIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllIndustriesQuery, AllIndustriesQueryVariables>(AllIndustriesDocument, options);
        }
export type AllIndustriesQueryHookResult = ReturnType<typeof useAllIndustriesQuery>;
export type AllIndustriesLazyQueryHookResult = ReturnType<typeof useAllIndustriesLazyQuery>;
export type AllIndustriesQueryResult = Apollo.QueryResult<AllIndustriesQuery, AllIndustriesQueryVariables>;
export const CompaniesInvestorsDocument = gql`
    query companiesInvestors {
  companiesInvestors {
    id
    name
  }
}
    `;

/**
 * __useCompaniesInvestorsQuery__
 *
 * To run a query within a React component, call `useCompaniesInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesInvestorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesInvestorsQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesInvestorsQuery, CompaniesInvestorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesInvestorsQuery, CompaniesInvestorsQueryVariables>(CompaniesInvestorsDocument, options);
      }
export function useCompaniesInvestorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesInvestorsQuery, CompaniesInvestorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesInvestorsQuery, CompaniesInvestorsQueryVariables>(CompaniesInvestorsDocument, options);
        }
export type CompaniesInvestorsQueryHookResult = ReturnType<typeof useCompaniesInvestorsQuery>;
export type CompaniesInvestorsLazyQueryHookResult = ReturnType<typeof useCompaniesInvestorsLazyQuery>;
export type CompaniesInvestorsQueryResult = Apollo.QueryResult<CompaniesInvestorsQuery, CompaniesInvestorsQueryVariables>;
export const CompaniesIndustriesDocument = gql`
    query companiesIndustries {
  companiesIndustries {
    name
    id
  }
}
    `;

/**
 * __useCompaniesIndustriesQuery__
 *
 * To run a query within a React component, call `useCompaniesIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesIndustriesQuery, CompaniesIndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesIndustriesQuery, CompaniesIndustriesQueryVariables>(CompaniesIndustriesDocument, options);
      }
export function useCompaniesIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesIndustriesQuery, CompaniesIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesIndustriesQuery, CompaniesIndustriesQueryVariables>(CompaniesIndustriesDocument, options);
        }
export type CompaniesIndustriesQueryHookResult = ReturnType<typeof useCompaniesIndustriesQuery>;
export type CompaniesIndustriesLazyQueryHookResult = ReturnType<typeof useCompaniesIndustriesLazyQuery>;
export type CompaniesIndustriesQueryResult = Apollo.QueryResult<CompaniesIndustriesQuery, CompaniesIndustriesQueryVariables>;
export const CountriesDocument = gql`
    query countries($sorted: Boolean) {
  countries(sorted: $sorted) {
    ...BasicCountry
  }
}
    ${BasicCountryFragmentDoc}`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *      sorted: // value for 'sorted'
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const MarketActivityDocument = gql`
    query marketActivity($limit: Int!) {
  marketActivity {
    mostActiveCompanies(limit: $limit) {
      id
      name
      activity {
        numOpenListings
        numOpenStandingBidsAndActiveBids
      }
    }
  }
}
    `;

/**
 * __useMarketActivityQuery__
 *
 * To run a query within a React component, call `useMarketActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketActivityQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMarketActivityQuery(baseOptions: Apollo.QueryHookOptions<MarketActivityQuery, MarketActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketActivityQuery, MarketActivityQueryVariables>(MarketActivityDocument, options);
      }
export function useMarketActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketActivityQuery, MarketActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketActivityQuery, MarketActivityQueryVariables>(MarketActivityDocument, options);
        }
export type MarketActivityQueryHookResult = ReturnType<typeof useMarketActivityQuery>;
export type MarketActivityLazyQueryHookResult = ReturnType<typeof useMarketActivityLazyQuery>;
export type MarketActivityQueryResult = Apollo.QueryResult<MarketActivityQuery, MarketActivityQueryVariables>;
export const MostActiveCompaniesDocument = gql`
    query mostActiveCompanies($limit: Int!) {
  mostActiveCompanies(limit: $limit) {
    ...DashboardMostActiveCompaniesCompany
  }
}
    ${DashboardMostActiveCompaniesCompanyFragmentDoc}`;

/**
 * __useMostActiveCompaniesQuery__
 *
 * To run a query within a React component, call `useMostActiveCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostActiveCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostActiveCompaniesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMostActiveCompaniesQuery(baseOptions: Apollo.QueryHookOptions<MostActiveCompaniesQuery, MostActiveCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MostActiveCompaniesQuery, MostActiveCompaniesQueryVariables>(MostActiveCompaniesDocument, options);
      }
export function useMostActiveCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MostActiveCompaniesQuery, MostActiveCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MostActiveCompaniesQuery, MostActiveCompaniesQueryVariables>(MostActiveCompaniesDocument, options);
        }
export type MostActiveCompaniesQueryHookResult = ReturnType<typeof useMostActiveCompaniesQuery>;
export type MostActiveCompaniesLazyQueryHookResult = ReturnType<typeof useMostActiveCompaniesLazyQuery>;
export type MostActiveCompaniesQueryResult = Apollo.QueryResult<MostActiveCompaniesQuery, MostActiveCompaniesQueryVariables>;
export const ValidShareSeriesDocument = gql`
    query validShareSeries {
  validShareSeries
}
    `;

/**
 * __useValidShareSeriesQuery__
 *
 * To run a query within a React component, call `useValidShareSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidShareSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidShareSeriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useValidShareSeriesQuery(baseOptions?: Apollo.QueryHookOptions<ValidShareSeriesQuery, ValidShareSeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidShareSeriesQuery, ValidShareSeriesQueryVariables>(ValidShareSeriesDocument, options);
      }
export function useValidShareSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidShareSeriesQuery, ValidShareSeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidShareSeriesQuery, ValidShareSeriesQueryVariables>(ValidShareSeriesDocument, options);
        }
export type ValidShareSeriesQueryHookResult = ReturnType<typeof useValidShareSeriesQuery>;
export type ValidShareSeriesLazyQueryHookResult = ReturnType<typeof useValidShareSeriesLazyQuery>;
export type ValidShareSeriesQueryResult = Apollo.QueryResult<ValidShareSeriesQuery, ValidShareSeriesQueryVariables>;
export const OnTransactionDocumentReadyDocument = gql`
    subscription onTransactionDocumentReady($transactionId: ID!) {
  onTransactionDocumentReady(transactionId: $transactionId) {
    id
  }
}
    `;

/**
 * __useOnTransactionDocumentReadySubscription__
 *
 * To run a query within a React component, call `useOnTransactionDocumentReadySubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTransactionDocumentReadySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTransactionDocumentReadySubscription({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useOnTransactionDocumentReadySubscription(baseOptions: Apollo.SubscriptionHookOptions<OnTransactionDocumentReadySubscription, OnTransactionDocumentReadySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnTransactionDocumentReadySubscription, OnTransactionDocumentReadySubscriptionVariables>(OnTransactionDocumentReadyDocument, options);
      }
export type OnTransactionDocumentReadySubscriptionHookResult = ReturnType<typeof useOnTransactionDocumentReadySubscription>;
export type OnTransactionDocumentReadySubscriptionResult = Apollo.SubscriptionResult<OnTransactionDocumentReadySubscription>;
export const OnboardingCurrentActorDocument = gql`
    query onboardingCurrentActor {
  currentActor {
    ...OnboardingUser
  }
}
    ${OnboardingUserFragmentDoc}`;

/**
 * __useOnboardingCurrentActorQuery__
 *
 * To run a query within a React component, call `useOnboardingCurrentActorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCurrentActorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingCurrentActorQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingCurrentActorQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingCurrentActorQuery, OnboardingCurrentActorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingCurrentActorQuery, OnboardingCurrentActorQueryVariables>(OnboardingCurrentActorDocument, options);
      }
export function useOnboardingCurrentActorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingCurrentActorQuery, OnboardingCurrentActorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingCurrentActorQuery, OnboardingCurrentActorQueryVariables>(OnboardingCurrentActorDocument, options);
        }
export type OnboardingCurrentActorQueryHookResult = ReturnType<typeof useOnboardingCurrentActorQuery>;
export type OnboardingCurrentActorLazyQueryHookResult = ReturnType<typeof useOnboardingCurrentActorLazyQuery>;
export type OnboardingCurrentActorQueryResult = Apollo.QueryResult<OnboardingCurrentActorQuery, OnboardingCurrentActorQueryVariables>;
export const CreateAccountCollectionFlowDocument = gql`
    mutation createAccountCollectionFlow($countryId: String!, $transactionId: ID!) {
  createBankAccountCollectionFlow(
    countryId: $countryId
    transactionId: $transactionId
  ) {
    accountCollectionFlow {
      clientToken
      counterpartyId
    }
    errors {
      field
      message
      type
    }
  }
}
    `;
export type CreateAccountCollectionFlowMutationFn = Apollo.MutationFunction<CreateAccountCollectionFlowMutation, CreateAccountCollectionFlowMutationVariables>;

/**
 * __useCreateAccountCollectionFlowMutation__
 *
 * To run a mutation, you first call `useCreateAccountCollectionFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountCollectionFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountCollectionFlowMutation, { data, loading, error }] = useCreateAccountCollectionFlowMutation({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useCreateAccountCollectionFlowMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountCollectionFlowMutation, CreateAccountCollectionFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountCollectionFlowMutation, CreateAccountCollectionFlowMutationVariables>(CreateAccountCollectionFlowDocument, options);
      }
export type CreateAccountCollectionFlowMutationHookResult = ReturnType<typeof useCreateAccountCollectionFlowMutation>;
export type CreateAccountCollectionFlowMutationResult = Apollo.MutationResult<CreateAccountCollectionFlowMutation>;
export type CreateAccountCollectionFlowMutationOptions = Apollo.BaseMutationOptions<CreateAccountCollectionFlowMutation, CreateAccountCollectionFlowMutationVariables>;
export const TokenExchangeDocument = gql`
    mutation tokenExchange($identityToken: String!) {
  token(input: {identityToken: $identityToken}) {
    session {
      token
      refreshToken
      userId
    }
    errors {
      message
      type
      field
    }
  }
}
    `;
export type TokenExchangeMutationFn = Apollo.MutationFunction<TokenExchangeMutation, TokenExchangeMutationVariables>;

/**
 * __useTokenExchangeMutation__
 *
 * To run a mutation, you first call `useTokenExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenExchangeMutation, { data, loading, error }] = useTokenExchangeMutation({
 *   variables: {
 *      identityToken: // value for 'identityToken'
 *   },
 * });
 */
export function useTokenExchangeMutation(baseOptions?: Apollo.MutationHookOptions<TokenExchangeMutation, TokenExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TokenExchangeMutation, TokenExchangeMutationVariables>(TokenExchangeDocument, options);
      }
export type TokenExchangeMutationHookResult = ReturnType<typeof useTokenExchangeMutation>;
export type TokenExchangeMutationResult = Apollo.MutationResult<TokenExchangeMutation>;
export type TokenExchangeMutationOptions = Apollo.BaseMutationOptions<TokenExchangeMutation, TokenExchangeMutationVariables>;
