import en from "./en";
import account from "./en/account";
import error from "./en/error";
import onboarding from "./en/onboarding";
import postings from "./en/postings";
import transactions from "./en/transactions";
import { LocaleKey, TranslationKey, Resources } from "./types";

export const locales: Record<LocaleKey, Resources> = {
  en: {
    account,
    error,
    onboarding,
    postings,
    translation: en,
    transactions,
  },
};

export type { TranslationKey, Resources };
