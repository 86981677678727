import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Tag,
  Text,
  HStack,
  Stack,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

import { HiiveModalHeader, InfoRow, Note } from "@/components/common";
import { PreqinIcon } from "@/components/icons";
import { CompanyLastRoundDetailsCardCompanyFragment } from "@/gql";
import { formatCapitalRaised, formatLastRoundValue } from "@/utils";
import * as datetime from "@/utils/datetime";

const InvestorList = ({
  investors,
  onOpen,
}: {
  readonly investors: CompanyLastRoundDetailsCardCompanyFragment["investors"];
  readonly onOpen: () => void;
}) => {
  const { t } = useTranslation();
  const maxInvestors = 7;
  if (investors.length === 0) {
    return <> {t(`na`)}</>;
  }
  if (investors.length <= maxInvestors) {
    return <>{investors.map((investor) => investor.name).join(`, `)}</>;
  }
  return (
    <>
      {investors
        .slice(0, maxInvestors)
        .map((investor) => investor.name)
        .join(`, `)}
      {` `}
      <Button variant="boxed-grey-mini" onClick={onOpen}>
        {`+${investors.length - maxInvestors} more`}
      </Button>
    </>
  );
};

const InvestorModal = ({
  investors,
  isOpen,
  onClose,
}: {
  readonly investors: CompanyLastRoundDetailsCardCompanyFragment["investors"];
  readonly isOpen: boolean;
  readonly onClose: () => void;
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl">
    <ModalOverlay />
    <ModalContent>
      <HiiveModalHeader closeModal={onClose}>Investors</HiiveModalHeader>
      <ModalBody>
        {investors.map((investor) => investor.name).join(`, `)}
      </ModalBody>
    </ModalContent>
  </Modal>
);

const CompanyLastRoundDetailsCard = ({
  company,
}: {
  readonly company: CompanyLastRoundDetailsCardCompanyFragment;
}) => {
  const {
    lastRoundSeries,
    lastRoundDate,
    lastRoundPricePerShare,
    lastRoundRaised,
    totalRaised,
    lastRoundPostValuation,
    investors,
    industries,
  } = company;
  const { t } = useTranslation();

  const capitalRaised = formatCapitalRaised({ lastRoundRaised, totalRaised });
  const lastRoundValue = formatLastRoundValue({
    lastRoundPostValuation,
    lastRoundPricePerShare,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <InvestorModal investors={investors} isOpen={isOpen} onClose={onClose} />
      <Card>
        <CardHeader>
          <HStack justifyContent="space-between">
            <Text as="h5" textStyle="heading-sm">
              {t(`last_round_details`)}
            </Text>
            <Stack
              direction={{ base: `column`, sm: `row` }}
              alignItems="center"
            >
              <Text as="i" textStyle="text-sm">
                {t(`powered_by`)}
              </Text>
              <PreqinIcon mt="0px !important" />
            </Stack>
          </HStack>
        </CardHeader>
        <CardBody>
          <InfoRow isSensitive title={t(`value`)}>
            {lastRoundValue}
          </InfoRow>
          <InfoRow isSensitive title={t(`date`)}>
            {!lastRoundSeries && !lastRoundDate ? (
              t(`na`)
            ) : (
              <>
                {lastRoundDate && datetime.format(`MMMM YYYY`, lastRoundDate)}
                {lastRoundSeries && lastRoundDate ? ` ` : ``}
                {lastRoundSeries && ` (${lastRoundSeries})`}
              </>
            )}
          </InfoRow>
          <InfoRow isSensitive title={t(`capital_raised`)}>
            {capitalRaised}
          </InfoRow>

          <InfoRow isSensitive title={t(`notable_investors`)}>
            <InvestorList investors={investors} onOpen={onOpen} />
          </InfoRow>

          <InfoRow
            _last={{ border: `none` }}
            isSensitive
            title={t(`industries`)}
          >
            {industries.length ? (
              <Flex gap={2} wrap="wrap">
                {industries.map(({ name, id }) => (
                  <Tag variant="blue" key={id}>
                    {name}
                  </Tag>
                ))}
              </Flex>
            ) : (
              t(`na`)
            )}
          </InfoRow>
        </CardBody>
        <CardFooter>
          <Note textStyle="text-sm">
            {t(`last_round_details_description_preqin`)}
          </Note>
        </CardFooter>
      </Card>
    </>
  );
};
export default CompanyLastRoundDetailsCard;
