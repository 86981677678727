/* eslint-disable functional/immutable-data */

/* eslint-disable no-return-assign */
import { useOrganization } from "@frigade/react";
import { useAuthUserOrNull } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { createContext, ReactElement, useEffect } from "react";

import { useRouter } from "next/router";

import { Flex } from "@chakra-ui/react";

import { Loader } from "@/components/common";
import { FeatureFlagsHelper } from "@/components/helpers";
import { NavBar, NavSpacer } from "@/components/navigation";
import {
  CurrentActorProvider,
  KnockProvider,
  ModalProvider,
} from "@/components/providers";
import { useCurrentContextQuery, UserWithInstitutionFragment } from "@/gql";
import {
  canAccessPlatform,
  OnboardingRoutes,
  useCheckAuthenticationToken,
  useIsChangingRoute,
  useIsHiiveConnect,
  useResumeOnboarding,
  useSignOut,
  useSession,
  useToken,
} from "@/hooks";
import {
  useMarketplaceConfiguration,
  useSuitabilityUpfront,
} from "@/hooks/featureFlags";
import { getIsBroker, getIsAppMfaEnabled, constants } from "@/utils";

import Layout from "./Layout";

const { ROUTES } = constants;

interface AuthenticatedLayoutProps {
  readonly children: JSX.Element | readonly JSX.Element[];
  readonly title?: string;
  readonly useCustomLayout?: boolean;
}

const useIdentifyUser = () => {
  const token = useToken();
  const user = useSession();

  useEffect(() => {
    Sentry.setUser({ id: user?.id });
  }, [token, user]);
};

const useCheckAuthenticated = () => {
  const fronteggUser = useAuthUserOrNull();
  const isChangingRoute = useIsChangingRoute();
  const sessionToken = useToken();
  const hiiveToken = useCheckAuthenticationToken();
  const signOut = useSignOut();
  const marketplaceConfigurationEnabled = useMarketplaceConfiguration();
  const session = useSession();

  const isActorOnboarding = !session?.onboardingComplete;

  const shouldSignOutHiiveUser =
    marketplaceConfigurationEnabled &&
    hiiveToken &&
    !isActorOnboarding &&
    !fronteggUser &&
    !isChangingRoute();

  const shouldSignOut =
    shouldSignOutHiiveUser || (!sessionToken && !isChangingRoute);

  useEffect(() => {
    if (shouldSignOut) {
      signOut();
    }
  }, [shouldSignOut]);
};

const useIsOnboarding = () => {
  const router = useRouter();
  const isSUPEnabled = useSuitabilityUpfront();

  const exemptRoutes = [`/terms-and-conditions`];
  const onboardingRoutes = Object.values(OnboardingRoutes) as readonly string[];

  const supOnboardingAccessibleRoutes = [
    `/welcome`,
    `/terms-and-conditions`,
    `/verify-email`,
    `/something-went-wrong`,
    `/page-not-found`,
  ];

  const isOnboarding = isSUPEnabled
    ? supOnboardingAccessibleRoutes.includes(router.asPath)
    : onboardingRoutes.includes(router.asPath) ||
      exemptRoutes.includes(router.asPath);

  return isOnboarding;
};

export const EnforceAppMfaConfigurationContext = createContext(false);

const AuthenticatedProviders = ({
  children,
}: {
  readonly children: ReactElement;
}) => (
  <CurrentActorProvider>
    <ModalProvider>
      <FeatureFlagsHelper />
      <KnockProvider>{children}</KnockProvider>
    </ModalProvider>
  </CurrentActorProvider>
);

const AuthenticatedLayout = ({
  children,
  title,
  useCustomLayout = false,
}: AuthenticatedLayoutProps) => {
  const { data, loading } = useCurrentContextQuery();
  const signOut = useSignOut();
  const { replace, push, pathname } = useRouter();
  const isHiiveConnect = useIsHiiveConnect();
  const isMarketplaceConfigurationEnabled = !!useMarketplaceConfiguration();
  const { setOrganizationIdWithProperties } = useOrganization();
  const isSUPEnabled = useSuitabilityUpfront();

  const actor = data?.currentContext?.currentActor;
  const isHiiveUser = actor?.isHiiveUser;
  const isFronteggUser = !!useAuthUserOrNull();
  const isAppMfaEnabled = actor ? getIsAppMfaEnabled(actor) : false;

  const shouldEnforceAppMfaConfiguration =
    isHiiveUser &&
    isFronteggUser &&
    isMarketplaceConfigurationEnabled &&
    !isAppMfaEnabled;

  const isOnboarding = useIsOnboarding();
  useCheckAuthenticated();
  useIdentifyUser();
  const { routeToFirstIncompleteStep } = useResumeOnboarding();

  const getIsConfiguredEnv = () => {
    const env = process.env.NEXT_PUBLIC_INFRA_ENV;
    if (!env) return false;
    return [`production`, `development`].includes(env);
  };

  const getIsActorOnCorrectPortal = (_actor: UserWithInstitutionFragment) =>
    (getIsBroker(_actor) && isHiiveConnect) ||
    (!getIsBroker(_actor) && !isHiiveConnect);

  useEffect(() => {
    if (!actor || !getIsConfiguredEnv() || getIsActorOnCorrectPortal(actor)) {
      return;
    }

    // Redirect broker users to broker portal if in main app
    if (getIsBroker(actor) && !isHiiveConnect) {
      signOut();
      const redirect = `${process.env.NEXT_PUBLIC_CLIENT_CONNECT_HOST}${window.location.pathname}`;
      replace(redirect);
    }

    // Redirect non-broker users to main app if in broker portal
    if (!getIsBroker(actor) && isHiiveConnect) {
      signOut();
      const redirect = `${process.env.NEXT_PUBLIC_CLIENT_HOST}${window.location.pathname}`;
      replace(redirect);
    }
  }, [actor]);

  useEffect(() => {
    if (actor?.institution) {
      setOrganizationIdWithProperties(actor.institution.id, {
        name: actor.institution.legalName,
        membershipAgreementSigned: actor.institution.membershipAgreementSigned,
      });
    }
  }, [
    actor?.institution?.membershipAgreementSigned,
    actor?.institution?.legalName,
  ]);

  // Redirect Hiive users to MFA Enrollment if app MFA not enabled
  useEffect(() => {
    if (shouldEnforceAppMfaConfiguration && pathname !== ROUTES.SETUP_MFA) {
      push(ROUTES.SETUP_MFA);
    }
  }, [isHiiveUser, isMarketplaceConfigurationEnabled, isAppMfaEnabled, push]);

  if (loading) return <Loader minHeight="100vh" />;

  const shouldRedirectToSignIn = !actor;
  const shouldRedirectToOnboarding =
    actor && !isOnboarding && !canAccessPlatform(actor);

  if (shouldRedirectToSignIn || shouldRedirectToOnboarding) {
    if (shouldRedirectToSignIn) signOut();
    if (shouldRedirectToOnboarding && isSUPEnabled) push(`/welcome`);
    if (shouldRedirectToOnboarding && !isSUPEnabled)
      routeToFirstIncompleteStep();

    return <Loader minHeight="100vh" />;
  }

  if (useCustomLayout)
    return (
      <AuthenticatedProviders>
        <Layout title={title}>
          <Flex
            as="main"
            id="main-element"
            direction="column"
            minH="100vh"
            flex="1 1 auto"
            position="relative"
          >
            {children}
          </Flex>
        </Layout>
      </AuthenticatedProviders>
    );
  return (
    <AuthenticatedProviders>
      <Layout title={title}>
        <Flex
          direction="column"
          minH="100vh"
          flex="1 1 auto"
          position="relative"
        >
          <NavSpacer />
          <EnforceAppMfaConfigurationContext.Provider
            value={!!shouldEnforceAppMfaConfiguration}
          >
            <NavBar />
          </EnforceAppMfaConfigurationContext.Provider>
          <Flex
            as="main"
            id="main-element"
            flex="1 1 auto"
            direction="column"
            align="center"
          >
            {children}
          </Flex>
        </Flex>
      </Layout>
    </AuthenticatedProviders>
  );
};

export default AuthenticatedLayout;
