import { useTranslation } from "react-i18next";

import { Box, Flex, Text, VStack } from "@chakra-ui/react";

import { HiiveButton, MailtoLink } from "@/components/common";
import { constants } from "@/utils";

const NotFoundError = () => {
  const { t } = useTranslation(`error`);

  return (
    <Flex direction="column" alignItems="center" pt={{ base: 10, md: 20 }}>
      <Text textStyle="deprecated-heading-7xl" textAlign="center">
        {t(`page_not_found`)}
      </Text>
      <VStack gap={5}>
        <Box textStyle="deprecated-text-4xl" textAlign="center">
          <Text>{t(`were_sorry`)}</Text>
          <Text>{t(`please_try_again_later`)}</Text>
        </Box>
        <MailtoLink email={constants.email_support} subject="Contact Hiive">
          <HiiveButton
            size="md"
            variant="rounded-solid-salmon"
            sentryLabel="[NotFoundError] Contact Support"
          >
            {t(`contact_support`)}
          </HiiveButton>
        </MailtoLink>
      </VStack>
    </Flex>
  );
};

export default NotFoundError;
